import { Button, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUserInfo } from '../../reducers/authSlice';
import HTTPService from '../../utils/axios';
import slackLogo from '../assets/img/slack.svg';
import CircularLoader from '../CircularLoader/CircularLoader';
import { customToast } from '../../App';
const SlackIntegrationPage = () => {
    const { invokeApi } = HTTPService();
    const { userInfo } = useSelector(getUserInfo);
    const [searchParams, setSearchParams] = useSearchParams();
    const role = localStorage.getItem('role')
    const [loading, setLoading] = useState(true);
    const [prorgams, setPrograms] = useState([]);
    const [program, setProgram] = useState("");
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const navigate = useNavigate()
    const handleCode = () => {
        setLoading(true)
        invokeApi({
            method: "POST",
            url: `/slack/oauth/callback/`,
            data: {
                code,
                program_id: program
            },
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            setLoading(false)
            if (role === 'admin')
                navigate('/admin/integrations/slack');
            else if (role === 'customer')
                navigate('/customer/integrations/slack');
            else if (role === 'customer')
                navigate('/researcher/integrations/slack');

            customToast.success("Account added successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
        }).catch(error => {
            const flattenedErrors = Object.keys(error.data)
                .map(key => key.replaceAll('_', ' ').toUpperCase() + ": " + error.data[key].join('\n'))
                .join('\n');
            customToast.error(flattenedErrors ?? "Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
            if (role === 'admin')
                navigate('/admin/integrations/slack');
            else if (role === 'customer')
                navigate('/customer/integrations/slack');
            else if (role === 'customer')
                navigate('/researcher/integrations/slack');
            setLoading(false)
        })
    }
    const getPrograms = () => {
        let arr = [];
        let url = `programs/`
        if (role === 'customer' && userInfo?.user_data?.id)
            url = `programs/?customer=${userInfo.user_data.id}`
        invokeApi({
            method: "GET",
            url,
        }).then((data) => {
            data.results.map(option => {
                arr.push({
                    label: `${option.program_id} (${option.program_title.length > 30
                        ? option.program_title.slice(0, 30) + "..."
                        : option.program_title
                        })`,
                    value: option.id,
                });
            });
            setPrograms(arr);
            setLoading(false)
        }).catch((error) => {
            customToast.error("Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
            setLoading(false)
        });
    }

    useEffect(() => {
        if (userInfo?.user_data)
            getPrograms();
    }, [userInfo?.user_data])
    // useEffect(() => {
    //     if (code)
    //         handleCode()
    // }, [code]);
    return (
        <div style={{ height: '100vh', overflowY: 'auto', width: '100%' }} className='d-flex justify-content-center align-items-center row'>
            {loading ? <CircularLoader size={100} /> :
                <div className='col-sm-12 col-md-3' style={{ boxShadow: '0 0 3px 0 #ccc', height: 'calc(70% - 0px)', padding: '2rem', overflowY: 'auto' }}>
                    <h3>
                        <img height={'50px'} className='mr-2' src={slackLogo} alt="" />
                        Slack Integration</h3>
                    <div style={{ fontSize: '1.2rem', marginTop: '2rem', fontWeight: '600' }}>
                        Select Program
                    </div>
                    <Select
                        fullWidth
                        value={program}
                        onChange={(e) => setProgram(e.target.value)}
                        sx={{
                            mt: 1,
                            ".paper": {
                                height: "200px !important",
                            },
                        }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 200,
                                },
                            },
                        }}  >
                        {prorgams.map((option) => (
                            <MenuItem key={option.value} value={option.value} sx={{ fontSize: '1.2rem' }}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <Button variant='contained' className='mt-3' onClick={handleCode}>
                        Submit
                    </Button>
                </div>}

        </div>
    )
}

export default SlackIntegrationPage