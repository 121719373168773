import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formatDate } from "../../utils/helperFunctions";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Skeleton from "react-loading-skeleton";
import HTTPService from "../../utils/axios";
import AppContext from "../Context/AppContext";
import { customToast } from "../../App";

export default function Addskills() {
	const navigate = useNavigate();
	const { invokeApi } = HTTPService();
	const [skillLoading, setSkillLoading] = useState(false);
	const { setCircularLoading } = useContext(AppContext);
	const { userId } = useParams();
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [skills, setSkills] = useState();
	const [selectedSkill, setSelectedSkill] = useState(null);
	const [skillTypeValues, setSkillTypeValues] = useState([]);
	const [skillLevelValues, setSkillLevelValues] = useState([]);

	const fetchBaseValues = async () => {
		invokeApi({
			method: "GET",
			url: `basevalues/`,
		}).then((data) => {
			data?.results?.map((item) => {
				if (item.title_code == "skill_type") {
					setSkillTypeValues(
						item.value_options
							.split(",")
							.map((value) => ({ value, label: value }))
					);
				}
				if (item.title_code == "skill_level") {
					setSkillLevelValues(
						item.value_options
							.split(",")
							.map((value) => ({ value, label: value }))
					);
				}
			});
		}).catch((error) => {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		});
	};
	const fetchSkills = async () => {
		setSkillLoading(true);
		invokeApi({
			method: "GET",
			url: `skills/?user_id=${userId}`,
		})
			.then((data) => {
				setSkillLoading(false);

				setSkills(data);
			})
			.catch((error) => {
				setSkillLoading(false);
				customToast.error("Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			});
	};
	useEffect(() => {
		fetchSkills();
		fetchBaseValues();
	}, []);

	const handleChange = (e) => {
		let data = { ...formData };
		if (["certification_documents_upload"].includes(e.target.name)) {
			data[e.target.name] = e.target.files[0];
		} else {
			data[e.target.name] = e.target.value;
		}
		setFormData(data);

	};

	const handleSubmit = async (type) => {
		//  variable that will be send in payload
		setCircularLoading(true);
		let apiData = {
			...formData,
		};
		if (typeof apiData.certification_documents_upload === "string") {
			delete apiData.certification_documents_upload;
		}
		setCircularLoading(true);
		invokeApi({
			method: "POST",
			url: `skills/`,
			data: {
				user_id: userId,
				...apiData,
			},
		}).then((data) => {
			setCircularLoading(false);
			fetchSkills();
			setFormData({});
			setErrors({});
			document.getElementById("certification_documents_upload").value = null;
			customToast.success("data added successfully.", {
				position: toast.POSITION.TOP_RIGHT,
			})
		}).catch((error) => {
			setCircularLoading(false);
			console.log(error);
			setErrors({ ...error.data });
			document.getElementById("certification_documents_upload").value = null;
			const flattenedErrors = Object.keys(error.data)
				.map(key => key.replaceAll('_', ' ').toUpperCase() + ": " + error.data[key].join('\n'))
				.join('\n');
			customToast.error(flattenedErrors ?? "Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
			});
		});
	};

	const deleteSkill = async (id) => {
		invokeApi({
			method: "DELETE",
			url: `skills/${id}/`,
		}).then((data) => {
			fetchSkills();
			customToast.success("data delete successfully.", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		}).catch((error) => {
			console.log(error);
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		});
	};

	const handleEdit = (payment) => {
		setSelectedSkill(payment);
	};

	const handleCloseModal = () => {
		setSelectedSkill(null);
	};

	const handleChangeEdit = (e) => {
		let data = { ...selectedSkill };
		if (["certification_documents_upload"].includes(e.target.name)) {
			data[e.target.name] = e.target.files[0];
		} else {
			data[e.target.name] = e.target.value;
		}
		setSelectedSkill(data);
	};

	const handleUpdate = async () => {
		setCircularLoading(true);
		let apiData = {
			...selectedSkill,
		};
		if (typeof apiData.certification_documents_upload === "string") {
			delete apiData.certification_documents_upload;
		}

		invokeApi({
			method: "PATCH",
			url: `skills/${apiData.id}/`,
			data: {
				...apiData,
			},
		}).then((data) => {
			setCircularLoading(false);
			handleCloseModal();
			fetchSkills();
			customToast.success("status updated", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		}).catch((error) => {
			console.log(error);
			const flattenedErrors = Object.keys(error.data)
				.map(key => key.replaceAll('_', ' ').toUpperCase() + ": " + error.data[key].join('\n'))
				.join('\n');
			customToast.error(flattenedErrors ?? "Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
			});
			setCircularLoading(true);
		});
	};

	return (
		<>
			<div className="card mt-0" id="des">
				<div className="card-header pb-0">
					<h5>Adding Your Skills :</h5>
				</div>
				<div className="card-body pt-0">
					<p>
						The Bugbusterslabs platform leverages user data to enhance program
						recommendations via Programs and submissions. It draws insights from
						your historical submissions, platform engagement, and self-reported
						details like skills and interests. By filling out the Skills and
						Interests section in your profile, you can highlight your expertise
						and preferences. Utilizing this input, Bugbusterslabs tailors
						program suggestions more effectively. Additionally, users have the
						option to rate their research skills on a scale of one to five.
					</p>

					<strong>The Rating Scale Details Are :</strong>

					<p className="mb-2 mt-2">
						<strong>1 - Beginner : </strong> You understand the basics, or you
						have started learning.
					</p>

					<p className="mb-2 mt-0">
						<strong>2 - Novice : </strong> You have context but need resources
						and guidelines.
					</p>

					<p className="mb-2 mt-0">
						<strong>3 - Competent : </strong> You have the necessary experience
						and knowledge.
					</p>

					<p className="mb-2 mt-0">
						<strong>4 - Proficient :</strong> You have strong experience and a
						deep understanding.
					</p>

					<p className="mb-2 mt-0">
						<strong>5 - Expert :</strong> You have outstanding knowledge and
						intuitively apply solutions.
					</p>
				</div>
			</div>
			<div className="card mt-2">
				<div className="card-header pb-0">
					<h5>Skills :</h5>
				</div>
				<div className="card-body pt-0">
					<div className="table-responsive">
						<table className="table align-items-center mb-0 customerDashboardTable">
							<thead>
								<tr>
									<th className="text-uppercase  font-weight-bolder opacity-7">
										Skill Type
									</th>
									<th className="text-uppercase  font-weight-bolder opacity-7">
										Skill Title
									</th>
									<th className="text-uppercase  font-weight-bolder opacity-7">
										Skill Level
									</th>
									<th className="text-center text-uppercase  font-weight-bolder opacity-7">
										Certification Title
									</th>
									<th className="text-center text-uppercase  font-weight-bolder opacity-7">
										Certification ID
									</th>
									<th className="text-center text-uppercase  font-weight-bolder opacity-7">
										Certification Documents
									</th>
									<th className="text-center text-uppercase  font-weight-bolder opacity-7">
										Created At
									</th>
									<th className="text-center text-uppercase  font-weight-bolder opacity-7">
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								{skillLoading
									? Array(5)
										.fill(0)
										.map((item, index) => (
											<tr key={index}>
												<td>
													<p className="text-sm text-secondary mb-0 px-2">
														<Skeleton width={70} />
													</p>
												</td>
												<td>
													<p className="text-sm text-secondary mb-0">
														<Skeleton width={70} />
													</p>
												</td>
												<td>
													<p className="text-sm text-secondary mb-0 px-2">
														<Skeleton width={70} />
													</p>
												</td>
												<td>
													<p className="text-sm text-secondary mb-0">
														<Skeleton width={70} />
													</p>
												</td>
												<td>
													<p className="text-sm text-secondary mb-0">
														<Skeleton width={70} />
													</p>
												</td>
												<td className="align-middle text-center text-sm">
													<p className="text-sm text-secondary mb-0">
														<Skeleton width={70} />
													</p>
												</td>
												<td className="align-middle text-center">
													<p className="text-sm text-secondary mb-0">
														<Skeleton width={70} />
													</p>
												</td>
												<td>
													<div className="d-flex">
														<Skeleton width={40} height={40} />
													</div>
												</td>
											</tr>
										))
									: skills?.results &&
									skills.results.map((item) => (
										<tr key={item.id}>
											<td>
												<p className="text-sm text-secondary mb-0 px-2">
													{item.skill_type}
												</p>
											</td>
											<td>
												<p className="text-sm text-secondary mb-0">
													{item.skill_title}
												</p>
											</td>
											<td>
												<p className="text-sm text-secondary mb-0 px-2">
													{item.skill_level}
												</p>
											</td>
											<td>
												<p className="text-sm text-secondary mb-0">
													{item.certification_title}
												</p>
											</td>
											<td>
												<p className="text-sm text-secondary mb-0">
													{item.certification_id}
												</p>
											</td>
											<td className="align-middle text-center text-sm">
												<p className="text-sm text-secondary mb-0">
													<a
														href={item.certification_documents_upload}
														target="_blank"
														className="btn btn-link"
													>
														View
													</a>
												</p>
											</td>
											<td className="align-middle text-center">
												<p className="text-sm text-secondary mb-0">
													{formatDate(item.created_at, 1)}
												</p>
											</td>
											<td>
												<div className="d-flex">
													<button
														type="button"
														className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
														data-bs-toggle="dropdown"
														aria-expanded="false"
													>
														<i
															className="fa fa-ellipsis-v text-xs"
															aria-hidden="true"
														></i>
													</button>
													<ul className="dropdown-menu">
														<li>
															<button
																className="dropdown-item"
																// to={`/admin/customer/edit/${item.id}`}
																onClick={() => handleEdit(item)}
															>
																Edit
															</button>
														</li>
														<li>
															<button
																onClick={() => deleteSkill(item.id)}
																className="dropdown-item"
															>
																Delete
															</button>
														</li>
													</ul>
												</div>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="card mt-2" id="basic-info">
				<div className="card-header mb-1">
					<h5 className="mb-0">Add New Skills</h5>
					<p className="text-sm mb-0">(add your Skill level from 1 - 5)</p>
				</div>
				<h6 className="text-center">Skill Set -1</h6>
				<div className="card-body pt-0">
					<div className="row">
						<div className="col-6">
							<label className="form-label">Skill Title</label>
							<div className="input-group">
								<input
									className="form-control w-100"
									placeholder="Enter Skill "
									required="required"
									type="text"
									name="skill_title"
									value={formData.skill_title ? formData.skill_title : ""}
									onChange={handleChange}
								/>
								<p style={{ color: "red", fontSize: "14px" }}>
									{(formData.skill_title === "" ||
										formData.skill_title === null ||
										formData.skill_title === undefined) &&
										errors.skill_title}
								</p>
							</div>
						</div>
						<div className="col-sm-3">
							<label className="form-label">Skill Type</label>
							<div className="input-group">
								<Form.Select
									aria-label="Default select example"
									value={
										formData && formData.skill_type ? formData.skill_type : ""
									}
									// value={form && form.priority ? form.priority : ""}
									// onChange={handlePriorityChange}
									onChange={(selectedOption) => {
										setFormData({
											...formData,
											skill_type: selectedOption.target.value,
										});
									}}
								>
									<option>Select Type</option>
									{skillTypeValues.map((item) => (
										<option key={item.value} value={item.value}>
											{item.label}
										</option>
									))}
								</Form.Select>
							</div>
						</div>
						<div className="col-sm-3">
							<label className="form-label">Skill Level</label>
							<div className="input-group">
								<Form.Select
									className="w-100"
									aria-label="Default select example"
									value={
										formData && formData.skill_level ? formData.skill_level : ""
									}
									// value={form && form.priority ? form.priority : ""}
									// onChange={handlePriorityChange}
									onChange={(selectedOption) => {
										setFormData({
											...formData,
											skill_level: selectedOption.target.value,
										});
									}}
								>
									<option>Select Level</option>
									{skillLevelValues.map((item) => (
										<option key={item.value} value={item.value}>
											{item.label}
										</option>
									))}
								</Form.Select>
								<p style={{ color: "red", fontSize: "14px" }}>
									{(formData.skill_level === "" ||
										formData.skill_level === null ||
										formData.skill_level === undefined) &&
										errors.skill_level}
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<label className="form-label">Certification Title</label>
							<div className="input-group">
								<input
									name="certification_title"
									className="form-control w-100"
									type="text"
									placeholder="Enter Certification Title "
									required="required"
									value={formData.certification_title ?? ""}
									onChange={handleChange}
								/>
								<p style={{ color: "red", fontSize: "14px" }}>
									{(formData.certification_title === "" ||
										formData.certification_title === null ||
										formData.certification_title === undefined) &&
										errors.certification_title}
								</p>
							</div>
						</div>
						<div className="col-6">
							<label className="form-label">Certification Id</label>
							<div className="input-group">
								<input
									name="certification_id"
									className="form-control w-100"
									type="text"
									placeholder="Enter Certification Id "
									required="required"
									value={formData.certification_id ?? ""}
									onChange={handleChange}
								/>
								<p style={{ color: "red", fontSize: "14px" }}>
									{(formData.certification_id === "" ||
										formData.certification_id === null ||
										formData.certification_id === undefined) &&
										errors.certification_id}
								</p>
							</div>
						</div>
						<div className="col-6">
							<label className="form-label">Certification Type</label>
							<div className="input-group">
								<input
									name="certification_type"
									className="form-control w-100"
									type="text"
									placeholder="Enter Certification Type "
									required="required"
									value={formData.certification_type ?? ""}
									onChange={handleChange}
								/>
								<p style={{ color: "red", fontSize: "14px" }}>
									{(formData.certification_type === "" ||
										formData.certification_type === null ||
										formData.certification_type === undefined) &&
										errors.certification_type}
								</p>
							</div>
						</div>
						<div className="col-6">
							<label className="form-label">
								Certification Documents Upload
							</label>
							<div className="input-group">
								<input
									className="form-control w-100"
									type="file"
									id="certification_documents_upload"
									name="certification_documents_upload"
									// value={formData.certification_documents_upload}
									onChange={handleChange}
								/>
								<p style={{ color: "red", fontSize: "14px" }}>
									{(formData.certification_documents_upload === "" ||
										formData.certification_documents_upload === null ||
										formData.certification_documents_upload === undefined) &&
										errors.certification_documents_upload}
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<label className="form-label mt-4">
								Skill | Certification Short Description
							</label>
							<div className="input-group">
								<textarea
									rows="6"
									className="form-control"
									placeholder="Enter Short Description .."
									name="certification_short_description"
									value={formData.certification_short_description ?? ""}
									onChange={handleChange}
								></textarea>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<button
								onClick={handleSubmit}
								className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0"
							>
								Add New Skill Set
							</button>
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-lg-12">
							{/* <button className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0">
                Submit Skills
              </button> */}
							<button onClick={() => navigate(-1)} className="btn  bg-danger text-white btn-sm me-3 float-end mt-4 mb-0">
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>

			<Modal
				show={selectedSkill !== null}
				onHide={handleCloseModal}
				size="xl"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Edit Skill Details</Modal.Title>
				</Modal.Header>

				<Modal.Body
					style={{
						overflowY: "auto",
						maxHeight: "450px",
						minHeight: "450px",
						// width: "500px",
					}}
				>
					<div className="row">
						<div className="col-6">
							<label className="form-label">Skill Title</label>
							<div className="input-group">
								<input
									name="skill_title"
									className="form-control"
									type="text"
									placeholder="Enter Skill "
									required="required"
									value={selectedSkill?.skill_title ?? ""}
									onChange={handleChangeEdit}
								/>
							</div>
						</div>
						<div className="col-sm-3">
							<label className="form-label">Skill Type</label>
							<div className="input-group">
								<Form.Select
									aria-label="Default select example"
									value={
										selectedSkill && selectedSkill.skill_type
											? selectedSkill.skill_type
											: ""
									}
									// value={form && form.priority ? form.priority : ""}
									// onChange={handlePriorityChange}
									onChange={(selectedOption) => {
										setSelectedSkill({
											...selectedSkill,
											skill_type: selectedOption.target.value,
										});
									}}
								>
									<option>Select Type</option>
									{skillTypeValues.map((item) => (
										<option key={item.value} value={item.value}>
											{item.label}
										</option>
									))}
								</Form.Select>
							</div>
						</div>
						<div className="col-sm-3">
							<label className="form-label">Skill Level</label>
							<div className="input-group">
								<Form.Select
									aria-label="Default select example"
									value={
										selectedSkill && selectedSkill.skill_level
											? selectedSkill.skill_level
											: ""
									}
									// value={form && form.priority ? form.priority : ""}
									// onChange={handlePriorityChange}
									onChange={(selectedOption) => {
										setSelectedSkill({
											...selectedSkill,
											skill_level: selectedOption.target.value,
										});
									}}
								>
									<option>Select Skill</option>
									{skillLevelValues.map((item) => (
										<option key={item.value} value={item.value}>
											{item.label}
										</option>
									))}
								</Form.Select>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<label className="form-label">Certification Title</label>
							<div className="input-group">
								<input
									name="certification_title"
									className="form-control"
									type="text"
									placeholder="Enter Certification Title "
									required="required"
									value={selectedSkill?.certification_title ?? ""}
									onChange={handleChangeEdit}
								/>
							</div>
						</div>
						<div className="col-6">
							<label className="form-label">Certification Id</label>
							<div className="input-group">
								<input
									name="certification_id"
									className="form-control"
									type="text"
									placeholder="Enter Certification Id "
									required="required"
									value={selectedSkill?.certification_id ?? ""}
									onChange={handleChangeEdit}
								/>
							</div>
						</div>
						<div className="col-6">
							<label className="form-label">Certification Type</label>
							<div className="input-group">
								<input
									name="certification_type"
									className="form-control"
									type="text"
									placeholder="Enter Certification Type "
									required="required"
									value={selectedSkill?.certification_type ?? ""}
									onChange={handleChangeEdit}
								/>
							</div>
						</div>
						<div className="col-6">
							<label className="form-label">
								Certification Documents Upload
							</label>
							<div className="input-group">
								<input
									className="form-control"
									type="file"
									id="certification_documents_upload"
									name="certification_documents_upload"
									// value={selectedSkill?.certification_documents_upload}
									onChange={handleChangeEdit}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<label className="form-label mt-4">
								Skill | Certification Short Description
							</label>
							<div className="input-group">
								<textarea
									rows="6"
									className="form-control"
									placeholder="Enter Short Description .."
									name="certification_short_description"
									value={selectedSkill?.certification_short_description ?? ""}
									onChange={handleChangeEdit}
								></textarea>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="btn btn-light" onClick={() => handleCloseModal()}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							handleUpdate();
						}}
					>
						update
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
