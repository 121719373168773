import { useEffect, useState } from "react";
import axios from "axios";

const axiosBaseUrl = process.env.REACT_APP_API_URL;

const instance = axios.create({
    baseURL: axiosBaseUrl,
    timeout: 60000,
    withCredentials: false,
});

// Function to refresh token
const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
        throw new Error("No refresh token available");
    }
    const response = await axios.post(`${axiosBaseUrl}/refresh-token`, { token: refreshToken });
    const { token, newRefreshToken } = response.data;

    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", newRefreshToken);

    return token;
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

// Function to log out the user
const logout = () => {
    localStorage.clear();
    // Redirect to login page
    window.location.href = "/login"; // or use history.push('/login') if using react-router
};

// Request interceptor
instance.interceptors.request.use(config => {
    console.log(config.noToken, config.url)
    if (!config.noToken && config.url && !config.url.includes("/login") && !config.url.includes("/refresh-token") && !config.url.includes("forgot")) {
        const token = localStorage.getItem("token");
        if (token && (window?.location?.pathname !== '/programs')) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    if (config.url && config.url.includes('/logout')) {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        if (token) {
            if (!config.data) {
                config.data = {}
            }
            config.data['refresh_token'] = refreshToken;
        }
    }
    if (config.url && config.url.includes("jira")) {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
});

// Response interceptor
// instance.interceptors.response.use(
//     response => response,
//     async error => {
//         const originalRequest = error.config;
//         if (error.response.status === 401 && !originalRequest._retry) {
//             if (isRefreshing) {
//                 return new Promise((resolve, reject) => {
//                     failedQueue.push({ resolve, reject });
//                 }).then(token => {
//                     originalRequest.headers.Authorization = `Bearer ${token}`;
//                     return axios(originalRequest);
//                 }).catch(err => {
//                     return Promise.reject(err);
//                 });
//             }

//             originalRequest._retry = true;
//             isRefreshing = true;

//             return new Promise((resolve, reject) => {
//                 refreshToken().then(token => {
//                     instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//                     originalRequest.headers.Authorization = `Bearer ${token}`;
//                     processQueue(null, token);
//                     resolve(axios(originalRequest));
//                 }).catch(err => {
//                     processQueue(err, null);
//                     // logout();
//                     reject(err);
//                 }).finally(() => {
//                     isRefreshing = false;
//                 });
//             });
//         }

//         return Promise.reject(error);
//     }
// );

const HTTPService = () => {
    const invokeApi = async (request) => {
        if (!instance) {
            console.error("Axios instance not initialized");
            return;
        }
        try {
            const config = {
                method: request.method,
                url: request.url,
                maxBodyLength: Infinity,
                data: request.data,
                params: request.params,
                headers: request.headers || {
                    "Content-Type": "application/json",
                    "Content-Type": "multipart/form-data",
                },
                responseType: request.responseType,
                noToken: request.noToken
            };
            const response = await instance.request(config);
            return response.data;
        } catch (error) {
            throw error.response || error;
        }
    };

    // same as invokeApi but returns a promise instead of accepting callback
    const invoke = (method, url, data, params, headers, responseType) => {
        return invokeApi({
            method,
            url,
            data,
            params,
            headers,
            responseType,
        });
    };

    return { invokeApi, invoke };
};

export default HTTPService;
