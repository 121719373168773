import React, { useContext, useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";
import AddModal from "../../../common/modal/AddModal";
import AppContext from "../../../Context/AppContext";
import useHttp from "../../../Hooks/useHttp";
import "../faqCategory/FaqCategoryStyles.css";
import { customToast } from "../../../../App";

export default function EditQA() {
	const { setCircularLoading } = useContext(AppContext);

	const [openAddModal, setOpenAddModal] = useState(false);

	const navigate = useNavigate();
	const [InpQs, setInpQs] = useState();
	const [sequence, setSequence] = useState();
	const [ans, setAns] = useState("Please Write Your Answer");
	const [type, setType] = useState("");
	const [userType, setUserType] = useState();
	const [mainCatId, setMainCatId] = useState();
	const [mainCatOpt, setMainCatOpt] = useState();
	const [subCat, setSubCat] = useState();
	const [subCatOpt, setSubCatOpt] = useState([]);
	const { id } = useParams();

	const { GetRequest, PutRequest } = useHttp();

	const token = localStorage.getItem("token");
	const API = process.env.REACT_APP_API_URL;

	const handleSubmit = async () => {
		setOpenAddModal(false);
		setCircularLoading(true);
		const param = {
			question: InpQs,
			answer: ans,
			user_type: type,
			sequence: sequence,
			qa_status: "active",
			user_id: "test_user_id_1",
			is_deleted: false,
			sub_category_id: subCat,
		};
		console.log(param);
		const { data, error } = await PutRequest(
			API + "faq_questionanswer/" + id + "/",
			param,
			{
				Authorization: "Bearer  " + token,
				"Content-Type": "multipart/form-data",
			}
		);
		if (error) {
			setCircularLoading(false);
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		}
		if (data) {
			setCircularLoading(false);

			setSequence("");
			setType("");
			setAns("Please Write Your Answer");
			setSubCat("");
			setInpQs("");

			customToast.success("Question Uploaded Successfully", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
			navigate("/admin/faq/dashboard");
		}
	};

	const fetchMainCategory = async (sub_cat_id) => {
		const { data, error } = await GetRequest(
			API + "faq_category/?id=" + sub_cat_id,
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		// console.log(data, error);
		if (error) {
			// alert("Something went wrong");
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {
			setMainCatId(data.results[0].parent_category_id);
			fetchSubCategories(data.results[0].parent_category_id);
		}
	};

	const fetchSubCategories = async (parent_category_id) => {
		const { data, error } = await GetRequest(
			API +
			"faq_category/?category_type=sub_category&cat_status=active&parent_category_id=" +
			parent_category_id,
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		// console.log(data, error);
		if (error) {
			// alert("Something went wrong");
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {

			setSubCatOpt(data.results);
		}
	};

	const fetchValueOpt = async () => {
		const { data, error } = await GetRequest(
			API + "basevalues/?title_code=user_type",
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		// console.log(data, error);
		if (error) {
			// alert("Something went wrong");
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {
			setUserType(data);
		}
	};
	const fetchMainCatName = async () => {
		const { data, error } = await GetRequest(
			API + "faq_category/?category_type=main_category&cat_status=active",
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		// console.log(data, error);
		if (error) {
			// alert("Something went wrong");
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {
			setMainCatOpt(data);
			// console.log("maincat  ",data)
		}
	};
	const fetchEditData = async () => {
		const { data, error } = await GetRequest(
			API + "/faq_questionanswer/" + id + "/",
			{},
			{
				Authorization: "Bearer  " + token,
				"Content-Type": "multipart/form-data",
			}
		);
		if (error) {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		}
		if (data) {
			setType(data.user_type);
			setSubCat(data.sub_category_id);
			setInpQs(data.question);
			setAns(data.answer);
			setSequence(data.sequence);
			fetchMainCategory(data.sub_category_id);
		}
	};
	useEffect(() => {
		fetchValueOpt();
		fetchEditData();
		fetchMainCatName();
	}, []);

	const Breadcrumbname = {
		home: "/admin/home",
		"Master Module": "",
		"FAQ Q&A": "/admin/faq/dashboard",
		"Edit FAQ": "",
	};
	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row w-100 d-flex justify-content-center">
					<div className="addCategoryArea  my-4">
						<div className="row">
							<div className="col-lg-6 ps-3">
								<Breadcrumb names={Breadcrumbname} />
							</div>

							<div className=" col-lg-6">
								<div className="button-row d-flex justify-content-end mt-0 mb-3">
									<Link
										to="/admin/faq/dashboard"
										className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
										id="adduser"
									>
										VIEW ALL
									</Link>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="card">
								<div className="row ">
									<div className="card mt-0 " id="basic-info">
										<div className="card-header">
											<h5>EDIT QUESTION & ANSWERS</h5>
										</div>
										<div className="card-body pt-0">
											<div className="row mb-3">
												<div className="col-6">
													<label className="form-label">Type</label>

													<select
														className="form-select"
														name="choices-gender"
														id="choices-gender"
														value={type}
														onChange={(e) => setType(e.target.value)}
													>
														<option value="" selected>
															Select Q & A Type
														</option>
														{userType &&
															userType?.results[0].value_options
																?.split(",")
																.map((item, index) => {
																	console.log(item);
																	return <option key={index} value={item}>{item}</option>;
																})}
													</select>
												</div>
											</div>
											<div className="row">
												<div className="col-6">
													<label className="form-label">Main Category</label>

													<select
														className="form-select"
														name="choices-gender"
														id="choices-gender"
														value={mainCatId}
														onChange={(e) => {
															setMainCatId(e.target.value);
															fetchSubCategories(e.target.value);
														}}
													>
														{mainCatOpt &&
															mainCatOpt.results &&
															mainCatOpt.results.length &&
															mainCatOpt.results.map((item, index) => (
																<option value={item.id} key={index}>
																	{item.category_title}
																</option>
															))}
													</select>
												</div>
												<div className="col-6">
													<label className="form-label">Sub Category</label>

													<select
														className="form-select"
														name="choices-gender"
														id="choices-gender"
														value={subCat}
														onChange={(e) => setSubCat(e.target.value)}
													>
														<option value="" selected>
															Enter Sub-Category
														</option>
														{subCatOpt &&
															subCatOpt.map((item, index) => (
																// if (mainCatId === item.parent_category_id) {
																<option key={index} value={item.id}>
																	{item.category_title}
																</option>
															))}
													</select>
												</div>
											</div>

											<div className="row">
												<div className="col-12">
													<label className="form-label mt-4">
														Your Question
													</label>
													<div className="input-group ">
														<input
															className="form-control"
															placeholder="Write Your Question Here"
															type="text"
															value={InpQs}
															onChange={(e) => setInpQs(e.target.value)}
														/>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-12">
													<label className="form-label mt-4">Your Answer</label>
													<CKEditor
														editor={ClassicEditor}
														data={ans}
														onChange={(e, editor) => setAns(editor.getData())}
													/>
												</div>
											</div>

											<div className="row">
												<div className="col-6">
													<label className="form-label mt-4">Sequence</label>
													<div className="input-group ">
														<input
															className="form-control"
															placeholder="Enter Sequence"
															type="number"
															value={sequence}
															onChange={(e) => setSequence(e.target.value)}
														/>
													</div>
												</div>
											</div>
											<div className=" d-flex justify-content-end">
												<Link
													to="/admin/faq/dashboard"
													className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
												>
													Cancel
												</Link>
												<button
													onClick={() => setOpenAddModal(true)}
													className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
												>
													Submit Q & A
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AddModal
				cancelBtn={"Cancel"}
				submitBtn={"SUBMIT"}
				createAddTopic={"Edit Q&A"}
				confirmText={"Edit The Q&A"}
				isOpen={openAddModal}
				onSubmit={handleSubmit}
				onClose={() => setOpenAddModal(false)}
			/>
		</>
	);
}
