import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import downarrowdark from "../../assets/svg/downarrow.svg";
import menudata from "../menudata/menudata.json";
const Management = (props) => {
	const userType = props.usertype;
	const isSupportEnabled = localStorage.getItem('supporttoken');
	const userRole = Object.entries(menudata).filter(
		([key, value]) => key === userType
	);

	const [managementMenuList, setManagementmenuList] = useState([]);

	useEffect(() => {
		if (userRole[0][1]?.management) {
			if (isSupportEnabled != null) {
				setManagementmenuList(userRole[0][1].management.submenu.filter(el => el.name !== 'Support Customer'));
			} else {
				setManagementmenuList(userRole[0][1].management.submenu);
			}
		}
	}, [userRole[0][1]?.management]);

	return (
		<>
			<li className="nav-item dropdown dropdown-hover">
				<Link
					className="nav-link d-flex justify-content-between cursor-pointer align-items-center"
					to="#"
					id="dropdownMenuBlocks"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="me-2"
						width="18"
						height="19"
						viewBox="0 0 18 19"
						fill="none"
					>
						<path
							d="M9 13.116C10.02 13.116 11.1525 12.9138 12.3975 12.5094C13.6425 12.1051 15.51 11.3937 18 10.3753V10.0384C18 9.27462 17.6925 8.6606 17.0775 8.19634C16.4625 7.73209 15.7875 7.60479 15.0525 7.81446C14.7975 7.88934 14.5389 7.97919 14.2767 8.08402C14.0145 8.18886 13.6656 8.32364 13.23 8.48838C12.315 8.87775 11.5425 9.14732 10.9125 9.29708C10.2825 9.44684 9.645 9.52172 9 9.52172C8.325 9.52172 7.6725 9.44324 7.0425 9.2863C6.4125 9.12935 5.6175 8.85589 4.6575 8.46591C4.2975 8.31615 3.9864 8.19275 3.7242 8.09571C3.462 7.99866 3.2106 7.9124 2.97 7.83692C2.235 7.59731 1.5564 7.70963 0.9342 8.17388C0.312 8.63814 0.0006 9.25964 0 10.0384V10.3304C1.965 11.229 3.69 11.9179 5.175 12.3971C6.66 12.8763 7.935 13.116 9 13.116ZM9 18.5073C11.07 18.5073 12.8889 17.9418 14.4567 16.8108C16.0245 15.6798 17.0856 14.2311 17.64 12.4645C15.675 13.3032 14.025 13.9211 12.69 14.3182C11.355 14.7154 10.125 14.9137 9 14.9131C7.86 14.9131 6.5589 14.6998 5.0967 14.2733C3.6345 13.8468 2.0406 13.2364 0.315 12.442C0.825 14.2841 1.8525 15.7556 3.3975 16.8567C4.9425 17.9577 6.81 18.5079 9 18.5073ZM9 7.7246C8.01 7.7246 7.1625 7.37267 6.4575 6.6688C5.7525 5.96492 5.4 5.11878 5.4 4.13037C5.4 3.14195 5.7525 2.29581 6.4575 1.59194C7.1625 0.888068 8.01 0.536133 9 0.536133C9.99 0.536133 10.8375 0.888068 11.5425 1.59194C12.2475 2.29581 12.6 3.14195 12.6 4.13037C12.6 5.11878 12.2475 5.96492 11.5425 6.6688C10.8375 7.37267 9.99 7.7246 9 7.7246Z"
							fill="#7F56D9"
						></path>
					</svg>
					Management
					<img
						src={downarrowdark}
						alt="down-arrow"
						className="arrow ms-1 d-lg-block d-none"
					/>
					<img
						src={downarrowdark}
						alt="down-arrow"
						className="arrow ms-1 d-lg-none d-block"
					/>
				</Link>

				<div
					className="dropdown-menu dropdown-menu-animation dropdown-md dropdown-md-responsive p-3 border-radius-lg mt-0 mt-lg-2"
					aria-labelledby="dropdownMenuBlocks"
				>
					<div className="d-none d-lg-block">
						<ul className="list-group">
							{managementMenuList.map((item, index) => {
								if (item.name.toLowerCase() !== userType + 's')
									return (
										<li key={index} className="nav-item dropdown dropdown-hover dropdown-subitem list-group-item border-0 p-0">
											<Link
												className="dropdown-item py-2 ps-3 border-radius-md"
												to={item.link}
											>
												<div className="d-flex">
													<div className="icon h-10 me-3 d-flex mt-1">
														<i className={item.icon}></i>
													</div>
													<div className="w-100 d-flex align-items-center justify-content-between">
														<div>
															<p className="dropdown-header text-dark p-0">
																{item.name}
															</p>
														</div>
													</div>
												</div>
											</Link>
										</li>
									);
							})}
						</ul>
					</div>
				</div>
			</li>
		</>
	);
};

export default Management;
