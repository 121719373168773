import React from 'react'
import Header from '../../components/Header/Header'
import Dashboard from '../../components/Header-Components/Dashboard'
import Management from '../../components/Header-Components/Management'
import Payments from '../../components/Header-Components/Payments'
import Integrations from '../../components/Header-Components/Integrations'
import Reports from '../../components/Header-Components/Reports'
import Footer from '../../../FrontEnd/components/Footer'
import { useNavigate } from 'react-router-dom'

const Success = () => {
    const navigate = useNavigate();
    const handleback = () => {
        navigate('/customer/payment/wallet')
    }
    return (
        <>
            <Header returnhome="/customer/home">
                <Dashboard Dashboardlink="/customer/home" />
                <Management usertype="customer" />
                <Payments usertype="customer" />
                <Integrations usertype="customer" />
                <Reports usertype="customer" />
            </Header>
            <div className="bg-gray-100">
                <main className="position-relative mt-0 d-flex justify-content-center" style={{ height: "auto" }}>
                    <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
                        {/* <img src={rightPurple} alt="Right Mark" className="rightMark" /> */}
                        <i className="fa-solid fa-check fa-10x" style={{ color: '#7f56d9' }}></i>
                        <h5 className="RegSuccessMessage">
                            Payment Done Successfully
                        </h5>
                        {/* <div className="RegSuccessDescription">
                            <p>
                                Thank you! We have sent you an E-mail to {regEmail}.
                                Kindly click the link in that Mail to complete your Registration
                                Further
                            </p>
                        </div> */}
                        <div >
                            <button className="btn btn-primary me-2" onClick={handleback} >Back</button>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        </>
    )
}

export default Success