import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import Infoicon from "../resetpassword/info.svg";
import Staricon from "../resetpassword/Star.svg";
import "./User.css";

import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HTTPService from "../../utils/axios";
import AddModal from "../common/modal/AddModal";
import AppContext from "../Context/AppContext";
import useHttp from "../Hooks/useHttp";
import { passwordPattern } from "../../utils/helperFunctions";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import Icon from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { customToast } from "../../App";
export default function AddUser() {
	const { invokeApi } = HTTPService();
	const [openAddModal, setOpenAddModal] = useState(false);
	const { setCircularLoading } = useContext(AppContext);
	const API = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem("token");
	const navigate = useNavigate();
	const [otp, setOtp] = useState("");
	const [emailOtp, setEmailOtp] = useState("");
	const [formState, setFormState] = useState(1);
	const [form, setForm] = useState({});
	const [errors, setErrors] = useState({});
	const [valids, setValids] = useState({});
	const { GetRequest, PostRequest } = useHttp();

	const [passwordIcon, setPasswordIcon] = useState(eyeOff);
	const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(eyeOff);
	const [passwordType, setPasswordType] = useState("password");
	const [confirmPasswordType, setConfirmPasswordType] = useState("password");
	const handlePasswordToggle = () => {
		if (passwordType === "password") {
			setPasswordIcon(eye);
			setPasswordType("text");
		} else {
			setPasswordIcon(eyeOff);
			setPasswordType("password");
		}
	};

	const handleConfirmPasswordToggle = () => {
		if (confirmPasswordType === "password") {
			setConfirmPasswordIcon(eye);
			setConfirmPasswordType("text");
		} else {
			setConfirmPasswordIcon(eyeOff);
			setConfirmPasswordType("password");
		}
	};
	const [phValue, setPhValue] = useState();
	const handleOnChange = (value, country) => {
		const phoneNumberWithoutCode = value
			.replace(`${country.dialCode}`, "")
			.trim();
		setPhoneNumber(
			"phone_number",
			phoneNumberWithoutCode,
			`+${country.dialCode}`
		);
		setPhValue({ value });

		// setCountryCode("Country_Code", country.dialCode);
		// console.log("PH--->>>", phValue, form);
	};

	//Check Username
	const setUsername = async (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please enter username",
			});
		} else {
			const { data, error } = await GetRequest(
				API + "check_user_exists/?username=" + value,
				{},
				{
					"Content-Type": "multipart/form-data",
				}
			);
			if (data && data.results && data.results[0].status === true) {
				setErrors({
					...errors,
					username: "Username already registered",
				});
				setValids({
					...valids,
					[field]: false,
				});
			} else {
				setValids({
					...valids,
					[field]: true,
				});
				setErrors({
					...errors,
					[field]: null,
				});
			}
		}
	};

	//First Name
	const setFirstName = async (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please enter first name",
			});
			setValids({
				...valids,
				[field]: false,
			});
		} else {
			setValids({
				...valids,
				[field]: true,
			});
			setErrors({
				...errors,
				[field]: null,
			});
		}
	};

	//Last name
	const setLastName = async (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please enter last name",
			});
			setValids({
				...valids,
				[field]: false,
			});
		} else {
			setValids({
				...valids,
				[field]: true,
			});
			setErrors({
				...errors,
				[field]: null,
			});
		}
	};

	//Check Gender
	const setGender = async (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please select gender",
			});
			setValids({
				...valids,
				[field]: false,
			});
		} else {
			setValids({
				...valids,
				[field]: true,
			});
			setErrors({
				...errors,
				[field]: null,
			});
		}
	};

	//Country Code
	const setCountryCode = async (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please enter Country Code",
			});
			setValids({
				...valids,
				[field]: false,
			});
		} else {
			setValids({
				...valids,
				[field]: true,
			});
			setErrors({
				...errors,
				[field]: null,
			});
		}
	};

	//Phone Number
	const setPhoneNumber = async (field, value, Country_Code) => {
		setForm({
			...form,
			[field]: value,
			'Country_Code': Country_Code
		});

		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please enter phone number",
			});
		} else {
			const { data, error } = await GetRequest(
				API + "check_user_exists/?phone_number=" + form.Country_Code + value,
				{},
				{
					"Content-Type": "multipart/form-data",
				}
			);
			if (data && data.results && data.results[0].status === true) {
				setErrors({
					...errors,
					phone_number: "Phone number already registered",
				});
				setValids({
					...valids,
					[field]: false,
				});
			} else {
				setValids({
					...valids,
					[field]: true,
				});
				setErrors({
					...errors,
					[field]: null,
				});
			}
		}
	};

	//Email
	const setEmail = async (field, value) => {
		setForm({
			...form,
			[field]: value.toLowerCase(),
		});

		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please enter Email",
			});
		} else {
			const { data, error } = await GetRequest(
				API + "check_user_exists/?email=" + value.toLowerCase(),
				{},
				{
					"Content-Type": "multipart/form-data",
				}
			);
			if (data && data.results && data.results[0].status === true) {
				setErrors({
					...errors,
					email: "Email already registered",
				});
				setValids({
					...valids,
					[field]: false,
				});
			} else {
				setValids({
					...valids,
					[field]: true,
				});
				setErrors({
					...errors,
					[field]: null,
				});
			}
		}
	};

	//Password
	const setPassword = async (field, value) => {
		setForm({
			...form,
			[field]: value,
		});
		if (!passwordPattern.test(value)) {
			setErrors({
				...errors,
				[field]: "Password does not meet the criteria.",
			});
		} else {
			setValids({
				...valids,
				[field]: true,
			});
			setErrors({
				...errors,
				[field]: null,
			});
		}
		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please enter password",
			});
		}
	};
	//Confirm Password
	const setConfirmPassword = async (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		if (form.password === value) {
			setValids({
				...valids,
				[field]: true,
			});
			setErrors({
				...errors,
				[field]: null,
			});
		} else {
			setErrors({
				...errors,
				[field]: "Password Confirmation Failed",
			});
		}
		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please enter confirm password",
			});
		}
	};

	//Check Basic Information Validity
	const checkBasicInformationValidity = () => {
		const {
			first_name,
			last_name,
			gender,
			username,
			email,
			Country_Code,
			phone_number,
			password,
			confirm_password,
		} = form;

		const basicInformationErrors = {};

		// first_name errors
		if (!first_name || first_name === "")
			basicInformationErrors.first_name = "Please enter first name";

		// last_name errors
		if (!last_name || last_name === "")
			basicInformationErrors.last_name = "Please enter last name";

		// username errors
		if (!username || username === "")
			basicInformationErrors.username = "Please enter username";

		// email errors
		if (!email || email === "")
			basicInformationErrors.email = "Please enter email";

		// Country_Code errors
		if (!Country_Code || Country_Code === "")
			basicInformationErrors.Country_Code = "Please select country code";

		// phone_number errors
		if (!phone_number || phone_number === "")
			basicInformationErrors.phone_number = "Please enter phone number";

		if (!gender || gender === "") {
			basicInformationErrors.gender = "Please enter gender";
		}

		// password errors
		if (!password || password === "")
			basicInformationErrors.password = "Please enter password";

		//  if (!validatePass()) {
		//  	basicInformationErrors.password = "Password does not meet the criteria.";
		//  }

		// password errors
		if (!confirm_password || confirm_password === "")
			basicInformationErrors.confirm_password = "Please enter confirm password";
		//  if (!validateConfirmPass()) {
		//  	basicInformationErrors.confirm_password =
		//  		"Confirmation password is not match";
		//  }

		//password confirmation errors
		if (
			password &&
			password !== "" &&
			confirm_password &&
			confirm_password !== "" &&
			password !== confirm_password
		)
			basicInformationErrors.confirm_password = "Password confirmation failed";

		return basicInformationErrors;
	};

	//Send Phone OTP
	const sendPhoneOtp = async () => {
		const { phone_number, Country_Code } = form;
		const phnumber = Country_Code + phone_number;

		const { data, error } = await PostRequest(
			API + "verify/phone/",
			{
				phone_number: phnumber,
			},
			{
				Authorization: "Bearer  " + token,
				"Content-Type": "multipart/form-data",
			}
		);
		if (error) {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {

		}
	};
	const sendVerificationEmail = async () => {
		const { email } = form;
		const { data, error } = await PostRequest(
			API + "send-email-otp/",
			{
				email: email,
			},
			{
				Authorization: "Bearer  " + token,
				"Content-Type": "multipart/form-data",
			}
		);
		if (error) {
			// customToast.error("Something went wrong!", {
			// 	position: toast.POSITION.TOP_RIGHT,
			// 	autoClose: 1000,
			// });
		} else {

		}
	};
	//Save Basic Information
	const saveBasicInformation = () => {
		const basicInformationErrors = checkBasicInformationValidity();
		if (Object.keys(basicInformationErrors).length > 0) {
			setErrors(basicInformationErrors);
		} else {
			if (!!form["Phone_Verfication"])
				setForm({
					...form,
					Phone_Verfication: false,
				});
			if (!!form["email_Verfication"])
				setForm({
					...form,
					email_Verfication: false,
				});
			setOtp("");
			setEmailOtp("");
			sendVerificationEmail();
			sendPhoneOtp();
			setFormState(2);
		}
	};

	//Verify Phone OTP
	const verifyPhoneOtp = async (e) => {
		e.preventDefault();
		const { phone_number, Country_Code } = form;
		const phnumber = Country_Code + phone_number;
		const { data, error } = await PostRequest(
			API + "verify/code/",
			{
				phone_number: phnumber.toString(),
				verification_code: otp.toString(),
			},
			{
				Authorization: "Bearer  " + token,
				"Content-Type": "multipart/form-data",
			}
		);
		if (error) {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
			setErrors({
				...errors,
				Phone_Verfication: "Invalid OTP",
			});
		} else {
			setForm({
				...form,
				Phone_Verfication: true,
			});
			if (!!errors["Phone_Verfication"])
				setErrors({
					...errors,
					Phone_Verfication: null,
				});
		}
	};

	//Verify Email OTP
	const verifyEmailOtp = async (e) => {
		e.preventDefault();
		const { email } = form;
		const { data, error } = await PostRequest(
			API + "verify-email-otp/",
			{
				email: email,
				verification_code: emailOtp,
			},
			{
				Authorization: "Bearer  " + token,
				"Content-Type": "multipart/form-data",
			}
		);
		if (error) {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
			setErrors({
				...errors,
				email_Verfication: "Invalid OTP",
			});
		} else {
			setForm({
				...form,
				email_Verfication: true,
			});
			if (!!errors["email_Verfication"])
				setErrors({
					...errors,
					email_Verfication: null,
				});
		}
	};
	const checkVerificationValidity = () => {
		const { email_Verfication, Phone_Verfication } = form;

		const verificationErrors = {};

		// Phone OTP errors
		if (
			!Phone_Verfication ||
			Phone_Verfication === false ||
			Phone_Verfication === null
		)
			verificationErrors.Phone_Verfication = "Please verify phone number";

		// Email OTP errors
		if (
			!email_Verfication ||
			email_Verfication === false ||
			email_Verfication === null
		)
			verificationErrors.email_Verfication = "Please verify email";

		return verificationErrors;
	};

	const saveVerificationInformation = () => {
		const verificationErrors = checkVerificationValidity();
		if (Object.keys(verificationErrors).length > 0) {
			setErrors(verificationErrors);
		} else {
			setFormState(3);
		}
	};

	const setProfilePic = async (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please Upload Profile Picture",
			});
			setValids({
				...valids,
				[field]: false,
			});
		} else {
			setValids({
				...valids,
				[field]: true,
			});
			setErrors({
				...errors,
				[field]: null,
			});
		}
	};

	const setShortDesc = async (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please Enter a short description",
			});
			setValids({
				...valids,
				[field]: false,
			});
		} else {
			setValids({
				...valids,
				[field]: true,
			});
			setErrors({
				...errors,
				[field]: null,
			});
		}
	};
	const checkProfileValidity = () => {
		const { display_pic, Short_Description } = form;

		const profileErrors = {};

		// display_pic errors
		if (!display_pic || display_pic === "" || display_pic.size === 0)
			profileErrors.display_pic = "Please Upload Profile Picture";

		// Short_Description errors
		if (!Short_Description || Short_Description === "")
			profileErrors.Short_Description = "Please Enter a short description";

		return profileErrors;
	};

	const finalSubmit = async () => {
		setOpenAddModal(false);
		setCircularLoading(true);
		const param = {
			...form,
			phone_number: form.Country_Code + form.phone_number,
			user_status: "active",
			is_deleted: false,
		};

		invokeApi({
			method: "POST",
			url: "sub_admin/",
			data: param,
		})
			.then((data) => {
				setCircularLoading(false);
				navigate("/admin/users");
			})
			.catch((error) => {
				setCircularLoading(false);
				const flattenedErrors = typeof error.data == 'object' ? Object.keys(error.data)
					.map(key => key.replaceAll('_', ' ').toUpperCase() + ": " + error.data[key].join('\n'))
					.join('\n') : undefined;
				customToast.error(flattenedErrors ?? "Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
				});
			});
	};

	const saveProfileInformation = () => {
		const profileErrors = checkProfileValidity();
		if (Object.keys(profileErrors).length > 0) {
			setErrors(profileErrors);
		} else {
			console.log("submit");
			finalSubmit();
		}
	};

	const Breadcrumbname = {
		Home: "/admin/home",
		Managment: "/admin/users",
		User: "/admin/users",
		"Add User": "/admin/user/add",
	};
	return (
		<>
			<div className="container-fluid py-4" style={{ height: "135vh" }}>
				<div className="row">
					<div className="col-lg-12 col-12 m-auto ps-3">
						<Breadcrumb names={Breadcrumbname} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="multisteps-form mb-5">
							<div className="row">
								<div className="col-12 col-lg-10 mx-auto my-5 ">
									<div className="multisteps-form__progress">
										{formState >= 1 ? (
											<button
												className="multisteps-form__progress-btn js-active"
												type="button"
												title="User Info"
											>
												User Info
											</button>
										) : (
											<button
												className="multisteps-form__progress-btn"
												type="button"
												title="User Info"
											>
												User Info
											</button>
										)}

										{formState >= 2 ? (
											<button
												className="multisteps-form__progress-btn js-active"
												type="button"
												title="Socials"
											>
												Verification
											</button>
										) : (
											<button
												className="multisteps-form__progress-btn"
												type="button"
												title="Verification"
											>
												Verification
											</button>
										)}
										{formState === 3 ? (
											<button
												className="multisteps-form__progress-btn js-active"
												type="button"
												title="Profile"
											>
												Profile
											</button>
										) : (
											<button
												className="multisteps-form__progress-btn"
												type="button"
												title="Profile"
											>
												Profile
											</button>
										)}
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12 col-lg-10 m-auto">
									<Form className="multisteps-form__form mb-8">
										{formState === 1 ? (
											<div
												className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
												data-animation="FadeIn"
											>
												<h5 className="font-weight-bolder mb-0">New User</h5>
												<div className="multisteps-form__content">
													<div className="row mt-3">
														<Form.Group
															as={Col}
															md="6"
															controlId="validationCustom03"
														>
															<Form.Label label="Password">
																User Name
															</Form.Label>
															<Form.Control
																className="multisteps-form__input"
																type="text"
																name="username"
																placeholder="Enter Researcher Name"
																// defaultValue={form.username}
																//
																value={form && form.username && form.username}
																onChange={(e) =>
																	setUsername("username", e.target.value)
																}
																required
																isInvalid={!!errors.username}
																isValid={
																	valids.username && valids.username === true
																}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.username}
															</Form.Control.Feedback>
														</Form.Group>

														<Form.Group
															as={Col}
															md="6"
															controlId="validationCustom03"
														>
															<Form.Label label="Password">
																First Name
															</Form.Label>
															<Form.Control
																className="multisteps-form__input"
																type="text"
																name="first_name"
																placeholder="Enter First Name"
																value={
																	form && form.first_name && form.first_name
																}
																onChange={(e) =>
																	setFirstName("first_name", e.target.value)
																}
																required
																isInvalid={!!errors.first_name}
																isValid={
																	valids.first_name &&
																	valids.first_name === true
																}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.first_name}
															</Form.Control.Feedback>
														</Form.Group>
													</div>
													<div className="row mt-3">
														<Form.Group
															as={Col}
															md="6"
															controlId="validationCustom03"
														>
															<Form.Label label="Password">
																Last Name
															</Form.Label>
															<Form.Control
																className="multisteps-form__input"
																type="text"
																name="last_name"
																placeholder="Enter Last Name"
																value={form && form.last_name && form.last_name}
																onChange={(e) =>
																	setLastName("last_name", e.target.value)
																}
																required
																isInvalid={!!errors.last_name}
																isValid={
																	valids.last_name && valids.last_name === true
																}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.last_name}
															</Form.Control.Feedback>
														</Form.Group>

														<Form.Group
															as={Col}
															md="6"
															controlId="validationCustom03"
														>
															<Form.Label label="Gender">Gender</Form.Label>
															<Form.Select
																name="gender"
																className="multisteps-form__input"
																value={form && form.gender && form.gender}
																onChange={(e) =>
																	setGender("gender", e.target.value)
																}
																required
																isInvalid={!!errors.gender}
																isValid={
																	valids.gender && valids.gender === true
																}
															>
																<option value="">-- Select Gender --</option>
																<option value="Male">Male</option>
																<option value="Female">Female</option>
															</Form.Select>
															<Form.Control.Feedback type="invalid">
																{errors.gender}
															</Form.Control.Feedback>
														</Form.Group>
													</div>
													<div className="row mt-3">
														<Form.Group as={Col} md="6">
															<Form.Label htmlFor="">
																Phone Number
																<span style={{ color: "red" }}>*</span>
															</Form.Label>
															<div style={{ width: "100%" }}>
																<PhoneInput
																	country={"91"}
																	placeholder="Enter phone number"
																	value={phValue?.value ?? ""}
																	onChange={(value, country) =>
																		handleOnChange(value, country)
																	}
																	inputStyle={{ width: "100%" }}
																/>
															</div>
															{/* <Form.Label>ISO</Form.Label>
															<Form.Control
																className="multisteps-form__input shadow-none"
																as="select"
																value={
																	form && form.Country_Code && form.Country_Code
																}
																onChange={(e) =>
																	setCountryCode("Country_Code", e.target.value)
																}
																isInvalid={!!errors.Country_Code}
																isValid={
																	valids.Country_Code &&
																	valids.Country_Code === true
																}
															>
																<option value="">--</option>
																<option value="+91">IN</option>
																<option value="+1">US</option>
																<option value="+2">UK</option>
															</Form.Control>
															<Form.Control.Feedback type="invalid">
																{errors.Country_Code}
															</Form.Control.Feedback>
														</Form.Group>

														<Form.Group as={Col} md="5">
															<Form.Label>Number</Form.Label>
															<Form.Control
																className="multisteps-form__input shadow-none"
																type="number"
																placeholder="Enter number"
																value={
																	form && form.phone_number && form.phone_number
																}
																onChange={(e) =>
																	setPhoneNumber("phone_number", e.target.value)
																}
																isInvalid={!!errors.phone_number}
																isValid={
																	valids.phone_number &&
																	valids.phone_number === true
																}
															/> */}
															<Form.Control.Feedback type="invalid">
																{errors.phone_number}
															</Form.Control.Feedback>
														</Form.Group>

														<Form.Group
															as={Col}
															md="6"
															controlId="validationCustom03"
														>
															<Form.Label label="email">Email</Form.Label>
															<Form.Control
																className="multisteps-form__input"
																type="email"
																name="email"
																placeholder="Enter Email"
																value={form && form.email && form.email}
																onChange={(e) =>
																	setEmail("email", e.target.value)
																}
																required
																isInvalid={!!errors.email}
																isValid={valids.email && valids.email === true}
															/>

															<Form.Control.Feedback type="invalid">
																{errors.email}
															</Form.Control.Feedback>
														</Form.Group>
													</div>
													<div className="row mt-0">
														<Form.Group
															as={Col}
															md="6"
															controlId="validationCustom03"
														>
															<Form.Label label="password">Password</Form.Label>
															<div style={{ position: 'relative' }}>
																<Form.Control
																	className="multisteps-form__input"
																	type={passwordType}
																	name="password"
																	placeholder="******"
																	value={form && form.password && form.password}
																	onChange={(e) =>
																		setPassword("password", e.target.value)
																	}
																	required
																	isInvalid={!!errors.password}
																	isValid={
																		valids.password && valids.password === true
																	}
																/>
																<span
																	className="flex justify-around items-center position-absolute"
																	style={{ top: '5px', right: '40px' }}
																	onClick={handlePasswordToggle}
																>
																	<Icon
																		className="absolute mr-10"
																		icon={passwordIcon}
																		size={25}
																	/>
																</span>
															</div>
															{errors.password && (
																<div
																	className="invalid-feedback"
																	style={{ display: "block" }}
																>
																	{errors.password}
																</div>
															)}
														</Form.Group>

														<Form.Group
															as={Col}
															md="6"
															controlId="validationCustom03"
														>
															<Form.Label label="password">
																Confirm Password
															</Form.Label>
															<div style={{ position: 'relative' }}>
																<Form.Control
																	className="multisteps-form__input"
																	type={confirmPasswordType}
																	name="confirm_password"
																	placeholder="******"
																	value={
																		form &&
																		form.confirm_password &&
																		form.confirm_password
																	}
																	onChange={(e) =>
																		setConfirmPassword(
																			"confirm_password",
																			e.target.value
																		)
																	}
																	isInvalid={!!errors.confirm_password}
																	required
																	isValid={
																		valids.confirm_password &&
																		valids.confirm_password === true
																	}
																/>
																<span
																	className="flex justify-around items-center position-absolute"
																	style={{ top: '5px', right: '40px' }}
																	onClick={handleConfirmPasswordToggle}
																>
																	<Icon
																		className="absolute mr-10"
																		icon={confirmPasswordIcon}
																		size={25}
																	/>
																</span>
															</div>
															{errors.confirm_password && (
																<div
																	className="invalid-feedback"
																	style={{ display: "block" }}
																>
																	{errors.confirm_password}
																</div>
															)}
														</Form.Group>
													</div>
													<div className="suggestion-pass mt-4">
														<ul className="list-unstyled">
															<li>
																<div className="d-flex">
																	<img
																		className="info-icon"
																		src={Infoicon}
																		alt="info-icon"
																	/>
																	<strong>
																		Suggestions for a Strong Password:
																	</strong>
																</div>
															</li>
															<li>
																<div className="star mt-2 ms-4">
																	<img
																		className="info-icon"
																		src={Staricon}
																		alt="info-icon"
																	/>
																	<p className="m-0">
																		A Combination of Upper and Lowercase
																		Letters, Numbers & Special Characters
																		(Allowed One Only )
																	</p>
																</div>
															</li>
															<li>
																<div className="star mt-2 ms-4">
																	<img
																		className="info-icon"
																		src={Staricon}
																		alt="info-icon"
																	/>
																	<p className="m-0">
																		At least 8 Characters or More
																	</p>
																</div>
															</li>
														</ul>
													</div>
													<div className="button-row d-flex mt-4">
														<button
															className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
															type="button"
															title="Next"
															onClick={saveBasicInformation}
														>
															Next
														</button>
													</div>
												</div>
											</div>
										) : (
											""
										)}

										{formState === 2 ? (
											<div
												className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
												data-animation="FadeIn"
											>
												<h5 className="font-weight-bolder">
													Authenticate User Account
												</h5>
												<div className="multisteps-form__content">
													<div className="row mt-5 m-auto">
														<div className="col-lg-3 col-12 text-center d-flex align-items-center justify-content-end">
															<div className=" px-0 ">
																<i
																	style={{ color: "#7F56D9" }}
																	className="fa-solid fa-phone bg-white border border-dark p-4 rounded-circle"
																></i>
															</div>
														</div>
														<div className="col-lg-3 col-12 text-center verify-text">
															<h5 style={{ color: "#7F56D9" }}>
																Verify Your Phone
															</h5>
															<p className="my-2">
																Please enter the 6 digit code send to
															</p>
															<h6>
																{form.Country_Code +
																	form.phone_number.replace(/.(?=.{2})/g, "x")}
															</h6>
														</div>
														<div className="col-lg-6 col-12">
															<div className="d-flex align-items-center">
																<OtpInput
																	value={otp}
																	onChange={setOtp}
																	numInputs={6}
																	inputStyle="form-control w-100 me-4 px-0"
																	renderInput={(props) => (
																		<input
																			disabled={
																				form.Phone_Verfication &&
																					form.Phone_Verfication
																					? true
																					: false
																			}
																			{...props}
																		/>
																	)}
																/>

																<button
																	type="button"
																	name="submit"
																	className="btn bg-gradient-dark mb-0"
																	id="verifybtn"
																	onClick={(e) => verifyPhoneOtp(e)}
																	disabled={
																		form.Phone_Verfication &&
																			form.Phone_Verfication
																			? true
																			: false
																	}
																>
																	{form.Phone_Verfication &&
																		form.Phone_Verfication
																		? "Verified"
																		: "Verify"}
																</button>
															</div>
															<div className="text-danger">
																<small>
																	<em>
																		{errors.Phone_Verfication &&
																			errors.Phone_Verfication}
																	</em>
																</small>
															</div>
														</div>
													</div>

													<div className="row mt-5 mb-3 m-auto">
														<div className="col-lg-3 col-12 text-start d-flex align-items-center justify-content-end">
															<div className=" px-0 ">
																<i
																	style={{ color: "#7F56D9" }}
																	className="fa-solid fa-envelope bg-white border border-dark p-4 rounded-circle"
																></i>
															</div>
														</div>
														<div className="col-lg-3 col-12 text-center verify-text">
															<h5 style={{ color: "#7F56D9" }}>
																Verify Your email
															</h5>
															<p className="my-2">
																Please enter the 6 digit code send to
															</p>
															<h6>{form.email.replaceAll(/.(?=.{4})/g, "x")}</h6>
														</div>
														<div className="col-lg-6 col-12">
															<div className="d-flex align-items-center">
																<OtpInput
																	value={emailOtp}
																	onChange={setEmailOtp}
																	numInputs={6}
																	inputStyle="form-control w-100 me-4 px-0"
																	renderInput={(props) => (
																		<input
																			disabled={
																				form.email_Verfication &&
																					form.email_Verfication
																					? true
																					: false
																			}
																			{...props}
																		/>
																	)}
																/>
																<button
																	type="button"
																	name=""
																	className="btn bg-gradient-dark mb-0"
																	id="verifybtn"
																	onClick={(e) => verifyEmailOtp(e)}
																	disabled={
																		form.email_Verfication &&
																			form.email_Verfication
																			? true
																			: false
																	}
																>
																	{form.email_Verfication &&
																		form.email_Verfication
																		? "Verified"
																		: "Verify"}
																</button>
															</div>
															<div className="text-danger">
																<small>
																	<em>
																		{errors.email_Verfication &&
																			errors.email_Verfication}
																	</em>
																</small>
															</div>
														</div>
													</div>

													<div className="row">
														<div className="button-row d-flex mt-4 col-12">
															<button
																className="btn bg-gradient-dark mb-0 js-btn-prev"
																type="button"
																title="Prev"
																onClick={(e) => {
																	setFormState(1);
																}}
															>
																Prev
															</button>
															<button
																className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
																type="button"
																title="Next"
																onClick={saveVerificationInformation}
															>
																Next
															</button>
														</div>
													</div>
												</div>
											</div>
										) : (
											""
										)}
										{formState === 3 ? (
											<div
												className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
												data-animation="FadeIn"
											>
												<h5 className="font-weight-bolder">Profile</h5>
												<div className="multisteps-form__content mt-3">
													<div className="row">
														<Form.Group as={Col} md="12">
															<Form.Label label="Password">
																Upload Profile Image
															</Form.Label>
															<Form.Control
																className="multisteps-form__input"
																type="file"
																name="profileimg"
																onChange={(e) =>
																	setProfilePic(
																		"display_pic",
																		e.target.files[0]
																	)
																}
																isInvalid={!!errors.display_pic}
																isValid={
																	valids.display_pic &&
																	valids.display_pic === true
																}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.display_pic}
															</Form.Control.Feedback>
														</Form.Group>

														<Form.Group className="mt-2" as={Col} md="12">
															<Form.Label label="Password">
																Short Description
															</Form.Label>
															<Form.Control
																as="textarea"
																rows={3}
																className="multisteps-form__input"
																type="text"
																name="shortdesk"
																value={
																	form &&
																	form.Short_Description &&
																	form.Short_Description
																}
																onChange={(e) =>
																	setShortDesc(
																		"Short_Description",
																		e.target.value
																	)
																}
																isInvalid={!!errors.Short_Description}
																isValid={
																	valids.Short_Description &&
																	valids.Short_Description === true
																}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.Short_Description}
															</Form.Control.Feedback>
														</Form.Group>
													</div>
													<div className="button-row d-flex mt-4">
														<button
															className="btn bg-gradient-dark mb-0 js-btn-prev"
															type="button"
															title="Prev"
															onClick={(e) => {
																setFormState(2);
															}}
														>
															Prev
														</button>
														<button
															className="btn bg-gradient-dark ms-auto mb-0"
															type="button"
															title="Send"
															onClick={() => setOpenAddModal(true)}
														>
															Submit
														</button>
													</div>
												</div>
											</div>
										) : (
											""
										)}
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AddModal
				cancelBtn={"Cancel"}
				submitBtn={"SUBMIT"}
				createAddTopic={"Add Sub-Admin"}
				confirmText={"Add The Sub-Admin"}
				isOpen={openAddModal}
				onSubmit={saveProfileInformation}
				onClose={() => setOpenAddModal(false)}
			/>
		</>
	);
}
