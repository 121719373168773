import React, { useContext, useEffect, useState } from 'react'
import Header from './components/Header'
import HTTPService from '../utils/axios';
import { Table } from 'react-bootstrap';
import ProfileImage from '../components/ProfileImage';
import AppContext from '../BackEnd/Context/AppContext';
import CircularLoader from '../BackEnd/CircularLoader/CircularLoader';
import moment from 'moment';

const LeaderBoardPage = () => {
    const ASSET_URL = process.env.REACT_APP_ASSET_URL;
    const { invokeApi } = HTTPService();
    const { loading, setLoading } = useContext(AppContext);
    const [leaderBoard, setLeaderBoard] = useState();
    const [leaderBoardMonth, setLeaderBoardMonth] = useState('all');
    const fetchLeaderBoard = () => {
        setLoading(true)
        let params = {}
        if (leaderBoardMonth != 'all') {
            params.month = leaderBoardMonth
        }
        invokeApi({
            method: "GET",
            url: "widget/researcher-leader-board/",
            params
        }).then((response) => {
            setLoading(false)
            setLeaderBoard(response);
        }).catch((error) => {
            setLoading(false)
            console.error("Error fetching transactions data:", error);
        });
    };

    const months = [
        { value: "all", label: 'All' },
        { value: moment().month(), label: 'last Month' },
    ];
    useEffect(() => {
        fetchLeaderBoard();
    }, []);
    useEffect(() => {
        if (leaderBoardMonth) {
            fetchLeaderBoard();
        }
    }, [leaderBoardMonth]);

    return (
        <div>
            {loading && <CircularLoader size={100} />}
            <Header />
            <div className="container mt-4">
                <h2>Leaderboard</h2>
                <h4>Total points from paid programs</h4>
                <div className="row mt-4">
                    <div className="col-9">
                        <Table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Rank</th>
                                    <th className='text-start'>Researcher Name</th>
                                    <th style={{ backgroundColor: "#F6F6F6" }}>
                                        Points
                                    </th>
                                    <th>Signal</th>
                                    <th>Impact</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leaderBoard && leaderBoard.length > 0 ?
                                    leaderBoard.map((item, index) => (

                                        <tr key={index}><td>
                                            <div className="d-flex align-items-center justify-content-center align-items-center">
                                                {index + 1}
                                            </div>
                                        </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div
                                                        style={{
                                                            height: "72px",
                                                            width: "72px",
                                                            marginRight: "8px",
                                                            overflow: 'hidden',
                                                            borderRadius: '50%'
                                                        }}
                                                    >
                                                        <ProfileImage src={ASSET_URL + item.display_pic} style={{ objectFit: 'cover' }} />
                                                    </div><div className="text-start">
                                                        <div
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "#446EAB",
                                                                fontWeight: "500",
                                                            }}
                                                        >
                                                            <span>
                                                                {item.first_name}{" "}
                                                                {item.last_name}{" "}
                                                            </span>
                                                            <span>({item.username})</span>
                                                        </div>
                                                        <div style={{ fontSize: "11px" }}>
                                                            <span>{item.summary}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td
                                                style={{
                                                    backgroundColor: "#F6F6F6",
                                                    fontWeight: "600",
                                                    color: "#446EAB",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {item.reward_points}
                                            </td>
                                            <td
                                                style={{
                                                    fontWeight: "600",
                                                    color: "#446EAB",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {!item.total_reports
                                                    ? 0
                                                    : parseInt(
                                                        item.reward_points / item.total_reports
                                                    ) ?? 0}
                                            </td>
                                            <td
                                                style={{
                                                    fontWeight: "600",
                                                    color: "#446EAB",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {!item.valid_reports
                                                    ? 0
                                                    : parseInt(
                                                        item.reward_points / item.valid_reports
                                                    ) ?? 0}
                                            </td>
                                        </tr>
                                    )) :
                                    <tr>
                                        <td colSpan="5">NO DATA</td>
                                    </tr>
                                }

                            </tbody>
                        </Table>
                    </div>
                    <div className="col-3">
                        <div>
                            <select className="form-select quSelect" value={leaderBoardMonth} onChange={e => setLeaderBoardMonth(e.target.value)}>
                                {months.map(option => <option key={option.value} value={option.value}>
                                    Period: {option.label}
                                </option>)}
                            </select>
                        </div>
                        <div className=" researcherLastSide">
                            <div className="LastSideHeading border-bottom">
                                <h5>How it Works!</h5>
                            </div>
                            <div className="LastSideLast">
                                <ul>
                                    <li>
                                        <b>Reputation</b> : Gained or lost based on report
                                        validity. <br /> Gain more based on the size of your
                                        bounty
                                    </li>
                                    <li>
                                        <b>Signal</b> : Average Reputation per report.
                                    </li>
                                    <li>
                                        <b>Impact</b> : Average Reputation per bounty
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LeaderBoardPage