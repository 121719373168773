import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import { getUserInfo } from "../../../reducers/authSlice";
import { useSelector } from "react-redux";
import walletDashboard from "../../assets/img/wallet-solid.svg";
import Chart from "react-apexcharts";
import "./researcherWallet.css";
import moment from "moment";
import useHttp from "../../Hooks/useHttp";
import HTTPService from "../../../utils/axios";
import { toast } from "react-toastify";
import { formatDate } from "../../../utils/helperFunctions";
import { Tooltip } from "@mui/material";
import { customToast } from "../../../App";

export default function ResearcherWallet() {
  const { userInfo, userAccData } = useSelector(getUserInfo);
  const API = process.env.REACT_APP_API_URL;
  const { invokeApi } = HTTPService();
  const [walletData, setWalletData] = useState({});
  const [earningData, setEarningData] = useState();
  const [type, setType] = useState("week");
  const [widgetTransaction, setWidgetTransaction] = useState();

  const fetchWidgetTransaction = () => {
    invokeApi({
      method: "GET",
      url: `widget/transactions-metrics`,
      params: {
        type
      }
    }).then((data) => {
      setWidgetTransaction(data);
    }).catch((error) => {
      console.log(error)
    });
  };

  const fetchUser = () => {
    invokeApi({
      method: "GET",
      url: `wallets/${userAccData.wallet_id}/`,
    }).then((data) => {
      setWalletData(data);
    }).catch((error) => {
      console.log(error)
    });
  };
  const fetchEarnings = () => {
    invokeApi({
      method: "GET",
      url: "payments/earnings",
      params: {
        user_id: userInfo?.user_data?.id,
      },
    }).then((res) => {
      setEarningData(res);
    }).catch((error) => {
      customToast.error("Unable to fetch earning data", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  };
  useEffect(() => {
    if (userAccData?.wallet_id) {
      fetchUser();
    }
    fetchWidgetTransaction();
    fetchEarnings();
  }, [userAccData]);

  const Breadcrumbname = {
    Home: "/researcher/home",
    Payment: "/researcher/payments/wallet",
    Wallet: "/researcher/payments/wallet",
    Dashboard: "",
  };
  const overviewBalanceOpt = {
    series: [
      {
        data: widgetTransaction?.transactions ? [...widgetTransaction.transactions.map(data => data.total_amount)] : [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: ["#363062"],
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 4,
          borderRadiusApplication: "around",
          // borderRadiusWhenStacked: "last",
          columnWidth: "30%",
          barHeight: "100%",
          distributed: false,
          rangeBarOverlap: true,
          rangeBarGroupRows: false,
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          dumbbellColors: undefined,
          isFunnel: false,
          isFunnel3d: true,
          colors: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: undefined,
              },
            ],
            backgroundBarColors: ["#EEEEEE"],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 4,
          },
          dataLabels: {
            position: "top",
            maxItems: 100,
            hideOverflowingLabels: true,

            total: {
              enabled: false,
              formatter: undefined,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontFamily: undefined,
                fontWeight: 600,
              },
            },
          },
        },
      },

      xaxis: {
        categories: widgetTransaction?.transactions ? [...widgetTransaction.transactions.map(data => data.day_value)] : [],
        tooltip: {
          x: {
            show: false,
          },
        },
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: false,
          showDuplicates: false,
          trim: false,
          minHeight: "30px",
          maxHeight: 120,

          style: {
            colors: "#4F6488",
            fontSize: "14px",
            fontWeight: 500,

            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      yaxis: {
        min: 0,
        tickAmount: 2,
        labels: {
          formatter: function (value) {
            var val = Math.abs(value);
            return `$${val}`;
          },
          style: {
            fontSize: "14px",
            fontWeight: "500",
          },
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value) {
            return `$${value}`;
          },
          title: {
            formatter: function () {
              return '';
            }
          }
        }
      },
    },
  };

  useEffect(() => {
    if (type) {
      fetchWidgetTransaction()
    }
  }, [type])
  return (
    <>
      {userAccData?.wallet_id ? (
        <div className="container-fluid py-4 pb-0">
          <div className="row">
            <div className="col-lg-6 ps-3">
              <Breadcrumb names={Breadcrumbname} />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div
                className="card p-3 d-flex flex-column"
                style={{ height: "300px" }}
              >
                <div className="row h-100">
                  <div className="col-4">
                    <div className="d-flex flex-column align-items-center">
                      <p style={{ color: "#2D2434", fontWeight: "600" }}>
                        Wallet Details
                      </p>
                      <div style={{ height: "100px" }}>
                        <img
                          style={{ height: "100%" }}
                          src={walletDashboard}
                          alt=""
                        />
                      </div>
                      <div>
                        <button
                          style={{
                            marginTop: "50px",
                            padding: "10px",
                            fontSize: "14px",
                            backgroundColor: "#7F56D9",
                            color: "#fff",
                          }}
                        >
                          Withdraw Balance
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row h-100 d-flex justify-content-between">
                      <div>
                        <div className="d-flex">
                          <span style={{ color: "#B8B9BD" }}>Name</span>
                          <Tooltip title="User's Name">
                            <i
                              className="fa fa-question-circle ms-3"
                              aria-hidden="true"
                            ></i>
                          </Tooltip>
                        </div>
                        <div>
                          <p style={{ color: "#000", fontWeight: "600" }}>
                            {`${walletData.first_name} ${walletData?.middle_name
                              ? walletData.middle_name
                              : ""
                              } ${walletData.last_name}`}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex">
                          <span style={{ color: "#B8B9BD" }}>
                            Researcher ID
                          </span>
                          <Tooltip title="Unique Researcher ID">
                            <i
                              className="fa fa-question-circle ms-3"
                              aria-hidden="true"
                            ></i>
                          </Tooltip>
                        </div>
                        <div>
                          <p style={{ color: "#000", fontWeight: "600" }}>
                            {walletData?.user?.user_id}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex">
                          <span style={{ color: "#B8B9BD" }}>Wallet ID</span>
                          <Tooltip title="Wallet ID">
                            <i
                              className="fa fa-question-circle ms-3"
                              aria-hidden="true"
                            ></i>
                          </Tooltip>
                        </div>
                        <div>
                          <p style={{ color: "#000", fontWeight: "600" }}>
                            {walletData.Wallet_kyc_id}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row h-100 d-flex justify-content-between">
                      <div>
                        <div className="d-flex">
                          <span style={{ color: "#B8B9BD" }}>
                            Wallet Balance
                          </span>
                          <Tooltip title="Total Wallet Balance">
                            <i
                              className="fa fa-question-circle ms-3"
                              aria-hidden="true"
                            ></i>
                          </Tooltip>
                        </div>
                        <div>
                          <h3 style={{ color: "#000", fontWeight: "600" }}>
                            $ {walletData.Wallet_Balance}
                          </h3>
                        </div>
                      </div>
                      <div>
                        <div className="">
                          <div>
                            <span style={{ color: "#B8B9BD", fontSize: "13px" }}>
                              Last Updated on

                            </span>
                            <Tooltip title="Last updated date of wallet Info">
                              <i
                                className="fa fa-question-circle ms-3"
                                aria-hidden="true"
                              ></i>
                            </Tooltip></div>
                          <div>
                            <b style={{ color: "#000" }}>
                              {" "}
                              {moment(walletData.updated_at).format(
                                "YYYY MMM DD"
                              )}{" "}
                            </b>
                          </div>

                        </div>
                      </div>
                      <div>
                        <div className="d-flex">
                          <span style={{ color: "#B8B9BD" }}>KYC Status</span>
                          <Tooltip title="KYC status of wallet">
                            <i
                              className="fa fa-question-circle ms-3"
                              aria-hidden="true"
                            ></i>
                          </Tooltip>
                        </div>
                        <div>
                          <p style={{ color: "#22B14C", fontWeight: "600" }}>
                            {walletData?.KYC_Status}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div
                className="card p-3 d-flex flex-column"
                style={{ height: "300px" }}
              >
                <h5 className="p-3 pb-0">Overview Balance</h5>
                <div className="row">
                  <div className="col-8">
                    <div style={{ height: "224px" }}>
                      <Chart
                        options={overviewBalanceOpt.options}
                        series={overviewBalanceOpt.series}
                        type="bar"
                        height="100%"
                      />
                    </div>
                  </div>
                  <div className="col-4" style={{ marginTop: "-28px" }}>
                    <div className="h-100 d-flex flex-column justify-content-around">
                      <div className="weekly24main" style={{ width: "87%" }}>
                        <select className="weekly24" value={type} onChange={e => setType(e.target.value)}>
                          {['week', 'month', 'year'].map((data) => {
                            return <option value={data}>{data}</option>
                          })}
                        </select>
                      </div>
                      <div className="d-flex justify-content-between align-items-center pe-3">
                        <h3 className="mb-0 " style={{ fontSize: "23px" }}>
                          $ {widgetTransaction?.total_transactions_amount}
                        </h3>
                        {/* <span style={{ color: "#37D159" }}>
                          7% <i className="fa-solid fa-caret-up"></i>
                        </span> */}
                      </div>

                      <div>
                        <div style={{ fontSize: "13px", fontWeight: "500" }}>
                          <span style={{ color: "#000" }}>
                            Last Transaction:
                            <span style={{ color: "#26B24F" }}>${widgetTransaction?.last_transaction_amount?.amount}</span>
                          </span>
                        </div>
                        <div style={{ fontSize: "13px" }}>
                          <span>Date of Transaction {formatDate(widgetTransaction?.last_transaction_amount?.created_at, 1)}</span>
                        </div>
                      </div>

                      {/* <div>
                        <div style={{ fontSize: "13px", fontWeight: "500" }}>
                          <span style={{ color: "#000" }}>
                            Last Week:
                            <span style={{ color: "#26B24F" }}>$49,500.00</span>
                          </span>
                        </div>
                        <div style={{ fontSize: "13px" }}>
                          <span>Updated on 2024-01-20</span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-3 mt-4">
              <div>
                <p
                  style={{
                    marginBottom: "4px",
                    color: "#000",
                    fontWeight: "600",
                  }}
                >
                  Available Funds:
                </p>
                <div className="card p-3">
                  <div className="d-flex align-items-baseline mb-3">
                    <div>
                      <span
                        style={{
                          color: "rgb(184, 185, 189)",
                          fontSize: "13px",
                        }}
                      >
                        Balance Available for Use
                      </span>
                    </div>
                    <Tooltip title="Funds Available in Researcher(s) Wallet">
                      <i
                        className="fa fa-question-circle ms-3"
                        aria-hidden="true"
                      ></i>
                    </Tooltip>
                  </div>

                  <div className="mb-3">
                    <h3 className="mb-0 " style={{ fontSize: "23px" }}>
                      $ {earningData?.total_wallet_balance ?? 0}
                    </h3>
                  </div>
                  <span
                    className="mb-0"
                    style={{ color: "#B8B9BD", fontSize: "13px" }}
                  >
                    Last Updated on
                    <b style={{ color: "#000" }}> 2024 Jan 20 </b>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-3 mt-4">
              <div>
                <p
                  style={{
                    marginBottom: "4px",
                    color: "#000",
                    fontWeight: "600",
                  }}
                >
                  Future Payments:
                </p>
                <div className="card p-3">
                  <div className="d-flex align-items-baseline mb-3">
                    <div>
                      <span
                        style={{
                          color: "rgb(184, 185, 189)",
                          fontSize: "13px",
                        }}
                      >
                        Payments being Cleared
                      </span>
                    </div>
                    <Tooltip title="Payments being Cleared">
                      <i
                        className="fa fa-question-circle ms-3"
                        aria-hidden="true"
                      ></i>
                    </Tooltip>
                  </div>

                  <div className="mb-3">
                    <h3 className="mb-0 " style={{ fontSize: "23px" }}>
                      $ {earningData?.payment_towards_submission ?? 0}
                    </h3>
                  </div>
                  <span
                    className="mb-0"
                    style={{ color: "#B8B9BD", fontSize: "13px" }}
                  >
                    Last Updated on
                    <b style={{ color: "#000" }}> 2024 Jan 20 </b>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-3 mt-4">
              <div>
                <p
                  style={{
                    marginBottom: "4px",
                    color: "#000",
                    fontWeight: "600",
                  }}
                >
                  Last Payment:
                </p>
                <div className="card p-3">
                  <div className="d-flex align-items-baseline mb-3">
                    <div>
                      <span
                        style={{
                          color: "rgb(184, 185, 189)",
                          fontSize: "13px",
                        }}
                      >
                        Payment Deposited
                      </span>
                    </div>
                    <Tooltip title="Payment Deposited">
                      <i
                        className="fa fa-question-circle ms-3"
                        aria-hidden="true"
                      ></i>
                    </Tooltip>
                  </div>

                  <div className="mb-3">
                    <h3 className="mb-0 " style={{ fontSize: "23px" }}>
                      $ {earningData?.payment_deposited ?? 0}
                    </h3>
                  </div>
                  <span
                    className="mb-0"
                    style={{ color: "#B8B9BD", fontSize: "13px" }}
                  >
                    Last Updated on
                    <b style={{ color: "#000" }}> 2024 Jan 20 </b>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-3 mt-4">
              <div>
                <p
                  style={{
                    marginBottom: "4px",
                    color: "#000",
                    fontWeight: "600",
                  }}
                >
                  Withdrawls:
                </p>
                <div className="card p-3">
                  <div className="d-flex align-items-baseline mb-3">
                    <div>
                      <span
                        style={{
                          color: "rgb(184, 185, 189)",
                          fontSize: "13px",
                        }}
                      >
                        Earnings Withdrawn to Date
                      </span>
                    </div>
                    <Tooltip title="Earnings Withdrawn to Date">
                      <i
                        className="fa fa-question-circle ms-3"
                        aria-hidden="true"
                      ></i>
                    </Tooltip>
                  </div>

                  <div className="mb-3">
                    <h3 className="mb-0 " style={{ fontSize: "23px" }}>
                      $ {earningData?.earnings_withdrawn ?? 0}
                    </h3>
                  </div>
                  <span
                    className="mb-0"
                    style={{ color: "#B8B9BD", fontSize: "13px" }}
                  >
                    Last Updated on
                    <b style={{ color: "#000" }}> 2024 Jan 20 </b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid py-4 pb-0">
          <div className="row">
            <div className="col-lg-6 ps-3">
              <Breadcrumb names={Breadcrumbname} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="card p-3 d-flex flex-column"
                style={{ height: "300px" }}
              >
                <div className="row h-100">
                  <div className="col-12">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p style={{ color: "#2D2434", fontWeight: "600" }}>
                        Wallet Not Created
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
