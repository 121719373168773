import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import { acceptedFileTypes } from "../../../utils/helperFunctions";
import CircularLoader from "../../CircularLoader/CircularLoader";
import AppContext from "../../Context/AppContext";
import AddModal from "../../common/modal/AddModal";
import EditPrgmSkeletonLoader from "../../components/SkeletonLoader/EditPrgmSkeletonLoader";
import { customToast } from "../../../App";
const currDate = () => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;
};
export default function ResearcherSubmissonEdit() {
  let userRoleData = {};
  const { loading, setLoading } = useContext(AppContext);
  const [circleLoading, setCircleLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [valids, setValids] = useState({});
  const [files, setFiles] = useState([]);
  const [attchFile, setAttchFile] = useState([]);
  const { id } = useParams();
  const [targetUrlInput, setTargetUrlInput] = useState([]);
  const { invokeApi } = HTTPService();
  const [programOpt, setProgramOpt] = useState();
  const [severityOpt, setSeverityOpt] = useState();
  const [priorityOpt, setPriorityOpt] = useState();
  const [lfValue, setLfValue] = useState("");
  const [lfArray, setLfArray] = useState([]);
  const [aeValue, setAeValue] = useState("");
  const [aeArray, setAeArray] = useState([]);
  const [totaValue, setTotaValue] = useState("");
  const [totaArray, setTotaArray] = useState([]);
  const navigate = useNavigate();
  const [taxonomyData, setTaxonomyData] = useState();

  async function receiveData(allData) {
    const TargetUrl = [
      allData && allData.target_url1 !== null ? allData.target_url1 : null,
      allData && allData.target_url2 !== null ? allData.target_url2 : null,
      allData && allData.target_url3 !== null ? allData.target_url3 : null,
      allData && allData.target_url4 !== null ? allData.target_url4 : null,
      allData && allData.target_url5 !== null ? allData.target_url5 : null,
    ];
    const evidenceDoc = [
      allData && allData.evidence_attachments1 !== null
        ? allData.evidence_attachments1
        : null,
      allData && allData.evidence_attachments2 !== null
        ? allData.evidence_attachments2
        : null,
      allData && allData.evidence_attachments3 !== null
        ? allData.evidence_attachments3
        : null,
      allData && allData.evidence_attachments4 !== null
        ? allData.evidence_attachments4
        : null,
      allData && allData.evidence_attachments5 !== null
        ? allData.evidence_attachments5
        : null,
    ];
    const attachDoc = [
      allData && allData.attachments_upload_doc1 !== null
        ? allData.attachments_upload_doc1
        : null,
      allData && allData.attachments_upload_doc2 !== null
        ? allData.attachments_upload_doc2
        : null,
      allData && allData.attachments_upload_doc3 !== null
        ? allData.attachments_upload_doc3
        : null,
      allData && allData.attachments_upload_doc4 !== null
        ? allData.attachments_upload_doc4
        : null,
      allData && allData.attachments_upload_doc5 !== null
        ? allData.attachments_upload_doc5
        : null,
    ];

    const TotaStrToArr =
      allData &&
        (allData.type_of_testing_allowed === null ||
          allData.type_of_testing_allowed === "")
        ? []
        : allData.type_of_testing_allowed.split(",");
    const LfStrToArr =
      allData &&
        (allData.languages_frameworks === null ||
          allData.languages_frameworks === "")
        ? []
        : allData.languages_frameworks.split(",");
    const AeStrToArr =
      allData &&
        (allData.asset_environments === null || allData.asset_environments === "")
        ? []
        : allData.asset_environments.split(",");
    const filteredTargetUrl = TargetUrl.filter((url) => url !== null);
    const filteredEvdDoc = evidenceDoc.filter((doc) => doc !== null);
    const filteredAttchDoc = attachDoc.filter((doc) => doc !== null);
    setFiles(filteredEvdDoc);
    setAttchFile(filteredAttchDoc);
    setTotaArray(TotaStrToArr);
    setLfArray(LfStrToArr);
    setAeArray(AeStrToArr);
    setTargetUrlInput(filteredTargetUrl);

    setForm({
      ...allData,
      user_type: userRoleData && userRoleData.role && userRoleData.role,
    });
  }
  const fetchSubmissionData = async () => {
    setLoading(true);
    invokeApi({
      method: "GET",
      url: `submissions/${id}`,
    })
      .then((data) => {
        setLoading(false);
        receiveData(data);
      })
      .catch((error) => {
        setLoading(false);
        const flattenedErrors = Object.keys(error.data)
          .map(
            (key) =>
              key.replaceAll("_", " ").toUpperCase() +
              ": " +
              error.data[key].join("\n")
          )
          .join("\n");
        customToast.error(flattenedErrors ?? "Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      });
  };
  //set type
  const setProgramVal = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Select Program",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set title
  const setTitle = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Title",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set description
  const setDescription = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Description",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set reproduce step
  const setStep = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Steps",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set REMEDIATION
  const setRemediation = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter remediation recommendation",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set Severity
  const setSeverity = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Select Severity",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set Priority
  const setPriority = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Select Priority",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set Vtx
  const setVtx = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!taxonomyData.includes(value)) {
      setErrors({
        ...errors,
        [field]: "Please enter valid VTX code",
      });
      setValids({
        ...valids,
        [field]: false,
      });
      return
    }
    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Vtx",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set Target
  const setTarget = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Target",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set Target Url
  const setTargetUrl = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Target Url",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  const handleAddInput = (e) => {
    e.preventDefault();
    if (targetUrlInput.length < 4) {
      setTargetUrlInput([...targetUrlInput, ""]);
    }
  };
  const deleteTargateBugUrl = (e, i) => {
    e.preventDefault();
    const newTargetUrl = [...targetUrlInput];
    newTargetUrl.splice(i, 1);
    setTargetUrlInput(newTargetUrl);
  };
  const fetchProgramlist = async () => {
    invokeApi({
      method: "GET",
      url: `programs/?program_status=approved`,
    })
      .then((data) => {
        setProgramOpt(data);
      })
      .catch((error) => {
        const flattenedErrors = error.data
          ? Object.keys(error.data)
            .map(
              (key) =>
                key.replaceAll("_", " ").toUpperCase() +
                ": " +
                error.data[key].join("\n")
            )
            .join("\n")
          : "";
        customToast.error(flattenedErrors ?? "Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      });
  };
  const handleChange = (index, event) => {
    const newInputs = [...targetUrlInput];
    newInputs[index] = event.target.value;
    setTargetUrlInput(newInputs);
  };
  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const newFiles = Array.from(selectedFiles);
    for (const file of newFiles) {
      if (file.size > 5 * 1024 * 1024) {
        return customToast.error(`File "${file.name}" exceeds the maximum upload size of 5MB.`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }
      if (!acceptedFileTypes.includes(file?.type)) {
        return customToast.error(`File ${file?.type} not supported`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }
    }
    if (files.length + newFiles.length > 5) {
      return customToast.error(`You can only upload a maximum of 5 files.`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
    }
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };
  const handleAttachFileChange = (event) => {
    const selectedAttchFiles = event.target.files;
    const newAttchFiles = Array.from(selectedAttchFiles);
    for (const file of newAttchFiles) {
      if (file.size > 5 * 1024 * 1024) {
        return customToast.error(`File "${file?.name}" exceeds the maximum upload size of 5MB.`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }
      if (!acceptedFileTypes.includes(file?.type)) {
        return customToast.error(`File ${file?.name} not supported`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }
    }
    if (attchFile.length + newAttchFiles.length > 5) {
      return customToast.error(`You can only upload a maximum of 5 files.`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
    }
    setAttchFile((prevAttchFiles) => [...prevAttchFiles, ...newAttchFiles]);
  };
  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };
  const handleRemoveAttachFile = (index) => {
    setAttchFile((prevAttchFiles) => {
      const newAttchFiles = [...prevAttchFiles];
      newAttchFiles.splice(index, 1);
      return newAttchFiles;
    });
  };
  const addLanguageFrameworks = (e) => {
    if (e.keyCode === 13 && lfValue) {
      e.stopPropagation();
      e.preventDefault();
      setLfArray([...lfArray, lfValue]);
      setLfValue("");
    } else {

    }
  };
  const addAE = (e) => {
    if (e.keyCode === 13 && aeValue) {
      e.stopPropagation();
      e.preventDefault();
      setAeArray([...aeArray, aeValue]);
      setAeValue("");
    } else {

    }
  };
  const addTota = (e) => {
    if (e.keyCode === 13 && totaValue) {
      e.stopPropagation();
      e.preventDefault();
      setTotaArray([...totaArray, totaValue]);
      setTotaValue("");
    } else {

    }
  };
  const deleteLF = (val) => {
    let focusLf = lfArray.filter((t) => t !== val);
    setLfArray(focusLf);
  };
  const deleteAe = (val) => {
    let focusAe = aeArray.filter((t) => t !== val);
    setAeArray(focusAe);
  };
  const deleteTota = (val) => {
    let focusTota = totaArray.filter((t) => t !== val);
    setTotaArray(focusTota);
  };

  const fetchSeverity = async () => {
    invokeApi({
      method: "GET",
      url: `basevalues/?title_code=Severity`,
    })
      .then((data) => {
        setSeverityOpt(data.results);
      })
      .catch((error) => {
        const flattenedErrors = Object.keys(error.data)
          .map(
            (key) =>
              key.replaceAll("_", " ").toUpperCase() +
              ": " +
              error.data[key].join("\n")
          )
          .join("\n");
        customToast.error(flattenedErrors ?? "Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      });
  };
  const fetchPriority = async () => {
    invokeApi({
      method: "GET",
      url: `basevalues/?title_code=Priority`,
    })
      .then((data) => {
        setPriorityOpt(data.results);
      })
      .catch((error) => {
        const flattenedErrors = Object.keys(error.data)
          .map(
            (key) =>
              key.replaceAll("_", " ").toUpperCase() +
              ": " +
              error.data[key].join("\n")
          )
          .join("\n");
        customToast.error(flattenedErrors ?? "Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      });
  };
  const checkallValidity = () => {
    const { program_id, submission_title, step_to_reproduce, vtx, severity, priority, target_url1, remediation_recommendation } = form;
    const allError = {};
    if (!program_id || program_id === "")
      allError.program_id = "Please select program";
    if (!submission_title || submission_title === "")
      allError.submission_title = "Please enter submisison title";
    if (!step_to_reproduce || step_to_reproduce === "")
      allError.step_to_reproduce = "Please enter step to reproduce";
    if (!severity) allError.severity = "Please enter value";
    if (!priority) allError.priority = "Please enter value";
    if (!vtx) allError.vtx = "Please enter value";
    if (totaArray.length < 1) allError.totaArray = "Please enter atleast one value";
    if (lfArray.length < 1) allError.lfArray = "Please enter atleast one value";
    if (aeArray.length < 1) allError.aeArray = "At least one target URL must be provided.";
    if (!target_url1) allError.target_url1 = "At least one target URL must be provided";

    if (!remediation_recommendation)
      allError.remediation_recommendation = "Please enter remediation recommendation"
    if (files.length < 1) allError.files = "Please upload atleast one document";

    return allError;
  };

  const updateAttachments = (form, files, attachmentKeyPrefix, count) => {
    const updatedForm = { ...form };
    for (let i = 1; i <= count; i++) {
      const key = `${attachmentKeyPrefix}${i}`;
      if (form && typeof form[key] === "string" && !files[i - 1]) {
        updatedForm[key] = "";
      } else if (form && typeof form[key] === "string") {
        delete updatedForm[key];
      } else {
        updatedForm[key] = files && files[i - 1] ? files[i - 1] : null;
      }
    }
    return updatedForm;
  };

  const finalSubmit = async () => {
    const checkError = checkallValidity();
    if (Object.keys(checkError).length > 0) {
      setErrors(checkError);
    } else {
      let updatedForm = updateAttachments(
        form,
        files,
        "evidence_attachments",
        5
      );
      updatedForm = updateAttachments(
        updatedForm,
        attchFile,
        "attachments_upload_doc",
        5
      );
      const param = {
        ...updatedForm,
        user: form?.user_details?.id,
        type_of_testing_allowed: totaArray.toString(),
        languages_frameworks: lfArray.toString(),
        asset_environments: aeArray.toString(),
        date: currDate(),
        target_url1:
          targetUrlInput && targetUrlInput[0] ? targetUrlInput[0] : '',
        target_url2:
          targetUrlInput && targetUrlInput[1] ? targetUrlInput[1] : '',
        target_url3:
          targetUrlInput && targetUrlInput[2] ? targetUrlInput[2] : '',
        target_url4:
          targetUrlInput && targetUrlInput[3] ? targetUrlInput[3] : '',
        target_url5:
          targetUrlInput && targetUrlInput[4] ? targetUrlInput[4] : '',
      };

      setCircleLoading(true);
      setOpenAddModal(false);
      invokeApi({
        method: "PATCH",
        url: `submissions/${id}/`,
        data: param,
      })
        .then((data) => {
          setCircleLoading(false);
          navigate("/researcher/submissions");
        })
        .catch((error) => {
          setCircleLoading(false);
          customToast.error(error?.data?.detail?.toString() ?? "Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        });
    }
  };
  const getVtxSubmissions = () => {
    invokeApi({
      method: "GET",
      url: `submissions-vtx`,
    }).then((data) => {
      setTaxonomyData(data.results.map(dat => dat.vtx_code));
    }).catch((error) => {
    });
  };

  useEffect(() => {
    fetchSubmissionData();
    fetchSeverity();
    fetchPriority();
    fetchProgramlist();
    getVtxSubmissions();
  }, [form.user_id]);

  const handleSubmitModal = () => {
    const checkError = checkallValidity();
    if (Object.keys(checkError).length > 0) {
      setErrors(checkError);
      customToast.error("Enter Values for mandatory fields!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    } else {
      setOpenAddModal(true);
    }
  };

  return (
    <>
      {loading ? (
        <EditPrgmSkeletonLoader layout={2} />
      ) : (
        <>
          {circleLoading && <CircularLoader size={100} />}
          <div className="container ">
            <div className="row">
              <div className="col-12 mt-4 ">
                <div className="card">
                  <div className="row ">
                    <div className="card mt-0 " id="basic-info">
                      <div className="card-header">
                        <p
                          style={{
                            color: "#000",
                            fontWeight: "600",
                            marginBottom: "0",
                            fontSize: "17px",
                          }}
                        >
                          Edit Submission
                        </p>
                      </div>
                      <div className="card-body pt-0">
                        <Form>
                          <div className="row mb-3">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label"
                                style={{ fontSize: "14px" }}
                              >
                                Select Program
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <Form.Control
                                as="select"
                                className="form-select"
                                name="choices-gender"
                                id="choices-gender"
                                value={
                                  form && form.program_id && form.program_id
                                }
                                onChange={(e) =>
                                  setProgramVal("program_id", e.target.value)
                                }
                                isInvalid={!!errors.program_id}
                                isValid={
                                  valids.program_id &&
                                  valids.program_id === true
                                }
                                disabled
                              >
                                <option value="" selected>
                                  Select Program
                                </option>
                                {programOpt &&
                                  programOpt.results &&
                                  programOpt.results.map((item, index) => (
                                    <option value={item.id}>
                                      {item.program_title} -- {item.program_id}
                                    </option>
                                  ))}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors.program_id}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="row">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                Title
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>
                              <p
                                style={{
                                  color: "#B2C3DD",
                                  marginBottom: "10px",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                }}
                              >
                                (The Title or Short Description of the
                                Vulnerability Report)
                              </p>
                              <div className=" ">
                                <Form.Control
                                  className="form-control"
                                  placeholder="Enter a Submission Title"
                                  type="text"
                                  value={
                                    form &&
                                    form.submission_title &&
                                    form.submission_title
                                  }
                                  onChange={(e) =>
                                    setTitle("submission_title", e.target.value)
                                  }
                                  isInvalid={!!errors.submission_title}
                                  isValid={
                                    valids.submission_title &&
                                    valids.submission_title === true
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.submission_title}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </div>

                          <div className="row">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                Detail Description
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={
                                  form && form.detail_description
                                    ? form.detail_description
                                    : ""
                                }
                                onChange={(e, editor) => {
                                  setDescription(
                                    "detail_description",
                                    editor.getData()
                                  );

                                  // checkValidation();
                                }}
                              />
                              {form.detail_description === " " ||
                                form.detail_description === undefined ||
                                form.detail_description === null ? (
                                <p style={{ color: "#f00", fontSize: "14px" }}>
                                  Please Enter Detail Description
                                </p>
                              ) : (
                                ""
                              )}
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                Step to Reproduce
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={
                                  form && form.step_to_reproduce
                                    ? form.step_to_reproduce
                                    : ""
                                }
                                onChange={(e, editor) =>
                                  setStep("step_to_reproduce", editor.getData())
                                }
                              />{" "}
                              {!form.step_to_reproduce && (
                                <p style={{ color: "#f00", fontSize: "14px" }}>
                                  Please Enter Value
                                </p>
                              )}
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                Remediation Recommendation
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={
                                  form && form.remediation_recommendation
                                    ? form.remediation_recommendation
                                    : ""
                                }
                                onChange={(e, editor) =>
                                  setRemediation(
                                    "remediation_recommendation",
                                    editor.getData()
                                  )
                                }
                              />
                            </Form.Group>
                            {errors?.remediation_recommendation && <div className="invalid-warning">
                              {errors.remediation_recommendation}
                            </div>}
                          </div>

                          <div className="row mt-3">
                            <Form.Group as={Col} md="6">
                              <Form.Label
                                className="form-label"
                                style={{ fontSize: "14px" }}
                              >
                                Severity{" "}
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <Form.Control
                                as="select"
                                className="form-select"
                                name="choices-gender"
                                id="choices-gender"
                                value={form && form.severity && form.severity}
                                onChange={(e) =>
                                  setSeverity("severity", e.target.value)
                                }
                                isValid={
                                  valids.severity && valids.severity === true
                                }
                              >
                                <option value="" selected>
                                  Select Severity
                                </option>
                                {severityOpt &&
                                  severityOpt[0].value_options
                                    ?.split(",")
                                    .map((item, index) => (
                                      <option value={item}>{item}</option>
                                    ))}
                              </Form.Control>
                              {errors.severity && (<div className="invalid-feedback">
                                {errors.severity}
                              </div>)}
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                              <Form.Label
                                className="form-label"
                                style={{ fontSize: "14px" }}
                              >
                                Priority{" "}
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <Form.Control
                                as="select"
                                className="form-select"
                                name="choices-gender"
                                id="choices-gender"
                                value={form && form.priority && form.priority}
                                onChange={(e) =>
                                  setPriority("priority", e.target.value)
                                }
                                isValid={
                                  valids.priority && valids.priority === true
                                }
                              >
                                <option value="" selected>
                                  Select priority
                                </option>
                                {priorityOpt &&
                                  priorityOpt[0].value_options
                                    ?.split(",")
                                    .map((item, index) => (
                                      <option value={item}>{item}</option>
                                    ))}
                              </Form.Control>
                              {errors.priority && (<div className="invalid-feedback">
                                {errors.priority}
                              </div>)}
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group as={Col} md="6">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                VTx
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>{" "}
                              </Form.Label>

                              <div className=" ">
                                <Form.Control
                                  className="form-control"
                                  placeholder="Enter VTx"
                                  type="text"
                                  value={form && form.vtx && form.vtx}
                                  onChange={(e) =>
                                    setVtx("vtx", e.target.value)
                                  }
                                  isValid={valids.vtx && valids.vtx === true}
                                />
                              </div>
                              {errors.vtx && (<div className="invalid-feedback">
                                {errors.vtx}
                              </div>)}
                              <a
                                href="/researcher/vtxtaxonomy/dashboard"
                                className="link"
                                target="_blank"
                              >
                                View Vtx Dashboard
                              </a>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                CVSS Score
                              </Form.Label>

                              <div className=" ">
                                <div
                                  className="form-control"
                                  style={{ backgroundColor: "#99D9EA" }}
                                >
                                  <p
                                    style={{
                                      marginBottom: "0",
                                      color: "#000",
                                      fontWeight: "600",
                                    }}
                                  >
                                    System Generate Calculation
                                  </p>
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                Target Title  <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <div className=" ">
                                <Form.Control
                                  className="form-control"
                                  placeholder="Enter a Target Name"
                                  type="text"
                                  value={
                                    form &&
                                    form.target_title &&
                                    form.target_title
                                  }
                                  onChange={(e) =>
                                    setTarget("target_title", e.target.value)
                                  }
                                  isValid={
                                    valids.target_title &&
                                    valids.target_title === true
                                  }
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                Target Bug URL <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <div className=" d-flex flex-column">
                                <div className="d-flex">
                                  <div style={{ width: "70%" }}>
                                    <Form.Control
                                      className="form-control"
                                      placeholder="Enter URL"
                                      type="text"
                                      value={
                                        form &&
                                        form.target_url1 &&
                                        form.target_url1
                                      }
                                      onChange={(e) =>
                                        setTargetUrl(
                                          "target_url1",
                                          e.target.value
                                        )
                                      }
                                      isValid={
                                        valids.target_url1 &&
                                        valids.target_url1 === true
                                      }
                                    />
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      onClick={handleAddInput}
                                      style={{ borderRadius: "8px" }}
                                      className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                                    >
                                      Add +
                                    </button>
                                  </div>
                                </div>
                                {targetUrlInput.map((input, index) => (
                                  <div className="d-flex mt-3 ">
                                    <div style={{ width: "70%" }}>
                                      <Form.Control
                                        className="form-control"
                                        placeholder="Enter URL"
                                        type="text"
                                        key={index}
                                        value={input}
                                        onChange={(e) => handleChange(index, e)}
                                        isValid={
                                          input && input.value !== ""
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                    <div>
                                      <button
                                        type="button"
                                        onClick={(e) =>
                                          deleteTargateBugUrl(e, index)
                                        }
                                        style={{ borderRadius: "8px" }}
                                        className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                                      >
                                        X
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {!form?.target_url1 && errors?.target_url1 && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors?.target_url1}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                          <div className="row mb-3 mt-4">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label"
                                style={{ fontSize: "14px" }}
                              >
                                Types of Testing Allowed
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <div className="form-control  languageFrameworkDiv">
                                {totaArray.map((item, index) => {
                                  return (
                                    <>
                                      <div key={index} className="LfElement">
                                        <span>{item}</span>
                                        <span
                                          onClick={(val) => deleteTota(item)}
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <i className="fa-solid fa-xmark"></i>
                                        </span>
                                      </div>
                                    </>
                                  );
                                })}

                                <input
                                  type="text"
                                  placeholder="Add Type of Testing Allowed"
                                  value={totaValue}
                                  onChange={(e) => setTotaValue(e.target.value)}
                                  onKeyDown={addTota}
                                />

                              </div>
                              {totaArray && totaArray.length < 1 && errors?.totaArray && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.totaArray}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                          <div className="row mb-3 mt-4">
                            <div className="col-6">
                              <Form.Label
                                className="form-label"
                                style={{ fontSize: "14px" }}
                              >
                                Languages & Frameworks
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <div className="form-control  languageFrameworkDiv">
                                {lfArray.map((item, index) => {
                                  return (
                                    <>
                                      <div key={index} className="LfElement">
                                        <span>{item}</span>
                                        <span
                                          onClick={(val) => deleteLF(item)}
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <i className="fa-solid fa-xmark"></i>
                                        </span>
                                      </div>
                                    </>
                                  );
                                })}
                                <input
                                  type="text"
                                  placeholder="Add Language or Framework"
                                  value={lfValue}
                                  onChange={(e) => setLfValue(e.target.value)}
                                  onKeyDown={addLanguageFrameworks}
                                />
                              </div>
                              {lfArray && lfArray.length < 1 && errors.lfArray && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.lfArray}
                                </div>
                              )}
                            </div>
                            <div className="col-6">
                              <Form.Label
                                className="form-label "
                                style={{ fontSize: "14px" }}
                              >
                                Asset Environments
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <div className="form-control  languageFrameworkDiv">
                                {aeArray.map((item, index) => {
                                  return (
                                    <>
                                      <div key={index} className="LfElement">
                                        <span>{item}</span>
                                        <span
                                          onClick={(val) => deleteAe(item)}
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <i className="fa-solid fa-xmark"></i>
                                        </span>
                                      </div>
                                    </>
                                  );
                                })}
                                <input
                                  type="text"
                                  placeholder="Add Asset Environments"
                                  value={aeValue}
                                  onChange={(e) => setAeValue(e.target.value)}
                                  onKeyDown={addAE}
                                />
                              </div>
                              {aeArray && aeArray.length < 1 && errors.aeArray && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.aeArray}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3 mt-4">
                            <div className="col-6">
                              <div className="d-flex justify-content-between">
                                <Form.Label
                                  className="form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Evidence Attachments
                                  <span
                                    style={{ color: "red", marginLeft: "3px" }}
                                  >
                                    *
                                  </span>
                                </Form.Label>
                                <p
                                  style={{
                                    marginBottom: "0",
                                    fontSize: "10px",
                                  }}
                                >
                                  Max Upload Size: 5 MB
                                </p>
                              </div>

                              <div className="form-control dotted">
                                <div className="file-upload  w-100">
                                  {files.length === 0 ? (
                                    <p>Drop File Here To Upload</p>
                                  ) : (
                                    files.map((file, index) => (
                                      <div
                                        key={index}
                                        className="d-flex justify-content-between align-items-center"
                                      >
                                        <div className="text-overflow txtWidth">
                                          {file.name || file}
                                        </div>
                                        <i
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleRemoveFile(index);
                                          }}
                                          style={{ borderRadius: "8px", cursor: 'pointer', position: 'absolute', zIndex: 1000, right: 0 }}
                                          className="fa-solid fa-trash text-primary"
                                        ></i>
                                      </div>
                                    ))
                                  )}
                                  <Form.Control
                                    type="file"
                                    multiple="true"
                                    onChange={handleFileChange}
                                  />
                                </div>
                              </div>
                              {files && files.length < 1 && errors.files && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.files}
                                </div>
                              )}
                              <div className="d-flex justify-content-between">
                                <span className="text-xxs">
                                  * File Formats Allowed (.jpg, .png, .webp,
                                  .pdf, .xls, .doc, .docx, .csv, .ppt)
                                </span>
                                <span className="text-xxs">
                                  Max File Count: 5
                                </span>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex justify-content-between">
                                <Form.Label
                                  className="form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Attachments(Upload Documents)
                                </Form.Label>
                                <p
                                  style={{
                                    marginBottom: "0",
                                    fontSize: "10px",
                                  }}
                                >
                                  Max Upload Size: 5 MB
                                </p>
                              </div>

                              <div className="form-control dotted">
                                <div className="file-upload w-100">
                                  {attchFile.length === 0 ? (
                                    <p>Drop File Here To Upload</p>
                                  ) : (
                                    attchFile.map((file, index) => (
                                      <div
                                        key={index}
                                        className="d-flex justify-content-between align-items-center"
                                      >
                                        <span className="text-overflow txtWidth">
                                          {file.name || file}
                                        </span>
                                        <i
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleRemoveAttachFile(index);
                                          }}
                                          style={{ borderRadius: "8px", cursor: 'pointer', position: 'absolute', zIndex: 1000, right: 0 }}
                                          className="fa-solid fa-trash text-primary"
                                        > </i>
                                      </div>
                                    ))
                                  )}
                                  <Form.Control
                                    type="file"
                                    onChange={handleAttachFileChange}
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <span className="text-xxs">
                                  * File Formats Allowed (.jpg, .png, .webp,
                                  .pdf, .xls, .doc, .docx, .csv, .ppt)
                                </span>
                                <span className="text-xxs">
                                  Max File Count: 5
                                </span>
                              </div>
                            </div>
                          </div>
                        </Form>

                        <div className=" d-flex justify-content-end">
                          <Link
                            to="/researcher/submissions"
                            className="btn  btn-sm float-end mt-4 mb-0 px-3"
                            style={{ backgroundColor: "#E9ECEF" }}
                          >
                            Cancel
                          </Link>
                          <button
                            type="button" onClick={() => handleSubmitModal()}
                            className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
                          >
                            UPDATE SUBMISSION
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"SUBMIT"}
        createAddTopic={"Edit Submission"}
        confirmText={"Edit The Submission"}
        isOpen={openAddModal}
        onSubmit={finalSubmit}
        onClose={() => setOpenAddModal(false)}
      />
    </>
  );
}
