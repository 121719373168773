import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserInfo, setUserInfo } from "../../reducers/authSlice";
import HTTPService from "../../utils/axios";
import { acceptedMediaFileTypes, getObjectKeys, passwordPattern } from "../../utils/helperFunctions";
import AppContext from "../Context/AppContext";
import useHttp from "../Hooks/useHttp";
import AddModal from "./modal/AddModal";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { customToast } from "../../App";

export default function Basicinfo({ userData }) {
  const isSupportEnabled = localStorage.getItem('supporttoken')
  const { invokeApi } = HTTPService();
  const dispatch = useDispatch();
  const { memberData, setMemberData, setCircularLoading } = useContext(AppContext);
  const { userId, type } = useParams();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [valids, setValids] = useState({});
  const token = localStorage.getItem("token");
  const { userInfo } = useSelector(getUserInfo)
  const API = process.env.REACT_APP_API_URL;
  const location = useLocation();

  const [phValue, setPhValue] = useState();


  const [passwordIcon, setPasswordIcon] = useState(eyeOff);
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(eyeOff);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const handlePasswordToggle = () => {
    if (passwordType === "password") {
      setPasswordIcon(eye);
      setPasswordType("text");
    } else {
      setPasswordIcon(eyeOff);
      setPasswordType("password");
    }
  };

  const handleConfirmPasswordToggle = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordIcon(eye);
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordIcon(eyeOff);
      setConfirmPasswordType("password");
    }
  };

  const handleOnChange = (value, country) => {
    const phoneNumberWithoutCode = value
      .replace(`${country.dialCode}`, "")
      .trim();
    setPhValue({ value });
    // setPhoneNumber("phone_number", phoneNumberWithoutCode);
    // setCountryCode("Country_Code", country.dialCode);
    setFormData({
      ...formData,
      Country_Code: country.dialCode,
      phone_number: phoneNumberWithoutCode,
    });
  };
  const { PatchRequest, GetRequest } = useHttp();
  useEffect(() => {
    console.log(location);
    if (userInfo?.user_data?.id) {
      if (userData && getObjectKeys(userData).length > 0) setFormData(userData);
      else fetchUserData();
    }
  }, [userData, userInfo?.user_data?.id]);

  const fetchUserData = async () => {
    let userType = "customer";
    if (location.pathname.startsWith("/researcher")) {
      userType = "researcher";
    }
    if (
      location.pathname.includes("researcher") &&
      location.pathname.includes("/admin")
    ) {
      userType = "researcher";
    }
    if (
      location.pathname.startsWith("user") &&
      location.pathname.includes("admin") &&
      location.pathname.includes("header") &&
      localStorage.getItem('role') !== 'sub_admin'

    ) {
      userType = "user";
    }
    if (
      location.pathname.startsWith("/admin/user/edit")
    ) {
      userType = "sub_admin";
    }
    if (
      location.pathname.startsWith("/admin/user/edit") &&
      localStorage.getItem('role') == 'sub_admin'
    ) {
      userType = "sub_admin";
    }
    if (
      location.pathname.startsWith("/admin/user/edit") &&
      localStorage.getItem('role') == 'admin' && location.pathname.includes('header')
    ) {
      userType = "user";
    }



    invokeApi({
      method: "GET",
      url: `${userType}/` + userId + "/",
    })
      .then((data) => {
        setFormData(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      });
  };

  const handleChange = (e) => {
    const { name, type, files, value } = e.target;
    let data = { ...formData };
    if (["display_pic", "address_proof_doc", "id_proof_doc"].includes(name)) {
      if (
        files[0] &&
        files[0].size &&
        files[0].size > 5 * 1024 * 1024
      ) {
        // Convert to bytes (10MB)
        e.target.value = '';
        return customToast.error(`File "${files[0]?.name}" exceeds the maximum upload size of 5MB.`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

      }
      if (!acceptedMediaFileTypes.includes(files[0]?.type)) {
        customToast.error(`File ${files[0]?.type} not supported`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
        e.target.value = '';
        return null
      } else {
        data[name] = files[0];
      }
    } else {
      data[name] = e.target.value;
    }
    setFormData(data);

    const field = name;
    if (['password', 'confirm_password'].includes(field)) {
      if (!passwordPattern.test(value)) {
        setErrors({
          ...errors,
          [name]: "Password does not meet the criteria."
        });
        setValids({
          ...valids,
          [name]: false,
        });
        return null
      } else {

      }
      if (data?.password != data?.confirm_password) {
        setErrors({
          ...errors,
          "password": passwordPattern.test(data?.password) ? null : false,
          ['confirm_password']: "Password and Confirm Password are not same."
        });
        setValids({
          ...valids,
          "password": passwordPattern.test(data?.password),
          ['confirm_password']: false,
        });
        return null
      } else {
        setValids({
          ...valids,
          ['password']: true,
          ['confirm_password']: true,
        });
        setErrors({
          ...errors,
          ['password']: null,
          ['confirm_password']: null,
        });
        return null

      }
    }

    if (!value || value === "") {
      setErrors({
        ...errors,
        [name]:
          field === "first_name"
            ? "Please provide First Name."
            : field === "last_name"
              ? "Please provide Last Name."
              : field === "gender"
                ? "Please provide Gender."
                : field === "username"
                  ? "Please provide username."
                  : field === "entity_name"
                    ? "Please provide entity_name."
                    : field === "email"
                      ? "Please provide email."
                      : field === "phone_number"
                        ? "Please provide phone number."
                        : field === "password"
                          ? "Please provide new password."
                          : field === "confirm_password"
                            ? "Please provide Confirm Password."
                            : null,
      });
      setValids({
        ...valids,
        [name]: false,
      });
    } else {
      setValids({
        ...valids,
        [name]: true,
      });
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleSubmit = async (type) => {
    let userType = "customer";
    if (location.pathname.startsWith("/researcher")) {
      userType = "researcher";
    }
    if (
      location.pathname.includes("researcher") &&
      location.pathname.includes("/admin")
    ) {
      userType = "researcher";
    }
    if (
      location.pathname.startsWith("user") &&
      location.pathname.includes("admin") &&
      location.pathname.includes("header") &&
      localStorage.getItem('role') !== 'sub_admin'

    ) {
      userType = "user";
    }
    if (
      location.pathname.startsWith("/admin/user/edit")
    ) {
      userType = "sub_admin";
    }
    if (
      location.pathname.startsWith("/admin/user/edit") &&
      localStorage.getItem('role') == 'sub_admin'
    ) {
      userType = "sub_admin";
    }
    if (
      location.pathname.startsWith("/admin/user/edit") &&
      localStorage.getItem('role') == 'admin' && location.pathname.includes('header')
    ) {
      userType = "user";
    }
    //  variable that will be send in payload
    let apiData = {
      ...formData,
    };

    if (typeof apiData.display_pic === "string") {
      delete apiData.display_pic;
    }
    if (typeof apiData.address_proof_doc === "string") {
      delete apiData.address_proof_doc;
    }
    if (typeof apiData.id_proof_doc === "string") {
      delete apiData.id_proof_doc;
    }

    if (type) {
      if (!formData.password) {
        customToast.error("Please enter in password field", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        setMemberData({ openmodal: false });

        return null
      }
      if (!formData.confirm_password) {
        customToast.error("Please enter in confirm password field", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        setMemberData({ openmodal: false });

        return null
      }

      if (formData.password !== formData.confirm_password) {
        customToast.error("Both password and confirm password are not same", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        setMemberData({ openmodal: false });

        return null
      }
      apiData.password = formData.password;
      apiData.confirm_password = formData.confirm_password;
    } else {
      delete apiData.password;
    }
    setMemberData({ openmodal: false });
    setCircularLoading(true);

    const authToken = isSupportEnabled != null ? isSupportEnabled : token;
    console.log(authToken)
    const { data, error } = await PatchRequest(
      API + `${userType}/` + userId + "/",
      {
        ...apiData,
      },
      {},
      {
        Authorization: "Bearer  " + authToken,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      setCircularLoading(false);
      customToast.error(error?.data?.detail ?? "Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
    if (data) {
      if (userInfo?.user_data?.id === userId) {
        let role = userType;
        if (userInfo?.role === 'admin') {
          role = 'admin'
        }
        // localStorage.setItem(
        //   "userInfo",
        //   JSON.stringify({ ...data, role })
        // );
        // dispatch(setUserInfo({ ...data, role }));
      }
      setCircularLoading(false);
      customToast.success("Data updated", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      {/* <!-- Card Basic Info --> */}

      <Form>
        <div className="card mt-0" id="basic-info">
          <div className="card-header">
            <h5>Basic Info</h5>
          </div>
          <div className="card-body pt-0">
            <div className="row">
              <Form.Group className="col-6">
                <Form.Label className="form-label">First Name</Form.Label>
                <div className="input-group">
                  <Form.Control
                    id="first_name"
                    className="form-control"
                    type="text"
                    placeholder="First Name"
                    required="required"
                    onChange={handleChange}
                    name="first_name"
                    isInvalid={!!errors.first_name}
                    isValid={valids.first_name && valids.first_name === true}
                    defaultValue={formData?.first_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="form-label">Last Name</Form.Label>
                <div className="input-group">
                  <Form.Control
                    id="last_name"
                    name="last_name"
                    className="form-control"
                    type="text"
                    placeholder="Last Name"
                    required="required"
                    isInvalid={!!errors.last_name}
                    isValid={valids.last_name && valids.last_name === true}
                    defaultValue={formData?.last_name}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
            <div className="row">
              {location.pathname.includes("researcher") && (
                <Form.Group className="col-sm-6 col-6">
                  <Form.Label className="form-label mt-4">Gender</Form.Label>
                  <Form.Select
                    className="form-control"
                    id="choices-gender"
                    name="gender"
                    isInvalid={!!errors.gender}
                    isValid={valids.gender && valids.gender === true}
                    value={formData?.gender}
                    onChange={handleChange}
                    required
                  >
                    <option
                      value="Male"
                      selected={formData?.gender === "Male" ? true : false}
                    >
                      Male
                    </option>
                    <option
                      value="Female"
                      selected={formData?.gender === "Female" ? true : false}
                    >
                      Female
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <Form.Group className="col-sm-6">
                <Form.Label className="form-label mt-4">Username</Form.Label>
                <div className="input-group ">
                  <Form.Control
                    className="form-control"
                    // placeholder="Username"
                    required="required"
                    type="text"
                    defaultValue={formData?.username}
                    name="username"
                    isInvalid={!!errors.username}
                    isValid={valids.username && valids.username === true}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              {location.pathname.includes("customer") && (
                <Form.Group className="col-sm-6 col-6">
                  <Form.Label className="form-label mt-4">
                    Entity Name
                  </Form.Label>
                  <div className="input-group ">
                    <Form.Control
                      className="form-control"
                      placeholder="Entity name"
                      type="text"
                      value={formData?.entity_name}
                      isInvalid={!!errors.entity_name}
                      isValid={
                        valids.entity_name && valids.entity_name === true
                      }
                      name="entity_name"
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.entity_name}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              )}
            </div>

            <div className="row">
              <Form.Group className="col-6">
                <Form.Label className="form-label mt-4">Email</Form.Label>
                <div className="input-group">
                  <Form.Control
                    id="email"
                    className="form-control"
                    type="email"
                    placeholder="example@email.com"
                    value={formData?.email}
                    name="email"
                    isInvalid={!!errors.email}
                    isValid={valids.email && valids.email === true}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="form-label mt-4">
                  Phone Number
                </Form.Label>
                <PhoneInput
                  country={"91"}
                  placeholder="Enter phone number"
                  value={
                    formData?.phone_number
                      ? `${formData.Country_Code}${formData.phone_number}`
                      : ""
                  }
                  name="phone_number"
                  required="required"
                  isInvalid={!!errors.phone_number && !!errors.Country_Code}
                  isValid={
                    valids.phone_number &&
                    valids.phone_number === true &&
                    valids.Country_Code === true
                  }
                  onChange={(value, country) => handleOnChange(value, country)}
                  style={{ width: "100% !important", marginTop: 4 }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone_number && errors.Country_Code}
                </Form.Control.Feedback>

              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col-sm-12">
                <Form.Label className="form-label mt-4">
                  Change Profile Image
                </Form.Label>
                <div className="input-group ">
                  <Form.Control
                    className="form-control"
                    placeholder="Username"
                    type="file"
                    name="display_pic"
                    onChange={(e) => handleChange(e)}
                    accept=".jpg,.png,.webp"
                  />
                </div>
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="col-12">
                <Form.Label className="form-label mt-4">
                  Short Description
                </Form.Label>
                <div className="input-group">
                  <textarea
                    row="3"
                    className="form-control"
                    name="Short_Description"
                    placeholder="Enter Short Description .."
                    value={formData?.Short_Description}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </Form.Group>
            </div>
            <button
              className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0"
              type="button"
              onClick={() => {
                setMemberData({
                  handlesubmit: () => handleSubmit(),
                  openmodal: true,
                  basicinfo: true,
                });
              }}
            >
              Update
            </button>
          </div>
        </div>
        {/* <!-- Card Change Password --> */}
        <div className="card mt-4" id="password">
          <div className="card-header">
            <h5>Change Password</h5>
          </div>
          <Form.Group className="card-body pt-0">
            {/* <Form.Label className="form-label">Current password</Form.Label>
            <div className="form-group">
              <Form.Control
                className="form-control"
                type="password"
                placeholder="Current password"
              />
            </div> */}
            <Form.Label className="form-label">New password</Form.Label>
            <div className="form-group position-relative">
              <Form.Control
                className="form-control"
                type={passwordType}
                placeholder="New password"
                name="password"
                isInvalid={!!errors.password}
                isValid={valids.password && valids.password === true}
                value={formData?.password}
                autoComplete="new-password"
                onChange={handleChange}
              />
              <span
                className="flex justify-around items-center position-absolute"
                style={{ top: '5px', right: '40px' }}
                onClick={handlePasswordToggle}
              >
                <Icon
                  className="absolute mr-10"
                  icon={passwordIcon}
                  size={25}
                />
              </span>
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </div>
            <Form.Label className="form-label">Confirm new password</Form.Label>
            <div className="form-group position-relative">
              <Form.Control
                className="form-control "
                type={confirmPasswordType}
                autoComplete="off"
                placeholder="Confirm password"
                name="confirm_password"
                isInvalid={!!errors.confirm_password}
                isValid={
                  valids.confirm_password && valids.confirm_password === true
                }
                value={formData?.confirm_password}
                onChange={handleChange}
              />
              <span
                className="flex justify-around items-center position-absolute"
                style={{ top: '5px', right: '40px' }}
                onClick={handleConfirmPasswordToggle}
              >
                <Icon
                  className="absolute mr-10"
                  icon={confirmPasswordIcon}
                  size={25}
                />
              </span>
              <Form.Control.Feedback type="invalid">
                {errors.confirm_password}
              </Form.Control.Feedback>
            </div>
            <h5 className="mt-5">Password requirements</h5>
            <p className="text-muted mb-2">
              Please follow this guide for a strong password:
            </p>
            <ul className="text-muted ps-4 mb-0 float-start">
              <li>
                <span className="text-sm">One special characters</span>
              </li>
              <li>
                <span className="text-sm">Min 8 characters</span>
              </li>
              <li>
                <span className="text-sm">One number (2 are recommended)</span>
              </li>
              <li>
                <span className="text-sm">Change it often</span>
              </li>
            </ul>
            <button
              className="btn bg-gradient-dark btn-sm float-end mt-6 mb-0"
              type="button"
              onClick={() => {
                setMemberData({
                  handlesubmit: () => handleSubmit("handlePassword"),
                  openmodal: true,
                  changePassword: true,
                });
              }}
            >
              Update password
            </button>
          </Form.Group>
        </div>
        {/* <!-- Card Change Password --> */}
        {/* <div className="card mt-4" id="2fa">
          <div className="card-header d-flex">
            <h5 className="mb-0">Two-factor authentication</h5>
            <span className="badge badge-success ms-auto">Enabled</span>
          </div>
          <div className="card-body">
            <div className="d-flex">
              <p className="my-auto">SMS number</p>
              <p className="text-secondary text-sm ms-auto my-auto me-3">
                {formData.phone_number}
              </p>
              <button
                className="btn btn-sm btn-outline-dark mb-0"
                type="button"
              >
                Edit
              </button>
            </div>
            <hr className="horizontal dark" />
            <div className="d-flex">
              <p className="my-auto">Authenticator app</p>
              <p className="text-secondary text-sm ms-auto my-auto me-3">
                Not Configured
              </p>
              <button
                className="btn btn-sm btn-outline-dark mb-0"
                type="button"
              >
                Setup
              </button>
            </div>
          </div>
        </div> */}
        {/* <!-- Card Accounts --> */}
        {/* <div className="card mt-4" id="accounts">
        <div className="card-header">
          <h5>Accounts</h5>
          <p className="text-sm">
            Here you can setup and manage your integration settings.
          </p>
        </div>
        <div className="card-body pt-0">
          <div className="d-flex">
            <img
              className="width-48-px"
              src="../../../assets/img/small-logos/logo-slack.svg"
              alt="logo_slack"
            />
            <div className="my-auto ms-3">
              <div className="h-100">
                <h5 className="mb-0">Slack</h5>
                <Link className="text-sm text-body" to="javascript:;">
                  Show less{" "}
                  <i
                    className="fas fa-chevron-up text-xs ms-1"
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            </div>
            <p className="text-sm text-secondary ms-auto me-3 my-auto">
              Enabled
            </p>
            <div className="form-check form-switch my-auto">
              <input
                className="form-check-input"
                checked
                type="checkbox"
                id="flexSwitchCheckDefault1"
              />
            </div>
          </div>
          <div className="ps-5 pt-3 ms-3">
            <p className="mb-0 text-sm">
              You haven't added your Slack yet or you aren't authorized. Please
              add our Slack Bot to your account by clicking on{" "}
              <Link to="javascript">here</Link>. When you've added the bot, send
              your verification code that you have received.
            </p>
            <div className="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4">
              <p className="text-sm font-weight-bold my-auto ps-sm-2">
                Verification Code
              </p>
              <input
                className="form-control form-control-sm ms-sm-auto mt-sm-0 mt-2 w-sm-15 w-40"
                type="text"
                value="1172913"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Copy!"
              />
            </div>
            <div className="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4">
              <p className="text-sm font-weight-bold my-auto ps-sm-2">
                Connected account
              </p>
              <h6 className="text-sm ms-auto me-3 my-auto">
                hello@creative-tim.com
              </h6>
              <button
                className="btn btn-sm bg-gradient-danger my-sm-auto mt-2 mb-0"
                type="button"
                name="button"
              >
                Delete
              </button>
            </div>
          </div>
          <div className="d-flex">
            <img
              className="width-48-px"
              src="../../../assets/img/small-logos/logo-atlassian.svg"
              alt="logo_atlassian"
            />
            <div className="my-auto ms-3">
              <div className="h-100">
                <h5 className="mb-0">Atlassian</h5>
                <p className="mb-0 text-sm">Payment vendor</p>
              </div>
            </div>
            <p className="text-sm text-secondary ms-auto me-3 my-auto">
              Enabled
            </p>
            <div className="form-check form-switch my-auto">
              <input
                className="form-check-input"
                checked
                type="checkbox"
                id="flexSwitchCheckDefault3"
              />
            </div>
          </div>
        </div>
      </div> */}
        {/* <!-- Card Notifications --> */}
        {/* <div className="card mt-4" id="notifications">
        <div className="card-header">
          <h5>Notifications</h5>
          <p className="text-sm">
            Choose how you receive notifications. These notification settings
            apply to the things you’re watching.
          </p>
        </div>
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th className="ps-1" colspan="4">
                    <p className="mb-0">Activity</p>
                  </th>
                  <th className="text-center">
                    <p className="mb-0">Email</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="ps-1" colspan="4">
                    <div className="my-auto">
                      <span className="text-dark d-block text-sm">
                        Mentions
                      </span>
                      <span className="text-xs font-weight-normal">
                        Notify when another formData mentions you in a comment
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                      <input
                        className="form-check-input"
                        checked
                        type="checkbox"
                        id="flexSwitchCheckDefault11"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="ps-1" colspan="4">
                    <div className="my-auto">
                      <span className="text-dark d-block text-sm">
                        Comments
                      </span>
                      <span className="text-xs font-weight-normal">
                        Notify when another formData comments your item.
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                      <input
                        className="form-check-input"
                        checked
                        type="checkbox"
                        id="flexSwitchCheckDefault14"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="ps-1" colspan="4">
                    <div className="my-auto">
                      <span className="text-dark d-block text-sm">Follows</span>
                      <span className="text-xs font-weight-normal">
                        Notify when another formData follows you.
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault17"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="ps-1" colspan="4">
                    <div className="my-auto">
                      <p className="text-sm mb-0">Log in from a new device</p>
                    </div>
                  </td>
                  <td>
                    <div className="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                      <input
                        className="form-check-input"
                        checked
                        type="checkbox"
                        id="flexSwitchCheckDefault20"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
        {/* <!-- Card Sessions --> */}
        {/* <div className="card mt-4 mb-4" id="delete">
          <div className="card-header">
            <h5>Account Status</h5>
          </div>
          <div className="card-body d-sm-flex pt-0">
            <div className="d-flex align-items-center mb-sm-0 mb-4">
              <div>
                <div className="form-check form-switch mb-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault0"
                    checked={confirmStatus}
                    onChange={() => setConfirmStatus(!confirmStatus)}
                  />
                </div>
              </div>
              <div className="ms-2">
                <span className="text-dark font-weight-bold d-block text-sm">
                  Confirm
                </span>
                <span className="text-xs d-block">
                  I want to Change My Status
                </span>
              </div>
            </div>
            <button
              className={
                changeStatus
                  ? "btn bg-gradient-danger mb-0 ms-auto"
                  : "btn bg-gradient-dark mb-0 ms-auto"
              }
              type="button"
              name="button"
              disabled={confirmStatus ? false : true}
              onClick={() => setChangeStatus(!changeStatus)}
            >
              {changeStatus ? "In-Active" : "Active"}
            </button>
          </div>
        </div> */}
      </Form>
      {memberData.basicinfo === true && (
        <AddModal
          cancelBtn={"Cancel"}
          submitBtn={"SUBMIT"}
          createAddTopic={"Update Basic Info"}
          confirmText={"Update Basic Info"}
          isOpen={memberData.openmodal}
          onSubmit={memberData.handlesubmit}
          onClose={() => setMemberData({ openmodal: false })}
        />
      )}

      {memberData.changePassword === true && (
        <AddModal
          cancelBtn={"Cancel"}
          submitBtn={"SUBMIT"}
          createAddTopic={"Update Password"}
          confirmText={"Update Password"}
          isOpen={memberData.openmodal}
          onSubmit={memberData.handlesubmit}
          onClose={() => setMemberData({ openmodal: false })}
        />
      )}
    </>
  );
}
