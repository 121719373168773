import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import faqdata from "./data/faqdata.json";
import { HashLink } from "react-router-hash-link";

import useHttp from "../BackEnd/Hooks/useHttp";

import { toast } from "react-toastify";
import { lineOverFlowDot } from "../utils/helperFunctions";

export default function Faq() {
  const [openAccordion, setOpenAccordion] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    "59ae24e3-e192-4f25-991f-80770e553d0b"
  );
  const accordionClick = (k) => {
    setOpenAccordion(k);
    setIsOpen(!isOpen);
  };

  const { GetRequest, PutRequest, DeleteRequest } = useHttp();

  const [newfaqdata, setNewfaqdata] = useState();
  // const [error,setError]=useState(null);

  const API = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");

  const fetchFaq = async (stsId) => {
    const { data, error } = await GetRequest(
      API + "faq_questionanswer",
      {},
      {
        // Authorization: "Bearer  " + token,
        // "Content-Type": "multipart/form-data",
        // Authorization: `Bearer ${token}`,
        "Content-Type": "json/HTML",
      }
    );
    if (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      console.log(data);
      setNewfaqdata(data);
    }
  };

  useEffect(() => {
    fetchFaq();
  }, []);

  return (
    <>
      <Header />
      <section className="faq-tab mt-5">
        <div className="container">
          <div className="fawtab w-70 d-flex mx-auto justify-content-center mt-3">
            <HashLink
              // to="#BugBountyPlatform"
              className="hoverbg"
              onClick={() => {
                setSelectedTab("59ae24e3-e192-4f25-991f-80770e553d0b");
              }}
            >
              <div className="p-4 border border-left-0 d-flex align-items-center">
                <i
                  class="fa-solid fa-lg fa-bug pe-2"
                  style={{ color: "#7f56d9", fontSize: "22px" }}
                ></i>
                Bug Bounty Platform
              </div>
            </HashLink>
            <HashLink
              // to="#PenetrationTesting"
              className="hoverbg"
              onClick={() => {
                setSelectedTab("9b47827c-06b6-4590-a605-87bbd57b8fbe");
              }}
            >
              <div className="p-4 border border-right-0 d-flex align-items-center">
                <i
                  class="fa-solid fa-lg fa-person-chalkboard pe-2"
                  style={{ color: "#7f56d9", fontSize: "22px" }}
                ></i>
                Penetration Testing
              </div>
            </HashLink>
            <HashLink
              // to="#ExternalAttackSurfaceManagement"
              className="hoverbg"
              onClick={() => {
                setSelectedTab("221f29ea-cdd1-4f74-b42b-bf73391bccbc");
              }}
            >
              <div className="p-4 border border-left-0 d-flex align-items-center">
                <i
                  class="fa-solid fa-lg fa-people-roof pe-2"
                  style={{ color: "#7f56d9", fontSize: "22px" }}
                ></i>

                {lineOverFlowDot("External Attack Surface Management", 20)}
              </div>
            </HashLink>
          </div>
        </div>
      </section>

      <section className="uui-section_faq01">
        <div className="uui-page-padding-3">
          <div className="uui-container-large-3">
            <div
              className="uui-padding-vertical-xhuge-2"
              id="PenetrationTesting"
            >
              <div className="uui-text-align-center-3">
                <div className="uui-max-width-large-3 align-center">
                  <div className="uui-heading-subheading-2">FAQ</div>
                  <h2 className="uui-heading-medium-2">
                    {selectedTab == "9b47827c-06b6-4590-a605-87bbd57b8fbe"
                      ? "Penetration Testing"
                      : selectedTab == "221f29ea-cdd1-4f74-b42b-bf73391bccbc"
                      ? "External Attack Surface Management"
                      : "Bug Bounty Platform"}
                  </h2>
                  <div className="uui-space-xsmall-2"></div>
                </div>
              </div>
              {/* <div className="uui-faq01_component">
								<div className="uui-faq01_list">
									{faqdata.penetration.map((item, index) => {
										return (
											<>
												<div
													className="uui-faq01_accordion"
													onClick={() => accordionClick(item.id)}
												>
													<div
														data-w-id="f9ed9c1e-e2e1-3864-2dfb-ec87fdc5e42f"
														className="uui-faq01_question"
													>
														<div className="uui-faq01_heading">
															{item.qs}
															<br />
														</div>
														<div className="uui-faq01_icon-wrapper">
															<div className="accordion-icon_component">
																<div className="accordion-icon_horizontal-line"></div>
																<div
																	className="accordion-icon_vertical-line"
																	style={
																		isOpen === true && openAccordion === item.id
																			? {
																					transform: "rotate(90deg)",
																					transition: ".3s all",
																			  }
																			: { transition: ".3s all" }
																	}
																></div>
															</div>
														</div>
													</div>
													<div
														className={
															isOpen === true && openAccordion === item.id
																? "uui-faq01_answer d-block"
																: "uui-faq01_answer d-none"
														}
													>
														<div className="uui-text-size-medium-3">
															{item.ans}
														</div>
														<div className="uui-space-medium"></div>
													</div>
												</div>
											</>
										);
									})}
								</div>
							</div> */}

              <div className="uui-faq01_component">
                <div className="uui-faq01_list">
                  {newfaqdata &&
                    newfaqdata.results &&
                    newfaqdata.results
                      ?.filter((item) => item.sub_category_id === selectedTab)
                      .sort((a, b) => a.sequence - b.sequence)
                      .map((item, index) => {
                        return (
                          <>
                            <div
                              className="uui-faq01_accordion"
                              onClick={() => accordionClick(item.faq_qa_id)}
                            >
                              <div
                                data-w-id="f9ed9c1e-e2e1-3864-2dfb-ec87fdc5e42f"
                                className="uui-faq01_question"
                              >
                                <div className="uui-faq01_heading">
                                  {item.question}
                                  <br />
                                </div>
                                <div className="uui-faq01_icon-wrapper">
                                  <div className="accordion-icon_component">
                                    <div className="accordion-icon_horizontal-line"></div>
                                    <div
                                      className="accordion-icon_vertical-line"
                                      style={
                                        isOpen === true &&
                                        openAccordion === item.faq_qa_id
                                          ? {
                                              transform: "rotate(90deg)",
                                              transition: ".3s all",
                                            }
                                          : { transition: ".3s all" }
                                      }
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  isOpen === true &&
                                  openAccordion === item.faq_qa_id
                                    ? "uui-faq01_answer d-block"
                                    : "uui-faq01_answer d-none"
                                }
                              >
                                <div
                                  className="uui-text-size-medium-3"
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer,
                                  }}
                                ></div>
                                <div className="uui-space-medium"></div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                </div>
              </div>
            </div>

            {/* <div
							className="uui-padding-vertical-xhuge-2"
							id="BugBountyPlatform"
						>
							<div className="uui-text-align-center-3">
								<div className="uui-max-width-large-3 align-center">
									<h2 className="uui-heading-medium-2">Bug Bounty Platform</h2>
									<div className="uui-space-xsmall-2"></div>
								</div>
							</div>
							<div className="uui-faq01_component">
								<div className="uui-faq01_list">
									{faqdata.bugbounty.map((item, index) => {
										return (
											<>
												<div
													className="uui-faq01_accordion accordStyle"
													onClick={() => accordionClick(item.id)}
												>
													<div
														data-w-id="f9ed9c1e-e2e1-3864-2dfb-ec87fdc5e42f"
														className="uui-faq01_question"
													>
														<div className="uui-faq01_heading">
															{item.qs}
															<br />
														</div>
														<div className="uui-faq01_icon-wrapper">
															<div className="accordion-icon_component">
																<div className="accordion-icon_horizontal-line"></div>
																<div
																	className="accordion-icon_vertical-line"
																	style={
																		isOpen === true && openAccordion === item.id
																			? {
																					transform: "rotate(90deg)",
																					transition: ".3s all",
																			  }
																			: { transition: ".3s all" }
																	}
																></div>
															</div>
														</div>
													</div>
													<div
														className={
															isOpen === true && openAccordion === item.id
																? "uui-faq01_answer d-block"
																: "uui-faq01_answer d-none"
														}
													>
														<div className="uui-text-size-medium-3">
															<div
																dangerouslySetInnerHTML={{ __html: item.ans }}
															/>
														</div>
														<div className="uui-space-medium"></div>
													</div>
												</div>
											</>
										);
									})}
								</div>
							</div>
						</div>
						<div
							className="uui-padding-vertical-xhuge-2"
							id="ExternalAttackSurfaceManagement"
						>
							<div className="uui-text-align-center-3">
								<div className="uui-max-width-large-3 align-center">
									<h2 className="uui-heading-medium-2">
										External Attack Surface Management
									</h2>
									<div className="uui-space-xsmall-2"></div>
								</div>
							</div>
							<div className="uui-faq01_component">
								<div className="uui-faq01_list">
									{faqdata.external.map((item, index) => {
										return (
											<>
												<div
													className="uui-faq01_accordion"
													onClick={() => accordionClick(item.id)}
												>
													<div
														data-w-id="f9ed9c1e-e2e1-3864-2dfb-ec87fdc5e42f"
														className="uui-faq01_question"
													>
														<div className="uui-faq01_heading">
															{item.qs}
															<br />
														</div>
														<div className="uui-faq01_icon-wrapper">
															<div className="accordion-icon_component">
																<div className="accordion-icon_horizontal-line"></div>
																<div
																	className="accordion-icon_vertical-line"
																	style={
																		isOpen === true && openAccordion === item.id
																			? {
																					transform: "rotate(90deg)",
																					transition: ".3s all",
																			  }
																			: { transition: ".3s all" }
																	}
																></div>
															</div>
														</div>
													</div>
													<div
														className={
															isOpen === true && openAccordion === item.id
																? "uui-faq01_answer d-block"
																: "uui-faq01_answer d-none"
														}
													>
														<div className="uui-text-size-medium-3">
															<div
																dangerouslySetInnerHTML={{ __html: item.ans }}
															/>
														</div>
														<div className="uui-space-medium"></div>
													</div>
												</div>
											</>
										);
									})}
								</div>
							</div>
						</div> */}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
