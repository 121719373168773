import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../reducers/authSlice";
import jsPDF from "jspdf";
import { customToast } from "../../../App";
const scopeUrlConst = [
  "scope_items_url1",
  "scope_items_url2",
  "scope_items_url3",
  "scope_items_url4",
  "scope_items_url5",
];
const targetUrl = [
  "target_url1",
  "target_url2",
  "target_url3",
  "target_url4",
  "target_url5",
];
const attachmentsConst = [
  "attachments_upload_doc1",
  "attachments_upload_doc2",
  "attachments_upload_doc3",
  "attachments_upload_doc4",
  "attachments_upload_doc5",
  "evidence_attachments1",
  "evidence_attachments2",
  "evidence_attachments3",
  "evidence_attachments4",
  "evidence_attachments5",
];

const ProgramReport = () => {
  const { id } = useParams();
  const [program, setProgam] = useState();
  const [submissions, setSubmissions] = useState();
  const [customer, setCustomer] = useState();
  const { invokeApi } = HTTPService();
  const { userAccData, userInfo } = useSelector(getUserInfo);
  const [vtxResponses, setVtxResponses] = useState([]);
  const [vtMainList, setVtMainList] = useState({});
  const navigate = useNavigate();
  const [routeLink, setRouteLink] = useState();
  console.log("userAccData", userInfo);
  useEffect(() => {
    if (userInfo.role == "researcher") {
      setRouteLink(`/researcher/programs/${id}/submission`);
    } else if (userInfo.role == "customer") {
      setRouteLink(`/customer/program/${id}/submission`);
    } else {
      setRouteLink(`/admin/program/${id}/submission`);
    }
  }, [userInfo]);

  console.log("vtMainList", vtxResponses);
  const fetchData = async () => {
    invokeApi({
      method: "GET",
      url: `/programs/${id}`,
    })
      .then((data) => {
        setProgam(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  // const fetchSubmisisons = async () => {
  //   invokeApi({
  //     method: "GET",
  //     url: `/submissions/`,
  //     params: {
  //       program_id: id,
  //     },
  //   })
  //     .then((data) => {
  //       setSubmissions(data.results);
  //     })
  //     .catch((error) => {
  //       customToast.error("Something went wrong!", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 1000,
  //       });
  //     });
  // };
  const fetchSubmissions = async () => {
    try {
      const data = await invokeApi({
        method: "GET",
        url: `/submissions/`,
        params: {
          program_id: id,
        },
      });

      setSubmissions(data.results);

      const vtxPromises = data.results
        .filter((submission) => submission.vtx)
        .map((submission) =>
          invokeApi({
            method: "GET",
            url: `/submissions-vtx?vtx_code=${submission.vtx}`,
          }).then((vtxResponse) => {
            return {
              ...vtxResponse.results[0],
              submission_id: submission.submissions_id,
              severity: submission.severity,
            };
          })
        );
      Promise.all(vtxPromises).then((vtxResponses) => {
        const newVtMainList = { ...vtMainList };
        vtxResponses.forEach((vtxResponse) => {
          const mainCategory = vtxResponse.main_category;
          newVtMainList[mainCategory] = {
            genericDescription: vtxResponse.generic_description,
            appendix: vtxResponse.appendix,
          };
        });
        setVtMainList(newVtMainList);
      });

      const vtxResponses = await Promise.all(vtxPromises);

      setVtxResponses(vtxResponses);
    } catch (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const fetchCustomer = async () => {
    invokeApi({
      method: "GET",
      url: `/customer/${program?.customer}`,
    })
      .then((data) => {
        setCustomer(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  useEffect(() => {
    if (id) {
      fetchData();
      fetchSubmissions();
    }
  }, [id]);

  useEffect(() => {
    if (program) {
      fetchCustomer();
    }
  }, [program]);

  const pdfRef = useRef(null);

  const postReport = () => {
    const input = pdfRef.current;
    const pdf = new jsPDF("p", "mm", "a4", true);

    pdf.html(input, {
      callback: async function (pdf) {
        const pdfBlob = pdf.output("blob");

        const formData = new FormData();
        formData.append("report", pdfBlob, "report.pdf");
        formData.append("title", `Program Report(${program?.program_id})`);
        formData.append("report_type", "Program Report");
        formData.append("reference_id", id);
        formData.append("reference_type", "Program");
        formData.append("user_id", userAccData?.id);

        try {
          await invokeApi({
            method: "POST",
            url: `/report/`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          customToast.success("PDF uploaded successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          // setAgreementModal(false);
          // setSafeHarbor(true);
          // pdf.save("agreement.pdf");
        } catch (error) {
          customToast.error("Failed to upload PDF!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      },
      x: 2,
      y: 2,
      html2canvas: {
        scale: 0.15,
      },
    });
  };

  // useEffect(() => {
  //   if (
  //     program &&
  //     Object.keys(program.length > 0) &&
  //     customer &&
  //     Object.keys(customer.length > 0) &&
  //     submissions
  //   ) {
  //     postReport();
  //   }
  // }, [program, customer, submissions]);
  const transformData = (data) => {
    const countMap = {};

    data.forEach((item) => {
      if (!item.main_category) {
        return;
      }
      const key = item.main_category;
      const subCategory = item.sub_category ? item.sub_category : "N/A";
      const severity = item.severity ? item.severity : "N/A";
      const uniqueKey = `${key}|${subCategory}|${severity}`;

      if (countMap[uniqueKey]) {
        countMap[uniqueKey].count += 1;
      } else {
        countMap[uniqueKey] = {
          key,
          subCategory,
          severity,
          count: 1,
        };
      }
    });

    return Object.values(countMap);
  };

  const [transformedData, setTransformedData] = useState([]);
  useEffect(() => {
    if (vtxResponses) {
      setTransformedData(transformData(vtxResponses));
    }
  }, [vtxResponses]);

  return (
    <>
      <div ref={pdfRef} className="container report">
        <h3 className="text-center" style={{ color: "#333333" }}>
          Program Type: {program?.program_type}
        </h3>
        <div
          style={{
            fontSize: "1.25rem",
            marginBottom: ".1rem",
            fontWeight: 600,
            color: "#333333",
          }}
        >
          Title: {program?.program_title}
        </div>
        <div
          style={{
            fontSize: "1.25rem",
            marginBottom: ".1rem",
            fontWeight: 600,
            color: "#333333",
          }}
        >
          Program ID: {program?.program_id}
        </div>
        <div>
          <span className="sub-title" style={{ marginTop: "30px" }}>
            1. Introduction:
          </span>
          <div>
            <div className="sub-title">Overview:</div>
            <span
              className="text-justify"
              dangerouslySetInnerHTML={{ __html: program?.project_description }}
            ></span>
          </div>
        </div>
        <div>
          <span className="sub-title">2. Vulnerability Details:</span>
          <div>
            <div className="sub-title">Description:</div>
            <div className="text-justify">
              Here are various submissions connected to programs. Click the link
              to delve into them further.
            </div>
            <table>
              <thead>
                <tr>
                  <th>Submission ID</th>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                {submissions &&
                  submissions.map((sub, index) => (
                    <tr key={index}>
                      <td style={{ fontSize: "14px" }}>
                        <a
                          style={{ fontSize: "14px", color: "#000066" }}
                          target="_blank"
                          href={routeLink}
                        >
                          {sub.submissions_id}
                        </a>
                      </td>
                      <td style={{ fontSize: "14px" }}>
                        {
                          <a
                            style={{ fontSize: "14px", color: "#000066" }}
                            target="_blank"
                            href={routeLink}
                          >
                            {sub.submission_title}
                          </a>
                        }
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div>
            <div className="sub-title">Affected Component: </div>
            <div>
              The vulnerability exists in Affected Component Feature,
              specifically in specific location or functionality as given below.
            </div>
            <table>
              <thead>
                <tr>
                  <th>Submission ID</th>
                  <th>Affected Component/Feature</th>
                  <th>Affected Location URL</th>
                </tr>
              </thead>
              <tbody>
                {submissions &&
                  submissions.map((sub, index) => (
                    <tr key={index}>
                      <td style={{ fontSize: "14px" }}>
                        <a
                          style={{ fontSize: "14px", color: "#000066" }}
                          target="_blank"
                          href={`/admin/submissions/view/${sub.id}`}
                        >
                          {sub.submissions_id}
                        </a>
                      </td>
                      <td style={{ fontSize: "14px" }}>
                        <a
                          style={{ fontSize: "14px", color: "#000066" }}
                          target="_blank"
                          href={`/admin/submissions/view/${sub.id}`}
                        >
                          {sub.target_title}
                        </a>
                      </td>
                      <td style={{ fontSize: "14px" }}>
                        {targetUrl.map((url) => {
                          if (sub[url]) {
                            return (
                              <div
                                key={url}
                                className=" d-flex justify-content-start align-items-center"
                                style={{ textWrap: "nowrap", fontSize: "14px" }}
                              >
                                <a
                                  className="text-overflow-ellipsis"
                                  target="_blank"
                                  href={sub[url]}
                                  style={{ color: "#000066", fontSize: "14px" }}
                                >
                                  {sub[url]}
                                </a>
                              </div>
                            );
                          }
                        })}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div>
            <span className="sub-title">Vulnerability Type:</span>
            <div>
              This vulnerability falls under the Type of Vulnerability
              categories. Here are various submissions connected to programs.
              Click the link to delve into them further
            </div>
            <table>
              <thead>
                <tr>
                  <th>Submission ID</th>
                  <th>VTx Code</th>
                  <th>VTx Category</th>
                  <th>VTx Sub-Category</th>
                </tr>
              </thead>
              <tbody>
                {vtxResponses &&
                  vtxResponses.map((sub, index) => (
                    <tr key={index}>
                      <td style={{ fontSize: "14px" }}>
                        <a
                          style={{ fontSize: "14px", color: "#000066" }}
                          target="_blank"
                          href={`/admin/submissions/view/${sub.id}`}
                        >
                          {sub.submission_id}
                        </a>
                      </td>
                      <td style={{ fontSize: "14px" }}>
                        <a
                          style={{ fontSize: "14px", color: "#000066" }}
                          target="_blank"
                          href={`/admin/submissions/view/${sub.id}`}
                        >
                          {sub.vtx_code}
                        </a>
                      </td>
                      <td style={{ fontSize: "14px" }}>
                        <a
                          style={{ fontSize: "14px", color: "#000066" }}
                          target="_blank"
                          href={`/admin/submissions/view/${sub.id}`}
                        >
                          {sub.main_category}
                        </a>
                      </td>
                      <td style={{ fontSize: "14px" }}>
                        <a
                          style={{ fontSize: "14px", color: "#000066" }}
                          target="_blank"
                          href={`/admin/submissions/view/${sub.id}`}
                        >
                          {sub.sub_category}
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div>
            <span className="sub-title">Reproduction Steps:</span>
            <div>
              Here are various submissions connected to programs. Click the link
              to delve into them further.
            </div>
            <table>
              <thead>
                <tr>
                  <th>Submission ID</th>
                  <th>Step to reproduce</th>
                </tr>
              </thead>
              <tbody>
                {submissions &&
                  submissions.map((sub, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          style={{ fontSize: "14px", color: "#000066" }}
                          target="_blank"
                          href={routeLink}
                        >
                          <a
                            style={{ fontSize: "14px", color: "#000066" }}
                            target="_blank"
                            href={`/admin/submissions/view/${sub.id}`}
                          >
                            {sub.submissions_id}
                          </a>
                        </a>
                      </td>
                      <td style={{ fontSize: "14px" }}>
                        {sub.step_to_reproduce ? (
                          <span
                            style={{ fontSize: "14px" }}
                            dangerouslySetInnerHTML={{
                              __html: sub.step_to_reproduce,
                            }}
                          ></span>
                        ) : (
                          "-"
                        )}{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div>
            <div className="sub-title">Proof of Concept (PoC):</div>

            <table>
              <thead>
                <tr>
                  <th>Submission ID</th>
                  <th>Attachments</th>
                </tr>
              </thead>
              <tbody>
                {submissions &&
                  submissions.map((sub, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          target="_blank"
                          href={routeLink}
                          style={{ fontSize: "14px", color: "#000066" }}
                        >
                          {/* <a
                            style={{ fontSize: "14px", color: "#000066" }}
                            target="_blank"
                            href={`/admin/submissions/view/${sub.id}`}
                          > */}
                          {sub.submissions_id}
                          {/* </a> */}
                        </a>
                      </td>
                      <td>
                        {attachmentsConst.map((attach) => {
                          if (sub[attach])
                            return (
                              <div key={attach}>
                                <a
                                  style={{ fontSize: "14px", color: "#000066" }}
                                  target="_blank"
                                  href={sub[attach]}
                                >
                                  {sub[attach]}
                                </a>
                              </div>
                            );
                        })}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className="sub-title"> 3. Technical Details:</div>
          <div>
            <div className="sub-title">Technical Analysis:</div>
            <div>
              I'd like you to please provide a detailed technical analysis
              explaining how the vulnerability can be exploited.
            </div>
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>Vulnerability</th>
                  <th>Detailed analysis</th>
                </tr>
              </thead>
              <tbody>
                {vtMainList &&
                  Object.entries(vtMainList)
                    .filter(([key]) => key !== "undefined")
                    .map(([key, value]) => (
                      <tr key={key}>
                        <td colSpan={2} style={{ fontSize: "14px" }}>
                          {key}
                        </td>
                        <td
                          className="text-justify"
                          style={{ fontSize: "14px" }}
                        >
                          {value.genericDescription}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          <div>
            <span className="sub-title">Impact Assessment:</span>
            <div>
              According to the technical analysis, the severity assessment of
              the vulnerability ranges from Critical to Informational,
              reflecting its impact on the system, application, data, and users.
            </div>

            <table>
              <thead>
                <tr>
                  <th>Vulnerability Category</th>
                  <th>Severity</th>
                  <th>Sub-Cate (Vulnerability)</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {transformedData.map((item, index) => (
                  <tr key={index}>
                    <td style={{ fontSize: "14px" }}>{item.key}</td>
                    <td style={{ fontSize: "14px" }}>{item.severity}</td>
                    <td style={{ fontSize: "14px" }}>{item.subCategory}</td>
                    <td style={{ fontSize: "14px" }}>{item.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className="sub-title">4. Recommendations:</div>
          <div className="sub-title">Remediation Recommendation:</div>
          <div>
            This report highlights a critical security vulnerability that
            requires immediate attention and remediation. Addressing this issue
            quickly would ensure the security and integrity of the
            [System/Application].
          </div>
          <table>
            <thead>
              <tr>
                <th>Submission ID</th>
                <th>Remediation Recommendation</th>
              </tr>
            </thead>
            <tbody>
              {submissions &&
                submissions.map((sub, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        className="text-justify"
                        style={{ fontSize: "14px", color: "#000066" }}
                        target="_blank"
                        href={routeLink}
                      >
                        {sub.submissions_id}
                      </a>
                    </td>
                    <td style={{ fontSize: "14px" }}>
                      {sub.remediation_recommendation ? (
                        <span
                          style={{ fontSize: "14px" }}
                          dangerouslySetInnerHTML={{
                            __html: sub.remediation_recommendation,
                          }}
                        ></span>
                      ) : (
                        "-"
                      )}{" "}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div>
          {/* <div className="sub-title">4. Recommendations:</div> */}
          <div className="sub-title">Best Practices:</div>
          <div>
            To avert comparable vulnerabilities down the road, it's advisable to
            adhere to established best practices and guidelines in General
            Security.
          </div>
          <table>
            <thead>
              <tr>
                <th colSpan={2}>Vulnerability</th>
                <th>Best Practice Note</th>
              </tr>
            </thead>
            <tbody>
              {vtMainList &&
                Object.entries(vtMainList)
                  .filter(([key]) => key !== "undefined")
                  .map(([key, value]) => (
                    <tr key={key}>
                      <td colSpan={2} style={{ fontSize: "14px" }}>
                        {key}
                      </td>
                      <td className="text-justify" style={{ fontSize: "14px" }}>
                        {value.appendix}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>

        <div>
          <div className="sub-title">5. Conclusion:</div>
          <div>
            This report highlights a critical security vulnerability that
            requires immediate attention and remediation. Addressing this issue
            quickly would ensure the security and integrity of the
            [System/Application].
          </div>
        </div>
        <div>
          <div className="sub-title"> 6. Disclosure Policy:</div>
          <div>
            This report adheres to the disclosure policy outlined in the{" "}
            {program?.program_title}. The program's terms and conditions submit
            all details provided. Read{" "}
            <a href="" target="_blank">
              Terms and Conditions
            </a>
          </div>
        </div>
        <div>
          <div className="sub-title"> 7. Contact Information:</div>
          <div>
            For further clarification or assistance, please contact: <br />
            <ul>
              <li>
                Name: {customer?.first_name} {customer?.last_name}
              </li>
              <li>Email: {customer?.email}</li>
            </ul>
          </div>
        </div>
        <div>
          <div className="sub-title"> 8. Acknowledgments:</div>
          <div>
            I want to express my gratitude to the [Bug Bounty Program
            Administrators / {customer?.entity_name}] for their commitment to
            security and for providing the opportunity to contribute to
            improving the [System/Application].
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="btn bg-gradient-dark me-3 mb-0 "
        >
          Cancel
        </button>
        <button
          onClick={postReport}
          className="btn bg-gradient-dark me-3 mb-0 "
        >
          Save Report
        </button>
      </div>
    </>
  );
};

export default ProgramReport;
