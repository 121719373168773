import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import "./researcherHomeStyle.css";
import Chart from "react-apexcharts";
import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import ProfileImage from "../../../components/ProfileImage";
import Skeleton from "react-loading-skeleton";
import moment from "moment/moment";
import { customToast } from "../../../App";

const Breadcrumbname = {
  home: "/researcher/home",
  Dashboard: "",
};
const severityValues = {
  Critical: 5,
  Severe: 4,
  Moderate: 3,
  Low: 2,
  Informational: 1,
  null: 0,
};
const ResearcherHome = () => {
  const ASSET_URL = process.env.REACT_APP_ASSET_URL;
  const [researcherLoad, setResearcherLoad] = useState(false);
  const { invokeApi } = HTTPService();
  const [researcherPortfolio, setResearcherPortfolio] = useState([]);
  const [programStats, setProgramStats] = useState([]);
  const [acceptance, setAcceptance] = useState([]);
  const [performance, setPerformance] = useState([]);
  const [programResearcher, setProgramResearcher] = useState([]);
  const [rewardManagement, setRewardmanagement] = useState([]);

  const getResearcherPortfolio = async (id) => {
    setResearcherLoad(true);
    invokeApi({
      method: "GET",
      url: `widget/researcher-leader-board`,
    })
      .then((data) => {
        setResearcherLoad(false);
        setResearcherPortfolio(data);
      })
      .catch((error) => {
        setResearcherLoad(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const getProgramStats = () => {
    invokeApi({
      method: "GET",
      url: `widget/programs-submission-stats/`,
    })
      .then((data) => {
        setProgramStats(data.program_submission_stats);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const getPerformance = () => {
    invokeApi({
      method: "GET",
      url: `widget/researcher-performance/`,
    })
      .then((data) => {
        setPerformance(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const getAcceptance = () => {
    invokeApi({
      method: "GET",
      url: `widget/acceptence-criteria/`,
    })
      .then((data) => {
        setAcceptance(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const getProgramResearcher = () => {
    invokeApi({
      method: "GET",
      url: `widget/programs-researcher-stats/`,
    })
      .then((data) => {
        setProgramResearcher(data.researcher_stats);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const getWidgetRewardManagement = () => {
    invokeApi({
      method: "GET",
      url: `widget/reward-management`,
    })
      .then((data) => {
        setRewardmanagement(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  useEffect(() => {
    getProgramStats();
    getResearcherPortfolio();
    getAcceptance();
    getPerformance();
    getProgramResearcher();
    getWidgetRewardManagement();
  }, []);

  const donutOptions = {
    chart: {
      type: "donut",
      width: '100%',
      height: '100%',
    },
    legend: {
      show: true,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val + "%"; // Custom tooltip format
        },
      },
    },
    series: [
      ...programStats.map((el) => {
        return el.percentage_of_total_submissions;
      }),
    ],
    labels: [
      ...programStats.map((el) => {
        return el.program_title;
      }),
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      show: false,
    },
  };
  const lineOption = {
    series: [
      {
        name: "Total Submissions",
        data: acceptance.map((el) => {
          return el.total_submissions;
        }),
      }, {
        name: "Resolved Submissions",
        data: acceptance.map((el) => {
          return el.resolved_submissions;
        }),
      },

    ],

    options: {
      markers: {
        size: 5,
        colors: undefined,
        strokeColors: "#fff",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 3,
        },
      },
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        colors: ['rgb(0, 143, 251)', 'rgb(0, 227, 150)'], // Reversed stroke colors
      },
      xaxis: {
        categories: acceptance.map((el) => {
          return el.month_name;
        }),
      },
      yaxis: {
        tickAmount: 4,
        labels: {
          formatter: function (val) {
            return Math.round(val).toString();
          },
        },
      },
    },
  };

  const multiBarOption = {
    series: [
      {
        name: "Total Reports",
        data: performance.map((el) => {
          return el.total_submissions;
        }),
      },
      {
        name: "Critical",
        data: performance.map((el) => {
          return el.severities.Critical;
        }),
      },
      {
        name: "Severe",
        data: performance.map((el) => {
          return el.severities.Severe;
        }),
      },
      {
        name: "Medium",
        data: performance.map((el) => {
          return el.severities.Moderate;
        }),
      },
      {
        name: "Low",
        data: performance.map((el) => {
          return el.severities.Low;
        }),
      },
      {
        name: "Informational",
        data: performance.map((el) => {
          return el.severities.Informational;
        }),
      },
    ],
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },

    legend: {
      position: "right",
      offsetY: 40,
      labels: {
        colors: ["#7f56da", "#FFDB96", "#ABCF9A", "#A4C2E6", "#FFD681"],
        useSeriesColors: false,
        style: {
          fontSize: "14px",
        },
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 1,
        strokeColor: "#000",
        fillColors: ["#7f56da", "#FFDB96", "#ABCF9A", "#A4C2E6", "#FFD681"],
        radius: 0,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#7f56da"],
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: "#333",
          fillColor: "#c2c2c2",
          opacity: 0.8,
          offsetX: 0,
          offsetY: 0,
        },
      ],
      xaxis: [
        {
          x: 0,
          strokeDashArray: 0,
          borderColor: "#333",
          fillColor: "#c2c2c2",
          opacity: 0.8,
          offsetX: 0,
          offsetY: 0,
        },
      ],
    },
    xaxis: {
      title: {
        text: "Month-Year",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-xaxis-title",
        },
      },
      categories: performance.map((el) => {
        return moment(el.month).format("d/M/Y");
      }),
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true,
        hideOverlappingLabels: false,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 120,

        style: {
          colors: "#4F6488",
          fontSize: "14px",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },

    fill: {
      colors: ["#7f56da", "#FFDB96", "#ABCF9A", "#A4C2E6", "#FFD681"],
    },
  };

  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="card h-100">
              <h5 className="p-3 text-center">Researcher Profile</h5>
              <div className="table-responsive researcherProfile">
                <table className="table align-items-center mb-0 researcherProfileTable">
                  <thead>
                    <tr>
                      <th
                        className="text-uppercase  font-weight-bolder  text-start ps-3 th-first"
                        colSpan={2}
                      >
                        <div>Program ID</div>
                        <div>& Name</div>
                      </th>
                      <th className="text-uppercase  font-weight-bolder ">
                        <div>Total </div>
                        <div>Submissions </div>
                      </th>
                      <th className="text-uppercase  font-weight-bolder ">
                        <div>Valid </div>
                        <div>Submissions </div>
                      </th>
                      <th className="text-uppercase  font-weight-bolder  ">
                        <div>Total Earnings </div>
                        <div>
                          <b>(USD)</b>
                        </div>
                      </th>
                      <th className="text-uppercase  font-weight-bolder  ">
                        <div>Highest Payout </div>
                        <div>
                          <b>(USD)</b>
                        </div>
                      </th>
                      <th className="text-uppercase  font-weight-bolder  ">
                        Progress
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {researcherLoad
                      ? Array(5)
                        .fill()
                        .map((item) => (
                          <tr>
                            <td>
                              <div className="d-flex">
                                <div className="text-start">
                                  <Skeleton height={14} width={100} />
                                  <Skeleton height={14} width={100} />
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className="mb-0 px-2">
                                <Skeleton height={14} width={"100%"} />
                              </p>
                            </td>

                            <td className="cell-breakWord">
                              <Skeleton height={14} width={"100%"} />
                            </td>
                            <td>
                              <Skeleton height={14} width={"100%"} />
                            </td>
                            <td>
                              <Skeleton height={14} width={"100%"} />
                            </td>
                            <td>
                              <Skeleton height={14} width={"100%"} />
                            </td>
                          </tr>
                        ))
                      : programResearcher &&
                      programResearcher.map(
                        (item, index) =>
                          index < 5 && (
                            <tr key={index}>
                              <td
                                className="text-start td-first"
                                style={{ width: "100px", overflow: "hidden" }}
                                colSpan={2}
                              >
                                <div
                                  style={{
                                    color: "#8955D6",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.program_id}
                                </div>
                                <div
                                  style={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {item.program_title}
                                </div>
                              </td>
                              <td
                                style={{
                                  padding: "0",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                <p className="mb-0">
                                  {item.total_submissions}
                                </p>
                              </td>
                              <td
                                style={{
                                  padding: "0",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                                className="mb-0"
                              >
                                <p className="mb-0">
                                  {item.total_valid_submissions}
                                </p>
                              </td>
                              <td
                                style={{
                                  padding: "0",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                <p
                                  className="mb-0"
                                  style={{
                                    color: "#22B14C",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  ${item.total_earnings}
                                </p>
                              </td>
                              <td style={{ padding: "0" }}>
                                <p
                                  className="mb-0"
                                  style={{
                                    color: "#22B14C",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  ${item.highest_rewards}
                                </p>
                              </td>
                              <td style={{ padding: "0" }}>
                                <p
                                  className="mb-1"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.total_submissions === 0 ||
                                    item.total_valid_submissions === 0
                                    ? "0.0%"
                                    : (
                                      (parseInt(item.total_valid_submissions) /
                                        parseInt(item.total_submissions)) *
                                      100
                                    ).toFixed(1) + "%"}
                                </p>
                                <div className="progress">
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      width:
                                        item.total_submissions === "0" ||
                                          item.total_valid_submissions === "0"
                                          ? "0%"
                                          : (
                                            (parseInt(
                                              item.total_valid_submissions
                                            ) /
                                              parseInt(
                                                item.total_submissions
                                              )) *
                                            100
                                          ).toFixed(0) + "%",
                                      backgroundColor:
                                        item.total_submissions === "0" ||
                                          item.total_valid_submissions === "0"
                                          ? "#f7ccac"
                                          : (parseInt(item.total_valid_submissions) /
                                            parseInt(item.total_submissions)) *
                                            100 <=
                                            20
                                            ? "#f7ccac"
                                            : (parseInt(item.total_valid_submissions) /
                                              parseInt(item.total_submissions)) *
                                              100 <=
                                              40
                                              ? "#ed7d31"
                                              : (parseInt(item.total_valid_submissions) /
                                                parseInt(item.total_submissions)) *
                                                100 <=
                                                60
                                                ? "#98c3e6"
                                                : (parseInt(item.total_valid_submissions) /
                                                  parseInt(item.total_submissions)) *
                                                  100 <=
                                                  80
                                                  ? "#0072bf"
                                                  : "#00af52",
                                    }}
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="card h-100">
              <h5 className="p-3 text-center">Researcher Leaderboard</h5>
              <div className="table-responsive researcherProfile">
                <table className="table align-items-center mb-0 researcherProfileTable">
                  <thead>
                    <tr>
                      <th className="text-uppercase  font-weight-bolder th-first">
                        Researcher
                      </th>
                      <th className="text-uppercase  font-weight-bolder ">
                        Total <br /> Submisions
                      </th>
                      <th className="text-uppercase  font-weight-bolder ">
                        Valid <br /> Submisions
                      </th>
                      <th className="text-uppercase  font-weight-bolder ">
                        Critical <br /> Findings
                      </th>
                      <th className="text-uppercase  font-weight-bolder  ps-2">
                        Points <br /> Earned
                      </th>
                      <th className="text-uppercase  font-weight-bolder  ps-2">
                        Progress <br /> Meter
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {researcherLoad
                      ? Array(5)
                        .fill()
                        .map((item) => (
                          <tr>
                            <td>
                              <div className="d-flex">
                                <div className="me-2">
                                  <div className="outsideBorder">
                                    <div className="customerProfilePic">
                                      <Skeleton
                                        height={"100%"}
                                        width={"100%"}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="text-start">
                                  <span className="text-xxs">
                                    <Skeleton height={14} width={"100%"} />
                                  </span>
                                  <p className="mb-0">
                                    <Skeleton height={14} width={120} />
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className="mb-0 px-2">
                                <Skeleton height={14} width={"100%"} />
                              </p>
                            </td>

                            <td className="cell-breakWord">
                              <Skeleton height={14} width={"100%"} />
                            </td>
                            <td>
                              <Skeleton height={14} width={"100%"} />
                            </td>
                            <td>
                              <Skeleton height={14} width={"100%"} />
                            </td>
                            <td>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </td>
                          </tr>
                        ))
                      : researcherPortfolio &&
                      researcherPortfolio.map(
                        (item, index) =>
                          index < 5 && (
                            <tr key={index} className="td-first">
                              <td>
                                <div className="d-flex">
                                  <div className="me-2">
                                    <div className="outsideBorder">
                                      <div className="customerProfilePic">
                                        <ProfileImage
                                          src={ASSET_URL + item.display_pic}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-start">
                                    <span
                                      style={{
                                        color: "#8955D6",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <b>{item.username}</b>
                                    </span>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                      className="mb-0"
                                    >
                                      {item.first_name} {item.last_name}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p
                                  className="mb-0 px-2"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.total_reports}
                                </p>
                              </td>

                              <td className="cell-breakWord">
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.valid_reports}
                                </p>
                              </td>
                              <td>
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.critical_findings}
                                </p>
                              </td>
                              <td>
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.reward_points}
                                </p>
                              </td>
                              <td>
                                <p
                                  className="mb-1"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.total_reports === 0 ||
                                    item.valid_reports === 0
                                    ? "0.0%"
                                    : (
                                      (parseInt(item.valid_reports) /
                                        parseInt(item.total_reports)) *
                                      100
                                    ).toFixed(1) + "%"}
                                </p>
                                <div className="progress">
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      width:
                                        item.total_reports === "0" ||
                                          item.valid_reports === "0"
                                          ? "0%"
                                          : (
                                            (parseInt(item.valid_reports) /
                                              parseInt(
                                                item.total_reports
                                              )) *
                                            100
                                          ).toFixed(0) + "%",
                                      backgroundColor:
                                        item.total_reports === "0" ||
                                          item.valid_reports === "0"
                                          ? "#f7ccac"
                                          : (parseInt(item.valid_reports) /
                                            parseInt(item.total_reports)) *
                                            100 <=
                                            20
                                            ? "#f7ccac"
                                            : (parseInt(item.valid_reports) /
                                              parseInt(item.total_reports)) *
                                              100 <=
                                              40
                                              ? "#ed7d31"
                                              : (parseInt(item.valid_reports) /
                                                parseInt(item.total_reports)) *
                                                100 <=
                                                60
                                                ? "#98c3e6"
                                                : (parseInt(item.valid_reports) /
                                                  parseInt(item.total_reports)) *
                                                  100 <=
                                                  80
                                                  ? "#0072bf"
                                                  : "#00af52",
                                    }}
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </td>
                              {/* <td>
                                    <p className="mb-0">
                                      {item.reputationPoints}
                                    </p>
                                  </td> */}
                            </tr>
                          )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mt-4">
            <div className="card h-100">
              <div className="row d-flex justify-content-center pb-2 ">
                <div className="col-12">
                  <h5 className="p-3 text-center">Programs</h5>
                </div>
                <div className="ol-sm-12 col-md-6 text-center p-0">
                  <Chart
                    options={donutOptions}
                    series={donutOptions.series}
                    type="donut"
                    width={"100%"}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <div
                    style={{
                      marginRight: "14px",
                      marginBottom: "14px",
                    }}
                  >
                    {programStats &&
                      programStats.map((data) => (
                        <div key={data.id} className="d-flex donutLegendPart">
                          <div className="ms-2 w-100">
                            <div className="col-12">
                              <div className=" donutLegendName  justify-content-between">
                                <p>{data.program_title}</p>
                                <p>
                                  {data.percentage_of_total_submissions.toFixed(
                                    2
                                  )}
                                  %
                                </p>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="donutLegendName">
                                Submission count:{" "}
                                <span>
                                  {data.total_program_submissions.toFixed(0)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mt-4">
            <div className="card h-100">
              <div className="p-3 ">
                <h5 className="text-center">Reward Management</h5>
              </div>
              {/* <div className="text-end me-4">
                <i className="fa-regular fa-calendar me-2"></i>
                <span style={{ fontWeight: "600" }}>1 - 7 Nov 2023</span>
              </div> */}
              <div className="row">
                <div className="px-4">
                  {rewardManagement &&
                    rewardManagement.map((data) => {
                      return (
                        <div
                          key={data.updated_at}
                          className="d-flex justify-content-between rewardManaPart"
                        >
                          <div className="col-7 d-flex">
                            <div className="col-2 arrowCircle">
                              {data.note_status !== "pending" ? (
                                <div className="circleOutline">
                                  <i className="fa-solid fa-arrow-up"></i>
                                </div>
                              ) : (
                                <div className="circleOutlineDown">
                                  <i className="fa-solid fa-arrow-down"></i>
                                </div>
                              )}
                            </div>
                            <div
                              className="col-10"
                              style={{ lineHeight: ".5" }}
                            >
                              <p className="rewardManaPartP">
                                {data.program_title}
                              </p>
                              <span className="rewardManaPartSpan">
                                {data.submission_title}
                              </span>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="text-center">
                              {data.note_status === "pending" ? (
                                <span className="stsResolvedPending">
                                  Pending
                                </span>
                              ) : (
                                <span className="stsResolved">Submitted</span>
                              )}
                            </div>
                          </div>
                          <div className="col-1">
                            <div className="text-center">
                              {data.note_status === "pending" ? (
                                <span className="stsResolvedPending">
                                  ${data.rewards_amount}
                                </span>
                              ) : (
                                <span className="stsResolved">
                                  ${data.rewards_amount}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mt-4">
            <div className="card h-100">
              <h5 className="p-3 text-center">Acceptance Rate</h5>
              <div>
                <Chart
                  options={lineOption.options}
                  series={lineOption.series}
                  type="line"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mt-4">
            <div className="card h-100">
              <h5 className="p-3 text-center" style={{ color: "#243081" }}>
                Researcher Performance
              </h5>
              <div>
                <div className="">
                  <Chart
                    options={multiBarOption}
                    series={multiBarOption.series}
                    type="bar"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-sm-12 col-md-6 mt-4">
            <div className="card h-100">
              <h5 className="p-3 text-center">Researcher Reports</h5>
              <div className="table-responsive researcherProfile">
                <table className="table align-items-center mb-0 researcherProfileTable">
                  <thead>
                    <tr>
                      <th
                        className="text-uppercase  font-weight-bolder "
                        style={{ width: "0" }}
                      >
                        Report ID & <br /> Researcher ID
                      </th>
                      <th
                        className="text-uppercase  font-weight-bolder "
                        style={{ width: "0" }}
                      >
                        Title
                      </th>
                      <th
                        className="text-uppercase  font-weight-bolder "
                        style={{ width: "0" }}
                      >
                        Severity <br /> Level
                      </th>
                      <th
                        className="text-uppercase  font-weight-bolder  "
                        style={{ width: "0" }}
                      >
                        Status
                      </th>
                      <th
                        className="text-uppercase  font-weight-bolder  "
                        style={{ width: "0" }}
                      >
                        Submission <br /> Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {researcherReportData &&
                      researcherReportData.map(
                        (item, index) =>
                          index < 5 && (
                            <tr key={index}>
                              <td>
                                <div className="d-flex">
                                  <div className="me-2">
                                    <div className="outsideBorder">
                                      <div className="customerProfilePic">
                                        <img src={profilePhoto} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-start">
                                    <span className="text-xxs">
                                      <b>{item.subid}</b>
                                    </span>
                                    <p className="mb-0">{item.id}</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="mb-0 px-2">{item.title}</p>
                              </td>

                              <td className="cell-breakWord">
                                <p className="mb-0">{item.SeverityLevel}</p>
                              </td>
                              <td>
                                <p
                                  style={
                                    item.status === "Resolved"
                                      ? { color: "#02AF4A" }
                                      : item.status === "Open"
                                        ? { color: "#BF0104" }
                                        : item.status === "Closed"
                                          ? { color: "#23150B" }
                                          : ""
                                  }
                                  className="mb-0"
                                >
                                  <b>{item.status}</b>
                                </p>
                              </td>
                              <td>
                                <p className="mb-0">{item.submissionDate}</p>
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ResearcherHome;
