import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import HTTPService from '../../../utils/axios';
import { customToast } from '../../../App';
const scopeUrlConst = [
  'scope_items_url1',
  'scope_items_url2',
  'scope_items_url3',
  'scope_items_url4',
  'scope_items_url5',
]
const targetUrl = [
  'target_url1',
  'target_url2',
  'target_url3',
  'target_url4',
  'target_url5',
]
const attachmentsConst = [
  'attachments_upload_doc1',
  'attachments_upload_doc2',
  'attachments_upload_doc3',
  'attachments_upload_doc4',
  'attachments_upload_doc5',
  'evidence_attachments1',
  'evidence_attachments2',
  'evidence_attachments3',
  'evidence_attachments4',
  'evidence_attachments5',
]
const ResearcherProgramReport = () => {
  const { researcherId, programId } = useParams()
  const [program, setProgam] = useState();
  const [submissions, setSubmissions] = useState();
  const [researcher, setResearcher] = useState();
  const { invokeApi } = HTTPService();
  const fetchResearcher = async () => {
    invokeApi({
      method: "GET",
      url: `/researcher/${researcherId}`,
    }).then((data) => {
      setResearcher(data)
    }).catch((error) => {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  };
  const fetchSubmisisons = async () => {
    invokeApi({
      method: "GET",
      url: `/submissions/`,
      params: {
        user_id: researcherId
      }
    }).then((data) => {
      setSubmissions(data)
    }).catch((error) => {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  };

  useEffect(() => {
    if (researcherId) {
      fetchResearcher()
      fetchSubmisisons()
    }
  }, [researcherId])

  return (
    <div className='container report'>
      <h3 className='text-center'>Report based on Researcher</h3>
      <div>Bug Bounty Submission Report: </div>
      <div>
        <div className='sub-title'>1. Researcher Information:</div>
        <div>
          <ul>
            <li>Name: {researcher?.first_name} {researcher?.last_name}</li>
            <li>Researcher ID: {researcher?.user_id}</li>
            <li>Contact Information: {researcher?.email} / {researcher?.phone_number}</li>
          </ul>
        </div>
      </div>
      <div>
        <div className='sub-title'>2. Summary of Submissions::</div>
        <div>
          <div className='sub-title'>Total Submissions: {submissions?.total_records}</div>

          <table>
            <thead>
              <tr>
                <th>Submission ID</th>
                <th>Title</th>
              </tr>
            </thead>
            <tbody>

              {
                submissions && submissions.results.map((sub, index) => <tr key={index}>
                  <td>{sub.submissions_id}</td>
                  <td>{<a target='_blank' href={`/admin/program/${researcherId}/submission`}>{sub.submission_title}
                  </a>}</td>
                </tr>
                )
              }

            </tbody>
          </table>
        </div>
        <div>
          <div className='sub-title'>Submission Breakdown: </div>
          <ul>
            <li>Total Submissions: [Number of submissions] [Total Count]</li>
            <li>Accepted: [Number of accepted submissions] [Resolved]</li>
            <li>Rejected: [Number of rejected submissions] [Rejected]</li>
            <li>Pending: [Number of submissions pending review] [New-Triaged-Unresolved]</li>
            <li>Hold: [Number of submissions hold] [Hold]</li>
          </ul>
        </div>
      </div>
      <div>
        <div className='sub-title'> 3. Submission Details:</div>
        <div>
          <div>In the Submission Details section, all significant information is included, and to access the details, simply click
            on the quick link provided below.</div>
          <table>
            <thead>
              <tr>
                <th>Submission ID</th>
                <th>Title</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {
                submissions && submissions.results.map((sub, index) =>
                  <tr key={index}>
                    <td>{sub.submissions_id}</td>
                    <td>{sub.submission_title}</td>
                    <td><a target='_blank' href={`/admin/program/${researcherId}/submission`}>{sub.submissions_id}</a></td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>

      </div>
      <div>
        <div className='sub-title'>4. Conclusion:</div>
        <div>
          This report summarizes the submissions by {researcher?.first_name + " " + researcher?.last_name} in the bug bounty program.
          The researcher has contributed {submissions?.total_records} submissions,
          highlighting their expertise and dedication to improving the security of the [Bug Bounty
          Program Names] platform.
        </div>
      </div>
      <div>
        <div className='sub-title'>5. Contact Information:</div>
        <div>
          For further clarification or assistance, please contact:
          <ul>
            <li>[Bug Bounty Program Administrators / Company Name] [SUB-ADMIN & GENERAL
              SUPPORT EMAIL] BUGBUSTERSLABS PRIVATE LTD</li>
            <li>[Contact Information] [EMAIL - {researcher?.email}]</li>
          </ul>
        </div>
      </div>





    </div>
  )
}

export default ResearcherProgramReport