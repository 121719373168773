import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import "./SubadminDashboard.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfileImage from "../../components/ProfileImage";
import { formatDate, lineOverFlowDot } from "../../utils/helperFunctions";
import AddModal from "../common/modal/AddModal";
import CommonTable from "../common/Table/CommonTable";
import CountSkeletonLoader from "../components/SkeletonLoader/CountSkeletonLoader";
import AppContext from "../Context/AppContext";
import useHttp from "../Hooks/useHttp";
import HTTPService from "../../utils/axios";
import { customToast } from "../../App";

const tableColumn = [
  "User ID",
  "username",
  "name",
  "gender",
  "Department",
  "designation",
  "email",
  "phone",
  "registred",
  "status",
  "action",
];

const searchColumn = [
  { name: "User Id", value: "user_id" },
  { name: "User Name", value: "username" },
  { name: "Name", value: "first_name" },
  { name: "Email", value: "email" },
  { name: "Phone", value: "phone_number" },
];

const filterColumn = [
  {
    name: "Status",
    value: "user_status",
    filters: [{ value: "pending", label: "Pending" },
    { value: "active", label: "Active" },
    { value: "in_active", label: "Inactive" },
    { value: "hold", label: "Hold" },
    { value: "blocked", label: "Blocked" },],
  },
  {
    name: "Gender",
    value: "gender",
    filters: ["Male", "Female"],
  },
];

export default function SubadminDashboard() {
  const { setLoading } = useContext(AppContext);
  const [refresh, setRefresh] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [subAdminList, setSubAdminList] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();
  const { invokeApi } = HTTPService();

  const fetchSubAdmin = () => {
    let url = `sub_admin/?page=${currentPage}&&page_size=25`;
    if (searchQuery && searchQuery.length > 0) {
      url = url + `&&${searchValue}=${searchQuery}`;
    }
    if (filterType && filterValue && filterValue.length > 0) {
      url = url + `&${filterType}=${filterValue}`;
    }


    setLoading(true);

    invokeApi({
      method: "GET",
      url,
    })
      .then((data) => {
        setLoading(false);

        setSubAdminList(data);
        setTotalPages(data.total_pages);
      })
      .catch((error) => {
        setLoading(false);

        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  useEffect(() => {
    fetchSubAdmin();
  }, []);

  useEffect(() => {
    fetchSubAdmin();
  }, [searchQuery, currentPage, filterValue]);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [DelId, setDelId] = useState();
  const API = process.env.REACT_APP_API_URL;
  const { loading, setCircularLoading } = useContext(AppContext);
  const { GetRequest, DeleteRequest, PutRequest } = useHttp();
  const token = localStorage.getItem("token");
  const [userData, setUserData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusVal, setChangeStatusVal] = useState();
  const [getId, setGetId] = useState("");
  const [count, setCount] = useState();
  const navigate = useNavigate();

  const fetchStatus = async (stsId) => {
    const { data, error } = await GetRequest(
      API + "sub_admin/" + stsId + "/",
      {},
      {
        Authorization: "Bearer  " + token,
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      console.log(error);
    }
    if (data) {
      setChangeStatusVal(data && data.user_status);
    }
  };

  const handleStatus = async (id) => {
    fetchStatus(id);
    setChangeStatusModal(true);
    setGetId(id);
  };
  const setStatus = async () => {
    setChangeStatusModal(false);
    setCircularLoading(true);
    const id = getId;
    const param = {
      id: id,
      user_status: changeStatusVal,
    };
    const { data, error } = await PutRequest(
      API + "sub_admin/" + id + "/",
      param,
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      setCircularLoading(false);
      console.log(error);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
    if (data) {
      setCircularLoading(false);
      setRefresh(!refresh);
      customToast.success("status updated", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const deleteUser = async (id) => {
    setOpenAddModal(false);
    setCircularLoading(true);
    const { error } = await DeleteRequest(
      API + "sub_admin/" + id + "/",

      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      setCircularLoading(false);
      customToast.error("Something went worng!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setCircularLoading(false);
      setRefresh(!refresh);
      customToast.success("Deleted Successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const fetchCount = async () => {
    const { data, error } = await GetRequest(
      API + "sub_admin/status_count/",
      {},
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      customToast.error("Something went worng!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setCount(data);
      //
    }
  };
  useEffect(() => {
    fetchCount();
    // GetUsers();
  }, []);
  const Breadcrumbname = {
    Home: "/admin/home",
    Management: "/admin/users",
    User: "/admin/users",
    Dashboard: "",
  };
  return (
    <div style={{ position: "relative" }}>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              <Link
                to="/admin/user/add"
                className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                id="adduser"
              >
                Add User
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          {loading ? (
            <CountSkeletonLoader showState={1} />
          ) : (
            <div className="col-lg-12 col-12">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-8 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {count &&
                              count.total_user_counts &&
                              count.total_user_counts.active}
                          </h5>
                          <span className="text-white text-sm">
                            Active Users
                          </span>
                        </div>
                        <div className="col-4">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {count &&
                              count.today_user_counts &&
                              count.today_user_counts.active}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12 mt-4 mt-md-0">
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-8 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {count &&
                              count.total_user_counts &&
                              count.total_user_counts.in_active}
                          </h5>
                          <span className="text-white text-sm">
                            In-Actives Users
                          </span>
                        </div>
                        <div className="col-4">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {count &&
                              count.today_user_counts &&
                              count.today_user_counts.in_active}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12 mt-4 mt-md-0">
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-8 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {count &&
                              count.total_user_counts &&
                              count.total_user_counts.blocked}
                          </h5>
                          <span className="text-white text-sm">
                            Blocked Users
                          </span>
                        </div>
                        <div className="col-4">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {count &&
                              count.today_user_counts &&
                              count.today_user_counts.blocked}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12 mt-4 mt-md-0">
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-8 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {count &&
                              count.total_user_counts &&
                              count.total_user_counts.pending}
                          </h5>
                          <span className="text-white text-sm">
                            Pending Approveals
                          </span>
                        </div>
                        <div className="col-4">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {count &&
                              count.today_user_counts &&
                              count.today_user_counts.pending}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row my-4">
          <div className="col-12">
            <CommonTable
              column={tableColumn}
              data={subAdminList?.results}
              totalPages={totalPages ?? 1}
              totalData={subAdminList?.total_records}
              filterList={filterColumn}
              filterType={filterType}
              setFilterType={setFilterType}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              searchBar={true}
              searchBy={searchColumn}
              itemPerPg={25}
              setTable={1}
              card={7}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            >
              {subAdminList &&
                subAdminList?.results.map((item, index) => (
                  <tr key={index}>
                    <td className="cursor-pointer">
                      <p
                        className=" mb-0 px-2"
                        onClick={() => {
                          navigate(`/admin/user/view/${item.id}`);
                        }}
                        style={{ color: "#5A78F0", fontSize: "12px" }}
                      >
                        {item.user_id}
                      </p>
                    </td>
                    <td
                      className="cursor-pointer text-start"
                      onClick={() => {
                        navigate(`/admin/user/view/${item.id}`);
                      }}
                    >
                      <p
                        className="  mb-0 px-2"
                        style={{ color: "#5A78F0", fontSize: "13px" }}
                      >
                        {item.username}
                      </p>
                    </td>
                    <td
                      className="cursor-pointer text-start"
                      onClick={() => {
                        navigate(`/admin/user/view/${item.id}`);
                      }}
                    >
                      <div className="d-flex px-2 py-1">
                        <div
                          style={{
                            height: "36px",
                            width: "36px",
                            marginRight: "10px",
                          }}
                        >
                          <ProfileImage
                            alt="avatar"
                            src={item.display_pic}
                            className="avatar avatar-sm "
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6
                            className="mb-0 text-sm"
                            style={{ color: "#5A78F0" }}
                          >
                            {item.first_name} {item.last_name}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td className="text-start px-4">
                      <p className="text-sm  mb-0">{item.gender}</p>
                    </td>
                    <td text-start>
                      <p className="text-sm  mb-0">{item?.department?.title}</p>
                    </td>
                    <td className="align-middle text-start text-sm">
                      <p className="text-sm  mb-0">
                        {item?.designation?.title}
                      </p>
                    </td>
                    <td className="align-middle text-start">
                      <p className="text-sm  mb-0">
                        {lineOverFlowDot(item.email, 20)}
                      </p>
                    </td>
                    <td className="align-middle text-start">
                      <p className="text-sm  mb-0">{item.phone_number}</p>
                    </td>
                    <td className="align-middle text-start">
                      <p className="text-sm  mb-0">
                        {formatDate(item.created_at, 2)}
                      </p>
                    </td>
                    <td className="align-middle text-center">
                      {item.user_status === "active" ? (
                        <button
                          onClick={() => handleStatus(item.id)}
                          className="badge bg-success text-white"
                        >
                          Active
                        </button>
                      ) : item.user_status === "in_active" ? (
                        <button
                          onClick={() => handleStatus(item.id)}
                          className="badge bg-danger text-white"
                        >
                          Inactive
                        </button>
                      ) : item.user_status === "hold" ? (
                        <button
                          onClick={() => handleStatus(item.id)}
                          className="badge bg-danger text-white"
                        >
                          Hold
                        </button>
                      ) : item.user_status === "pending" ? (
                        <button
                          onClick={() => handleStatus(item.id)}
                          className="badge bg-danger text-white"
                        >
                          Pending
                        </button>
                      ) : item.user_status === "blocked" ? (
                        <button
                          onClick={() => handleStatus(item.id)}
                          className="badge bg-danger text-white"
                        >
                          Blocked
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <Link
                          to={`/admin/user/view/${item.id}`}
                          className="btn btn-sm px-3 mt-2"
                        >
                          &#128065;
                        </Link>
                        <button
                          type="button"
                          className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v text-xs"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/admin/user/edit/${item.id}`}
                            >
                              Edit
                            </Link>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                setOpenAddModal(true);
                                setDelId(item.id);
                              }}
                            >
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
            </CommonTable>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"SUBMIT"}
        createAddTopic={"Delete Sub-Admin"}
        confirmText={"Delete The Sub-Admin"}
        isOpen={openAddModal}
        onSubmit={() => deleteUser(DelId)}
        onClose={() => setOpenAddModal(false)}
      />
      {changeStatusModal ? (
        <div className="changeStatus">
          <div className="changeStatusBody">
            <div className="changeStatusBodyTop">
              <i className="fa-solid fa-circle-exclamation fa-rotate-180"></i>
              <p>Change The User Status</p>
            </div>
            <div className="changeStatusBodySecPart">
              <select
                name=""
                id=""
                value={changeStatusVal}
                onChange={(e) => setChangeStatusVal(e.target.value)}
              >
                <option value="active">Active</option>
                <option value="in_active">Inactive</option>
                <option value="pending">Pending</option>
                <option value="hold">Hold</option>
                <option value="blocked">Blocked</option>
              </select>
            </div>
            <div className="changeStatusBodyThirdPart">
              <p>Are You Sure You Want to Change The Status?</p>
            </div>
            <div className="changeStatusBodyLastPart">
              <button
                onClick={() => setChangeStatusModal(false)}
                className="changeStatusBtnClose"
              >
                CLOSE
              </button>
              <button onClick={setStatus} className="changeStatusBtnSubmit">
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
