import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import "../../dashboard/company/Companydashboard.css";

import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

import useHttp from "../../Hooks/useHttp";

import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import AddModal from "../../common/modal/AddModal";
import AppContext from "../../Context/AppContext";
import CommonTable from "../../common/Table/CommonTable";
import ProfileImage from "../../../components/ProfileImage";
import { customToast } from "../../../App";

export default function Companydashboard() {
  const { invokeApi } = HTTPService();
  const { setCircularLoading } = useContext(AppContext);
  const Breadcrumbname = {
    home: "/admin/home",
    "Master Module": "/admin/company/dashboard",
    Company: "/admin/company/dashboard",
    Dashboard: "/admin/company/dashboard",
  };
  const [openAddModal, setOpenAddModal] = useState({ isOpen: false });

  const { GetRequest, PutRequest, DeleteRequest } = useHttp();
  const [companydata, setCompanydata] = useState({});
  const [companyAddressdata, setCompanyAddressdata] = useState();
  const { setLoading } = useContext(AppContext);
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const API = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();
  const filterColumn = [
    {
      name: "Status",
      value: "company_status",
      filters: [
        { value: "active", label: "Active" },
        { value: "in_active", label: "Inactive" },]
    }
  ];
  const fetchCompany = async (hide) => {
    if (!hide)
      setLoading(true);
    let url = `${API}company/?page=${currentPage}&page_size=25`;
    if (searchQuery && searchQuery.length > 0) {
      url = url + `&${searchValue}=${searchQuery}`;
    }
    if (filterType && filterValue && filterValue.length > 0) {
      url = url + `&${filterType}=${filterValue}`;
    }
    const { data, error } = await GetRequest(
      url,
      {},
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "application/json",
      }
    );
    if (error) {
      setLoading(false);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setLoading(false);

      setCompanydata(data);
      setTotalPages(data.total_pages);
      //let cData=data.results
      //setCompanydata({...companydata,cData});
    }
  };
  useEffect(() => {
    // if (searchQuery && searchQuery.length > 0) {
    fetchCompany(true);
    // }
  }, [searchQuery, currentPage, filterValue]);

  const fetchCompanyAddr = async (stsId) => {
    const { data, error } = await GetRequest(
      API + "company_address",
      {},
      {
        Authorization: "Bearer  " + token,
        // "Content-Type": "multipart/form-data",
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      //let cData=data.results
      //setCompanydata({...companydata,cData});
      setCompanyAddressdata(data);
    }
  };
  const handleDelete = (id) => {
    setCircularLoading(true);
    setOpenAddModal({ ...openAddModal, isOpen: false });
    invokeApi({
      method: "DELETE",
      url: `/company/${id}/`,
    })
      .then((response) => {
        setCircularLoading(false);
        customToast.success("Delete Successfull", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        fetchCompany();
      })
      .catch((error) => {
        setCircularLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchCompany();
    fetchCompanyAddr();
  }, []);

  const tableColumn = [
    "Company ID",
    { label: "Company Profile", align: "center" },
    "Legal Name",
    "Email",
    "Contact",
    "City",
    { label: "Allotment Type", align: "center" },
    "Status",
    "Action",
  ];
  const searchColumn = [
    { name: "Company Id", value: "id" },
    { name: "Legal Name", value: "legal_name" },
    { name: "Allotment Type", value: "allotment_type" },
    { name: "Contact", value: "phone_number" },
  ];

  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              <Link
                to="/admin/company/add"
                className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                id="addcompanyprofile"
              >
                Add Company Profile
              </Link>
            </div>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12">
            <CommonTable
              filterList={filterColumn}
              filterType={filterType}
              setFilterType={setFilterType}
              setFilterValue={setFilterValue}
              column={tableColumn}
              data={companydata && companydata.results}
              totalPages={totalPages ?? 1}
              totalData={companydata?.total_records}
              searchBar={true}
              searchBy={searchColumn}
              itemPerPg={25}
              setTable={1}
              card={7}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            >
              {companydata &&
                companydata.results &&
                companydata.results.map((item, index) => (
                  <tr key={item.id} style={{ color: "black" }}>
                    <td>
                      <Link
                        to={`/admin/company/view/${item.id}`}
                        className="gotoView  mb-0 px-2"
                        style={{ fontSize: "12px" }}
                      >
                        {item.id}
                      </Link>
                    </td>
                    <td className="text-center">
                      <div className=" px-2 py-1">
                        <Link to={`/admin/company/view/${item.id}`}>
                          <ProfileImage
                            src={item.leader_logo}
                            width="50px"
                            className="avatar avatar-sm me-3"
                          />
                        </Link>
                      </div>
                    </td>
                    <td className="text-start">
                      <Link
                        to={`/admin/company/view/${item.id}`}
                        className="text-sm gotoView  mb-0"
                      >
                        {item.legal_name}
                      </Link>
                    </td>

                    <td className="text-start">
                      <p className="text-sm text-start mb-0">{item.email}</p>
                    </td>
                    <td className="align-middle text-start text-sm">
                      <p className="text-sm  mb-0">{item.phone_number}</p>
                    </td>
                    <td className="align-middle text-start">
                      <p className="text-sm  mb-0">
                        {companyAddressdata &&
                          companyAddressdata.results &&
                          companyAddressdata.results?.map((address) =>
                            address.company === item.id ? address.city : ""
                          )}
                      </p>
                    </td>

                    <td className="align-middle text-center">
                      <p className="text-sm  mb-0">{item.allotment_type}</p>
                    </td>
                    <td className="align-middle text-center">
                      {item.company_status === "active" ? (
                        <span className="badge bg-success text-white">
                          Active
                        </span>
                      ) : (
                        <span className="badge bg-danger text-white">
                          Inactive
                        </span>
                      )}
                    </td>
                    <td className="align-middle text-center">
                      <div
                        className="btn-group "
                        style={{ position: "static" }}
                      >
                        <Link
                          to={`/admin/company/view/${item.id}`}
                          className="btn bg-gradient-dark mt-2"
                        >
                          View
                        </Link>
                        <button
                          type="button"
                          className="btn bg-gradient-dark mt-2 dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-reference="parent"
                        >
                          <i
                            className="fa fa-ellipsis-v text-xs"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <ul className="dropdown-menu shadow-lg bg-white rounded py-0">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/admin/company/edit/${item.id}`}
                            >
                              Edit
                            </Link>
                          </li>
                          <li>
                            <button
                              onClick={() =>
                                setOpenAddModal({
                                  isOpen: true,
                                  id: item.id,
                                })
                              }
                              className="dropdown-item"
                            >
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
            </CommonTable>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"DELETE"}
        createAddTopic={"Delete Company"}
        confirmText={"Delete The Company"}
        isOpen={openAddModal.isOpen}
        onSubmit={() => handleDelete(openAddModal.id)}
        onClose={() => setOpenAddModal({ isOpen: false })}
      />
    </>
  );
}
