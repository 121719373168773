import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import { acceptedFileTypes } from "../../../utils/helperFunctions";
import CircularLoader from "../../CircularLoader/CircularLoader";
import AddModal from "../../common/modal/AddModal";
import "./SubmissionDashboard.css";
import { getUserInfo } from "../../../reducers/authSlice";
import { useSelector } from "react-redux";
import { customToast } from "../../../App";

export default function AddSubmission() {
  const { invokeApi } = HTTPService();
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [attchFile, setAttchFile] = useState([]);
  const [targetUrlInput, setTargetUrlInput] = useState([]);
  const [userType, setUserType] = useState();
  const [programOpt, setProgramOpt] = useState();
  const [userOpt, setUserOpt] = useState();
  const [severityOpt, setSeverityOpt] = useState();
  const [priorityOpt, setPriorityOpt] = useState();
  const [lfValue, setLfValue] = useState("");
  const [lfArray, setLfArray] = useState([]);
  const [aeValue, setAeValue] = useState("");
  const [aeArray, setAeArray] = useState([]);
  const [totaValue, setTotaValue] = useState("");
  const [totaArray, setTotaArray] = useState([]);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [valids, setValids] = useState({});
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();
  const [taxonomyData, setTaxonomyData] = useState();
  const { userInfo } = useSelector(getUserInfo)

  const getVtxSubmissions = () => {
    invokeApi({
      method: "GET",
      url: `submissions-vtx`,
    }).then((data) => {
      setTaxonomyData(data.results.map(dat => dat.vtx_code));
    }).catch((error) => {
    });
  };
  const currDate = () => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;
  };

  //set type
  const setType = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please select type",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //set user
  const setUser = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please select user",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set type
  const setProgramVal = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Select Program",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set title
  const setTitle = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Title",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //set description
  const setDescription = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Description",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set reproduce step
  const setStep = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Steps",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set REMEDIATION
  const setRemediation = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter remediation recommendation",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set Severity
  const setSeverity = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Select Severity",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set Priority
  const setPriority = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Select Priority",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set Vtx
  const setVtx = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!taxonomyData.includes(value)) {
      setErrors({
        ...errors,
        [field]: "Please enter valid VTX code",
      });
      setValids({
        ...valids,
        [field]: false,
      });
      return
    }
    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Vtx",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set Target
  const setTarget = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Target",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  //set Target Url
  const setTargetUrl = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Target Url",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const handleAddInput = (e) => {
    e.preventDefault();
    if (targetUrlInput.length < 4) {
      setTargetUrlInput([...targetUrlInput, ""]);
    }
  };
  const deleteTargateBugUrl = (e, i) => {
    e.preventDefault();
    const newTargetUrl = [...targetUrlInput];
    newTargetUrl.splice(i, 1);
    setTargetUrlInput(newTargetUrl);

    console.log("delete", targetUrlInput);
  };

  const fetchProgramlist = async () => {
    invokeApi({
      method: "GET",
      url: `programs/`,
      params: {
        program_status: "approved",
        researcher_id: form?.user_id,
      },
    })
      .then((data) => {
        setProgramOpt(data);
      })
      .catch((error) => { });
  };

  const handleChange = (index, event) => {
    const newInputs = [...targetUrlInput];
    newInputs[index] = event.target.value;
    setTargetUrlInput(newInputs);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const newFiles = Array.from(selectedFiles);
    for (const file of newFiles) {
      if (file.size > 5 * 1024 * 1024) {
        return customToast.error(`File "${file.name}" exceeds the maximum upload size of 5MB.`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }
      if (!acceptedFileTypes.includes(file?.type)) {
        return customToast.error(`File type error`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }
    }
    if (files.length + newFiles.length > 5) {
      return customToast.error(`You can only upload a maximum of 5 files.`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
    }
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    console.log(files);
  };
  const handleAttachFileChange = (event) => {
    const selectedAttchFiles = event.target.files;
    const newAttchFiles = Array.from(selectedAttchFiles);
    for (const file of newAttchFiles) {
      if (file.size > 5 * 1024 * 1024) {
        return customToast.error(`File "${file.name}" exceeds the maximum upload size of 5MB.`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }
      if (!acceptedFileTypes.includes(file?.type)) {
        return customToast.error(`File type error`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }
    }
    if (attchFile.length + newAttchFiles.length > 5) {
      return customToast.error(`You can only upload a maximum of 5 files.`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
    }
    setAttchFile((prevAttchFiles) => [...prevAttchFiles, ...newAttchFiles]);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };
  const handleRemoveAttachFile = (index) => {
    setAttchFile((prevAttchFiles) => {
      const newAttchFiles = [...prevAttchFiles];
      newAttchFiles.splice(index, 1);
      return newAttchFiles;
    });
  };

  const addLanguageFrameworks = (e) => {
    if (e.keyCode === 13 && lfValue) {
      e.preventDefault();
      setLfArray([...lfArray, lfValue]);
      setLfValue("");
    } else {

    }
  };
  const addAE = (e) => {
    if (e.keyCode === 13 && aeValue && e.key === 'Enter') {
      e.preventDefault();
      setAeArray([...aeArray, aeValue]);
      setAeValue("");
    } else {

    }
  };
  const addTota = (e) => {
    if (e.keyCode === 13 && totaValue) {
      e.preventDefault();
      e.stopPropagation();
      setTotaArray([...totaArray, totaValue]);
      setTotaValue("");
    } else {

    }
  };
  const deleteLF = (val) => {
    let focusLf = lfArray.filter((t) => t !== val);
    setLfArray(focusLf);
  };
  const deleteAe = (val) => {
    let focusAe = aeArray.filter((t) => t !== val);
    setAeArray(focusAe);
  };
  const deleteTota = (val) => {
    let focusTota = totaArray.filter((t) => t !== val);
    setTotaArray(focusTota);
  };

  const fetchValueOpt = async () => {
    invokeApi({
      method: "GET",
      url: `user/roles/`,
    })
      .then((res) => {
        setUserType(res);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const fetchSeverity = async () => {
    invokeApi({
      method: "GET",
      url: `basevalues/?title_code=Severity`,
    })
      .then((data) => {
        setSeverityOpt(data.results);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const fetchPriority = async () => {
    invokeApi({
      method: "GET",
      url: `basevalues/?title_code=Priority`,
    })
      .then((data) => {
        setPriorityOpt(data.results);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const checkallValidity = () => {
    const {
      user_type,
      user_details,
      program_id,
      submission_title,
      user_id,
      step_to_reproduce, vtx, priority, severity, target_url1, target_title, remediation_recommendation
    } = form;
    const allError = {};

    if (!user_type || user_type === "")
      allError.user_type = "Please select user type";
    if ((!user_details || user_details === "") && (!user_id || user_id === ""))
      allError.user_id = "Please select user";
    if (!program_id || program_id === "")
      allError.program_id = "Please select program";
    if (!submission_title || submission_title === "")
      allError.submission_title = "Please enter submisison title";

    if (!step_to_reproduce || step_to_reproduce === "")
      allError.step_to_reproduce = "Please enter step to reproduce";
    if (!severity) allError.severity = "Please enter value";
    if (!priority) allError.priority = "Please enter value";
    if (!vtx) allError.vtx = "Please enter value";
    if (!target_title) allError.target_title = "Please enter target title";
    if (totaArray.length < 1) allError.totaArray = "Please enter atleast one value";
    if (lfArray.length < 1) allError.lfArray = "Please enter atleast one value";
    if (aeArray.length < 1) allError.aeArray = "Please enter atleast one value";
    if (!target_url1) allError.target_url1 = "At least one target URL must be provided";

    if (!remediation_recommendation)
      allError.remediation_recommendation = "Please enter remediation recommendation"
    if (files.length < 1) allError.files = "Please upload atleast one document";


    return allError;
  };
  const finalSubmit = async () => {
    const checkError = checkallValidity();
    if (Object.keys(checkError).length > 0) {
      setErrors(checkError);
    } else {
      const param = {
        ...form,
        user: form.user_id,
        type_of_testing_allowed: totaArray.toString(),
        languages_frameworks: lfArray.toString(),
        asset_environments: aeArray.toString(),
        date: currDate(),
        submission_status: "new",

        target_url2:
          targetUrlInput && targetUrlInput[0] ? targetUrlInput[0] : null,
        target_url3:
          targetUrlInput && targetUrlInput[1] ? targetUrlInput[1] : null,
        target_url4:
          targetUrlInput && targetUrlInput[2] ? targetUrlInput[2] : null,
        target_url5:
          targetUrlInput && targetUrlInput[3] ? targetUrlInput[3] : null,
        evidence_attachments1: files && files[0] ? files[0] : null,
        evidence_attachments2: files && files[1] ? files[1] : null,
        evidence_attachments3: files && files[2] ? files[2] : null,
        evidence_attachments4: files && files[3] ? files[3] : null,
        evidence_attachments5: files && files[4] ? files[4] : null,
        attachments_upload_doc1:
          attchFile && attchFile[0] ? attchFile[0] : null,
        attachments_upload_doc2:
          attchFile && attchFile[1] ? attchFile[1] : null,
        attachments_upload_doc3:
          attchFile && attchFile[2] ? attchFile[2] : null,
        attachments_upload_doc4:
          attchFile && attchFile[3] ? attchFile[3] : null,
        attachments_upload_doc5:
          attchFile && attchFile[4] ? attchFile[4] : null,
      };

      setLoading(true);
      setOpenAddModal(false);
      invokeApi({
        method: "POST",
        url: `submissions/`,
        data: param,
      })
        .then((data) => {
          setLoading(false);
          navigate("/admin/submissions");
        })
        .catch((error) => {
          setLoading(false);
          customToast.error(error?.data?.detail?.toString() ?? "Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setLoading(false);
        });
    }
  };
  const handleFinalClick = async () => {
    const checkError = checkallValidity();
    if (Object.keys(checkError).length > 0) {
      setErrors(checkError);
      console.log("errors", checkError)
      setOpenAddModal(false);
      customToast.error("Reolved all errors!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else if (
      totaArray === undefined ||
      lfArray === undefined ||
      aeArray === undefined ||
      attchFile === undefined
    ) {
      return;
    } else {
      setOpenAddModal(true);
    }
  };

  useEffect(() => {
    if (form && form.user_type) {
      const fetchUserList = async () => {
        invokeApi({
          method: "GET",
          url: `/user/list_by_role/?role=${form.user_type}`,
        })
          .then((data) => {
            setUserOpt(data);
          })
          .catch((error) => {
            customToast.error("Something went wrong!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          });
      };
      fetchUserList();
    }
  }, [form.user_type]);
  useEffect(() => {
    if (userInfo?.user_data?.id) {
      fetchSeverity();
      fetchPriority();
      fetchValueOpt();
      getVtxSubmissions();
    }
  }, [userInfo?.user_data?.id]);

  useEffect(() => {
    if (form?.user_id) fetchProgramlist();
  }, [form?.user_id]);
  return (
    <>
      {loading && <CircularLoader size={100} />}

      <div className="container ">
        <div className="row">
          <div className="col-12 mt-4 ">
            <div className="card">
              <div className="row ">
                <div className="card mt-0 " id="basic-info">
                  <div className="card-header">
                    <p
                      style={{
                        color: "#000",
                        fontWeight: "600",
                        marginBottom: "0",
                        fontSize: "17px",
                      }}
                    >
                      New Submission
                    </p>
                    <p style={{ color: "#B5C6DE", fontSize: "13px" }}>
                      Create a New Submission
                    </p>
                  </div>
                  <div className="card-body pt-0">
                    <Form>
                      <div className="row mb-3">
                        <Form.Group as={Col} md="6">
                          <Form.Label
                            className="form-label"
                            style={{ fontSize: "14px" }}
                          >
                            Select Type
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>

                          <Form.Control
                            as="select"
                            className="form-select"
                            name="choices-gender"
                            id="choices-gender"
                            value={form && form.user_type && form.user_type}
                            onChange={(e) =>
                              setType("user_type", e.target.value)
                            }
                            isInvalid={!!errors.user_type}
                            isValid={
                              valids.user_type && valids.user_type === true
                            }
                          >
                            <option value="" selected>
                              Select User Type
                            </option>
                            {userType &&
                              userType?.results.map((item, index) => {
                                // console.log(item);
                                if (item.role.toLowerCase() === "researcher")
                                  return (
                                    <option
                                      key={index}
                                      value={item.role}
                                      className="text-capitalize"
                                    >
                                      {item.role}
                                    </option>
                                  );
                              })}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.user_type}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                          <Form.Label
                            className="form-label"
                            style={{ fontSize: "14px" }}
                          >
                            Select User{" "}
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>

                          <Form.Control
                            as="select"
                            className="form-select"
                            name="choices-gender"
                            id="choices-gender"
                            value={form && form.user_id && form.user_id}
                            onChange={(e) => setUser("user_id", e.target.value)}
                            isInvalid={!!errors.user_id}
                            isValid={valids.user_id && valids.user_id === true}
                          >
                            <option value="" selected>
                              Select User
                            </option>
                            {userOpt &&
                              userOpt.results &&
                              userOpt.results.map(
                                (item, index) =>
                                  item.user_status === "active" && (
                                    <option key={index} value={item.id}>
                                      {item.username} {item.user_id}
                                    </option>
                                  )
                              )}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.user_details}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="row mb-3">
                        <Form.Group as={Col} md="12">
                          <Form.Label
                            className="form-label"
                            style={{ fontSize: "14px" }}
                          >
                            Select Program
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>

                          <Form.Control
                            as="select"
                            className="form-select"
                            name="choices-gender"
                            id="choices-gender"
                            value={form && form.program_id && form.program_id}
                            onChange={(e) =>
                              setProgramVal("program_id", e.target.value)
                            }
                            isInvalid={!!errors.program_id}
                            isValid={
                              valids.program_id && valids.program_id === true
                            }
                          >
                            <option value="" selected>
                              Select Program
                            </option>
                            {programOpt &&
                              programOpt.results &&
                              programOpt.results.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.program_title} -- {item.program_id}
                                </option>
                              ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.program_id}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="row">
                        <Form.Group as={Col} md="12">
                          <Form.Label
                            className="form-label mt-4"
                            style={{ fontSize: "14px" }}
                          >
                            Title
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>
                          <p
                            style={{
                              color: "#B2C3DD",
                              marginBottom: "10px",
                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            (The Title or Short Description of the Vulnerability
                            Report)
                          </p>
                          <div className=" ">
                            <Form.Control
                              className="form-control"
                              placeholder="Enter a Submission Title"
                              type="text"
                              value={
                                form &&
                                form.submission_title &&
                                form.submission_title
                              }
                              onChange={(e) =>
                                setTitle("submission_title", e.target.value)
                              }
                              isInvalid={!!errors.submission_title}
                              isValid={
                                valids.submission_title &&
                                valids.submission_title === true
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.submission_title}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </div>

                      <div className="row">
                        <Form.Group as={Col} md="12">
                          <Form.Label
                            className="form-label mt-4"
                            style={{ fontSize: "14px" }}
                          >
                            Detail Description
                          </Form.Label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={
                              form &&
                              form.detail_description &&
                              form.detail_description
                            }
                            onChange={(e, editor) => {
                              setDescription(
                                "detail_description",
                                editor.getData()
                              );

                              // checkValidation();
                            }}
                          />
                          {form.detail_description === " " ||
                            form.detail_description === undefined ||
                            form.detail_description === null ? (
                            <p style={{ color: "#f00", fontSize: "14px" }}>
                              Please Enter Detail Description
                            </p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group as={Col} md="12">
                          <Form.Label
                            className="form-label mt-4"
                            style={{ fontSize: "14px" }}
                          >
                            Step to Reproduce
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={
                              form &&
                              form.step_to_reproduce &&
                              form.step_to_reproduce
                            }
                            onChange={(e, editor) =>
                              setStep("step_to_reproduce", editor.getData())
                            }
                          />
                          {!form.step_to_reproduce && errors?.step_to_reproduce && (
                            <p className="invalid-warning" style={{ color: "#f00", fontSize: "14px", display: 'block' }}>
                              {errors?.step_to_reproduce}
                            </p>
                          )}
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group as={Col} md="12">
                          <Form.Label
                            className="form-label mt-4"
                            style={{ fontSize: "14px" }}
                          >
                            Remediation Recommendation
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={
                              form &&
                              form.remediation_recommendation &&
                              form.remediation_recommendation
                            }
                            onChange={(e, editor) =>
                              setRemediation(
                                "remediation_recommendation",
                                editor.getData()
                              )
                            }
                          />
                        </Form.Group>
                        {!form.remediation_recommendation && errors?.remediation_recommendation && (
                          <p className="invalid-warning" style={{ color: "#f00", fontSize: "14px", display: 'block' }}>
                            {errors?.remediation_recommendation}
                          </p>
                        )}
                      </div>
                      <div className="row mt-3">
                        <Form.Group as={Col} md="6">
                          <Form.Label
                            className="form-label"
                            style={{ fontSize: "14px" }}
                          >
                            Severity{" "}
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>

                          <Form.Control
                            as="select"
                            className="form-select"
                            name="choices-gender"
                            id="choices-gender"
                            value={form && form.severity && form.severity}
                            onChange={(e) =>
                              setSeverity("severity", e.target.value)
                            }
                            isInvalid={!!errors.severity}
                            isValid={
                              valids.severity && valids.severity === true
                            }
                          >
                            <option value="" selected>
                              Select Severity
                            </option>
                            {severityOpt &&
                              severityOpt[0].value_options
                                ?.split(",")
                                .map((item, index) => (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.severity}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                          <Form.Label
                            className="form-label"
                            style={{ fontSize: "14px" }}
                          >
                            Priority{" "}
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>

                          <Form.Control
                            as="select"
                            className="form-select"
                            name="choices-gender"
                            id="choices-gender"
                            value={form && form.priority && form.priority}
                            onChange={(e) =>
                              setPriority("priority", e.target.value)
                            }
                            isValid={
                              valids.priority && valids.priority === true
                            }
                            isInvalid={!!errors.priority}
                          >
                            <option value="" selected>
                              Select priority
                            </option>
                            {priorityOpt &&
                              priorityOpt[0].value_options
                                ?.split(",")
                                .map((item, index) => (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.priority}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group as={Col} md="6">
                          <Form.Label
                            className="form-label mt-4"
                            style={{ fontSize: "14px" }}
                          >
                            VTx
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>

                          <div className=" ">
                            <Form.Control
                              className="form-control"
                              placeholder="Enter VTx"
                              type="text"
                              value={form && form.vtx && form.vtx}
                              onChange={(e) => setVtx("vtx", e.target.value)}
                              isValid={valids.vtx && valids.vtx === true}
                            />
                            {errors?.vtx && <div className="invalid-warning" style={{ display: 'block' }}>
                              {errors.vtx}
                            </div>}
                          </div>
                          <a
                            href="/admin/vtxtaxonomy/dashboard"
                            className="link"
                            target="_blank"
                          >
                            View Vtx Dashboard
                          </a>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                          <Form.Label
                            className="form-label mt-4"
                            style={{ fontSize: "14px" }}
                          >
                            CVSS Score
                          </Form.Label>

                          <div className=" ">
                            <div
                              className="form-control"
                              style={{ backgroundColor: "#99D9EA" }}
                            >
                              <p
                                style={{
                                  marginBottom: "0",
                                  color: "#000",
                                  fontWeight: "600",
                                }}
                              >
                                System Generate Calculation
                              </p>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group as={Col} md="12">
                          <Form.Label
                            className="form-label mt-4"
                            style={{ fontSize: "14px" }}
                          >
                            Target Title
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>

                          <div className=" ">
                            <Form.Control
                              className="form-control"
                              placeholder="Enter a Target Name"
                              type="text"
                              value={
                                form && form.target_title && form.target_title
                              }
                              onChange={(e) =>
                                setTarget("target_title", e.target.value)
                              }
                              isValid={
                                valids?.target_title &&
                                valids?.target_title === true
                              }
                            />
                          </div>

                        </Form.Group>
                        {!form?.target_title && errors?.target_title && (
                          <div className="invalid-warning" style={{ display: 'block' }}>
                            {errors?.target_title}
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <Form.Group as={Col} md="12">
                          <Form.Label
                            className="form-label mt-4"
                            style={{ fontSize: "14px" }}
                          >
                            Target Bug URL    <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>

                          <div className=" d-flex flex-column">
                            <div className="d-flex">
                              <div style={{ width: "70%" }}>
                                <Form.Control
                                  className="form-control"
                                  placeholder="Enter URL"
                                  type="text"
                                  value={
                                    form && form.target_url1 && form.target_url1
                                  }
                                  onChange={(e) =>
                                    setTargetUrl("target_url1", e.target.value)
                                  }
                                  isInvalid={!!errors.target_url1}
                                  isValid={
                                    valids.target_url1 &&
                                    valids.target_url1 === true
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.target_url1}
                                </Form.Control.Feedback>
                              </div>
                              <div>
                                <button
                                  type="button"
                                  onClick={handleAddInput}
                                  style={{ borderRadius: "8px" }}
                                  className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                                >
                                  Add +
                                </button>
                              </div>
                            </div>
                            {targetUrlInput.map((input, index) => (
                              <div key={index} className="d-flex mt-3 ">
                                <div style={{ width: "70%" }}>
                                  <Form.Control
                                    className="form-control"
                                    placeholder="Enter URL"
                                    type="text"
                                    key={index}
                                    value={input}
                                    onChange={(e) => handleChange(index, e)}
                                    isValid={
                                      input && input.value !== "" ? true : false
                                    }
                                  />
                                </div>
                                <div>
                                  <button
                                    button="type"
                                    onClick={(e) =>
                                      deleteTargateBugUrl(e, index)
                                    }
                                    style={{ borderRadius: "8px" }}
                                    className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                                  >
                                    X
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>

                          {/* {!form.target_url1 && errors?.target_url1 && (
                            <p className="invalid-warning" style={{ display: 'block' }}>
                              {errors?.target_url1}
                            </p>
                          )} */}
                        </Form.Group>
                      </div>
                      <div className="row mb-3 mt-4" onClick={e => e.preventDefault()}>
                        <Form.Group as={Col} md="12">
                          <Form.Label
                            className="form-label"
                            style={{ fontSize: "14px" }}
                          >
                            Types of Testing Allowed
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>

                          <div className="form-control  languageFrameworkDiv">
                            {totaArray.map((item, index) => {
                              return (
                                <div key={index} className="LfElement">
                                  <span>{item}</span>
                                  <span
                                    onClick={(val) => deleteTota(item)}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <i className="fa-solid fa-xmark"></i>
                                  </span>
                                </div>
                              );
                            })}

                            <input
                              type="text"
                              placeholder="Add Type of Testing Allowed"
                              value={totaValue}
                              onChange={(e) => setTotaValue(e.target.value)}
                              onKeyDown={addTota}
                            />
                          </div>
                          {totaArray && totaArray.length < 1 && errors?.totaArray && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.totaArray}
                            </div>
                          )}
                        </Form.Group>
                      </div>
                      <div className="row mb-3 mt-4">
                        <div className="col-6">
                          <Form.Label
                            className="form-label"
                            style={{ fontSize: "14px" }}
                          >
                            Languages & Frameworks
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>

                          <div className="form-control  languageFrameworkDiv">
                            {lfArray.map((item, index) => {
                              return (
                                <div key={index} className="LfElement">
                                  <span>{item}</span>
                                  <span
                                    onClick={(val) => deleteLF(item)}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <i className="fa-solid fa-xmark"></i>
                                  </span>
                                </div>
                              );
                            })}
                            <input
                              type="text"
                              placeholder="Add Language or Framework"
                              value={lfValue}
                              onChange={(e) => setLfValue(e.target.value)}
                              onKeyDown={addLanguageFrameworks}
                            />
                          </div>
                          {lfArray && lfArray.length < 1 && errors.lfArray && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.lfArray}
                            </div>
                          )}
                        </div>
                        <div className="col-6">
                          <Form.Label
                            className="form-label "
                            style={{ fontSize: "14px" }}
                          >
                            Asset Environments
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              *
                            </span>
                          </Form.Label>

                          <div className="form-control  languageFrameworkDiv">
                            {aeArray.map((item, index) => {
                              return (
                                <div key={index} className="LfElement">
                                  <span>{item}</span>
                                  <span
                                    onClick={(val) => deleteAe(item)}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <i className="fa-solid fa-xmark"></i>
                                  </span>
                                </div>
                              );
                            })}
                            <input
                              type="text"
                              placeholder="Add Asset Environments"
                              value={aeValue}
                              onChange={(e) => setAeValue(e.target.value)}
                              onKeyDown={addAE}
                            />
                          </div>
                          {aeArray && aeArray.length < 1 && errors.aeArray && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.aeArray}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mb-3 mt-4">
                        <div className="col-6">
                          <div className="d-flex justify-content-between">
                            <Form.Label
                              className="form-label"
                              style={{ fontSize: "14px" }}
                            >
                              Evidence Attachments
                              <span style={{ color: "red", marginLeft: "3px" }}>
                                *
                              </span>
                            </Form.Label>
                            <p
                              style={{
                                marginBottom: "0",
                                fontSize: "10px",
                              }}
                            >
                              Max Upload Size: 5 MB
                            </p>
                          </div>

                          <div className="form-control dotted">
                            <div className="file-upload w-100">
                              {files.length === 0 ? (
                                <p>Drop File Here To Upload</p>
                              ) : (
                                files.map((file, index) => (
                                  <div
                                    key={index}
                                    className="d-flex justify-content-between align-items-center"
                                  >
                                    <div className="text-overflow txtWidth">
                                      {file.name || file}
                                    </div>
                                    <i
                                      style={{ borderRadius: "8px", cursor: 'pointer', position: 'absolute', zIndex: 1000, right: 0 }}
                                      className="fa-solid fa-trash text-primary"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleRemoveFile(index);
                                      }}

                                    >

                                    </i>
                                  </div>
                                ))
                              )}
                              <Form.Control
                                type="file"
                                multiple="true"
                                onChange={handleFileChange}
                              />
                            </div>

                          </div>    {files && files.length < 1 && errors.files && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.files}
                            </div>
                          )}
                          <div className="d-flex justify-content-between">
                            <span className="text-xxs">
                              * File Formats Allowed (.jpg, .png, .webp, .pdf,
                              .xls, .doc, .docx, .csv, .ppt)
                            </span>
                            <span className="text-xxs">Max File Count: 5</span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="d-flex justify-content-between">
                            <Form.Label
                              className="form-label"
                              style={{ fontSize: "14px" }}
                            >
                              Attachments(Upload Documents)
                            </Form.Label>
                            <p
                              style={{
                                marginBottom: "0",
                                fontSize: "10px",
                              }}
                            >
                              Max Upload Size: 5 MB
                            </p>
                          </div>

                          <div className="form-control dotted">
                            <div className="file-upload w-100">
                              {attchFile.length === 0 ? (
                                <p>Drop File Here To Upload</p>
                              ) : (
                                attchFile.map((file, index) => (
                                  <div
                                    key={index}
                                    className="d-flex justify-content-between align-items-center"
                                  >
                                    <span className="text-overflow txtWidth">
                                      {file.name || file}
                                    </span>
                                    <i
                                      style={{ borderRadius: "8px", cursor: 'pointer', position: 'absolute', zIndex: 1000, right: 0 }}
                                      className="fa-solid fa-trash text-primary"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleRemoveAttachFile(index);
                                      }}
                                    >

                                    </i>
                                  </div>
                                ))
                              )}
                              <Form.Control
                                type="file"
                                onChange={handleAttachFileChange}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="text-xxs">
                              * File Formats Allowed (.jpg, .png, .webp, .pdf,
                              .xls, .doc, .docx, .csv, .ppt)
                            </span>
                            <span className="text-xxs">Max File Count: 5</span>
                          </div>
                        </div>
                      </div>
                    </Form>

                    <div className=" d-flex justify-content-end">
                      <Link
                        to="/admin/submissions"
                        className="btn  btn-sm float-end mt-4 mb-0 px-3"
                        style={{ backgroundColor: "#E9ECEF" }}
                      >
                        Cancel
                      </Link>
                      <button
                        type="button"
                        onClick={handleFinalClick}
                        className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
                      >
                        CREATE SUBMISSION
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"SUBMIT"}
        createAddTopic={"Add Submission"}
        confirmText={"Add The Submission"}
        isOpen={openAddModal}
        onSubmit={finalSubmit}
        onClose={() => setOpenAddModal(false)}
      />
    </>
  );
}
