import React, { useEffect, useState } from "react";
import "./customerDashboardStyle.css";
import Chart from "react-apexcharts";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import ProfileImage from "../../../../components/ProfileImage";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../../reducers/authSlice";
import HTTPService from "../../../../utils/axios";
import { customToast } from "../../../../App";

const Breadcrumbname = {
  home: "/customer/home",
  Dashboard: "",
};
const severityValues = {
  Critical: 5,
  Severe: 4,
  Moderate: 3,
  Low: 2,
  Informational: 1,
  null: 0,
};
const CustomerDashboard = () => {
  const ASSET_URL = process.env.REACT_APP_ASSET_URL;
  const { invokeApi } = HTTPService();
  const { userInfo } = useSelector(getUserInfo);
  const [customerPortfolioLoad, setCustomerPortfolioLoad] = useState(false);
  const [customerProgramLoad, setCustomerProgramLoad] = useState(false);
  const [researcherPortfolioLoad, setResearcherPortfolioLoad] = useState(false);
  const [customerPortfolio, setCustomerPortfolio] = useState([]);
  const [researcherPortfolio, setResearcherPortfolio] = useState([]);
  const [customerPrograms, setCustomerPrograms] = useState([]);
  const [programStats, setProgramStats] = useState([]);
  const [issueTracking, setIssueTracking] = useState([]);
  const [rewardManagement, setRewardmanagement] = useState([]);
  const [widgetStats, setWidgetStats] = useState({});

  const getCustomerPrograms = async () => {
    setCustomerProgramLoad(true);
    invokeApi({
      method: "GET",
      url: `widget/programs-customer-stats/`,
    })
      .then((data) => {
        setCustomerPrograms(data.customer_stats);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
    setCustomerProgramLoad(false);
  };

  const getCustomerPortfolio = async () => {
    setCustomerPortfolioLoad(true);
    invokeApi({
      method: "GET",
      url: `widget/customer-portfolio/`,
    })
      .then((data) => {
        setCustomerPortfolio(
          data.sort(
            (a, b) => b.total_submissions_count - a.total_submissions_count
          )
        );
        setCustomerPortfolioLoad(false);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setCustomerPortfolioLoad(false);
      });
  };
  const getResearcherPortfolio = async () => {
    setResearcherPortfolioLoad(true);
    invokeApi({
      method: "GET",
      url: `widget/researcher-leader-board/`,
    })
      .then((data) => {
        setResearcherPortfolioLoad(false);
        setResearcherPortfolio(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setResearcherPortfolioLoad(false);
      });
  };
  const getProgramStats = () => {
    invokeApi({
      method: "GET",
      url: `widget/programs-submission-stats/`,
    })
      .then((data) => {
        setProgramStats(data.program_submission_stats);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const getWidgetIssueTracking = () => {
    invokeApi({
      method: "GET",
      url: `widget/submission-issues-tracking/`,
    })
      .then((data) => {
        setIssueTracking(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const getWidgetRewardManagement = () => {
    invokeApi({
      method: "GET",
      url: `widget/reward-management`,
    })
      .then((data) => {
        setRewardmanagement(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const getWidgetStats = () => {
    // setResearcherPortfolioLoad(true);
    invokeApi({
      method: "GET",
      url: `widget/programs-stats/`,
    })
      .then((data) => {
        setWidgetStats(data.widget_stats);
      })
      .catch((error) => {
        // setResearcherPortfolioLoad(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  useEffect(() => {
    if (userInfo && userInfo.user_data) {
      getCustomerPortfolio();
      getResearcherPortfolio();
      getCustomerPrograms();
      getProgramStats();
      getWidgetIssueTracking();
      getWidgetRewardManagement();
      getWidgetStats();
    }
  }, [userInfo]);

  const issueOptions = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 7,
        borderRadiusApplication: "around",
        // borderRadiusWhenStacked: "last",
        columnWidth: "50%",
        barHeight: "70%",
        distributed: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
        hideZeroBarsWhenGrouped: false,
        isDumbbell: false,
        dumbbellColors: undefined,
        isFunnel: false,
        isFunnel3d: true,
        colors: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: undefined,
            },
          ],
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
        },
        dataLabels: {
          position: "top",
          maxItems: 100,
          hideOverflowingLabels: true,

          total: {
            enabled: false,
            formatter: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#373d3f",
              fontSize: "12px",
              fontFamily: undefined,
              fontWeight: 600,
            },
          },
        },
      },
    },
    xaxis: {
      categories: issueTracking && issueTracking.length > 0 ? [
        ...issueTracking.map((el) => {
          return el.vtx_main_category ?? '';
        }),
      ] : [],
      labels: {
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: false,
        showDuplicates: false,
        trim: true,
        minHeight: undefined,
        maxHeight: 120,

        style: {
          colors: "#65778E",
          fontSize: "12px",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 5,
      tickAmount: 5,
      labels: {
        formatter: function (value) {
          var val = value;
          if (val == 5) {
            val = "Critical";
          }
          if (val == 4) {
            val = "Severe";
          }
          if (val == 3) {
            val = "Moderate";
          }
          if (val == 2) {
            val = "Low";
          }
          if (val == 1) {
            val = "Informational";
          }
          return val;
        },
      },
    },
    background: {
      enabled: true,
      foreColor: "#fff",
      padding: 4,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: "#fff",
      opacity: 0.9,
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    series: [
      {
        name: "",
        data: [
          ...issueTracking.map((el) => {
            return severityValues[el.severity];
          }),
        ],
      },
    ],
    colors: [
      function ({ value, seriesIndex, w }) {
        if (value === 1) {
          return "#E6E6FA";
        }
        if (value === 2) {
          return "#FFDB96";
        }
        if (value === 3) {
          return "#ABCF9A";
        }
        if (value === 4) {
          return "#A4C2E6";
        }
        if (value === 5) {
          return "#FFD681";
        }
      },
    ],
  };
  const donutOptions = {
    chart: {
      type: "donut",
      width: '100%',
      height: '100%',
    },
    legend: {
      show: true,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val + "%"; // Custom tooltip format
        },
      },
    },
    series: [
      ...programStats.map((el) => {
        return el.percentage_of_total_submissions;
      }),
    ],
    labels: [
      ...programStats.map((el) => {
        return el.program_title;
      }),
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      show: false,
    },
  };
  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="row  p-0 m-0">
            {/* <div className="col-sm-12 col-md-6">
              <div className="card">
                <h5 className="p-3 text-center">Customer Portfolio</h5>
                <div className="table-responsive researcherProfile">
                  <table className="table align-items-center mb-0 researcherProfileTable">
                    <thead>
                      <tr>
                        <th className="text-uppercase  font-weight-bolder"></th>
                        <th className="text-uppercase  font-weight-bolder">
                          Active <br /> Programs
                        </th>
                        <th className="text-uppercase  font-weight-bolder">
                          Total <br /> Reports
                        </th>
                        <th className="text-uppercase  font-weight-bolder ps-2">
                          Resolved <br /> Reports
                        </th>
                        <th className="text-uppercase  font-weight-bolder ps-2">
                          Satisfaction <br /> Rating
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      {customerPortfolioLoad
                        ? Array(5)
                            .fill()
                            .map((item) => (
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="me-2">
                                      <div className="outsideBorder">
                                        <div className="customerProfilePic">
                                          <Skeleton
                                            height={"100%"}
                                            width={"100%"}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-start">
                                      <span className="text-xxs">
                                        <Skeleton height={13} width={'100%'} />
                                      </span>
                                      <p
                                        style={{ fontSize: "14px" }}
                                        className="mb-0"
                                      >
                                        <Skeleton height={14} width={120} />
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <p
                                    className="  mb-0 px-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <Skeleton height={14} width={'100%'} />
                                  </p>
                                </td>

                                <td className="cell-breakWord">
                                  <p
                                    className="  mb-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <Skeleton height={14} width={'100%'} />
                                  </p>
                                </td>
                                <td>
                                  <p
                                    className="  mb-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <Skeleton height={14} width={'100%'} />
                                  </p>
                                </td>
                                <td>
                                  <p
                                    className="  mb-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <i
                                      className="me-1 fa-solid fa-star"
                                      style={{ color: " #FFD43B" }}
                                    ></i>
                                    <Skeleton height={14} width={30} />
                                  </p>
                                </td>
                              </tr>
                            ))
                        : customerPortfolio &&
                          customerPortfolio.map(
                            (item, index) =>
                              index < 5 && (
                                <tr key={index}>
                                  <td style={{ padding: "0" }}>
                                    <div className="d-flex">
                                      <div className="me-2 p-2">
                                        <div className="outsideBorder">
                                          <div className="customerProfilePic">
                                            <ProfileImage
                                              src={ASSET_URL+item.display_pic}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-start">
                                        <span className="text-xxs">
                                          <b>{item.user_id}</b>
                                        </span>
                                        <p className="mb-0">
                                          {item.first_name} {item.last_name}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      padding: "0",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    <p className="  mb-0 px-2">
                                      {item.active_program_count}
                                    </p>
                                  </td>

                                  <td
                                    style={{ padding: "0" }}
                                    className="cell-breakWord"
                                  >
                                    <p className="  mb-0">
                                      {item.total_submissions_count}
                                    </p>
                                  </td>
                                  <td style={{ padding: "0" }}>
                                    <p className="  mb-0">
                                      {item.resolved_submissions_count}
                                    </p>
                                  </td>
                                  <td style={{ padding: "0" }}>
                                    <p className="  mb-0">
                                      <i
                                        className="me-1 fa-solid fa-star"
                                        style={{ color: " #FFD43B" }}
                                      ></i>
                                      {item.rating.toFixed(2)} <span>| 5</span>
                                    </p>
                                  </td>
                                </tr>
                              )
                          )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
            <div className="col-sm-12 col-md-6 mt-4">
              <div className="card h-100">
                <h5 className="p-3 text-center">Customer Programs</h5>
                <div className="table-responsive researcherProfile">
                  <table className="table align-items-center mb-0 researcherProfileTable">
                    <thead>
                      <tr>
                        <th
                          className="text-uppercase  font-weight-bolder ps-2 th-first"
                          style={{ width: "0", fontSize: "12px" }}
                        >
                          Program ID <br /> & Name
                        </th>
                        <th
                          className="text-uppercase  font-weight-bolder"
                          style={{ width: "0", fontSize: "12px" }}
                        >
                          Total <br /> Submissions
                        </th>
                        <th
                          className="text-uppercase  font-weight-bolder"
                          style={{ width: "0", fontSize: "12px" }}
                        >
                          Valid <br /> Submissions
                        </th>
                        <th
                          className="text-uppercase  font-weight-bolder ps-2"
                          style={{ width: "0", fontSize: "12px" }}
                        >
                          Remaining <br /> Budget
                        </th>
                        <th
                          className="text-uppercase  font-weight-bolder ps-2"
                          style={{ width: "0", fontSize: "12px" }}
                        >
                          Number of <br /> Payouts
                        </th>
                        <th
                          className="text-uppercase  font-weight-bolder ps-2"
                          style={{ width: "0", fontSize: "12px" }}
                        >
                          Highest <br /> Payout
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerProgramLoad
                        ? Array(5)
                          .fill()
                          .map((item) => (
                            <tr>
                              <td>
                                <div className="d-flex">
                                  <div className="me-2">
                                    <div className="outsideBorder">
                                      <div className="customerProfilePic">
                                        <Skeleton
                                          height={"100%"}
                                          width={"100%"}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-start">
                                    <span className="text-xxs">
                                      {/* <b>{item.user_id}</b> */}
                                      <Skeleton height={13} width={"100%"} />
                                    </span>
                                    <p
                                      style={{ fontSize: "14px" }}
                                      className="mb-0"
                                    >
                                      <Skeleton height={14} width={120} />
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p
                                  className="  mb-0 px-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  <Skeleton height={14} width={"100%"} />
                                </p>
                              </td>

                              <td className="cell-breakWord">
                                <p
                                  className="  mb-0"
                                  style={{ fontSize: "14px" }}
                                >
                                  <Skeleton height={14} width={"100%"} />
                                </p>
                              </td>
                              <td>
                                <p
                                  className="  mb-0"
                                  style={{ fontSize: "14px" }}
                                >
                                  <Skeleton height={14} width={"100%"} />
                                </p>
                              </td>
                              <td>
                                <p
                                  className="  mb-0"
                                  style={{ fontSize: "14px" }}
                                >
                                  <i
                                    className="me-1 fa-solid fa-star"
                                    style={{ color: " #FFD43B" }}
                                  ></i>
                                  <Skeleton height={14} width={30} />
                                </p>
                              </td>
                            </tr>
                          ))
                        : customerPrograms &&
                        customerPrograms.map(
                          (item, index) =>
                            index < 5 && (
                              <tr key={index}>
                                <td style={{ whiteSpace: "normal" }}>
                                  <div className="d-flex">
                                    <div className="text-start">
                                      <span className="text-xxs">
                                        <b
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {item.program_id}
                                        </b>
                                      </span>
                                      <p
                                        className="mb-0 fw-bold"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {item.program_title}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <p
                                    className="  mb-0 px-2 fw-bold"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item.total_submissions}
                                  </p>
                                </td>

                                <td className="cell-breakWord">
                                  <p
                                    className="  mb-0 fw-bold"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item.total_valid_submissions}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    className="  mb-0 fw-bold"
                                    style={{
                                      color: "#22B14C",
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    ${item.remaining_rewards}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    className="  mb-0 fw-bold"
                                    style={{
                                      color: "#22B14C",
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item.rewards_paid}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    className="  mb-0 fw-bold"
                                    style={{
                                      color: "#22B14C",
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    ${item.highest_rewards}
                                  </p>
                                </td>
                              </tr>
                            )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mt-4 p-0">
              <div className="card h-100">
                <div className="row d-flex justify-content-center pb-2 ">
                  <div className="col-12">
                    <h5 className="p-3 text-center">Program Summary</h5>
                  </div>
                  <div className="col-sm-12 col-md-6 p-0">
                    <Chart
                      options={donutOptions}
                      series={donutOptions.series}
                      type="donut"
                      width={"100%"}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div
                      style={{
                        marginRight: "14px",
                        marginBottom: "14px",
                      }}
                    >
                      {programStats &&
                        programStats.map((data) => (
                          <div key={data.id} className="d-flex donutLegendPart">
                            <div className="ms-2 w-100">
                              <div className="col-12">
                                <div className=" donutLegendName  justify-content-between">
                                  <p>{data.program_title}</p>
                                  <p>
                                    {data.percentage_of_total_submissions.toFixed(
                                      2
                                    )}
                                    %
                                  </p>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="donutLegendName">
                                  Submission count:{" "}
                                  <span>
                                    {data.total_program_submissions.toFixed(0)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mt-4">
              <div className="card h-100">
                <div className="p-3 ">
                  <h5 className="text-center">Reward Management</h5>
                </div>
                {/* <div className="text-end me-4">
                <i className="fa-regular fa-calendar me-2"></i>
                <span style={{ fontWeight: "600" }}>1 - 7 Nov 2023</span>
              </div> */}
                <div className="row">
                  <div className="px-4">
                    {rewardManagement &&
                      rewardManagement.map((data) => {
                        return (
                          <div
                            key={data.updated_at}
                            className="d-flex justify-content-between rewardManaPart"
                          >
                            <div className="col-7 d-flex">
                              <div className="col-2 arrowCircle">
                                {data.note_status !== "pending" ? (
                                  <div className="circleOutline">
                                    <i className="fa-solid fa-arrow-up"></i>
                                  </div>
                                ) : (
                                  <div className="circleOutlineDown">
                                    <i className="fa-solid fa-arrow-down"></i>
                                  </div>
                                )}
                              </div>
                              <div
                                className="col-10"
                                style={{ lineHeight: ".5" }}
                              >
                                <p className="rewardManaPartP">
                                  {data.program_title}
                                </p>
                                <span className="rewardManaPartSpan">
                                  {data.submission_title}
                                </span>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="text-center">
                                {data.note_status === "pending" ? (
                                  <span className="stsResolvedPending">
                                    Pending
                                  </span>
                                ) : (
                                  <span className="stsResolved">Submitted</span>
                                )}
                              </div>
                            </div>
                            <div className="col-1">
                              <div className="text-center">
                                {data.note_status === "pending" ? (
                                  <span className="stsResolvedPending">
                                    ${data.rewards_amount}
                                  </span>
                                ) : (
                                  <span className="stsResolved">
                                    ${data.rewards_amount}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {rewardManagement && rewardManagement.length === 0 && (
                      <div className="text-center">No Data Avaialble</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mt-4">
              <div className="row p-0">
                <div
                  className="col-12 p-0"
                // style={{ paddingLeft: "12px", paddingRight: "29px" }}
                >
                  <div className="card programStsTxt">
                    <h5>Program Stats</h5>
                  </div>
                </div>
                <div className="col-6 p-0 h-100">
                  <div className="card mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        {widgetStats?.total_programs ?? 0}
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Total Number Of Programs
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Sum Of All Reports Submitted In Customer Programs
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-6 p-0 h-100">
                  <div className="card  mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        {widgetStats?.total_active_programs ?? 0}
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Total Number Of Active Programs
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Number Of Customer Support Queries Resolved
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-6 p-0 h-100">
                  <div className="card  mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        {widgetStats?.total_submissions ?? 0}
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Total Submissions
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Number Of Customer Support Queries Resolved
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-6 p-0 h-100">
                  <div className="card  mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        {widgetStats?.total_valid_submissions ?? 0}
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Total Valid Submissions
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Number Of Customer Support Queries Resolved
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-6 p-0 h-100 ">
                  <div className="card mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        4.2 <span style={{ fontSize: "18px" }}>/ 5.0</span>
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                        }}
                      >
                        Average Customer Satisfaction
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Average Satisfaction Rating Across All Customers
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-6 p-0 h-100 ">
                  <div className="card mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        {widgetStats?.total_submissions === 0 ||
                          widgetStats?.total_valid_submissions === 0
                          ? "0.0"
                          : (
                            (parseInt(widgetStats?.total_valid_submissions) /
                              parseInt(widgetStats?.total_submissions)) *
                            5
                          ).toFixed(1)}
                        <span style={{ fontSize: "18px" }}>/ 5.0</span>
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                        }}
                      >
                        Average Submission Rate
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Average Satisfaction Rating Across All Customers
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-sm-12 col-md-6 mt-4">
              <div className="card h-100">
                <h5 className="p-3 text-center">Researcher Profile</h5>
                <div className="table-responsive researcherProfile">
                  <table className="table align-items-center mb-0 researcherProfileTable">
                    <thead>
                      <tr>
                        <th
                          className="text-uppercase  font-weight-bolder text-start ps-7 th-first"
                        >
                          ID & Name
                        </th>
                        <th
                          className="text-uppercase  font-weight-bolder"
                        >
                          Total <br /> Submissions
                        </th>
                        <th
                          className="text-uppercase  font-weight-bolder"
                        >
                          Valid <br /> Submissions
                        </th>
                        <th
                          className="text-uppercase  font-weight-bolder "
                        >
                          Total
                          <br /> Rewards
                        </th>
                        <th
                          className="text-uppercase  font-weight-bolder "
                        >
                          Critical <br /> Findings
                        </th>
                        <th
                          className="text-uppercase  font-weight-bolder "
                        >
                          Highest
                          <br /> Rewards
                        </th>
                        <th
                          className="text-uppercase  font-weight-bolder "
                        >
                          Reward
                          <br /> Points
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {researcherPortfolioLoad
                        ? Array(5)
                          .fill()
                          .map((item) => (
                            <tr>
                              <td>
                                <div className="d-flex">
                                  <div className="me-2">
                                    <div className="outsideBorder">
                                      <div className="customerProfilePic">
                                        <Skeleton
                                          height={"100%"}
                                          width={"100%"}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-start">
                                    <span className="text-xxs">
                                      <Skeleton height={14} width={'100%'} />
                                    </span>
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "14px" }}
                                    >
                                      <Skeleton height={14} width={120} />
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p
                                  className="  mb-0 px-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  <Skeleton height={14} width={'100%'} />
                                </p>
                              </td>

                              <td className="cell-breakWord">
                                <Skeleton height={14} width={'100%'} />
                              </td>
                              <td>
                                <Skeleton height={14} width={'100%'} />
                              </td>
                              <td>
                                <Skeleton height={14} width={'100%'} />
                              </td>
                              <td>
                                <div className="">
                                  <Skeleton height={9} width={120} />
                                </div>
                              </td>
                              <td>
                                <div className="">
                                  <Skeleton height={9} width={120} />
                                </div>
                              </td>
                            </tr>
                          ))
                        : researcherPortfolio &&
                        researcherPortfolio.map(
                          (item, index) =>
                            index < 5 && (
                              <tr key={index}>
                                <td>
                                  <div className="d-flex">
                                    <div className="me-2 p-2">
                                      <div className="outsideBorder">
                                        <div className="customerProfilePic">
                                          <ProfileImage
                                            src={ASSET_URL+item.display_pic}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-start">
                                      <span className="text-xxs">
                                        <b>{item.user_id}</b>
                                      </span>
                                      <p
                                        className="mb-0 fw-bold"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {item.first_name} {item.last_name}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td style={{ padding: "0" }}>
                                  <p className="  mb-0 fw-bold">
                                    {item.total_reports}
                                  </p>
                                </td>
                                <td style={{ padding: "0" }} className="mb-0">
                                  <p className="  mb-0 fw-bold">
                                    {item.valid_reports}
                                  </p>
                                </td>
                                <td style={{ padding: "0" }}>
                                  <p
                                    className="  mb-0 fw-bold"
                                    style={{ color: "#22B14C" }}
                                  >
                                    ${item.total_rewards}
                                  </p>
                                </td>
                                <td style={{ padding: "0" }}>
                                  <p className="  mb-0 fw-bold">
                                    {item.critical_findings}
                                  </p>
                                </td>
                                <td style={{ padding: "0" }}>
                                  <p
                                    className="  mb-0 fw-bold"
                                    style={{ color: "#22B14C" }}
                                  >
                                    ${item.highest_rewards}
                                  </p>
                                </td>
                                <td style={{ padding: "0" }}>
                                  <p
                                    className="  mb-0 fw-bold"
                                    style={{ color: "#000080" }}
                                  >
                                    {item.reward_points}
                                  </p>
                                </td>
                              </tr>
                            )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
            <div className="col-sm-12 col-md-6 mt-4">
              <div className="card h-100">
                <h5 className="p-3 text-center pb-0 mb-1">
                  Vulnerabilities Reports Summary
                </h5>
                {/* <div className="text-center">Issues Tracking</div>
                <div className="text-center">Vulnerabilities Management</div> */}
                <div className="mixed-chart w-100" style={{ height: "384px" }}>
                  <Chart
                    options={issueOptions}
                    series={issueOptions.series}
                    type="bar"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 mt-4 p-0">
              <div className="card h-100">
                <h5 className="p-3 text-center">Researcher Leaderboard</h5>
                <div className="table-responsive researcherProfile">
                  <table className="table align-items-center mb-0 researcherProfileTable">
                    <thead>
                      <tr>
                        <th className="text-uppercase  font-weight-bolder th-first">
                          Researcher
                        </th>
                        <th className="text-uppercase  font-weight-bolder">
                          Total <br /> Submisions
                        </th>
                        <th className="text-uppercase  font-weight-bolder">
                          Valid <br /> Submisions
                        </th>
                        <th className="text-uppercase  font-weight-bolder">
                          Critical <br /> Findings
                        </th>
                        <th className="text-uppercase  font-weight-bolder ps-2">
                          Points <br /> Earned
                        </th>
                        <th className="text-uppercase  font-weight-bolder ps-2">
                          Progress <br /> Meter
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {researcherPortfolioLoad
                        ? Array(5)
                          .fill()
                          .map((item) => (
                            <tr>
                              <td>
                                <div className="d-flex">
                                  <div className="me-2">
                                    <div className="outsideBorder">
                                      <div className="customerProfilePic">
                                        <Skeleton
                                          height={"100%"}
                                          width={"100%"}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-start">
                                    <span className="text-xxs">
                                      <Skeleton height={14} width={"100%"} />
                                    </span>
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "14px" }}
                                    >
                                      <Skeleton height={14} width={"100%"} />
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p
                                  className="  mb-0 px-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  <Skeleton height={14} width={"100%"} />
                                </p>
                              </td>

                              <td className="cell-breakWord">
                                <Skeleton height={14} width={"100%"} />
                              </td>
                              <td>
                                <Skeleton height={14} width={"100%"} />
                              </td>
                              <td>
                                <Skeleton height={14} width={"100%"} />
                              </td>
                              <td>
                                {/* <p className="  mb-1">{item.progress} %</p> */}
                                <div className="">
                                  <Skeleton height={9} width={120} />
                                </div>
                              </td>
                            </tr>
                          ))
                        : researcherPortfolio &&
                        researcherPortfolio.map(
                          (item, index) =>
                            index < 5 && (
                              <tr key={index}>
                                <td>
                                  <div className="d-flex">
                                    <div className="me-2">
                                      <div className="outsideBorder">
                                        <div className="customerProfilePic">
                                          <ProfileImage
                                            src={ASSET_URL + item.display_pic}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-start">
                                      <span
                                        // style={{ color: "#8955D6" }}

                                        className="text-xxs"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        <b
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {item.username}
                                        </b>
                                      </span>
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                        }}
                                        className="mb-0 fw-bold"
                                      >
                                        {item.first_name} {item.last_name}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <p
                                    className="  mb-0 px-2 fw-bold"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item.total_reports}
                                  </p>
                                </td>

                                <td className="cell-breakWord">
                                  <p
                                    className="  mb-0 fw-bold"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item.valid_reports}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    className="  mb-0 fw-bold"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item.critical_findings}
                                  </p>
                                </td>
                                {/* <td>
                                    <p className="  mb-0">
                                      {item.highest_rewards}
                                    </p>
                                  </td> */}
                                <td>
                                  <p
                                    className="  mb-0 fw-bold"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item.reward_points}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    className="  mb-1 fw-bold"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item.total_reports === 0 ||
                                      item.valid_reports === 0
                                      ? "0.0%"
                                      : (
                                        (parseInt(item.valid_reports) /
                                          parseInt(item.total_reports)) *
                                        100
                                      ).toFixed(1) + "%"}
                                  </p>
                                  <div className="progress">
                                    <div
                                      key={index}
                                      style={{
                                        width:
                                          item.total_reports === "0" ||
                                            item.valid_reports === "0"
                                            ? "0%"
                                            : (
                                              (parseInt(
                                                item.valid_reports
                                              ) /
                                                parseInt(
                                                  item.total_reports
                                                )) *
                                              100
                                            ).toFixed(0) + "%",
                                        backgroundColor:
                                          item.total_reports === "0" ||
                                            item.valid_reports === "0"
                                            ? "#f7ccac"
                                            : (parseInt(item.valid_reports) /
                                              parseInt(
                                                item.total_reports
                                              )) *
                                              100 <=
                                              20
                                              ? "#f7ccac"
                                              : (parseInt(item.valid_reports) /
                                                parseInt(
                                                  item.total_reports
                                                )) *
                                                100 <=
                                                40
                                                ? "#ed7d31"
                                                : (parseInt(item.valid_reports) /
                                                  parseInt(
                                                    item.total_reports
                                                  )) *
                                                  100 <=
                                                  60
                                                  ? "#98c3e6"
                                                  : (parseInt(item.valid_reports) /
                                                    parseInt(
                                                      item.total_reports
                                                    )) *
                                                    100 <=
                                                    80
                                                    ? "#0072bf"
                                                    : "#00af52",

                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                      className="progress-bar fw-bold"
                                      role="progressbar"
                                      aria-valuenow="0"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </td>
                                {/* <td>
                                    <p className="  mb-0">
                                      {item.reputationPoints}
                                    </p>
                                  </td> */}
                              </tr>
                            )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDashboard;
