import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";

import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import useHttp from "../Hooks/useHttp";
import { ToastContainer, toast } from "react-toastify";
import AddModal from "../common/modal/AddModal";
import { customToast } from "../../App";

export default function AddDesignation() {
	const [openAddModal, setOpenAddModal] = useState(false);
	const location = useLocation();
	let { id } = useParams();

	const Breadcrumbname = {
		home: "/admin/home",
		Management: "",
		"Department Designation": "/admin/department-designation",
		"Add Designation": "/admin/department-designation/add-designation",
	};

	const API = process.env.REACT_APP_API_URL;
	const { PostRequest, GetRequest, DeleteRequest, PutRequest } = useHttp();
	const token = localStorage.getItem("token");
	const navigate = useNavigate();

	const [userName, setUserName] = useState();
	const [designation, setDesignation] = useState();
	const [role, setRole] = useState();

	const [usersList, setUsersList] = useState([]);
	const [roles, setRoles] = useState([]);

	const fetchSubadminsList = async () => {
		const { data, error } = await GetRequest(
			API + "/sub_admin/",
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		if (error) {
			// alert("Something went wrong");
			// customToast.error("Something went wrong!", {
			//   position: toast.POSITION.TOP_RIGHT,
			//   autoClose: 1000,
			// });
		} else {
			const tempArr = [];
			data.results.map((item) => {
				if (!item.designation || id == item.designation.id)
					tempArr.push({
						value: item.id,
						label: `${item.first_name} ${item.last_name} (${item.username})`,
					});
			});
			setUsersList([...tempArr]);
		}
	};

	const fetchRolesList = async () => {
		const { data, error } = await GetRequest(
			API + "/roles/",
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		if (error) {
			// alert("Something went wrong");
			// customToast.error("Something went wrong!", {
			//   position: toast.POSITION.TOP_RIGHT,
			//   autoClose: 1000,
			// });
		} else {
			const tempArr = [];
			data.results.map((item) => {
				tempArr.push({
					value: item.id,
					label: item.role_title,
				});
			});
			setRoles([...tempArr]);
		}
	};

	useEffect(() => {
		fetchSubadminsList();
		fetchRolesList();
	}, []);


	const finalSubmit = async () => {
		const param = {
			designation_title: designation,
			role_id: role,
			user_id: userName,
		};
		if (id) {
			const { data, error } = await PutRequest(
				API + `designations/${id}/`,
				param,
				{
					Authorization: "Bearer  " + token,
					"Content-Type": "multipart/form-data",
				}
			);
			if (error) {
				console.log(error);
				customToast.error("Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			} else {
				customToast.success("Designation added successfully", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
				navigate("/admin/department-designation");
			}
		} else {
			const { data, error } = await PostRequest(API + `designations/`, param, {
				Authorization: "Bearer  " + token,
				"Content-Type": "multipart/form-data",
			});
			if (error) {
				console.log(error);
				customToast.error("Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			} else {
				customToast.success("Designation added successfully", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
				navigate("/admin/department-designation");
			}
		}
	};

	const fetchDesignationData = async (id) => {
		const { data, error } = await GetRequest(
			API + `/designations/${id}/`,
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		if (error) {
			alert("Something went wrong");
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {
			console.log("data", data);
			setDesignation(data.designation_title);
			setUserName(data.user_id);
			setRole(data.role_id);
		}
	};
	useEffect(() => {
		if (id) {
			fetchDesignationData(id);
		}
	}, [id]);

	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row w-100 d-flex justify-content-center">
					<div className="">
						<div className="d-flex justify-content-between">
							<div className="">
								<Breadcrumb names={Breadcrumbname} />
							</div>
							<div className=" d-flex justify-content-end mt-0 mb-3">
								<Link
									to="/admin/department-designation"
									className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
									id="adduser"
								>
									VIEW ALL
								</Link>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="card">
							<div className="row">
								<div className="card mt-0" id="basic-info">
									<div className="card-header">
										<h5>Add User Designation</h5>
									</div>
									<div className="card-body pt-0">
										<div className="row">
											<div className="col-12">
												<label className="form-label ">User Name</label>

												<select
													className="form-select"
													name="choices-gender"
													id="choices-gender"
													options={usersList}
													value={userName}
													disabled={id ? true : false}
													onChange={(e) => setUserName(e.target.value)}
												>
													<option value="" selected>
														Select Sub-Admin{" "}
													</option>

													{usersList &&
														usersList.map((item, index) => {
															return (
																<option key={index} value={item.value}>{item.label}</option>
															);
														})}
												</select>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-6">
												<label className="form-label ">Designation</label>
												<div className="input-group ">
													<input
														className="form-control"
														placeholder="Enter a Designation"
														type="text"
														value={designation}
														onChange={(e) => setDesignation(e.target.value)}
													/>
												</div>
											</div>
											<div className="col-6">
												<label className="form-label ">
													Select Role & Permissions
												</label>
												<select
													className="form-select"
													name="choices-gender"
													id="choices-gender"
													value={role}
													onChange={(e) => setRole(e.target.value)}
												>
													<option value="" selected>
														Select Role & Permissions
													</option>
													{roles &&
														roles.map((item, index) => {
															return (
																<option key={index} value={item.value}>{item.label}</option>
															);
														})}
												</select>
											</div>
										</div>

										<div className=" d-flex justify-content-end">
											<Link
												to="/admin/department-designation"
												className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
											>
												Cancel
											</Link>
											<button
												onClick={() => setOpenAddModal(true)}
												className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
											>
												{location.pathname ===
													"/admin/department-designation/add-designation"
													? "Add Designation"
													: "Update Designation"}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AddModal
				cancelBtn={"Cancel"}
				submitBtn={"SUBMIT"}
				createAddTopic={
					location.pathname === "/admin/department-designation/add-designation"
						? "Add Designation"
						: "Edit Designation"
				}
				confirmText={
					location.pathname === "/admin/department-designation/add-designation"
						? "Add The Designation"
						: "Edit The Designation"
				}
				isOpen={openAddModal}
				onSubmit={finalSubmit}
				onClose={() => setOpenAddModal(false)}
			/>
		</>
	);
}
