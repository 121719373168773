import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";

import "./WalletDashboard.css";
import useHttp from "../Hooks/useHttp";
import { formatDate } from "../../utils/helperFunctions";
import ProfileImage from "../../components/ProfileImage";
import CommonTable from "../common/Table/CommonTable";
import AppContext from "../Context/AppContext";
import CountSkeletonLoader from "../components/SkeletonLoader/CountSkeletonLoader";
import HTTPService from "../../utils/axios";
import withPermission from "../../hoc/withPermission";
const tableColumn = [
  "Registred at",
  { label: "wallet id", align: "center" },
  { label: "wallet Balance", align: "center" },
  { label: "user id", align: "center" },
  { label: "user type", align: "center" },
  "full name (as per document)",
  { label: "id proof", align: "center" },
  "address proof",
  "status",
];
const searchColumn = [
  { name: "Wallet ID", value: "Wallet_kyc_id" },
  { name: "User ID", value: "user_id" },
  { name: "Name", value: "first_name" },
  // { name: "Registred At", value: "created_at" },
  // { name: "Status", value: "KYC_Status" },
];

const filterColumn = [
  // {
  //   name: "User Type",
  //   value: "role",
  //   filters: ["customer", "researcher"],
  // },
  {
    name: "Status",
    value: "KYC_Status",
    filters: ["active", "inactive"],
  },
];

function WalletDashboard() {
  const { invokeApi } = HTTPService();
  const { loading, setLoading } = useContext(AppContext);
  const API = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const { GetRequest } = useHttp();
  const [walletData, setWalletData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [walletCounts, setWalletCounts] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();
  const [totalData, setTotalData] = useState();

  const fetchData = async () => {
    setLoading(true);
    let url = `${API}wallets/?page=${currentPage}&page_size=25`;
    if (searchQuery && searchQuery.length > 0) {
      url = url + `&${searchValue}=${searchQuery}`;
    }
    if (filterType && filterValue && filterValue.length > 0) {
      url = url + `&${filterType}=${filterValue}`;
    }
    const { data, error } = await GetRequest(
      url,
      {},
      {
        Authorization: "Bearer  " + token,
      }
    );
    if (error) {
      setLoading(false);
      console.log(error);
    } else {
      setLoading(false);
      if (data.results) setWalletData(data.results);
      setTotalData(data.total_records);
      setTotalPages(data.total_pages);
      console.log("userdata", data);
    }
  };
  useEffect(() => {
    fetchData();
    fetchCounts();
  }, []);

  useEffect(() => {
    fetchData();
  }, [searchQuery, currentPage, filterValue]);

  const fetchCounts = () => {
    invokeApi({
      method: "GET",
      url: `wallets/counts`,
    })
      .then((response) => {
        setWalletCounts(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const Breadcrumbname = {
    Home: "/admin/home",
    Payments: "",
    Wallet: "",
    Dashboard: "",
  };

  return (
    <>
      <main className="main-content position-relative height-vh-100 border-radius-lg ">
        <div className="container-fluid py-4 pb-0">
          <div className="row">
            <div className="col-lg-6 ps-3">
              <Breadcrumb names={Breadcrumbname} />
            </div>
          </div>
          <div className="row">
            {loading ? (
              <CountSkeletonLoader showState={1} />
            ) : (
              <div className="col-lg-12 col-12">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="card">
                      <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                      <div className="card-body p-3 position-relative">
                        <div className="row">
                          <div className="col-8 text-start">
                            <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                              <i
                                className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <h5 className="text-white font-weight-bolder mb-0 mt-3">
                              {walletCounts?.all_counts?.active ?? 0}
                            </h5>
                            <span className="text-white text-sm">
                              Active Customers
                            </span>
                          </div>
                          <div className="col-4">
                            <div className="dropdown text-end mb-5"></div>
                            <h5 className="text-end text-white pt-3 mb-0 mt-2">
                              {walletCounts?.today_counts?.active ?? 0}
                            </h5>
                            <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                              Today's
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 mt-4 mt-md-0">
                    <div className="card">
                      <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                      <div className="card-body p-3 position-relative">
                        <div className="row">
                          <div className="col-8 text-start">
                            <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                              <i
                                className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <h5 className="text-white font-weight-bolder mb-0 mt-3">
                              {walletCounts?.all_counts &&
                              walletCounts?.all_counts.hasOwnProperty(
                                "in-active"
                              )
                                ? walletCounts?.today_counts["in-active"]
                                : 0}
                            </h5>
                            <span className="text-white text-sm">
                              In-Actives Customers
                            </span>
                          </div>
                          <div className="col-4">
                            <div className="dropdown text-end mb-5"></div>
                            <h5 className="text-end text-white pt-3 mb-0 mt-2">
                              {walletCounts?.today_counts &&
                              walletCounts?.today_counts.hasOwnProperty(
                                "in-active"
                              )
                                ? walletCounts?.today_counts["in-active"]
                                : 0}
                            </h5>
                            <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                              Today's
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 mt-4 mt-md-0">
                    <div className="card">
                      <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                      <div className="card-body p-3 position-relative">
                        <div className="row">
                          <div className="col-8 text-start">
                            <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                              <i
                                className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <h5 className="text-white font-weight-bolder mb-0 mt-3">
                              {walletCounts?.all_counts?.blocked ?? 0}
                            </h5>
                            <span className="text-white text-sm">
                              Blocked Customers
                            </span>
                          </div>
                          <div className="col-4">
                            <div className="dropdown text-end mb-5"></div>
                            <h5 className="text-end text-white pt-3 mb-0 mt-2">
                              {walletCounts?.today_counts?.blocked ?? 0}
                            </h5>
                            <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                              Today's
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 mt-4 mt-md-0">
                    <div className="card">
                      <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                      <div className="card-body p-3 position-relative">
                        <div className="row">
                          <div className="col-8 text-start">
                            <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                              <i
                                className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <h5 className="text-white font-weight-bolder mb-0 mt-3">
                              {walletCounts?.all_counts?.hold ?? 0}
                            </h5>
                            <span className="text-white text-sm">
                              Pending Approveals
                            </span>
                          </div>
                          <div className="col-4">
                            <div className="dropdown text-end mb-5"></div>
                            <h5 className="text-end text-white pt-3 mb-0 mt-2">
                              {walletCounts?.today_counts?.hold ?? 0}
                            </h5>
                            <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                              Today's
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="row my-4">
            <div className="col-12">
              <CommonTable
                column={tableColumn}
                data={walletData && walletData}
                totalPages={totalPages ?? 1}
                totalData={totalData}
                filterList={filterColumn}
                filterType={filterType}
                setFilterType={setFilterType}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                searchBar={true}
                searchBy={searchColumn}
                itemPerPg={25}
                setTable={3}
                card={7}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                searchPlaceHolder={searchPlaceHolder}
                setSearchPlaceHolder={setSearchPlaceHolder}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              >
                {walletData &&
                  walletData.length > 0 &&
                  walletData?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <p className="text-sm text-secondary mb-0 px-2">
                          {formatDate(item.updated_at, 1)}
                        </p>
                      </td>
                      <td>
                        <p className="text-sm text-secondary mb-0 px-2">
                          {item.Wallet_kyc_id}
                        </p>
                      </td>
                      <td>
                        <p className="text-sm text-secondary mb-0 px-2">
                          ${item.Wallet_Balance}
                        </p>
                      </td>
                      <td>
                        <p className="text-sm text-secondary mb-0 px-2">
                          {item.user.user_id}
                        </p>
                      </td>
                      <td>
                        <p
                          className="text-sm text-secondary mb-0 px-2"
                          style={{ textTransform: "capitalize" }}
                        >
                          {item?.user?.role}
                        </p>
                      </td>
                      <td>
                        <div className="d-flex px-2 py-1">
                          <div style={{ width: "50px", height: "50px" }}>
                            <ProfileImage
                              className="avatar avatar-sm me-3"
                              src={item.img}
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="mb-0 text-sm">
                              {item.first_name} {item.last_name}
                            </h6>
                          </div>
                        </div>
                      </td>
                      {/* <td>
															<p className="text-sm text-secondary mb-0">
																{item.verify === "Verified" ? (
																	<span className="badge bg-success text-white">
																		Verified
																	</span>
																) : (
																	<span className="badge bg-danger text-white">
																		Unverified
																	</span>
																)}
															</p>
														</td> */}
                      <td>
                        <p className="text-sm text-secondary mb-0">
                          {item.user.id_proof}
                        </p>
                      </td>
                      <td className="align-middle text-center text-sm">
                        <p className="text-sm text-secondary mb-0">
                          {item.user.address_proof}
                        </p>
                      </td>

                      <td className="align-middle text-center">
                        {item.KYC_Status === "active" ? (
                          <span className="badge bg-success text-white">
                            Active
                          </span>
                        ) : (
                          <span className="badge bg-danger text-white">
                            Inactive
                          </span>
                        )}
                      </td>
                      {/* <td>
															<div className="d-flex">
																<Link
																	to={`/admin/customer/view/${item.id}`}
																	className="btn btn-sm px-3 mt-2"
																>
																	&#128065;
																</Link>
																<button
																	type="button"
																	className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																>
																	<i
																		className="fa fa-ellipsis-v text-xs"
																		aria-hidden="true"
																	></i>
																</button>
																<ul className="dropdown-menu">
																	<li>
																		<Link
																			className="dropdown-item"
																			to={`/admin/customer/edit/${item.id}`}
																		>
																			Edit
																		</Link>
																	</li>
																	<li>
																		<a className="dropdown-item" href="#">
																			Delete
																		</a>
																	</li>
																</ul>
															</div>
														</td> */}
                    </tr>
                  ))}
              </CommonTable>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default withPermission(WalletDashboard);
WalletDashboard.moduleName = "Wallet";
