import React, { useContext, useEffect, useState } from "react";

import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Dashboard from "../../../components/Header-Components/Dashboard";
import ManageModule from "../../../components/Header-Components/ManageModule";
import Management from "../../../components/Header-Components/Management";
import MasterModule from "../../../components/Header-Components/MasterModule";
import Payments from "../../../components/Header-Components/Payments";
import Integrations from "../../../components/Header-Components/Integrations";
import Reports from "../../../components/Header-Components/Reports";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import useHttp from "../../../Hooks/useHttp";
import { ToastContainer, toast } from "react-toastify";
import AddModal from "../../../common/modal/AddModal";
import AppContext from "../../../Context/AppContext";
import HTTPService from "../../../../utils/axios";
import { customToast } from "../../../../App";

export default function AddCmsPage() {
  const { invokeApi } = HTTPService();
  const { setCircularLoading } = useContext(AppContext);
  const [openAddModal, setOpenAddModal] = useState(false);

  const navigate = useNavigate();
  const [InpQs, setInpQs] = useState();
  const [sequence, setSequence] = useState();
  const [ans, setAns] = useState("");
  const [type, setType] = useState("");
  const [userType, setUserType] = useState();
  const [mainCatId, setMainCatId] = useState();
  const [mainCatOpt, setMainCatOpt] = useState();
  const [metaTitle, setMetaTitle] = useState();
  const [metaDescription, setMetaDescription] = useState();
  const [subCat, setSubCat] = useState();
  const [subCatOpt, setSubCatOpt] = useState("");

  const { PostRequest, GetRequest } = useHttp();

  const token = localStorage.getItem("token");
  const API = process.env.REACT_APP_API_URL;

  const handleSubmit = async () => {
    setOpenAddModal(false);
    setCircularLoading(true);
    const param = {
      page_title: InpQs,
      page_content: ans,
      user_type: type,
      sequence: sequence,
      cms_status: "active",
      user_id: "test_user_id_1",
      is_deleted: false,
      sub_category_id: subCat,
      meta_title: metaTitle,
      meta_description: metaDescription,
    };
    invokeApi({
      method: "POST",
      url: "cms_pages/",
      data: param,
    })
      .then((data) => {
        setCircularLoading(false);
        console.log("cmspage", data);
        setSequence();
        setType("");
        setAns("Please Write Your Answer");
        setSubCat("");

        customToast.success("CMS Page Added Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        navigate("/admin/cms-pages");
      })
      .catch((error) => {
        setCircularLoading(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const fetchValueOpt = async () => {
    invokeApi({
      method: "GET",
      url: "basevalues/?title_code=user_type",
    })
      .then((data) => {
        setUserType(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
    // const { data, error } = await GetRequest(
    // 	API + "basevalues/?title_code=user_type",
    // 	{},
    // 	{
    // 		Authorization: "Bearer  " + token,
    // 	}
    // );
    // // console.log(data, error);
    // if (error) {
    // 	// alert("Something went wrong");
    // 	customToast.error("Something went wrong!", {
    // 		position: toast.POSITION.TOP_RIGHT,
    // 		autoClose: 1000,
    // 	});
    // } else {
    // 	setUserType(data);
    // }
  };

  const fetchMainCatName = async () => {
    invokeApi({
      method: "GET",
      url: "faq_category/?category_type=main_category&cat_status=active",
    })
      .then((data) => {
        setMainCatOpt(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
    // const { data, error } = await GetRequest(
    // 	API + "faq_category/?category_type=main_category&cat_status=active",
    // 	{},
    // 	{
    // 		Authorization: "Bearer  " + token,
    // 	}
    // );
    // // console.log(data, error);
    // if (error) {
    // 	// alert("Something went wrong");
    // 	customToast.error("Something went wrong!", {
    // 		position: toast.POSITION.TOP_RIGHT,
    // 		autoClose: 1000,
    // 	});
    // } else {
    // 	setMainCatOpt(data);
    // 	// console.log("maincat  ",data)
    // }
  };
  const fetchSubCatName = async () => {
    invokeApi({
      method: "GET",
      url: "faq_category/?category_type=sub_category&cat_status=active",
    })
      .then((data) => {
        setSubCatOpt(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
    // const { data, error } = await GetRequest(
    // 	API + "faq_category/?category_type=sub_category&cat_status=active",
    // 	{},
    // 	{
    // 		Authorization: "Bearer  " + token,
    // 	}
    // );
    // // console.log(data, error);
    // if (error) {
    // 	// alert("Something went wrong");
    // 	customToast.error("Something went wrong!", {
    // 		position: toast.POSITION.TOP_RIGHT,
    // 		autoClose: 1000,
    // 	});
    // } else {
    // 	setSubCatOpt(data);
    // }
  };

  useEffect(() => {
    fetchValueOpt();
    fetchMainCatName();
    fetchSubCatName();
  }, []);

  const Breadcrumbname = {
    home: "/admin/home",
    "Manage Module": "",
    "CMS Pages": "/admin/cms-pages",
    "Add New CMS Page": "/admin/cms-page/add",
  };
  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row w-100 d-flex justify-content-center">
          <div className="addCategoryArea  my-4">
            <div className="row">
              <div className="col-lg-8 ps-3">
                <Breadcrumb names={Breadcrumbname} />
              </div>
              <div className="col-lg-4">
                <div className="button-row d-flex justify-content-end mt-0 mb-3">
                  <Link
                    to="/admin/cms-pages"
                    className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                    id="adduser"
                  >
                    VIEW ALL
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="row ">
                  <div className="card mt-0 " id="basic-info">
                    <div className="card-header">
                      <h5>ADD NEW CMS PAGE</h5>
                    </div>
                    <div className="card-body pt-0">
                      <div className="row mb-3">
                        <div className="col-6">
                          <label className="form-label">Type</label>

                          <select
                            className="form-select"
                            name="choices-gender"
                            id="choices-gender"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                          >
                            <option value="" selected>
                              Select User Type
                            </option>
                            {userType &&
                              userType?.results[0].value_options
                                ?.split(",")
                                .map((item, index) => {
                                  console.log(item);
                                  return (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <label className="form-label">Main Category</label>

                          <select
                            className="form-select"
                            name="choices-gender"
                            id="choices-gender"
                            value={mainCatId}
                            onChange={(e) => setMainCatId(e.target.value)}
                          >
                            <option value="" selected>
                              Select Main Category
                            </option>
                            {mainCatOpt &&
                              mainCatOpt.results &&
                              mainCatOpt.results.length &&
                              mainCatOpt.results.map((item, index) => {
                                if (type === item.user_type) {
                                  return (
                                    <option value={item.id} key={index}>
                                      {item.category_title}
                                    </option>
                                  );
                                }
                              })}
                          </select>
                        </div>
                        <div className="col-6">
                          <label className="form-label">Sub-Category</label>

                          <select
                            className="form-select"
                            name="choices-gender"
                            id="choices-gender"
                            value={subCat}
                            onChange={(e) => setSubCat(e.target.value)}
                          >
                            <option value="" selected>
                              Select Sub-Category
                            </option>
                            {subCatOpt &&
                              subCatOpt?.results.map((item, index) => {
                                if (mainCatId === item.parent_category_id) {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.category_title}
                                    </option>
                                  );
                                }
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <label className="form-label mt-4">Page Title</label>
                          <div className="input-group ">
                            <input
                              className="form-control"
                              placeholder="Enter the Page Title"
                              type="text"
                              value={InpQs}
                              onChange={(e) => setInpQs(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <label className="form-label mt-4">
                            Page Description
                          </label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={ans}
                            onChange={(event, editor) => {
                              // console.log(editor.getData());
                              setAns(editor.getData());
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <label className="form-label mt-4">Meta Title</label>
                          <div className="input-group ">
                            <input
                              className="form-control"
                              placeholder="Enter Meta Title"
                              type="text"
                              value={metaTitle}
                              onChange={(e) => setMetaTitle(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <label className="form-label mt-4">
                            Meta Description
                          </label>
                          <div className="input-group ">
                            <input
                              className="form-control"
                              placeholder="Enter Meta Description"
                              type="text"
                              value={metaDescription}
                              onChange={(e) =>
                                setMetaDescription(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <label className="form-label mt-4">Sequence</label>
                          <div className="input-group ">
                            <input
                              className="form-control"
                              placeholder="Enter Sequence"
                              type="number"
                              value={sequence}
                              onChange={(e) => setSequence(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" d-flex justify-content-end">
                        <Link
                          to="/admin/cms-pages"
                          className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
                        >
                          Cancel
                        </Link>
                        <button
                          onClick={() => setOpenAddModal(true)}
                          className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"SUBMIT"}
        createAddTopic={"Add CMS Page"}
        confirmText={"Add The CMS Page"}
        isOpen={openAddModal}
        onSubmit={handleSubmit}
        onClose={() => setOpenAddModal(false)}
      />
    </>
  );
}
