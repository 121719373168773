import React, { useContext, useState } from 'react'
import HTTPService from '../../../../utils/axios';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../../reducers/authSlice';
import { formatDate } from '../../../../utils/helperFunctions';
import CommonTable from '../../Table/CommonTable';
import AppContext from '../../../Context/AppContext';
import { toast } from 'react-toastify';
import CircularLoader from '../../../CircularLoader/CircularLoader';
import { customToast } from '../../../../App';

const Breadcrumbname = {
    home: "/admin/home",
    Integrations: "",
    Slack: "",
};

const tableColumn = [
    "Channel ID",
    "Channel Name",
    "Program ID",
    "Program Name",
    "Team ID",
    "Slack User ID",
    "Created at",
    "Actions",
];
const searchColumn = [
    { name: "Program ID", value: "program_id" },
    { name: "Program Name", value: "program_title" },
];
const Slack = () => {
    const { invokeApi } = HTTPService();
    const { userInfo, permissions } = useSelector(getUserInfo);
    const [channels, setChannels] = useState({})
    const [totalPages, setTotalPages] = useState(1)
    const [searchValue, setSearchValue] = useState("");
    const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const { memberData, setMemberData, loading, setLoading } = useContext(AppContext);
    const [filterValue, setFilterValue] = useState();

    const handleClick = () => {
        setLoading(true);
        invokeApi({
            method: "GET",
            url: `/slack/oauth/start/`,
        }).then(res => {
            window.location.href = res.slack_auth_url
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })
    }

    const fetchChannels = () => {
        setLoading(true);
        let params = {
            page: currentPage,
        }
        if (searchValue && searchValue.length > 0) {
            params[searchValue] = searchQuery
        }
        invokeApi({
            method: "GET",
            url: `/slack/get_all_channels/`,
            params
        }).then(res => {
            setChannels(res)
            setTotalPages(res.totalPages ?? 1)
            setLoading(false);
        }).catch(error => {
            setLoading(false);

        })
    }
    useEffect(() => {
        if (userInfo?.user_data?.id) {
            fetchChannels()
        }

    }, [userInfo?.user_data?.id])

    const handleDelete = (id) => {
        invokeApi({
            method: "DELETE",
            url: `/slack/delete_user/${id}/`,
        }).then(res => {
            setMemberData({ openmodal: false })
            // setChannels(prev => {
            //     let newdata = { ...prev };
            //     newdata.results.filter(el => el.id !== id)
            //     return newdata;
            // })
            fetchChannels()
            customToast.success("Account deleted!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
        }).catch(error => {
            // const flattenedErrors = error.data ? Object.keys(error.data)
            //     .map(key => key.replaceAll('_', ' ').toUpperCase() + ": " + error.data[key].join('\n'))
            //     .join('\n') : '';
            customToast.error("Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
            setMemberData({ openmodal: false })
        })
    }


    useEffect(() => {
        fetchChannels();
    }, [searchQuery, currentPage, searchValue]);

    return (
        <div>
            {loading &&
                <CircularLoader size={100} />}
            <div className="container-fluid py-4 pb-0">
                <div className="row">
                    <div className="col-lg-6 ps-3">
                        <Breadcrumb names={Breadcrumbname} />
                    </div>
                    <div className="col-lg-6">
                        <div className="button-row d-flex justify-content-end mt-0 mb-3">
                            <button className="btn bg-gradient-dark me-3 mb-0 text-capitalize" onClick={handleClick}>Add Slack</button>
                        </div>
                    </div>
                </div>

                <div className="row my-4">
                    <div className="col-12">
                        <CommonTable
                            searchBy={searchColumn}
                            column={tableColumn}
                            data={channels?.results}
                            totalPages={totalPages ?? 1}
                            itemPerPg={25}
                            setTable={1}
                            card={7}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            searchPlaceHolder={searchPlaceHolder}
                            setSearchPlaceHolder={setSearchPlaceHolder}
                            searchBar={true}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            filterValue={filterValue}
                            totalData={channels?.total_records}
                        >
                            <>
                                {channels?.results &&
                                    channels?.results.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <p
                                                    className="text-sm  cursor-pointer mb-0 px-2"
                                                    style={{ color: "#5A78F0" }}
                                                >
                                                    {item.channel_id}
                                                </p>
                                            </td>
                                            <td className="text-start">
                                                <p
                                                    className="text-sm  cursor-pointer mb-0 px-2"
                                                    style={{ color: "#5A78F0" }}
                                                >
                                                    {item.channel_name}
                                                </p>
                                            </td>
                                            <td className="text-start">
                                                <p className="text-sm  mb-0">{item?.program_details?.program_id}</p>
                                            </td>
                                            <td className="text-start">
                                                <p className="text-sm  mb-0">{item?.program_details?.program_title}</p>
                                            </td>   <td className="text-start">
                                                <p className="text-sm  mb-0">{item.team_id}</p>
                                            </td>
                                            <td className="text-center">
                                                <p className="text-sm  mb-0">{item.slack_user_id}</p>
                                            </td>
                                            <td className="text-center">
                                                <p className="text-sm  mb-0 text-center">
                                                    {formatDate(item.created_at, 1)}
                                                </p>
                                            </td>
                                            <td>
                                                <button className='btn btn-danger' onClick={() => handleDelete(item.id)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                            </>
                        </CommonTable>
                    </div>
                </div>
            </div>
            {memberData.openmodal === true && (
                <AddModal
                    cancelBtn={"Cancel"}
                    submitBtn={"SUBMIT"}
                    createAddTopic={"Delete"}
                    confirmText={"Delete"}
                    isOpen={memberData.openmodal}
                    onSubmit={memberData.handlesubmit}
                    onClose={() => setMemberData({ openmodal: false })}
                />
            )}
        </div>
    )
}

export default Slack;