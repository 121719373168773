import React from "react";
import "../verifymail/Verifymail.css";
import Footer from "../components/Footer/Footer";
import envicon from "./verify.svg";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

export default function Verifymail() {
	return (
		<>
			<div className="container">
				<Link to="/">
					<div className=" top-content">
						<img className="logo" src={logo} alt="logo" width="20%" />
					</div>
				</Link>
				<div
					className="row   justify-content-center"
					style={{ marginTop: " 171px!important" }}
				>
					<div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
						<div className="card z-index-0">
							<div className="main-welcome" id="verifyemail">
								<img className="mb-3" src={envicon} alt="email-icon" />
								<h4 className="verifyemail text-dark">Verify Your Email</h4>
								<span className="main-welcome-para">
									We have sent you vertification email{" "}
									<strong>example@.com</strong> Please check it
								</span>
							</div>
							<div className="card-body py-0">
								<form role="form" className="text-start">
									<div className="text-center">
										<button type="button" className="loginbtn btn  w-100  mb-2">
											Verify
										</button>
									</div>
									<div className="mb-2 position-relative text-center">
										<p className="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3">
											or
										</p>
									</div>
									<div className="text-center mb-3">
										<span className="text-decoration-underline text-cl">
											Don’t have an Account ? <Link to="#">Signup Now</Link>
										</span>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
