import React, { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import "../faqCategory/FaqCategoryStyles.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useHttp from "../../../Hooks/useHttp";
import AddModal from "../../../common/modal/AddModal";

import { useSelector } from "react-redux";
import withPermission from "../../../../hoc/withPermission";
import { getUserInfo } from "../../../../reducers/authSlice";
import HTTPService from "../../../../utils/axios";
import AppContext from "../../../Context/AppContext";
import CommonTable from "../../../common/Table/CommonTable";
import { customToast } from "../../../../App";

const FaqCategoryDashboard = () => {
  const { userInfo, permissions } = useSelector(getUserInfo);
  const permission = permissions.find((perm) => perm.module === "FAQ Category");
  const API = process.env.REACT_APP_API_URL;
  const [openAddModal, setOpenAddModal] = useState({ isOpen: false });
  const { invokeApi } = HTTPService();
  const { GetRequest, DeleteRequest, PutRequest } = useHttp();
  const token = localStorage.getItem("token");
  const [faqCatData, setFaqCatData] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const { setLoading } = useContext(AppContext);
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();
  const filterColumn = [
    {
      name: "Status",
      value: "cat_status",
      filters: [
        { value: "active", label: "Active" },
        { value: "in_active", label: "Inactive" },]
    }
  ];
  const FaqCat = async (hide) => {
    let url = `${API}faq_category/?page=${currentPage}&page_size=25`;
    if (searchQuery && searchQuery.length > 0) {
      url = url + `&${searchValue}=${searchQuery}`;
    }
    if (filterType && filterValue && filterValue.length > 0) {
      url = url + `&${filterType}=${filterValue}`;
    }
    if (!hide)
      setLoading(true);
    invokeApi({
      method: "GET",
      url,
    })
      .then((res) => {
        setLoading(false);
        setFaqCatData(res);
        setTotalPages(res.total_pages);
      })
      .catch((error) => {
        setLoading(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  useEffect(() => {
    // if (searchQuery && searchQuery.length > 0) {
    FaqCat(true);
    // }
  }, [searchQuery, currentPage, filterValue]);

  const handleStatus = async (
    id,
    title,
    code,
    cattype,
    sequence,
    uId,
    utype,
    status
  ) => {
    const param = {
      category_title: title,
      category_code: code,
      category_type: cattype,
      sequence: sequence,
      user_id: uId,
      user_type: utype,
      is_deleted: false,
      parent_category_id: "",
      cat_status: status,
    };

    invokeApi({
      method: "PUT",
      url: `faq_category/${id}/`,
      data: param,
    })
      .then((res) => {
        FaqCat();
        customToast.success("status updated", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        //setCircularLoading(false)
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const deleteFaqCat = async (id) => {
    invokeApi({
      method: "DELETE",
      url: `faq_category/${id}/`,
    })
      .then((res) => {
        FaqCat();
        customToast.success("Deleted Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setOpenAddModal({ isOpen: false });
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const navigate = useNavigate();
  const Breadcrumbname = {
    home: "/admin/home",
    "Master Module": "",
    "FAQ Category": "/admin/faq-category",
    Dashboard: "/admin/faq-category",
  };

  useEffect(() => {
    FaqCat();
  }, []);

  const tableColumn = [
    "FAQ ID",
    "CATEGORY TITLE",
    "CATEGORY TYPE",
    "FAQ TYPE",
    "ADD SUB-CATEGORY",
    "SEQUENCE",
    "Status",
    "Action",
  ];
  const searchColumn = [
    { name: "Faq Id", value: "faq_category_id" },
    { name: "Category Title", value: "category_title" },
    { name: "Category Type", value: "category_type" },
    { name: "Faq Type", value: "user_type" },
  ];

  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              {(permission?.create || userInfo.role === "admin") && (
                <Link
                  to="/admin/faq-category/add"
                  className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                  id="adduser"
                >
                  ADD FAQ Category
                </Link>
              )}
            </div>
          </div>
        </div>

        <div className="table-overflow row my-4">
          <div className="col-12">
            <CommonTable
              filterList={filterColumn}
              filterType={filterType}
              setFilterType={setFilterType}
              setFilterValue={setFilterValue}
              column={tableColumn}
              data={faqCatData && faqCatData.results}
              totalPages={totalPages ?? 1}
              totalData={faqCatData?.total_records}
              searchBar={true}
              searchBy={searchColumn}
              itemPerPg={25}
              setTable={2}
              card={7}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            >
              {faqCatData &&
                faqCatData.results &&
                faqCatData.results.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        to={
                          item.category_type === "main_category"
                            ? `/admin/faq-category/view/${item.id}`
                            : `/admin/faq-sub-category/view/${item.id}`
                        }
                        className="text-sm gotoView  mb-0 px-2"
                      >
                        {item.faq_category_id}
                      </Link>
                    </td>
                    <td className="text-start">
                      <Link
                        to={
                          item.category_type === "main_category"
                            ? `/admin/faq-category/view/${item.id}`
                            : `/admin/faq-sub-category/view/${item.id}`
                        }
                        className="text-sm gotoView  mb-0 px-2"
                      >
                        {item.category_title}
                      </Link>
                    </td>

                    <td className="text-start">
                      <p className="text-sm  mb-0">{item.category_type}</p>
                    </td>
                    <td className="text-start">
                      <p className="text-sm  mb-0">{item.user_type}</p>
                    </td>
                    <td className="d-flex justify-content-center align-items-center ">
                      <p>
                        {item.category_type === "main_category" ? (
                          <button
                            onClick={() =>
                              navigate("/admin/faq-sub-category/add")
                            }
                            className="add-subcategory-btn btn btn-primary "
                            style={{
                              marginTop: "9%",
                              marginBottom: "1%",
                            }}
                          >
                            ADD SUB-CATEGORY
                          </button>
                        ) : (
                          <p
                            className="text-sm  mb-0"
                            style={{
                              marginTop: "255%",
                            }}
                          >
                            -
                          </p>
                        )}
                      </p>
                    </td>
                    <td>
                      <p className="text-sm  mb-0">{item.sequence}</p>
                    </td>

                    <td className="align-middle text-center">
                      {item.cat_status === "active" ? (
                        <span
                          onClick={() => {
                            handleStatus(
                              item.id,
                              item.category_title,
                              item.category_code,
                              item.category_type,
                              item.sequence,
                              item.user_id,
                              item.user_type,
                              "in_active"
                            );
                          }}
                          className="badge bg-success text-white"
                          style={{ cursor: "pointer" }}
                        >
                          Active
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            handleStatus(
                              item.id,
                              item.category_title,
                              item.category_code,
                              item.category_type,
                              item.sequence,
                              item.user_id,
                              item.user_type,
                              "active"
                            );
                          }}
                          className="badge bg-danger text-white"
                          style={{ cursor: "pointer" }}
                        >
                          Inactive
                        </span>
                      )}
                    </td>
                    <td>
                      <div className="d-flex">
                        <Link
                          to={
                            item.category_type === "main_category"
                              ? `/admin/faq-category/view/${item.id}`
                              : `/admin/faq-sub-category/view/${item.id}`
                          }
                          className="btn btn-sm px-3 mt-2"
                        >
                          &#128065;
                        </Link>
                        <button
                          type="button"
                          className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v text-xs"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            {(permission?.edit ||
                              userInfo.role === "admin") && (
                                <Link
                                  className="dropdown-item"
                                  to={
                                    item.category_type === "main_category"
                                      ? `/admin/faq-category/edit/${item.id}`
                                      : `/admin/faq-sub-category/edit/${item.id}`
                                  }
                                >
                                  Edit
                                </Link>
                              )}
                          </li>
                          <li>
                            {(permission?.delete ||
                              userInfo.role === "admin") && (
                                <button
                                  onClick={() => {
                                    setOpenAddModal({
                                      isOpen: true,
                                      id: item.id,
                                    });
                                  }}
                                  className="dropdown-item"
                                >
                                  Delete
                                </button>
                              )}
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
            </CommonTable>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"DELETE"}
        createAddTopic={"Delete Faq Category"}
        confirmText={"Delete The Faq Category"}
        isOpen={openAddModal.isOpen}
        onSubmit={() => deleteFaqCat(openAddModal.id)}
        onClose={() => setOpenAddModal({ isOpen: false })}
      />
    </>
  );
};

export default withPermission(FaqCategoryDashboard);
FaqCategoryDashboard.moduleName = "FAQ Category";
