import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ViewInfoSkeleton() {
  return (
    <div className="px-5 pt-4 pb-0">
      <h6 className="pt-1 ps-2" style={{ fontWeight: "bold" }}>
        <Skeleton width={200} />
      </h6>
      {/* <p className="ps-7 mb-1" style={{ fontWeight: "bold", color: "black" }}>
        <Skeleton width={150} />
      </p> */}

      <div className="d-flex align-items-center justify-content-center">
        <div style={{ border: "1px solid #575460", width: "80%" }}>
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                style={{
                  border: "1px solid #575460",
                  display: "grid",
                  gridTemplateColumns: "40% 60%",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    borderRight: "2px solid #575460",
                    backgroundColor: "#7F50DA",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <p style={{ paddingRight: "15px", marginBottom: "0" }}>
                    <Skeleton width={100} />
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor: "#E8E0F8",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <Skeleton width="100%" height={30} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default ViewInfoSkeleton;
