import React from "react";
import { Col, Form } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

export default function EditPrgmSkeletonLoader({layout}) {
	return (
		<>
			<div className="container-fluid py-4">
				<div className="row">
					<div className="col-lg-12 m-auto ps-3">
						<Skeleton style={{ width: "300px" }} />
					</div>
				</div>
				<div className="card card-body mt-4">
					<div className="row">
						<div className={layout === 1 ? "col-lg-9" : "col-lg-12"}>
							<h6 className="mb-0">
								<Skeleton style={{ width: "152px" }} />
							</h6>
							{/* <p className="text-sm mb-0"> program</p> */}
							<hr className="horizontal dark my-3" />
							<div className="row">
								<div className="col-lg-6 ">
									<Form.Group>
										<Form.Label>
											<Skeleton style={{ width: "150px" }} />
										</Form.Label>
										<div>
											<Skeleton style={{ width: "100%", height: "40px" }} />
										</div>
									</Form.Group>
								</div>
								<div className="col-lg-6 ">
									<Form.Group>
										<Form.Label>
											<Skeleton style={{ width: "150px" }} />
										</Form.Label>
										<div>
											<Skeleton style={{ width: "100%", height: "40px" }} />
										</div>
									</Form.Group>
								</div>
							</div>

							<div className="row">
								<div className="col-lg-12 ">
									<Form.Group>
										<Form.Label>
											<Skeleton style={{ width: "150px" }} />
										</Form.Label>
										<div>
											<Skeleton style={{ width: "100%", height: "40px" }} />
										</div>
									</Form.Group>
								</div>
							</div>

							<Form.Group className="mt-2">
								<Form.Label>
									<Skeleton style={{ width: "150px" }} />
								</Form.Label>
								<div>
									<Skeleton style={{ width: "100%", height: "40px" }} />
								</div>
							</Form.Group>

							<Form.Group className="mt-2">
								<Form.Label>
									<Skeleton style={{ width: "150px" }} />
								</Form.Label>
								<div>
									<Skeleton style={{ width: "100%", height: "200px" }} />
								</div>
							</Form.Group>
						</div>
						{layout === 1 && (
							<div className="col-lg-3 ">
								<Form.Group>
									<Form.Label>
										<Skeleton width={"100px"} />
									</Form.Label>

									<div className="form-control dotted">
										<div className="w-100 file-upload d-flex justify-content-center">
											<p style={{ width: "75%" }}>
												<Skeleton style={{ width: "150px" }} />
											</p>
										</div>
									</div>
								</Form.Group>

								<Form.Group className="mt-3">
									<Form.Label>
										<Skeleton style={{ width: "150px" }} />
									</Form.Label>
									<div>
										<Skeleton
											style={{
												width: "60px",
												height: "22px",
												borderRadius: "12px",
											}}
										/>
									</div>
								</Form.Group>

								<Form.Group className="mt-2">
									<Form.Label>
										<Skeleton width={"100px"} />
									</Form.Label>

									<div className="form-control dotted">
										<div className="w-100 file-upload d-flex justify-content-center">
											<p style={{ width: "75%" }}>
												<Skeleton style={{ width: "150px" }} />
											</p>
										</div>
									</div>
								</Form.Group>
							</div>
						)}
					</div>

					<Form.Group className="mt-2">
						<Form.Label>
							<Skeleton style={{ width: "150px" }} />
						</Form.Label>
						<div>
							<Skeleton style={{ width: "100%", height: "40px" }} />
						</div>
					</Form.Group>

					<Form.Group className="mt-2">
						<Form.Label>
							<Skeleton style={{ width: "150px" }} />
						</Form.Label>
						<div>
							<Skeleton style={{ width: "100%", height: "40px" }} />
						</div>
					</Form.Group>
					<div className="row ">
						<div className="col-12">
							<Form.Group className="mt-2">
								<Form.Label>
									<Skeleton style={{ width: "150px" }} />
								</Form.Label>

								<div className="d-flex">
									<div>
										<Skeleton style={{ width: "600px", height: "40px" }} />
									</div>
									<div>
										<Skeleton
											height={"40px"}
											width={"70px"}
											style={{ marginLeft: "10px" }}
										/>
									</div>
								</div>
							</Form.Group>
						</div>
					</div>

					<div className="row">
						<div className="col-12">
							<Form.Group className="mt-2">
								<Form.Label>
									<Skeleton style={{ width: "150px" }} />
								</Form.Label>

								<div className="d-flex">
									<div>
										<Skeleton style={{ width: "600px", height: "40px" }} />
									</div>
									<div>
										<Skeleton
											height={"40px"}
											width={"70px"}
											style={{ marginLeft: "10px" }}
										/>
									</div>
								</div>
							</Form.Group>
						</div>
					</div>

					<div className="row mt-2">
						<div className="col-lg-6 col-12">
							<Form.Group className="mt-2">
								<Form.Label>
									<Skeleton style={{ width: "150px" }} />
								</Form.Label>
								<div>
									<Skeleton style={{ width: "100%", height: "40px" }} />
								</div>
							</Form.Group>
						</div>
						<div className="col-lg-6 col-12">
							<Form.Group className="mt-2">
								<Form.Label>
									<Skeleton style={{ width: "150px" }} />
								</Form.Label>
								<div>
									<Skeleton style={{ width: "100%", height: "40px" }} />
								</div>
							</Form.Group>
						</div>
					</div>
					<div className="row mt-3">
						<Form.Label>
							<Skeleton style={{ width: "150px" }} />
						</Form.Label>

						<div className="d-flex">
							<div>
								<Skeleton style={{ width: "600px", height: "40px" }} />
							</div>
							<div>
								<Skeleton
									height={"40px"}
									width={"70px"}
									style={{ marginLeft: "10px" }}
								/>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-5">
							<Form.Group className="mt-3">
								<Form.Label>
									<Skeleton style={{ width: "150px" }} />
								</Form.Label>
								<div>
									<Skeleton style={{ width: "100%", height: "40px" }} />
								</div>
							</Form.Group>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<Form.Group className="mt-3">
								<Form.Label>
									<Skeleton style={{ width: "150px" }} />
								</Form.Label>
								<div>
									<Skeleton style={{ width: "100%", height: "40px" }} />
								</div>
							</Form.Group>
						</div>
						<div className="col-lg-6">
							<Form.Group className="mt-3">
								<Form.Label>
									<Skeleton style={{ width: "150px" }} />
								</Form.Label>
								<div>
									<Skeleton style={{ width: "100%", height: "40px" }} />
								</div>
							</Form.Group>
						</div>
					</div>
					<div className="row mb-3 mt-4">
						<Form.Group as={Col} md="12">
							<Form.Label>
								<Skeleton style={{ width: "150px" }} />
							</Form.Label>
							<div>
								<Skeleton style={{ width: "100%", height: "40px" }} />
							</div>
						</Form.Group>
					</div>
					<div className="row mb-3 mt-4">
						<div className="col-6">
							<Form.Label>
								<Skeleton style={{ width: "150px" }} />
							</Form.Label>
							<div>
								<Skeleton style={{ width: "100%", height: "40px" }} />
							</div>
						</div>
						<div className="col-6">
							<Form.Label>
								<Skeleton style={{ width: "150px" }} />
							</Form.Label>
							<div>
								<Skeleton style={{ width: "100%", height: "40px" }} />
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-6">
							<Form.Group className="mt-3">
								<div className="d-flex justify-content-between">
									<Form.Label>
										<Skeleton style={{ width: "150px" }} />
									</Form.Label>
								</div>

								<div className="form-control dotted">
									<div className="file-upload  ">
										<p>
											<Skeleton style={{ width: "400px" }} />
										</p>
									</div>
								</div>
								{/* {taxofiles === undefined || taxofiles.length > 0 ? (
										""
									) : (
										<p style={{ color: "red", fontSize: "13px" }}>
											Upload atleast one file
										</p>
									)} */}

								<p className="form-text text-muted text-xs ms-1 mt-2">
									<Skeleton width={200} />
								</p>
							</Form.Group>
						</div>

						<div className="col-lg-6">
							<Form.Group className="mt-3">
								<div className="d-flex justify-content-between">
									<Form.Label>
										<Skeleton style={{ width: "150px" }} />
									</Form.Label>
								</div>

								<div className="form-control dotted">
									<div className="file-upload  ">
										<p>
											<Skeleton style={{ width: "400px" }} />
										</p>
									</div>
								</div>

								<p className="form-text text-muted text-xs ms-1 mt-2">
									<Skeleton width={200} />
								</p>
							</Form.Group>
						</div>
					</div>

					<div className="d-flex justify-content-end mt-4">
						<Skeleton style={{ height: "40px", width: "95px" }} />
						<Skeleton
							style={{ height: "40px", width: "120px", marginLeft: "20px" }}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
