import React from "react";
import Skeleton from "react-loading-skeleton";
import { useLoaderData, useLocation } from "react-router-dom";

export default function CommonMemberEditSkeleton() {
	const location = useLocation();
	return (
		<>
			<div className="bg-gray-100">
				<main className="position-relative mt-0">
					<div
						className="container-fluid pt-4  "
						style={{ background: "#f5f5fb" }}
					>
						<div className="row align-items-center">
							<div className="col-lg-4 col-sm-8">
								<div className="nav-wrapper position-relative end-0">
									<Skeleton style={{ width: "82%", height: "40px" }} />
								</div>
							</div>
						</div>
						<div className="container-fluid my-3 py-3 mb-0 p-0">
							<div className="row mb-5">
								<div className="col-12 mb-4">
									{/* <!-- Card Profile --> */}
									<div className="card card-body" id="profile">
										<div className="row justify-content-center align-items-center">
											<div className="col-sm-auto col-4">
												<Skeleton circle height={"78px"} width={"78px"} />
											</div>
											<div className="col-sm-auto col-8 my-auto">
												<div className="h-100">
													<h5 className="mb-1 font-weight-bolder">
														<Skeleton style={{ width: "170px" }} />
													</h5>
													<p className="mb-0 font-weight-bold text-sm">
														<Skeleton style={{ width: "150px" }} />
													</p>
												</div>
											</div>
											<div className="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
												<Skeleton style={{ width: "150px", height: "20px" }} />
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-3">
									<div className="card position-sticky top-1">
										<ul className="nav flex-column bg-white border-radius-lg p-3">
											<li className="nav-item">
												<Skeleton height={18} width={313} count={7} />
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-9 mt-lg-0 mt-4">
									<>
										{/* <!-- Card Basic Info --> */}
										<div className="card mt-0" id="basic-info">
											<div className="card-header">
												<Skeleton width={100} />
											</div>
											<div className="card-body pt-0">
												<div className="row">
													<div className="col-6">
														<Skeleton width={100} />

														<Skeleton width={"100%"} height={40} />
													</div>
													<div className="col-6">
														<Skeleton width={100} />

														<Skeleton width={"100%"} height={40} />
													</div>
												</div>
												<div className="row mt-3">
													{location.pathname.includes("researcher") && (
														<div className="col-sm-6 col-6">
															<Skeleton width={100} />
															<Skeleton width={"100%"} height={40} />
														</div>
													)}

													<div className="col-sm-6">
														<Skeleton width={100} />
														<Skeleton width={"100%"} height={40} />
													</div>

													{location.pathname.includes("customer") && (
														<div className="col-sm-6 col-6">
															<Skeleton width={100} />
															<Skeleton width={"100%"} height={40} />
														</div>
													)}
												</div>

												<div className="col-sm-6 mt-3">
													<Skeleton width={100} />
													<Skeleton width={"100%"} height={40} />
												</div>

												{location.pathname.includes("customer") && (
													<div className="col-sm-6 col-6 mt-3">
														<Skeleton width={100} />
														<Skeleton width={"100%"} height={40} />
													</div>
												)}
											</div>

											<div className="card-body row mt-3">
												<div className="col-6">
													<Skeleton width={100} />
													<Skeleton width={"100%"} height={40} />
												</div>
												<div className="col-6">
													<Skeleton width={100} />
													<Skeleton width={"100%"} height={40} />
												</div>
											</div>
											<div className="card-body ">
												<div className="row">
													<div className="col-sm-12">
														<Skeleton width={100} />
														<Skeleton width={"100%"} height={40} />
													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<Skeleton width={100} />
														<Skeleton width={"100%"} height={40} />
													</div>
												</div>
											</div>
											<div className="row d-flex justify-content-end p-3 px-4">
												<div className="col-3 d-flex justify-content-end">
													<Skeleton height={30} width={80} />
												</div>
											</div>
										</div>
										{/* <!-- Card Change Password --> */}
										<div className="card mt-4" id="password">
											<div className="card-header">
												<Skeleton width={150} height={21} />
											</div>
											<div className="card-body pt-0">
												<Skeleton width={100} />
												<Skeleton width={"100%"} height={40} />
												<Skeleton width={100} />
												<Skeleton width={"100%"} height={40} />
												<Skeleton width={100} />
												<Skeleton width={"100%"} height={40} />
												<Skeleton width={150} height={21} className="mt-5" />
												<Skeleton width={200} height={16} className="mt-2" />
												<Skeleton width={150} height={14} count={4} />
												<div className="row d-flex justify-content-end p-3 px-4">
													<div className="col-3 d-flex justify-content-end">
														<Skeleton height={30} width={120} />
													</div>
												</div>
											</div>
										</div>
										
									</>
								</div>
							</div>
						</div>
					</div>
					{/* <Footer /> */}
				</main>
			</div>
		</>
	);
}
