import React from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import pentestImg from "./components/images/PenTesting-1.png"
import penstage from "./components/images/PenTestStages.png"
import penStep from "./components/images/Processteps.png"

export default function PenTesting() {
  return (
    <>
      <Header />
      <header class="uui-section_heroheader03">
        <div class="uui-page-padding-4">
          <header class="uui-section_heroheader05">
            <div class="uui-page-padding-17">
              <div class="uui-container-large-14">
                <div class="uui-padding-vertical-xhuge-15">
                  <div class="w-layout-grid uui-heroheader05_component">
                    <div class="uui-heroheader05_content">
                      <div class="uui-space-small-11"></div>
                      <h1 class="uui-heading-xlarge-9">Penetration Testing</h1>
                      <div class="uui-space-small-11"></div>
                      <div class="uui-text-size-xlarge-9">
                        Beyond the Surface:
                        <strong class="bold-text-2">Uncover</strong> the
                        <strong class="bold-text-3">Unseen</strong> with
                        Penetration Testing.
                      </div>
                      <div class="uui-space-large-8"></div>
                    </div>
                    <img
                      src={pentestImg}
                      loading="lazy"
                      id="w-node-_2ca2d588-a438-60c6-a413-40e3f0694c6b-a5e62066"
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 87vw, 35vw"
                      alt="Illustration depicting Penetration Testing"
                    />
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div class="uui-container-large-4"></div>
        </div>
      </header>
      <div class="uui-page-padding-3">
        <div class="uui-container-large-3"></div>
        <section class="uui-section_layout32">
          <div class="uui-page-padding-13">
            <div class="uui-container-large-10"></div>
          </div>
          <div class="uui-page-padding-13">
            <div class="uui-container-large-10"></div>
          </div>
        </section>
        <section class="uui-section_layout08">
          <div class="uui-page-padding-13">
            <div class="uui-container-large-14">
              <div class="uui-padding-vertical-xhuge-15">
                <div class="w-layout-grid uui-layout08_component">
                  <div class="uui-layout08_content">
                    <h2 class="uui-heading-medium-9">Pentest Process</h2>
                    <div class="uui-space-xsmall-8">
                      <img
                        src={penstage}
                        loading="lazy"
                        sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, 87vw"
                        alt=""
                        class="image-8"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="uui-padding-vertical-xhuge-15">
                <div class="w-layout-grid uui-layout08_component">
                  <div class="uui-layout08_content">
                    <h2 class="uui-heading-medium-9">
                      Advanced Penetration Testing for Enhanced Security
                    </h2>
                    <div class="uui-space-xsmall-8"></div>
                    <div class="uui-text-size-large-8">
                      Our specialized Advanced Penetration Testing service
                      enhances an organization&#x27;s cybersecurity by
                      evaluating its information systems, networks,
                      applications, and infrastructure. Ethical hacking
                      activities uncover vulnerabilities, strengthening
                      security. Our approach includes:
                    </div>
                    <div class="uui-layout08_item-list">
                      <div
                        id="w-node-_332813ba-8c27-c05f-20a1-bdb6677b599a-a5e62066"
                        class="uui-layout08_item"
                      >
                        <div class="uui-layout08_item-icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-8 w-embed">
                            <svg
                              width="currentWidth"
                              height="currentHeight"
                              viewbox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                fill="#F4EBFF"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                fill="#9E77ED"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="uui-layout08_item-text-wrapper">
                          <div class="uui-text-size-large-8">
                            Define Scope and Objectives
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_332813ba-8c27-c05f-20a1-bdb6677b59a0-a5e62066"
                        class="uui-layout08_item"
                      >
                        <div class="uui-layout08_item-icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-8 w-embed">
                            <svg
                              width="currentWidth"
                              height="currentHeight"
                              viewbox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                fill="#F4EBFF"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                fill="#9E77ED"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="uui-layout08_item-text-wrapper">
                          <div class="uui-text-size-large-8">
                            Understand the Environment
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_332813ba-8c27-c05f-20a1-bdb6677b59a6-a5e62066"
                        class="uui-layout08_item"
                      >
                        <div class="uui-layout08_item-icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-8 w-embed">
                            <svg
                              width="currentWidth"
                              height="currentHeight"
                              viewbox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                fill="#F4EBFF"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                fill="#9E77ED"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="uui-layout08_item-text-wrapper">
                          <div class="uui-text-size-large-8">
                            Threat Modeling
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_332813ba-8c27-c05f-20a1-bdb6677b59ac-a5e62066"
                        class="uui-layout08_item"
                      >
                        <div class="uui-layout08_item-icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-8 w-embed">
                            <svg
                              width="currentWidth"
                              height="currentHeight"
                              viewbox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                fill="#F4EBFF"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                fill="#9E77ED"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="uui-layout08_item-text-wrapper">
                          <div class="uui-text-size-large-8">
                            Real-World Attack Scenarios
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_332813ba-8c27-c05f-20a1-bdb6677b59b2-a5e62066"
                        class="uui-layout08_item"
                      >
                        <div class="uui-layout08_item-icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-8 w-embed">
                            <svg
                              width="currentWidth"
                              height="currentHeight"
                              viewbox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                fill="#F4EBFF"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                fill="#9E77ED"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="uui-layout08_item-text-wrapper">
                          <div class="uui-text-size-large-8">
                            Test Multiple Surfaces
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_332813ba-8c27-c05f-20a1-bdb6677b59b8-a5e62066"
                        class="uui-layout08_item"
                      >
                        <div class="uui-layout08_item-icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-8 w-embed">
                            <svg
                              width="currentWidth"
                              height="currentHeight"
                              viewbox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                fill="#F4EBFF"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                fill="#9E77ED"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="uui-layout08_item-text-wrapper">
                          <div class="uui-text-size-large-8">
                            Customized Tools and Techniques
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_332813ba-8c27-c05f-20a1-bdb6677b59be-a5e62066"
                        class="uui-layout08_item"
                      >
                        <div class="uui-layout08_item-icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-8 w-embed">
                            <svg
                              width="currentWidth"
                              height="currentHeight"
                              viewbox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                fill="#F4EBFF"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                fill="#9E77ED"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="uui-layout08_item-text-wrapper">
                          <div class="uui-text-size-large-8">
                            Red Team Tactics
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_332813ba-8c27-c05f-20a1-bdb6677b59c4-a5e62066"
                        class="uui-layout08_item"
                      >
                        <div class="uui-layout08_item-icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-8 w-embed">
                            <svg
                              width="currentWidth"
                              height="currentHeight"
                              viewbox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                fill="#F4EBFF"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                fill="#9E77ED"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="uui-layout08_item-text-wrapper">
                          <div class="uui-text-size-large-8">
                            Compliance and Regulations
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_332813ba-8c27-c05f-20a1-bdb6677b59ca-a5e62066"
                        class="uui-layout08_item"
                      >
                        <div class="uui-layout08_item-icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-8 w-embed">
                            <svg
                              width="currentWidth"
                              height="currentHeight"
                              viewbox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                fill="#F4EBFF"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                fill="#9E77ED"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="uui-layout08_item-text-wrapper">
                          <div class="uui-text-size-large-8">
                            Collaboration Engagement
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uui-container-large-10"></div>
          </div>
          <div class="uui-page-padding-17">
            <div class="uui-container-large-10">
              <div class="uui-padding-vertical-xhuge-11">
                <div class="uui-layout32_content-left">
                  <div class="uui-heading-subheading-7">How it works</div>
                  <h2 class="uui-heading-medium-7">
                    <strong>
                      Simple steps to launch Pentest for your product
                    </strong>
                  </h2>
                  <div class="uui-space-medium-4"></div>
                </div>
                <div class="uui-layout32_content-right">
                  <img
                    src={penStep}
                    loading="lazy"
                    sizes="(max-width: 479px) 69vw, (max-width: 767px) 73vw, 70vw"
                    alt=""
                    class="image-7"
                  />
                </div>
              </div>
            </div>
            <div class="uui-page-padding-13"></div>
            <section class="uui-section_pricing04">
              <div class="uui-container-large-15">
                <div class="uui-padding-vertical-xhuge-16">
                  <div class="uui-text-align-center-9">
                    <div class="uui-max-width-large-10 align-center">
                      <div class="uui-heading-subheading-10">Pricing</div>
                      <h2 class="uui-heading-medium-10">
                        Simple, transparent pricing
                      </h2>
                      <div class="uui-space-xsmall-9"></div>
                    </div>
                  </div>
                  <div class="w-layout-grid uui-pricing04_components">
                    <div class="uui-pricing04_plan">
                      <div class="uui-pricing04_content">
                        <div class="uui-pricing04_content-top">
                          <div class="uui-text-align-center-9">
                            <h2 class="uui-heading-xxsmall-8">Basic Web App</h2>
                            <div class="uui-pricing04_price">
                              $499
                              <span class="uui-pricing04_price-text">
                                /assesments
                              </span>
                            </div>
                          </div>
                          <div class="uui-pricing04_feature-list">
                            <div
                              id="w-node-_4b876c9a-cfc3-d176-6f1e-069185f9789e-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                2 User Roles
                              </div>
                            </div>
                            <div
                              id="w-node-_4b876c9a-cfc3-d176-6f1e-069185f978a3-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                5 Distinct Features/Functions
                              </div>
                            </div>
                            <div
                              id="w-node-_4b876c9a-cfc3-d176-6f1e-069185f978a8-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                Comprehensive Reports
                              </div>
                            </div>
                            <div
                              id="w-node-_2de960b9-135b-853d-68a6-abfb253997d9-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                Dashboard Access
                              </div>
                            </div>
                            <div
                              id="w-node-_87669b3a-308e-8af7-6f47-db7673e6923f-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-text-size-medium-9">
                                ------------------OR-------------------
                              </div>
                            </div>
                            <div
                              id="w-node-_92bb4ce1-9128-c5d6-c682-7fd4edb0806b-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-text-size-medium-9">
                                <strong>Network</strong>
                              </div>
                            </div>
                            <div
                              id="w-node-_4b876c9a-cfc3-d176-6f1e-069185f978ad-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                20 active IP address
                              </div>
                            </div>
                          </div>
                        </div>
                        <Link
                          to="/contactus"
                          class="uui-button-14 w-inline-block text-white"
                        >
                          <div>Get started</div>
                        </Link>
                      </div>
                    </div>
                    <div class="uui-pricing04_popular-plan">
                      <div class="uui-pricing04_popular-label">
                        <div>Most popular plan</div>
                      </div>
                      <div class="uui-pricing04_content">
                        <div class="uui-pricing04_content-top">
                          <div class="uui-text-align-center-9">
                            <h2 class="uui-heading-xxsmall-8">
                              Standard Web App
                            </h2>
                            <div class="uui-pricing04_price">
                              $2999
                              <span class="uui-pricing04_price-text">
                                /assesments
                              </span>
                            </div>
                          </div>
                          <div class="uui-pricing04_feature-list">
                            <div
                              id="w-node-_4b876c9a-cfc3-d176-6f1e-069185f978ca-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                2 User Roles
                              </div>
                            </div>
                            <div
                              id="w-node-_4b876c9a-cfc3-d176-6f1e-069185f978cf-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                20 Distinct Features/Functions
                              </div>
                            </div>
                            <div
                              id="w-node-_4b876c9a-cfc3-d176-6f1e-069185f978d4-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                Comprehensive Reports
                              </div>
                            </div>
                            <div
                              id="w-node-_4b876c9a-cfc3-d176-6f1e-069185f978d9-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                Dashboard Access
                              </div>
                            </div>
                            <div
                              id="w-node-_5853350a-2d38-c5ec-baba-e646c87d49f0-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-text-size-medium-9">
                                ------------------OR-------------------
                              </div>
                            </div>
                            <div
                              id="w-node-_0c3f1820-3be9-b28c-125a-f34b2cee1032-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-text-size-medium-9">
                                <strong>Network</strong>
                              </div>
                            </div>
                            <div
                              id="w-node-aaa837ae-3fc7-81d7-bf3b-f8a27f32464b-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                100 active IP address
                              </div>
                            </div>
                          </div>
                        </div>
                        <Link
                          to="/contactus"
                          class="uui-button-14 w-inline-block text-white"
                        >
                          <div>Get started</div>
                        </Link>
                      </div>
                    </div>
                    <div class="uui-pricing04_plan">
                      <div class="uui-pricing04_content">
                        <div class="uui-pricing04_content-top">
                          <div class="uui-text-align-center-9">
                            <h2 class="uui-heading-xxsmall-8">
                              Premium Web App
                            </h2>
                            <div class="uui-pricing04_price">
                              $4999
                              <span class="uui-pricing04_price-text">
                                /assesments
                              </span>
                            </div>
                          </div>
                          <div class="uui-pricing04_feature-list">
                            <div
                              id="w-node-_4b876c9a-cfc3-d176-6f1e-069185f978f3-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                3 User Roles
                              </div>
                            </div>
                            <div
                              id="w-node-_4b876c9a-cfc3-d176-6f1e-069185f978f8-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                30 Distinct Features/Functions
                              </div>
                            </div>
                            <div
                              id="w-node-_4b876c9a-cfc3-d176-6f1e-069185f978fd-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                Comprehensive Reports
                              </div>
                            </div>
                            <div
                              id="w-node-_4b876c9a-cfc3-d176-6f1e-069185f97902-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                Dashboard Access
                              </div>
                            </div>
                            <div
                              id="w-node-af343e0b-eb34-a1e8-68bc-c8225e3167ef-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-text-size-medium-9">
                                ------------------OR-------------------
                              </div>
                            </div>
                            <div
                              id="w-node-ddd6c1e6-20c4-27b9-49eb-8f0f8cec1cf1-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-text-size-medium-9">
                                <strong>Network</strong>
                              </div>
                            </div>
                            <div
                              id="w-node-e2029d0d-ee5e-5571-c3ca-d48f71d61bef-a5e62066"
                              class="uui-pricing04_feature"
                            >
                              <div class="uui-pricing04_icon-wrapper">
                                <div class="uui-icon-1x1-xsmall-9 w-embed">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_4195_156141)">
                                      <path
                                        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#039855"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clippath id="clip0_4195_156141">
                                        <rect
                                          width="24"
                                          height="24"
                                          rx="12"
                                          fill="white"
                                        ></rect>
                                      </clippath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                              <div class="uui-text-size-medium-9">
                                200 active IP address
                              </div>
                            </div>
                          </div>
                        </div>
                        <Link
                          to="/contactus"
                          class="uui-button-14 w-inline-block text-white"
                        >
                          <div>Get started</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uui-page-padding-18"></div>
            </section>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
