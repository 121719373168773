import React from "react";
import Header from "./components/Header";
import Signup from "./Signup";
import Footer from "./components/Footer";

const ResearcherSignup = () => {
	return (
		<>
			<Header />
			<Signup loginLink="/researcher/login"/>
			<Footer />
		</>
	);
};

export default ResearcherSignup;
