import React from "react";
import { Link } from "react-router-dom";
import downarrowdark from "../../assets/svg/downarrow.svg";

import menudata from "../menudata/menudata.json";

const Payments = (props) => {
	const userType = props.usertype;

	const userRole = Object.entries(menudata).filter(
		([key, value]) => key === userType
	);
	return (
		<>
			<li className="nav-item dropdown dropdown-hover">
				<Link
					className="nav-link  d-flex justify-content-between cursor-pointer align-items-center"
					to="#"
					role="button"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="me-2"
						width="18"
						height="14"
						viewBox="0 0 18 14"
						fill="none"
					>
						<path
							d="M2.8125 0.0319824C2.06658 0.0319824 1.35121 0.327824 0.823762 0.854425C0.296316 1.38103 0 2.09525 0 2.83998V4.30014H18V2.83998C18 2.09525 17.7037 1.38103 17.1762 0.854425C16.6488 0.327824 15.9334 0.0319824 15.1875 0.0319824H2.8125ZM0 10.7024V5.42333H18V10.7024C18 11.4471 17.7037 12.1613 17.1762 12.6879C16.6488 13.2145 15.9334 13.5104 15.1875 13.5104H2.8125C2.06658 13.5104 1.35121 13.2145 0.823762 12.6879C0.296316 12.1613 0 11.4471 0 10.7024ZM12.2625 9.24221C12.1133 9.24221 11.9702 9.30138 11.8648 9.4067C11.7593 9.51202 11.7 9.65486 11.7 9.80381C11.7 9.95275 11.7593 10.0956 11.8648 10.2009C11.9702 10.3062 12.1133 10.3654 12.2625 10.3654H14.7375C14.8867 10.3654 15.0298 10.3062 15.1352 10.2009C15.2407 10.0956 15.3 9.95275 15.3 9.80381C15.3 9.65486 15.2407 9.51202 15.1352 9.4067C15.0298 9.30138 14.8867 9.24221 14.7375 9.24221H12.2625Z"
							fill="#7F56D9"
						></path>
					</svg>
					Payments
					<img
						src={downarrowdark}
						alt="down-arrow"
						className="arrow ms-1 d-lg-block d-none"
					/>
					<img
						src={downarrowdark}
						alt="down-arrow"
						className="arrow ms-1 d-lg-none d-block"
					/>
				</Link>
				<div
					className="dropdown-menu dropdown-menu-animation dropdown-md dropdown-md-responsive p-3 border-radius-lg mt-0 mt-lg-2"
					aria-labelledby="dropdownMenuBlocks"
				>
					<div className="d-none d-lg-block">
						<ul className="list-group">
							{userRole[0][1]?.payments && userRole[0][1].payments.submenu.map((item, index) => {
								if ((props?.overAllPermissions && props?.overAllPermissions[item.name]?.view) || ['admin', 'customer', 'researcher'].includes(userType))
									return (
										<li key={index} className="nav-item dropdown dropdown-hover dropdown-subitem list-group-item border-0 p-0">
											<Link
												className="dropdown-item py-2 ps-3 border-radius-md"
												to={item.link}
											>
												<div className="d-flex">
													<div className="icon h-10 me-3 d-flex mt-1">
														<i className={item.icon}></i>
													</div>
													<div className="w-100 d-flex align-items-center justify-content-between">
														<div>
															<p className="dropdown-header text-dark p-0">
																{item.name}
															</p>
														</div>
													</div>
												</div>
											</Link>
										</li>
									);
							})}
						</ul>
					</div>
				</div>
			</li>
		</>
	);
};

export default Payments;
