import React, { useContext } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";

import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import Backbutton from "../components/backbutton/Backbutton";
import CircularLoader from "../CircularLoader/CircularLoader";
import AppContext from "../Context/AppContext";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../reducers/authSlice";

const AdminMainProgramTab = () => {
  const location = useLocation();
  const { id } = useParams();
  const { loading } = useContext(AppContext);
  const trueLocation = () => {
    if (
      location.pathname === `/admin/program/${id}/summary` ||
      location.pathname === "/admin/program" ||
      location.pathname === `/customer/program/${id}/summary` ||
      location.pathname === "/customer/program"
    ) {
      return "Summary";
    }
    if (
      location.pathname === `/admin/program/${id}/details` ||
      location.pathname === `/customer/program/${id}/details`
    ) {
      return "Details";
    }
    if (
      location.pathname === `/admin/program/${id}/submission` ||
      location.pathname === `/customer/program/${id}/submission`
    ) {
      return "Submission";
    }
    if (
      location.pathname === `/admin/program/${id}/researcher` ||
      location.pathname === `/customer/program/${id}/researcher`
    ) {
      return "Researcher";
    }
    if (
      location.pathname === `/admin/program/${id}/rewards` ||
      location.pathname === `/customer/program/${id}/rewards`
    ) {
      return "Rewards";
    }
    if (
      location.pathname === `/admin/program/${id}/reports` ||
      location.pathname === `/customer/program/${id}/reports`
    ) {
      return "Reports";
    }
    if (
      location.pathname === `/admin/program/${id}/insights` ||
      location.pathname === `/customer/program/${id}/insights`
    ) {
      return "Insights";
    }
    if (
      location.pathname === `/admin/program/${id}/tracking` ||
      location.pathname === `/customer/program/${id}/tracking`
    ) {
      return "Tracking";
    }
    if (
      location.pathname === `/admin/program/${id}/settings` ||
      location.pathname === `/customer/program/${id}/settings`
    ) {
      return "Settings";
    }
  };
  const Breadcrumbname = {
    home: location.pathname.startsWith("/admin")
      ? "/admin/home"
      : "/customer/home",
    Management: location.pathname.startsWith("/admin")
      ? `/admin/program/dashboard`
      : `/customer/program/dashboard`,
    Programs: location.pathname.startsWith("/admin")
      ? `/admin/program/dashboard`
      : `/customer/program/dashboard`,
    [trueLocation()]: "",
  };
  const { userInfo } = useSelector(getUserInfo);

  return (
    <>
      {loading && <CircularLoader size={100} />}
      <div className="container-fluid pt-4  " style={{ background: "#f5f5fb" }}>
        <div className="row">
          <div className="col-lg-6 m-auto ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            {/* <Backbutton /> */}
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              <Link
                to={`/${userInfo?.role}/program/dashboard`}
                className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
              >
                Back
              </Link>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-8">
            <div className="nav-wrapper position-relative end-0">
              <ul className="nav nav-pills nav-fill p-1" role="tablist">
                <li className="nav-item" role="presentation">
                  <Link
                    className={
                      location.pathname === `/admin/program/${id}/summary` ||
                        location.pathname === "/admin/program" ||
                        location.pathname === `/customer/program/${id}/summary`
                        ? "nav-link mb-0 px-0 py-1 active"
                        : "nav-link mb-0 px-0 py-1"
                    }
                    to={
                      location.pathname.startsWith("/admin")
                        ? `/admin/program/${id}/summary`
                        : `/customer/program/${id}/summary`
                    }
                    aria-selected="false"
                  >
                    Summary
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className={
                      location.pathname === `/admin/program/${id}/details` ||
                        location.pathname === `/customer/program/${id}/details`
                        ? "nav-link mb-0 px-0 py-1 active"
                        : "nav-link mb-0 px-0 py-1"
                    }
                    to={
                      location.pathname.startsWith("/admin")
                        ? `/admin/program/${id}/details`
                        : `/customer/program/${id}/details`
                    }
                    aria-selected="false"
                  >
                    Details
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className={
                      location.pathname === `/admin/program/${id}/submission` ||
                        location.pathname === `/customer/program/${id}/submission`
                        ? "nav-link mb-0 px-0 py-1 active"
                        : "nav-link mb-0 px-0 py-1"
                    }
                    to={
                      location.pathname.startsWith("/admin")
                        ? `/admin/program/${id}/submission`
                        : `/customer/program/${id}/submission`
                    }
                    aria-selected="false"
                  >
                    Submissions
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      location.pathname === `/admin/program/${id}/researcher` ||
                        location.pathname === `/customer/program/${id}/researcher`
                        ? "nav-link mb-0 px-0 py-1 active"
                        : "nav-link mb-0 px-0 py-1"
                    }
                    to={
                      location.pathname.startsWith("/admin")
                        ? `/admin/program/${id}/researcher`
                        : `/customer/program/${id}/researcher`
                    }
                    aria-selected="false"
                  >
                    Researchers
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      location.pathname === `/admin/program/${id}/rewards` ||
                        location.pathname === `/customer/program/${id}/rewards`
                        ? "nav-link mb-0 px-0 py-1 active"
                        : "nav-link mb-0 px-0 py-1"
                    }
                    to={
                      location.pathname.startsWith("/admin")
                        ? `/admin/program/${id}/rewards`
                        : `/customer/program/${id}/rewards`
                    }
                    aria-selected="false"
                  >
                    Rewards
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={
                      location.pathname === `/admin/program/${id}/tracking` ||
                        location.pathname === `/customer/program/${id}/tracking`
                        ? "nav-link mb-0 px-0 py-1 active"
                        : "nav-link mb-0 px-0 py-1"
                    }
                    to={
                      location.pathname.startsWith("/admin")
                        ? `/admin/program/${id}/tracking`
                        : `/customer/program/${id}/tracking`
                    }
                    aria-selected="false"
                  >
                    Tracking
                  </Link>
                </li>

                {/* <li className="nav-item">
									<Link
										className={
											location.pathname === `/admin/program/${id}/insights
												? "nav-link mb-0 px-0 py-1 active"
												: "nav-link mb-0 px-0 py-1"
										}
										to={`/admin/program/${id}/insights`}
										aria-selected="false"
									>
										Insights
									</Link>
								</li> */}
                {/* <li className="nav-item">
                  <Link
                    className={
                      location.pathname === `/admin/program/${id}/reports` ||
                      location.pathname === `/customer/program/${id}/reports`
                        ? "nav-link mb-0 px-0 py-1 active"
                        : "nav-link mb-0 px-0 py-1"
                    }
                    to={
                      location.pathname.startsWith("/admin")
                        ? `/admin/program/${id}/reports`
                        : `/customer/program/${id}/reports`
                    }
                    aria-selected="false"
                  >
                    Reports
                  </Link>
                </li> */}
                {/* <li className="nav-item">
									<Link
										className={
											location.pathname === `/admin/program/${id}/settings`
												? "nav-link mb-0 px-0 py-1 active"
												: "nav-link mb-0 px-0 py-1"
										}
										to={`/admin/program/${id}/settings`}
										aria-selected="false"
									>
										Settings
									</Link>
								</li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-12 mt-lg-0 mt-4 d-flex justify-content-center">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminMainProgramTab;
