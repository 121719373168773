import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import submissionStatusData from "./submissionStatusData.json";
import { ToastContainer, toast } from "react-toastify";
import useHttp from "../../Hooks/useHttp";
import {
  getObjectKeys,
  lineOverFlowDot,
  flattenedObject,
} from "../../../utils/helperFunctions";
import AddModal from "../../common/modal/AddModal";
import CircularLoader from "../../CircularLoader/CircularLoader";
import AppContext from "../../Context/AppContext";
import CommonTable from "../../common/Table/CommonTable";
import CountSkeletonLoader from "../../components/SkeletonLoader/CountSkeletonLoader";
import HTTPService from "../../../utils/axios";
import { customToast } from "../../../App";

const tableColumn = [
  "submission id",
  "date",
  "title",
  "metrics stats",
  { label: "payment details", align: "center" },
  { label: "payment status", align: "center" },
  { label: "processed by", align: "center" },
  "status",
  "action",
];

const searchColumn = [
  { name: "Submission Id", value: "submissions_id" },
  // { name: "Date", value: "created_at" },
  { name: "Title", value: "submission_title" },
  // { name: "Priority", value: "priority" },
  // { name: "Severity", value: "severity" },
  { name: "VTx", value: "vtx" },
  // { name: "Submission Status", value: "submission_status" },
];

const filterColumn = [
  {
    name: "Priority",
    value: "priority",
    filters: ["P1", "P2", "P3", "P4", "P5"],
  },
  {
    name: "Severity",
    value: "severity",
    filters: ["Informational", "Low", "Moderate", "Critical", "Severe"],
  },
  {
    name: "Submission Status",
    value: "submission_status",
    filters: ["new", "triaged", "review", "resolved", "unresolved", "rejected"],
  },
];

export default function ResearcherSubmission() {
  const { setLoading, loading, setCircularLoading } = useContext(AppContext);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [DelId, setDelId] = useState();
  const uid = localStorage.getItem("userid");
  const API = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const { GetRequest, DeleteRequest } = useHttp();
  const [submissionData, setSubmissionData] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [userDetails, setUserDetails] = useState();
  const [programDetails, setProgramDetails] = useState();
  const [analytics, setAnalytics] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { invokeApi } = HTTPService();

  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();

  const fetchUserDetails = async () => {
    const { data, error } = await GetRequest(
      API + "researcher/",
      {},
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      console.log(error);
    }
    if (data) {
      setUserDetails(data);
    }
  };
  const fetchProgramDetails = async () => {
    const { data, error } = await GetRequest(
      API + "programs/",
      {},
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      console.log(error);
    }
    if (data) {
      setProgramDetails(data);
    }
  };

  const fetchSubmissionData = async () => {
    setLoading(true);
    let url = `${API}submissions/?page=${currentPage}&page_size=25`;
    if (searchQuery && searchQuery.length > 0) {
      url = url + `&${searchValue}=${searchQuery}`;
    }
    if (filterType && filterValue && filterValue.length > 0) {
      url = url + `&${filterType}=${filterValue}`;
    }
    const { data, error } = await GetRequest(
      url,
      {
        user: uid,
      },
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      console.log(error);
    }
    if (data) {
      setLoading(false);
      setSubmissionData(data);
      setTotalPages(data.total_pages);
      fetchUserDetails();
      fetchProgramDetails();
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    setOpenAddModal(false);
    const { error } = await DeleteRequest(
      API + "submissions/" + id + "/",

      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      customToast.error("Something went worng!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setLoading(false);
      fetchSubmissionData();
      customToast.success("Deleted Successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const fetchAnalytics = async () => {
    invokeApi({
      method: "GET",
      url: `submissions/status-count/`,
    })
      .then((res) => {
        setAnalytics(res);
      })
      .catch((error) => { });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const timeZone = "IST";

    return (
      <>
        <span>
          {`${year}-${month}-${day}`}
          <div>{` ${hours}:${minutes}:${seconds} ${timeZone}`}</div>
        </span>
      </>
    );
  };

  const Breadcrumbname = {
    home: "/researcher/home",
    Management: "",
    Submissions: "",
    Dashboard: "",
  };
  useEffect(() => {
    fetchSubmissionData();
    fetchAnalytics();
    // fetchCustomerDetails();
  }, []);

  useEffect(() => {
    fetchSubmissionData();
  }, [searchQuery, currentPage, filterValue]);

  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              <Link
                to="/researcher/submissions/new"
                className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                id="adduser"
              >
                + New Submission
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          {loading ? (
            <CountSkeletonLoader showState={2} />
          ) : (
            <div className="col-lg-12 col-12">
              <div className="row d-flex">
                <div style={{ flex: 1 }} className=" mt-4">
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics?.total_submissions_status?.new ?? 0}
                          </h5>
                          <span className="cardHeader text-white text-sm">
                            New
                          </span>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics?.today_submissions_status_count?.new ??
                              0}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1 }} className=" mt-4">
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics?.total_submissions_status?.triaged ?? 0}
                          </h5>
                          <span className="cardHeader text-white text-sm">
                            Triaged
                          </span>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics?.today_submissions_status_count
                              ?.triaged ?? 0}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1 }} className=" mt-4">
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics?.total_submissions_status?.review ?? 0}
                          </h5>
                          <span className="cardHeader text-white text-sm">
                            Review
                          </span>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics?.today_submissions_status_count
                              ?.review ?? 0}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1 }} className=" mt-4 ">
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics?.total_submissions_status?.unresolved ??
                              0}
                          </h5>
                          <span className="cardHeader text-white text-sm">
                            Unresolved
                          </span>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics?.today_submissions_status_count
                              ?.unresolved ?? 0}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1 }} className=" mt-4">
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics?.total_submissions_status?.resolved ?? 0}
                          </h5>
                          <span className="cardHeader text-white text-sm">
                            Resolved
                          </span>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics?.today_submissions_status_count
                              ?.resolved ?? 0}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1 }} className=" mt-4">
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics?.total_submissions_status?.rejected ?? 0}
                          </h5>
                          <span className="cardHeader text-white text-sm">
                            Rejected
                          </span>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics?.today_submissions_status_count
                              ?.rejected ?? 0}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row my-4">
          <div className="col-12">
            <CommonTable
              column={tableColumn}
              data={submissionData && submissionData.results}
              totalPages={totalPages ?? 1}
              filterList={filterColumn}
              filterType={filterType}
              setFilterType={setFilterType}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              searchBar={true}
              itemPerPg={25}
              searchBy={searchColumn && searchColumn}
              setTable={1}
              card={7}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalData={submissionData?.total_records}

            >
              {submissionData &&
                submissionData.results &&
                submissionData.results.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        to={`/researcher/submissions/view/${item.id}`}
                        className="gotoView  mb-0 px-2"
                      >
                        {item.submissions_id}
                      </Link>
                    </td>
                    <td>
                      <div style={{ width: "85px" }}>
                        <p
                          className="  mb-0 px-2"
                          style={{
                            color: "#272727",
                            fontSize: "13px",
                          }}
                        >
                          {formatDate(item.created_at)}
                        </p>
                      </div>
                    </td>
                    <td className="text-start">
                      <div style={{ width: "200px" }} className="text-wrap">
                        <p
                          style={{
                            color: "#3F48CC",
                            fontWeight: "600",
                            marginBottom: "5px",
                            // textWrap: "nowrap",
                            fontSize: "14px",
                          }}
                        >
                          {/* {item.submission_title} */}
                          {lineOverFlowDot(item.submission_title, 40)}
                        </p>
                        {programDetails &&
                          programDetails.results.map((prgmItem, index) =>
                            prgmItem.id === item.program_id ? (
                              <p
                                key={index}
                                style={{
                                  color: "#000",
                                  fontWeight: "600",
                                  marginBottom: "5px",
                                  // textWrap: "nowrap",
                                  fontSize: "14px",
                                }}
                              >
                                {lineOverFlowDot(prgmItem.program_title, 30)}
                              </p>
                            ) : (
                              ""
                            )
                          )}

                        <p className="text-secondary text-xs">
                          {lineOverFlowDot(item.detail_description, 50)}
                        </p>
                      </div>
                    </td>
                    <td>
                      <p className="text-sm  mb-0 metricStatElement">
                        <div className="row d-flex flex-column align-items-center">
                          {item.priority && (
                            <div className=" d-flex align-items-center">
                              <p className="col-7 mb-0  text-start">Priority</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                {item.priority}
                              </span>
                            </div>
                          )}

                          {item.severity && (
                            <div className="d-flex align-items-center">
                              <p className="col-7 mb-0  text-start">Severity</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                {item.severity}
                              </span>
                            </div>
                          )}

                          {item.vtx && (
                            <div className="d-flex align-items-center">
                              <p className="col-7 mb-0  text-start">VTx</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                {item.vtx}
                              </span>
                            </div>
                          )}

                          {item.cvss_score && (
                            <div className="d-flex align-items-center">
                              <p className="col-7 mb-0  text-start">CVSS</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                {item.cvss_score}
                              </span>
                            </div>
                          )}
                        </div>
                      </p>
                    </td>

                    <td className="  text-sm">
                      {item.transaction_details ? (
                        <p className="text-sm  mb-0 metricStatElement">
                          <div className="row d-flex flex-column align-items-center">
                            <div className=" d-flex align-items-center">
                              <p className="col-5 mb-0  text-start">Amount</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                ${item?.transaction_details?.amount}
                              </span>
                            </div>

                            <div className="d-flex align-items-center">
                              <p className="col-5 mb-0  text-start">Fees</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                ${item?.transaction_details?.processing_fees}
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <p className="col-5 mb-0  text-start">Txn ID</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                {item?.transaction_details?.transactions_id}
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <p className="col-5 mb-0  text-start">Date</p>
                              <span>:</span>
                              <span
                                style={{
                                  marginLeft: "4px",
                                  textWrap: "wrap",
                                  textAlign: "left",
                                }}
                              >
                                {formatDate(
                                  item?.transaction_details?.created_at,
                                  1
                                )}
                              </span>
                            </div>
                          </div>
                        </p>
                      ) : (
                        "No Transaction"
                      )}
                    </td>
                    <td className="align-middle text-center text-uppercase">
                      {item.submission_status === "resolved" &&
                        item.transaction_details?.status != "success" && (
                          <div className={`UnPaidBtn cursor-pointer`}>
                            <span>Unpaid</span>
                          </div>
                        )}
                      {item.submission_status === "resolved" &&
                        item.transaction_details?.status == "success" && (
                          <div className={`statusAccepted cursor-pointer`}>
                            <span>Paid</span>
                          </div>
                        )}
                      {item.submission_status !== "resolved" &&
                        item.transaction_details?.status !== "success" && (
                          <div className={` cursor-pointer`}>
                            <span>-</span>
                          </div>
                        )}
                    </td>
                    <td className="align-middle text-center">
                      <div
                        className=""
                        style={{ color: "rgb(101, 98, 114)", fontSize: "12px" }}
                      >
                        {item.assignee ? (
                          <>
                            {" "}
                            <p style={{ fontSize: "12px", margin: 0 }}>
                              {item?.assign_details?.user_id}
                            </p>
                            {item?.assign_details?.first_name}{" "}
                            {item?.assign_details?.last_name}{" "}
                          </>
                        ) : (
                          <>No-Assignee</>
                        )}
                      </div>
                    </td>
                    <td className="align-middle text-center text-uppercase">
                      {/* {item.submission_status === "new" ? (
																		<div className="statusNew cursor-pointer" >
																			<span>{item.submission_status}</span>
																		</div>
																	) : item.submission_status === "triaged" ? (
																		<div className="statusTriaged">
																			<span>{item.submission_status}</span>
																		</div>
																	) : item.submission_status === "resolved" ? (
																		<div className="statusAccepted">
																			<span>{item.submission_status}</span>
																		</div>
																	) : item.submission_status ===
																	  "unresolved " ? (
																		<div className="statusRejected">
																			<span>{item.submission_status}</span>
																		</div>
																	) : item.submission_status === "Review" ? (
																		<div className="statusAccepted">
																			<span>{item.submission_status}</span>
																		</div>
																	) : (
																		"null"
																	)} */}

                      {submissionStatusData &&
                        submissionStatusData.map(
                          (sts, index) =>
                            item.submission_status === sts.status && (
                              <div
                                key={index}
                                className={`${sts.classname} cursor-pointer`}
                              // onClick={() => handleStatus(item.id)}
                              >
                                <span>{item.submission_status}</span>
                              </div>
                            )
                        )}
                    </td>
                    <td>
                      <div className="d-flex">
                        <Link
                          to={`/researcher/submissions/view/${item.id}`}
                          className="btn btn-sm px-3 mt-2"
                        >
                          &#128065;
                        </Link>
                        <button
                          type="button"
                          className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v text-xs"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/researcher/programs/${item.program_id}/submission`}
                            >
                              View comment
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/researcher/submissions/edit/${item.id}`}
                            >
                              Edit
                            </Link>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                setOpenAddModal(true);

                                setDelId(item.id);
                              }}
                            >
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
            </CommonTable>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"DELETE"}
        createAddTopic={"Delete Submission"}
        confirmText={"Delete The Submission"}
        isOpen={openAddModal}
        onSubmit={() => handleDelete(DelId)}
        onClose={() => setOpenAddModal(false)}
      />
    </>
  );
}
