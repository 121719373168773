import React, { useContext, useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Dashboard from "../../../components/Header-Components/Dashboard";
import ManageModule from "../../../components/Header-Components/ManageModule";
import Management from "../../../components/Header-Components/Management";
import MasterModule from "../../../components/Header-Components/MasterModule";
import Payments from "../../../components/Header-Components/Payments";
import Integrations from "../../../components/Header-Components/Integrations";
import Reports from "../../../components/Header-Components/Reports";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";

import "./FaqCategoryStyles.css";

import useHttp from "../../../Hooks/useHttp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddModal from "../../../common/modal/AddModal";
import AppContext from "../../../Context/AppContext";
import { customToast } from "../../../../App";

export default function AddFaqCategory() {
	const { setCircularLoading } = useContext(AppContext);
	const [openAddModal, setOpenAddModal] = useState(false);

	const navigate = useNavigate();
	const [mainCatName, setMainCatName] = useState("");
	const [categoryCode, setCategoryCode] = useState("");
	const [sequence, setSequence] = useState();
	const [type, setType] = useState("");
	const [selectOptType, setSelectOptType] = useState();

	const { PostRequest, GetRequest } = useHttp();
	const token = localStorage.getItem("token");
	const API = process.env.REACT_APP_API_URL;
	const handleSubmit = async () => {
		setOpenAddModal(false);
		setCircularLoading(true);
		const param = {
			category_title: mainCatName,
			category_code: categoryCode,
			sequence: sequence,
			user_type: type,
			category_type: "main_category",
			cat_status: "active",
			user_id: "test_user_id_1",
			is_deleted: false,
			parent_category_id: "",
		};
		const { data, error } = await PostRequest(API + "faq_category/", param, {
			Authorization: "Bearer  " + token,
			"Content-Type": "multipart/form-data",
		});
		if (error) {
			setCircularLoading(false);
			//alert("Something went wrong please check input");
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		}
		// console.log(error);
		if (data) {
			setCircularLoading(false);
			setMainCatName("");
			setSequence();
			setType("");
			setCategoryCode("");
			navigate("/admin/faq-category");
			customToast.success("Category Added successfully", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		}
	};
	const fetchValueOpt = async () => {
		const { data, error } = await GetRequest(
			API + "basevalues/?title_code=user_type",
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		// console.log(data, error);
		if (error) {
			// alert("Something went wrong");
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {
			setSelectOptType(data);
		}
	};
	useEffect(() => {
		fetchValueOpt();
	}, []);
	console.log(selectOptType);
	const Breadcrumbname = {
		home: "/admin/home",
		"Master Module": "",
		"FAQ Category": "/admin/faq-category",
		"Add New Main Category": "/admin/faq-category/add",
	};

	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row w-100 d-flex justify-content-center">
					<div className="addCategoryArea  my-4">
						<div className="d-flex justify-content-between">
							<div className="">
								<Breadcrumb names={Breadcrumbname} />
							</div>
							<div className=" d-flex justify-content-end mt-0 mb-3">
								<Link
									to="/admin/faq-category"
									className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
									id="adduser"
								>
									VIEW ALL
								</Link>
							</div>
						</div>
						<div className="col-12">
							<div className="card">
								<div className="row">
									<div className="card mt-0" id="basic-info">
										<div className="card-header">
											<h5>ADD FAQ MAIN CATEGORY</h5>
										</div>
										<div className="card-body pt-0">
											<div className="row">
												<div className="col-6">
													<label className="form-label ">Type</label>

													<select
														className="form-select"
														name="choices-gender"
														id="choices-gender"
														value={type}
														onChange={(e) => setType(e.target.value)}
													>
														<option value="" selected>
															Select Q & A Type
														</option>

														{selectOptType &&
															selectOptType?.results[0].value_options
																?.split(",")
																.map((item, index) => {
																	console.log(item);
																	return <option key={index} value={item}>{item}</option>;
																})}
													</select>
												</div>
											</div>

											<div className="row">
												<div className="col-sm-6">
													<label className="form-label mt-4">
														Main Category
													</label>
													<div className="input-group ">
														<input
															className="form-control"
															placeholder="Enter Main Category"
															type="text"
															value={mainCatName}
															onChange={(e) => setMainCatName(e.target.value)}
														/>
													</div>
												</div>
												<div className="col-6">
													<label className="form-label mt-4">
														Category Code
													</label>

													<div className="input-group">
														<input
															id="firstName"
															name="firstName"
															className="form-control"
															type="text"
															placeholder="Enter Category Code"
															required="required"
															value={categoryCode}
															onChange={(e) => setCategoryCode(e.target.value)}
														/>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-6">
													<label className="form-label mt-4">Sequence</label>
													<div className="input-group ">
														<input
															className="form-control"
															placeholder="Enter Sequence"
															type="number"
															value={sequence}
															onChange={(e) => setSequence(e.target.value)}
														/>
													</div>
												</div>
											</div>
											<div className=" d-flex justify-content-end">
												<Link
													to="/admin/faq-category"
													className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
												>
													Cancel
												</Link>
												<button
													onClick={() => setOpenAddModal(true)}
													className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
												>
													Add Category
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AddModal
				cancelBtn={"Cancel"}
				submitBtn={"SUBMIT"}
				createAddTopic={"Add Faq Category"}
				confirmText={"Add The Faq Category"}
				isOpen={openAddModal}
				onSubmit={handleSubmit}
				onClose={() => setOpenAddModal(false)}
			/>
		</>
	);
}
