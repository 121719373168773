import React, { useState } from "react";
import { Link } from "react-router-dom";

const Accordion = ({ title, dropdowntitle }) => {
  const [isActive, setIsActive] = useState(false);
 

  return (
    <div className="accordion-item">
      <div
        className="accordion-title productElementItem d-flex justify-content-between cursor-pointer"
        onClick={() => setIsActive(!isActive)}
      >
        <Link to="" className="accordion-item">
          {title && title}
        </Link>
        {dropdowntitle && dropdowntitle ? (
          <div className="arrows cursor-pointer">{isActive ? "-" : "+"}</div>
        ) : (
          ""
        )}
      </div>
      {isActive && (
        <div className="accordion-content">
          <ul className="accordion-dropdown-item mt-1 ">
            {dropdowntitle && dropdowntitle}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Accordion;
