import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ViewInfo from '../../../common/ViewInfo';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import useHttp from '../../../Hooks/useHttp';
import { customToast } from '../../../../App';

export default function ViewFaqa() {

	const { GetRequest } = useHttp();
	const [faqaData, setFaqaData] = useState({});

	const API = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem("token");

	const navigate = useNavigate();

	const { id } = useParams();

	console.log("Faqa id " + id);

	const fetchFaqaData = async () => {
		const { data, error } = await GetRequest(
			API + "faq_questionanswer/" + id + "/",
			{},
			{
				Authorization: `Bearer ${token}`,
			}
		);
		// console.log(data, error);
		if (error) {
			//alert("Something went wrong");
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
			console.log(error);
		}
		if (data) {
			setFaqaData(data);
			console.log("setFaqaData-- ", data);
		}
	};


	useEffect(() => {
		fetchFaqaData();
	}, [id]);

	const Title = "View FAQ Q&A"
	const option = ["ID", "Sequence", "Question", "Answer"];
	const FaqaValue = [faqaData.faq_qa_id, faqaData.sequence, faqaData.question, faqaData.answer]


	const Breadcrumbname = {
		home: "/admin/home",
		MasterModule: "",
		Faqa: "/admin/master-module",
		"ViewFaqa": "",
	};



	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row w-100 d-flex justify-content-center">
					<div className="addCategoryArea  my-4">
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div className="col-lg-6 ps-3">
								<Breadcrumb names={Breadcrumbname} />
							</div>

							<div className="button-row d-flex justify-content-end mt-0 mb-3">
								<Link
									to="/admin/faq/dashboard"
									className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
									id="adduser"
								>
									VIEW ALL
								</Link>
							</div>
						</div>


						<div className="col-12">
							<div className="card" style={{ backgroundColor: "white" }}>
								<ViewInfo itemValue={FaqaValue} option={option} title={Title} />


								<div className=" d-flex justify-content-end p-4">
									<button className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3" onClick={() => navigate(-1)}>
										Back
									</button>
									<button
										type="button"
										className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
										onClick={() => navigate(`/admin/faq/edit/${id}`)}
									>
										Edit
									</button>
								</div>
							</div>
						</div>





					</div>
				</div>
			</div>
		</>
	)
}
