import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function Backbutton() {
    const navigate = useNavigate();
  return (
    <>
      <div className="button-row d-flex justify-content-end mt-0 mb-3">
        <Link
          className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
          onClick={() => navigate(-1)}
        >
            Back
        </Link>
      </div>
    </>
  );
}
