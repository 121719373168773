import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import extImg from "./components/images/ExternalAttack-1.png"
import extEsam from "./components/images/ESAM.png"

export default function ExternalAttackSurface() {
	return (
		<>
			<Header />
			<div class="uui-page-padding-3">
				<header class="uui-section_heroheader13-3">
					<div class="uui-page-padding-9">
						<header class="uui-section_heroheader05">
							<div class="uui-page-padding-17">
								<div class="uui-container-large-14">
									<div class="uui-padding-vertical-xhuge-15">
										<div class="w-layout-grid uui-heroheader05_component">
											<div class="uui-heroheader05_content">
												<div class="uui-space-small-11"></div>
												<h1 class="uui-heading-xlarge-9">
													External Attack Surface Management
												</h1>
												<div class="uui-space-small-11"></div>
												<div class="uui-text-size-xlarge-9">
													Beyond the Horizon: Managing
													<strong class="bold-text-5">External Risks</strong>
													with <strong class="bold-text-4">Precision</strong>.
												</div>
												<div class="uui-space-large-8"></div>
											</div>
											<img
												src={extImg}
												loading="lazy"
												id="w-node-eaad19bd-ed2c-6afb-d93b-b56ad5435669-f0f94205"
												sizes="(max-width: 479px) 100vw, (max-width: 767px) 87vw, (max-width: 991px) 81vw, 32vw"
												alt="Illustration depicting External Attack Surface"
											/>
										</div>
									</div>
								</div>
							</div>
						</header>
						<div class="uui-container-large-8"></div>
					</div>
				</header>
				<header class="uui-section_heroheader01">
					<div class="uui-page-padding-3">
						<div class="uui-container-large-3"></div>
					</div>
				</header>
				<div class="uui-container-large-3"></div>
				<section class="uui-section_layout01">
					<div class="uui-page-padding-5">
						<div class="uui-container-large-5"></div>
					</div>
				</section>
				<section class="uui-section_layout01-2">
					<div class="uui-page-padding-17">
						<div class="uui-container-large-14">
							<div class="uui-padding-vertical-xhuge-15">
								<img
									src={extEsam}
									loading="lazy"
									sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, 87vw"
									alt=""
								/>
								<div class="w-layout-grid uui-layout01_component-2">
									<div
										id="w-node-_6ab544a1-8a96-4bea-30f3-44f35274750b-f0f94205"
										class="uui-layout01_content-2"
									>
										<h2 class="uui-heading-medium-9">
											Streamlining External Attack Surface Management
										</h2>
										<div class="uui-space-xsmall-8"></div>
										<div class="uui-text-size-large-8">
											Our External Attack Surface Management (EASM) service
											helps organizations identify, assess, and mitigate
											vulnerabilities in external-facing systems, networks, and
											assets. It analyzes the organization&#x27;s digital
											footprint, including public websites, APIs, cloud
											infrastructure, and third-party integrations, to reduce
											the risk of external attacks. EASM focuses on identifying,
											monitoring, and reducing the digital attack surface
											exposed to external threats, ensuring a robust security
											posture.
										</div>
										<div class="uui-space-medium-6"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
}
