import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import "./SubmissionDashboard.css";
import { toast } from "react-toastify";
import submissionStatusData from "./submissionStatusData.json";
import AddModal from "../../common/modal/AddModal";
import {
  flattenedObject,
  lineOverFlowDot,
  formatDate,
} from "../../../utils/helperFunctions";
import RewardModal from "../../common/modal/RewardModal";
import CommonTable from "../../common/Table/CommonTable";
import AppContext from "../../Context/AppContext";
import CountSkeletonLoader from "../../components/SkeletonLoader/CountSkeletonLoader";
import HTTPService from "../../../utils/axios";
import { getUserInfo } from "../../../reducers/authSlice";
import { useSelector } from "react-redux";
import withPermission from "../../../hoc/withPermission";
import { Stack } from "@mui/material";
import { customToast } from "../../../App";

const tableColumn = [
  "Submission Id",
  "Date",
  "Title",
  "Metrics Stats",
  { label: "Researcher Details", align: "center" },
  { label: "Payment Details", align: "center" },
  { label: "Payment Status", align: "center" },
  { label: "Processed By", align: "center" },
  "Tag",
  "Status",
  "Action",
];

const searchColumn = [
  { name: "Submission Id", value: "submissions_id" },
  // { name: "Date", value: "created_at" },
  { name: "Title", value: "submission_title" },
  // { name: "Priority", value: "priority" },
  // { name: "Severity", value: "severity" },
  { name: "VTx", value: "vtx" },
  // { name: "Submission Status", value: "submission_status" },
];

const filterColumn = [
  {
    name: "Priority",
    value: "priority",
    filters: ["P1", "P2", "P3", "P4", "P5"],
  },
  {
    name: "Severity",
    value: "severity",
    filters: ["Informational", "Low", "Moderate", "Critical", "Severe"],
  },
  {
    name: "Submission Status",
    value: "submission_status",
    filters: ["new", "triaged", "review", "resolved", "unresolved", "rejected"],
  },
];

const Breadcrumbname = {
  home: "/admin/home",
  Management: "",
  Submissions: "",
  Dashboard: "",
};
function SubmissionDashboard() {
  const { userInfo, permissions } = useSelector(getUserInfo);
  const permission = permissions.find((perm) => perm.module === "Submissions");
  const { setLoading, loading, setCircularLoading } = useContext(AppContext);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [DelId, setDelId] = useState();
  const [paymentModal, setPaymentModal] = useState(false);
  const [submissionData, setSubmissionData] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [programDetails, setProgramDetails] = useState();
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusVal, setChangeStatusVal] = useState();
  const [selectedSubmission, setSelectedSubmission] = useState();
  const [isViewMode, setIsViewMode] = useState(false);
  const [isPayMode, setIsPayMode] = useState(false);
  const [analytics, setAnalytics] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { invokeApi } = HTTPService();
  const [subAdmins, setSubAdmins] = useState();
  const [assignee, setAssignee] = useState("");
  const [openAssignee, setOpenAssignee] = useState(false);

  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();

  const handleStatus = async (data) => {
    // fetchStatus(id);
    setChangeStatusModal(true);
    setChangeStatusVal(data.submission_status);
    setSelectedSubmission(data);
  };

  const fetchSubmissionData = async (noLoading) => {
    if (!noLoading) setLoading(true);
    let url = `submissions/?page=${currentPage}&page_size=25`;
    if (searchQuery && searchQuery.length > 0) {
      url = url + `&${searchValue}=${searchQuery}`;
    }
    if (filterType && filterValue && filterValue.length > 0) {
      url = url + `&${filterType}=${filterValue}`;
    }
    invokeApi({
      method: "GET",
      url,
    })
      .then((res) => {
        setLoading(false);
        setSubmissionData(res);
        setTotalPages(res.total_pages);
        // fetchUserDetails();
        fetchProgramDetails();
      })
      .catch((error) => {
        setLoading(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const setStatus = async () => {
    setChangeStatusModal(false);
    setCircularLoading(true);
    const param = {
      // detail_description: particularSubmission.detail_description,
      // program_id: particularSubmission.program_id,
      // user_id: particularSubmission.user_id,
      // submission_title: particularSubmission.submission_title,
      // id: id,
      submission_status: changeStatusVal.toLowerCase(),
    };
    invokeApi({
      method: "PATCH",
      url: `submissions/${selectedSubmission.id}/`,
      data: param,
    })
      .then((res) => {
        fetchSubmissionData(true);
        customToast.success("status updated", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setCircularLoading(false);
      })
      .catch((error) => {
        setCircularLoading(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  // const fetchUserDetails = async () => {
  // 	invokeApi({
  // 		method: "GET",
  // 		url: `researcher/`,
  // 	}).then((res) => {
  // 		setUserDetails(res);
  // 	}).catch((error) => {
  // 		customToast.error("Something went wrong!", {
  // 			position: toast.POSITION.TOP_RIGHT,
  // 			autoClose: 1000,
  // 		});
  // 	});
  // };
  const fetchProgramDetails = async () => {
    invokeApi({
      method: "GET",
      url: `programs/`,
    })
      .then((res) => {
        setProgramDetails(res);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const fetchAnalytics = async () => {
    invokeApi({
      method: "GET",
      url: `submissions/status-count/`,
    })
      .then((res) => {
        setAnalytics(res);
      })
      .catch((error) => { });
  };

  const handleDelete = async (id) => {
    setLoading(true);
    setOpenAddModal(false);
    invokeApi({
      method: "DELETE",
      url: `submissions/${id}/`,
    })
      .then((res) => {
        fetchSubmissionData();
        customToast.success("Deleted Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const fetchSubAdmins = () => {
    invokeApi({
      method: "GET",
      url: `sub_admin/`,
    })
      .then((response) => {
        setSubAdmins(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const changeAssigne = () => {
    invokeApi({
      method: "PATCH",
      url: `submissions/${selectedSubmission.id}/`,
      data: {
        assignee: assignee.id,
      },
    })
      .then((response) => {
        customToast.success("Action performed successfully.");
        setSubmissionData((prev) => {
          let data = { ...prev };
          data.results = data.results.map((el) => {
            if (el.id === response.id) {
              return response;
            }
            return el;
          });
          return data;
        });
        // setStatus(response);
        setOpenAssignee(false);
        setSelectedSubmission();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchSubmissionData();
    fetchAnalytics();
    fetchSubAdmins();
    // fetchCustomerDetails();
  }, []);

  useEffect(() => {
    // if (searchQuery && searchQuery.length > 0) {
    fetchSubmissionData(true);
    // }
  }, [searchQuery, currentPage, filterValue]);

  useEffect(() => {
    if (selectedSubmission) {
      let user = subAdmins?.results?.find(
        (el) => el.id === selectedSubmission.assignee
      );
      setAssignee(user);
    }
  }, [selectedSubmission]);
  return (
    <>
      {/* {loading && <CircularLoader size={100} />} */}
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              {(permission?.create || userInfo.role === "admin") && (
                <Link
                  to="/admin/submissions/new"
                  className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                  id="adduser"
                >
                  + New Submission
                </Link>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          {loading ? (
            <CountSkeletonLoader showState={2} />
          ) : (
            <div className="col-lg-12 col-12">
              <div className="row d-flex">
                <div className=" mt-4" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <Stack>
                            <h5 className="text-white font-weight-bolder mb-0 mt-3">
                              {analytics?.total_submissions_status?.new ?? 0}
                            </h5>
                            <span className="cardHeader text-white text-sm">
                              New
                            </span>
                          </Stack>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <Stack>
                            <h5 className="text-end text-white pt-3 mb-0 mt-2">
                              {analytics?.today_submissions_status_count?.new ??
                                0}
                            </h5>
                            <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                              Today's
                            </p>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <Stack>
                            <h5 className="text-white font-weight-bolder mb-0 mt-3">
                              {analytics?.total_submissions_status?.triaged ??
                                0}
                            </h5>
                            <span className="cardHeader text-white text-sm">
                              Triaged
                            </span>
                          </Stack>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <Stack>
                            <h5 className="text-end text-white pt-3 mb-0 mt-2">
                              {analytics?.today_submissions_status_count
                                ?.triaged ?? 0}
                            </h5>
                            <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                              Today's
                            </p>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <Stack>
                            <h5 className="text-white font-weight-bolder mb-0 mt-3">
                              {analytics?.total_submissions_status?.review ?? 0}
                            </h5>
                            <span className="cardHeader text-white text-sm">
                              Review
                            </span>
                          </Stack>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <Stack>
                            <h5 className="text-end text-white pt-3 mb-0 mt-2">
                              {analytics?.today_submissions_status_count
                                ?.review ?? 0}
                            </h5>
                            <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                              Today's
                            </p>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4 " style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <Stack>
                            <h5 className="text-white font-weight-bolder mb-0 mt-3">
                              {analytics?.total_submissions_status
                                ?.unresolved ?? 0}
                            </h5>
                            <span className="cardHeader text-white text-sm">
                              Unresolved
                            </span>
                          </Stack>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <Stack>
                            <h5 className="text-end text-white pt-3 mb-0 mt-2">
                              {analytics?.today_submissions_status_count
                                ?.unresolved ?? 0}
                            </h5>
                            <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                              Today's
                            </p>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <Stack>
                            <h5 className="text-white font-weight-bolder mb-0 mt-3">
                              {analytics?.total_submissions_status?.resolved ??
                                0}
                            </h5>
                            <span className="cardHeader text-white text-sm">
                              Resolved
                            </span>
                          </Stack>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <Stack>
                            <h5 className="text-end text-white pt-3 mb-0 mt-2">
                              {analytics?.today_submissions_status_count
                                ?.resolved ?? 0}
                            </h5>
                            <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                              Today's
                            </p>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <Stack>
                            <h5 className="text-white font-weight-bolder mb-0 mt-3">
                              {analytics?.total_submissions_status?.rejected ??
                                0}
                            </h5>
                            <span className="cardHeader text-white text-sm">
                              Rejected
                            </span>
                          </Stack>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <Stack>
                            <h5 className="text-end text-white pt-3 mb-0 mt-2">
                              {analytics?.today_submissions_status_count
                                ?.rejected ?? 0}
                            </h5>
                            <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                              Today's
                            </p>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row my-4">
          {/* {!loading && <CommonTableSkeletonLoader card={8}/>} */}
          <div className="col-12">
            <CommonTable
              column={tableColumn}
              data={submissionData?.results}
              totalPages={totalPages ?? 1}
              totalData={submissionData?.total_records}
              filterList={filterColumn}
              filterType={filterType}
              setFilterType={setFilterType}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              searchBar={true}
              itemPerPg={25}
              searchBy={searchColumn && searchColumn}
              setTable={1}
              card={7}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            >
              {submissionData?.results &&
                submissionData?.results?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        to={`/admin/submissions/view/${item.id}`}
                        className="  mb-0 px-2 gotoView"
                        style={{ fontSize: "12px" }}
                      >
                        {item.submissions_id}
                      </Link>
                    </td>
                    <td>
                      <div style={{ width: "85px" }}>
                        <p
                          className="  mb-0 px-2"
                          style={{
                            color: "#272727",
                            fontSize: "13px",
                          }}
                        >
                          {formatDate(item.created_at, 2)}
                        </p>
                      </div>
                    </td>
                    <td className="text-start">
                      <div style={{ width: "200px" }} className="text-wrap">
                        <p
                          style={{
                            color: "#3F48CC",
                            fontWeight: "600",
                            marginBottom: "5px",
                            // textWrap: "nowrap",
                            fontSize: "14px",
                          }}
                        >
                          {/* {item.submission_title} */}
                          {lineOverFlowDot(item.submission_title, 40)}
                        </p>
                        {programDetails &&
                          programDetails.results.map((prgmItem, index) =>
                            prgmItem.id === item.program_id ? (
                              <p
                                key={index}
                                style={{
                                  color: "#000",
                                  fontWeight: "600",
                                  marginBottom: "5px",
                                  // textWrap: "nowrap",
                                  fontSize: "14px",
                                }}
                              >
                                {lineOverFlowDot(prgmItem.program_title, 30)}
                              </p>
                            ) : (
                              ""
                            )
                          )}

                        <p className="text-secondary text-xs">
                          {lineOverFlowDot(item.detail_description, 50)}
                        </p>
                      </div>
                    </td>
                    <td>
                      <p className="text-sm  mb-0 metricStatElement">
                        <div className="row d-flex flex-column align-items-center">
                          {item.priority && (
                            <div className=" d-flex align-items-center">
                              <p className="col-7 mb-0  text-start">Priority</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                {item.priority}
                              </span>
                            </div>
                          )}

                          {item.severity && (
                            <div className="d-flex align-items-center">
                              <p className="col-7 mb-0  text-start">Severity</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                {item.severity}
                              </span>
                            </div>
                          )}

                          {item.vtx && (
                            <div className="d-flex align-items-center">
                              <p className="col-7 mb-0  text-start">VTx</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                {item.vtx}
                              </span>
                            </div>
                          )}

                          {item.cvss_score && (
                            <div className="d-flex align-items-center">
                              <p className="col-7 mb-0  text-start">CVSS</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                {item.cvss_score}
                              </span>
                            </div>
                          )}
                        </div>
                      </p>
                    </td>
                    <td className="text-center">
                      <div className="d-flex justify-content-center">
                        {/* <div className="rescuerLogo me-2 ms-2">
																			<img src={rescuerLogo} alt="" />
																		</div> */}
                        {item.user_details ? (
                          <div className="d-flex flex-column align-items-start">
                            <p className="mb-0" style={{ fontSize: "12px" }}>
                              {item.user_details.user_id}
                            </p>
                            <p
                              className="mb-0"
                              style={{
                                color: "#656272",
                                fontSize: "12px",
                              }}
                            >
                              {item.user_details.first_name}{" "}
                              {item.user_details.last_name}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td className=" text-center text-sm">
                      {item.transaction_details ? (
                        <p className="text-sm  mb-0 metricStatElement">
                          <div className="row d-flex flex-column align-items-center">
                            <div className=" d-flex align-items-center">
                              <p className="col-5 mb-0  text-start">Amount</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                ${item?.transaction_details?.amount}
                              </span>
                            </div>

                            <div className="d-flex align-items-center">
                              <p className="col-5 mb-0  text-start">Fees</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                ${item?.transaction_details?.processing_fees}
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <p className="col-5 mb-0  text-start">Txn ID</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                {item?.transaction_details?.transactions_id}
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <p className="col-5 mb-0  text-start">Date</p>
                              <span>:</span>
                              <span
                                style={{
                                  marginLeft: "4px",
                                  textWrap: "wrap",
                                  textAlign: "left",
                                }}
                              >
                                {formatDate(
                                  item?.transaction_details?.created_at,
                                  1
                                )}
                              </span>
                            </div>
                          </div>
                        </p>
                      ) : (
                        "No Transaction"
                      )}
                    </td>
                    <td className="align-middle text-center text-uppercase">
                      {/* <div className={`PaidBtn cursor-pointer`}	>
																						<span>
																							Paid</span>
																					</div> */}

                      {item.submission_status === "resolved" &&
                        item.transaction_details?.status != "success" && (
                          <div className={`UnPaidBtn cursor-pointer`}>
                            <span>Unpaid</span>
                          </div>
                        )}
                      {item.submission_status === "resolved" &&
                        item.transaction_details?.status == "success" && (
                          <div className={`statusAccepted cursor-pointer`}>
                            <span>Paid</span>
                          </div>
                        )}
                      {item.submission_status !== "resolved" &&
                        item.transaction_details?.status !== "success" && (
                          <div className={` cursor-pointer`}>
                            <span>-</span>
                          </div>
                        )}
                    </td>
                    <td className="align-middle text-center">
                      <div
                        className=""
                        style={{ color: "rgb(101, 98, 114)", fontSize: "12px" }}
                      >
                        {item.assignee ? (
                          <>
                            {" "}
                            <p style={{ fontSize: "12px", margin: 0 }}>
                              {item?.assign_details?.user_id}
                            </p>
                            {item?.assign_details?.first_name}{" "}
                            {item?.assign_details?.last_name}{" "}
                          </>
                        ) : (
                          <>No-Assignee</>
                        )}

                        {["admin", "sub_admin"].includes(userInfo?.role) && (
                          <>
                            <br />
                            <button
                              style={{
                                color: "#fff",
                                borderRadius: "4px",
                                boxShadow: "0 0 3px 1px #ccc",
                                border: "none",
                                padding: "5px 10px",
                                background: "#7f56d9",
                              }}
                              onClick={() => {
                                setSelectedSubmission(item);
                                setOpenAssignee(true);
                              }}
                            >
                              Change Assignee
                            </button>
                          </>
                        )}
                      </div>
                    </td>
                    <td >
                      <div style={{ fontSize: '12px', whiteSpace: 'pre-wrap', width: '100px' }}>
                        {item.tags && item.tags.split(',').join(', ')}
                      </div>
                    </td>
                    <td className="align-middle text-center text-uppercase">
                      {submissionStatusData &&
                        submissionStatusData.map(
                          (sts, index) =>
                            item.submission_status === sts.status && (
                              <button
                                disabled={
                                  item.submission_status === "resolved" &&
                                  item.transaction_details?.status == "success"
                                }
                                key={index}
                                className={`${sts.classname} cursor-pointer text-uppercase`}
                                style={{ background: "transparent" }}
                                onClick={() => handleStatus(item)}
                              >
                                <span>{item.submission_status}</span>
                              </button>
                            )
                        )}
                    </td>
                    <td>
                      <div className="d-flex">
                        <Link
                          to={`/admin/submissions/view/${item.id}`}
                          className="btn btn-sm px-3 mt-2"
                        >
                          &#128065;
                        </Link>
                        <button
                          type="button"
                          className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v text-xs"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <ul className="dropdown-menu shadow">
                          {item.submission_status === "resolved" && (
                            <>
                              <li>
                                <button
                                  onClick={() => {
                                    setSelectedSubmission(item);
                                    setPaymentModal(true);
                                    setIsViewMode(true);
                                    setIsPayMode(false);
                                  }}
                                  className="dropdown-item"
                                >
                                  View Note
                                </button>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={() => {
                                    setSelectedSubmission(item);
                                    setIsViewMode(false);
                                    setPaymentModal(true);
                                    setIsPayMode(true);
                                  }}
                                >
                                  Add Payment
                                </Link>
                              </li>
                            </>
                          )}

                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/admin/program/${item.program_id}/submission`}
                            >
                              View comment
                            </Link>
                          </li>
                          <li>
                            {(permission?.edit || userInfo.role === "admin") &&
                              item.submission_status !== "resolved" &&
                              item.transaction_details?.status != "success" && (
                                <Link
                                  className="dropdown-item"
                                  to={`/admin/submissions/edit/${item.id}`}
                                >
                                  Edit
                                </Link>
                              )}
                          </li>
                          <li>
                            {(permission?.delete || userInfo.role === "admin") && (
                              <button
                                className="dropdown-item"
                                onClick={() => {
                                  setOpenAddModal(true);

                                  setDelId(item.id);
                                }}
                              >
                                Delete
                              </button>
                            )}
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
            </CommonTable>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"DELETE"}
        createAddTopic={"Delete Submission"}
        confirmText={"Delete The Submission"}
        isOpen={openAddModal}
        onSubmit={() => handleDelete(DelId)}
        onClose={() => setOpenAddModal(false)}
      />
      {changeStatusModal ? (
        <div className="changeStatus">
          <div className="changeStatusBody">
            <div className="changeStatusBodyTop">
              <i className="fa-solid fa-circle-exclamation fa-rotate-180"></i>
              <p>Change The Submission Status</p>
            </div>
            <div className="changeStatusBodySecPart">
              <select
                name=""
                id=""
                value={changeStatusVal}
                onChange={(e) => setChangeStatusVal(e.target.value)}
              >
                <option value="new">New</option>
                <option value="triaged">Triaged</option>
                <option value="review">Review</option>
                <option value="resolved">Resolved</option>
                <option value="unresolved">Unresolved </option>
                <option value="rejected">Rejected </option>
              </select>
            </div>
            <div className="changeStatusBodyThirdPart">
              <p>Are You Sure You Want to Change The Status?</p>
            </div>
            <div className="changeStatusBodyLastPart">
              <button
                onClick={() => setChangeStatusModal(false)}
                className="changeStatusBtnClose"
              >
                CLOSE
              </button>
              <button onClick={setStatus} className="changeStatusBtnSubmit">
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {paymentModal && (
        <RewardModal
          isOpen={paymentModal}
          submission={selectedSubmission}
          isViewMode={isViewMode}
          isPayMode={isPayMode}
          onClose={() => setPaymentModal(false)}
          onSubmit={() => {
            setPaymentModal(false);
            fetchSubmissionData(true);
          }}
        />
      )}
      {openAssignee && (
        <div className="modal PaymentModal">
          <div
            className="PaymentModal-content"
            style={{ position: "relative" }}
          >
            <button
              style={{
                position: "absolute",
                right: "5px",
                top: "5px",
                background: "transparent",
              }}
              onClick={() => setOpenAssignee(false)}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
            <div className="">
              <div className=" PaymentModal-header">
                <h4>Change Assignee</h4>
              </div>
              <div className="modal-body pt-0 mb-4 mt-2">
                <div className="d-flex gap-1">
                  <select
                    className="form-select"
                    aria-label=".form-select-lg example"
                    value={assignee?.id ? assignee.id : ""}
                    onChange={(e) => {
                      setAssignee(
                        subAdmins.results.find((el) => el.id === e.target.value)
                      );
                    }}
                  >
                    <option value="" selected>
                      Open this select menu
                    </option>
                    {subAdmins &&
                      subAdmins.results &&
                      subAdmins.results.map((data, index) => (
                        <option key={index} value={data.id}>
                          {data.first_name + " " + data.last_name}
                        </option>
                      ))}
                  </select>
                  <button
                    onClick={changeAssigne}
                    className="addRewardBtn assignBtn"
                  >
                    Assign
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withPermission(SubmissionDashboard);
SubmissionDashboard.moduleName = "Submissions";
