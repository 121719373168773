import React from "react";
import { Link } from "react-router-dom";
import downarrowdark from "../../assets/svg/downarrow.svg";

import menudata from "../menudata/menudata.json";

const Reports = (props) => {
	const userType = props.usertype;

	const userRole = Object.entries(menudata).filter(
		([key, value]) => key === userType
	);
	return (
		<>
			<li className="nav-item  dropdown dropdown-hover">
				<Link
					className="nav-link d-flex justify-content-between cursor-pointer align-items-center "
					to="#"
					role="button"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="me-2"
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
					>
						<path
							d="M14.2214 0.0319824H13.3325C12.3506 0.0319824 11.5547 0.826646 11.5547 1.80691V14.2314C11.5547 15.2117 12.3506 16.0064 13.3325 16.0064H14.2214C15.2032 16.0064 15.9991 15.2117 15.9991 14.2314V1.80691C15.9991 0.826646 15.2032 0.0319824 14.2214 0.0319824Z"
							fill="#7F56D9"
						></path>
						<path
							d="M8.44401 5.35693H7.55512C6.57328 5.35693 5.77734 6.1516 5.77734 7.13186V14.2316C5.77734 15.2119 6.57328 16.0065 7.55512 16.0065H8.44401C9.42585 16.0065 10.2218 15.2119 10.2218 14.2316V7.13186C10.2218 6.1516 9.42585 5.35693 8.44401 5.35693Z"
							fill="#7F56D9"
						></path>
						<path
							d="M2.66667 11.5693H1.77778C0.795938 11.5693 0 12.364 0 13.3443V14.2317C0 15.212 0.795938 16.0067 1.77778 16.0067H2.66667C3.64851 16.0067 4.44444 15.212 4.44444 14.2317V13.3443C4.44444 12.364 3.64851 11.5693 2.66667 11.5693Z"
							fill="#7F56D9"
						></path>
					</svg>
					Reports
					<img
						src={downarrowdark}
						alt="down-arrow"
						className="arrow ms-1 d-lg-block d-none"
					/>
					<img
						src={downarrowdark}
						alt="down-arrow"
						className="arrow ms-1 d-lg-none d-block"
					/>
				</Link>
				<div
					className="dropdown-menu dropdown-menu-animation dropdown-md dropdown-md-responsive p-3 border-radius-lg mt-0 mt-lg-2"
					aria-labelledby="dropdownMenuBlocks"
				>
					<div className="d-none d-lg-block">
						<ul className="list-group">
							{userRole[0][1]?.reports && userRole[0][1].reports.submenu.map((item, index) => {
								if ((props?.overAllPermissions && props?.overAllPermissions[item.name]?.view) || ['admin', 'customer', 'researcher'].includes(userType))
									return (
										<li key={index} className="nav-item dropdown dropdown-hover dropdown-subitem list-group-item border-0 p-0">
											<Link
												className="dropdown-item py-2 ps-3 border-radius-md"
												to={item.link}
											>
												<div className="d-flex">
													<div className="icon h-10 me-3 d-flex mt-1">
														<i className={item.icon}></i>
													</div>
													<div className="w-100 d-flex align-items-center justify-content-between">
														<div>
															<p className="dropdown-header text-dark p-0">
																{item.name}
															</p>
														</div>
													</div>
												</div>
											</Link>
										</li>
									);
							})}
						</ul>
					</div>
				</div>
			</li>
		</>
	);
};

export default Reports;
