import React, { useContext, useEffect, useState } from "react";
import "./ProgramtabStyle.css";
import integrationLogo from "./img/home-decor-3.jpg";
import uploadedImg from "./img/team-3.jpg";
import officeMeeting from "./img/office-dark.jpg";
import HTTPService from "../../utils/axios";
import { useLocation, useParams } from "react-router-dom";
import { programStatusConstant } from "./AdminProgram.constants";
import { formatDate, getObjectKeys, moduleTypeIcon, replaceMessageKeys } from "../../utils/helperFunctions";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileImage from "../../components/ProfileImage";
import AppContext from "../Context/AppContext";
import PrgmSummarySkeleton from "../components/SkeletonLoader/PrgmSummarySkeleton";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../reducers/authSlice";
import { customToast } from "../../App";

const AdminProgramSummary = () => {
  const location = useLocation();
  const { invokeApi } = HTTPService();
  const { id } = useParams();
  const [program, setProgram] = useState();
  const [programStatus, setProgramStatus] = useState({});
  const [submissions, setSubmissions] = useState();
  const [stats, setStats] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusVal, setChangeStatusVal] = useState();
  const { loading, setLoading } = useContext(AppContext);
  const { userInfo } = useSelector(getUserInfo);
  const [trackings, setTrackings] = useState([]);
  const getTracking = async () => {
    const params = {
      program_id: id,
    };
    invokeApi({
      method: "GET",
      url: `program-tracking/`,
      params,
    }).then((res) => {
      setTrackings(res);
    }).catch((error) => {
    });
  };
  const fetchProgram = () => {
    setLoading(true);
    invokeApi({
      method: "GET",
      url: `programs/${id}`,
    })
      .then((response) => {
        setLoading(false);
        setProgram(response);
        setChangeStatusVal(response.program_status);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  const fetchProgramStatus = () => {
    invokeApi({
      method: "GET",
      url: `submissions/status-count/?program_id=${id}`,
    })
      .then((response) => {
        setProgramStatus(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const fetchSubmissions = () => {
    invokeApi({
      method: "GET",
      url: `submissions/?program_id=${id}`,
    })
      .then((response) => {
        setSubmissions(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchStats = () => {
    invokeApi({
      method: "GET",
      url: `submissions/stats?program_id=${id}`,
    })
      .then((response) => {
        setStats(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const setStatus = async () => {
    const data = {
      program_status: changeStatusVal,
    };
    invokeApi({
      method: "PATCH",
      url: `programs/${id}/`,
      data,
    })
      .then((response) => {
        customToast.success("status updated", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        fetchProgram();
        setChangeStatusModal(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setChangeStatusModal(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchProgram();
      fetchProgramStatus();
      fetchSubmissions();
      fetchStats();
      getTracking();
    }
  }, [id]);

  return (
    <>
      {loading ? (
        <PrgmSummarySkeleton />
      ) : (
        <div className="container-fluid programSummary mt-4">
          <div className="row">
            <div className="col-4">
              {/* demo title part */}
              <div className="card p-3 demoTitleBox">
                <div className="row">
                  <h5 className="mb-0">{program?.program_title}</h5>
                  <span className="text-secondary">Issue Summary</span>
                </div>

                <div className="row mt-4">
                  {programStatusConstant &&
                    programStatusConstant.map((key, index) => {
                      return (
                        <div key={index} className="col-12">
                          <div className="d-flex justify-content-center">
                            <div className="col-1">
                              <i className="fa-solid fa-hourglass-half text-primary"></i>
                            </div>
                            <div className="col-6">
                              <p className="mb-1 text-capitalize">
                                {key.replaceAll("_", " ")}
                              </p>
                            </div>
                            <div className="col-2">
                              <div className="demoTitleItemCount">
                                <span>
                                  {programStatus?.total_submissions_status?.hasOwnProperty(key)
                                    ? programStatus.total_submissions_status[key]
                                    : 0}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <hr style={{ marginBottom: "5px", marginTop: "0px" }} />

                <div className=" w-100 d-flex justify-content-between mb-0">
                  {
                    userInfo?.role != 'customer' && <>  <div className="col-6 d-flex align-items-center">


                    </div>
                      <div className="col-6 d-flex justify-content-end">
                        <div
                          className="btn bg-gradient-dark  mb-0 text-capitalize"
                          id="adduser"
                          style={{ textWrap: "nowrap" }}
                          onClick={() => setChangeStatusModal(true)}
                        >
                          Action Button
                        </div>



                      </div>
                    </>}
                </div>
              </div>
            </div>
            <div className="col-4 p-0">
              <div
                className="card p-3 d-flex  align-items-center h-100"
                style={{ backgroundColor: "#9874E5", marginRight: "12px" }}
              >
                <div className="col-12">
                  <img
                    className="uploadedLogo"
                    style={{ width: "100%", objectFit: "contain " }}
                    src={program?.logo ? program?.logo : uploadedImg}
                    alt=""
                  />
                </div>
                <div className="row basicDetails">
                  <div className="w-100 d-flex align-items-center">
                    <div className="col-5">
                      <h6 style={{ textWrap: "nowrap", color: "#fff" }}>
                        Program Visibility
                      </h6>
                    </div>
                    <div className="col-1">
                      <h6 className="mx-2" style={{ color: "#fff" }}>
                        :
                      </h6>
                    </div>
                    <div className="col-6">
                      <h6 className="basicDetailsStatus text-capitalize">
                        {program?.program_status}
                      </h6>
                    </div>
                  </div>
                  <div className=" w-100 d-flex align-items-center">
                    <div className="col-5">
                      <h6 style={{ textWrap: "nowrap", color: "#fff" }}>
                        Severity
                      </h6>
                    </div>
                    <div className="col-1">
                      <h6 className="mx-2" style={{ color: "#fff" }}>
                        :
                      </h6>
                    </div>
                    <div className="col-6">
                      <h6 className="basicDetailsStatus">
                        {program?.severity}
                      </h6>
                    </div>
                  </div>
                  <div className=" w-100 d-flex align-items-center">
                    <div className="col-5">
                      <h6 style={{ textWrap: "nowrap", color: "#fff" }}>URL</h6>
                    </div>
                    <div className="col-1">
                      <h6 className="mx-2" style={{ color: "#fff" }}>
                        :
                      </h6>
                    </div>
                    <div className="col-6">
                      <h6 className="basicDetailsStatus">
                        <a
                          href={program?.scope_items_url1}
                          style={{ color: "#004DFF" }}
                          target="_blank"
                        >
                          {program?.scope_items_url1}
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card col-4" style={{ borderRadius: "10px" }}>
              <div className="col-12">
                <div
                  className="p-3 statsBox"
                // style={{ backgroundColor: "#9874E5" }}
                >
                  <div className="row">
                    <div>
                      <h5 style={{ color: "#000" }}>Program Stats</h5>
                    </div>
                  </div>
                  <hr style={{ backgroundColor: "#000" }} />
                  <div
                    className="row d-flex flex-column StatsList"
                    style={{ marginTop: "28px", color: "#000" }}
                  >
                    <div className="col-10 d-flex">
                      <div className="col-7 p-0">
                        <p style={{ textWrap: "nowrap" }}>Total Invited</p>
                      </div>
                      <div className="col-1">
                        <p>:</p>
                      </div>
                      <div className="col-4 p-0">
                        <p>{stats["Total Invited"] ?? 0}</p>
                      </div>
                    </div>
                    <div className="col-10 d-flex">
                      <div className="col-7 p-0">
                        <p style={{ textWrap: "nowrap" }}>Total Submitter</p>
                      </div>
                      <div className="col-1">
                        <p>:</p>
                      </div>
                      <div className="col-4 p-0">
                        <p>{stats["Total Submitter"] ?? 0}</p>
                      </div>
                    </div>
                    <div className="col-10 d-flex">
                      <div className="col-7 p-0">
                        <p style={{ textWrap: "nowrap" }}>Total Submissions</p>
                      </div>
                      <div className="col-1">
                        <p>:</p>
                      </div>
                      <div className="col-4 p-0">
                        <p>{stats["Total Submissions"] ?? 0}</p>
                      </div>
                    </div>
                    <div className="col-10 d-flex">
                      <div className="col-7 p-0">
                        <p style={{ textWrap: "nowrap" }}>Valid Submissions</p>
                      </div>
                      <div className="col-1">
                        <p>:</p>
                      </div>
                      <div className="col-4 p-0">
                        <p>{stats["Valid Submissions"] ?? 0}</p>
                      </div>
                    </div>
                    <div className="col-10 d-flex">
                      <div className="col-7 p-0">
                        <p style={{ textWrap: "nowrap" }}>Rewards Paid</p>
                      </div>
                      <div className="col-1">
                        <p>:</p>
                      </div>
                      <div className="col-4 p-0">
                        <p>${stats["Total Amount Paid"] ?? 0}</p>
                      </div>
                    </div>
                    <div className="col-10 d-flex">
                      <div className="col-7 p-0">
                        <p style={{ textWrap: "nowrap" }}>Rewards Pending</p>
                      </div>
                      <div className="col-1">
                        <p>:</p>
                      </div>
                      <div className="col-4 p-0">
                        <p>${stats["Total Amount Pending"] ?? 0}</p>
                      </div>
                    </div>
                    {/*  <div className="col-10 d-flex">
                  <div className="col-7 p-0">
                    <p style={{ textWrap: "nowrap" }}>End In</p>
                  </div>
                  <div className="col-1">
                    <p>:</p>
                  </div>
                  <div className="col-4 p-0">
                    <p style={{ textWrap: "nowrap" }}>
                      2023-12-31 <br /> 23:59:59 IST
                    </p>
                  </div>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* your assigned submission */}
            <div className="mt-3 col-6">
              <div className="card p-3 demoTitleBox">
                <div className="row">
                  <h5 className="col-6" style={{ textWrap: "nowrap" }}>
                    Program Submissions
                  </h5>
                  <div className="col-6 d-flex justify-content-end">
                    <Link
                      to={
                        location.pathname.startsWith("/admin")
                          ? `/admin/program/${id}/submission`
                          : `/customer/program/${id}/submission`
                      }
                      className="btn bg-gradient-dark col-4"
                    >
                      View
                    </Link>
                  </div>
                </div>

                {submissions &&
                  submissions.results &&
                  submissions.results.slice(0, 5).map((submission, index) => (
                    <div key={index} className="row mb-3">
                      <div
                        style={{ width: "12%" }}
                        className="col-3 d-flex justify-content-center align-items-center"
                      >
                        <div className="yourAssignedLogo">
                          <ProfileImage
                            src={submission?.user_details?.display_pic}
                          />
                        </div>
                      </div>
                      <div className="col-7 d-flex flex-column justify-content-center">
                        <div>
                          <p
                            style={{
                              // marginBottom: 0,
                              color: "#212529",
                              // fontWeight: "500",
                              marginBottom: ".5rem",
                              lineHeight: "18px",
                            }}
                          >
                            {submission.submission_title}
                          </p>
                        </div>
                        <div style={{ lineHeight: "1" }}>
                          {/* <div className="text-xxs mb-1">
                        {submission.target_title}
                      </div> */}
                          <div
                            className="mb-2"
                            style={{ color: "#004DFF", fontSize: ".9rem" }}
                          >
                            <span
                              style={{
                                fontWeight: "600",
                                paddingRight: ".1em",
                              }}
                            >
                              {submission?.user_details?.first_name
                                ? submission?.user_details?.first_name
                                : " "}
                              {" " + submission?.user_details?.last_name
                                ? submission?.user_details?.last_name
                                : ""}
                            </span>
                            <span
                              style={{ color: "#000435", fontWeight: "600" }}
                            >
                              ,{" "}
                              {" " + submission?.user_details?.username
                                ? submission?.user_details?.username
                                : ""}
                            </span>
                            <span
                              style={{ color: "#000435", fontWeight: "600" }}
                            >
                              ({submission?.user_details?.user_id})
                            </span>
                          </div>
                          <div
                            className="mb-1"
                            style={{ color: "#67748E", fontSize: ".9rem" }}
                          >
                            {/* {submission.languages_frameworks} <br /> */}
                            {formatDate(submission.created_at, 1)},
                            {" " +
                              moment(submission.created_at)
                                .endOf("day")
                                .fromNow()}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                <hr />
              </div>
            </div>
            <div className="col-6 mt-3 p-0">
              <div className="card p-3 recentActivity">
                <div className="row">
                  <div>
                    <h5>Recent Activity</h5>
                  </div>
                </div>
                <hr />
                <div className="row position-relative mt-4">
                  <div className="col-12">
                    {trackings.map((notification, index) => (
                      <div key={index} className="d-flex py-1 mx-2">
                        <div className="my-auto mr-2">
                          <i className={`${moduleTypeIcon['program']} fontSize-2rem mr-2 text-primary`} ></i>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="text-sm font-weight-normal mb-1">
                            <span className="" style={{
                              whiteSpace: "pre-wrap",
                              display: "block"
                            }}
                              dangerouslySetInnerHTML={{ __html: replaceMessageKeys(notification?.message?.message, notification.message) }}
                            >

                            </span>
                          </h6>
                          <p className="text-xs text-secondary mb-0 mt-1 d-flex align-items-center">
                            <div><i className="fa fa-clock me-1 text-primary"></i></div>
                            <div>{notification?.message?.time_stamp}</div>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {changeStatusModal && (
        <div className="changeStatus">
          <div className="changeStatusBody">
            <div className="changeStatusBodyTop">
              <i className="fa-solid fa-circle-exclamation fa-rotate-180"></i>
              <p>Change The Program Status</p>
            </div>
            <div className="changeStatusBodySecPart">
              <select
                name=""
                id=""
                value={changeStatusVal}
                onChange={(e) => setChangeStatusVal(e.target.value)}
              >
                <option value="approved">Approved</option>
                <option value="in_progess">In_Progress</option>
                <option value="pending">Pending</option>
                <option value="hold">Hold</option>
                <option value="closed">Closed </option>
              </select>
            </div>
            <div className="changeStatusBodyThirdPart">
              <p>Are You Sure You Want to Change The Status?</p>
            </div>
            <div className="changeStatusBodyLastPart">
              <button
                onClick={() => setChangeStatusModal(false)}
                className="changeStatusBtnClose"
              >
                CLOSE
              </button>
              <button onClick={setStatus} className="changeStatusBtnSubmit">
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminProgramSummary;
