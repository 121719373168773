import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ProfileImage from "../../../components/ProfileImage";
import { getUserInfo } from "../../../reducers/authSlice";
import HTTPService from "../../../utils/axios";
import { formatDate, getObjectKeys } from "../../../utils/helperFunctions";
import Backbutton from "../../components/backbutton/Backbutton";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import useHttp from "../../Hooks/useHttp";
import "./customerUsers.css";
import CircularLoader from "../../CircularLoader/CircularLoader";
import { customToast } from "../../../App";

export default function CustomerViewUser() {
	//   const [formState, setFormState] = useState(1);
	const { userId, type } = useParams();
	const [userData, setUserData] = useState();
	const { GetRequest } = useHttp();
	const API = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem("token");
	const [socialData, setSocialData] = useState();
	const [paymentData, setPaymentData] = useState();
	const [walletData, setWalletData] = useState();
	const { invokeApi } = HTTPService();
	const { userInfo } = useSelector(getUserInfo)
	const [isNotificationUpdating, setIsNotificationUpdating] = useState(false)

	const [notificationsTabs, setNotificationsTabs] = useState([
		{
			id: '1',
			"module_display_name": "Programm Notification ON/OFF",
			"module_type": "program",
			"is_enabled": false,
		},
		{
			id: '2',
			"module_display_name": "Submission Notification ON/OFF",
			"module_type": "submission",
			"is_enabled": false,
		},
		{
			id: '3',
			"module_display_name": "Wallet Notification ON/OFF",
			"module_type": "wallet",
			"is_enabled": false,
			"user": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
		},
		{
			id: '4',
			"module_display_name": "Badges Notification ON/OFF",
			"module_type": "badges",
			"is_enabled": false,
		},
		{
			id: '5',
			"module_display_name": "Account Notification ON/OFF",
			"module_type": "account",
			"is_enabled": false,
		},
		{
			id: '6',
			"module_display_name": "Transactions Notification ON/OFF",
			"module_type": "transactions",
			"is_enabled": false,
		}
	])

	const getNotificationSettings = () => {
		invokeApi({
			method: "GET",
			url: `/notifications-toggle/`,

		}).then((data) => {
			setNotificationsTabs(prev => prev.map(notify => {
				const getNotify = data.find(n => n.module_type === notify.module_type)
				if (getNotify && Object.keys(getNotify).length > 0) {
					return { ...notify, ...getNotify }
				}
				return notify
			}));
		}).catch((error) => {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		});
	}

	const handleChangeNotification = (notification) => {
		setIsNotificationUpdating(true);
		const data = {
			...notification,
			is_enabled: !notification.is_enabled
		}
		invokeApi({
			method: "POST",
			url: `/notifications-toggle/`,
			data,
			headers: {
				"Content-Type": 'application/json'
			}
		}).then((res) => {
			setNotificationsTabs(prev => prev.map(notify => {
				if (notify.module_type === res.module_type) {
					return { ...notify, ...data }
				}
				return notify
			}));
			setIsNotificationUpdating(false);
		}).catch((error) => {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
			setIsNotificationUpdating(false);
		});
	}

	const fetchUserData = async () => {
		const { data, error } = await GetRequest(
			API + "customer/" + userId + "/",
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		if (error) {
			console.log(error)
		} else {
			setUserData(data)
			console.log("userdata", data)
		}
	};

	const Breadcrumbname = {
		home: "/customer/home",
		"View Customer": "",
	};
	useEffect(() => {
		if (userInfo?.user_data?.id) {
			fetchUserData();
			fetchSocialData();
			fetchPaymentData()
			getNotificationSettings();
		}
	}, [userInfo?.user_data?.id])

	const fetchSocialData = async () => {
		let userType = 'user_profile';
		const { data, error } = await GetRequest(
			API + `${userType}/` + `?user=${userId}`,
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		if (error) {
			console.log(error)
		} else {
			if (data.results && data.results[0]) {
				setSocialData(data.results[0])
			}
		}
	};
	const fetchPaymentData = async () => {
		const { data, error } = await GetRequest(
			API + `payments/` + `?user_id=${userId}`,
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		if (error) {
			console.log(error)
		} else {
			if (data.results && data.results[0]) {
				setPaymentData(data.results[0])
			}
		}
	};
	const fetchWalletData = async () => {
		const { data, error } = await GetRequest(
			API + `wallets/` + `${userData?.wallet_id}`,
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		if (error) {
			console.log(error)
		} else {
			if (data) {
				setWalletData(data)
			}
		}
	};
	useEffect(() => {
		if (userData?.wallet_id)
			fetchWalletData()
	}, [userData?.wallet_id])

	return (
		<>
			{isNotificationUpdating &&
				<CircularLoader size={100} />}
			<div className="container-fluid py-4">
				<div className="row">
					<div className="col-lg-6 m-auto ps-3">
						<Breadcrumb names={Breadcrumbname} />
					</div>
					<div className="col-lg-6">
						<Backbutton />
					</div>
				</div>

				<div className="card card-body blur shadow-blur mx-0 overflow-hidden">
					<div className="row gx-4">
						<div className="col-auto">
							<div className="avatar avatar-xl position-relative">
								<ProfileImage src={userData?.display_pic} errorIcon={<FaUserCircle style={{ fontSize: '3rem', color: '#7f56d9 ' }} />} className="w-100 border-radius-lg shadow-sm" />
							</div>
						</div>
						<div className="col-auto my-auto">
							<div className="h-100">
								<h5 className="mb-1">{userData?.first_name} {userData?.last_name}</h5>
								<p className="mb-0 font-weight-bold text-sm">{userData?.user_id} ({userData?.username})</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
							<div className="nav-wrapper position-relative end-0">
								<ul
									className="nav nav-pills nav-fill p-1 bg-transparent"
									role="tablist"
								>
									<li className="nav-item">
										<Link
											to={`/customer/user/edit/${userId}/${type}/basicinfo/#profile`}
											className="nav-link mb-0 px-0 py-1 text-end"
											role="tab"
											aria-selected="true"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="me-2"
												width="18"
												height="18"
												viewBox="0 0 18 18"
												fill="none"
											>
												<mask
													id="mask0_1683_5670"
													maskUnits="userSpaceOnUse"
													x="0"
													y="0"
													width="18"
													height="19"
												>
													<path
														d="M14.0744 5.49005C14.5845 6.21955 14.9297 7.05105 15.086 7.92694H17V10.105H15.086C14.9297 10.9809 14.5845 11.8124 14.0744 12.5419L15.4284 13.8938L13.8856 15.4341L12.5316 14.0823C11.8009 14.5916 10.9681 14.9362 10.0908 15.0922V17.0032H7.9092V15.0922C7.0319 14.9362 6.19907 14.5916 5.4684 14.0823L4.1144 15.4341L2.5716 13.8938L3.9256 12.5419C3.41549 11.8124 3.07033 10.9809 2.914 10.105H1V7.92694H2.914C3.07033 7.05105 3.41549 6.21955 3.9256 5.49005L2.5716 4.13822L4.1144 2.59789L5.4684 3.94972C6.19907 3.44043 7.0319 3.09582 7.9092 2.93974V1.02881H10.0908V2.93974C10.9681 3.09582 11.8009 3.44043 12.5316 3.94972L13.8856 2.59789L15.4284 4.13822L14.0744 5.49005Z"
														fill="white"
														stroke="white"
														strokeWidth="2"
														strokeLinejoin="round"
													></path>
													<path
														d="M9 11.0126C9.53043 11.0126 10.0391 10.8023 10.4142 10.4278C10.7893 10.0533 11 9.54542 11 9.01584C11 8.48626 10.7893 7.97836 10.4142 7.60389C10.0391 7.22942 9.53043 7.01904 9 7.01904C8.46957 7.01904 7.96086 7.22942 7.58579 7.60389C7.21071 7.97836 7 8.48626 7 9.01584C7 9.54542 7.21071 10.0533 7.58579 10.4278C7.96086 10.8023 8.46957 11.0126 9 11.0126Z"
														fill="black"
														stroke="black"
														strokeWidth="2"
														strokeLinejoin="round"
													></path>
												</mask>
												<g mask="url(#mask0_1683_5670)">
													<path
														d="M-0.601562 -0.568604H18.5984V18.6006H-0.601562V-0.568604Z"
														fill="#7F56D9"
													></path>
												</g>
											</svg>
											<span className="ms-1">Settings</span>
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid py-4">
				<div className="row mt-3">
					<div className="col-12 col-md-6 col-xl-4 mt-md-0 mt-4">
						<div className="card h-100">
							<div className="card-header pb-0 p-3">
								<div className="row">
									<div className="col-md-8 d-flex align-items-center">
										<h6 className="mb-0">Profile Information</h6>
									</div>
									<div className="col-md-4 text-end">
										<Link to={`/customer/user/edit/${userId}/${type}/basicinfo/#profile`}>
											<i
												className="fas fa-pen text-secondary text-sm"
												data-bs-toggle="tooltip"
												data-bs-placement="top"
												aria-hidden="true"
												aria-label="Edit Profile"
												data-bs-original-title="Edit Profile"
											></i>
										</Link>
									</div>
								</div>
							</div>
							<div className="card-body p-3">
								<p className="text-sm">
									{userData?.Short_Description}
								</p>
								<hr className="horizontal gray-light my-4" />
								<ul className="list-group">
									<li className="list-group-item border-0 ps-0 pt-0 text-sm">
										<strong className="text-dark">Full Name:</strong> &nbsp;
										{userData?.first_name} {userData?.last_name}
									</li>
									<li className="list-group-item border-0 ps-0 text-sm">
										<strong className="text-dark">Mobile:</strong> &nbsp;
										{userData?.phone_number}
									</li>
									<li className="list-group-item border-0 ps-0 text-sm">
										<strong className="text-dark">Email:</strong> &nbsp; {
											userData?.email
										}
									</li>
									{/* <li className="list-group-item border-0 ps-0 text-sm">
											<strong className="text-dark">Location:</strong> &nbsp;
											location
										</li> */}
									{/* <li className="list-group-item border-0 ps-0 text-sm">
											<strong className="text-dark">Gender:</strong> &nbsp;
											{userData?.gender}
										</li> */}
									<li className="list-group-item border-0 ps-0 pb-0 d-block">
										<strong className="text-dark text-sm ">Social:</strong>
										&nbsp;


										{
											socialData && getObjectKeys(socialData).map((key, index) => {
												if (["facebook", "instagram", "twitter_x", "youtube", "linked_in"].includes(key) && socialData[key]) {
													return <a
														key={index} className={`pt-2 btn btn-${key.replace('_x', '').replace('_', '')} btn-simple mb-0 ps-1 pe-2 py-0 w-100 text-start`}
														href={socialData[key]}
														target="_blank"
													>
														<i
															className={`fab fa-${key.replace('_x', '').replace('_', '')}  fa-2x`}
															aria-hidden="true"
															style={{ width: '2em' }}
														></i>
														{/* <span className="ps-3">{key.replace('_x', '').replace('_', '')}</span> */}
														<span className="">{socialData[key]}</span>
													</a>
												}


											})
										}

									</li>
								</ul>
							</div>
						</div>
					</div>

					<div className="col-12 col-md-6 col-xl-4 mt-md-0 mt-4">
						<div className="card h-100">
							<div className="card-header pb-0 p-3">
								<div className="row">
									<div className="col-md-8 d-flex align-items-center">
										<h6 className="mb-0">KYC Details</h6>
									</div>
									<div className="col-md-4 text-end">
										<Link to={`/customer/user/edit/${userId}/${type}/paymentinformation`}>
											<i
												className="fas fa-pen text-secondary text-sm"
												data-bs-toggle="tooltip"
												data-bs-placement="top"
												aria-hidden="true"
												aria-label="Edit Profile"
												data-bs-original-title="Edit Profile"
											></i>
										</Link>
									</div>
								</div>
							</div>
							<div className="card-body p-3">
								<ul className="list-group">
									<li className="list-group-item border-0 ps-0 pt-0 text-sm lh-1">
										<strong className="text-dark">ID Proof:</strong> &nbsp;
										{userData?.id_proof ? userData.id_proof : '-'}
										{userData?.id_proof_doc ? <a href={userData?.id_proof_doc} className="btn btn-link p-0 m-0 p-1" target="_blank">View</a> : '-'}
									</li>
									<li className="list-group-item border-0 ps-0 text-sm lh-1">
										<strong className="text-dark">Address Proof:</strong>
										{userData?.address_proof ? userData.address_proof : '-'}
										{userData?.address_proof_doc ? <a href={userData?.address_proof_doc} className="btn btn-link p-0 m-0 p-1" target="_blank">View</a> : '-'}
									</li>
								</ul>
								<h6 className="mb-0 mt-3">Payment Mode</h6>
								<ul className="list-group pt-3">
									<li className="list-group-item border-0 ps-0 pt-0 text-sm lh-1">
										<strong className="text-dark">Ac/No:</strong> &nbsp;{" "}
										{paymentData?.account_number}
									</li>
									<li className="list-group-item border-0 ps-0 text-sm lh-1">
										<strong className="text-dark">Account Name:</strong>{" "}
										&nbsp; {paymentData?.account_name}
									</li>
									<li className="list-group-item border-0 ps-0 text-sm lh-1">
										<strong className="text-dark">Account Name:</strong>{" "}
										&nbsp; {paymentData?.ifsc}
									</li>
									<li className="list-group-item border-0 ps-0 text-sm lh-1">
										<strong className="text-dark">Account Name:</strong>{" "}
										&nbsp; {paymentData?.branch}
									</li>
								</ul>
								<h6 className="mb-0 mt-3">Wallet Details</h6>
								<ul className="list-group pt-3">
									<li className="list-group-item border-0 ps-0 pt-0 text-sm lh-1">
										<strong className="text-dark">KYC Status : </strong>{" "}
										&nbsp;{" "}
										<span className="text-success font-weight-bold">
											{walletData?.KYC_Status}
										</span>
									</li>
									<li className="list-group-item border-0 ps-0 text-sm lh-1">
										<strong className="text-dark">Wallet ID:</strong> &nbsp;{" "}
										{walletData?.Wallet_kyc_id}
									</li>
									<li className="list-group-item border-0 ps-0 text-sm lh-1">
										<strong className="text-dark">Wallet Balance:</strong>{" "}
										&nbsp;$ {walletData?.Wallet_Balance}
									</li>
									<li className="list-group-item border-0 ps-0 text-sm lh-1">
										<strong className="text-dark">
											Date Of Registration:
										</strong>{" "}
										&nbsp; {walletData?.created_at && formatDate(walletData?.created_at, 1)}
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6 col-xl-4">
						<div className="card h-100">
							<div className="card-header pb-0 p-3">
								<h6 className="mb-0">Notification Settings</h6>
							</div>
							<div className="card-body p-3">
								<ul className="list-group">
									{
										notificationsTabs.map((notification) => <li key={notification?.id} className="list-group-item border-0 px-0">
											<div className="form-check form-switch ps-0">
												<input
													className="form-check-input ms-auto"
													type="checkbox"
													id="flexSwitchCheckDefault"
													checked={notification.is_enabled}
													onChange={() => handleChangeNotification(notification)}
												/>
												<label
													className="form-check-label text-body ms-3 text-truncate w-80 mb-0"
													htmlFor="flexSwitchCheckDefault"
												>
													{notification.module_display_name}
												</label>
											</div>
										</li>)
									}
								</ul>
							</div>
						</div>
					</div>
				</div>

				{/* <div className="row mt-3">
					<div className="col-12 col-md-6 col-xl-6 mt-md-0 mt-4">
						<div className="card h-100">
							<div className="card-header pb-0 p-3">
								<div className="row">
									<div className="col-md-8 d-flex align-items-center">
										<h6 className="mb-0">Top Projects</h6>
									</div>
									<div className="col-md-4 text-end">
										<Link to={`/customer/user/edit/${userId}/${type}/addskill`} >
											<i
												className="fas fa-pen text-secondary text-sm"
												data-bs-toggle="tooltip"
												data-bs-placement="top"
												aria-hidden="true"
												aria-label="Edit Profile"
												data-bs-original-title="Edit Profile"
											></i>
										</Link>
									</div>
								</div>
							</div>
							<div className="card-body p-4">
								<div className="col-md-8 d-flex align-items-center">
									<h6 className="mb-0">Project 1 : Demo Project Title</h6>
								</div>
								<p className="text-sm">
									simply dummy text of the printing and typesetting industry.
									Lorem Ipsum has been the industry's standard dummy text ever
									since the 1500s, when an unknown printer took a galley of
									type and scrambled it to make a type specimen book. It has
									survived not only five centuries,
								</p>
								<hr className="horizontal gray-light mt-3" />
								<div className="col-md-8 d-flex align-items-center">
									<h6 className="mb-0">Project 2 : Demo Project Title</h6>
								</div>
								<p className="text-sm">
									simply dummy text of the printing and typesetting industry.
									Lorem Ipsum has been the industry's standard dummy text ever
									since the 1500s, when an unknown printer took a galley of
									type and scrambled it to make a type specimen book. It has
									survived not only five centuries,
								</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6 col-xl-6">
						<div className="card h-100">
							<div className="card-header pb-0 p-3">
								<div className="row">
									<div className="col-md-8 d-flex align-items-center">
										<h6 className="mb-0">2 Factor Authentications </h6>
									</div>
									<div className="col-md-4 text-end">
										<Link to="javascript:;">
											<i
												className="fas fa-pen text-secondary text-sm"
												data-bs-toggle="tooltip"
												data-bs-placement="top"
												aria-hidden="true"
												aria-label="Edit Profile"
												data-bs-original-title="Edit Profile"
											></i>
										</Link>
									</div>
								</div>
							</div>
							<div className="card-body p-3">
								<ul className="list-group">
									<li className="list-group-item border-0 px-0">
										<div className="form-check form-switch ps-0">
											<input
												className="form-check-input ms-auto"
												type="checkbox"
												id="flexSwitchCheckDefault"
												checked=""
											/>
											<label
												className="form-check-label text-body ms-3 text-truncate w-80 mb-0"
												htmlFor="flexSwitchCheckDefault"
											>
												SMS Authentication
											</label>
										</div>
									</li>
									<li className="list-group-item border-0 px-0">
										<div className="form-check form-switch ps-0">
											<input
												className="form-check-input ms-auto"
												type="checkbox"
												id="flexSwitchCheckDefault1"
											/>
											<label
												className="form-check-label text-body ms-3 text-truncate w-80 mb-0"
												htmlFor="flexSwitchCheckDefault1"
											>
												Authenticator App
											</label>
										</div>
									</li>
									<li className="list-group-item border-0 px-0">
										<div className="form-check form-switch ps-0">
											<input
												className="form-check-input ms-auto"
												type="checkbox"
												id="flexSwitchCheckDefault2"
												checked=""
											/>
											<label
												className="form-check-label text-body ms-3 text-truncate w-80 mb-0"
												htmlFor="flexSwitchCheckDefault2"
											>
												Email Authentication
											</label>
										</div>
									</li>
								</ul>
								<div className="card-header pb-0 p-3 px-0">
									<h6 className="mb-0">Integration Accounts</h6>
								</div>
								<ul className="list-group">
									<li className="list-group-item border-0 px-0">
										<div className="form-check form-switch ps-0">
											<input
												className="form-check-input ms-auto"
												type="checkbox"
												id="flexSwitchCheckDefault3"
											/>
											<label
												className="form-check-label text-body ms-3 text-truncate w-80 mb-0"
												htmlFor="flexSwitchCheckDefault3"
											>
												Slack Integration
											</label>
										</div>
									</li>
									<li className="list-group-item border-0 px-0">
										<div className="form-check form-switch ps-0">
											<input
												className="form-check-input ms-auto"
												type="checkbox"
												id="flexSwitchCheckDefault4"
												checked=""
											/>
											<label
												className="form-check-label text-body ms-3 text-truncate w-80 mb-0"
												htmlFor="flexSwitchCheckDefault4"
											>
												Notion Integration
											</label>
										</div>
									</li>
									<li className="list-group-item border-0 px-0 pb-0">
										<div className="form-check form-switch ps-0">
											<input
												className="form-check-input ms-auto"
												type="checkbox"
												id="flexSwitchCheckDefault5"
											/>
											<label
												className="form-check-label text-body ms-3 text-truncate w-80 mb-0"
												htmlFor="flexSwitchCheckDefault5"
											>
												Jira Tickets Integration
											</label>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</>
	);
}
