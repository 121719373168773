import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Infoicon from "../../resetpassword/info.svg";
import Staricon from "../../resetpassword/Star.svg";
import "./Style.css";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import Dashboard from "../../components/Header-Components/Dashboard";
import Management from "../../components/Header-Components/Management";
import Integrations from "../../components/Header-Components/Integrations";
import Payments from "../../components/Header-Components/Payments";
import Reports from "../../components/Header-Components/Reports";

export default function ResearcherAdd() {
	const navigate = useNavigate();
	const [validated, setValidated] = useState(false);
	const [formState, setFormState] = useState(1);
	const [formData, setFormData] = useState({
		customername: "",
		entityName: "",
	});

	const Breadcrumbname = {
		Home: "/researcher/home",
		Managment: "/researcher/management/dashboard",
		Researcher: "/researcher/management/dashboard",
		"Add Researcher": "",
	};
	// step handeler
	const handelstatestep = (e, step) => {
		const form = document.getElementById("mulstep");
		if (form.checkValidity() == false) {
			e.preventDefault();
			e.stopPropagation();
		} else {
			setFormState(step);
		}
		setValidated(true);
	};

	const handleChange = async (event) => {
		const { name, type, files } = event.target;

		if (type === "file") {
			const file = files[0];
			if (file) {
				const reader = new FileReader();
				reader.onloadend = () => {
					const base64 = reader.result.split(",")[1];
					const extensionMatch = file.name.match(/\.([^.]+)$/);
					const extension = extensionMatch[1].toLowerCase();
					setFormData((prevData) => ({
						...prevData,
						[name]: [
							prevData[name],
							{
								type: extension,
								content: base64,
								size: getFileSizeInMB(file),
							},
						],
					}));
				};

				reader.readAsDataURL(file);
			}
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: event.target.value,
			}));
		}
	};

	function getFileSizeInMB(file) {
		const fileSizeInBytes = file.size;
		const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

		if (fileSizeInMB > 5) {
			return false;
		}
		return fileSizeInMB.toFixed(2);
	}

	const handelstatestepPrew = (e, step) => {
		e.preventDefault();
		setFormState(step);
	};

	const handalSubmit = (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		}
		if (form.checkValidity() === true) {
			navigate("/researcher/management/dashboard");
		}
		setValidated(true);
	};
	return (
		<>
			<>
				<div className="container-fluid py-4">
					<div className="row">
						<div className="col-lg-12 col-md-10 col-12 mx-auto ps-3">
							<Breadcrumb names={Breadcrumbname} />
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="multisteps-form mb-5">
								<div className="row">
									<div className="col-12 col-lg-10 col-md-10 mx-auto my-5 ">
										<div className="multisteps-form__progress">
											{formState >= 1 ? (
												<button
													className="multisteps-form__progress-btn js-active"
													type="button"
													title="User Info"
												>
													User Info
												</button>
											) : (
												<button
													className="multisteps-form__progress-btn"
													type="button"
													title="User Info"
												>
													User Info
												</button>
											)}

											{formState >= 2 ? (
												<button
													className="multisteps-form__progress-btn js-active"
													type="button"
													title="Socials"
												>
													Verification
												</button>
											) : (
												<button
													className="multisteps-form__progress-btn"
													type="button"
													title="Verification"
												>
													Verification
												</button>
											)}

											{formState >= 3 ? (
												<button
													className="multisteps-form__progress-btn js-active"
													type="button"
													title="Socials"
												>
													KYC/Uploads
												</button>
											) : (
												<button
													className="multisteps-form__progress-btn"
													type="button"
													title="Verification"
												>
													KYC/Uploads
												</button>
											)}
											{formState === 4 ? (
												<button
													className="multisteps-form__progress-btn js-active"
													type="button"
													title="Profile"
												>
													Profile
												</button>
											) : (
												<button
													className="multisteps-form__progress-btn"
													type="button"
													title="Profile"
												>
													Profile
												</button>
											)}
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-lg-10 m-auto">
										<Form
											className="multisteps-form__form mb-8"
											noValidate
											validated={validated}
											onSubmit={handalSubmit}
											id="mulstep"
										>
											{formState === 1 ? (
												<div
													className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
													data-animation="FadeIn"
												>
													<div className="multisteps-form__content">
														<div className="row mt-3">
															<Form.Group
																as={Col}
																md="6"
																controlId="validationCustom03"
															>
																<Form.Label label="Password">
																	Researcher Name
																</Form.Label>
																<Form.Control
																	className="multisteps-form__input"
																	type="text"
																	name="Researchername"
																	placeholder="Enter Researcher Name"
																	defaultValue={formData.Researchername}
																	onChange={handleChange}
																	required
																/>
																<Form.Control.Feedback type="invalid">
																	Please provide Researcher Name.
																</Form.Control.Feedback>
															</Form.Group>

															<Form.Group
																as={Col}
																md="6"
																controlId="validationCustom03"
															>
																<Form.Label label="Gender">Gender</Form.Label>
																<Form.Select
																	name="gender"
																	className="multisteps-form__input"
																	defaultValue={formData.gender}
																	onChange={handleChange}
																	required
																>
																	<option>-- Select Gender --</option>
																	<option value="Male">Male</option>
																	<option value="Female">Female</option>
																</Form.Select>
																<Form.Control.Feedback type="invalid">
																	Please provide Researcher Name.
																</Form.Control.Feedback>
															</Form.Group>
														</div>
														<div className="row mt-3">
															<Form.Group
																as={Col}
																md="6"
																controlId="validationCustom03"
															>
																<Form.Label label="Password">
																	First Name
																</Form.Label>
																<Form.Control
																	className="multisteps-form__input"
																	type="text"
																	name="firstName"
																	placeholder="Enter First Name"
																	defaultValue={formData.firstName}
																	onChange={handleChange}
																	required
																/>
																<Form.Control.Feedback type="invalid">
																	Please provide First Name.
																</Form.Control.Feedback>
															</Form.Group>

															<Form.Group
																as={Col}
																md="6"
																controlId="validationCustom03"
															>
																<Form.Label label="Password">
																	Last Name
																</Form.Label>
																<Form.Control
																	className="multisteps-form__input"
																	type="text"
																	name="lastName"
																	placeholder="Enter Last Name"
																	defaultValue={formData.lastName}
																	onChange={handleChange}
																	required
																/>
																<Form.Control.Feedback type="invalid">
																	Please provide Last Name.
																</Form.Control.Feedback>
															</Form.Group>
														</div>
														<div className="row mt-3">
															<div className="col-12 col-sm-1">
																<Form.Label label="number">ISO</Form.Label>
																<Form.Select
																	className="multisteps-form__input"
																	name="iso"
																	onChange={handleChange}
																	defaultValue={formData.iso}
																	required
																>
																	<option value="IN">IN</option>
																	<option value="US">US</option>
																	<option value="UK">UK</option>
																</Form.Select>
															</div>
															<Form.Group
																as={Col}
																md="5"
																controlId="validationCustom03"
															>
																<Form.Label label="number">Number</Form.Label>
																<Form.Control
																	className="multisteps-form__input"
																	type="number"
																	name="number"
																	placeholder="Enter number"
																	defaultValue={formData.number}
																	onChange={handleChange}
																	required
																/>
																<Form.Control.Feedback type="invalid">
																	Please provide Phone Number.
																</Form.Control.Feedback>
															</Form.Group>

															<Form.Group
																as={Col}
																md="6"
																controlId="validationCustom03"
															>
																<Form.Label label="email">Email</Form.Label>
																<Form.Control
																	className="multisteps-form__input"
																	type="email"
																	name="email"
																	placeholder="Enter Email"
																	defaultValue={formData.email}
																	onChange={handleChange}
																	required
																/>
																<Form.Control.Feedback type="invalid">
																	Please provide Email.
																</Form.Control.Feedback>
															</Form.Group>
														</div>
														<div className="row mt-2">
															<Form.Group
																as={Col}
																md="6"
																controlId="validationCustom03"
															>
																<Form.Label label="password">
																	Password
																</Form.Label>
																<Form.Control
																	className="multisteps-form__input"
																	type="Password"
																	name="Password"
																	placeholder="******"
																	defaultValue={formData.Password}
																	onChange={handleChange}
																	required
																/>
																<Form.Control.Feedback type="invalid">
																	Please Enter Password.
																</Form.Control.Feedback>
															</Form.Group>

															<Form.Group
																as={Col}
																md="6"
																controlId="validationCustom03"
															>
																<Form.Label label="password">
																	Confirm Password
																</Form.Label>
																<Form.Control
																	className="multisteps-form__input"
																	type="Password"
																	name="confPassword"
																	placeholder="******"
																	defaultValue={formData.confPassword}
																	onChange={handleChange}
																	required
																/>
																<Form.Control.Feedback type="invalid">
																	Please Enter Confirm Password.
																</Form.Control.Feedback>
															</Form.Group>
														</div>
														<div className="suggestion-pass mt-4">
															<ul className="list-unstyled">
																<li>
																	<div className="d-flex">
																		<img
																			className="info-icon"
																			src={Infoicon}
																			alt="info-icon"
																		/>
																		<strong>
																			Suggestions for a Strong Password:
																		</strong>
																	</div>
																</li>
																<li>
																	<div className="star mt-2 ms-4">
																		<img
																			className="info-icon"
																			src={Staricon}
																			alt="info-icon"
																		/>
																		<p className="m-0">
																			A Combination of Upper and Lowercase
																			Letters, Numbers & Special Characters
																			(Allowed One Only )
																		</p>
																	</div>
																</li>
																<li>
																	<div className="star mt-2 ms-4">
																		<img
																			className="info-icon"
																			src={Staricon}
																			alt="info-icon"
																		/>
																		<p className="m-0">
																			At least 8 Characters or More
																		</p>
																	</div>
																</li>
															</ul>
														</div>
														<div className="button-row d-flex mt-4">
															<button
																className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
																type="button"
																title="Next"
																onClick={(e) => handelstatestep(e, 2)}
															>
																Next
															</button>
														</div>
													</div>
												</div>
											) : (
												""
											)}

											{formState === 2 ? (
												<div
													className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
													data-animation="FadeIn"
												>
													<h5 className="font-weight-bolder">
														Authenticate Researcher Account
													</h5>
													<div className="multisteps-form__content">
														<div className="row mt-5 m-auto">
															<div className="col-lg-3 col-12 text-center d-flex align-items-center justify-content-end">
																<div className=" px-0 ">
																	<i
																		style={{ color: "#7F56D9" }}
																		className="fa-solid fa-phone bg-white border border-dark p-4 rounded-circle"
																	></i>
																</div>
															</div>
															<div className="col-lg-3 col-12 text-center verify-text">
																<h5 style={{ color: "#7F56D9" }}>
																	Verify Your Phone
																</h5>
																<p className="my-2">
																	Please enter the 4 digit code send to
																</p>
																<h6>+91********136</h6>
															</div>
															<div className="col-lg-6 col-12 d-flex align-items-center">
																<form
																	className="d-flex align-items-center"
																	id="inputfilds"
																	action=""
																>
																	<input
																		type="text"
																		name=""
																		className="form-control me-1 px-1"
																	/>
																	<input
																		type="text"
																		name=""
																		className="form-control me-1 px-0"
																	/>
																	<input
																		type="text"
																		name=""
																		className="form-control me-1 px-0"
																	/>
																	<input
																		type="text"
																		name=""
																		className="form-control me-4 px-0"
																	/>
																	<button
																		type="submit"
																		name="submit"
																		className="btn bg-gradient-dark mb-0"
																		id="verifybtn"
																	>
																		Verify
																	</button>
																</form>
															</div>
														</div>

														<div className="row mt-5 mb-3 m-auto">
															<div className="col-lg-3 col-12 text-start d-flex align-items-center justify-content-end">
																<div className=" px-0 ">
																	<i
																		style={{ color: "#7F56D9" }}
																		className="fa-solid fa-envelope bg-white border border-dark p-4 rounded-circle"
																	></i>
																</div>
															</div>
															<div className="col-lg-3 col-12 text-center verify-text">
																<h5 style={{ color: "#7F56D9" }}>
																	Verify Your email
																</h5>
																<p className="my-2">
																	Please enter the 4 digit code send to
																</p>
																<h6>e********@gmail.com</h6>
															</div>
															<div className="col-lg-6 col-12 d-flex align-items-center">
																<form
																	className="d-flex "
																	id="inputfilds"
																	action=""
																>
																	<input
																		type="text"
																		name=""
																		className="form-control me-1 px-1"
																	/>
																	<input
																		type="text"
																		name=""
																		className="form-control me-1 px-0"
																	/>
																	<input
																		type="text"
																		name=""
																		className="form-control me-1 px-0"
																	/>
																	<input
																		type="text"
																		name=""
																		className="form-control me-4 px-0"
																	/>
																	<button
																		type="submit"
																		name=""
																		className="btn bg-gradient-dark mb-0"
																		id="verifybtn"
																	>
																		Verify
																	</button>
																</form>
															</div>
														</div>

														<div className="row">
															<div className="button-row d-flex mt-4 col-12">
																<button
																	className="btn bg-gradient-dark mb-0 js-btn-prev"
																	type="button"
																	title="Prev"
																	onClick={(e) => handelstatestepPrew(e, 1)}
																>
																	Prev
																</button>
																<button
																	className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
																	type="button"
																	title="Next"
																	onClick={(e) => handelstatestep(e, 3)}
																>
																	Next
																</button>
															</div>
														</div>
													</div>
												</div>
											) : (
												""
											)}
											{formState === 3 ? (
												<div
													className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
													data-animation="FadeIn"
												>
													<h5 className="font-weight-bolder">
														KYC (Upload Documents)
													</h5>
													<div className="multisteps-form__content">
														<div className="row mt-3 ">
															<Form.Group
																as={Col}
																md="6"
																controlId="validationCustom03"
															>
																<Form.Label label="password">
																	ID Proof
																</Form.Label>
																<Form.Control
																	className="multisteps-form__input"
																	type="Password"
																	name="idproof"
																	placeholder="Enter ID Proof Number"
																	defaultValue={formData.idproof}
																	onChange={handleChange}
																	required
																/>
																<Form.Control.Feedback type="invalid">
																	Please Enter Password.
																</Form.Control.Feedback>
															</Form.Group>

															<Form.Group
																as={Col}
																md="6"
																controlId="validationCustom03"
															>
																<Form.Label label="password">
																	Upload Image
																</Form.Label>
																<Form.Control
																	className="multisteps-form__input"
																	type="file"
																	name="idproofimage"
																	defaultValue={formData.idproofimage}
																	onChange={handleChange}
																	required
																/>
																<Form.Control.Feedback type="invalid">
																	Please Upload Id Proof Documents.
																</Form.Control.Feedback>
															</Form.Group>
														</div>
														<div className="row mt-3 mt-5">
															<Form.Group
																as={Col}
																md="6"
																controlId="validationCustom03"
															>
																<Form.Label label="password">
																	Address Proof
																</Form.Label>
																<Form.Control
																	className="multisteps-form__input"
																	type="text"
																	name="Addressproof"
																	placeholder="Enter Address Proof Number"
																	defaultValue={formData.Addressproof}
																	onChange={handleChange}
																	required
																/>
																<Form.Control.Feedback type="invalid">
																	Please Enter Address Proof.
																</Form.Control.Feedback>
															</Form.Group>

															<Form.Group
																as={Col}
																md="6"
																controlId="validationCustom03"
															>
																<Form.Label label="password">
																	Upload Image
																</Form.Label>
																<Form.Control
																	className="multisteps-form__input"
																	type="file"
																	name="addressproofimage"
																	defaultValue={formData.addressproofimage}
																	onChange={handleChange}
																	required
																/>
																<Form.Control.Feedback type="invalid">
																	Please Upload Address Proof Documents.
																</Form.Control.Feedback>
															</Form.Group>
														</div>
														<div className="row">
															<div className="button-row d-flex mt-4 col-12">
																<button
																	className="btn bg-gradient-dark mb-0 js-btn-prev"
																	type="button"
																	title="Prev"
																	onClick={(e) => handelstatestepPrew(e, 2)}
																>
																	Prev
																</button>
																<button
																	className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
																	type="button"
																	title="Next"
																	onClick={(e) => handelstatestep(e, 4)}
																>
																	Next
																</button>
															</div>
														</div>
													</div>
												</div>
											) : (
												""
											)}
											{formState === 4 ? (
												<div
													className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
													data-animation="FadeIn"
												>
													<h5 className="font-weight-bolder">Profile</h5>
													<div className="multisteps-form__content mt-3">
														<div className="row">
															<Form.Group
																as={Col}
																md="12"
																controlId="validationCustom03"
															>
																<Form.Label label="password">
																	Upload Profile Image
																</Form.Label>
																<Form.Control
																	className="multisteps-form__input"
																	type="file"
																	name="profileimage"
																	defaultValue={formData.profileimage}
																	onChange={handleChange}
																	required
																/>
																<Form.Control.Feedback type="invalid">
																	Please Upload Profile Image.
																</Form.Control.Feedback>
															</Form.Group>

															<Form.Group
																as={Col}
																md="12"
																controlId="validationCustom03"
															>
																<Form.Label label="password">
																	Short Description
																</Form.Label>
																<Form.Control
																	className="multisteps-form__input"
																	as="textarea"
																	rows="5"
																	type="text"
																	name="shortdes"
																	placeholder="Say a few words about who you are or what you're working on."
																	defaultValue={formData.shortdes}
																	onChange={handleChange}
																	required
																/>
																<Form.Control.Feedback type="invalid">
																	Please fill Some Bio-Data.
																</Form.Control.Feedback>
															</Form.Group>
														</div>
														<div className="button-row d-flex mt-4">
															<button
																className="btn bg-gradient-dark mb-0 js-btn-prev"
																type="button"
																title="Prev"
																onClick={(e) => handelstatestepPrew(e, 3)}
															>
																Prev
															</button>
															<button
																className="btn bg-gradient-dark ms-auto mb-0"
																type="submit"
																title="Send"
															>
																Submit
															</button>
														</div>
													</div>
												</div>
											) : (
												""
											)}
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
}
