import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import ViewInfo from "../../../common/ViewInfo";
import HTTPService from "../../../../utils/axios";
import ViewInfoSkeleton from "../../../components/SkeletonLoader/ViewInfoSkeleton";
import { customToast } from "../../../../App";

export default function ViewFaqCategory() {
	const [faqCategoryData, setFaqCategoryData] = useState({});
	const [viewInfoLoader, setViewInfoLoader] = useState(false)

	const { invokeApi } = HTTPService();

	const navigate = useNavigate();

	const { id } = useParams();

	console.log("Faqa id " + id);

	const fetchFaqCategory = async () => {
		setViewInfoLoader(true)
		invokeApi({
			method: "GET",
			url: `faq_category/${id}/`,
		})
			.then((res) => {
				setViewInfoLoader(false)
				setFaqCategoryData(res);
			})
			.catch((error) => {
				setViewInfoLoader(false)
				customToast.error("Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			});
	};

	useEffect(() => {
		fetchFaqCategory();
	}, [id]);

	const Title = "View FAQ Category";
	const option = [
		"FAQ ID",
		"Category title",
		"Category type",
		"FAQ type",
		"Sequence",
	];
	const FaqCategoryValue = [
		faqCategoryData.faq_category_id,
		faqCategoryData.category_title,
		faqCategoryData.category_type,
		faqCategoryData.user_type,
		faqCategoryData.sequence,
	];

	const Breadcrumbname = {
		home: "/admin/home",
		MasterModule: "",
		FaqCategory: "/admin/master-module",
		"faq-sub-category": "",
	};

	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row w-100 d-flex justify-content-center">
					<div className="addCategoryArea  my-4">
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div className="col-lg-7 ps-3">
								<Breadcrumb names={Breadcrumbname} />
							</div>

							<div className="button-row d-flex justify-content-end mt-0 mb-3">
								<Link
									to="/admin/faq-category"
									className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
									id="adduser"
								>
									VIEW ALL
								</Link>
							</div>
						</div>

						<div className="col-12">
							<div className="card" style={{ backgroundColor: "white" }}>
								{viewInfoLoader ? (
									<ViewInfoSkeleton />
								) : (<ViewInfo
									itemValue={FaqCategoryValue}
									option={option}
									title={Title}
								/>)}


								<div className=" d-flex justify-content-end p-4">
									<button
										className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
										onClick={() => navigate(-1)}
									>
										Back
									</button>
									<button
										type="button"
										className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
										onClick={() =>
											navigate(`/admin/faq-sub-category/edit/${id}`)
										}
									>
										Edit
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
