import React from "react";
import { Link } from "react-router-dom";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./components/css/home.css";

import HerooImg from "./components/images/Heroo.png";
import crowdsource from "./components/images/ppl.png";
import bugbounty from "./components/images/BugBounty.png";
import pentestHack from "./components/images/PentestHack.png";
import bugbounty1 from "./components/images/BugBounty_1.png";
import pentest1 from "./components/images/PenTesting-1.png";
import externalAttack1 from "./components/images/ExternalAttack-1.png";
import darkweb from "./components/images/DarkWeb.png";
import redteaming from "./components/images/RedTeaming.png";
import fin from "./components/images/Fin.png";
import health from "./components/images/Health.png";
import auto from "./components/images/Auto.png";
import tech from "./components/images/Tech.png";
import gov from "./components/images/Gov.png";
import security from "./components/images/Security.png";
import grad from "./components/images/Grad.png";
import power from "./components/images/Power.png";
import trans from "./components/images/Trans.png";
import industry from "./components/images/Industry.png";
import tele from "./components/images/Tele.png";
import gaming from "./components/images/Gaming.png";
import esam from "./components/images/ESAM.png";

export default function Home() {
  return (
    <>
      <Header />
      <header className="uui-section_heroheader13">
        <div className="uui-page-padding">
          <div className="uui-container-large">
            <div
              className="uui-padding-vertical-xhuge"
              style={{ paddingTop: "3rem" }}
            >
              <div className="uui-heroheader13_component">
                <div className="uui-text-align-center">
                  <div className="uui-max-width-xlarge">
                    <h1 className="uui-heading-xlarge">
                      Empower Your Cybersecurity
                    </h1>
                    <div className="uui-space-small"></div>
                    <div className="uui-max-width-large align-center">
                      <div className="uui-text-size-xlarge">
                        Uniting Organizations and Ethical Hackers Worldwide to
                        Protect Your Digital Assets.
                      </div>
                    </div>
                    <div className="uui-space-large"></div>
                  </div>
                </div>
              </div>
              <img
                src={HerooImg}
                loading="lazy"
                width="635"
                sizes="(max-width: 479px) 93vw, (max-width: 767px) 94vw, 635px"
                alt="Illustration depicting Cybersecurity"
                // srcset="./components/images/Heroo-p-500.png 500w, ./components/images/Heroo-p-800.png 800w, ./components/images/Heroo-p-1080.png 1080w, ./components/images/Heroo-p-1600.png 1600w, ./components/images/Heroo.png 1741w"
              />
            </div>
          </div>
          <section className="uui-section_layout46">
            <div className="uui-page-padding-4">
              <div className="uui-container-large-4">
                <div className="uui-padding-vertical-xhuge-4">
                  <div className="w-layout-grid uui-layout47_component ">
                    <div className="uui-layout47_content">
                      <div className="uui-icon-featured-outline-large-3">
                        <img
                          src={crowdsource}
                          loading="lazy"
                          width="30"
                          sizes="30px"
                          alt="Crowdsource"
                          // srcset="images/ppl-p-500.png 500w, images/ppl.png 800w"
                        />
                      </div>
                      <div className="uui-space-small-4"></div>
                      <h2 className="uui-heading-xsmall-2">
                        Crowdsourced Bug Bounty
                      </h2>
                      <div className="uui-space-xxsmall-4"></div>
                      <div className="uui-text-size-medium-4">
                        Bugbusterslabs connects organizations with ethical
                        hackers globally to enhance security by identifying and
                        addressing digital vulnerabilities.
                      </div>
                    </div>
                    <div className="uui-layout47_content">
                      <div className="uui-icon-featured-outline-large-3">
                        <img
                          src={bugbounty}
                          loading="lazy"
                          width="32"
                          sizes="32px"
                          alt="Bug Bounty Resilience"
                          // srcset="images/BugBounty-p-500.png 500w, images/BugBounty.png 800w"
                        />
                      </div>
                      <div className="uui-space-small-4"></div>
                      <h2 className="uui-heading-xsmall-2">
                        Bug Bounty Resilience
                      </h2>
                      <div className="uui-space-xxsmall-4"></div>
                      <div className="uui-text-size-medium-4">
                        Our service enhances security by leveraging bug bounty
                        programs to proactively identify and address
                        vulnerabilities.
                      </div>
                    </div>
                    <div className="uui-layout47_content">
                      <div className="uui-icon-featured-outline-large-3">
                        <img
                          src={pentestHack}
                          loading="lazy"
                          width="32"
                          alt="Hacker"
                        />
                      </div>
                      <div className="uui-space-small-4"></div>
                      <h2 className="uui-heading-xsmall-2">
                        Ethical Hacker Community
                      </h2>
                      <div className="uui-space-xxsmall-4"></div>
                      <div className="uui-text-size-medium-4">
                        Creating a diverse ethical hacker community for
                        comprehensive security with a user-friendly platform and
                        unwavering trust.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="uui-section_layout46">
            <div className="uui-page-padding-4">
              <header className="uui-section_header10">
                <div className="uui-page-padding-20">
                  <div className="uui-container-small-2">
                    <div className="uui-padding-vertical-xhuge-18">
                      <div className="uui-text-align-center-10">
                        <h1 className="uui-heading-large-2">Products</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <section className="uui-section_faq01">
                <div className="uui-page-padding-3">
                  <div className="uui-container-large-3">
                    <Swiper
                      spaceBetween={30}
                      centeredSlides={true}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".custom-next",
                        prevEl: ".custom-prev",
                      }}
                      modules={[Autoplay, Pagination, Navigation]}
                    >
                      <SwiperSlide>
                        <div className="slide w-slide">
                          <div className="w-layout-grid uui-layout09_component">
                            <img
                              src={bugbounty1}
                              loading="lazy"
                              id="w-node-_7c106fde-2954-a121-2389-51742e0bc5ca-2e0bc5c3"
                              sizes="(max-width: 479px) 100vw, (max-width: 767px) 61vw, (max-width: 991px) 60vw, 34vw"
                              alt="Illustration depicting Bug Bounty"
                              // srcset="images/BugBounty_1-p-500.png 500w, images/BugBounty_1-p-800.png 800w, images/BugBounty_1-p-1080.png 1080w, images/BugBounty_1.png 1415w"
                              className="image-9"
                            />
                            <div className="uui-layout09_content">
                              <h2 className="uui-heading-medium">
                                Bug Bounty Platform
                              </h2>
                              <div className="uui-space-xsmall"></div>
                              <div className="uui-layout09_item-list">
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc5d0-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Bridging the gap between organizations and
                                      ethical hackers.
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc5d6-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Fostering collaboration and responsible
                                      vulnerability disclosure.
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc5dc-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Creating a trusted, transparent space for
                                      security enhancement.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="uui-button-row-2 is-reverse-mobile-landscape">
                                <div className="uui-button-wrapper-2 max-width-full-mobile-landscape">
                                  <Link
                                    to="/bug-bounty"
                                    className="uui-button-2 w-inline-block text-white"
                                  >
                                    <div>Learn more</div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="w-slide">
                          <div className="w-layout-grid uui-layout09_component">
                            <img
                              src={pentest1}
                              loading="lazy"
                              id="w-node-_7c106fde-2954-a121-2389-51742e0bc5e9-2e0bc5c3"
                              sizes="(max-width: 479px) 100vw, (max-width: 767px) 61vw, (max-width: 991px) 60vw, 34vw"
                              alt="Illustration depicting Penetration Testing"
                              // srcset="images/PenTesting-1-p-500.png 500w, images/PenTesting-1-p-800.png 800w, images/PenTesting-1-p-1080.png 1080w, images/PenTesting-1-p-1600.png 1600w, images/PenTesting-1.png 1715w"
                              className="image-9"
                            />
                            <div className="uui-layout09_content">
                              <h2 className="uui-heading-medium">
                                Penetration Testing
                              </h2>
                              <div className="uui-space-xsmall"></div>
                              <div className="uui-layout09_item-list">
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc5ef-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Skilled security professionals simulate
                                      real-world cyber attacks.
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc5f5-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      In-depth analysis of an
                                      organization&#x27;s security defenses.
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc5fb-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Identifies critical vulnerabilities and
                                      potential entry points.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="uui-button-row-2 is-reverse-mobile-landscape">
                                <div className="uui-button-wrapper-2 max-width-full-mobile-landscape">
                                  <Link
                                    to="/pentesting"
                                    className="uui-button-2 w-inline-block text-white"
                                  >
                                    <div>Learn more</div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="w-slide">
                          <div className="w-layout-grid uui-layout09_component">
                            <img
                              src={externalAttack1}
                              loading="lazy"
                              id="w-node-_7c106fde-2954-a121-2389-51742e0bc608-2e0bc5c3"
                              sizes="(max-width: 479px) 100vw, (max-width: 767px) 61vw, (max-width: 991px) 60vw, 34vw"
                              alt="Illustration depicting External Attack Surface"
                              // srcset="images/ExternalAttack-1-p-500.png 500w, images/ExternalAttack-1-p-800.png 800w, images/ExternalAttack-1-p-1080.png 1080w, images/ExternalAttack-1-p-1600.png 1600w, images/ExternalAttack-1.png 1648w"
                              className="image-9"
                            />
                            <div className="uui-layout09_content">
                              <h2 className="uui-heading-medium">
                                External Attack Surface Management
                              </h2>
                              <div className="uui-space-xsmall"></div>
                              <div className="uui-layout09_item-list">
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc60e-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Identify, assess, and mitigate external
                                      vulnerabilities.
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc614-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Analyze digital footprint: websites, APIs,
                                      cloud, integrations.
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc61a-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Reduce risk of external attacks.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="uui-button-row-2 is-reverse-mobile-landscape">
                                <div className="uui-button-wrapper-2 max-width-full-mobile-landscape">
                                  <Link
                                    to="/bug-bounty"
                                    className="uui-button-2 w-inline-block text-white"
                                  >
                                    <div>Learn more</div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="w-slide">
                          <div className="w-layout-grid uui-layout09_component">
                            <img
                              src={darkweb}
                              loading="lazy"
                              id="w-node-_7c106fde-2954-a121-2389-51742e0bc627-2e0bc5c3"
                              sizes="(max-width: 479px) 100vw, (max-width: 767px) 61vw, (max-width: 991px) 60vw, 34vw"
                              alt=""
                              // srcset="images/DarkWeb-p-500.png 500w, images/DarkWeb-p-800.png 800w, images/DarkWeb-p-1080.png 1080w, images/DarkWeb-p-1600.png 1600w, images/DarkWeb.png 1834w"
                              className="image-9"
                            />
                            <div className="uui-layout09_content">
                              <h2 className="uui-heading-medium">
                                Dark Web Monitoring Solutions
                              </h2>
                              <div className="uui-space-xsmall"></div>
                              <div className="uui-layout09_item-list">
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc62d-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Defend against data breaches and
                                      unauthorized access.
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc633-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Shield sensitive information from identity
                                      theft.
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc639-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Protect proprietary assets and
                                      intellectual property.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="uui-button-row-2 is-reverse-mobile-landscape">
                                <div className="uui-button-wrapper-2 max-width-full-mobile-landscape">
                                  <Link
                                    to="/darkwebmonitoring"
                                    className="uui-button-2 w-inline-block text-white"
                                  >
                                    <div>Learn more</div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="w-slide">
                          <div className="w-layout-grid uui-layout09_component">
                            <img
                              src={redteaming}
                              loading="lazy"
                              id="w-node-_7c106fde-2954-a121-2389-51742e0bc646-2e0bc5c3"
                              sizes="(max-width: 479px) 100vw, (max-width: 767px) 61vw, (max-width: 991px) 60vw, 34vw"
                              alt=""
                              // srcset="images/RedTeaming-p-500.png 500w, images/RedTeaming-p-800.png 800w, images/RedTeaming-p-1080.png 1080w, images/RedTeaming.png 1587w"
                              className="image-9"
                            />
                            <div className="uui-layout09_content">
                              <h2 className="uui-heading-medium">
                                Red Teaming
                              </h2>
                              <div className="uui-space-xsmall"></div>
                              <div className="uui-layout09_item-list">
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc64c-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Identify hidden vulnerabilities and
                                      strengthen defenses.
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc652-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Stay ahead of evolving threats and prepare
                                      for breaches.
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="w-node-_7c106fde-2954-a121-2389-51742e0bc658-2e0bc5c3"
                                  className="uui-layout09_item"
                                >
                                  <div className="uui-layout09_item-icon-wrapper">
                                    <div className="uui-icon-1x1-xsmall-2 w-embed">
                                      <svg
                                        width="currentWidth"
                                        height="currentHeight"
                                        viewbox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                          fill="#F4EBFF"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                          fill="#9E77ED"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="uui-layout09_item-text-wrapper">
                                    <div className="uui-text-size-large">
                                      Raise cybersecurity awareness and empower
                                      employees.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="uui-button-row-2 is-reverse-mobile-landscape">
                                <div className="uui-button-wrapper-2 max-width-full-mobile-landscape">
                                  <Link
                                    to="/redteaming"
                                    className="uui-button-2 w-inline-block text-white"
                                  >
                                    <div>Learn more</div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                      <div className="swiper-button-prev custom-prev"></div>
                      <div className="swiper-button-next custom-next"></div>
                    </Swiper>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </header>
      <section className="uui-section_contact11">
        <div className="uui-page-padding-4">
          <header className="uui-section_heroheader03">
            <div className="uui-page-padding-4">
              <header className="uui-section_heroheader13-3">
                <div className="uui-page-padding-10">
                  <div className="uui-container-large-8">
                    <div className="uui-padding-vertical-xhuge-8">
                      <div className="uui-heroheader13_component-3">
                        <div className="uui-text-align-center-6">
                          <div className="uui-max-width-xlarge-4">
                            <h1 className="uui-heading-xlarge-5">
                              Cross-Industry Security Services
                            </h1>
                            <div className="uui-space-small-6"></div>
                            <div className="uui-max-width-large-7 align-center">
                              <div className="uui-text-size-xlarge-5">
                                Bugbusterslabs&#x27; security services benefit a
                                broad spectrum of industries relying on computer
                                systems, networks, and applications for
                                sensitive data storage, transactions, and
                                customer communication.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <section className="uui-section_layout46-2">
                      <div className="uui-page-padding-13">
                        <div className="uui-container-large-10">
                          <div className="uui-padding-vertical-xhuge-11">
                            <div className="crossIndustryGrid">
                              <div className="uui-layout47_content-2">
                                <div className="uui-icon-featured-outline-large-5">
                                  <img
                                    src={fin}
                                    loading="lazy"
                                    sizes="40px"
                                    // srcset="images/Fin-p-500.png 500w, images/Fin.png 512w"
                                    alt=""
                                  />
                                </div>
                                <div className="uui-space-small-8"></div>
                                <h3 className="uui-heading-xsmall-3">
                                  Financial Services
                                </h3>
                              </div>
                              <div className="uui-layout47_content-2">
                                <div className="uui-icon-featured-outline-large-5">
                                  <img
                                    src={health}
                                    loading="lazy"
                                    sizes="40px"
                                    // srcset="images/Health-p-500.png 500w, images/Health.png 512w"
                                    alt=""
                                  />
                                </div>
                                <div className="uui-space-small-8"></div>
                                <h3 className="uui-heading-xsmall-3">
                                  Healthcare
                                </h3>
                              </div>
                              <div className="uui-layout47_content-2">
                                <div className="uui-icon-featured-outline-large-5">
                                  <img
                                    src={auto}
                                    loading="lazy"
                                    sizes="40px"
                                    // srcset="images/Auto-p-500.png 500w, images/Auto.png 512w"
                                    alt=""
                                  />
                                </div>
                                <div className="uui-space-small-8"></div>
                                <h3 className="uui-heading-xsmall-3">
                                  Automotive
                                </h3>
                              </div>
                              <div className="uui-layout47_content-2">
                                <div className="uui-icon-featured-outline-large-5">
                                  <img
                                    src={tech}
                                    loading="lazy"
                                    sizes="40px"
                                    // srcset="images/Tech-p-500.png 500w, images/Tech.png 512w"
                                    alt=""
                                  />
                                </div>
                                <div className="uui-space-small-8"></div>
                                <h3 className="uui-heading-xsmall-3">
                                  Technology
                                </h3>
                              </div>
                              <div className="uui-layout47_content-2">
                                <div className="uui-icon-featured-outline-large-5">
                                  <img src={gov} loading="lazy" alt="" />
                                </div>
                                <div className="uui-space-small-8"></div>
                                <h3 className="uui-heading-xsmall-3">
                                  Government
                                </h3>
                              </div>
                              <div className="uui-layout47_content-2">
                                <div className="uui-icon-featured-outline-large-5">
                                  <img
                                    src={security}
                                    loading="lazy"
                                    sizes="100vw"
                                    // srcset="images/Security-p-500.png 500w, images/Security.png 512w"
                                    alt=""
                                  />
                                </div>
                                <div className="uui-space-small-8"></div>
                                <h3 className="uui-heading-xsmall-3">
                                  Security
                                </h3>
                              </div>
                              <div className="uui-layout47_content-2">
                                <div className="uui-icon-featured-outline-large-5">
                                  <img
                                    src={grad}
                                    loading="lazy"
                                    sizes="100vw"
                                    // srcset="images/Grad-p-500.png 500w, images/Grad.png 512w"
                                    alt=""
                                  />
                                </div>
                                <div className="uui-space-small-8"></div>
                                <h3 className="uui-heading-xsmall-3">
                                  Education
                                </h3>
                              </div>
                              <div className="uui-layout47_content-2">
                                <div className="uui-icon-featured-outline-large-5">
                                  <img src={power} loading="lazy" alt="" />
                                </div>
                                <div className="uui-space-small-8"></div>
                                <h3 className="uui-heading-xsmall-3">
                                  Energy &amp; Utilities
                                </h3>
                              </div>
                              <div className="uui-layout47_content-2">
                                <div className="uui-icon-featured-outline-large-5">
                                  <img
                                    src={trans}
                                    loading="lazy"
                                    sizes="100vw"
                                    // srcset="images/Trans-p-500.png 500w, images/Trans.png 512w"
                                    alt=""
                                  />
                                </div>
                                <div className="uui-space-small-8"></div>
                                <h3 className="uui-heading-xsmall-3">
                                  Transportation
                                </h3>
                              </div>
                              <div className="uui-layout47_content-2">
                                <div className="uui-icon-featured-outline-large-5">
                                  <img src={industry} loading="lazy" alt="" />
                                </div>
                                <div className="uui-space-small-8"></div>
                                <h3 className="uui-heading-xsmall-3">
                                  Manufacturing
                                </h3>
                              </div>
                              <div className="uui-layout47_content-2">
                                <div className="uui-icon-featured-outline-large-5">
                                  <img
                                    src={tele}
                                    loading="lazy"
                                    sizes="100vw"
                                    // srcset="images/Tele-p-500.png 500w, images/Tele.png 512w"
                                    alt=""
                                  />
                                </div>
                                <div className="uui-space-small-8"></div>
                                <h3 className="uui-heading-xsmall-3">
                                  Telecommunications
                                </h3>
                              </div>
                              <div className="uui-layout47_content-2">
                                <div className="uui-icon-featured-outline-large-5">
                                  <img
                                    src={gaming}
                                    loading="lazy"
                                    sizes="100vw"
                                    // srcset="images/Gaming-p-500.png 500w, images/Gaming.png 512w"
                                    alt=""
                                  />
                                </div>
                                <div className="uui-space-small-8"></div>
                                <h3 className="uui-heading-xsmall-3">Gaming</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </header>
              <div className="uui-container-large-4"></div>
            </div>
          </header>
          <section className="uui-section_layout25">
            <div className="uui-page-padding-19">
              <div className="uui-container-large-16">
                <div className="uui-padding-vertical-xhuge-17">
                  <img
                    src={esam}
                    loading="lazy"
                    sizes="100vw"
                    // srcset="images/ESAM-p-500.png 500w, images/ESAM-p-800.png 800w, images/ESAM-p-1080.png 1080w, images/ESAM-p-1600.png 1600w, images/ESAM-p-2000.png 2000w, images/ESAM-p-2600.png 2600w, images/ESAM-p-3200.png 3200w, images/ESAM.png 7344w"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </>
  );
}
