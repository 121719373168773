import React from "react";

import "./ViewTable.style.css";

export default function ViewTable({ TableHeader, TableLabel }) {
	return (
		<div className="px-5 pt-4 pb-0 ">
			<div className="d-flex align-items-center justify-content-center">
				<table
					style={{ borderCollapse: "collapse", width: "80%" }}
					className="viewTable"
				>
					<thead style={{ backgroundColor: "#7F50DA", color: "white" }}>
						<tr>
							{TableHeader.map((item, index) => (
								<th key={index}>{item}</th>
							))}
						</tr>
					</thead>

					<tbody style={{ backgroundColor: "#E8E0F8", color:"black" }}>
						{TableLabel?.map((row, rowIndex) => (
							<tr key={rowIndex}>
								{row?.map((cell, cellIndex) => (
									<td key={cellIndex}>{cell}</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}
