import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import "./Program.css";

import Col from "react-bootstrap/Col";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import { acceptedFileTypes, acceptedMediaFileTypes, taxanomyTypes } from "../../../utils/helperFunctions";
import AddModal from "../../common/modal/AddModal";
import AppContext from "../../Context/AppContext";
import useFetchProgramPackage from "../../HandleApis/Basevalues/FetchProgramPackage";
import useFetchProgramType from "../../HandleApis/Basevalues/FetchProgramType";
import useFetchSeverity from "../../HandleApis/Basevalues/FetchSeverity";
import useFetchCustomer from "../../HandleApis/Customer/FetchCustomer";
import { customToast } from "../../../App";

export default function AddProgram() {
  const { invokeApi } = HTTPService();
  const uid = localStorage.getItem("userid");
  const { setCircularLoading } = useContext(AppContext);
  const { severityOpt } = useFetchSeverity();
  const { programTypOpt } = useFetchProgramType();
  const { programPkgOpt } = useFetchProgramPackage();
  const { customerOpt } = useFetchCustomer();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [switchValue, setSwitchValue] = useState(false);
  const [taxOfFiles, setTaxOfFiles] = useState([]);
  const [attachFiles, setAttchFiles] = useState([]);
  const [logoName, setLogoName] = useState("");
  const [prgmAttchFileName, setPrgmAttachFileName] = useState("");
  const [form, setForm] = useState({});
  const [Errors, setErrors] = useState({});
  const [valids, setValids] = useState({});
  const [pgrmTagValue, setPgrmTagValue] = useState("");
  const [pgrmTagArr, setPgrmTagArr] = useState([]);
  const [scopeUrlInput, setScopeUrlInput] = useState([]);
  const [outScopeUrlInput, setOutScopeUrlInput] = useState([]);
  const [rewardInput, setRewardInput] = useState([]);
  const [typeOfTestingInput, setTypeOfTestingInput] = useState("");
  const [typeOfTestings, setTypeOfTestings] = useState([]);
  const [languagesFrameworksInput, setLanguagesFrameworksInput] = useState("");
  const [languagesFrameworks, setLanguagesFrameworks] = useState([]);
  const [assetEnvironmentsInput, setAssetEnvironmentsInput] = useState("");
  const [assetEnvironments, SetAssetEnvironments] = useState([]);
  const navigate = useNavigate();

  //set program type
  const setProgramType = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please select program type",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };
  //set program packages
  const setProgramPackages = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please select program packages",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };

  //set customer
  const setCustomer = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please select customer",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };
  //set customer
  const setProgramTitle = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please select Program Title",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };

  //set program Description
  const setDescription = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please select Program Description",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };

  //set scope title
  const setScopeTitle = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please enter scope title",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };
  //set scope item url
  const setScopeUrl = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please enter scope url",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };
  //set out of scope item url
  const setOutScopeUrl = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please enter out of scope url",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };

  //set Priority
  const setSeverity = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please Select Severity",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };
  //set expected Vul
  const setExpectedVulType = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please Select Expected Vulnerability Types ",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };
  //set reward amount 1
  const setRewardAmt1 = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please Enter Reward Amount ",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };

  //set maximum budget
  const setMaximumBudget = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please Enter Maximum Budget",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };
  //set start date
  const setStartDate = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please Select Start Date",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };
  //set end date
  const setEndDate = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please Select End Date",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };

  //id proof doc
  const setLogoFile = async (field, value) => {

    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please Upload Logo",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };

  //set program attach file
  const setProgramAttachFile = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...Errors,
        [field]: "Please Upload Program Attachment",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...Errors,
        [field]: null,
      });
    }
  };

  const handleAddInput = (e) => {
    e.preventDefault();
    if (scopeUrlInput.length < 4) {
      setScopeUrlInput([...scopeUrlInput, ""]);
    }
    console.log("scopeUrlInput", scopeUrlInput);
  };
  const handleOutAddInput = (e) => {
    e.preventDefault();
    if (outScopeUrlInput.length < 4) {
      setOutScopeUrlInput([...outScopeUrlInput, ""]);
    }
    console.log("scopeUrlInput", scopeUrlInput);
  };
  const handleRewardInput = (e) => {
    e.preventDefault();
    if (rewardInput.length < 4) {
      setRewardInput([...rewardInput, ""]);
    }
  };
  const handleChange = (index, event) => {
    const newInputs = [...scopeUrlInput];
    newInputs[index] = event.target.value;

    setScopeUrlInput(newInputs);
    // if (scopeUrlInput.length-1 === index ) {
    // 	setCheckValidScopeItemUrl(true);
    // } else {
    // 	setCheckValidScopeItemUrl(false);
    // }
  };
  const handleOutChange = (index, event) => {
    const newInputs = [...outScopeUrlInput];
    newInputs[index] = event.target.value;
    setOutScopeUrlInput(newInputs);
  };

  const handleRewardChange = (index, event) => {
    const newInputs = [...rewardInput];
    newInputs[index] = event.target.value;
    setRewardInput(newInputs);
  };
  const deleteTargateBugUrl = (e, i) => {
    e.preventDefault();
    const newScopeUrl = [...scopeUrlInput];
    newScopeUrl.splice(i, 1);
    setScopeUrlInput(newScopeUrl);

    // console.log("delete", scopeUrlInput);
  };
  const deleteOutScopeUrl = (e, i) => {
    e.preventDefault();
    const newOutScopeUrl = [...outScopeUrlInput];
    newOutScopeUrl.splice(i, 1);
    setOutScopeUrlInput(newOutScopeUrl);

    // console.log("delete", scopeUrlInput);
  };
  const deleteRewardInput = (e, i) => {
    e.preventDefault();
    const newRewardInput = [...rewardInput];
    newRewardInput.splice(i, 1);
    setRewardInput(newRewardInput);

    // console.log("delete", scopeUrlInput);
  };

  const addPgrmTag = (e) => {
    if (e.keyCode === 13 && pgrmTagValue) {
      e.preventDefault();
      setPgrmTagArr([...pgrmTagArr, pgrmTagValue]);
      setPgrmTagValue("");
    }
  };
  const deletePgrmTag = (val) => {
    let focusPgrmTag = pgrmTagArr.filter((t) => t !== val);
    setPgrmTagArr(focusPgrmTag);
  };

  const addTota = (e) => {
    if (e.keyCode === 13 && typeOfTestingInput) {
      e.preventDefault();
      setTypeOfTestings([...typeOfTestings, typeOfTestingInput]);
      setTypeOfTestingInput("");
    }
  };

  const deleteTota = (val) => {
    let focusTota = typeOfTestings.filter((t) => t !== val);
    setTypeOfTestings(focusTota);
  };
  const addLanguageFrameworks = (e) => {
    if (e.keyCode === 13 && languagesFrameworksInput) {
      e.preventDefault();
      setLanguagesFrameworks([...languagesFrameworks, languagesFrameworksInput]);
      setLanguagesFrameworksInput("");
    }
  };
  const addAE = (e) => {
    if (e.keyCode === 13 && assetEnvironmentsInput) {
      e.preventDefault();
      SetAssetEnvironments([...assetEnvironments, assetEnvironmentsInput]);
      setAssetEnvironmentsInput("");
    }
  };
  const deleteLF = (val) => {
    let focusLf = languagesFrameworks.filter((t) => t !== val);
    setLanguagesFrameworks(focusLf);
  };
  const deleteAe = (val) => {
    let focusAe = assetEnvironments.filter((t) => t !== val);
    SetAssetEnvironments(focusAe);
  };

  const handleSwitchToggle = () => {
    setSwitchValue(!switchValue);
  };

  const checkallValidity = () => {
    const {
      program_type,
      program_package,
      customer,
      program_title,
      project_description,
      logo,
      program_attachments,
      scope_title,
      scope_items_url1,
      out_Of_scope_item_url1,
      severity,
      expected_vulnerability_types,
      p1_reward_amt,
      p2_reward_amt,
      p3_reward_amt,
      maximun_budget,
      start_date,
      end_date,
    } = form;
    const allError = {};

    //program type
    if (!program_type || program_type === "")
      allError.program_type = "Please select program type";
    //program package
    if (!program_package || program_package === "")
      allError.program_package = "Please select program packages";
    //customer
    if (!customer || customer === "")
      allError.customer = "Please select customer";

    //program title
    if (!program_title || program_title === "")
      allError.program_title = "Please select Program Title";
    //project_description
    if (!project_description || project_description === "")
      allError.project_description = "Please select Program Description";
    //project_description
    if (!logo || logo === "") allError.logo = "Please Upload Logo";

    //scope_title
    if (!scope_title || scope_title === "")
      allError.scope_title = "Please enter scope title";
    //scope_items_url1
    if (!scope_items_url1 || scope_items_url1 === "")
      allError.scope_items_url1 = "Please enter scope url";

    //priority
    if (!severity || severity === "")
      allError.severity = "Please Select Severity";
    //expected_vulnerability_types
    if (!expected_vulnerability_types || expected_vulnerability_types === "")
      allError.expected_vulnerability_types =
        "Please Select Expected Vulnerability Types ";
    //p1_reward_amt
    if (!p1_reward_amt || p1_reward_amt === "")
      allError.p1_reward_amt = "Please Enter Reward Amount ";

    //maximun_budget
    if (!maximun_budget || maximun_budget === "")
      allError.maximun_budget = "Please Enter Maximum Budget";
    //start_date
    if (!start_date || start_date === "")
      allError.start_date = "Please Select Start Date";
    //end_date
    if (!end_date || end_date === "")
      allError.end_date = "Please Select End Date";

    if (!out_Of_scope_item_url1)
      allError.out_Of_scope_item_url1 = "Please Enter Value";
    if (!p1_reward_amt) allError.p1_reward_amt = "Please Enter Value";
    if (typeOfTestings.length < 1) allError.typeOfTestings = "Please add atleast one value";
    if (languagesFrameworks.length < 1) allError.languagesFrameworks = "Please add atleast one value";
    if (assetEnvironments.length < 1) allError.assetEnvironments = "Please add atleast one value";
    if (pgrmTagArr.length < 1) allError.pgrmTagArr = "Please enter program tag";
    if (taxOfFiles.length < 1) allError.taxOfFiles = "Please upload at least one document";
    if (attachFiles.length < 1) allError.attachFiles = "Please upload at least one document";

    return allError;
  };

  const finalSubmit = async () => {
    const checkError = checkallValidity();
    if (Object.keys(checkError).length > 0) {
      setErrors(checkError);
      setOpenAddModal(false)
    } else if (
      rewardInput === undefined ||
      typeOfTestings === undefined ||
      languagesFrameworks === undefined ||
      assetEnvironments === undefined ||
      taxOfFiles === undefined ||
      attachFiles === undefined
    ) {
      return;
    } else {
      const param = {
        ...form,
        scope_items_url2:
          scopeUrlInput && scopeUrlInput[0] ? scopeUrlInput[0] : null,
        scope_items_url3:
          scopeUrlInput && scopeUrlInput[1] ? scopeUrlInput[1] : null,
        scope_items_url4:
          scopeUrlInput && scopeUrlInput[2] ? scopeUrlInput[2] : null,
        scope_items_url5:
          scopeUrlInput && scopeUrlInput[3] ? scopeUrlInput[3] : null,
        out_Of_scope_item_url2:
          outScopeUrlInput && outScopeUrlInput[0] ? outScopeUrlInput[0] : null,
        out_Of_scope_item_url3:
          outScopeUrlInput && outScopeUrlInput[1] ? outScopeUrlInput[1] : null,
        out_Of_scope_item_url4:
          outScopeUrlInput && outScopeUrlInput[2] ? outScopeUrlInput[2] : null,
        out_Of_scope_item_url5:
          outScopeUrlInput && outScopeUrlInput[3] ? outScopeUrlInput[3] : null,
        p2_reward_amt: rewardInput && rewardInput[0] ? rewardInput[0] : null,
        p3_reward_amt: rewardInput && rewardInput[1] ? rewardInput[1] : null,
        p4_reward_amt: rewardInput && rewardInput[2] ? rewardInput[2] : null,
        p5_reward_amt: rewardInput && rewardInput[3] ? rewardInput[3] : null,
        testing_allowed: typeOfTestings.toString(),
        language_framworks: languagesFrameworks.toString(),
        asset_environments: assetEnvironments.toString(),
        taxonomy_doc1: taxOfFiles && taxOfFiles[0] ? taxOfFiles[0] : null,
        taxonomy_doc2: taxOfFiles && taxOfFiles[1] ? taxOfFiles[1] : null,
        taxonomy_doc3: taxOfFiles && taxOfFiles[2] ? taxOfFiles[2] : null,
        taxonomy_doc4: taxOfFiles && taxOfFiles[3] ? taxOfFiles[3] : null,
        taxonomy_doc5: taxOfFiles && taxOfFiles[4] ? taxOfFiles[4] : null,
        upload_doc1: attachFiles && attachFiles[0] ? attachFiles[0] : null,
        upload_doc2: attachFiles && attachFiles[1] ? attachFiles[1] : null,
        upload_doc3: attachFiles && attachFiles[2] ? attachFiles[2] : null,
        upload_doc4: attachFiles && attachFiles[3] ? attachFiles[3] : null,
        upload_doc5: attachFiles && attachFiles[4] ? attachFiles[4] : null,
        program_status: "approved",
        private_program: switchValue.toString(),
        user_id: form.customer,
        project_tags: pgrmTagArr.toString(),
      };
      setCircularLoading(true);
      setOpenAddModal(false);
      invokeApi({
        method: "POST",
        url: "programs/",
        data: param,
      })
        .then((data) => {
          setCircularLoading(false);
          customToast.success("Program added successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          navigate("/admin/program/dashboard");
        })
        .catch((error) => {
          setCircularLoading(false);
          customToast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        });
    }
  };

  const handleFinalClick = async () => {
    const checkError = checkallValidity();
    if (Object.keys(checkError).length > 0) {
      setErrors(checkError);
      setOpenAddModal(false);
      customToast.error("Reolved all errors!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else if (
      rewardInput === undefined ||
      typeOfTestings === undefined ||
      languagesFrameworks === undefined ||
      assetEnvironments === undefined ||
      taxOfFiles === undefined ||
      attachFiles === undefined
    ) {
      return;
    } else {
      setOpenAddModal(true);
    }
  };

  const Breadcrumbname = {
    Home: "/admin/home",
    Management: "/admin/program/dashboard",
    Programs: "/admin/program/dashboard",
    "Add Program": "/admin/program/add",
  };

  const handleFileChange = (event, setFile, maxFiles, maxSize) => {
    const selectedFiles = Array.from(event.target.files);
    for (const file of selectedFiles) {
      if (file.size > maxSize) {
        alert(`File "${file.name}" exceeds the maximum upload size.`);
        return;
      }
    }
    if (setFile.length + selectedFiles.length > maxFiles) {
      alert(`You can only upload a maximum of ${maxFiles} files.`);
      return;
    }

    setFile((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const deleteFile = (index, setFile, file) => {
    const newFiles = [...file];
    newFiles[index] = "";
    setFile(newFiles);
  };

  return (
    <>
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-12 m-auto ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
        </div>
        <div className="card card-body mt-4">
          <div className="row">
            <div className="col-lg-9">
              <h6 className="mb-0">New Program</h6>
              <p className="text-sm mb-0">Create a new program</p>
              <hr className="horizontal dark my-3" />
              <div className="row">
                <div className="col-lg-6 ">
                  <Form.Group>
                    <Form.Label>
                      Program Type <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={form && form.program_type && form.program_type}
                      onChange={(e) =>
                        setProgramType("program_type", e.target.value)
                      }
                      isInvalid={!!Errors.program_type}
                      isValid={
                        valids.program_type && valids.program_type === true
                      }
                    >
                      <option value="">Select Program Type</option>
                      {programTypOpt &&
                        programTypOpt?.split(",").map((item, index) => {
                          // console.log(item);
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {Errors.program_type}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-lg-6 ">
                  <Form.Group>
                    <Form.Label>
                      Program Package <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={
                        form && form.program_package && form.program_package
                      }
                      onChange={(e) =>
                        setProgramPackages("program_package", e.target.value)
                      }
                      isInvalid={!!Errors.program_package}
                      isValid={
                        valids.program_package &&
                        valids.program_package === true
                      }
                    >
                      <option value="">Select Program Package</option>
                      {programPkgOpt &&
                        programPkgOpt?.split(",").map((item, index) => {
                          // console.log(item);
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {Errors.program_package}
                    </Form.Control.Feedback>

                    <p style={{ color: "#7F56D9", fontSize: "12px" }}>
                      * Premium Plan is Valid Upto 6 Months and get Bonus of
                      $500.00
                    </p>
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 ">
                  <Form.Group>
                    <Form.Label>
                      Customer Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={form && form.customer && form.customer}
                      onChange={(e) => setCustomer("customer", e.target.value)}
                      isInvalid={!!Errors.customer}
                      isValid={valids.customer && valids.customer === true}
                    >
                      <option value="">Select Customer Name</option>
                      {customerOpt &&
                        customerOpt.results &&
                        customerOpt.results.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.entity_name} -- {item.user_id} --
                            {item.first_name} {item.last_name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {Errors.customer}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <Form.Group className="mt-2">
                <Form.Label>
                  Program Title <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  className="m-0"
                  type="text"
                  name="programtitle"
                  placeholder="Enter a Program Title"
                  value={form && form.program_title && form.program_title}
                  onChange={(e) =>
                    setProgramTitle("program_title", e.target.value)
                  }
                  isInvalid={!!Errors.program_title}
                  isValid={
                    valids.program_title && valids.program_title === true
                  }
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {Errors.program_title}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mt-2">
                <Form.Label>
                  Program Description <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <p className="form-text text-muted text-xs ms-1 mt-0">
                  This is how others will learn about the project, so make it
                  good!
                </p>
                <CKEditor
                  editor={ClassicEditor}
                  data={
                    form && form.project_description && form.project_description
                  }
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    setDescription("project_description", editor.getData());
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
                {form &&
                  (form.project_description === " " ||
                    form.project_description === undefined ||
                    form.project_description === null) ? (
                  <p style={{ color: "#f00", fontSize: "14px" }}>
                    Please Enter Program Description
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
            <div className="col-lg-3 ">
              <Form.Group>
                <Form.Label>
                  Logo <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <div className="form-control dotted">
                  <div className="file-upload">
                    {logoName && logoName ? <span>{logoName} <i style={{ zIndex: 1000, position: 'absolute' }} onClick={(e) => {
                      e.stopPropagation();
                      setLogoFile("logo", '');
                      setLogoName();
                    }} className="fa-solid fa-trash text-primary"></i></span> : <p>Upload Logo</p>}
                    <Form.Control
                      type="file"
                      accept=".jpg,.png,.webp"
                      onChange={(e) => {
                        if (!acceptedMediaFileTypes.includes(e.target.files[0]?.type)) {
                          setErrors({
                            ...Errors,
                            ['logo']: "Please Upload Png or jpg file",
                          });
                          setValids({
                            ...valids,
                            ['logo']: false,
                          });
                          return null
                        }
                        if (
                          e.target.files[0] &&
                          e.target.files[0].size &&
                          e.target.files[0].size > 5 * 1024 * 1024
                        ) {
                          // Convert to bytes (10MB)
                          return customToast.error(`File "${e.target.files[0].name}" exceeds the maximum upload size of 5MB.`, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                          });

                        }
                        setLogoFile("logo", e.target.files[0]);
                        setLogoName(
                          e.target.files[0] &&
                          e.target.files[0].name &&
                          e.target.files[0].name
                        );
                      }}
                      isInvalid={!!Errors.logo}
                      isValid={valids.logo && valids.logo === true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {Errors.logo}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label>Private Program</Form.Label>
                <p className="form-text text-muted text-xs ms-1 mt-0">
                  If You wish to Hide Program
                </p>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={switchValue}
                  onChange={handleSwitchToggle}
                />
              </Form.Group>

              <Form.Group className="mt-2">
                <Form.Label>Program Attachments</Form.Label>

                <div className="form-control dotted">
                  <div className="file-upload">
                    {prgmAttchFileName && prgmAttchFileName ? (
                      <span>{prgmAttchFileName} <i style={{ zIndex: 1000, position: 'absolute' }} onClick={(e) => {
                        e.stopPropagation();
                        setProgramAttachFile("program_attachments", '');
                        setPrgmAttachFileName();
                      }} className="fa-solid fa-trash text-primary"></i></span>

                    ) : (
                      <p>Upload Program Attachment</p>
                    )}
                    <Form.Control
                      type="file"
                      onChange={(e) => {
                        if (!acceptedFileTypes.includes(e.target.files[0]?.type)) {
                          customToast.error(`File ${e.target.files[0]?.type} not supported`, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                          })
                          return null
                        }
                        if (
                          e.target.files[0] &&
                          e.target.files[0].size &&
                          e.target.files[0].size > 5 * 1024 * 1024
                        ) {
                          // Convert to bytes (10MB)
                          return customToast.error(`File "${e.target.files[0].name}" exceeds the maximum upload size of 5MB.`, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                          });

                        }
                        setProgramAttachFile(
                          "program_attachments",
                          e.target.files[0]
                        );
                        setPrgmAttachFileName(
                          e.target.files[0] &&
                          e.target.files[0].name &&
                          e.target.files[0].name
                        );
                      }}
                      isInvalid={!!Errors.program_attachments}
                      isValid={
                        valids.program_attachments &&
                        valids.program_attachments === true
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {Errors.program_attachments}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <p className="form-text text-center text-muted text-xs ms-1 mt-2">
                  max-upload size :5mb
                </p>
              </Form.Group>
            </div>
          </div>

          <Form.Group className="mt-2">
            <Form.Label>Program Tags <span style={{ color: "red" }}>*</span></Form.Label>
            <div className="form-control  languageFrameworkDiv">
              {pgrmTagArr.map((item, index) => {
                return (
                  <div key={index} className="LfElement">
                    <span>{item}</span>
                    <span
                      onClick={(val) => deletePgrmTag(item)}
                      style={{ marginLeft: "10px" }}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </span>
                  </div>
                );
              })}
              <Form.Control
                type="text"
                placeholder="Add Program Tag"
                value={pgrmTagValue}
                onChange={(e) => setPgrmTagValue(e.target.value)}
                onKeyDown={addPgrmTag}
                isInvalid={!!Errors.pgrmTagArr}
                isValid={pgrmTagValue.pgrmTagArr != ''}
              />
            </div>
            {
              Errors.pgrmTagArr && <div className="invalid-warning" style={{ display: 'block' }}>
                {Errors.pgrmTagArr}
              </div>
            }

          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label>
              Scope Title <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              className="m-0"
              type="text"
              name="scopetitle"
              placeholder="Enter Scope Title"
              value={form && form.scope_title && form.scope_title}
              onChange={(e) => setScopeTitle("scope_title", e.target.value)}
              isInvalid={!!Errors.scope_title}
              isValid={valids.scope_title && valids.scope_title === true}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {Errors.scope_title}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="row ">
            <div className="col-12">
              <Form.Group className="mt-2">
                <Form.Label>
                  Scope Items <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div className=" d-flex flex-column">
                  <div className="d-flex">
                    <div style={{ width: "70%" }}>
                      <Form.Control
                        placeholder="Enter Scope Item URL"
                        type="text"
                        value={
                          form && form.scope_items_url1 && form.scope_items_url1
                        }
                        onChange={(e) =>
                          setScopeUrl("scope_items_url1", e.target.value)
                        }
                        isInvalid={!!Errors.scope_items_url1}
                        isValid={
                          valids.scope_items_url1 &&
                          valids.scope_items_url1 === true
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {Errors.scope_items_url1}
                      </Form.Control.Feedback>
                    </div>
                    <div>
                      <button
                        onClick={handleAddInput}
                        style={{ borderRadius: "8px" }}
                        className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                      >
                        Add +
                      </button>
                    </div>
                  </div>
                  {scopeUrlInput.map((input, index) => (
                    <div key={index} className="d-flex  mt-3">
                      <div style={{ width: "70%" }}>
                        <Form.Control
                          className="form-control"
                          placeholder="Enter Scope Item URL"
                          type="text"
                          key={index}
                          value={input}
                          onChange={(e) => handleChange(index, e)}
                          isValid={input && input.value !== "" ? true : false}
                        />
                      </div>
                      <div>
                        <button
                          onClick={(e) => deleteTargateBugUrl(e, index)}
                          style={{ borderRadius: "8px" }}
                          className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Form.Group className="mt-2">
                <Form.Label>Out Of Scope Items <span style={{ color: "red" }}>*</span></Form.Label>
                <div className=" d-flex flex-column">
                  <div className="d-flex">
                    <div style={{ width: "70%" }}>
                      <Form.Control
                        className="form-control"
                        placeholder="Enter Out of Scope Item URL"
                        type="text"
                        value={
                          form &&
                          form.out_Of_scope_item_url1 &&
                          form.out_Of_scope_item_url1
                        }
                        onChange={(e) =>
                          setOutScopeUrl(
                            "out_Of_scope_item_url1",
                            e.target.value
                          )
                        }
                        isInvalid={!!Errors.out_Of_scope_item_url1}
                        isValid={
                          form.out_Of_scope_item_url1 !== ''
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {Errors.out_Of_scope_item_url1}
                      </Form.Control.Feedback>
                    </div>
                    <div>
                      <button
                        onClick={handleOutAddInput}
                        style={{ borderRadius: "8px" }}
                        className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                      >
                        Add +
                      </button>
                    </div>
                  </div>
                  {outScopeUrlInput.map((input, index) => (
                    <div key={index} className="d-flex mt-3">
                      <div style={{ width: "70%" }}>
                        <Form.Control
                          className="form-control"
                          placeholder="Enter Out of Scope Item URL"
                          type="text"
                          key={index}
                          value={input}
                          onChange={(e) => handleOutChange(index, e)}
                          isValid={input && input.value !== "" ? true : false}
                        />
                      </div>
                      <div>
                        <button
                          onClick={(e) => deleteOutScopeUrl(e, index)}
                          style={{ borderRadius: "8px" }}
                          className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </Form.Group>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-lg-6 col-12">
              <Form.Group className="mt-2">
                <Form.Label>
                  Severity <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  className="form-select"
                  name="choices-gender"
                  id="choices-gender"
                  value={form && form.severity && form.severity}
                  onChange={(e) => setSeverity("severity", e.target.value)}
                  isInvalid={!!Errors.severity}
                  isValid={valids.severity && valids.severity === true}
                >
                  <option value="" selected>
                    Select Severity
                  </option>
                  {severityOpt &&
                    (severityOpt !== undefined || severityOpt !== null) &&
                    severityOpt?.split(",").map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {Errors.severity}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-lg-6 col-12">
              <Form.Group className="mt-2">
                <Form.Label>
                  Expected Vulnerability Types
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  className="m-0"
                  type="text"
                  name="ExpectedVulnerabilityTypes"
                  placeholder="Enter Expected Vulnerability Types"
                  value={
                    form &&
                    form.expected_vulnerability_types &&
                    form.expected_vulnerability_types
                  }
                  onChange={(e) =>
                    setExpectedVulType(
                      "expected_vulnerability_types",
                      e.target.value
                    )
                  }
                  isInvalid={!!Errors.expected_vulnerability_types}
                  isValid={
                    valids.expected_vulnerability_types &&
                    valids.expected_vulnerability_types === true
                  }
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {Errors.expected_vulnerability_types}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row mt-3">
            <Form.Label>Rewards <span style={{ color: "red" }}>*</span></Form.Label>
            <div className="col-lg-1">
              <Form.Group className="mt-0">
                <Form.Control
                  readOnly
                  className="m-0 inpPriority"
                  type="text"
                  name="p1"
                  value="P1"
                // placeholder="P1"
                ></Form.Control>
              </Form.Group>
            </div>
            <div className="col-lg-4">
              <Form.Group className="mt-0">
                <Form.Control
                  className="m-0"
                  type="number"
                  name="p1"
                  placeholder="Enter Reward Amount"
                  value={form && form.p1_reward_amt && form.p1_reward_amt}
                  onChange={(e) =>
                    setRewardAmt1("p1_reward_amt", e.target.value)
                  }
                  isInvalid={!!Errors.p1_reward_amt}
                  isValid={
                    valids.p1_reward_amt && valids.p1_reward_amt === true
                  }
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {Errors.p1_reward_amt}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-lg-1">
              <button
                onClick={handleRewardInput}
                style={{ borderRadius: "8px", textWrap: "nowrap" }}
                className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
              >
                Add +
              </button>
            </div>
          </div>

          <div className="row">
            {rewardInput.map((input, index) => (
              <div key={index} className="d-flex">
                <div className="col-lg-1 pe-4">
                  <Form.Group className="mt-5">
                    <Form.Control
                      readOnly
                      className="m-0 inpPriority"
                      type="text"
                      name="p3"
                      value={"P" + (1 + index + 1)}
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mt-5">
                    <Form.Control
                      className="m-0"
                      type="number"
                      name="rewardamount3"
                      placeholder="Enter Reward Amount"
                      value={input}
                      key={index}
                      onChange={(e) => handleRewardChange(index, e)}
                      isValid={input && input.value !== "" ? true : false}
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className="col-lg-1 mt-5">
                  <button
                    onClick={(e) => deleteRewardInput(e, index)}
                    style={{ borderRadius: "8px" }}
                    className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                  >
                    X
                  </button>
                </div>
              </div>
            ))}

            <div className="col-lg-5">
              <Form.Group className="mt-3">
                <Form.Label>
                  Maximun Budget (USD) <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  className="m-0"
                  type="number"
                  name="rewardamount"
                  placeholder="Enter Reward Amount"
                  value={form && form.maximun_budget && form.maximun_budget}
                  onChange={(e) =>
                    setMaximumBudget("maximun_budget", e.target.value)
                  }
                  isInvalid={!!Errors.maximun_budget}
                  isValid={
                    valids.maximun_budget && valids.maximun_budget === true
                  }
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {Errors.maximun_budget}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group className="mt-3">
                <Form.Label>
                  Start Date <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  className="m-0"
                  type="date"
                  name="startdate"
                  value={form && form.start_date && form.start_date}
                  onChange={(e) => setStartDate("start_date", e.target.value)}
                  isInvalid={!!Errors.start_date}
                  isValid={valids.start_date && valids.start_date === true}
                />
                <Form.Control.Feedback type="invalid">
                  {Errors.start_date}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group className="mt-3">
                <Form.Label>
                  End Date <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  className="m-0"
                  type="date"
                  name="enddate"
                  value={form && form.end_date && form.end_date}
                  onChange={(e) => setEndDate("end_date", e.target.value)}
                  isInvalid={!!Errors.end_date}
                  isValid={valids.end_date && valids.end_date === true}
                />
                <Form.Control.Feedback type="invalid">
                  {Errors.end_date}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row mb-3 mt-4">
            <Form.Group as={Col} md="12">
              <Form.Label className="form-label" style={{ fontSize: "14px" }}>
                Types of Testing Allowed <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <div className="form-control  languageFrameworkDiv">
                {typeOfTestings.map((item, index) => {
                  return (
                    <div key={index} className="LfElement">
                      <span>{item}</span>
                      <span
                        onClick={(val) => deleteTota(item)}
                        style={{ marginLeft: "10px" }}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                  );
                })}

                <input
                  type="text"
                  placeholder="Add Type of Testing Allowed"
                  value={typeOfTestingInput}
                  onChange={(e) => setTypeOfTestingInput(e.target.value)}
                  onKeyDown={addTota}
                />
              </div>
              {!!Errors.typeOfTestings &&
                <div className="invalid-warning">
                  {Errors.typeOfTestings}
                </div>
              }
            </Form.Group>
          </div>
          <div className="row mb-3 mt-4">
            <div className="col-6">
              <Form.Label className="form-label" style={{ fontSize: "14px" }}>
                Languages & Frameworks <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <div className="form-control  languageFrameworkDiv">
                {languagesFrameworks.map((item, index) => {
                  return (
                    <div key={index} className="LfElement">
                      <span>{item}</span>
                      <span
                        onClick={(val) => deleteLF(item)}
                        style={{ marginLeft: "10px" }}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                  );
                })}
                <input
                  type="text"
                  placeholder="Add Language or Framework"
                  value={languagesFrameworksInput}
                  onChange={(e) => setLanguagesFrameworksInput(e.target.value)}
                  onKeyDown={addLanguageFrameworks}
                />
              </div>
              {Errors.languagesFrameworks &&
                <div className="invalid-warning">
                  {Errors.languagesFrameworks}
                </div>
              } </div>
            <div className="col-6">
              <Form.Label className="form-label " style={{ fontSize: "14px" }}>
                Asset Environments <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <div className="form-control  languageFrameworkDiv">
                {assetEnvironments.map((item, index) => {
                  return (
                    <div key={index} className="LfElement">
                      <span>{item}</span>
                      <span
                        onClick={(val) => deleteAe(item)}
                        style={{ marginLeft: "10px" }}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                  );
                })}
                <input
                  type="text"
                  placeholder="Add Asset Environments"
                  value={assetEnvironmentsInput}
                  onChange={(e) => setAssetEnvironmentsInput(e.target.value)}
                  onKeyDown={addAE}
                />
              </div>
              {Errors.assetEnvironments &&
                <div className="invalid-warning">
                  {Errors.assetEnvironments}
                </div>
              }
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <Form.Group className="mt-3">
                <div className="d-flex justify-content-between">
                  <Form.Label>Taxonomy Documents</Form.Label>
                  <p className="form-text text-muted text-xs">
                    Max Upload Sixe : 10 MB
                  </p>
                </div>
                <div className="form-control dotted">
                  <div className="file-upload">
                    <p
                      className="file-name"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Click to upload files.
                    </p>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={(e) => {
                        if (!taxanomyTypes.includes(e.target.files[0]?.type)) {
                          return customToast.error(`File ${e.target.files[0]?.type} not supported`, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                          })
                        }

                        handleFileChange(e, setTaxOfFiles, 5, 5 * 1024 * 1024)
                      }
                      }
                      accept=".xls, .xlsx, .doc, .docx"
                      isInvalid={!!Errors.taxOfFiles}
                      isValid={
                        valids.taxOfFiles && valids.taxOfFiles === true
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {Errors.taxOfFiles}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <p className="form-text text-muted text-xs ms-1 mt-2">
                  * file formats allowed (xls, xlsx, doc, docx)
                </p>
                <div>
                  {taxOfFiles.length > 0 && (
                    <ul className="file-list">
                      {taxOfFiles.map(
                        (file, index) =>
                          file !== "" && (
                            <li key={index} className="file-item">
                              <span className="file-name">
                                {file.name || file}
                              </span>
                              {file !== "" && (
                                <i
                                  onClick={() =>
                                    deleteFile(index, setTaxOfFiles, taxOfFiles)
                                  }
                                  style={{ borderRadius: "8px", cursor: 'pointer' }}
                                  className="fa-solid fa-trash text-primary"
                                >
                                </i>
                              )}
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </div>
              </Form.Group>
            </div>

            <div className="col-lg-6">
              <Form.Group className="mt-3">
                <div className="d-flex justify-content-between">
                  <Form.Label>Upload Documents</Form.Label>
                  <p className="form-text text-muted text-xs">
                    Max Upload Size : 10 MB
                  </p>
                </div>
                <div className="form-control dotted">
                  <div className="file-upload">
                    <p
                      className="file-name"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Click to upload files.
                    </p>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={(e) => {
                        if (!acceptedFileTypes.includes(e.target.files[0]?.type)) {
                          return customToast.error(`File ${e.target.files[0]?.type} not supported`, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                          })
                        }
                        handleFileChange(e, setAttchFiles, 5, 10 * 1024 * 1024)
                      }
                      }
                      isInvalid={!!Errors.attachFiles}
                      isValid={
                        valids.attachFiles && valids.attachFiles === true
                      }
                      accept=".jpg, .png, .webp, .pdf, .xls, .xlsx, doc, docx, .csv, .ppt"
                    />
                    <Form.Control.Feedback type="invalid">
                      {Errors.attachFiles}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <p className="form-text text-muted text-xs ms-1 mt-2">
                  * file formats allowed (.jpg, .png, webp, pdf, xls,xlsx, doc,
                  docx, .csv, .ppt)
                </p>
                <div>
                  {attachFiles.length > 0 && (
                    <ul className="file-list">
                      {attachFiles.map(
                        (file, index) =>
                          file !== "" && (
                            <li key={index} className="file-item">
                              <span className="file-name">
                                {file.name || file}
                              </span>
                              {file !== "" && (

                                <i className="fa-solid fa-trash text-primary" onClick={() =>
                                  deleteFile(index, setAttchFiles, attachFiles)
                                }
                                  style={{ borderRadius: "8px", cursor: 'pointer' }}
                                ></i>
                              )}
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </div>
              </Form.Group>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-4">
            <Link
              to="/admin/program/dashboard"
              type="button"
              name="button"
              className="btn bg-gradient-dark m-0"
            >
              Cancel
            </Link>
            <button
              type="submit"
              name="button"
              className="btn loginbtn m-0 ms-2 "
              onClick={handleFinalClick}
            >
              Create Program
            </button>
          </div>
        </div>
      </div>

      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"SUBMIT"}
        createAddTopic={"Add Program"}
        confirmText={"Add The Program"}
        isOpen={openAddModal}
        onSubmit={finalSubmit}
        onClose={() => setOpenAddModal(false)}
      />
    </>
  );
}
