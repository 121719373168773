import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import HTTPService from "../../utils/axios";
import logo from "../assets/logo.png";
import "../resetpassword/Resetpassword.css";
import Infoicon from "./info.svg";
import Staricon from "./Star.svg";
import { customToast } from "../../App";

export default function Resetpassword() {
	const navigate = useNavigate();
	const [validated, setValidated] = useState(false);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [passwordType, setPasswordType] = useState("password");
	const [confirmPasswordType, setConfirmPasswordType] = useState("password");
	const [passwordIcon, setPasswordIcon] = useState(eyeOff);
	const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(eyeOff);
	const [isValidPassword, setIsValidPassword] = useState(true);
	const [isPasswordMatch, setIsPasswordMatch] = useState(true);
	const { invokeApi } = HTTPService();
	const { code, token } = useParams();

	const handlePasswordToggle = () => {
		setPasswordType(passwordType === "password" ? "text" : "password");
		setPasswordIcon(passwordType === "password" ? eye : eyeOff);
	};

	const handleConfirmPasswordToggle = () => {
		setConfirmPasswordType(confirmPasswordType === "password" ? "text" : "password");
		setConfirmPasswordIcon(confirmPasswordType === "password" ? eye : eyeOff);
	};

	const handlePasswordChange = (e) => {
		const newPassword = e.target.value;
		setPassword(newPassword);
		validatePassword(newPassword);
		setIsPasswordMatch(newPassword === confirmPassword);
	};

	const handleConfirmPasswordChange = (e) => {
		const newConfirmPassword = e.target.value;
		setConfirmPassword(newConfirmPassword);
		setIsPasswordMatch(newConfirmPassword === password);
	};

	const validatePassword = (password) => {
		const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
		setIsValidPassword(passwordPattern.test(password));
	};

	const handalSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		if (!password) {
			customToast.error("Please enter in password field", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
			});
			return null;
		}
		if (!confirmPassword) {
			customToast.error("Please enter in confirm password field", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
			});
			return null;
		}

		if (password !== confirmPassword) {
			customToast.error("Both password and confirm password are not same", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
			});
			return null;
		}

		if (!isValidPassword) {
			customToast.error("Password does not meet the criteria", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
			});
			return null;
		}

		invokeApi({
			method: "POST",
			url: `/reset_password/${code}/${token}/`,
			data: {
				password,
				confirm_password: confirmPassword,
			},
		})
			.then((res) => {
				customToast.success("Password changed successfully", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
				});
				setTimeout(() => navigate("/"), 2000);
			})
			.catch((error) => {
				customToast.error("Please try again later", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
				});
			});
		setValidated(true);
	};

	return (
		<>
			<div className="container">
				<Link to="/">
					<div className="top-content">
						<img className="logo" src={logo} alt="logo" width="20%" />
					</div>
				</Link>
				<div
					className="row justify-content-center"
					style={{ marginTop: "171px!important" }}
				>
					<div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
						<div className="card z-index-0">
							<div className="main-welcome mb-3">
								<h4 className="main-welcome-head mb-0">Reset your Password</h4>
							</div>
							<div className="card-body pt-0">
								<Form
									className="text-start"
									noValidate
									validated={validated}
									onSubmit={handalSubmit}
								>
									<Row className="mb-3">
										<Form.Group
											className="position-relative"
											as={Col}
											md="12"
											controlId="validationCustom03"
										>
											<Form.Label>Password</Form.Label>
											<Form.Control
												type={passwordType}
												placeholder="New Password"
												value={password}
												onChange={handlePasswordChange}
												isInvalid={!isValidPassword}
												required
											/>
											<span
												className="flex justify-around items-center position-absolute"
												id="eyeicon"
												style={{ right: '50px' }}
												onClick={handlePasswordToggle}
											>
												<Icon
													className="absolute mr-12"
													icon={passwordIcon}
													size={25}
												/>
											</span>
											<Form.Control.Feedback type="invalid">
												Password does not meet the criteria.
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row className="mb-3">
										<Form.Group
											className="position-relative"
											as={Col}
											md="12"
											controlId="validationCustom03"
										>
											<Form.Label>Confirm Password</Form.Label>
											<Form.Control
												type={confirmPasswordType}
												placeholder="Confirm Password"
												value={confirmPassword}
												onChange={handleConfirmPasswordChange}
												isInvalid={!isPasswordMatch}
												required
											/>
											<span
												className="flex justify-around items-center position-absolute"
												id="eyeicon"
												style={{ right: '50px' }}
												onClick={handleConfirmPasswordToggle}
											>
												<Icon
													className="absolute mr-12"
													icon={confirmPasswordIcon}
													size={25}
												/>
											</span>
											<Form.Control.Feedback type="invalid">
												Passwords do not match.
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row className="mb-3">
										<div className="suggestion-pass">
											<ul className="list-unstyled">
												<li>
													<div className="d-flex">
														<img
															className="info-icon"
															src={Infoicon}
															alt="info-icon"
														/>
														<strong>Suggestions for a Strong Password:</strong>
													</div>
												</li>
												<li>
													<div className="star mt-2">
														<img
															className="info-icon"
															src={Staricon}
															alt="info-icon"
														/>
														<p className="m-0">
															A Combination of Upper and Lowercase Letters,
															Numbers & Special Characters (Allowed One Only )
														</p>
													</div>
												</li>
												<li>
													<div className="star mt-2">
														<img
															className="info-icon"
															src={Staricon}
															alt="info-icon"
														/>
														<p className="m-0">At least 8 Characters or More</p>
													</div>
												</li>
											</ul>
										</div>
									</Row>
									<div className="text-center">
										<Button type="submit" className="loginbtn w-100 my-4 mb-2">
											Submit
										</Button>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer />
		</>
	);
}
