import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Signup from './Signup'

const CustomerSignup = () => {
  return (
		<>
			<Header />
			<Signup loginLink="/customer/login" />
			<Footer />
		</>
	);
}

export default CustomerSignup
