import React, { useEffect, useState } from "react";
import { useParams, Outlet, Link, NavLink, useLocation } from "react-router-dom";
import rocket from "../../assets/svg/rocket.svg";
import basic from "../../assets/svg/basic.svg";
import box from "../../assets/svg/box.svg";
import fa from "../../assets/svg/fa.svg";
import account from "../../assets/svg/account.svg";
import settings from "../../assets/svg/setting.svg";
import { HashLink } from "react-router-hash-link";
import Basicinfo from "../../common/Basicinfo";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import useHttp from "../../Hooks/useHttp";
import ProfileImage from "../../../components/ProfileImage";
import { FaUserCircle } from "react-icons/fa";

export default function ResearcherEdit() {
	const API = process.env.REACT_APP_API_URL
	const [userData, setUserData] = useState([])
	const { userId, type } = useParams();
	const token = localStorage.getItem("token");
	const { GetRequest } = useHttp();
	const location = useLocation();
	const fetchUserData = async () => {
		const { data, error } = await GetRequest(
			API + "researcher/" + userId + "/",
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		if (error) {
			console.log(error)
		} else {
			setUserData(data)
			console.log("userdata", data)
		}
	};
	useEffect(() => {
		fetchUserData()
	}, [userId])

	const Breadcrumbname = {
		home: "/researcher/home",
		"Edit Profile": "",
	};

	return (
		<>
			<div className="bg-gray-100">
				<main className="position-relative mt-0">
					<div
						className="container-fluid pt-4  "
						style={{ background: "#f5f5fb" }}
					>
						<div className="row">
							<div className="col-lg-6 ps-3">
								<Breadcrumb names={Breadcrumbname} />
							</div>
						</div>
						<div className="row align-items-center">
							<div className="col-lg-4 col-sm-8">
								<div className="nav-wrapper position-relative end-0">
									<ul className="nav nav-pills nav-fill p-1" role="tablist">
										<li className="nav-item" role="presentation">
											<Link
												className={
													location.pathname ===
														`/researcher/user/edit/${userId}/${type}`
														? "nav-link mb-0 px-0 py-1 active"
														: "nav-link mb-0 px-0 py-1"
												}
												to={`/researcher/user/edit/${userId}/${type}`}
												aria-selected="false"
											>
												Profile
											</Link>
										</li>
										<li className="nav-item" role="presentation">
											<Link
												className={
													location.pathname ===
														`/researcher/user/edit/${userId}/${type}/addskill`
														? "nav-link mb-0 px-0 py-1 active"
														: "nav-link mb-0 px-0 py-1"
												}
												to={`/researcher/user/edit/${userId}/${type}/addskill`}
												aria-selected="false"
											>
												Skills
											</Link>
										</li>
										<li className="nav-item">
											<Link
												className={
													location.pathname ===
														`/researcher/user/edit/${userId}/${type}` +
														"/kyc"
														? "nav-link mb-0 px-0 py-1 active"
														: "nav-link mb-0 px-0 py-1"
												}
												to={`/researcher/user/edit/${userId}/${type}/kyc`}
												aria-selected="false"
											>
												KYC
											</Link>
										</li>
										<li className="nav-item">
											<Link
												className={
													location.pathname ===
														`/researcher/user/edit/${userId}/${type}/paymentinformation`
														? "nav-link mb-0 px-0 py-1 active"
														: "nav-link mb-0 px-0 py-1"
												}
												to={`/researcher/user/edit/${userId}/${type}/paymentinformation`}
												aria-selected="false"
											>
												Payment Information
											</Link>
										</li>
										<li className="nav-item">
											<Link
												className={
													location.pathname ===
														`/researcher/user/edit/${userId}/${type}/social`
														? "nav-link mb-0 px-0 py-1 active"
														: "nav-link mb-0 px-0 py-1"
												}
												to={`/researcher/user/edit/${userId}/${type}/social`}
												aria-selected="false"
											>
												Social
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="container-fluid my-3 py-3 mb-0 p-0">
							<div className="row mb-5">
								<div className="col-12 mb-4">
									{/* <!-- Card Profile --> */}
									<div className="card card-body" id="profile">
										<div className="row justify-content-center align-items-center">
											<div className="col-sm-auto col-4">
												<div className="avatar avatar-xl position-relative">
													<ProfileImage src={userData?.display_pic} errorIcon={<FaUserCircle style={{ fontSize: '3rem', color: '#7f56d9 ' }} />} className="w-100 border-radius-lg shadow-sm" />

												</div>
											</div>
											<div className="col-sm-auto col-8 my-auto">
												<div className="h-100">
													<h5 className="mb-1 font-weight-bolder">
														{userData?.first_name} {userData?.last_name}
													</h5>
													<p className="mb-0 font-weight-bold text-sm">
														{userData?.user_id} ({userData?.username})
													</p>
												</div>
											</div>
											<div className="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
												{/* <label className="form-check-label mb-0">
													<small id="profileVisibility">
														Switch to invisible
													</small>
												</label>
												<div className="form-check form-switch ms-2">
													<input
														className="form-check-input"
														type="checkbox"
														id="flexSwitchCheckDefault23"
														checked
														onChange="visible()"
													/>
												</div> */}
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-3">
									<div className="card position-sticky top-1">
										<ul className="nav flex-column bg-white border-radius-lg p-3">
											<li className="nav-item">
												<HashLink
													smooth
													className="nav-link text-body"
													data-scroll=""
													to={`/researcher/user/edit/${userId}/${type}/basicinfo/#profile`}
												>
													<div className="icon me-2">
														<img src={rocket} alt="" />
													</div>
													<span className="text-sm">Profile</span>
												</HashLink>
											</li>
											<li className="nav-item pt-2">
												<HashLink
													className="nav-link text-body"
													data-scroll=""
													to={`/researcher/user/edit/${userId}/${type}/basicinfo/#basic-info`}
												>
													<div className="icon me-2">
														<img src={basic} />
													</div>
													<span className="text-sm">Basic Info</span>
												</HashLink>
											</li>
											<li className="nav-item pt-2">
												<HashLink
													className="nav-link text-body"
													data-scroll=""
													to={`/researcher/user/edit/${userId}/${type}/basicinfo/#password`}
												>
													<div className="icon me-2">
														<img src={box} />
													</div>
													<span className="text-sm">Change Password</span>
												</HashLink>
											</li>
											{/* <li className="nav-item pt-2">
												<HashLink
													className="nav-link text-body"
													data-scroll=""
													to={`/researcher/user/edit/${userId}/${type}/basicinfo/#2fa`}
												>
													<div className="icon me-2">
														<img src={fa} />
													</div>
													<span className="text-sm">2FA</span>
												</HashLink>
											</li> */}
											{/* <li className="nav-item pt-2">
													<HashLink
														className="nav-link text-body"
														data-scroll=""
														to={`/researcher/user/edit/${userId}/${type}/basicinfo/#accounts`}
													>
														<div className="icon me-2">
															<img src={account} />
														</div>
														<span className="text-sm">Accounts</span>
													</HashLink>
												</li>
												<li className="nav-item pt-2">
													<HashLink
														className="nav-link text-body"
														data-scroll=""
														to={`/researcher/user/edit/${userId}/${type}/basicinfo/#notifications`}
													>
														<div className="icon me-2">
															<img src={notification} />
														</div>
														<span className="text-sm">Notifications</span>
													</HashLink>
												</li> */}
											{/* <li className="nav-item pt-2">
												<HashLink
													className="nav-link text-body"
													data-scroll=""
													to={`/researcher/user/edit/${userId}/basicinfo/#active`}
												>
													<div className="icon me-2">
														<img src={settings} />
													</div>
													<span className="text-sm">
														Active / In-Active Account
													</span>
												</HashLink>
											</li> */}
										</ul>
									</div>
								</div>
								<div className="col-lg-9 mt-lg-0 mt-4">
									{location.pathname === "/researcher/user/edit/" + userId ||
										location.pathname === `/researcher/user/edit/${userId}/${type}` ? (
										<Basicinfo userData={userData} />
									) : (
										<Outlet />
									)}
								</div>
							</div>
						</div>
					</div>
				</main >
			</div >
		</>
	);
}
