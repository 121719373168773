import React, { useEffect, useState } from 'react'
import useHttp from '../../../Hooks/useHttp';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';
import ViewInfo from '../../../common/ViewInfo';
import HTTPService from '../../../../utils/axios';
import { customToast } from '../../../../App';


export default function ViewNavigation() {
	const { GetRequest } = useHttp();
	const [CMSNavigationData, setCMSNavigationData] = useState({});
	const [CMSData, setCMSData] = useState({});
	const { invokeApi } = HTTPService();

	const API = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem("token");

	const navigate = useNavigate();

	const { id } = useParams();

	console.log("Faqa id " + id);




	const fetchCMSNavigation = async () => {
		invokeApi({
			method: "GET",
			url: `cms_navigation/${id}/`,
		})
			.then((res) => {
				setCMSNavigationData(res);
			})
			.catch((error) => {
				customToast.error("Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			});


	};

	//const CMSID=CMSNavigationData.page_id;
	const fetchCMS = async () => {
		if (!CMSNavigationData || !CMSNavigationData.page_id) {
			return;
		}


		invokeApi({
			method: "GET",
			url: `cms_pages/${CMSNavigationData.page_id}/`,
		})
			.then((res) => {
				setCMSData(res);
			})
			.catch((error) => {
				customToast.error("Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			});
	};


	useEffect(() => {
		fetchCMSNavigation();
		fetchCMS();
	}, [id, CMSNavigationData.page_id]);

	const Title = "View CMS Navigation"
	const option = ["CMS NAVIGATION ID", "CMS PAGE & ID", "SHOW ON", 'Sequence'];

	const cmsOverview = CMSData && CMSData.page_title + " " + CMSData && CMSData.cms_page_id;
	const FaqCategoryValue = [CMSNavigationData.cms_navigation_id, cmsOverview, CMSNavigationData.show_on, CMSNavigationData.sequence]


	const Breadcrumbname = {
		home: "/admin/home",
		MasterModule: "",
		FaqCategory: "/admin/master-module",
		"faq-sub-category": "",
	};



	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row w-100 d-flex justify-content-center">
					<div className="addCategoryArea  my-4">
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div className="col-lg-7 ps-3">
								<Breadcrumb names={Breadcrumbname} />
							</div>

							<div className="button-row d-flex justify-content-end mt-0 mb-3">
								<Link
									to="/admin/cms-navigation"
									className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
									id="adduser"
								>
									VIEW ALL
								</Link>
							</div>
						</div>


						<div className="col-12">
							<div className="card" style={{ backgroundColor: "white" }}>
								<ViewInfo itemValue={FaqCategoryValue} option={option} title={Title} />


								<div className=" d-flex justify-content-end p-4">
									<button className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3" onClick={() => navigate(-1)}>
										Back
									</button>
									<button
										type="button"
										className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
										onClick={() => navigate(`/admin/cms-navigation/edit/${id}`)}
									>
										Edit
									</button>
								</div>
							</div>
						</div>





					</div>
				</div>
			</div>
		</>
	)
}
