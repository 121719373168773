import React from "react";

import { Link } from "react-router-dom";
import downarrowdark from "../../assets/svg/downarrow.svg";

import menudata from "../menudata/menudata.json";

const MasterModule = (props) => {
	const userType = props.usertype;

	const userRole = Object.entries(menudata).filter(
		([key, value]) => key === userType
	);
	return (
		<>
			<li className="nav-item dropdown dropdown-hover">
				<Link
					className="nav-link d-flex justify-content-between cursor-pointer align-items-center"
					to="#"
					role="button"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="#7F56D9"
						className="bi bi-shield-shaded me-2"
						viewBox="0 0 16 16"
					>
						<path
							fillRule="evenodd"
							d="M8 14.933a1 1 0 0 0 .1-.025q.114-.034.294-.118c.24-.113.547-.29.893-.533a10.7 10.7 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.8 11.8 0 0 1-2.517 2.453 7 7 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7 7 0 0 1-1.048-.625 11.8 11.8 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 63 63 0 0 1 5.072.56"
						/>
					</svg>
					Master Module
					<img
						src={downarrowdark}
						alt="down-arrow"
						className="arrow ms-1 d-lg-block d-none"
					/>
					<img
						src={downarrowdark}
						alt="down-arrow"
						className="arrow ms-1 d-lg-none d-block"
					/>
				</Link>
				<div
					className="dropdown-menu dropdown-menu-animation dropdown-md dropdown-md-responsive p-3 border-radius-lg mt-0 mt-lg-2"
					aria-labelledby="dropdownMenuBlocks"
				>
					<div className="d-none d-lg-block">
						<ul className="list-group">
							{userRole[0][1] && userRole[0][1]?.masterModule && userRole[0][1].masterModule.submenu.map((item, index) => {
								if ((props?.overAllPermissions && props?.overAllPermissions[item.name]?.view) || ['admin', 'customer', 'researcher'].includes(userType))
									return (
										<li key={index} className="nav-item dropdown dropdown-hover dropdown-subitem list-group-item border-0 p-0">
											<Link
												className="dropdown-item py-2 ps-3 border-radius-md"
												to={item.link}
											>
												<div className="d-flex">
													<div className="icon h-10 me-3 d-flex mt-1">
														<i className={item.icon}></i>
													</div>
													<div className="w-100 d-flex align-items-center justify-content-between">
														<div>
															<p className="dropdown-header text-dark p-0">
																{item.name}
															</p>
														</div>
													</div>
												</div>
											</Link>
										</li>
									);
							})}
						</ul>
					</div>
				</div>
			</li>
		</>
	);
};

export default MasterModule;
