import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React from "react";

function PrgmSkeletonLoader({ card }) {
	return Array(card)
		.fill(0)
		.map((item, index) => (
			<div key={index} className="col-3 ">
				<div className="customer-name-status">
					<div className="row mt-3 mb-3 w-100 d-flex justify-content-between  align-items-center">
						<div className="col-5">
							<span className="customerName">
								<Skeleton style={{ height: "25px" }} />
							</span>
						</div>
						<div className="col-6 d-flex justify-content-end align-items-center">
							<Skeleton
								style={{
									borderRadius: "20px",
									overflow: "hidden",
									height: "40px",
									width: "120px",
								}}
							/>
						</div>
					</div>
					<div className="row mb-3 w-100 d-flex justify-content-center">
						<Skeleton style={{ height: "125px", borderRadius: "20px" }} />
					</div>

					<div className="row bug-bounty-demo">
						<hr />
						<div className="d-flex justify-content-center align-items-center">
							<Skeleton
								circle
								style={{
									height: "30px",
									width: "30px",
									marginRight: "8px",
								}}
							/>
							<div>
								<Skeleton style={{ height: "18px", width: "200px" }} />
							</div>
						</div>
						<div className="text-center  ">
							<h5
								style={{
									marginTop: "8px",
								}}
							>
								<Skeleton style={{ height: "18px", width: "80%" }} />
							</h5>
						</div>
						<hr />
					</div>

					<div className="row">
						<div className="mb-3 row d-flex justify-content-center align-items-center">
							<div className="col-1">
								{/* <i className="fa-solid fa-id-badge"></i> */}
								<Skeleton style={{ width: "14px" }} />
							</div>
							<div className="col-3">
								<Skeleton />
							</div>
							<div className="col-7">
								<Skeleton />
							</div>
						</div>
						<div className="mb-3 row d-flex justify-content-center align-items-center">
							<div className="col-1">
								{/* <i className="fa-solid fa-id-badge"></i> */}
								<Skeleton style={{ width: "14px" }} />
							</div>
							<div className="col-3">
								<Skeleton />
							</div>
							<div className="col-7">
								<Skeleton />
							</div>
						</div>
						<div className="mb-3 row d-flex justify-content-center align-items-center">
							<div className="col-1">
								{/* <i className="fa-solid fa-id-badge"></i> */}
								<Skeleton style={{ width: "14px" }} />
							</div>
							<div className="col-3">
								<Skeleton />
							</div>
							<div className="col-7">
								<Skeleton />
							</div>
						</div>
						<div className="mb-3 row d-flex justify-content-center align-items-center">
							<div className="col-1">
								{/* <i className="fa-solid fa-id-badge"></i> */}
								<Skeleton style={{ width: "14px" }} />
							</div>
							<div className="col-3">
								<Skeleton />
							</div>
							<div className="col-7">
								<Skeleton />
							</div>
						</div>
						<div className="mb-3 row d-flex justify-content-center align-items-center">
							<div className="col-1">
								{/* <i className="fa-solid fa-id-badge"></i> */}
								<Skeleton style={{ width: "14px" }} />
							</div>
							<div className="col-3">
								<Skeleton />
							</div>
							<div className="col-7">
								<Skeleton />
							</div>
						</div>
					</div>

					<hr />

					<div className="row w-100 d-flex justify-content-end px-4 mb-3">
						<Skeleton
							style={{
								width: "65%",
								height: "40px",
								borderRadius: "12px",
								marginLeft: "35%",
							}}
						/>
					</div>
				</div>
			</div>
		));
}

export default PrgmSkeletonLoader;
