import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import HTTPService from "../../../../utils/axios";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import AppContext from "../../../Context/AppContext";
import { customToast } from "../../../../App";
export default function AddVTxTaxonomy({
  isOpen,
  onClose,
  onSubmit,
  modalTitle,
  confirmText,
  cancelBtn,
}) {
  const { setCircularLoading } = useContext(AppContext);
  const [file, setFile] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const { invokeApi } = HTTPService();
  const setSubmissionVtx = () => {
    setCircularLoading(true);
    let formData = new FormData();
    formData.append("file", file);
    invokeApi({
      method: "POST",
      url: `submissions-vtx`,
      data: formData,
    })
      .then((data) => {
        setCircularLoading(false);
        onSubmit();
        setFile();
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setCircularLoading(false);
      });
  };

  const handleSubmit = () => {
    setSubmissionVtx();
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Handle the files here
    console.log(acceptedFiles);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  return (
    <>
      {isOpen && (
        <div className="dragDropModal">
          <div className="dragDropModalBody">
            <div className="dragDropModalBodyTop">
              <h6 style={{ color: "#A867AC" }}>{modalTitle}</h6>
              <div>
                {" "}
                <button
                  className="btn btn-xs  me-3 mb-2 btn-secondary mb-0 text-capitalize"
                  onClick={onClose}
                >
                  CLOSE
                </button>
              </div>
            </div>

            <div className="vtxModalcontent">
              <div className="vtxModalcontentLeft">
                <a
                  href={`/assets/files/Sample VTx Taxonomy Upload.xlsx`}
                  target="_blank"
                  download
                >
                  <i className="fa-solid fa-download fa-2x"></i>
                  Download Sample
                </a>
              </div>
              <div>
                <div className="ModalSubHeading">
                  <h6 style={{ fontWeight: 900 }}>Add Bulk Upload File</h6>
                  <p style={{ fontSize: "14px" }}>Max file Size: 10MB</p>
                </div>
                <div
                  {...getRootProps({ className: "dropzone" })}
                  className="dragDropArea"
                >
                  <input {...getInputProps()} />
                  <div>
                    <div className="text-center">{file && file?.name}</div>
                    <div className="text-center">
                      Drag 'n' drop some file here, or click to select file
                    </div>
                  </div>
                </div>
                <p style={{ textAlign: "center", fontSize: "13px" }}>
                  *File Format Allowed &#40;.xls , .xlsx &#41;{" "}
                </p>
              </div>
            </div>

            <div className="FooterDragFile">
              <div className="elegibleDesc">
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#96394C",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  ** Kindly Make Sure You Choose the Correct Main & Sub Category
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#96394C",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  ** VTx Code will be unique and can not be repate.
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#96394C",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  ** Data Once Upload Cannot be Detele or Alter Further.
                </p>
              </div>
              <div className="dragDropModalBodyLastPart">
                <button
                  style={{ backgroundColor: "#ccc" }}
                  className="btn  me-2 mb-0 text-capitalize"
                  onClick={onClose}
                >
                  {cancelBtn}
                </button>
                <button
                  onClick={handleSubmit}
                  className="btn btn-xs bg-gradient-dark me-2 mb-0 text-capitalize"
                >
                  UPLOAD FILE
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
