import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import avatarimg from "../../assets/img/user-default.png";

export default function AvatarMenu({
  userdata,
  userFound,
  imageError,
  setImageError,
  type,
  userType,
  Logout,
  setScreenLock,
}) {
  const [avatermenu, setAvatermenu] = useState(false);
  const menuRef = useRef();
  const isSupportEnabled = localStorage.getItem('supporttoken')

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setAvatermenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="nav-link d-flex align-items-center font-weight-bold px-0"
      style={{ position: "relative" }}
      onClick={() => setAvatermenu(!avatermenu)}
      ref={menuRef}
    >
      <div className="profilePhoto">
        {userFound === false || imageError ? (
          <img
            src={avatarimg}
            className="me-sm-1 rounded-circle dropdown-toggle cursor-pointer"
            alt=""
          />
        ) : (
          <img
            src={userdata?.display_pic}
            className="me-sm-1 rounded-circle dropdown-toggle cursor-pointer"
            onError={() => setImageError(true)}
            alt=""
          />
        )}
      </div>
      {userFound === false ? (
        <span
          className="d-sm-inline d-none text-primary"
          style={{ cursor: "pointer", textWrap: "nowrap" }}
        >
          username
        </span>
      ) : (
        <span
          className="d-sm-inline d-none text-primary"
          style={{ cursor: "pointer", textWrap: "nowrap" }}
        >
          {userdata?.first_name} {userdata?.last_name}
        </span>
      )}
      {avatermenu && (
        <div className="bg-white shadow-lg avatar-dropdown-menu" ref={menuRef}>
          <ul>
            <li>
              {isSupportEnabled != null ?
                <Link to={`/${type}/supportcustomers/view/${userdata?.id}`}>
                  <i className="fa fa-user pe-2 fixed-plugin-button-nav"></i>
                  View Profile
                </Link> :
                <Link to={`/${type}/${userType}/view/${userdata?.id}/header`}>
                  <i className="fa fa-user pe-2 fixed-plugin-button-nav"></i>
                  View Profile
                </Link>}
            </li>
            <li className="">
              {isSupportEnabled != null ?
                <Link
                  to={`/${type}/supportcustomers/edit/${userdata?.id}`}
                >
                  <i className="fa fa-key pe-2 fixed-plugin-button-nav"></i>
                  Change Password
                </Link> :
                <Link
                  to={`/${type}/${userType}/edit/${userdata?.id}/header/basicinfo/#password`}
                >
                  <i className="fa fa-key pe-2 fixed-plugin-button-nav"></i>
                  Change Password
                </Link>}

            </li>
            <li onClick={setScreenLock}>
              <i className="fa fa-user-lock pe-2 fixed-plugin-button-nav"></i>
              Screen Lock
            </li>
            <li>
              <Link to={`/${type}/${userType}/edit/${userdata?.id}/header`}>
                <i className="fa fa-gear pe-2 fixed-plugin-button-nav"></i>
                Setting
              </Link>
            </li>
            <li className="cursor-pointer" onClick={Logout}>
              <i className="fa fa-power-off pe-2 fixed-plugin-button-nav"></i>
              Logout
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
