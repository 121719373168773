import React, { useContext, useEffect, useState } from 'react'
import CircularLoader from '../CircularLoader/CircularLoader';
import { moduleTypeIcon, replaceMessageKeys } from '../../utils/helperFunctions';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../reducers/authSlice';
import HTTPService from '../../utils/axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { customToast } from '../../App';
import { Divider } from '@mui/material';

const Notifications = () => {
    const [loading, setLoading] = useState(true);
    const { userInfo } = useSelector(getUserInfo)
    const { invokeApi } = HTTPService();
    const [notifications, setNotifications] = useState();

    const fetchNotifications = async () => {
        setLoading(true);
        invokeApi({
            method: "GET",
            url: `/notifications/`,
        }).then((data) => {
            setNotifications(data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        });
    };

    const setNotificationAsRead = async (id) => {
        invokeApi({
            method: "PATCH",
            url: `/notifications/${id}/`,
            data: {
                "is_read": true
            }
        }).then((data) => {
            setNotifications(prev => prev.map(notify => {
                if (notify.id == id) {
                    notify.is_read = true;
                }
                return notify
            }))
            customToast.success("Marked as read", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        }).catch((error) => {
            return null;
        });
    };
    useEffect(() => {
        if (userInfo?.user_data?.id) {
            fetchNotifications()
        }
    }, [userInfo?.user_data?.id])
    return (<div>

        {loading &&
            <CircularLoader size={100} />}
        <div className='container-fluid' style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
            <h4 className='pt-4 '>Notifications</h4>
            <Divider sx={{ borderColor: '#000', marginBottom: '20px' }} />
            <div style={{ minHeight: '400px', backgroundColor: '#fff', padding: '10px', borderRadius: '10px' }}>

                {
                    notifications && notifications.length > 0 ? notifications.map((notification => <div key={notification?.id} className="mb-2 pb-2 pt-2">
                        <Link className="dropdown-item border-radius-md" to="#">
                            <div className="d-flex py-1">
                                <div className="my-auto mr-2">
                                    <div className="bg-primary"
                                        style={{
                                            borderRadius: '50%', padding: '10px', display: 'flex', justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '40px',
                                            height: '40px',
                                        }}>
                                        <i className={`${moduleTypeIcon[notification.module_type]} fontSize-2rem text-white`} ></i>
                                    </div>
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                        <span className="" style={{
                                            whiteSpace: "pre-wrap",
                                            display: "block"
                                        }}
                                            dangerouslySetInnerHTML={{ __html: replaceMessageKeys(notification?.message?.message, notification.message) }}
                                        >
                                        </span>
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 mt-1 d-flex align-items-center">
                                        <div><i className="fa fa-clock me-1 text-primary"></i></div>
                                        <div>{notification?.message?.time_stamp}</div>

                                        {
                                            !notification?.is_read &&
                                            <button onClick={() => setNotificationAsRead(notification.id)} type="button" className=' text-primary' style={{ fontWeight: 'bold', background: 'transparent' }}>
                                                Mark as read
                                            </button>
                                        }   </p>

                                </div>
                            </div>
                        </Link>
                    </div>)
                    ) : <div style={{ fontSize: '1.6rem', fontWeight: '600' }}><i className="fa-solid fa-comments"></i> No Notifications</div>
                }
            </div>
        </div>
    </div>
    )
}

export default Notifications