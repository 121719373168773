import React, { useCallback, useContext, useEffect, useState } from "react";
import "./ReportDashboard.css";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import userdata from "./Reportdata.json";
import withPermission from "../../hoc/withPermission";
import { getUserInfo } from "../../reducers/authSlice";
import { useSelector } from "react-redux";
import ReportModal from "../common/ReportModal/ReportModal";
import HTTPService from "../../utils/axios";
import moment from "moment";
import { toast } from "react-toastify";
import AppContext from "../Context/AppContext";
import Skeleton from "react-loading-skeleton";
import { debounce } from "../../utils/helperFunctions";
import { customToast } from "../../App";

const searchBy = [
  { name: "User Name", value: "username" },
  { name: "User ID", value: "user_id" },
];

function ReportDashboard() {
  const { userInfo, userAccData, permissions } = useSelector(getUserInfo);
  const [isOpen, setIsOpen] = useState(false);
  const { setLoading, loading } = useContext(AppContext);
  const { invokeApi } = HTTPService();
  const [reportList, setReportList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue, setInputValue] = useState("");

  const permission = permissions.find((perm) => perm.module === "Reports");
  const Breadcrumbname = {
    Home: "/admin/home",
    Reports: "/admin/reports-dashboard",
    "All Reports": "/admin/reports-dashboard",
    Dashboard: "/admin/reports-dashboard",
  };

  const handleDelete = async (id) => {
    invokeApi({
      method: "DELETE",
      url: `/report/${id}/`,
    })
      .then((data) => {
        setReportList(reportList.filter((obj) => obj.id !== id));
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const fetchData = async () => {
    setLoading(true);
    let params = {};
    if (searchQuery && searchQuery.length > 0) {
      params[searchValue] = searchQuery;
    }
    invokeApi({
      method: "GET",
      url: `/report/`,
      params,
    })
      .then((data) => {
        setLoading(false);
        setReportList(data.results);
      })
      .catch((error) => {
        setLoading(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [searchQuery]);

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      // setCurrentPage(1);
    }, 800),
    []
  );
  const handleSearch = (e) => {
    setInputValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    const selectedOption = searchBy.find((opt) => searchValue === opt.value);
    setSearchPlaceHolder(
      selectedOption && selectedOption.name ? selectedOption.name : ""
    );
  }, [searchValue]);

  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              {(permission?.create || userInfo.role === "admin") && (
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                  id="adduser"
                >
                  Generate New Report
                </button>
              )}
            </div>
          </div>
        </div>

        {/* <div className="w-100 mt-4 container-fluid d-flex  justify-content-end">
          <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center mt-3 mt-md-0">
            <div className="search-container w-100">
              <div className="row">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                  {loading ? (
                    <Skeleton height={40} />
                  ) : (
                    <select
                      name=""
                      className="form-select"
                      id=""
                      value={searchValue && searchValue}
                      onChange={(e) => handleSearchValue(e)}
                    >
                      <option value="">Select Search Option</option>
                      {searchBy.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center position-relative">
                  {loading ? (
                    <Skeleton height={40} width={250} />
                  ) : (
                    <>
                      <input
                        type="text"
                        placeholder={`Search by ${searchPlaceHolder}`}
                        value={inputValue}
                        onChange={handleSearch}
                        disabled={searchPlaceHolder ? false : true}
                        className="form-control"
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: 0,
                          zIndex: 100,
                          padding: "15px",
                        }}
                      >
                        <i className="fas fa-search" aria-hidden="true"></i>
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="row my-4">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table align-items-center mb-0 adminReportDashboardTable">
                  <thead>
                    <tr className="text-center">
                      <th
                        className="text-uppercase  font-weight-bolder opacity-8 text-start "
                        style={{ paddingLeft: "1.5rem" }}
                      >
                        Title
                      </th>
                      <th
                        className="text-uppercase  font-weight-bolder opacity-8 text-start"
                        style={{ paddingLeft: "1.5rem" }}
                      >
                        Type
                      </th>
                      <th
                        className="text-uppercase  font-weight-bolder opacity-8text-start "
                        style={{ paddingLeft: "1.5rem" }}
                      >
                        User ID
                      </th>
                      <th
                        className="text-uppercase  font-weight-bolder opacity-8text-start "
                        style={{ paddingLeft: "1.5rem" }}
                      >
                        Username
                      </th>
                      <th
                        className="text-uppercase  font-weight-bolder opacity-8text-start "
                        style={{ paddingLeft: "1.5rem" }}
                      >
                        User Type
                      </th>
                      <th className="text-uppercase  font-weight-bolder opacity-8 ps-2">
                        Created
                      </th>
                      <th className=" text-uppercase  font-weight-bolder opacity-8">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading
                      ? Array(6)
                        .fill()
                        .map(() => (
                          <tr className="text-center">
                            <td>
                              <p className="text-sm text-secondary mb-0 px-2 text-start">
                                <Skeleton height={20} width={230} />
                              </p>
                            </td>
                            <td>
                              <p className="text-sm text-secondary mb-0 px-2 text-start">
                                <Skeleton height={20} width={230} />
                              </p>
                            </td>
                            <td>
                              <p className="text-sm text-secondary mb-0 px-2">
                                <Skeleton height={20} width={230} />
                              </p>
                            </td>
                            {(permission?.delete ||
                              userInfo.role === "admin") && (
                                <td className="text-center">
                                  <i
                                    style={{
                                      color: "#7F56D9",
                                      cursor: "pointer",
                                    }}
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </td>
                              )}
                          </tr>
                        ))
                      : reportList &&
                      reportList.map((item, index) => (
                        <tr key={index} className="text-center">
                          <td className="text-start">
                            <p className="text-sm text-secondary mb-0 px-2">
                              {item.title}
                            </p>
                          </td>
                          <td className="text-start">
                            <p className="text-sm text-secondary mb-0 px-2">
                              {item.report_type}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm text-secondary mb-0 px-2 text-start">
                              {item.user_details.user_id}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm text-secondary mb-0 px-2 text-start">
                              {item.user_details.username}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm text-secondary mb-0 px-2 text-start">
                              {item.user_details.role}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm text-secondary mb-0 px-2">
                              {moment(item.created_at).format(
                                "YYYY-MM-DD HH:mm:ss [IST]"
                              )}
                            </p>
                          </td>
                          {(permission?.delete ||
                            userInfo.role === "admin") && (
                              <td className="text-center">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "2rem",
                                    justifyContent: "center",
                                  }}
                                >
                                  <i
                                    onClick={() => {
                                      window.open(item.report);
                                    }}
                                    style={{
                                      color: "#7F56D9",
                                      cursor: "pointer",
                                    }}
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    onClick={() => {
                                      handleDelete(item.id);
                                    }}
                                    style={{
                                      color: "#7F56D9",
                                      cursor: "pointer",
                                    }}
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </td>
                            )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {isOpen && (
          <ReportModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            user_id={userAccData.user_id}
            uid={userAccData.id}
            role={userInfo.role}
          />
        )}
      </div>
    </>
  );
}

export default withPermission(ReportDashboard);
ReportDashboard.moduleName = "Reports";
