import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import HTTPService from "../../../utils/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../reducers/authSlice";
import CircularLoader from "../../CircularLoader/CircularLoader";
import { customToast } from "../../../App";

const transformOptions = (options, type, role, uid, user_id) => {
  console.log("options", options);
  let filteredArr = [...options];
  // if (role == "customer" && type == "program") {
  //   filteredArr = options.filter((item) => item.customer === uid);
  // } else if (role == "customer" && type == "submission") {
  //   filteredArr = options.filter((item) => item.user_id === uid);
  // }
  if (role == "researcher" && type == "submission") {
    filteredArr = options.filter((item) => item.user === uid);
  } else if (role == "researcher" && type == "researcher") {
    filteredArr = options.filter((item) => item.user_id === user_id);
  } else {
    filteredArr = options;
  }
  let arr = [];
  filteredArr.map((option) => {
    if (type === "program") {
      if (filteredArr.length < 1) {
        arr.push({ label: "No Programs for user" });
      } else {
        arr.push({
          label: `${option.program_id} (${option.program_title.length > 30
            ? option.program_title.slice(0, 30) + "..."
            : option.program_title
            })`,
          value: option.id,
        });
      }
    } else if (type === "submission") {
      if (filteredArr.length < 1) {
        arr.push({ label: "No Submissions by user" });
      } else {
        arr.push({
          label: `${option.submissions_id} (${option.submission_title.length > 30
            ? option.submission_title.slice(0, 30) + "..."
            : option.submission_title
            })`,
          value: option.id,
        });
      }
    } else if (type === "researcher") {
      if (filteredArr.length < 1) {
        arr.push({ label: "No Data for user" });
      } else {
        arr.push({
          label: `${option.first_name} ${option.last_name}(${option.user_id})`,
          value: option.id,
        });
      }
    } else if (type === "researcherprogram") {
      arr.push({
        label: `${option.first_name} ${option.last_name}(${option.user_id})`,
        value: option.id,
      });
    }
  });
  return arr;
};
const ReportModal = ({ isOpen, onClose, user_id, uid, role }) => {
  const { invokeApi } = HTTPService();
  const { userInfo, permissions } = useSelector(getUserInfo);
  const [reportType, setReportType] = useState("select");
  const [selectionId, setSelectionId] = useState("");
  const [types, setTypes] = useState([]);
  const [programs, setProgams] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState("");
  const [loading, setLoading] = useState(false)
  let reportTypes = [];
  if (role != "customer") {
    reportTypes = [
      { label: "Select Report", value: "select" },
      { label: "Program Report", value: "program" },
      { label: "Report based on Submission ID", value: "submission" },
      {
        label: "Report based on Researcher",
        value: "researcher",
      },
      // { label: 'Researcher for the program', value: 'researcherprogram' },
    ];
  } else {
    reportTypes = [
      { label: "Select Report", value: "select" },
      { label: "Program Report", value: "program" },
      { label: "Report based on Submission ID", value: "submission" },
      // {
      //   label: "Report based on Researcher",
      //   value: "researcher",
      // },
      // { label: 'Researcher for the program', value: 'researcherprogram' },
    ];
  }

  const fetchData = async () => {
    let url = "";
    let params = {};
    console.log("test in--->>>", role, reportType);
    setLoading(true)
    if (reportType === "program" && role == "researcher") {
      url = "/programs/";
      params = { researcher_id: uid };
    } else if (reportType === "program") {
      url = "/programs/";
    } else if (reportType === "submission") {
      url = "/submissions/";
    } else if (reportType === "researcher") {
      url = "/researcher/";
    } else if (reportType === "researcherprogram") {
      url = "/researcher/";
    }

    try {
      const data = await invokeApi({
        method: "GET",
        url,
        params,
      });

      let submissions = data.results;

      // if (role === "customer" && reportType === "submission") {
      //   const programData = await invokeApi({
      //     method: "GET",
      //     url: "/programs/",
      //   });

      //   const programs = programData.results;

      //   submissions = submissions.map((submission) => {
      //     const matchedProgram = programs.find((program) => program.id === submission.program_id);
      //     if (matchedProgram?.user_id) {
      //       return {
      //         ...submission,
      //         user_id: matchedProgram.user_id,
      //       };
      //     }
      //   });
      // }

      setTypes(transformOptions(submissions, reportType, role, uid, user_id));
      setLoading(false)
    } catch (error) {
      setLoading(false)
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const fetchPrograms = async () => {
    let url = "/programs/";
    invokeApi({
      method: "GET",
      url,
      params: {
        // user_id: selectionId,
      },
    })
      .then((data) => {
        setProgams(transformOptions(data.results, "program"));
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    let userRole = userInfo?.role == 'sub_admin' ? 'admin' : userInfo.role
    if (reportType === "program")
      navigate(`/${userRole}/reports-dashboard/program/${selectionId}`);
    else if (reportType === "submission")
      navigate(`/${userRole}/reports-dashboard/submission/${selectionId}`);
    else if (reportType === "researcher")
      navigate(`/${userRole}/reports-dashboard/researcher/${selectionId}`);
    else if (reportType === "researcherprogram")
      navigate(
        `/${userRole}/reports-dashboard/researcher-program/${selectionId}/${programs.id}`
      );
  };
  useEffect(() => {
    if (reportType != "select") {
      fetchData();
    }
  }, [reportType]);
  useEffect(() => {
    if (selectionId) {
      fetchPrograms();
    }
  }, [selectionId]);
  return (
    <>
      {loading &&
        <CircularLoader size={100} />}
      {isOpen && (
        <div className="changeStatus" style={{ zIndex: 1 }}>
          <div className="changeStatusBody">
            <div className="changeStatusBodyTop">
              {/* <i className="fa-solid fa-circle-exclamation fa-rotate-180"></i> */}
            </div>
            <div className="changeStatusBodyThirdPart">
              <p>Generate Report</p>
            </div>
            <div className="text-center p-3">
              <Select
                fullWidth
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
              >
                {reportTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <Select
                sx={{
                  mt: 1,
                  ".paper": {
                    height: "200px !important",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
                fullWidth
                value={selectionId}
                onChange={(e) => setSelectionId(e.target.value)}
              >
                {types.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {reportType === "researcherprogram" && (
                <Select
                  sx={{
                    mt: 1,
                    ".paper": {
                      height: "200px !important",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                  fullWidth
                  value={selectedProgram}
                  onChange={(e) => setSelectedProgram(e.target.value)}
                >
                  {programs.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
            <div className="changeStatusBodyLastPart">
              <button
                // onClick={() => setChangeStatusModal(false)}
                className="changeStatusBtnClose"
                onClick={onClose}
              >
                Close
              </button>
              <button onClick={handleSubmit} className="changeStatusBtnSubmit">
                Generate
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportModal;
