import React, { useCallback, useContext, useEffect, useState } from "react";
import HTTPService from "../../utils/axios";
import {
  debounce,
  formatDate,
  getObjectKeys,
} from "../../utils/helperFunctions";
import { useParams } from "react-router-dom";
import { FaTicketAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../reducers/authSlice";
import { toast } from "react-toastify";
import ProfileImage from "../../components/ProfileImage";
import moment from "moment";
import submissionStatusData from "./submissionStatusData.json";
import "./ProgramtabStyle.css";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import AppContext from "../Context/AppContext";
import RewardModal from "../common/modal/RewardModal";
import { Modal, Chip, TextField } from "@mui/material";
import { customToast } from "../../App";

const tagretUrlConst = [
  "target_url1",
  "target_url2",
  "target_url3",
  "target_url4",
  "target_url5",
];
const evidenceAttachmentsConst = [
  "evidence_attachments1",
  "evidence_attachments2",
  "evidence_attachments3",
  "evidence_attachments4",
  "evidence_attachments5",
];

const attachmentsUploadConst = [
  "attachments_upload_doc1",
  "attachments_upload_doc2",
  "attachments_upload_doc3",
  "attachments_upload_doc4",
  "attachments_upload_doc5",
];
const columns = [
  "select",
  "severity",
  "priority",
  "vtx",
  "cvss_score",
  "submission_title",
  "submission_status",
  "target_title",
];
const AdminProgramSubmission = () => {
  const { invokeApi } = HTTPService();
  const { userInfo } = useSelector(getUserInfo);
  const { id } = useParams();
  const { setLoading, loading, setCircularLoading } = useContext(AppContext);
  const [status, setStatus] = useState([]);
  const [submissions, setSubmissions] = useState();
  const [selectedSubmission, setSelectedSubmission] = useState();
  const [subAdmins, setSubAdmins] = useState();
  const [comments, setComments] = useState();
  const [comment, setComment] = useState();
  const [searchColumn, setSearchColumn] = useState("select");
  const [searchValue, setSearchValue] = useState("");
  const [assignee, setAssignee] = useState("");
  const [editComment, setEditComment] = useState();
  const [submissionLoad, setSubmissionLoad] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusVal, setChangeStatusVal] = useState();
  const [isViewMode, setIsViewMode] = useState(false);
  const [isPayMode, setIsPayMode] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [noteInput, setNoteInput] = useState("");
  const [noteChips, setNoteChips] = useState("");
  const [noteLoading, setNoteLoading] = useState(false);

  const handleAddChip = (e) => {
    if (e.key === "Enter" && noteInput.trim() !== "") {
      setNoteChips((prevChips) => {
        // If prevChips is not empty, append a comma before adding the new chip
        return prevChips
          ? `${prevChips},${noteInput.trim()}`
          : noteInput.trim();
      });
      setNoteInput("");
    }
  };
  console.log("chips", noteChips);
  useEffect(() => {
    if (
      selectedSubmission &&
      selectedSubmission.tags &&
      selectedSubmission.tags.length > 0
    ) {
      setNoteChips(selectedSubmission?.tags);
    }
  }, [selectedSubmission]);

  const handleDeleteChip = (chipToDelete) => {
    setNoteChips((prevChips) =>
      prevChips.filter((chip) => chip !== chipToDelete)
    );
  };

  const AddEditTags = (selectedSubmission) => {
    setNoteLoading(true);
    invokeApi({
      method: "PATCH",
      url: `submissions/${selectedSubmission.id}/`,
      data: { tags: noteChips },
    })
      .then((response) => {
        setNoteChips("");
        setNoteModal(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setNoteLoading(false);
  };

  const fetchStatus = () => {
    setSubmissionLoad(true);
    invokeApi({
      method: "GET",
      url: `submissions/status-count/?program_id=${id}`,
    })
      .then((response) => {
        setSubmissionLoad(false);
        setStatus(response);
      })
      .catch((error) => {
        setSubmissionLoad(false);
        console.error("Error fetching data:", error);
      });
  };
  const fetchSubmissions = (value, column) => {
    let searClm = column ? column : searchColumn;
    let searchQuery = searchValue;
    if (value) {
      searchQuery = value;
    }
    let params = {
      program_id: id,
      user_id: userInfo?.user_data?.id,
    };
    if (searClm && searchQuery) {
      params[searClm] = searchQuery;
    }
    invokeApi({
      method: "GET",
      url: `submissions/`,
      params,
    })
      .then((response) => {
        setSubmissions(response);
        if (response.results.length > 0)
          setSelectedSubmission(response.results[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const fetchSubAdmins = () => {
    invokeApi({
      method: "GET",
      url: `sub_admin/`,
    })
      .then((response) => {
        setSubAdmins(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const fetchComments = () => {
    invokeApi({
      method: "GET",
      url: `program-comments/`,
      params: {
        program_id: id,
        submission: selectedSubmission?.id,
      },
    })
      .then((response) => {
        setComments(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleComment = () => {
    if (userInfo?.user_data?.id) {
      invokeApi({
        method: "POST",
        url: `program-comments/`,
        data: {
          comment,
          program_id: id,
          user_id: userInfo.user_data.id,
          submission: selectedSubmission?.id,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          setComments((prev) => {
            let data = { ...prev };
            data.results.unshift(response);
            return data;
          });
          setComment("");
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      customToast.error("Logged in userid not found, Please login again.");
    }
  };
  const debouncedSearch = useCallback(
    debounce((value, clm) => {
      fetchSubmissions(value, clm);
    }, 500),
    []
  );
  const handleChangeSearchValue = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    debouncedSearch(value, searchColumn);
  };

  useEffect(() => {
    fetchStatus();
    fetchSubmissions();
    fetchSubAdmins();
  }, [id]);

  useEffect(() => {
    if (searchColumn !== "select" && searchValue) {
      fetchSubmissions();
    }
  }, [searchColumn]);

  useEffect(() => {
    if (selectedSubmission) {
      let user = subAdmins?.results?.find(
        (el) => el.id === selectedSubmission.assignee
      );
      setAssignee(user);
      fetchComments();
      const interval = setInterval(() => {
        fetchComments();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [selectedSubmission]);

  const changeAssigne = () => {
    console.log(selectedSubmission);
    invokeApi({
      method: "PATCH",
      url: `submissions/${selectedSubmission.id}/`,
      data: {
        assignee: assignee.id,
      },
    })
      .then((response) => {
        customToast.success("Action performed successfully.");
        setSubmissions((prev) => {
          let data = { ...prev };
          data.results = data.results.map((el) => {
            if (el.id === response.id) {
              return response;
            }
            return el;
          });
          return data;
        });
        // setStatus(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const onChangeEditComment = (comment) => {
    setEditComment((prev) => {
      return {
        ...prev,
        comment,
      };
    });
  };

  const handleEditComment = () => {
    invokeApi({
      method: "PATCH",
      url: `program-comments/${editComment.id}/`,
      data: {
        ...editComment,
      },
    })
      .then((response) => {
        customToast.success("Action performed successfully.");
        setComments((prev) => {
          let data = { ...prev };
          data.results = data.results.map((comment) => {
            if (comment.id === response.id) {
              return response;
            }
            return comment;
          });
          return data;
        });
        setEditComment();
        // setStatus(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleStatus = async (data) => {
    // fetchStatus(id);
    setChangeStatusModal(true);
    setChangeStatusVal(data.submission_status);
    setSelectedSubmission(data);
  };

  const setSubmissionStatus = async () => {
    setChangeStatusModal(false);
    setCircularLoading(true);
    const param = {
      // detail_description: particularSubmission.detail_description,
      // program_id: particularSubmission.program_id,
      // user_id: particularSubmission.user_id,
      // submission_title: particularSubmission.submission_title,
      // id: id,
      submission_status: changeStatusVal.toLowerCase(),
    };
    invokeApi({
      method: "PATCH",
      url: `submissions/${selectedSubmission.id}/`,
      data: param,
    })
      .then(async (res) => {
        // let tempSubmission = selectedSubmission;
        await fetchSubmissions(true);
        customToast.success("status updated", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // setSelectedSubmission(tempSubmission);
        setCircularLoading(false);
      })
      .catch((error) => {
        setCircularLoading(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  return (
    <>
      <div className="row mt-4 w-100">
        {/* top part */}
        <div className="row">
          <div className="col-12 statusBox">
            {status &&
              status.total_submissions_status &&
              ["new", "rejected", "resolved", "review", "triaged"].map(
                (data, index) => {
                  if (status.total_submissions_status[data])
                    return (
                      <div className=" statusBoxItems" key={index}>
                        <p style={{ textTransform: "capitalize" }}>{data}</p>
                        <div>
                          <p>{status?.total_submissions_status[data] ?? 0}</p>
                        </div>
                      </div>
                    );
                }
              )}
          </div>
        </div>
        {/* second part */}
        <div className="row">
          <div className="input-group p-0 mb-1 mt-2">
            <div className="input-group-prepend" style={{ minWidth: "190px" }}>
              <Select
                fullWidth
                value={searchColumn}
                onChange={(e) => setSearchColumn(e.target.value)}
                size="small"
                placeholder="Select"
              >
                {columns &&
                  columns.map((data) => (
                    <MenuItem key={data} value={data}>
                      <div className="text-capitalize">
                        {data.replaceAll("_", " ")}
                      </div>
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <input
              value={searchValue}
              onChange={handleChangeSearchValue}
              type="text"
              className="form-control p-2"
              placeholder="search..."
            />
          </div>
        </div>
        {/* 3rd part */}
        {/* <div className="row mt-2">
					<div className="col-12 statusBox">
						<div className="resultsMatchingText">
							<span>7 Results Matching Search -</span>
							<button>View In Insights</button>
						</div>
					</div>
				</div> */}

        {/* 4th part */}
        <div className="row mt-2">
          <div className="col-12 card rounded-0 flex-row">
            <div className="col-4">
              {submissionLoad ? (
                <Skeleton height={600} width={"96%"} />
              ) : (
                <div className="scrollableLeftSide">
                  {submissions?.results &&
                    submissions.results.map((data, index) => {
                      return (
                        <div
                          className="col-12 mt-3"
                          key={index}
                          onClick={() => setSelectedSubmission(data)}
                        >
                          <div className="demoLeftItemBox">
                            <p>
                              <FaTicketAlt
                                style={{ fontSize: "2em", marginRight: ".3em" }}
                                className="text-primary"
                              />
                              {data.submission_title}
                            </p>
                            {/* <div className="programSubmissionDescLeft">
													<p>
														<div className="">
															<p>{data.submission_status}</p>
														</div>
														<span dangerouslySetInnerHTML={{ __html: data.detail_description }}></span>
													</p>
												</div> */}
                            <div className="d-flex justify-content-start">
                              <div
                                style={{
                                  color: "#004DFF",
                                  marginRight: "10px",
                                }}
                              >
                                <span style={{ fontWeight: "600" }}>
                                  {data?.user?.first_name
                                    ? data?.user?.first_name
                                    : ""}
                                  {" " + data?.user?.last_name
                                    ? data?.user?.last_name
                                    : ""}
                                </span>
                              </div>
                              <div>
                                {formatDate(data.created_at, 1)}
                                <br />
                              </div>
                            </div>
                            <div className="d-flex justify-content-start gap-1 mt-2">
                              <div className="d-flex justify-content-between w-100">
                                <div className="d-flex justify-content-start gap-1">
                                  <div
                                    style={{
                                      aspectRatio: "1/1",
                                      padding: "5px 10px",
                                    }}
                                    className={
                                      data.priority == "P1"
                                        ? "taxonomyDiv p1Level"
                                        : data.priority == "P2"
                                          ? "taxonomyDiv p2Level"
                                          : data.priority == "P3"
                                            ? "taxonomyDiv p3Level"
                                            : data.priority == "P4"
                                              ? "taxonomyDiv p4Level"
                                              : "taxonomyDiv p5Level"
                                    }
                                  >
                                    <p>{data.priority}</p>
                                  </div>
                                  <div className="taxonomyDiv">
                                    <p>{data.severity}</p>
                                  </div>
                                </div>

                                <div
                                  className={`taxonomyDiv text-capitalize`}
                                  style={
                                    data.submission_status === "triaged"
                                      ? {
                                        backgroundColor: "#ffae00",
                                        color: "#fff",
                                      }
                                      : data.submission_status === "accepted"
                                        ? {
                                          backgroundColor: "#82d616",
                                          color: "#fff",
                                        }
                                        : data.submission_status === "new"
                                          ? {
                                            backgroundColor: "#3ce0e2",
                                            color: "#fff",
                                          }
                                          : data.submission_status === "review" ||
                                            data.submission_status === "resolved"
                                            ? {
                                              backgroundColor: "#82d616",
                                              color: "#fff",
                                            }
                                            : {
                                              backgroundColor: "#ea0606",
                                              color: "#fff",
                                            }
                                  }
                                >
                                  <span>{data.submission_status}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
            <div className="col-8 scrollableLeftSide">
              {submissionLoad ? (
                <Skeleton height={600} width={"100%"} />
              ) : selectedSubmission ? (
                <>
                  <div className="row mt-4 ms-4">
                    <div className="col-12 d-flex justify-content-between">
                      <div className=" rightSideDemoTitle">
                        <div style={{ color: "#205EBD" }}>
                          {selectedSubmission.submissions_id}
                        </div>
                        <p>
                          <FaTicketAlt
                            style={{ fontSize: "1.5em", marginRight: ".3em" }}
                            className="text-primary"
                          />
                          {selectedSubmission.submission_title}
                        </p>
                        {/* <div className="detailsDemoDetails">
														<span>24 Dec 2023 - Platform Type - </span>
														<button>View Submission Button</button>
													</div> */}
                      </div>
                      <div className="">
                        <div
                          // className="submissionStatus"
                          className={`submissionStatus submissionStatus${selectedSubmission.submission_status.toLowerCase()} text-capitalize`}
                          // style={{ textTransform: "capitalize" }}
                          style={
                            selectedSubmission.submission_status === "triaged"
                              ? {
                                backgroundColor: "#ffae00",
                                color: "#fff",
                              }
                              : selectedSubmission.submission_status ===
                                "accepted"
                                ? {
                                  backgroundColor: "#82d616",
                                  color: "#fff",
                                }
                                : selectedSubmission.submission_status === "new"
                                  ? {
                                    backgroundColor: "#3ce0e2",
                                    color: "#fff",
                                  }
                                  : selectedSubmission.submission_status ===
                                    "review" ||
                                    selectedSubmission.submission_status ===
                                    "resolved"
                                    ? {
                                      backgroundColor: "#82d616",
                                      color: "#fff",
                                    }
                                    : {
                                      backgroundColor: "#ea0606",
                                      color: "#fff",
                                    }
                          }
                        >
                          {selectedSubmission.submission_status}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 ms-4 border-bottom pb-3 me-4">
                    <div className="col-6 fourOptionBox">
                      <div className="col-6">
                        <span>
                          <b>Severity:</b> {selectedSubmission.severity}
                        </span>
                      </div>
                      <div className="col-6">
                        <span>
                          <b>VTx:</b>
                          {selectedSubmission.vtx}
                        </span>
                      </div>
                      <div className="col-6">
                        <span>
                          <b>Priority:</b> {selectedSubmission.priority}
                        </span>
                      </div>
                      <div className="col-6">
                        <span>
                          <b>CVSS:</b> {selectedSubmission.cvss_score}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 ms-4">
                    <span className="assignee">
                      Assignee
                      <i
                        className="fa-solid fa-eye-slash text-primary"
                        style={{ marginRight: "5px" }}
                      ></i>
                    </span>

                    <div className="col-12 mt-3 d-flex justify-content-between">
                      <div className="col-6">
                        <div className="d-flex gap-1">
                          <select
                            className="form-select"
                            aria-label=".form-select-lg example"
                            value={assignee?.id ? assignee.id : ""}
                            onChange={(e) => {
                              setAssignee(
                                subAdmins.results.find(
                                  (el) => el.id === e.target.value
                                )
                              );
                            }}
                          >
                            <option value="" selected>
                              Open this select menu
                            </option>
                            {subAdmins &&
                              subAdmins.results &&
                              subAdmins.results.map((data, index) => (
                                <option key={index} value={data.id}>
                                  {data.first_name + " " + data.last_name}
                                </option>
                              ))}
                          </select>
                          <button
                            onClick={changeAssigne}
                            className="addRewardBtn assignBtn"
                          >
                            Assign
                          </button>
                        </div>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-end">
                        {/* <div>
                          <button className="addRewardBtn markasDuplicateBtn">
                            Mark as Duplicate
                          </button>
                        </div> */}
                        <div>
                          <button
                            className="addRewardBtn threeDotBtn dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown"
                          >
                            <span>...</span>
                          </button>
                          <ul className="dropdown-menu dropMenu">
                            {selectedSubmission.submission_status ===
                              "resolved" && (
                                <li>
                                  <button
                                    onClick={() => {
                                      // setSelectedSubmission(item);
                                      setPaymentModal(true);
                                      setIsViewMode(true);
                                      setIsPayMode(false);
                                    }}
                                    className="dropdown-item"
                                  >
                                    View Note
                                  </button>
                                </li>
                              )}
                            <li>
                              <button
                                disabled={
                                  selectedSubmission.submission_status ===
                                  "resolved" &&
                                  selectedSubmission.transaction_details
                                    ?.status == "success"
                                }
                                className="dropdown-item"
                                onClick={() => handleStatus(selectedSubmission)}
                              >
                                Update Status
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={() => setNoteModal(true)}
                              >
                                Add Tags
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 ms-4 me-4 integrationDiv">
                    <p className="IntegrationHeading mb-0">Assigned User</p>
                    <div
                      className="d-flex align-items-center "
                      style={{
                        borderBottom: "2px solid #C5C5C5",
                        paddingBottom: "15px",
                      }}
                    >
                      <div style={{ width: "50px" }}>
                        <ProfileImage
                          style={{ width: "100%", marginRight: ".3em" }}
                          src={assignee?.display_pic}
                        />
                      </div>
                      {assignee && assignee?.first_name ? (
                        <p
                          style={{
                            color: "#000435",
                            marginBottom: "0",
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                        >
                          {`${assignee?.first_name} ${assignee?.last_name} (${assignee?.user_id})`}
                        </p>
                      ) : (
                        <p
                          style={{
                            color: "#000435",
                            marginBottom: "0",
                            fontSize: "14px",
                            fontWeight: 600,
                            marginLeft: ".5rem",
                          }}
                        >
                          No Assignee
                        </p>
                      )}
                    </div>

                    <div className="mt-4 mb-3 d-flex">
                      <div className="col-1">
                        <i className="fa-xl fa-solid fa-indent text-primary"></i>
                      </div>
                      <div className="col-11">
                        <p className=" submissionDescription">
                          SUBMISSION DESCRIPTION:
                        </p>
                        <p className=" loremTextSubmissionDescription">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: selectedSubmission.detail_description,
                            }}
                          ></span>
                        </p>
                      </div>
                    </div>
                    <div className="mb-0 d-flex">
                      <div className="col-1">
                        <i className="fa-xl fa-solid fa-clipboard-list text-primary"></i>
                      </div>
                      <div className="col-11">
                        <p className=" submissionDescription">
                          STEP TO REPRODUCE:
                        </p>
                        <p className=" loremTextSubmissionDescription">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: selectedSubmission.step_to_reproduce,
                            }}
                          ></span>
                          {/* <ul>
															<li>
																<span>
																	Step 1: Lorem ipsum dolor, sit amet consectetur
																	adipisicing elit. Voluptatem, quod?
																</span>
															</li>
															<li>
																<span>
																	Step 2: Lorem ipsum dolor, sit amet consectetur
																	adipisicing elit
																</span>
															</li>
															<li>
																<span>
																	Step 2: Lorem ipsum dolor, sit amet consectetur
																	adipi
																</span>
															</li>
														</ul> */}
                        </p>
                      </div>
                    </div>
                    <div className="mb-0 d-flex">
                      <div className="col-1">
                        <i className="fa-xl fa-solid fa-clipboard-list text-primary"></i>
                      </div>
                      <div className="col-11">
                        <p className=" submissionDescription">
                          REMEDIATION RECOMMENDATION:
                        </p>
                        <p className=" loremTextSubmissionDescription">
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                selectedSubmission.remediation_recommendation,
                            }}
                          ></span>
                        </p>
                      </div>
                    </div>
                    <div className="mb-0 d-flex">
                      <div className="col-1">
                        <i className="fa-xl fa-solid fa-house text-primary"></i>
                      </div>
                      <div className="col-11 d-flex">
                        <div className="col-6">
                          <p className=" submissionDescription">TARGET TITLE</p>
                          <p className=" ">{selectedSubmission.target_title}</p>
                        </div>
                        <div className="col-6 d-flex">
                          <div className="">
                            <p className="submissionDescription">
                              TARGET URL :
                            </p>
                            <div>
                              {tagretUrlConst.map((key) => {
                                if (selectedSubmission[key])
                                  return (
                                    <p
                                      key={key}
                                      className=" d-flex justify-content-end align-items-center"
                                      style={{ textWrap: "nowrap" }}
                                    >
                                      <b>&#x2022;</b>
                                      <a
                                        className="text-overflow-ellipsis"
                                        target="_blank"
                                        href={selectedSubmission[key]}
                                        style={{
                                          color: "#98748E",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {selectedSubmission[key]}
                                      </a>
                                    </p>
                                  );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" d-flex" style={{ marginBottom: "20px" }}>
                      <div className="col-1">
                        <i className="fa-xl fa-solid fa-tv  text-primary"></i>
                      </div>
                      <div className="col-11 d-flex">
                        <div className="">
                          <span className=" submissionDescription">
                            TYPES OF TESTING ALLOWED :
                          </span>
                          <span
                            style={{ fontSize: "14px", marginLeft: "10px" }}
                          >
                            {selectedSubmission.type_of_testing_allowed}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="  d-flex" style={{ marginBottom: "20px" }}>
                      <div className="col-1">
                        <i className=" fa-xl fa-solid fa-pen  text-primary"></i>
                      </div>
                      <div className="col-11 d-flex">
                        <div className="">
                          <span className=" submissionDescription">
                            LANGUAGES & FRAMEWORKS :
                          </span>
                          <span
                            style={{ fontSize: "14px", marginLeft: "10px" }}
                          >
                            {selectedSubmission.languages_frameworks}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className=" d-flex" style={{ marginBottom: "20px" }}>
                      <div className="col-1">
                        <i className="fa-xl fa-solid fa-satellite-dish  text-primary"></i>
                      </div>
                      <div className="col-11 d-flex">
                        <div className="">
                          <span className=" submissionDescription">
                            ASSET ENVIRONMENTS :
                          </span>
                          <span
                            style={{ fontSize: "14px", marginLeft: "10px" }}
                          >
                            {selectedSubmission.asset_environments}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className=" d-flex" style={{ marginBottom: "20px" }}>
                      <div className="col-1">
                        <i className="fa-xl fa-solid fa-note-sticky fa-rotate-270  text-primary"></i>
                      </div>
                      <div className="col-11 d-flex">
                        <div className="">
                          <span className=" submissionDescription">
                            EVIDENCE ATTACHTEMTS :
                          </span>

                          <div>
                            {evidenceAttachmentsConst.map((key) => {
                              if (selectedSubmission[key])
                                return (
                                  <a
                                    target="_blank"
                                    href={selectedSubmission[key]}
                                    key={key}
                                    style={{
                                      fontSize: "10px",
                                      marginLeft: "10px",
                                      backgroundColor: "#E6E6E6",
                                      padding: "5px 10px",
                                      borderRadius: "5px ",
                                    }}
                                  >
                                    {selectedSubmission[key]}
                                  </a>
                                );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" d-flex" style={{ marginBottom: "20px" }}>
                      <div className="col-1">
                        <i className="fa-xl fa-solid fa-note-sticky fa-rotate-270  text-primary"></i>
                      </div>
                      <div className="col-11 d-flex">
                        <div className="">
                          <span className=" submissionDescription">
                            UPLOAD ATTACHTEMTS :
                          </span>

                          <div>
                            {attachmentsUploadConst.map((key) => {
                              if (selectedSubmission[key])
                                return (
                                  <a
                                    target="_blank"
                                    href={selectedSubmission[key]}
                                    key={key}
                                    style={{
                                      fontSize: "10px",
                                      marginLeft: "10px",
                                      backgroundColor: "#E6E6E6",
                                      padding: "5px 10px",
                                      borderRadius: "5px ",
                                    }}
                                  >
                                    {selectedSubmission[key]}
                                  </a>
                                );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr style={{ background: "#000" }} />
                  <div className="row ">
                    <h4>Comments</h4>
                    <div className="col-12">
                      <div>
                        <textarea
                          placeholder="Feel free to submit or share notes related to program and submissions here."
                          style={{
                            fontSize: "12px",
                            width: "100%",
                            height: "100px",
                            borderRadius: "10px",
                            border: "1px solid #919595",
                            padding: "8px 10px",
                          }}
                          cols="20"
                          rows="6"
                          value={comment ?? ""}
                          onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12 d-inline-flex justify-content-end">
                      <button
                        className=" btn btn-primary mt-2"
                        style={{
                          padding: "8px 20px",
                          color: "#fff",
                          fontSize: "1em",
                          backgroundColor: "#7f56d9",
                        }}
                        onClick={handleComment}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row mt-4 me-4 mb-4  d-flex justify-content-center ">
                    <div className="col-12 pb-4">
                      {userInfo &&
                        comments &&
                        comments.results &&
                        comments.results.map((comment, index) => (
                          <div
                            style={{
                              gap: "10px",
                              marginBottom: "20px",
                              display: "flex",
                              justifyContent:
                                userInfo?.user_data?.id == comment.user_id
                                  ? "flex-end"
                                  : "flex-start",
                            }}
                            key={index}
                          >
                            {userInfo?.user_data?.id != comment.user_id && (
                              <div style={{ width: "60px", height: "60px" }}>
                                <ProfileImage
                                  src={comment?.user?.display_pic}
                                />
                              </div>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems:
                                  userInfo?.user_data?.id === comment.user_id
                                    ? "flex-end"
                                    : "flex-start",
                              }}
                            >
                              <div>
                                <span
                                  style={{
                                    color: "#004DFF",
                                    fontWeight: "600",
                                    paddingRight: ".2em",
                                  }}
                                >
                                  {comment?.user?.username},
                                  {/* {comment.user.first_name + " " + comment.user.last_name}, */}
                                </span>
                                <span style={{ color: "#004DFF" }}>
                                  {comment?.user?.role ?? ""}
                                </span>
                              </div>
                              <div>
                                {editComment?.id === comment.id ? (
                                  <textarea
                                    placeholder="Feel free to submit or share notes related to program and submissions here."
                                    style={{
                                      fontSize: "12px",
                                      width: "100%",
                                      height: "100px",
                                      borderRadius: "10px",
                                      border: "1px solid #919595",
                                      padding: "8px 10px",
                                    }}
                                    cols="20"
                                    rows="4"
                                    value={editComment.comment ?? ""}
                                    onChange={(e) =>
                                      onChangeEditComment(e.target.value)
                                    }
                                  ></textarea>
                                ) : (
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      color: "#000435",
                                    }}
                                  >
                                    {comment.comment}
                                  </span>
                                )}
                              </div>
                              <div style={{ fontSize: "12px" }}>
                                {moment(comment.created_at).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                )}
                              </div>
                              <div>
                                {userInfo?.user_data?.id ===
                                  comment.user_id && (
                                    <>
                                      {editComment &&
                                        editComment?.id === comment.id ? (
                                        <button
                                          onClick={handleEditComment}
                                          className="mt-2 btn btn-primary"
                                          style={{
                                            padding: "4px 10px",
                                            background: "#7F56D9",
                                          }}
                                        >
                                          Submit
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() => setEditComment(comment)}
                                          className="mt-2 btn btn-primary"
                                          style={{
                                            padding: "4px 10px",
                                            background: "#7F56D9",
                                          }}
                                        >
                                          Edit
                                        </button>
                                      )}
                                      <button
                                        onClick={() => setEditComment()}
                                        className="mt-2 btn btn-tertiary"
                                        style={{
                                          marginLeft: "10px",
                                          padding: "4px 10px",
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </>
                                  )}
                              </div>
                            </div>

                            {userInfo?.user_data?.id == comment.user_id && (
                              <div style={{ width: "60px", height: "60px" }}>
                                <ProfileImage
                                  src={comment?.user?.display_pic}
                                />
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <span style={{ fontSize: "2em" }}>
                    No Submission selected
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {changeStatusModal ? (
        <div className="changeStatus">
          <div className="changeStatusBody">
            <div className="changeStatusBodyTop">
              <i className="fa-solid fa-circle-exclamation fa-rotate-180"></i>
              <p>Change The Submission Status</p>
            </div>
            <div className="changeStatusBodySecPart">
              {userInfo.role == "customer" &&
                (selectedSubmission.submission_status == "review" ||
                  selectedSubmission.submission_status == "unresolved") ? (
                <select
                  name=""
                  id=""
                  value={changeStatusVal}
                  onChange={(e) => setChangeStatusVal(e.target.value)}
                >
                  {/* <option value="new">New</option> */}
                  {/* <option value="triaged">Triaged</option> */}
                  <option value="review">Review</option>
                  <option value="resolved">Resolved</option>
                  <option value="unresolved">Unresolved </option>
                  <option value="rejected">Rejected </option>
                </select>
              ) : userInfo.role == "customer" ? (
                <select
                  name=""
                  id=""
                  value={selectedSubmission.submission_status}
                  // onChange={(e) => setChangeStatusVal(e.target.value)}
                  disabled
                >
                  <option value="new">New</option>
                  <option value="triaged">Triaged</option>
                  <option value="review">Review</option>
                  <option value="resolved">Resolved</option>
                  <option value="unresolved">Unresolved </option>
                  <option value="rejected">Rejected </option>
                </select>
              ) : (
                <select
                  name=""
                  id=""
                  value={changeStatusVal}
                  onChange={(e) => setChangeStatusVal(e.target.value)}
                >
                  <option value="new">New</option>
                  <option value="triaged">Triaged</option>
                  <option value="review">Review</option>
                  <option value="resolved">Resolved</option>
                  <option value="unresolved">Unresolved </option>
                  <option value="rejected">Rejected </option>
                </select>
              )}
            </div>
            <div className="changeStatusBodyThirdPart">
              <p>Are You Sure You Want to Change The Status?</p>
            </div>
            <div className="changeStatusBodyLastPart">
              <button
                onClick={() => setChangeStatusModal(false)}
                className="changeStatusBtnClose"
              >
                CLOSE
              </button>
              <button
                onClick={setSubmissionStatus}
                className="changeStatusBtnSubmit"
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {paymentModal && (
        <RewardModal
          isOpen={paymentModal}
          submission={selectedSubmission}
          isViewMode={isViewMode}
          isPayMode={isPayMode}
          onClose={() => setPaymentModal(false)}
          onSubmit={() => {
            setPaymentModal(false);
          }}
        />
      )}
      <Modal
        open={noteModal}
        onClose={() => setNoteModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            backgroundColor: "white",
            boxShadow: 24,
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <h5 id="modal-title">Add Notes</h5>
          <TextField
            label="Add a note"
            variant="outlined"
            fullWidth
            value={noteInput}
            onChange={(e) => setNoteInput(e.target.value)}
            onKeyDown={handleAddChip}
            margin="normal"
          />
          <div className="mt-3">
            {noteChips?.split(",").map((chip, index) => (
              <Chip
                key={index}
                label={chip.trim()} // trimming any extra spaces
                onDelete={() => handleDeleteChip(chip)}
                className="me-2 mb-2"
              />
            ))}
          </div>
          <div className="modal-footer">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setNoteModal(false)}
              fullWidth
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => AddEditTags(selectedSubmission)}
              fullWidth
            >
              {noteLoading ? <CircularProgress /> : "Save"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdminProgramSubmission;
