import React from 'react'

const ResearcherProgramAnnouncement = () => {
  return (
    <div>
      announcement
    </div>
  )
}

export default ResearcherProgramAnnouncement
