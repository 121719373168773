import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import { useEffect, useState } from "react";

export default function useFetchSeverity() {
	const [severityOpt, setSeverityOpt] = useState();

	const { invokeApi } = HTTPService();
	useEffect(() => {
		const fetchSeverity = async () => {
			invokeApi({
				method: "GET",
				url: "basevalues/?title_code=Severity",
			})
				.then((data) => {
					setSeverityOpt(data.results[0].value_options);
				})
				.catch((error) => {
					toast.error("Something went wrong!", {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 1000,
					});
				});
		};
		fetchSeverity();
	}, []);
	return { severityOpt };
}
