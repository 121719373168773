import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import Footer from "../../components/Footer/Footer";

export default function ForgotPasswordSuccess() {
	const navigate = useNavigate();
	return (
		<>
			<div
				className="bg-gray-100 "
				style={{ height: "100vh", position: "relative" }}
			>
				<div className="container h-100">
					<div style={{height:"88%"}} className="d-flex flex-column justify-content-center">
						<Link to="/">
							<div className=" top-content">
								<img className="logo" src={logo} alt="logo" width="20%" />
							</div>
						</Link>
						<div
							className="row   justify-content-center"
							style={{ marginTop: " 171px!important" }}
						>
							<div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
								<div className="card z-index-0">
									<div className="main-welcome" id="verifyemail">
										<div
											style={{
												fontSize: "30px",
												padding: "15px 25px",
												backgroundColor: "#f5f6f8",
												borderRadius: "50%",
												color: "#6941C6",
											}}
										>
											<i className="fa-solid fa-envelope-open-text"></i>
										</div>
										<h4 className="verifyemail text-dark pb-0">Success!</h4>
										<span className="main-welcome-para">
											Check your mail for further Instructions.
										</span>
									</div>
									<div className="card-body py-0 mb-3">
										<div className="text-center">
											<button
												type="button"
												className="loginbtn btn  w-100  mb-2"
												style={{ backgroundColor: "#6941C6" }}
												onClick={() => navigate("/")}
											>
												Back To Home
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{ position: "absolute", bottom: "0px", width: "100%" }}>
					<Footer />
				</div>
			</div>
		</>
	);
}
