import React from 'react'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function CountSkeletonLoader({showState}) {
  return (
		<>
			{showState === 1 && (
				<div className="col-lg-12 col-12">
					<div className="row">
						{Array(4)
							.fill(0)
							.map((item,index) => (
								<div key={index}  className="col-lg-3 col-md-6 col-12">
									<div className="card">
										<span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
										<div className="card-body p-3 position-relative">
											<div className="row">
												<div className="col-8 text-start">
													<div className="icon icon-shape  shadow text-center border-radius-md">
														<Skeleton
															style={{ width: "100%", height: "100%" }}
															baseColor="#FFE6E6"
															highlightColor="#E1AFD1"
														/>
													</div>
													<h5 className="text-white font-weight-bolder mb-0 mt-3">
														<Skeleton
															style={{ width: "50px" }}
															baseColor="#FFE6E6"
															highlightColor="#E1AFD1"
														/>
													</h5>
													<span className="text-white text-sm">
														<Skeleton
															style={{ width: "70px" }}
															baseColor="#FFE6E6"
															highlightColor="#E1AFD1"
														/>
													</span>
												</div>
												<div className="col-4">
													<div className="dropdown text-end mb-5"></div>
													<h5 className="text-end text-white pt-3 mb-0 mt-2">
														<Skeleton
															style={{ width: "50px" }}
															baseColor="#FFE6E6"
															highlightColor="#E1AFD1"
														/>
													</h5>
													<p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
														<Skeleton
															style={{ width: "70px" }}
															baseColor="#FFE6E6"
															highlightColor="#E1AFD1"
														/>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
					</div>
				</div>
			)}
			{showState === 2 && (
				<>
					<div className="col-lg-12 col-12">
						<div className="row">
							{Array(6)
								.fill(0)
								.map((item,index) => (
									<div  key={index} className="col-lg-2 col-md-6 col-12 mt-4 mt-md-0">
										<div className="card">
											<span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
											<div className="card-body p-3 position-relative">
												<div className="row">
													<div className="col-7 text-start">
														<div className="icon icon-shape  shadow text-center border-radius-md">
															<Skeleton
																style={{ width: "100%", height: "100%" }}
																baseColor="#FFE6E6"
																highlightColor="#E1AFD1"
															/>
														</div>
														<h5 className="text-white font-weight-bolder mb-0 mt-3">
															<Skeleton
																style={{ width: "50px" }}
																baseColor="#FFE6E6"
																highlightColor="#E1AFD1"
															/>
														</h5>
														<span className="cardHeader text-white text-sm">
															<Skeleton
																style={{ width: "70px" }}
																baseColor="#FFE6E6"
																highlightColor="#E1AFD1"
															/>
														</span>
													</div>
													<div className="col-5">
														<div className="dropdown text-end mb-5"></div>
														<h5 className="text-end text-white pt-3 mb-0 mt-2">
															<Skeleton
																style={{ width: "50px" }}
																baseColor="#FFE6E6"
																highlightColor="#E1AFD1"
															/>
														</h5>
														<p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
															<Skeleton
																style={{ width: "70px" }}
																baseColor="#FFE6E6"
																highlightColor="#E1AFD1"
															/>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
						</div>
					</div>
				</>
			)}
		</>
	);
}
