import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import { useEffect, useState } from "react";

export default function useFetchProgramType() {
	const [programTypOpt, setProgramTypOpt] = useState();

	const { invokeApi } = HTTPService();
	useEffect(() => {
		const fetchPrgmType = async () => {
			invokeApi({
				method: "GET",
				url: "basevalues/?title_code=program_type",
			})
				.then((data) => {
					setProgramTypOpt(data.results[0].value_options);
				})
				.catch((error) => {
					toast.error("Something went wrong!", {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 1000,
					});
				});
		};
		fetchPrgmType();
	}, []);
	return { programTypOpt };
}
