import React, { useContext, useEffect, useState } from "react";
import {
	useParams,
	Outlet,
	Link,
	NavLink,
	useLocation,
} from "react-router-dom";
import "./Editsubadmin.css";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import rocket from "../assets/svg/rocket.svg";
import basic from "../assets/svg/basic.svg";
import box from "../assets/svg/box.svg";
import fa from "../assets/svg/fa.svg";

import settings from "../assets/svg/setting.svg";
import { HashLink } from "react-router-hash-link";
import Basicinfo from "../common/Basicinfo";

import useHttp from "../Hooks/useHttp";
import { FaUserCircle } from "react-icons/fa";
import ProfileImage from "../../components/ProfileImage";
import AppContext from "../Context/AppContext";
import CommonMemberEditSkeleton from "../components/SkeletonLoader/CommonMemberEditSkeleton";
import CircularLoader from "../CircularLoader/CircularLoader";
import AddModal from "../common/modal/AddModal";
export default function Editsubadmin() {
	const {
		loading,
		setLoading,
		memberData,
		setMemberData,
		circularLoading,
		layout,
	} = useContext(AppContext);
	const { userId, type } = useParams();
	const location = useLocation();
	const API = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem("token");
	const { GetRequest } = useHttp();
	const [userData, setUserData] = useState([]);
	const fetchUserData = async () => {
		let userType = "sub_admin";
		if (type == "header") {
			userType = "user";
		}
		setLoading(true);
		const { data, error } = await GetRequest(
			API + `${userType}/${userId}`,
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		if (error) {
			setLoading(false);
			console.log(error);
		} else {
			setLoading(false);
			setUserData(data);
			console.log("userdata", data);
		}
	};
	useEffect(() => {
		fetchUserData();
	}, [userId]);

	return (
		<>
			{loading ? (
				<CommonMemberEditSkeleton />
			) : (
				<>
					{circularLoading && <CircularLoader size={100} />}
					<div className="bg-gray-100">
						<main className="position-relative mt-0">
							<div
								className="container-fluid pt-4  "
								style={{ background: "#f5f5fb" }}
							>
								<div className="row align-items-center">
									<div className="col-lg-4 col-sm-8">
										<div className="nav-wrapper position-relative end-0">
											<ul className="nav nav-pills nav-fill p-1" role="tablist">
												<li className="nav-item" role="presentation">
													<Link
														className={
															location.pathname ===
																"/admin/user/edit/" + userId ||
															location.pathname ===
																"/admin/user/edit/" + userId + "/basicinfo"
																? "nav-link mb-0 px-0 py-1 active"
																: "nav-link mb-0 px-0 py-1"
														}
														to={
															type
																? `/admin/user/edit/${userId}/${type}`
																: `/admin/user/edit/${userId}`
														}
														aria-selected="false"
													>
														Profile
													</Link>
												</li>
												{/* <li className="nav-item" role="presentation">
											<Link
												className={
													location.pathname ===
														"/admin/user/edit/" + userId + "/addskill"
														? "nav-link mb-0 px-0 py-1 active"
														: "nav-link mb-0 px-0 py-1"
												}
												to={`/admin/user/edit/${userId}/addskill`}
												aria-selected="false"
											>
												Skills
											</Link>
										</li>
										<li className="nav-item">
											<Link
												className={location.pathname === "/admin/user/edit/" + userId + "/paymentinformation" ? "nav-link mb-0 px-0 py-1 active" : "nav-link mb-0 px-0 py-1"}
												to={`/admin/user/edit/${userId}/paymentinformation`}
												aria-selected="false"
											>
												Payment Information
											</Link>
										</li> */}
												<li className="nav-item">
													<Link
														className={
															location.pathname ===
															"/admin/user/edit/" + userId + "/social"
																? "nav-link mb-0 px-0 py-1 active"
																: "nav-link mb-0 px-0 py-1"
														}
														to={
															type
																? `/admin/user/edit/${userId}/${type}/social`
																: `/admin/user/edit/${userId}/social`
														}
														aria-selected="false"
													>
														Social
													</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="container-fluid my-3 py-3 mb-0 p-0">
									<div className="row mb-5">
										<div className="col-12 mb-4">
											{/* <!-- Card Profile --> */}
											<div className="card card-body" id="profile">
												<div className="row justify-content-center align-items-center">
													<div className="col-sm-auto col-4">
														<div className="avatar avatar-xl position-relative">
															<ProfileImage
																src={userData?.display_pic}
																errorIcon={
																	<FaUserCircle
																		style={{
																			fontSize: "3rem",
																			color: "#7f56d9 ",
																		}}
																	/>
																}
																className="w-100 border-radius-lg shadow-sm"
															/>
														</div>
													</div>
													<div className="col-sm-auto col-8 my-auto">
														<div className="h-100">
															<h5 className="mb-1 font-weight-bolder">
																{userData?.first_name} {userData?.last_name}
															</h5>
															<p className="mb-0 font-weight-bold text-sm">
																{userData?.user_id} ({userData?.username})
															</p>
														</div>
													</div>
													<div className="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
														{/* <label className="form-check-label mb-0">
															<small id="profileVisibility">
																Switch to invisible
															</small>
														</label>
														<div className="form-check form-switch ms-2">
															<input
																className="form-check-input"
																type="checkbox"
																id="flexSwitchCheckDefault23"
																checked
																onChange="visible()"
															/>
														</div> */}
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3">
											<div className="card position-sticky top-1">
												<ul className="nav flex-column bg-white border-radius-lg p-3">
													<li className="nav-item">
														<Link
															className="nav-link text-body"
															to={
																type
																	? `/admin/user/edit/${userId}/${type}/basicinfo`
																	: `/admin/user/edit/${userId}/basicinfo`
															}
															// to="#profile"
														>
															<div className="icon me-2">
																<img src={rocket} />
															</div>
															<span className="text-sm">Profile</span>
														</Link>
													</li>
													<li className="nav-item pt-2">
														<HashLink
															className="nav-link text-body"
															data-scroll=""
															to={
																type
																	? `/admin/user/edit/${userId}/${type}/basicinfo/#basic-info`
																	: `/admin/user/edit/${userId}/basicinfo/#basic-info`
															}
														>
															<div className="icon me-2">
																<img src={basic} alt="" />
															</div>
															<span className="text-sm">Basic Info</span>
														</HashLink>
													</li>
													<li className="nav-item pt-2">
														<HashLink
															className="nav-link text-body"
															data-scroll=""
															to={
																type
																	? `/admin/user/edit/${userId}/${type}/basicinfo/#password`
																	: `/admin/user/edit/${userId}/basicinfo/#password`
															}
														>
															<div className="icon me-2">
																<img src={box} alt="" />
															</div>
															<span className="text-sm">Change Password</span>
														</HashLink>
													</li>
													<li className="nav-item pt-2">
														<HashLink
															className="nav-link text-body"
															data-scroll=""
															to={
																type
																	? `/admin/user/edit/${userId}/${type}/basicinfo/#2fa`
																	: `/admin/user/edit/${userId}/basicinfo/#2fa`
															}
														>
															<div className="icon me-2">
																<img src={fa} alt="" />
															</div>
															<span className="text-sm">2FA</span>
														</HashLink>
													</li>
													{/* <li className="nav-item pt-2">
												<HashLink
													className="nav-link text-body"
													data-scroll=""
													to={type ? `/admin/user/edit/${userId}/${type}/basicinfo/#accounts` : `/admin/user/edit/${userId}/basicinfo/#accounts`}
												>
													<div className="icon me-2">
														<img src={account} alt="" />
													</div>
													<span className="text-sm">Accounts</span>
												</HashLink>
											</li>
											<li className="nav-item pt-2">
												<HashLink
													className="nav-link text-body"
													data-scroll=""
													to={type ? `/admin/user/edit/${userId}/${type}/basicinfo/#notifications`` : `/admin/user/edit/${userId}/basicinfo/#notifications`}
												>
													<div className="icon me-2">
														<img src={notification} alt="" />
													</div>
													<span className="text-sm">Notifications</span>
												</HashLink>
											</li> */}
													{/* <li className="nav-item pt-2">
														<HashLink
															className="nav-link text-body"
															data-scroll=""
															to={
																type
																	? `/admin/user/edit/${userId}/${type}/basicinfo/#delete`
																	: `/admin/user/edit/${userId}/basicinfo/#delete`
															}
														>
															<div className="icon me-2">
																<img src={settings} alt="" />
															</div>
															<span className="text-sm">
																Active / In-Active Account
															</span>
														</HashLink>
													</li> */}
												</ul>
											</div>
										</div>
										<div className="col-lg-9 mt-lg-0 mt-4">
											{location.pathname === "/admin/user/edit/" + userId ||
											location.pathname ===
												`/admin/user/edit/${userId}/${type}` ? (
												<Basicinfo userData={userData} />
											) : (
												<Outlet />
											)}
										</div>
									</div>
								</div>
							</div>
							{/* <Footer /> */}
						</main>
					</div>
				</>
			)}
			{memberData.basicinfo === true && (
				<AddModal
					cancelBtn={"Cancel"}
					submitBtn={"SUBMIT"}
					createAddTopic={"Update Basic Info"}
					confirmText={"Update Basic Info"}
					isOpen={memberData.openmodal}
					onSubmit={memberData.handlesubmit}
					onClose={() => setMemberData({ openmodal: false })}
				/>
			)}

			{memberData.changePassword === true && (
				<AddModal
					cancelBtn={"Cancel"}
					submitBtn={"SUBMIT"}
					createAddTopic={"Update Password"}
					confirmText={"Update Password"}
					isOpen={memberData.openmodal}
					onSubmit={memberData.handlesubmit}
					onClose={() => setMemberData({ openmodal: false })}
				/>
			)}

			{memberData.social === true && (
				<AddModal
					cancelBtn={"Cancel"}
					submitBtn={"SUBMIT"}
					createAddTopic={"Add Social Link"}
					confirmText={"Add Social Link"}
					isOpen={memberData.openmodal}
					onSubmit={memberData.handlesubmit}
					onClose={() => setMemberData({ openmodal: false })}
				/>
			)}
		</>
	);
}
