import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import useHttp from "../Hooks/useHttp";
import Footer from "../components/Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import AddModal from "../common/modal/AddModal";
import HTTPService from "../../utils/axios";
import { customToast } from "../../App";

export default function BadgesDashboardAdd() {
  const location = useLocation();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editData, setEditData] = useState();
  const [programTypOpt, setProgramTypOpt] = useState();
  const [programPkgOpt, setProgramPkgOpt] = useState();
  const [customerOpt, setCustomerOpt] = useState();
  const [logoName, setLogoName] = useState("");
  const [form, setForm] = useState({});
  const [validation, setValidation] = useState({ errors: {}, valids: {} });
  const [severityOpt, setSeverityOpt] = useState();
  const [terms, setTerms] = useState("");

  const { PostRequest, GetRequest, PatchRequest } = useHttp();
  const token = localStorage.getItem("token");
  const API = process.env.REACT_APP_API_URL;
  let { id } = useParams();
  const navigate = useNavigate();

  const fetchBadgesDataById = async (id) => {
    const { data, error } = await GetRequest(
      API + `/badges/${id}/`,
      {},
      { Authorization: "Bearer " + token }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setEditData(data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchBadgesDataById(id);
    }
  }, [id]);

  useEffect(() => {
    if (editData) {
      setForm({
        badge_title: editData.badge_title,
        badge_code: editData.badge_code,
        image: editData.image,
        badge_description: editData.badge_description,
        sequence: editData.sequence,
      });
      setTerms(editData.terms_conditions);
      if (editData.image) setLogoName(editData.image.split("/").pop());
    }
  }, [editData]);

  const handleChange = (field, value) => {
    setForm((prevForm) => ({ ...prevForm, [field]: value }));

    if (!value || value === "") {
      setValidation((prevValidation) => ({
        ...prevValidation,
        errors: {
          ...prevValidation.errors,
          [field]: `Please enter ${field.replace("_", " ")}`,
        },
        valids: { ...prevValidation.valids, [field]: false },
      }));
    } else {
      setValidation((prevValidation) => ({
        ...prevValidation,
        errors: { ...prevValidation.errors, [field]: null },
        valids: { ...prevValidation.valids, [field]: true },
      }));
    }
  };

  const fetchProgramType = async () => {
    const { data, error } = await GetRequest(
      API + "basevalues/?title_code=program_type",
      {},
      { Authorization: "Bearer " + token }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setProgramTypOpt(data);
    }
  };

  const fetchProgramPackages = async () => {
    const { data, error } = await GetRequest(
      API + "basevalues/?title_code=program_package",
      {},
      { Authorization: "Bearer " + token }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setProgramPkgOpt(data);
    }
  };

  const fetchCustomer = async () => {
    const { data, error } = await GetRequest(
      API + "customer/",
      {},
      { Authorization: "Bearer " + token }
    );
    if (!error) {
      setCustomerOpt(data);
    }
  };

  const fetchSeverity = async () => {
    const { data, error } = await GetRequest(
      API + "basevalues/?title_code=Severity",
      {},
      { Authorization: "Bearer " + token }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setSeverityOpt(data.results);
    }
  };

  const checkAllValidity = () => {
    const { badge_title, badge_code, image, badge_description } = form;
    const allError = {};

    if (!badge_title || badge_title === "")
      allError.badge_title = "Please enter Badge Title";

    if (!badge_code || badge_code === "")
      allError.badge_code = "Please enter Badge Code";

    if (!image || image === "") allError.image = "Please upload Badge Image";

    if (!badge_description || badge_description === "")
      allError.badge_description = "Please enter Badge Description";

    if (!terms || terms === "")
      allError.terms_conditions = "Please enter Terms and Conditions";

    return allError;
  };

  const { invokeApi } = HTTPService();
  const finalSubmit = async () => {
    const checkError = checkAllValidity();
    if (Object.keys(checkError).length > 0) {
      setValidation((prevValidation) => ({
        ...prevValidation,
        errors: { ...prevValidation.errors, ...checkError },
      }));
    } else {
      const reqBody = {
        ...form,
        created_by: localStorage.getItem("userid"),
        terms_conditions: terms,
      };
      if (typeof reqBody.image === "string") {
        delete reqBody.image;
      }
      const method = id ? "PATCH" : "POST";
      invokeApi({
        method,
        url: `badges/${id ? `${id}/` : ""}`,
        data: reqBody,
      })
        .then((data) => {
          customToast.success("Badge saved successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          navigate("/admin/badges/dashboard");
        })
        .catch((error) => {
          customToast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        });
    }
  };

  const handleFinalClick = () => {
    const checkError = checkAllValidity();
    if (Object.keys(checkError).length > 0) {
      setValidation((prevValidation) => ({
        ...prevValidation,
        errors: { ...prevValidation.errors, ...checkError },
      }));
      setOpenAddModal(false);
      customToast.error("Resolve all errors!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setOpenAddModal(true);
    }
  };

  const Breadcrumbname = {
    Home: "/admin/home",
    Payments: "",
    Badges: "/admin/badges/dashboard",
    "Add New Badges": "",
  };

  useEffect(() => {
    fetchProgramType();
    fetchProgramPackages();
    fetchCustomer();
    fetchSeverity();
  }, []);

  return (
    <>
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-12 m-auto ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
        </div>
        <div className="card card-body mt-4">
          <div className="row">
            <div className="col-lg-9">
              <h6 className="mb-0">Add Badges Details</h6>
              <hr className="horizontal dark my-3" />

              <div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mt-2">
                    <Form.Label>
                      Title <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="badgetitle"
                      placeholder="Enter a Badge Title"
                      value={form.badge_title || ""}
                      onChange={(e) =>
                        handleChange("badge_title", e.target.value)
                      }
                      isInvalid={!!validation.errors.badge_title}
                      isValid={validation.valids.badge_title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {validation.errors.badge_title}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mt-2">
                    <Form.Label>
                      Code <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="badgecode"
                      placeholder="Enter a Badge Code"
                      value={form.badge_code || ""}
                      onChange={(e) =>
                        handleChange("badge_code", e.target.value)
                      }
                      isInvalid={!!validation.errors.badge_code}
                      isValid={validation.valids.badge_code}
                    />
                    <Form.Control.Feedback type="invalid">
                      {validation.errors.badge_code}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <Form.Group>
                <Form.Label>
                  Badge Icon <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div className="form-control dotted">
                  <div className="file-upload">
                    {logoName || <p>Upload Logo</p>}
                    <Form.Control
                      type="file"
                      onChange={(e) => {
                        handleChange("image", e.target.files[0]);
                        setLogoName(e.target.files[0].name);
                      }}
                      isInvalid={!!validation.errors.image}
                      isValid={validation.valids.image}
                    />
                    <Form.Control.Feedback type="invalid">
                      {validation.errors.image}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </Form.Group>
            </div>
          </div>

          <Form.Group className="mt-2">
            <Form.Label>
              Description <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <CKEditor
              editor={ClassicEditor}
              data={form.badge_description || ""}
              onChange={(event, editor) =>
                handleChange("badge_description", editor.getData())
              }
            />
            {!form.badge_description && (
              <p style={{ color: "#f00", fontSize: "14px" }}>
                Please Enter Badge Description
              </p>
            )}
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label>
              Terms & Conditions <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <CKEditor
              editor={ClassicEditor}
              data={terms || ""}
              onChange={(event, editor) => setTerms(editor.getData())}
            />
            {!terms && (
              <p style={{ color: "#f00", fontSize: "14px" }}>
                Please Enter Terms & Conditions
              </p>
            )}
          </Form.Group>

          <div className="row">
            <div className="col-lg-6">
              <Form.Group className="mt-2">
                <Form.Label>Sequence</Form.Label>
                <Form.Control
                  type="number"
                  name="Sequence"
                  placeholder="Enter Sequence"
                  value={form.sequence || ""}
                  onChange={(e) => handleChange("sequence", e.target.value)}
                  isInvalid={!!validation.errors.sequence}
                  isValid={validation.valids.sequence}
                />
                <Form.Control.Feedback type="invalid">
                  {validation.errors.sequence}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-4">
            <button
              type="button"
              className="btn bg-gradient-dark m-0"
              onClick={() => navigate("/admin/badges/dashboard")}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn loginbtn m-0 ms-2"
              onClick={handleFinalClick}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"SUBMIT"}
        createAddTopic={
          location.pathname === "/admin/badges/add" ? "Add Badge" : "Edit Badge"
        }
        confirmText={
          location.pathname === "/admin/badges/add"
            ? "Add The Badge"
            : "Edit The Badge"
        }
        isOpen={openAddModal}
        onSubmit={finalSubmit}
        onClose={() => setOpenAddModal(false)}
      />
    </>
  );
}
