import React, { useContext, useState } from 'react'
import HTTPService from '../../../../utils/axios';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../../reducers/authSlice';
import { formatDate } from '../../../../utils/helperFunctions';
import CommonTable from '../../Table/CommonTable';
import { toast } from 'react-toastify';
import CircularLoader from '../../../CircularLoader/CircularLoader';
import { MenuItem, Select } from '@mui/material';
import { Link } from 'react-router-dom';
import AppContext from '../../../Context/AppContext';
import { customToast } from '../../../../App';

const Breadcrumbname = {
    home: "/admin/home",
    Integrations: "",
    Jira: "",
};

const tableColumn = [
    { label: "Cloud Id", align: 'start' },
    "Name",
    "Url",
    "Created at",
    "Action",
];
const Jira = () => {
    const { invokeApi } = HTTPService();
    const { loading, setLoading } = useContext(AppContext);
    const { userInfo, permissions } = useSelector(getUserInfo);
    const [channels, setChannels] = useState({})
    const [totalPages, setTotalPages] = useState(1)
    const [searchValue, setSearchValue] = useState("");
    const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [prorgams, setPrograms] = useState([]);
    const [selectedJiraItem, setSelectedJiraItem] = useState();
    const [projectList, setProjectList] = useState();
    const [program, setProgram] = useState(' ');
    const [selectedProject, setSelectedProject] = useState(' ');

    const handleClick = () => {
        setLoading(true)
        invokeApi({
            method: "GET",
            url: `jira/auth/login`,
        }).then(res => {
            window.location.href = res.authorization_url
            // setLoading(false)
        }).catch(error => {
            setLoading(false)
        })
    }

    const fetchChannels = () => {
        setLoading(true);
        invokeApi({
            method: "GET",
            url: `jira/get-instances`,
        }).then(res => {
            setLoading(false);
            setChannels(res)
            setTotalPages(res.totalPages ?? 1)
        }).catch(error => {
            setLoading(false);

        })
    }

    const fetchProjectList = () => {
        setLoading(true);
        invokeApi({
            method: "POST",
            url: `/jira/list-project`,
            data: {
                cloud_name: selectedJiraItem.name
            }, headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            console.log(res)
            setLoading(false);
            if (res.length < 1) {
                customToast.error("No project found!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                });
                return
            }
            setProjectList(res.map(item => ({ ...item, value: item.id, label: item.name })))
        }).catch(error => {

            setLoading(false);
        })
    }

    const getPrograms = () => {
        let arr = [];
        let url = `programs/`
        if (userInfo?.role === 'customer' && userInfo?.user_data?.id)
            url = `programs/?customer=${userInfo.user_data.id}`
        invokeApi({
            method: "GET",
            url,
        }).then((data) => {
            data.results.map(option => {
                arr.push({
                    label: `${option.program_id} (${option.program_title.length > 30
                        ? option.program_title.slice(0, 30) + "..."
                        : option.program_title
                        })`,
                    value: option.id,
                });
            });
            setPrograms(arr);
        }).catch((error) => {
            customToast.error("Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        });
    }


    useEffect(() => {
        if (userInfo?.user_data?.id) {
            fetchChannels()
            getPrograms();
        }
    }, [userInfo?.user_data?.id])


    useEffect(() => {
        if (selectedJiraItem) {
            fetchProjectList()
        }
    }, [selectedJiraItem])

    const handleSubmitMapProject = () => {
        const selectPrj = projectList.find(prj => prj.id == selectedProject)
        invokeApi({
            method: "POST",
            url: `jira/map-project`,
            data: {
                project_key: selectPrj.key,
                project_name: selectPrj.name,
                program_id: program,
                cloud_name: selectedJiraItem.name
            }
        }).then(res => {
            customToast.success("Program configuration added!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            })
            console.log(res)
        }).catch(error => {
            const flattenedErrors = Object.keys(error.data)
                .map(key => key.replaceAll('_', ' ').toUpperCase() + ": " + error.data[key].join('\n'))
                .join('\n');
            customToast.error(flattenedErrors ?? "Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
        })
    }

    return (
        <div>
            {loading &&
                <CircularLoader size={100} />}
            <div className="container-fluid py-4 pb-0">
                <div className="row">
                    <div className="col-lg-6 ps-3">
                        <Breadcrumb names={Breadcrumbname} />
                    </div>
                    <div className="col-lg-6">
                        <div className="button-row d-flex justify-content-end mt-0 mb-3">
                            <button className="btn bg-gradient-dark me-3 mb-0 text-capitalize" onClick={handleClick}>Add Jira Account</button>
                        </div>
                    </div>
                </div>

                <div className="row my-4">
                    <div className="col-12">
                        <CommonTable
                            column={tableColumn}
                            data={channels?.results}
                            totalPages={totalPages ?? 1}
                            itemPerPg={25}
                            setTable={1}
                            card={7}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            searchPlaceHolder={searchPlaceHolder}
                            setSearchPlaceHolder={setSearchPlaceHolder}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            searchBy={[]}
                            totalData={channels?.total_records}
                        >
                            <>
                                {channels?.results &&
                                    channels?.results.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <p
                                                    className="text-sm  cursor-pointer mb-0 text-start"
                                                    style={{ color: "#5A78F0" }}
                                                >
                                                    {item.cloudId}
                                                </p>
                                            </td>
                                            <td className="text-start">
                                                <p
                                                    className="text-sm  cursor-pointer mb-0 px-2"
                                                    style={{ color: "#5A78F0" }}
                                                >
                                                    {item.name}
                                                </p>
                                            </td>
                                            <td className="text-start">
                                                <p className="text-sm  mb-0">{item.url}</p>
                                            </td>
                                            <td className="text-center">
                                                <p className="text-sm  mb-0 text-center">
                                                    {formatDate(item.created_at, 1)}
                                                </p>
                                            </td>
                                            <td className="text-center">
                                                <Link className='btn btn-primary bg-primary' to={`/${userInfo?.role}/integrations/jira/${item.name}`}>View projects</Link>
                                            </td> <td className="text-center">
                                                <button onClick={() => {
                                                    setShowModal(true)
                                                    setSelectedJiraItem(item)
                                                }} className='btn btn-primary bg-primary'>Add Program</button>
                                            </td>
                                        </tr>
                                    ))}
                            </>
                        </CommonTable>
                    </div>
                </div>
            </div>

            {showModal ? (
                <div className="changeStatus" style={{ zIndex: 2 }}>
                    <div className="changeStatusBody">
                        <div className="changeStatusBodyTop">
                            <i className="fa-solid fa-circle-exclamation fa-rotate-180"></i>
                            <p>Map Project with Program</p>
                        </div>
                        <div className="p-4">
                            <Select
                                fullWidth
                                value={selectedProject}
                                onChange={(e) => setSelectedProject(e.target.value)}
                                sx={{
                                    mt: 1,
                                    ".paper": {
                                        height: "200px !important",
                                    },
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                }}  >
                                <MenuItem value=' ' sx={{ fontSize: '1.2rem' }}>
                                    Select Project
                                </MenuItem>
                                {projectList?.map((option) => (
                                    <MenuItem key={option.value} value={option.value} sx={{ fontSize: '1.2rem' }}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                fullWidth
                                value={program}
                                onChange={(e) => setProgram(e.target.value)}
                                sx={{
                                    mt: 1,
                                    ".paper": {
                                        height: "200px !important",
                                    },
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                }}  >
                                <MenuItem value=' ' sx={{ fontSize: '1.2rem' }}>
                                    Select Program
                                </MenuItem>
                                {prorgams.map((option) => (
                                    <MenuItem key={option.value} value={option.value} sx={{ fontSize: '1.2rem' }}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="changeStatusBodyLastPart">
                            <button
                                onClick={() => setShowModal(false)}
                                className="changeStatusBtnClose"
                            >
                                CLOSE
                            </button>

                            <button onClick={handleSubmitMapProject} className="changeStatusBtnSubmit">
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    )
}

export default Jira;