import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import rocket from "../../assets/svg/rocket.svg";

import { Col } from "react-bootstrap";
import { toast } from "react-toastify";
import ProfileImage from "../../../components/ProfileImage";
import HTTPService from "../../../utils/axios";
import AddModal from "../../common/modal/AddModal";
import AppContext from "../../Context/AppContext";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../reducers/authSlice";
import { customToast } from "../../../App";

export default function Companyedit() {
  const { invokeApi } = HTTPService();
  const { userInfo } = useSelector(getUserInfo);
  const [formState, setFormState] = useState(1);
  const { setCircularLoading } = useContext(AppContext);
  const [openAddModal, setOpenAddModal] = useState(false);
  const { userId: companyId } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [valids, setValids] = useState({});
  const [allotmentOpt, setAllotment] = useState();
  const [formData, setFormData] = useState({
    domain_name: "",
    legal_name: "",
    email: "",
    support_email: "",
    iso: "",
    phone_number: "",
    website_title: "",
    support_phone: "",
    landline_number: "",
    allotment_type: "",
    companyId: "",
    address1: "",
    address2: "",
    address3: "",
    landmark: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    CIN: "",
    TAN: "",
    PAN: "",
    GSTIN: "",
    facebook: "",
    instagram: "",
    twitter: "",
    pinterest: "",
    google_plus: "",
    youtube: "",
    extra: "",
    b2buser_android: "",
    b2buser_ios: "",
    header_logo: "",
    leader_logo: "",
    footer_logo: "",
    favicon_logo: "",
    linkId: "",
  });

  const fetchCompany = async (stsId) => {
    invokeApi({
      method: "GET",
      url: `/company/${companyId}/`,
    }).then((data) => {
      if (data) {
        if (data) {
          setFormData((prevData) => ({
            ...prevData,
            domain_name: data.domain_name,
            legal_name: data.legal_name,
            email: data.email,
            support_email: data.support_email,
            iso: data.iso,
            phone_number: data.phone_number,
            website_title: data.website_title,
            support_phone: data.support_phone,
            landline_number: data.landline_number,
            allotment_type: data.allotment_type,
            header_logo: data.header_logo,
            leader_logo: data.leader_logo,
            footer_logo: data.footer_logo,
            favicon_logo: data.favicon_logo,
          }));
        }
      }
    }).catch((error) => {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });

  };

  const fetchCompanyAddress = async (stsId) => {
    invokeApi({
      method: "GET",
      url: `company_address`,
    }).then((data) => {
      if (data && data.results) {
        const filteredCompanyAddreess = data.results.find(
          (item) => item.company === companyId
        );
        if (filteredCompanyAddreess) {
          setFormData((prevData) => ({
            ...prevData,
            companyId: filteredCompanyAddreess.id,
            address1: filteredCompanyAddreess.address1,
            address2: filteredCompanyAddreess.address2,
            address3: filteredCompanyAddreess.address3,
            landmark: filteredCompanyAddreess.landmark,
            phone: filteredCompanyAddreess.phone,
            city: filteredCompanyAddreess.city,
            state: filteredCompanyAddreess.state,
            country: filteredCompanyAddreess.country,
            CIN: filteredCompanyAddreess.CIN,
            TAN: filteredCompanyAddreess.TAN,
            PAN: filteredCompanyAddreess.PAN,
            GSTIN: filteredCompanyAddreess.GSTIN,
          }));
        }
      }
    }).catch((error) => {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  };

  const fetchCompanyLink = async (stsId) => {

    invokeApi({
      method: "GET",
      url: `company_link`,
    }).then((data) => {
      const filteredCompanyLink = data.results.find(
        (item) => item.company === companyId
      );
      if (filteredCompanyLink) {
        setFormData((prevData) => ({
          ...prevData,
          facebook: filteredCompanyLink.facebook,
          instagram: filteredCompanyLink.instagram,
          twitter: filteredCompanyLink.twitter,
          pinterest: filteredCompanyLink.pinterest,
          google_plus: filteredCompanyLink.google_plus,
          youtube: filteredCompanyLink.youtube,
          extra: filteredCompanyLink.extra,
          b2buser_android: filteredCompanyLink.b2buser_android,
          b2buser_ios: filteredCompanyLink.b2buser_ios,
          linkId: filteredCompanyLink.id,
        }));
      }
    }).catch((error) => {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  };

  useEffect(() => {
    if (userInfo?.user_data?.id) {
      fetchCompany();
      fetchCompanyAddress();
      fetchCompanyLink();
      fetchAllotmentType();
    }
  }, [userInfo?.user_data?.id]);


  const handleChange = async (event) => {
    const { name, type, files } = event.target;
    const field = name;

    if (type === "file") {
      const file = files[0];

      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64 = reader.result.split(",")[1];
          const extensionMatch = file.name.match(/\.([^.]+)$/);
          const extension = extensionMatch[1].toLowerCase();

          // Update formData with the new file object
          // setFormData((prevData) => ({
          // 	...prevData,
          // 	[name]: [
          // 		...(prevData[name] || []), // Ensure prevData[name] is an array or initialize as an empty array
          // 		{
          // 			type: extension,
          // 			content: base64,
          // 			size: getFileSizeInMB(file),
          // 		},
          // 	],
          // }));
          setFormData({ ...formData, [name]: file });
        };

        reader.readAsDataURL(file);
      }
      // setValidated(true);
    } else {
      const value = event.target.value;

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      if (!value || value === "") {
        setErrors({
          ...errors,
          [name]:
            field === "domain_name"
              ? "Please provide Domain Name."
              : field === "legal_name"
                ? "Please provide Legal Name."
                : field === "email"
                  ? !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
                    ? "Please provide Email."
                    : null
                  : field === "support_email"
                    ? "Please provide Support Email."
                    : field === "phone_number"
                      ? "Please provide Number."
                      : field === "website_title"
                        ? "Please provide Website Title."
                        : field === "address1"
                          ? "Please provide primary Address."
                          : field === "landmark"
                            ? "Please provide Landmark."
                            : field === "city"
                              ? "Please provide City."
                              : field === "state"
                                ? "Please provide State."
                                : field === "country"
                                  ? "Please provide Country."
                                  : field === "CIN"
                                    ? "Please provide CIN Number."
                                    : field === "TAN"
                                      ? "Please provide TAN Number."
                                      : field === "PAN"
                                        ? "Please provide PAN Number."
                                        : field === "GSTIN"
                                          ? "Please provide GSTIN Number."
                                          : null,
        });
        setValids({
          ...valids,
          [name]: false,
        });
      } else {
        if (
          field === "email" &&
          !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
        ) {
          setErrors({
            ...errors,
            [name]: "Please provide Valid Email.",
          });
          setValids({
            ...valids,
            [name]: false,
          });
        } else if (
          field === "support_email" &&
          !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
        ) {
          setErrors({
            ...errors,
            [name]: "Please provide Valid Support Email.",
          });
          setValids({
            ...valids,
            [name]: false,
          });
        } else {
          setValids({
            ...valids,
            [name]: true,
          });

          setErrors({
            ...errors,
            [name]: null,
          });
        }
      }

      // setValidated(true);
    }
  };

  const addressId = formData.companyId;
  const LinkId = formData.linkId;

  const handalSubmit = async (event) => {
    setOpenAddModal(false);
    setCircularLoading(true);
    event.preventDefault();
    event.stopPropagation();

    const files = [];

    if (formData && typeof formData.header_logo === "string") {
      delete formData.header_logo;
    } else {
      setFormData({
        ...formData,
        header_logo: files && files[0] ? files[0] : null,
      });
    }
    if (formData && typeof formData.leader_logo === "string") {
      delete formData.leader_logo;
    } else {
      setFormData({
        ...formData,
        leader_logo: files && files[1] ? files[1] : null,
      });
    }
    if (formData && typeof formData.footer_logo === "string") {
      delete formData.footer_logo;
    } else {
      setFormData({
        ...formData,
        footer_logo: files && files[2] ? files[2] : null,
      });
    }
    if (formData && typeof formData.favicon_logo === "string") {
      delete formData.favicon_logo;
    } else {
      setFormData({
        ...formData,
        favicon_logo: files && files[3] ? files[3] : null,
      });
    }

    const param = {
      id: companyId,
      domain_name: formData.domain_name,
      legal_name: formData.legal_name,
      email: formData.email,
      support_email: formData.support_email,
      phone_number: formData.phone_number,
      support_phone: formData.support_phone,
      website_title: formData.website_title,
      landline_number: formData.landline_number,
      allotment_type: formData.allotment_type,
      header_logo: formData.header_logo,
      leader_logo: formData.leader_logo,
      footer_logo: formData.footer_logo,
      favicon_logo: formData.favicon_logo,
    };

    invokeApi({
      method: "PATCH",
      url: `company/${companyId}/`,
      data: param
    }).then((data) => {
      setCircularLoading(false);
      customToast.success("Company Update successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }).catch((error) => {
      setCircularLoading(false);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });




  };

  const handleUpdateAddress = () => {
    const Addparam = {
      address1: formData.address1,
      address2: formData.address2,
      address3: formData.address3,
      landmark: formData.landmark,
      phone: formData.phone,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      CIN: formData.CIN,
      TAN: formData.TAN,
      PAN: formData.PAN,
      GSTIN: formData.GSTIN,
      company: companyId,
      id: addressId,
      is_deleted: false,
    };
    invokeApi({
      method: "PATCH",
      url: `company_address/${addressId}/`,
      data: Addparam
    }).then((data) => {
      setCircularLoading(false);
      customToast.success("CompanyAddress Update successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }).catch((error) => {
      setCircularLoading(false);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  }

  const handleUpdateLinks = () => {
    const Linkparam = {
      facebook: formData.facebook,
      instagram: formData.instagram,
      twitter: formData.twitter,
      pinterest: formData.pinterest,
      google_plus: formData.google_plus,
      youtube: formData.youtube,
      extra: formData.extra,
      b2buser_android: formData.b2buser_android,
      b2buser_ios: formData.b2buser_ios,
      company: companyId,
    };

    invokeApi({
      method: "PATCH",
      url: `company_link/${LinkId}/`,
      data: Linkparam
    }).then((data) => {
      setCircularLoading(false);
      customToast.success("CompanyLink Update successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }).catch((error) => {
      setCircularLoading(false);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  }
  const fetchAllotmentType = async () => {
    invokeApi({
      method: "GET",
      url: `basevalues/?title_code=allotment_type`,
    }).then((data) => {
      setAllotment(data.results[0].value_options);
    }).catch((error) => {
      console.log(error);
    });
  };

  return (
    <>
      <div className="bg-gray-100">
        <main className="position-relative mt-0">
          <div
            className="container-fluid pt-4  "
            style={{ background: "#f5f5fb" }}
          >
            <div className="row align-items-center">
              <div className="col-lg-4 col-sm-8">
                <div className="nav-wrapper position-relative end-0">
                  <ul className="nav nav-pills nav-fill p-1" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className={
                          formState === 1
                            ? "cursorPointer nav-link mb-0 px-0 py-1 active"
                            : "cursorPointer nav-link mb-0 px-0 py-1 "
                        }
                        data-bs-toggle="tab"
                        to="#mainprofile"
                        role="tab"
                        aria-selected="true"
                        onClick={() => setFormState(1)}
                      >
                        Main Profile
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`cursorPointer nav-link mb-0 px-0 py-1  ${formState === 2 ? "active" : ''}`}
                        data-bs-toggle="tab"
                        to="#addressreg"
                        role="tab"
                        aria-selected="false"
                        onClick={() => setFormState(2)}
                      >
                        Address & Reg
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`cursorPointer nav-link mb-0 px-0 py-1  ${formState === 3 ? "active" : ''
                          }`}
                        data-bs-toggle="tab"
                        to="#otherinformation"
                        role="tab"
                        aria-selected="false"
                        onClick={() => setFormState(3)}
                      >
                        Other Information
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`cursorPointer nav-link mb-0 px-0 py-1  ${formState === 4 ? "active" : ''}`}
                        data-bs-toggle="tab"
                        to="#social"
                        role="tab"
                        aria-selected="false"
                        onClick={() => setFormState(4)}
                      >
                        Social
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>

            <div className="card card-body mt-4" id="profile">
              <div className="row justify-content-center align-items-center">
                <div className="col-sm-auto col-4">
                  <div className="avatar avatar-xl position-relative">
                    <ProfileImage src={formData?.leader_logo} alt="bruce"
                      className="w-100 border-radius-lg shadow-sm" />
                  </div>
                </div>
                <div className="col-sm-auto col-8 my-auto">
                  <div className="h-100">
                    <h5 className="mb-1 font-weight-bolder">
                      {formData?.legal_name}
                    </h5>
                    <p className="mb-0 font-weight-bold text-sm">
                      {formData?.domain_name}
                    </p>
                  </div>
                </div>
                <div className="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                </div>
              </div>
            </div>

            <div className="container-fluid my-3 py-3 mb-0 p-0">
              <div className="row mb-5">
                <div className="col-lg-3">
                  <div className="card position-sticky top-1">
                    <ul className="nav flex-column bg-white border-radius-lg p-3">
                      <li className="nav-item">
                        <HashLink
                          smooth
                          className="nav-link text-body"
                          data-scroll=""
                          to="#profile"
                        >
                          <div className="icon me-2">
                            <img src={rocket} alt="" />
                          </div>
                          <span className="text-sm">Profile</span>
                        </HashLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-9 mt-lg-0 mt-4">
                  {/* <!-- Card Basic Info --> */}
                  <Form className="multisteps-form__form mb-2">
                    {formState === 1 && (
                      <div className="card " id="#">
                        <div className="card-header pb-2">
                          <h5>Main Profile</h5>
                        </div>

                        <div className="card-body pt-0">
                          <div className="row">
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Domain Name">
                                Allotment Type
                              </Form.Label>
                              <Form.Select
                                className="multisteps-form__input"
                                type="text"
                                name="allotment_type"
                                placeholder="Enter Domain Name"
                                required
                                // onChange={(e) =>
                                // 	setDomainName("domain_name", e.target.value)
                                value={
                                  formData &&
                                  formData.allotment_type &&
                                  formData.allotment_type
                                }
                                isValid={
                                  valids.allotment_type &&
                                  valids.allotment_type === true
                                }
                                onChange={handleChange}
                              >
                                {/* <option value="">Select Allotment Type</option> */}
                                {allotmentOpt?.split(",").map((item, index) => (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </Form.Select>
                              {/* <Form.Control.Feedback type="invalid">
																  {errors.domain_name}
															  </Form.Control.Feedback> */}
                            </Form.Group>
                            <Form.Group className="col-4">
                              <Form.Label
                                label="Domain Name"
                                className="form-label"
                              >
                                Domain Name
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  id="Domainname"
                                  name="domain_name"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Domain Name"
                                  isInvalid={!!errors.domain_name}
                                  isValid={
                                    valids.domain_name &&
                                    valids.domain_name === true
                                  }
                                  // value={user && user[0].domin ? "domain name":"domain name"}
                                  defaultValue={
                                    formData &&
                                    formData.domain_name &&
                                    formData.domain_name
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.domain_name}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            <Form.Group className="col-4">
                              <Form.Label className="form-label ">
                                Legal Name
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  id="Legalname"
                                  name="legal_name"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Legal Name"
                                  isInvalid={!!errors.legal_name}
                                  isValid={
                                    valids.legal_name &&
                                    valids.legal_name === true
                                  }
                                  defaultValue={
                                    formData &&
                                    formData.legal_name &&
                                    formData.legal_name
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.legal_name}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Email
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  type="email"
                                  placeholder="Enter Email"
                                  isInvalid={!!errors.email}
                                  isValid={
                                    valids.email && valids.email === true
                                  }
                                  defaultValue={
                                    formData && formData.email && formData.email
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.email}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>

                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Support Mail
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  id="email"
                                  name="support_email"
                                  className="form-control"
                                  type="email"
                                  placeholder="Enter Optional Mail"
                                  defaultValue={
                                    formData &&
                                    formData.support_email &&
                                    formData.support_email
                                  }
                                  isInvalid={!!errors.support_email}
                                  isValid={
                                    valids.support_email &&
                                    valids.support_email === true
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.support_email}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Websit Title
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  id="email"
                                  name="website_title"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Websit Title"
                                  isInvalid={!!errors.website_title}
                                  isValid={
                                    valids.website_title &&
                                    valids.website_title === true
                                  }
                                  defaultValue={
                                    formData &&
                                    formData.website_title &&
                                    formData.website_title
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.website_title}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            <div className="col-1">
                              <Form.Label className="form-label mt-4">
                                ISO
                              </Form.Label>
                              <Form.Select className="form-control">
                                <option value="+91" selected>
                                  IN
                                </option>
                                <option value="+03">UK</option>
                                <option value="+1">US</option>
                                <option value="#">CA</option>
                                <option value="#">CA</option>
                              </Form.Select>
                            </div>
                            <Form.Group className="col-5">
                              <Form.Label className="form-label mt-4">
                                Number
                              </Form.Label>
                              <div className="input-group ">
                                {/* <span className="input-group-text">+91</span> */}
                                <Form.Control
                                  name="phone_number"
                                  className="form-control"
                                  placeholder="Enter Number"
                                  type="number"
                                  // value={user && user[0].phone ? "phone":"phone"}
                                  isInvalid={!!errors.phone_number}
                                  isValid={
                                    valids.phone_number &&
                                    valids.phone_number === true
                                  }
                                  defaultValue={
                                    formData &&
                                    formData.phone_number &&
                                    formData.phone_number
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.phone_number}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Support Contact
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="support_phone"
                                  className="form-control"
                                  type="number"
                                  placeholder="Support Contact Number"
                                  isValid={
                                    valids.support_phone &&
                                    valids.support_phone === true
                                  }
                                  defaultValue={
                                    formData &&
                                    formData.support_phone &&
                                    formData.support_phone
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.support_phone}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            <Form.Group className="col-sm-6">
                              <Form.Label className="form-label mt-4">
                                Land Line Number
                              </Form.Label>
                              <div className="input-group ">
                                <Form.Control
                                  name="landline_number"
                                  className="form-control"
                                  placeholder="Enter Landline Number"
                                  type="number"
                                  // value={user && user[0].landlinenumber ? "land":"land"}
                                  defaultValue={
                                    formData &&
                                    formData.landline_number &&
                                    formData.landline_number
                                  }
                                  isValid={
                                    valids.landline_number &&
                                    valids.landline_number === true
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.landline_number}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            {/* <Form.Group className="col-sm-6">
														  <Form.Label className="form-label mt-4">
															  Allotment Type
														  </Form.Label>
														  <div className="input-group ">
															  <Form.Control
																  name="allotment_type"
																  className="form-control"
																  placeholder="Enter Allotment Type"
																  type="text"
																  defaultValue={
																	  formData &&
																	  formData.allotment_type &&
																	  formData.allotment_type
																  }
																  // isValid={
																  // 	valids.allotment_type &&
																  // 	valids.allotment_type === true
																  // }
																  onChange={handleChange}
															  />
														  </div>
													  </Form.Group> */}
                          </div>
                        </div>
                        <div className="mb-4 mr-2">
                          <button
                            type="button"
                            className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0"
                            onClick={() => setOpenAddModal(true)}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    )}
                    {formState === 2 && (
                      <div className="card mt-4" id="#">
                        <div className="card-header pb-0">
                          <h5>Address & Registration</h5>
                        </div>
                        <div className="card-body pt-0">
                          {/* address & registration end*/}
                          <div className="row">
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Address 1
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="address1"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Address1"
                                  //
                                  isInvalid={!!errors.address1}
                                  isValid={
                                    valids.address1 && valids.address1 === true
                                  }
                                  defaultValue={
                                    formData &&
                                    formData.address1 &&
                                    formData.address1
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.address1}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Address 2
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="address2"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Address2"
                                  //
                                  // value={user[0].address2}
                                  defaultValue={formData.address2}
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Address 3
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="address3"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Address3"
                                  //
                                  // value={user[0].address3}
                                  defaultValue={formData.address3}
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Landmark
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="landmark"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Nearest Landmark"
                                  //
                                  isInvalid={!!errors.landmark}
                                  isValid={
                                    valids.landmark && valids.landmark === true
                                  }
                                  defaultValue={
                                    formData &&
                                    formData.landmark &&
                                    formData.landmark
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.landmark}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Phone
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="phone"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Phone Number"
                                  //
                                  // value={user[0].phone}
                                  defaultValue={
                                    formData && formData.phone && formData.phone
                                  }
                                  isValid={
                                    valids.phone && valids.phone === true
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.phone}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                City
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="city"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter City"
                                  //
                                  isInvalid={!!errors.city}
                                  isValid={valids.city && valids.city === true}
                                  defaultValue={
                                    formData && formData.city && formData.city
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.city}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                State
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="state"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter State"
                                  //
                                  isInvalid={!!errors.state}
                                  isValid={
                                    valids.state && valids.state === true
                                  }
                                  defaultValue={
                                    formData && formData.state && formData.state
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.state}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Country
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="country"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Country"
                                  //
                                  //value={user[0].country}
                                  defaultValue={
                                    FormData &&
                                    formData.country &&
                                    formData.country
                                  }
                                  isInvalid={!!errors.country}
                                  isValid={
                                    valids.country && valids.country === true
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.country}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                CIN
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="CIN"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter CIN Number"
                                  //
                                  isInvalid={!!errors.CIN}
                                  isValid={valids.CIN && valids.CIN === true}
                                  defaultValue={
                                    formData && formData.CIN && formData.CIN
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.CIN}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                TAN
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="TAN"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter TAN Number"
                                  isInvalid={!!errors.TAN}
                                  isValid={valids.TAN && valids.TAN === true}
                                  defaultValue={
                                    formData && formData.TAN && formData.TAN
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.TAN}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                PAN
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="PAN"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter PAN Number"
                                  //
                                  isInvalid={!!errors.PAN}
                                  isValid={valids.PAN && valids.PAN === true}
                                  defaultValue={
                                    formData && formData.PAN && formData.PAN
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.PAN}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                GSTIN
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="GSTIN"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter GSTIN Number"
                                  //
                                  isInvalid={!!errors.GSTIN}
                                  isValid={
                                    valids.GSTIN && valids.GSTIN === true
                                  }
                                  defaultValue={
                                    formData && formData.GSTIN && formData.GSTIN
                                  }
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.GSTIN}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="mb-4 mr-2">
                          <button
                            type="button"
                            className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0"
                            onClick={handleUpdateAddress}
                          >
                            Update Address
                          </button>
                        </div>
                      </div>
                    )}
                    {formState === 3 && (
                      <div className="card mt-4" id="#">
                        <div className="card-header pb-0">
                          <h5>Other Information</h5>
                        </div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Header Logo
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="header_logo"
                                  className="form-control"
                                  type="file"
                                  //defaultValue={formData && formData.header_logo && formData.header_logo}
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Loader
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="leader_logo"
                                  className="form-control"
                                  type="file"
                                  // isValid={
                                  // 	valids.leader_logo &&
                                  // 	valids.leader_logo === true
                                  // }
                                  //defaultValue={formData && formData.leader_logo && formData.leader_logo}
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Footer Logo
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="footer_logo"
                                  className="form-control"
                                  type="file"
                                  // isValid={
                                  // 	valids.footer_logo &&
                                  // 	valids.footer_logo === true
                                  // }
                                  //defaultValue={formData && formData.footer_logo && formData.footer_logo}
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                Favicon Logo
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="favicon_logo"
                                  className="form-control"
                                  type="file"
                                  // isValid={
                                  // 	valids.favicon_logo &&
                                  // 	valids.favicon_logo === true
                                  // }
                                  //defaultValue={formData && formData.favicon_logo && formData.favicon_logo}
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className="mb-4 mr-2">
                            <button
                              type="button"
                              className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0"
                              onClick={() => setOpenAddModal(true)}
                            >
                              Update Images
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {formState === 4 && (
                      <div className="card mt-4" id="#">
                        <div className="card-header pb-0">
                          <h5>Social</h5>
                        </div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                B2B User Android
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="b2buser_android"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Android Link"
                                  isValid={
                                    valids.b2buser_android &&
                                    valids.b2buser_android === true
                                  }
                                  defaultValue={
                                    formData &&
                                    formData.b2buser_android &&
                                    formData.b2buser_android
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group className="col-6">
                              <Form.Label className="form-label mt-4">
                                B2B User ISO
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="b2buser_ios"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter ISO Link"
                                  isValid={
                                    valids.b2buser_ios &&
                                    valids.b2buser_ios === true
                                  }
                                  defaultValue={
                                    formData &&
                                    formData.b2buser_ios &&
                                    formData.b2buser_ios
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group className="col-4">
                              <Form.Label className="form-label mt-4">
                                Facebook
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="facebook"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Facebook URL"
                                  //
                                  isValid={
                                    valids.facebook && valids.facebook === true
                                  }
                                  defaultValue={
                                    formData &&
                                    formData.facebook &&
                                    formData.facebook
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group className="col-4">
                              <Form.Label className="form-label mt-4">
                                Instagram
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="instagram"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Instagram URL"
                                  //
                                  isValid={
                                    valids.instagram &&
                                    valids.instagram === true
                                  }
                                  defaultValue={
                                    formData &&
                                    formData.instagram &&
                                    formData.instagram
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group className="col-4">
                              <Form.Label className="form-label mt-4">
                                Twitter
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="twitter"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Twitter URL"
                                  //
                                  // value={user[0].twitter}
                                  defaultValue={
                                    formData &&
                                    formData.twitter &&
                                    formData.twitter
                                  }
                                  isValid={
                                    valids.twitter && valids.twitter === true
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label mt-4">
                                Linkedin
                              </label>
                              <div className="input-group">
                                <input
                                  name="pinterest"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Pinterest URL"
                                  //
                                  isValid={
                                    valids.pinterest &&
                                    valids.pinterest === true
                                  }
                                  defaultValue={
                                    formData &&
                                    formData.pinterest &&
                                    formData.pinterest
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <Form.Label className="col-4">
                              <Form.Label className="form-label mt-4">
                                Google My Account
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="google_plus"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Google  URL"
                                  //
                                  isValid={
                                    valids.google_plus &&
                                    valids.google_plus === true
                                  }
                                  defaultValue={
                                    formData &&
                                    formData.google_plus &&
                                    formData.google_plus
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Label>
                            <Form.Group className="col-4">
                              <Form.Label className="form-label mt-4">
                                Youtube
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="youtube"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Youtube URL"
                                  //
                                  isValid={
                                    valids.youtube && valids.youtube === true
                                  }
                                  defaultValue={
                                    formData &&
                                    formData.youtube &&
                                    formData.youtube
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group className="col-4">
                              <Form.Label className="form-label mt-4">
                                Extra
                              </Form.Label>
                              <div className="input-group">
                                <Form.Control
                                  name="extra"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Extra URL"
                                  isValid={
                                    valids.extra && valids.extra === true
                                  }
                                  defaultValue={
                                    formData && formData.extra && formData.extra
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="mb-4 mr-2">
                          <button
                            type="button"
                            className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0"
                            onClick={handleUpdateLinks}
                          >
                            Update Socials
                          </button>
                        </div>
                      </div>
                    )}


                  </Form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"SUBMIT"}
        createAddTopic={"Edit Company"}
        confirmText={"Edit The Company"}
        isOpen={openAddModal}
        onSubmit={handalSubmit}
        onClose={() => setOpenAddModal(false)}
      />
    </>
  );
}
