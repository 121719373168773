import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../reducers/authSlice";
import jsPDF from "jspdf";
import { customToast } from "../../../App";
const scopeUrlConst = [
  "scope_items_url1",
  "scope_items_url2",
  "scope_items_url3",
  "scope_items_url4",
  "scope_items_url5",
];
const targetUrl = [
  "target_url1",
  "target_url2",
  "target_url3",
  "target_url4",
  "target_url5",
];
const attachmentsConst = [
  "attachments_upload_doc1",
  "attachments_upload_doc2",
  "attachments_upload_doc3",
  "attachments_upload_doc4",
  "attachments_upload_doc5",
  "evidence_attachments1",
  "evidence_attachments2",
  "evidence_attachments3",
  "evidence_attachments4",
  "evidence_attachments5",
];
const ResearcherReport = () => {
  const { id } = useParams();
  const [program, setProgam] = useState();
  const [submissions, setSubmissions] = useState();
  const [researcher, setResearcher] = useState();
  const [submissionStats, setSubmissionStats] = useState({});
  const { invokeApi } = HTTPService();
  const { userAccData, userInfo } = useSelector(getUserInfo);
  const navigate = useNavigate();
  const [routeLink, setRouteLink] = useState();
  const [vtxResponses, setVtxResponses] = useState([]);
  const [vtMainList, setVtMainList] = useState({});
  const [programTitles, setProgramTitles] = useState([]);

  useEffect(() => {
    if (userInfo.role == "researcher") {
      setRouteLink(`/researcher/programs/`);
    } else if (userInfo.role == "customer") {
      setRouteLink(`/customer/programs/`);
    } else {
      setRouteLink(`/admin/programs/`);
    }
  }, [userInfo]);

  const fetchResearcher = async () => {
    invokeApi({
      method: "GET",
      url: `/researcher/${id}`,
    })
      .then((data) => {
        setResearcher(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const fetchStats = async () => {
    invokeApi({
      method: "GET",
      url: `/submissions/status-count/`,
    })
      .then((data) => {
        setSubmissionStats(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const fetchSubmissions = async () => {
    try {
      const data = await invokeApi({
        method: "GET",
        url: `/submissions/`,
        params: {
          user_id: id,
        },
      });

      setSubmissions(data);

      const vtxPromises = data.results
        .filter((submission) => submission.vtx)
        .map((submission) =>
          invokeApi({
            method: "GET",
            url: `/submissions-vtx?vtx_code=${submission.vtx}`,
          }).then((vtxResponse) => ({
            ...vtxResponse.results[0],
            submission_id: submission.submissions_id,
            severity: submission.severity,
          }))
        );

      const programIds = [
        ...new Set(data.results.map((submission) => submission.program_id)),
      ];
      const programPromises = programIds.map((programId) =>
        invokeApi({
          method: "GET",
          url: `/programs/${programId}/`,
        }).then((programResponse) => programResponse.program_title)
      );

      const [vtxResponses, programTitles] = await Promise.all([
        Promise.all(vtxPromises),
        Promise.all(programPromises),
      ]);

      const newVtMainList = { ...vtMainList };
      vtxResponses.forEach((vtxResponse) => {
        const mainCategory = vtxResponse.main_category;
        newVtMainList[mainCategory] = {
          genericDescription: vtxResponse.generic_description,
          appendix: vtxResponse.appendix,
        };
      });

      setVtMainList(newVtMainList);
      setVtxResponses(vtxResponses);

      const uniqueProgramTitles = [...new Set(programTitles)];
      setProgramTitles(uniqueProgramTitles);
    } catch (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchResearcher();
      fetchSubmissions();
      fetchStats();
    }
  }, [id]);

  const pdfRef = useRef(null);

  const postReport = () => {
    const input = pdfRef.current;
    const pdf = new jsPDF("p", "mm", "a4", true);

    pdf.html(input, {
      callback: async function (pdf) {
        const pdfBlob = pdf.output("blob");

        const formData = new FormData();
        formData.append("report", pdfBlob, "report.pdf");
        formData.append("title", `Researcher Report(${researcher?.id})`);
        formData.append("report_type", "Researcher Report");
        formData.append("reference_id", id);
        formData.append("reference_type", "Researcher");
        formData.append("user_id", userAccData?.id);

        try {
          await invokeApi({
            method: "POST",
            url: `/report/`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          customToast.success("PDF uploaded successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          // setAgreementModal(false);
          // setSafeHarbor(true);
          // pdf.save("agreement.pdf");
        } catch (error) {
          customToast.error("Failed to upload PDF!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      },
      x: 2,
      y: 2,
      html2canvas: {
        scale: 0.15,
      },
    });
  };

  const transformData = (data) => {
    const countMap = {};

    data.forEach((item) => {
      if (!item.main_category) {
        return;
      }
      const key = item.main_category;
      // const subCategory = item.sub_category ? item.sub_category : "N/A";
      // const severity = item.severity ? item.severity : "N/A";
      const uniqueKey = `${key}`;

      if (countMap[uniqueKey]) {
        countMap[uniqueKey].count += 1;
      } else {
        countMap[uniqueKey] = {
          key,
          // subCategory,
          // severity,
          count: 1,
        };
      }
    });

    return Object.values(countMap);
  };

  const [transformedData, setTransformedData] = useState([]);
  useEffect(() => {
    if (vtxResponses) {
      setTransformedData(transformData(vtxResponses));
    }
  }, [vtxResponses]);

  return (
    <>
      <div ref={pdfRef} className="container report">
        <h3 className="text-center">Report based on Researcher</h3>
        <div>Bug Bounty Submission Report: </div>
        <div>
          <div
            className="sub-title"
            style={{
              fontSize: "1.25rem",
              marginBottom: "0.1rem",
              fontWeight: "600",
              color: "#333333",
            }}
          >
            1. Researcher Information:
          </div>
          <div>
            <ul>
              <li
                style={{
                  fontSize: "1.25rem",
                  marginBottom: "0.1rem",
                  fontWeight: "600",
                  color: "#333333",
                }}
              >
                Name: {researcher?.first_name} {researcher?.last_name}
              </li>
              <li
                style={{
                  fontSize: "1.25rem",
                  marginBottom: "0.1rem",
                  fontWeight: "600",
                  color: "#333333",
                }}
              >
                Researcher ID: {researcher?.user_id}
              </li>
              <li
                style={{
                  fontSize: "1.25rem",
                  marginBottom: "0.1rem",
                  fontWeight: "600",
                  color: "#333333",
                }}
              >
                Contact Information: {researcher?.email}
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className="sub-title">2. Summary of Submissions::</div>
          <div>
            <div className="sub-title">
              Total Submissions:{" "}
              {
                submissions?.results.filter(
                  (item) => item.user === researcher?.id
                ).length
              }
            </div>

            <table>
              <thead>
                <tr>
                  <th>Submission ID</th>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                {submissions &&
                  submissions.results
                    .filter((item) => item.user === researcher?.id)
                    .map((sub, index) => (
                      <tr key={index}>
                        <td style={{ fontSize: "14px" }}>
                          {sub.submissions_id}
                        </td>
                        <td>
                          {
                            <a
                              style={{ fontSize: "14px", color: "#000066" }}
                              target="_blank"
                              href={`${routeLink}${sub.program_id}/submission`}
                            >
                              {sub.submission_title}
                            </a>
                          }
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          <div>
            <div className="sub-title">Submission Breakdown: </div>
            <ul>
              <li>
                Total Submissions:{" "}
                {
                  submissions?.results.filter(
                    (item) => item.user === researcher?.id
                  ).length
                }
                {/* {submissionStats && Object.keys(submissionStats).length > 0
                  ? submissionStats.total_submissions_status.triaged +
                    submissionStats.total_submissions_status.resolved +
                    submissionStats.total_submissions_status.rejected +
                    submissionStats.total_submissions_status.unresolved +
                    submissionStats.total_submissions_status.review +
                    submissionStats.total_submissions_status.new
                  : 0} */}
              </li>
              <li>
                Accepted:{" "}
                {
                  submissions?.results
                    .filter((item) => item.user === researcher?.id)
                    .filter((item) => item.submission_status === "resolved")
                    .length
                }
                {/* {submissionStats && Object.keys(submissionStats).length > 0
                  ? submissionStats.total_submissions_status.resolved
                  : 0} */}
              </li>
              <li>
                Pending:{" "}
                {/* {submissionStats && Object.keys(submissionStats).length > 0
                  ? submissionStats.total_submissions_status.triaged +
                    // submissionStats.total_submissions_status.unresolved +
                    submissionStats.total_submissions_status.new
                  : 0} */}
                {submissions?.results
                  .filter((item) => item.user === researcher?.id)
                  .filter((item) => item.submission_status === "triaged")
                  .length +
                  submissions?.results
                    .filter((item) => item.user === researcher?.id)
                    .filter((item) => item.submission_status === "new").length}
              </li>

              <li>
                Review:{" "}
                {/* {submissionStats && Object.keys(submissionStats).length > 0
                  ? submissionStats.total_submissions_status.review
                  : 0} */}
                {
                  submissions?.results
                    .filter((item) => item.user === researcher?.id)
                    .filter((item) => item.submission_status === "review")
                    .length
                }
              </li>
              <li>
                Unresolved:{" "}
                {/* {submissionStats && Object.keys(submissionStats).length > 0
                  ? submissionStats.total_submissions_status.unresolved
                  : 0} */}
                {
                  submissions?.results
                    .filter((item) => item.user === researcher?.id)
                    .filter((item) => item.submission_status === "unresolved")
                    .length
                }
              </li>
              <li>
                Rejected:{" "}
                {/* {submissionStats && Object.keys(submissionStats).length > 0
                  ? submissionStats.total_submissions_status.rejected
                  : 0} */}
                {
                  submissions?.results
                    .filter((item) => item.user === researcher?.id)
                    .filter((item) => item.submission_status === "rejected")
                    .length
                }
              </li>
            </ul>
          </div>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>Vulnerability Category</th>
                {/* <th>Severity</th>
                <th>Sub-Cate (Vulnerability)</th> */}
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {transformedData.map((item, index) => (
                <tr key={index}>
                  <td style={{ fontSize: "14px" }}>{item.key}</td>
                  {/* <td style={{ fontSize: "14px" }}>{item.severity}</td>
                  <td style={{ fontSize: "14px" }}>{item.subCategory}</td> */}
                  <td style={{ fontSize: "14px" }}>{item.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <div className="sub-title"> 3. Submission Details:</div>
          <div>
            <div>
              In the Submission Details section, all significant information is
              included, and to access the details, simply click on the quick
              link provided below.
            </div>
            <table>
              <thead>
                <tr>
                  <th>Submission ID</th>
                  <th>Title</th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                {submissions &&
                  submissions.results
                    .filter((item) => item.user === researcher?.id)
                    .map((sub, index) => (
                      <tr key={index}>
                        <td style={{ fontSize: "14px" }}>
                          {sub.submissions_id}
                        </td>
                        <td style={{ fontSize: "14px" }}>
                          {sub.submission_title}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <a
                            style={{
                              fontSize: "14px",
                              color: "#000066",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            target="_blank"
                            href={`${routeLink}${sub.program_id}/submission`}
                          >
                            Go
                          </a>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className="sub-title">4. Conclusion:</div>
          <div>
            This report summarizes the submissions by{" "}
            <strong>
              {researcher?.first_name + " " + researcher?.last_name}
            </strong>{" "}
            in the bug bounty program. The researcher has contributed{" "}
            <strong>
              {
                submissions?.results.filter(
                  (item) => item.user === researcher?.id
                ).length
              }
            </strong>{" "}
            submissions, highlighting their expertise and dedication to
            improving the security of the {programTitles.join(", ")} platform.
          </div>
        </div>
        <div>
          <div className="sub-title">5. Contact Information:</div>
          <div>
            For further clarification or assistance, please contact:
            <ul>
              <li>
                BUG BOUNTY PROGRAM | ADMINISTRATOR | BUGBUSTERSLABS PRIVATE
                LIMITED
              </li>
              <li>EMAIL - programs@bugbusterslabs.com</li>
            </ul>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="btn bg-gradient-dark me-3 mb-0 "
        >
          Cancel
        </button>
        <button
          onClick={postReport}
          className="btn bg-gradient-dark me-3 mb-0 "
        >
          Save Report
        </button>
      </div>
    </>
  );
};

export default ResearcherReport;
