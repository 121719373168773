import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React from "react";

function CommonTableSkeletonLoader({ card, setTable }) {
	return (
		<>
			{setTable === 1 && (
				<table className="table align-items-center mb-0 customerDashboardTable">
					<thead>
						<tr>
							<th className="text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "80px" }} />
							</th>
							<th className="text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "80px" }} />
							</th>
							<th className="text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "112px" }} />
							</th>
							<th className="text-uppercase  font-weight-bolder opacity-7 ps-2">
								<Skeleton style={{ width: "100px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "112px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "100px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "80px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "100px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "70px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "70px" }} />
							</th>
						</tr>
					</thead>
					<tbody>
						{Array(card)
							.fill(0)
							.map((item,index) => (
								<tr  key={index} >
									<td>
										<p
											className="  mb-0 px-2"
											style={{
												color: "#272727",
												fontSize: "12px",
											}}
										>
											<Skeleton style={{ width: "112px" }} />
										</p>
									</td>
									<td>
										<div style={{ width: "85px" }}>
											<p
												className="  mb-0 px-2"
												style={{
													color: "#272727",
													fontSize: "13px",
												}}
											>
												<Skeleton style={{ width: "80px" }} count={2} />
											</p>
										</div>
									</td>
									<td className="text-center">
										<div style={{ width: "200px" }} className="text-wrap">
											<p
												style={{
													color: "#3F48CC",
													fontWeight: "600",
													marginBottom: "5px",
													// textWrap: "nowrap",
													fontSize: "14px",
												}}
											>
												<Skeleton style={{ width: "80px" }} />
											</p>

											<p
												style={{
													color: "#000",
													fontWeight: "600",
													marginBottom: "5px",
													// textWrap: "nowrap",
													fontSize: "14px",
												}}
											>
												<Skeleton style={{ width: "80px" }} />
											</p>

											<p className="text-secondary text-xs">
												<Skeleton style={{ width: "80px" }} />
											</p>
										</div>
									</td>
									<td>
										<p className="text-sm  mb-0 metricStatElement">
											<div className="row d-flex flex-column align-items-center">
												<div className=" d-flex align-items-center">
													<p className="col-7 mb-0  text-start">
														<Skeleton style={{ width: "40px" }} />
													</p>

													<Skeleton style={{ width: "40px" }} />
												</div>

												<div className="d-flex align-items-center">
													<p className="col-7 mb-0  text-start">
														<Skeleton style={{ width: "40px" }} />
													</p>

													<Skeleton style={{ width: "40px" }} />
												</div>
												<div className="d-flex align-items-center">
													<p className="col-7 mb-0  text-start">
														<Skeleton style={{ width: "40px" }} />
													</p>

													<Skeleton style={{ width: "40px" }} />
												</div>
												<div className="d-flex align-items-center">
													<p className="col-7 mb-0  text-start">
														<Skeleton style={{ width: "40px" }} />
													</p>

													<Skeleton style={{ width: "40px" }} />
												</div>
											</div>
										</p>
									</td>
									<td>
										<div className="d-flex justify-content-center">
											<div className="d-flex flex-column align-items-start">
												<p className="mb-0" style={{ fontSize: "12px" }}>
													<Skeleton style={{ width: "60px" }} count={2} />
												</p>
											</div>
										</div>
									</td>
									<td className="  text-sm">
										<p className="text-sm  mb-0 metricStatElement">
											<div className="row d-flex flex-column align-items-center">
												<div className=" d-flex align-items-center">
													<p className="col-7 mb-0  text-start">
														<Skeleton style={{ width: "40px" }} />
													</p>

													<Skeleton style={{ width: "45px" }} />
												</div>

												<div className="d-flex align-items-center">
													<p className="col-7 mb-0  text-start">
														<Skeleton style={{ width: "40px" }} />
													</p>

													<Skeleton style={{ width: "45px" }} />
												</div>
												<div className="d-flex align-items-center">
													<p className="col-7 mb-0  text-start">
														<Skeleton style={{ width: "40px" }} />
													</p>

													<Skeleton style={{ width: "45px" }} />
												</div>
												<div className="d-flex align-items-center">
													<p className="col-7 mb-0  text-start">
														<Skeleton style={{ width: "40px" }} />
													</p>

													<Skeleton style={{ width: "45px" }} />
												</div>
											</div>
										</p>
									</td>
									<td className="align-middle text-center text-uppercase">
										<Skeleton style={{ height: "28px", width: "72px" }} />
									</td>
									<td className="align-middle text-center">
										<Skeleton count={2} />
									</td>
									<td className="align-middle text-center text-uppercase">
										<div className={` cursor-pointer`}>
											<Skeleton style={{ height: "28px", width: "50px" }} />
										</div>
									</td>
									<td>
										<div className="d-flex justify-content-around">
											<Skeleton style={{ height: "28px", width: "28px" }} />
											<Skeleton style={{ height: "28px", width: "28px" }} />
										</div>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			)}

			{setTable === 2 && (
				<table className="table align-items-center mb-0 customerDashboardTable">
					<thead>
						<tr>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "100px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "80px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "100px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "70px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "70px" }} />
							</th>
						</tr>
					</thead>
					<tbody>
						{Array(card)
							.fill(0)
							.map((item,index) => (
								<tr  key={index} >
									<td>
										<p
											className="  mb-0 px-2"
											style={{
												color: "#272727",
												fontSize: "12px",
											}}
										>
											<Skeleton style={{ width: "112px" }} />
										</p>
									</td>

									<td>
										<div className="d-flex justify-content-center">
											<div className="d-flex flex-column align-items-start">
												<p className="mb-0" style={{ fontSize: "12px" }}>
													<Skeleton style={{ width: "60px" }} count={2} />
												</p>
											</div>
										</div>
									</td>

									<td className="align-middle text-center text-uppercase">
										<Skeleton
											count={2}
											style={{
												height: "12px",
												width: "400px",
												textAlign: "left",
											}}
										/>
									</td>

									<td className="align-middle text-center text-uppercase">
										<div className={` cursor-pointer`}>
											<Skeleton style={{ height: "28px", width: "50px" }} />
										</div>
									</td>
									<td>
										<div className="d-flex justify-content-around">
											<Skeleton style={{ height: "28px", width: "28px" }} />
										</div>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			)}
			{setTable === 3 && (
				<table className="table align-items-center mb-0 customerDashboardTable">
					<thead>
						<tr>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "100px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "80px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "100px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "70px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "70px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "70px" }} />
							</th>
							<th className="text-center text-uppercase  font-weight-bolder opacity-7">
								<Skeleton style={{ width: "70px" }} />
							</th>
						</tr>
					</thead>
					<tbody>
						{Array(card)
							.fill(0)
							.map((item,index) => (
								<tr key={index} >
									<td>
										<p
											className="  mb-0 px-2"
											style={{
												color: "#272727",
												fontSize: "12px",
											}}
										>
											<Skeleton style={{ width: "112px" }} />
										</p>
									</td>

									<td>
										<div className="d-flex justify-content-center">
											<div className="d-flex flex-column align-items-start">
												<p className="mb-0" style={{ fontSize: "12px" }}>
													<Skeleton style={{ width: "60px" }} count={2} />
												</p>
											</div>
										</div>
									</td>

									<td className="align-middle text-center text-uppercase">
										<Skeleton
										
											style={{
												height: "12px",
												width: "200px",
												textAlign: "left",
											}}
										/>
									</td>
									<td className="align-middle text-center text-uppercase">
										<Skeleton
											count={2}
											style={{
												height: "12px",
												width: "200px",
												textAlign: "left",
											}}
										/>
									</td>
									<td className="align-middle text-center text-uppercase">
										<Skeleton
											count={2}
											style={{
												height: "12px",
												width: "200px",
												textAlign: "left",
											}}
										/>
									</td>

									<td className="align-middle text-center text-uppercase">
										<div className={` cursor-pointer`}>
											<Skeleton style={{ height: "28px", width: "50px" }} />
										</div>
									</td>
									<td>
										<div className="d-flex justify-content-around">
											<Skeleton style={{ height: "28px", width: "28px" }} />
										</div>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			)}
		</>
	);
}

export default CommonTableSkeletonLoader;
