import React, { useEffect, useState } from "react";
import HTTPService from "../../../utils/axios";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ViewInfo from "../../common/ViewInfo";
import Skeleton from "react-loading-skeleton";
import { customToast } from "../../../App";

const ResearcherProgramSummary = () => {
  const { id } = useParams();
  const location = useLocation();
  const { safeHarbor } = location.state || {};
  const { invokeApi } = HTTPService();
  const [program, setProgram] = useState();
  const [scopeUrl, setScopeUrl] = useState({});
  const [outUrl, setOutUrl] = useState({});
  const [prgmSummLoad, setPrgmSummLoad] = useState(false);

  const fetchProgram = () => {
    setPrgmSummLoad(true);
    invokeApi({
      method: "GET",
      url: `programs/?id=${id}`,
    })
      .then((data) => {
        setPrgmSummLoad(false);
        if (data.results && data.results.length > 0)
          setProgram(data.results[0]);
      })
      .catch((error) => {
        setPrgmSummLoad(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const scopeDetailsOption = [
    "Program Tags",
    "Scope Title",
    "Scope Item URL",
    "Out of Scope Item URL",
    "Expected Vulnerabilities",
    "Types of Testing Allowed",
    "Languages & Frameworks",
    "Asset Environments",
  ];

  const fetchScopeUrls = () => {
    const urls = [];

    if (program && program.scope_items_url1) {
      urls.push(program.scope_items_url1);
    }
    if (program && program.scope_items_url2) {
      urls.push(program.scope_items_url2);
    }
    if (program && program.scope_items_url3) {
      urls.push(program.scope_items_url3);
    }
    if (program && program.scope_items_url4) {
      urls.push(program.scope_items_url4);
    }
    if (program && program.scope_items_url5) {
      urls.push(program.scope_items_url5);
    }

    setScopeUrl({ innerItem: [...urls], structure: "obj1" });
    console.log("url", scopeUrl);
    console.log("typeof", typeof scopeUrl);
  };
  const fetchOutUrls = () => {
    const urls = [];
    if (program && program.out_Of_scope_item_url1) {
      urls.push(program.out_Of_scope_item_url1);
    }
    if (program && program.out_Of_scope_item_url2) {
      urls.push(program.out_Of_scope_item_url2);
    }
    if (program && program.out_Of_scope_item_url3) {
      urls.push(program.out_Of_scope_item_url3);
    }
    if (program && program.out_Of_scope_item_url4) {
      urls.push(program.out_Of_scope_item_url4);
    }
    if (program && program.out_Of_scope_item_url5) {
      urls.push(program.out_Of_scope_item_url5);
    }
    setOutUrl({ innerItem: [...urls], structure: "obj1" });
  };
  const scopeDetailsField = [
    program?.project_tags,
    program?.scope_title,
    scopeUrl,
    outUrl,
    program?.expected_vulnerability_types,
    program?.testing_allowed,
    program?.language_framworks,
    program?.asset_environments,
  ];
  useEffect(() => {
    if (id) {
      fetchProgram();
    }
  }, [id]);
  useEffect(() => {
    if (program) {
      fetchScopeUrls();
      fetchOutUrls();
    }
  }, [program]);

  return (
    <>
      <div className="row w-100" style={{ backgroundColor: "#fff" }}>
        <div className="col-8">
          {prgmSummLoad ? (
            <Skeleton height={600} width={"100%"} />
          ) : (
            <>
              <div className="row ">
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#000",
                      fontWeight: "600",
                      marginTop: "20px",
                      marginBottom: "4px",
                    }}
                  >
                    Program Description:
                  </p>
                  <span className="prgmDescSpan">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: program?.project_description,
                      }}
                    ></span>
                  </span>
                </div>
              </div>

              <div className="row">
                <div>
                  <div>
                    <p
                      style={{
                        fontSize: "18px",
                        color: "#000",
                        fontWeight: "600",
                        marginTop: "20px",
                        marginBottom: "4px",
                        textDecoration: "underline",
                      }}
                    >
                      Scope Details:
                    </p>
                    <div>
                      <ViewInfo
                        itemValue={scopeDetailsField}
                        option={scopeDetailsOption}
                        containerStyles={{ padding: "0" }}
                        fullWidth={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="col-4">
          {prgmSummLoad ? (
            <Skeleton height={600} width={"100%"} />
          ) : (
            <>
              <div className="row">
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#000",
                      fontWeight: "600",
                      marginTop: "20px",
                      marginBottom: "4px",
                    }}
                  >
                    Reward Range: Maximum Budget (${program?.maximun_budget})
                  </p>
                </div>
                <div>
                  <table className="table pgrmSummTableP">
                    <thead style={{ backgroundColor: "#F7F7F7" }}>
                      <tr>
                        <th scope="col">Technical severity</th>
                        <th scope="col">Reward</th>
                      </tr>
                    </thead>
                    <tbody>
                      {program?.p1_reward_amt && (
                        <tr>
                          <td>
                            <div
                              style={{ textAlign: "left", paddingLeft: "25px" }}
                            >
                              <span
                                className="p1Level"
                                style={{ color: "#000" }}
                              >
                                P1
                              </span>
                              <span className="ptext" style={{ color: "#000" }}>
                                {" "}
                                - Critical
                              </span>
                            </div>
                          </td>
                          <td
                            className="moneyText"
                            style={{
                              textAlign: "left",
                              paddingLeft: "25px",
                              color: "#000",
                            }}
                          >
                            {program?.p1_reward_amt}
                          </td>
                        </tr>
                      )}
                      {program?.p2_reward_amt && (
                        <tr>
                          <td>
                            <div
                              style={{ textAlign: "left", paddingLeft: "25px" }}
                            >
                              <span
                                className="p2Level"
                                style={{ color: "#000" }}
                              >
                                P2
                              </span>
                              <span
                                className="ptext"
                                style={{ color: "#000", color: "#000" }}
                              >
                                {" "}
                                - Severe
                              </span>
                            </div>
                          </td>
                          <td
                            className="moneyText"
                            style={{
                              textAlign: "left",
                              paddingLeft: "25px",
                              color: "#000",
                            }}
                          >
                            {program?.p2_reward_amt}
                          </td>
                        </tr>
                      )}
                      {program?.p3_reward_amt && (
                        <tr>
                          <td>
                            <div
                              style={{ textAlign: "left", paddingLeft: "25px" }}
                            >
                              <span
                                className="p3Level"
                                style={{ color: "#000", color: "#000" }}
                              >
                                P3
                              </span>
                              <span className="ptext" style={{ color: "#000" }}>
                                {" "}
                                - Moderate
                              </span>
                            </div>
                          </td>
                          <td
                            className="moneyText"
                            style={{
                              textAlign: "left",
                              paddingLeft: "25px",
                              color: "#000",
                            }}
                          >
                            {program?.p3_reward_amt}
                          </td>
                        </tr>
                      )}
                      {program?.p4_reward_amt && (
                        <tr>
                          <td>
                            <div
                              style={{
                                textAlign: "left",
                                paddingLeft: "25px",
                                color: "#000",
                              }}
                            >
                              <span
                                className="p4Level"
                                style={{ color: "#000" }}
                              >
                                P4
                              </span>
                              <span className="ptext" style={{ color: "#000" }}>
                                {" "}
                                - Low
                              </span>
                            </div>
                          </td>
                          <td
                            className="moneyText"
                            style={{
                              textAlign: "left",
                              paddingLeft: "25px",
                              color: "#000",
                            }}
                          >
                            {program?.p4_reward_amt}
                          </td>
                        </tr>
                      )}
                      {program?.p5_reward_amt && (
                        <tr>
                          <td>
                            <div
                              style={{
                                textAlign: "left",
                                paddingLeft: "25px",
                                color: "#000",
                              }}
                            >
                              <span
                                className="p5Level"
                                style={{ color: "#000" }}
                              >
                                P5
                              </span>
                              <span className="ptext" style={{ color: "#000" }}>
                                {" "}
                                - Informational
                              </span>
                            </div>
                          </td>
                          <td
                            className="moneyText"
                            style={{
                              textAlign: "left",
                              paddingLeft: "25px",
                              color: "#000",
                            }}
                          >
                            {program?.p5_reward_amt}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div>
                  {!(program?.p1_reward_amt && program?.p2_reward_amt && program?.p3_reward_amt && program?.p4_reward_amt && program?.p5_reward_amt) && (
                    <div className="p5submission">
                      <i className="fa-solid fa-circle-info"></i>
                      <p>
                        {`${program?.p1_reward_amt ? "" : "P1, "}${program?.p2_reward_amt ? "" : "P2, "}${program?.p3_reward_amt ? "" : "P3, "}${program?.p4_reward_amt ? "" : "P4, "}${program?.p5_reward_amt ? "" : "P5 "}
      Submission do not receive any rewards on this program.`}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    color: "#000",
                    fontWeight: "600",
                    marginTop: "20px",
                    marginBottom: "4px",
                    textDecoration: "underline",
                  }}
                >
                  Resources:
                </p>
              </div>
              <div className="prgmDescAttach">
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#000",
                      fontWeight: "600",

                      marginBottom: "4px",
                    }}
                  >
                    Program Description Attachments:
                  </p>
                </div>
                {[1, 2, 3, 4, 5].map((data) => {
                  if (program && program[`upload_doc${data}`])
                    return (
                      <div key={data} className="d-flex mb-3">
                        <div className="d-flex align-items-center">
                          <i className="fa-solid fa-note-sticky fa-rotate-270"></i>
                        </div>
                        <div>
                          <a
                            target="_blank"
                            href={program[`upload_doc${data}`]}
                            download={true}
                          >
                            <span className="pgrmDescAttachFileName mr-2">
                              {program[`upload_doc${data}`].split("/").at(-1)}
                            </span>
                            <i className="fa-solid fa-download"></i>
                          </a>
                        </div>
                        {/* <div>
                          <a
                            href={program[`upload_doc${data}`]}
                            download={program[`upload_doc${data}`]
                              .split("/")
                              .at(-1)}
                          >
                            <button className="btn btn-download">
                              <i className="fa-solid fa-download"></i>
                            </button>
                          </a>
                        </div> */}
                      </div>
                    );
                })}
              </div>
              <div className="prgmDescAttach" style={{ height: "200px" }}>
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#000",
                      fontWeight: "600",

                      marginBottom: "4px",
                    }}
                  >
                    Taxonomy Document Attachments:
                  </p>
                </div>
                {[1, 2, 3, 4, 5].map((data) => {
                  if (program && program[`taxonomy_doc${data}`]) {
                    const fileUrl = program[`taxonomy_doc${data}`];
                    const fileName = fileUrl.split("/").at(-1);
                    return (
                      <div key={data} className="d-flex mb-3">
                        <div className="d-flex align-items-center">
                          <i className="fa-solid fa-note-sticky fa-rotate-270"></i>
                        </div>
                        <div>
                          <a
                            target="_blank"
                            href={fileUrl}
                            download={true}
                            rel="noreferrer"
                          >
                            <span className="pgrmDescAttachFileName mr-2">
                              {fileName}
                            </span>
                            <i className="fa-solid fa-download"></i>
                          </a>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>

            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ResearcherProgramSummary;
