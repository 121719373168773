import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import Footer from "../../../components/Footer/Footer";

import "./BaseValueStyles.css";
import useHttp from "../../../Hooks/useHttp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddModal from "../../../common/modal/AddModal";
import AppContext from "../../../Context/AppContext";
import { customToast } from "../../../../App";

export default function AddBaseValue() {
	const { setCircularLoading } = useContext(AppContext);
	const [openAddModal, setOpenAddModal] = useState(false);

	const [title, setTitle] = useState("");
	const [code, setCode] = useState("");
	const [options, setOptions] = useState([]);
	const { PostRequest } = useHttp();
	const navigate = useNavigate();

	const token = localStorage.getItem("token");
	const API = process.env.REACT_APP_API_URL;

	const handleSubmit = async () => {
		setOpenAddModal(false);
		setCircularLoading(true)
		const param = {
			title: title,
			title_code: code,
			value_options: options.toString(),
			value_status: "active",
			user_id: "",
			is_deleted: false,
		};
		const { data, error } = await PostRequest(API + "basevalues/", param, {
			Authorization: "Bearer  " + token,
			"Content-Type": "multipart/form-data",
		});
		if (error) {
			setCircularLoading(false)
			//alert("Something went wrong please check input");
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		}
		if (data) {
			setCircularLoading(false)
			setTitle("");
			setCode("");
			setOptions([]);
			navigate("/admin/base-values");
			customToast.success("Base values successfully added", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		}
	};

	const deleteOptions = (val) => {
		let focusOption = options.filter((t) => t !== val);
		setOptions(focusOption);
	};

	const processOption = (e) => {
		if (
			e.keyCode === 13 &&
			e.target.value &&
			e.target.value.length > 0 &&
			e.target.value !== " "
		) {
			setOptions([...options, e.target.value]);
			e.target.value = "";
		}
	};

	const Breadcrumbname = {
		home: "/admin/home",
		"Master Module": "",
		"Base Values": "/admin/base-values",
		"Add Base Value": "",
	};

	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row w-100 d-flex justify-content-center">
					<div className="addCategoryArea  my-4">
						<div className="row">
							<div className="col-lg-6 ps-3">
								<Breadcrumb names={Breadcrumbname} />
							</div>
						</div>

						<div className="">
							<div className="button-row d-flex justify-content-end mt-0 mb-3">
								<Link
									to="/admin/base-values"
									className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
									id="adduser"
								>
									VIEW ALL
								</Link>
							</div>
						</div>
						<div className="col-12">
							<div className="card">
								<div className="row">
									<div className="card mt-0" id="basic-info">
										<div className="card-header">
											<h5>ADD BASE VALUE</h5>
										</div>
										<div className="card-body pt-0">
											<div className="row">
												<div className="col-sm-6">
													<label className="form-label mt-4">Title</label>
													<div className="input-group ">
														<input
															className="form-control"
															placeholder="Enter Title"
															// onChange={handleChange}
															onChange={(e) => setTitle(e.target.value)}
															// defaultValue={formData.title}
															value={title}
															type="text"
														/>
													</div>
												</div>
												<div className="col-6">
													<label className="form-label mt-4">Title Code</label>

													<div className="input-group">
														<input
															id="firstName"
															className="form-control"
															type="text"
															placeholder="Enter Title Code"
															// onChange={handleChange}
															onChange={(e) => setCode(e.target.value)}
															// defaultValue={formData.title_code}
															value={code}
															required="required"
														/>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-12">
													<label className="form-label mt-4">Add Options</label>
													<div className="input-group ">
														<div
															className="form-control d-flex tagInputBox"
														// style={{ minHeight: "45px" }}
														>
															{options.map((item, index) => {
																return (
																	<div key={index} className="tagElements">
																		<span>{item}</span>
																		<span
																			onClick={(val) => deleteOptions(item)}
																			style={{
																				marginLeft: "10px",
																				cursor: "pointer",
																				backgroundColor: "#ea0606",
																				padding: "0px 5px",
																				borderRadius: "6px",
																			}}
																		>
																			X
																		</span>
																	</div>
																);
															})}
															<input
																type="text"
																placeholder="Type Options"
																onKeyDown={processOption}
																name="value_options"
																required="false"
															// defaultValue={formData.value_options}
															/>
														</div>
													</div>
												</div>
											</div>
											<div className=" d-flex justify-content-end">
												<button onClick={() => navigate(-1)} className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3">
													Cancel
												</button>
												<button
													type="button"
													onClick={() => setOpenAddModal(true)}
													className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
												>
													Add Value
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AddModal
				cancelBtn={"Cancel"}
				submitBtn={"SUBMIT"}
				createAddTopic={"Add Base Values"}
				confirmText={"Add The Base Values"}
				isOpen={openAddModal}
				onSubmit={handleSubmit}
				onClose={() => setOpenAddModal(false)}
			/>
		</>
	);
}
