import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../reducers/authSlice";
import { customToast } from "../../../App";

const targetUrl = [
  "target_url1",
  "target_url2",
  "target_url3",
  "target_url4",
  "target_url5",
];
const attachmentsConst = [
  "evidence_attachments1",
  "evidence_attachments2",
  "evidence_attachments3",
  "evidence_attachments4",
  "evidence_attachments5",
];
const SubmisisonReport = () => {
  const { id } = useParams();
  const [submission, setSubmission] = useState();
  const [programData, setProgramData] = useState();
  const [vtxData, setVtxData] = useState();
  const { userAccData } = useSelector(getUserInfo);
  const { invokeApi } = HTTPService();
  const navigate = useNavigate();

  const fetchVtxData = async () => {
    invokeApi({
      method: "GET",
      url: `/submissions-vtx?vtx_code=${submission?.vtx}`,
    })
      .then((data) => {
        setVtxData(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const fetchProgramData = async () => {
    invokeApi({
      method: "GET",
      url: `/programs/${submission?.program_id}/`,
    })
      .then((data) => {
        setProgramData(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  useEffect(() => {
    if (submission && submission.program_id) {
      fetchProgramData();
      fetchVtxData();
    }
  }, [submission]);

  // const [researcher, setResearcher] = useState();
  // const fetchResearcher = async () => {
  //   invokeApi({
  //     method: "GET",
  //     url: `/researcher/${submission?.user_id}`,
  //   }).then((data) => {
  //     setResearcher(data)
  //   }).catch((error) => {
  //     customToast.error("Something went wrong!", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 1000,
  //     });
  //   });
  // };
  // useEffect(() => {
  //   if (submission) {
  //     fetchResearcher()
  //   }
  // }, [submission])
  const fetchSubmisison = async () => {
    invokeApi({
      method: "GET",
      url: `/submissions/${id}/`,
    })
      .then((data) => {
        setSubmission(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  useEffect(() => {
    if (id) {
      fetchSubmisison();
    }
  }, [id]);

  const pdfRef = useRef(null);

  const postReport = () => {
    const input = pdfRef.current;
    const pdf = new jsPDF("p", "mm", "a4", true);

    pdf.html(input, {
      callback: async function (pdf) {
        const pdfBlob = pdf.output("blob");

        const formData = new FormData();
        formData.append("report", pdfBlob, "report.pdf");
        formData.append(
          "title",
          `Submission Report(${submission?.submissions_id})`
        );
        formData.append("report_type", "Submission Report");
        formData.append("reference_id", id);
        formData.append("reference_type", "Submission");
        formData.append("user_id", userAccData?.id);

        try {
          await invokeApi({
            method: "POST",
            url: `/report/`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          customToast.success("PDF uploaded successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          // setAgreementModal(false);
          // setSafeHarbor(true);
          // pdf.save("agreement.pdf");
        } catch (error) {
          customToast.error("Failed to upload PDF!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      },
      x: 2,
      y: 2,
      html2canvas: {
        scale: 0.15,
      },
    });
  };

  return (
    <>
      <div ref={pdfRef} className="container report">
        <h3 className="text-center">Submisison Report</h3>
        <div className="text-center">
          Program Type: {programData?.program_type}
        </div>
        <div
          style={{
            fontSize: "1.25rem",
            marginBottom: "0.1rem",
            fontWeight: "600",
            color: "#333333",
          }}
        >
          Title: {submission?.submission_title}
        </div>
        <div
          style={{
            fontSize: "1.25rem",
            marginBottom: "0.1rem",
            fontWeight: "600",
            color: "#333333",
          }}
        >
          ID: {submission?.submissions_id}
        </div>
        <div>
          <div className="sub-title">1. Introduction:</div>
          <div>
            <div className="sub-title">Overview:</div>
            <span
              dangerouslySetInnerHTML={{
                __html: submission?.detail_description,
              }}
            ></span>
          </div>
        </div>
        <div>
          <div className="sub-title">2. Vulnerability Details:</div>
          {" " + submission?.target_title}
          <div>
            <div className="sub-title">Description:</div> The vulnerability
            allows an attacker to {vtxData?.results[0]?.generic_description}.
            {/* {
            submissions && submissions.map((sub, index) =>
              <div key={index}>
                <a target='_blank' href={`/admin/program/${id}/submission`}>{sub.submissions_id}
                  -
                  {sub.submission_title}
                </a>
              </div>
            )
          } */}
          </div>

          <div>
            <div className="sub-title">Vulnerability Type:</div>
            <div>
              This vulnerability falls under the{" "}
              {vtxData?.results[0]?.main_category},{" "}
              {vtxData?.results[0]?.sub_category}.
            </div>
          </div>
          <div>
            <div className="sub-title">Reproduction Steps:</div>
            <div>
              Here are various submissions connected to programs. Click the link
              to delve into them further.
            </div>
            {submission && submission.step_to_reproduce ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: submission.step_to_reproduce,
                }}
              ></span>
            ) : (
              "-"
            )}
          </div>
          <div>
            <div className="sub-title">Proof of Concept (PoC):</div>
            <table>
              <thead>
                <tr>
                  <th>Submission ID</th>
                  <th>Attachments</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      style={{ fontSize: "14px", color: "#000066" }}
                      target="_blank"
                      href={`/admin/program/${id}/submission`}
                    >
                      {submission?.submissions_id}
                    </a>
                  </td>
                  <td>
                    {submission &&
                      attachmentsConst.map((attach) => {
                        if (submission[attach])
                          return (
                            <div key={attach}>
                              <a
                                style={{ fontSize: "14px", color: "#000066" }}
                                target="_blank"
                                href={submission[attach]}
                              >
                                {submission[attach]}
                              </a>
                            </div>
                          );
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className="sub-title"> 3. Technical Details:</div>
          <div>
            <div className="sub-title">
              Affected Versions: The vunerability exists in Affected Component &
              feature, specifically in the below location(s)
            </div>

            <table>
              <thead>
                <tr>
                  <th>Submission ID</th>
                  <th>Affected Location URL</th>
                </tr>
              </thead>
              <tbody>
                {submission &&
                  targetUrl.map((url) => {
                    if (submission && submission[url])
                      return (
                        <tr key={url}>
                          <td style={{ fontSize: "14px" }}>
                            <a
                              style={{ fontSize: "14px", color: "#000066" }}
                              target="_blank"
                              href={`/admin/program/${id}/submission`}
                            >
                              {submission.submissions_id}
                            </a>
                          </td>
                          <td style={{ fontSize: "14px" }}>
                            <a
                              style={{ fontSize: "14px", color: "#000066" }}
                              target="_blank"
                              href={submission[url]}
                            >
                              {submission[url]}
                            </a>
                          </td>
                        </tr>
                      );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className="sub-title">4. Recommendations:</div>
          <div className="sub-title">Remediation Recommendation:</div>
          <div>
            {submission?.remediation_recommendation ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: submission?.remediation_recommendation,
                }}
              ></span>
            ) : (
              "-"
            )}
          </div>
          {/* <div>
            <div className="sub-title">Mitigation:</div>
            It is recommended to [Provide specific mitigation steps or fixes,
            including code changes or configuration adjustments] to mitigate
            this vulnerability.
          </div> */}
          <div>
            <div className="sub-title">Best Practices:</div>
            To prevent similar vulnerabilities in the future, it is recommended
            to {vtxData?.results[0]?.appendix}.
          </div>
          <div>
            <div className="sub-title">Additional Information:</div>
            <div>VTx Code: {vtxData?.results[0]?.vtx_code}</div>
            <div>VTx Category: {vtxData?.results[0]?.main_category}</div>
            <div>VTx Sub Category: {vtxData?.results[0]?.sub_category}</div>
            <div>VTx Variant: {vtxData?.results[0]?.variant_title}</div>
          </div>
        </div>
        <div>
          <span className="sub-title">5. Conclusion:</span>
          <div>
            This report highlights a critical security vulnerability that
            requires immediate attention and remediation. Addressing this issue
            quickly would ensure the security and integrity of the
            {programData?.program_title}.
          </div>
        </div>
        <div>
          <div className="sub-title"> 6. Disclosure Policy:</div>
          <div>
            This report adheres to the disclosure policy outlined in the{" "}
            {submission?.submission_title}. The program's terms and conditions
            submit all details provided. Read{" "}
            <a href="" target="_blank">
              Terms and Conditions
            </a>
          </div>
        </div>
        <div>
          <div className="sub-title"> 7. Contact Information:</div>
          <div>
            For further clarification or assistance, please contact: <br />
            <ul>
              <li>
                Name: {submission?.user_details?.first_name}{" "}
                {submission?.user_details?.last_name}
              </li>
              <li>Email: {submission?.user_details?.email}</li>
            </ul>
          </div>
        </div>
        <div>
          <div className="sub-title"> 8. Acknowledgments:</div>
          <div>
            I want to express my gratitude to the {programData?.program_type} |
            Administrators for their commitment to security and for providing
            the opportunity to contribute to improving the{" "}
            {programData?.program_title}.
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="btn bg-gradient-dark me-3 mb-0 "
        >
          Cancel
        </button>
        <button
          onClick={postReport}
          className="btn bg-gradient-dark me-3 mb-0 "
        >
          Save Report
        </button>
      </div>
    </>
  );
};

export default SubmisisonReport;
