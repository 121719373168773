import React from "react";

export default function AddModal({
	isOpen,
	onClose,
	onSubmit,
	createAddTopic,
	confirmText,
	cancelBtn,
	submitBtn,
}) {
	return (
		<>
			{isOpen && (
				<div className="changeStatus">
					<div className="changeStatusBody">
						<div className="changeStatusBodyTop">
							<i className="fa-solid fa-circle-exclamation fa-rotate-180"></i>
							<p>{createAddTopic}</p>
						</div>
						{/* <div className="changeStatusBodySecPart">
						
					</div> */}
						<div className="changeStatusBodyThirdPart">
							<p>Are You Sure You Want to {confirmText}</p>
						</div>
						<div className="changeStatusBodyLastPart">
							<button
								// onClick={() => setChangeStatusModal(false)}
								className="changeStatusBtnClose"
								onClick={onClose}
							>
								{cancelBtn}
							</button>
							<button onClick={onSubmit} className="changeStatusBtnSubmit">
								{submitBtn === "SUBMIT"
									? "SUBMIT"
									: submitBtn === "DELETE"
									? "DELETE"
									: ""}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
