import React, { useEffect, useState } from 'react'
import HTTPService from '../utils/axios';
import { useParams } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import CircularLoader from '../BackEnd/CircularLoader/CircularLoader';
const CmsNavigation = () => {
    const { invokeApi } = HTTPService();
    const [cmsPage, setCmsPage] = useState();
    const [loading, setLoading] = useState(true);
    const { name } = useParams();
    const fetchNavigationData = () => {
        setLoading(true);
        setLoading(true);
        invokeApi({
            method: "GET",
            url: `cms_pages`,
            params: {
                page_title: name
            },
            noToken: true
        }).then((data) => {
            if (data.results && data.results.length > 0)
                setCmsPage(data.results[0]);
            setLoading(false);
        }).catch((error) => {
            console.log("somethings went wrong");
            setLoading(false);
        });
    };

    useEffect(() => {
        if (name) {
            fetchNavigationData();
        }
    }, [name]);
    return (
        <div>
            <Header />
            {
                loading ? <CircularLoader size={100} /> :
                    <div className='container mt-5 mb-5'>
                        <h4>{cmsPage?.page_title}</h4>
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: cmsPage.page_content }}></span>

                    </div>}
            <Footer />
        </div>
    )
}

export default CmsNavigation