import React, { useContext, useEffect, useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Link, useLocation } from "react-router-dom";
import Accordion from "./components/Accordion";
import HTTPService from "../utils/axios";
import AppContext from "../BackEnd/Context/AppContext";

export default function ResearcherDocs() {
  const { setCircularLoading } = useContext(AppContext);

  const location = useLocation();
  const [sideBarData, setSideBarData] = useState([]);
  const [pageDetails, setPageDetails] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedPageContent, setSelectedPageContent] = useState("");

  const customer = location.pathname === "/resources/docs/customer";
  const researcher = location.pathname === "/resources/docs/researcher";
  const apis = location.pathname === "/resources/docs/apis";
  const { invokeApi } = HTTPService();

  useEffect(() => {
    if (customer) {
      setCircularLoading(true);
      invokeApi({
        method: "GET",
        url: "/cms_pages/?user_type=Docs-Customer&cms_status=active",
      })
        .then((data) => {
          setCircularLoading(false);
          setPageDetails(data.results);
        })
        .catch((error) => console.log(error));
    } else if (researcher) {
      setCircularLoading(true);
      invokeApi({
        method: "GET",
        url: "/cms_pages/?user_type=Docs-Researcher&cms_status=active",
      })
        .then((data) => {
          setCircularLoading(false);
          setPageDetails(data.results);
        })
        .catch((error) => console.log(error));
    } else if (apis) {
      setCircularLoading(true);
      invokeApi({
        method: "GET",
        url: "/cms_pages/?user_type=Docs-API&cms_status=active",
      })
        .then((data) => {
          setCircularLoading(false);
          setPageDetails(data.results);
        })
        .catch((error) => console.log(error));
    }
  }, [customer, researcher, apis]);

  useEffect(() => {
    invokeApi({
      method: "GET",
      url: "/faq_category/",
    })
      .then((data) => setCategory(data.results))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const subCategoriesMap = {};
    const mainCategoriesMap = {};

    category.forEach((cat) => {
      if (cat.category_type === "sub_category") {
        subCategoriesMap[cat.id] = cat;
      } else if (cat.category_type === "main_category") {
        mainCategoriesMap[cat.id] = {
          ...cat,
          subcategories: [],
        };
      }
    });

    Object.values(subCategoriesMap).forEach((subCat) => {
      if (mainCategoriesMap[subCat.parent_category_id]) {
        mainCategoriesMap[subCat.parent_category_id].subcategories.push({
          ...subCat,
          pages: [],
        });
      }
    });

    pageDetails.forEach((page) => {
      const subCategory = subCategoriesMap[page.sub_category_id];
      if (subCategory) {
        const mainCategory = mainCategoriesMap[subCategory.parent_category_id];
        if (mainCategory) {
          const subCatIndex = mainCategory.subcategories.findIndex(
            (subCat) => subCat.id === subCategory.id
          );
          if (subCatIndex !== -1) {
            mainCategory.subcategories[subCatIndex].pages.push({
              pageTitle: page.page_title,
              pageContent: page.page_content,
            });
          }
        }
      }
    });

    const transformedArray = Object.values(mainCategoriesMap)
      .filter((mainCat) =>
        mainCat.subcategories.some((subCat) => subCat.pages.length > 0)
      )
      .map((mainCat) => ({
        mainCategory: mainCat.category_title,
        subcategory: mainCat.subcategories
          .filter((subCat) => subCat.pages.length > 0)
          .map((subCat) => ({
            subCategoryTitle: subCat.category_title,
            pages: subCat.pages,
          })),
      }));

    setSideBarData(transformedArray);

    if (transformedArray.length > 0) {
      const firstMainCat = transformedArray[0];
      if (firstMainCat.subcategory.length > 0) {
        const firstSubCat = firstMainCat.subcategory[0];
        if (firstSubCat.pages.length > 0) {
          setSelectedPageContent(firstSubCat.pages[0].pageContent);
        }
      }
    }
  }, [pageDetails, category]);

  useEffect(() => {
    setSelectedPageContent("");
  }, [location.pathname]);

  const handlePageClick = (content) => {
    setSelectedPageContent(content);
  };
  return (
    <>
      <Header />
      <div className="container researcherDocContainer">
        <div className="row my-4">
          <div className="col-lg-3 sidebar-rec-doc">
            {sideBarData &&
              sideBarData.map((item, index) => (
                <div key={index}>
                  <h5 style={{fontSize:"1rem", lineHeight:1.2}}>{item.mainCategory}</h5>
                  <ul>
                    <li>
                      {item?.subcategory?.map((subCat, subIndex) => (
                        <Accordion
                          key={subIndex}
                          title={subCat.subCategoryTitle}
                          dropdowntitle={
                            subCat.pages &&
                            subCat.pages.map((page, pageIndex) => (
                              <li key={pageIndex}>
                                <Link
                                  to="#"
                                  className="mb-2 productElementItem"
                                  onClick={() =>
                                    handlePageClick(page.pageContent)
                                  }
                                >
                                  {page.pageTitle}
                                </Link>
                              </li>
                            ))
                          }
                        />
                      ))}
                    </li>
                  </ul>
                </div>
              ))}
          </div>
          <div className="col-lg-9 px-5 pageContentDiv">
            {/* <h3 className="text-start">Overview</h3> */}
            {/* <p>{selectedPageContent}</p> */}
            <div dangerouslySetInnerHTML={{ __html: selectedPageContent }} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
