import React, { useContext, useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
// import navigationData from "../navigation/navigationData.json"

import "../navigation/navigationDashboardStyle.css";
import useHttp from "../../../Hooks/useHttp";
import { ToastContainer, toast } from "react-toastify";
import AddModal from "../../../common/modal/AddModal";
import AppContext from "../../../Context/AppContext";
import CommonTable from "../../../common/Table/CommonTable";
import withPermission from "../../../../hoc/withPermission";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../../reducers/authSlice";
import { customToast } from "../../../../App";

const NavigationDashboard = () => {
  const { userInfo, permissions } = useSelector(getUserInfo);

  const permission = permissions.find((perm) => perm.module === "Navigation");
  const { setLoading } = useContext(AppContext);
  const [navigationData, setNavigationData] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [cmsPage, setCmsPage] = useState();
  const [openAddModal, setOpenAddModal] = useState({ isOpen: false });
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { GetRequest, PutRequest, DeleteRequest } = useHttp();
  const API = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();

  const fetchNavigationData = async (hide) => {
    if (!hide)
      setLoading(true);
    let url = `${API}cms_navigation/?page=${currentPage}&page_size=25`;
    if (searchQuery && searchQuery.length > 0) {
      url = url + `&${searchValue}=${searchQuery}`;
    }
    if (filterType && filterValue && filterValue.length > 0) {
      url = url + `&${filterType}=${filterValue}`;
    }
    const { data } = await GetRequest(
      url,
      {},
      {
        Authorization: "Bearer  " + token,
      }
    );
    if (data) {
      setLoading(false);
      setNavigationData(data);
      setTotalPages(data.total_pages);
    } else {
      setLoading(false);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  const filterColumn = [
    {
      name: "Status",
      value: "cms_navigation_status",
      filters: [
        { value: "active", label: "Active" },
        { value: "in_active", label: "Inactive" },]
    }
  ];
  useEffect(() => {
    fetchNavigationData(true);
  }, [searchQuery, , currentPage, filterValue]);

  const fetchCmsPage = async () => {
    const { data, error } = await GetRequest(
      API + "cms_pages/",
      {},
      { Authorization: "Bearer  " + token }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setCmsPage(data);
      console.log(data.results);
    }
  };
  const handleStatus = async (sts, id, seq, show, pageId) => {
    const param = {
      cms_navigation_status: sts,
      id: id,
      sequence: seq,
      show_on: show,
      page_id: pageId,
    };
    const { data } = await PutRequest(
      API + "cms_navigation/" + id + "/",
      param,
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (data) {
      customToast.success("Successfully Status Change", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setTimeout(() => {
        fetchNavigationData();
      }, 2000);
    } else {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  const handleDelete = async (delid) => {
    setOpenAddModal({ ...openAddModal, isOpen: false });
    const { data, error, success } = await DeleteRequest(
      API + "cms_navigation/" + delid + "/",

      {
        Authorization: "Bearer " + token,
        // "Content-Type": "multipart/form-data",
      }
    );
    if (success) {
      customToast.success("Successfully Delete", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });

      fetchNavigationData();
      fetchCmsPage();
    } else {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  useEffect(() => {
    fetchNavigationData();
    fetchCmsPage();
  }, []);

  const tableColumn = [
    "CMS NAVIGATION ID",
    "CMS PAGE & ID",
    "SHOW ON",
    { label: "SEQUENCE", align: "center" },
    "STATUS",
    "ACTION",
  ];

  const searchColumn = [
    { name: "CMS Navigation Id", value: "cms_navigation_id" },
    { name: "Show On", value: "show_on" },
  ];
  const Breadcrumbname = {
    home: "/admin/home",
    "Manage Module": "",
    "CMS Navigation": "/admin/cms-navigation",
    Dashboard: "/admin/cms-navigation",
  };
  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              {(permission?.create || userInfo.role === "admin") && (
                <Link
                  to="/admin/cms-navigation/add"
                  className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                  id="adduser"
                >
                  ADD CMS Navigation
                </Link>
              )}
            </div>
          </div>
        </div>

        <div className="table-overflow row my-4">
          <div className="col-12">
            <CommonTable
              filterList={filterColumn}
              filterType={filterType}
              setFilterType={setFilterType}
              setFilterValue={setFilterValue}
              column={tableColumn}
              data={navigationData && navigationData.results}
              totalPages={totalPages ?? 1}
              totalData={navigationData?.total_records}
              searchBar={true}
              searchBy={searchColumn}
              itemPerPg={25}
              setTable={3}
              card={7}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            >
              {navigationData &&
                navigationData.results &&
                navigationData.results.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        to={`/admin/cms-navigation/view/${item.id}`}
                        className="text-sm gotoView mb-0 px-2"
                      >
                        {item.cms_navigation_id}
                      </Link>
                    </td>
                    <td className="text-start">
                      <p className="text-sm  mb-0 px-2">
                        {cmsPage &&
                          cmsPage.results?.map(
                            (cms) =>
                              item.page_id === cms.id && (
                                <>
                                  <span>{cms.page_title}</span>{" "}
                                  <span>{cms.cms_page_id}</span>
                                </>
                              )
                          )}
                      </p>
                    </td>

                    <td className="text-start">
                      <p className="text-sm  mb-0">{item.show_on}</p>
                    </td>
                    <td>
                      <p className="text-sm  mb-0">{item.sequence}</p>
                    </td>

                    <td className="align-middle text-center">
                      {item.cms_navigation_status === "active" ? (
                        <span
                          className="badge bg-success text-white "
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleStatus(
                              "in_active",
                              item.id,
                              item.sequence,
                              item.show_on,
                              item.page_id
                            );
                          }}
                        >
                          Active
                        </span>
                      ) : (
                        <span
                          className="badge bg-danger text-white"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleStatus(
                              "active",
                              item.id,
                              item.sequence,
                              item.show_on,
                              item.page_id
                            );
                          }}
                        >
                          Inactive
                        </span>
                      )}
                    </td>
                    <td className="text-center align-middle">
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <Link
                          to={`/admin/cms-navigation/view/${item.id}`}
                          className="btn btn-sm px-3 mt-2"
                        >
                          &#128065;
                        </Link>
                        <button
                          type="button"
                          className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v text-xs"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            {(permission?.create ||
                              userInfo.role === "admin") && (
                                <Link
                                  className="dropdown-item"
                                  to={`/admin/cms-navigation/edit/${item.id}`}
                                >
                                  Edit
                                </Link>
                              )}
                          </li>
                          <li>
                            {(permission?.create ||
                              userInfo.role === "admin") && (
                                <button
                                  onClick={() =>
                                    setOpenAddModal({
                                      isOpen: true,
                                      id: item.id,
                                    })
                                  }
                                  className="dropdown-item"
                                  href=""
                                >
                                  Delete
                                </button>
                              )}
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
            </CommonTable>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"DELETE"}
        createAddTopic={"Delete CMS Navigation"}
        confirmText={"Delete The CMS Navigation"}
        isOpen={openAddModal.isOpen}
        onSubmit={() => handleDelete(openAddModal.id)}
        onClose={() => setOpenAddModal({ isOpen: false })}
      />
    </>
  );
};

export default withPermission(NavigationDashboard);
NavigationDashboard.moduleName = "Navigation";
