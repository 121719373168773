import React, { useEffect, useState } from "react";
import useHttp from "../Hooks/useHttp";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import ViewInfo from "../common/ViewInfo";
import ViewTable from "../common/ViewTable";
import { customToast } from "../../App";

export default function ViewBadges() {

	const { GetRequest } = useHttp();
	const [badgeLevelCount, setBadgeLevelCount] = useState({});
	const [badgeData, setBadgeData] = useState({});
	const [badgeImg, setBadgeImg] = useState({ innerItem: [], structure: "" })
	const API = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem("token");

	const navigate = useNavigate();

	const { id } = useParams();

	console.log("badge id " + id);

	const fetchBadgesLevelCount = async () => {
		const { data, error } = await GetRequest(
			API + `badge-levels/?badge_id=${id}`,
			{},
			{
				Authorization: `Bearer ${token}`,
			}
		);
		if (error) {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {

			setBadgeLevelCount(data);
		}
	};

	const fetchBadgesData = async () => {
		const { data, error } = await GetRequest(
			API + "badges/" + id + "/",
			{},
			{
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			}
		);
		if (error) {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {
			setBadgeData(data);
		}
	};

	useEffect(() => {
		fetchBadgesLevelCount();
		fetchBadgesData();
	}, [id]);
	function fetchBadgeImage() {
		const bimg = [badgeData?.image]
		setBadgeImg({ innerItem: [...bimg], structure: "img" })
	}
	useEffect(() => {
		if (badgeData && badgeData.badge_id) {
			fetchBadgeImage();
		}
	}, [badgeData])

	console.log("badgeLevelCount--", badgeLevelCount);
	console.log("Badge Data--", badgeData);

	const Title = "View Badge Details:";
	const NewBaseValueDate = [
		badgeImg,
		badgeData?.badge_id,
		badgeData?.badge_title,
		badgeData?.badge_code,
		badgeData?.badge_description,
		badgeData?.terms_conditions,
		badgeData?.sequence,
	];
	const option = [
		"Badge Icon",
		"ID",
		"Title",
		"Title Code",
		"Description",
		"Terms & Conditions",
		"Sequence",
	];
	const subTitle = "Badge ID : " + badgeData.badge_id;

	const TableHeader = ["Level 1", "Priority", "Count", "Type"];

	const badgeLevel = badgeLevelCount?.results?.map((item, index) => [
		item.level,
		item.priority,
		item.points,
		item.type,
	]);

	console.log(badgeLevel);

	const Breadcrumbname = {
		home: "/admin/home",
		Badge: "",
		"View Badge Details": "",
	};

	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row w-100 d-flex justify-content-center">
					<div className="addCategoryArea  my-4">
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div className="col-lg-6 ps-3">
								<Breadcrumb names={Breadcrumbname} />
							</div>

							<div className="button-row d-flex justify-content-end mt-0 mb-3">
								<Link
									to="/admin/badges/dashboard"
									className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
									id="adduser"
								>
									VIEW ALL
								</Link>
							</div>
						</div>

						<div className="col-12">
							<div className="card" style={{ backgroundColor: "white" }}>
								<ViewInfo
									itemValue={NewBaseValueDate}
									option={option}
									title={Title}
									subTitle={subTitle}
								/>

								<ViewTable TableHeader={TableHeader} TableLabel={badgeLevel} />

								<div className=" d-flex justify-content-end p-4">
									<button
										className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
										onClick={() => navigate(-1)}
									>
										Back
									</button>
									<button
										type="button"
										className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
										onClick={() => navigate(`/admin/badges/edit/${id}`)}
									>
										Edit
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
