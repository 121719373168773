import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useHttp from "../Hooks/useHttp";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import HTTPService from "../../utils/axios";
import AppContext from "../Context/AppContext";
import AddModal from "./modal/AddModal";
import { customToast } from "../../App";


export default function Paymentinfo() {
	const navigate = useNavigate();
	const { memberData, setMemberData, setCircularLoading } = useContext(AppContext);
	const { invokeApi } = HTTPService();
	const [paymentLoading, setPaymentLoading] = useState(false);
	const { userId } = useParams();
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [payments, setPayments] = useState();
	const token = localStorage.getItem("token");
	const API = process.env.REACT_APP_API_URL;
	const location = useLocation();
	const [selectedPayment, setSelectedPayment] = useState(null);
	const { PatchRequest, PostRequest, DeleteRequest } = useHttp();

	useEffect(() => {
		fetchPaymentData();
	}, []);

	const fetchPaymentData = async () => {
		setPaymentLoading(true);

		invokeApi({
			method: "GET",
			url: `payments/?user_id=${userId}`,
		})
			.then((data) => {
				setPaymentLoading(false);
				setPayments(data);
			})
			.catch((error) => {
				setPaymentLoading(false);

				const flattenedErrors = Object.keys(error.data)
					.map(key => key.replaceAll('_', ' ').toUpperCase() + ": " + error.data[key].join('\n'))
					.join('\n');
				customToast.error(flattenedErrors ?? "Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
				});
			});
	};

	const handleChange = (e) => {
		let data = { ...formData };
		if (["cancel_cheque", "upload_attachment"].includes(e.target.name)) {
			data[e.target.name] = e.target.files[0];
		} else {
			data[e.target.name] = e.target.value;
		}
		setFormData(data);
	};

	const handleAddEdit = async (type) => {
		//  variable that will be send in payload
		let apiData = {};

		if (type === "edit") {
			apiData = {
				...selectedPayment,
			};
		} else {
			apiData = {
				...formData,
			};
		}
		if (typeof apiData.cancel_cheque === "string") {
			delete apiData.cancel_cheque;
		}
		if (typeof apiData.upload_attachment === "string") {
			delete apiData.upload_attachment;
		}
		if (type === "edit") {
			setMemberData({ openmodal: false });
			setCircularLoading(true);
			const { data, error } = await PatchRequest(
				API + `payments/${apiData.id}/`,
				{
					...apiData,
				},
				{},
				{
					Authorization: "Bearer  " + token,
					"Content-Type": "multipart/form-data",
				}
			);
			if (error) {
				setCircularLoading(false);
				console.log(error);
				customToast.error("Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}
			if (data) {
				setCircularLoading(false);
				handleCloseModal();
				fetchPaymentData();
				customToast.success("status updated", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}
		} else {
			setMemberData({ openmodal: false });
			setCircularLoading(true);
			const { data, error } = await PostRequest(
				API + `payments/`,
				{
					...apiData,
					user_id: userId,
				},
				{
					Authorization: "Bearer  " + token,
					"Content-Type": "multipart/form-data",
				}
			);
			if (error) {
				setCircularLoading(false);
				console.log(error);
				setErrors({ ...error.data });
				customToast.error("Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}
			if (data) {
				setCircularLoading(false);
				customToast.success("status updated", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
				fetchPaymentData();
			}
		}
	};

	const deleteAction = async (id) => {
		const { data, error } = await DeleteRequest(API + `payments/${id}/`, {
			Authorization: "Bearer  " + token,
			"Content-Type": "multipart/form-data",
		});
		if (error) {
			console.log(error);
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {
			fetchPaymentData();
			customToast.success("data delete successfully.", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		}
	};

	const handleEdit = (payment) => {
		setSelectedPayment(payment);
	};

	const handleCloseModal = () => {
		setSelectedPayment(null);
	};

	const handleChangeEdit = (e) => {
		let data = { ...selectedPayment };
		if (["cancel_cheque", "upload_attachment"].includes(e.target.name)) {
			data[e.target.name] = e.target.files[0];
		} else {
			data[e.target.name] = e.target.value;
		}
		setSelectedPayment(data);
	};
	useEffect(() => {
		if (formData.account_number !== formData.confirm_account_number) {
			setErrors({
				...errors,
				confirm_account_number: "Account number not match",
			});
		} else {
			const { confirm_account_number, ...rest } = errors;
			setErrors({ ...rest });
		}
	}, [formData.confirm_account_number]);
	return (
		<>
			<div className="card mt-4" id="basic-info">
				<div className="card mt-2">
					<div className="card-header pb-0">
						<h5>Payments :</h5>
					</div>
					<div className="card-body pt-0">
						<table className="table align-items-center mb-0 customerDashboardTable">
							<thead>
								<tr>
									<th className="text-uppercase  font-weight-bolder opacity-7">
										Account Name
									</th>
									<th className="text-uppercase  font-weight-bolder opacity-7">
										Account Number
									</th>
									<th className="text-uppercase  font-weight-bolder opacity-7">
										Bank Name
									</th>
									<th className="text-uppercase  font-weight-bolder opacity-7">
										Branch
									</th>
									<th className="text-uppercase  font-weight-bolder opacity-7">
										IFSC
									</th>
									<th className="text-uppercase  font-weight-bolder opacity-7">
										Cancel Cheque
									</th>
									<th className="text-center text-uppercase  font-weight-bolder opacity-7">
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								{paymentLoading ? (
									<tr>
										<td className="text-sm text-secondary mb-0 px-2">
											<Skeleton width={30} />
										</td>
										<td className="text-sm text-secondary mb-0 px-2">
											<Skeleton width={50} />
										</td>
										<td className="text-sm text-secondary mb-0 px-2">
											<Skeleton width={70} />
										</td>
										<td className="text-sm text-secondary mb-0 px-2">
											<Skeleton width={60} />
										</td>
										<td className="text-sm text-secondary mb-0 px-2">
											<Skeleton width={30} />
										</td>
										<td className="text-sm text-secondary mb-0 px-2">
											<Skeleton width={30} />
										</td>
										<td>
											<div className="d-flex">
												<Skeleton width={30} height={30} />
											</div>
										</td>
									</tr>
								) : (
									payments?.results &&
									payments.results.map((payment, index) => (
										<tr key={index}>
											<td className="text-sm text-secondary mb-0 px-2">
												{payment.account_name}
											</td>
											<td className="text-sm text-secondary mb-0 px-2">
												{payment.account_number}
											</td>
											<td className="text-sm text-secondary mb-0 px-2">
												{payment.bank_name}
											</td>
											<td className="text-sm text-secondary mb-0 px-2">
												{payment.branch}
											</td>
											<td className="text-sm text-secondary mb-0 px-2">
												{payment.ifsc}
											</td>
											<td className="text-sm text-secondary mb-0 px-2">
												<a
													href={payment.cancel_cheque}
													target="_blank"
													className="btn btn-link"
													rel="noreferrer"
												>
													View
												</a>
											</td>
											<td>
												<div className="d-flex">
													<button
														type="button"
														className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
														data-bs-toggle="dropdown"
														aria-expanded="false"
													>
														<i
															className="fa fa-ellipsis-v text-xs"
															aria-hidden="true"
														></i>
													</button>
													<ul className="dropdown-menu">
														<li>
															<button
																className="dropdown-item"
																// to={`/admin/customer/edit/${payment.id}`}
																onClick={() => handleEdit(payment)}
															>
																Edit
															</button>
														</li>
														<li>
															<button
																onClick={() => deleteAction(payment.id)}
																className="dropdown-item"
															>
																Delete
															</button>
														</li>
													</ul>
												</div>
											</td>
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
				</div>
				<div className="card-header">
					<h5>Add Payment Details</h5>
				</div>
				<div className="card-body pt-0">
					<Form>
						<div className="row">
							<div className="col-lg-4">
								<label className="form-label">Account Name</label>
								<div className="input-group ">
									<input
										className="form-control w-100"
										type="text"
										placeholder="Account Name"
										required="required"
										name="account_name"
										value={formData.account_name ?? ""}
										onChange={handleChange}
									/>

									<p style={{ color: "red", fontSize: "14px" }}>
										{(formData.account_name === "" ||
											formData.account_name === null ||
											formData.account_name === undefined) &&
											errors.account_name}
									</p>
								</div>
							</div>
							<div className="col-lg-4">
								<label className="form-label">Account Number</label>
								<div className="input-group">
									<input
										className="form-control w-100"
										type="number"
										placeholder="Account number"
										required="required"
										name="account_number"
										value={formData.account_number ?? ""}
										onChange={handleChange}
									/>
									<p style={{ color: "red", fontSize: "14px" }}>
										{(formData.account_number === "" ||
											formData.account_number === null ||
											formData.account_number === undefined) &&
											errors.account_number}
									</p>
								</div>
							</div>

							<div className="col-lg-4">
								<label className="form-label">Confirm Account Number</label>
								<div className="input-group">
									<input
										className="form-control w-100"
										type="text"
										placeholder="Confirm account number"
										required="required"
										name="confirm_account_number"
										value={formData.confirm_account_number ?? ""}
										onChange={handleChange}
									/>
									<p style={{ color: "red", fontSize: "14px" }}>
										{errors.confirm_account_number}
									</p>
								</div>
							</div>
						</div>

						<div className="row mt-2">
							<div className="col-lg-4">
								<label className="form-label">Bank Name</label>
								<div className="input-group">
									<input
										className="form-control w-100"
										type="text"
										placeholder="Bank Name"
										required="required"
										name="bank_name"
										value={formData.bank_name ?? ""}
										onChange={handleChange}
									/>
									<p style={{ color: "red", fontSize: "14px" }}>
										{(formData.bank_name === "" ||
											formData.bank_name === null ||
											formData.bank_name === undefined) &&
											errors.bank_name}
									</p>
								</div>
							</div>
							<div className="col-lg-4">
								<label className="form-label">Branch</label>
								<div className="input-group">
									<input
										className="form-control w-100"
										type="text"
										placeholder="Branch Name"
										required="required"
										name="branch"
										value={formData.branch ?? ""}
										onChange={handleChange}
									/>
									<p style={{ color: "red", fontSize: "14px" }}>
										{(formData.branch === "" ||
											formData.branch === null ||
											formData.branch === undefined) &&
											errors.branch}
									</p>
								</div>
							</div>

							<div className="col-lg-4">
								<label className="form-label">IFSC</label>
								<div className="input-group">
									<input
										className="form-control w-100"
										type="text"
										placeholder="IFSC"
										required="required"
										name="ifsc"
										value={formData.ifsc ?? ""}
										onChange={handleChange}
									/>
									<p style={{ color: "red", fontSize: "14px" }}>
										{(formData.ifsc === "" ||
											formData.ifsc === null ||
											formData.ifsc === undefined) &&
											errors.ifsc}
									</p>
								</div>
							</div>
						</div>

						<div className="row mt-2">
							<div className="col-lg-4">
								<label className="form-label">City</label>
								<div className="input-group">
									<input
										className="form-control w-100"
										type="text"
										placeholder="city"
										required="required"
										name="city"
										value={formData.city ?? ""}
										onChange={handleChange}
									/>
									<p style={{ color: "red", fontSize: "14px" }}>
										{(formData.city === "" ||
											formData.city === null ||
											formData.city === undefined) &&
											errors.city}
									</p>
								</div>
							</div>
							<div className="col-lg-4">
								<label className="form-label">County</label>
								<div className="input-group">
									<input
										className="form-control w-100"
										type="text"
										placeholder="Country"
										required="required"
										name="country"
										value={formData.country ?? ""}
										onChange={handleChange}
									/>
									<p style={{ color: "red", fontSize: "14px" }}>
										{(formData.country === "" ||
											formData.country === null ||
											formData.country === undefined) &&
											errors.country}
									</p>
								</div>
							</div>

							<div className="col-lg-4">
								<label className="form-label">Cancel Cheque</label>
								<div className="input-group">
									<input
										className="form-control"
										type="file"
										required="required"
										name="cancel_cheque"
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
					</Form>

					{/* <h6 className="text-start mt-3"> -- OR -- </h6> */}

					<div className="row">
						<div className="col-lg-12">
							<button
								onClick={() =>
									setMemberData({
										payment: true,
										handlesubmit: handleAddEdit,
										openmodal: true,
									})
								}
								className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0"
							>
								Add New Payment
							</button>
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-lg-12">

							<button onClick={() => navigate(-1)} className="btn  bg-danger text-white btn-sm me-3 float-end mt-4 mb-0">
								Cancel
							</button>
						</div>
					</div>
				</div>

				<Modal
					show={selectedPayment !== null}
					onHide={handleCloseModal}
					size="xl"
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Payment Details</Modal.Title>
					</Modal.Header>

					<Modal.Body
						style={{
							overflowY: "auto",
							maxHeight: "450px",
							minHeight: "450px",
							// width: "500px",
						}}
					>
						<div className="row">
							<div className="col-lg-4">
								<label className="form-label">Account Name</label>
								<div className="input-group">
									<input
										className="form-control"
										type="text"
										placeholder="Account Name"
										required="required"
										name="account_name"
										value={selectedPayment?.account_name ?? ""}
										onChange={handleChangeEdit}
									/>
								</div>
							</div>
							<div className="col-lg-4">
								<label className="form-label">Account Number</label>
								<div className="input-group">
									<input
										className="form-control"
										type="number"
										placeholder="Account number"
										required="required"
										name="account_number"
										value={selectedPayment?.account_number ?? ""}
										onChange={handleChangeEdit}
									/>
								</div>
							</div>

							<div className="col-lg-4">
								<label className="form-label">Confirm Account Number</label>
								<div className="input-group">
									<input
										className="form-control"
										type="text"
										placeholder="Confirm account number"
										required="required"
										name="confirm_account_number"
										value={selectedPayment?.confirm_account_number ?? ""}
										onChange={handleChangeEdit}
									/>
								</div>
							</div>
						</div>

						<div className="row mt-2">
							<div className="col-lg-4">
								<label className="form-label">Bank Name</label>
								<div className="input-group">
									<input
										className="form-control"
										type="text"
										placeholder="Bank Name"
										required="required"
										name="bank_name"
										value={selectedPayment?.bank_name ?? ""}
										onChange={handleChangeEdit}
									/>
								</div>
							</div>
							<div className="col-lg-4">
								<label className="form-label">Branch</label>
								<div className="input-group">
									<input
										className="form-control"
										type="text"
										placeholder="Branch Name"
										required="required"
										name="branch"
										value={selectedPayment?.branch ?? ""}
										onChange={handleChangeEdit}
									/>
								</div>
							</div>

							<div className="col-lg-4">
								<label className="form-label">IFSC</label>
								<div className="input-group">
									<input
										className="form-control"
										type="text"
										placeholder="IFSC"
										required="required"
										name="ifsc"
										value={selectedPayment?.ifsc ?? ""}
										onChange={handleChangeEdit}
									/>
								</div>
							</div>
						</div>

						<div className="row mt-2">
							<div className="col-lg-4">
								<label className="form-label">City</label>
								<div className="input-group">
									<input
										className="form-control"
										type="text"
										placeholder="city"
										required="required"
										name="city"
										value={selectedPayment?.city ?? ""}
										onChange={handleChangeEdit}
									/>
								</div>
							</div>
							<div className="col-lg-4">
								<label className="form-label">County</label>
								<div className="input-group">
									<input
										className="form-control"
										type="text"
										placeholder="Country"
										required="required"
										name="country"
										value={selectedPayment?.country ?? ""}
										onChange={handleChangeEdit}
									/>
								</div>
							</div>

							<div className="col-lg-4">
								<label className="form-label">Cancel Cheque</label>
								<div className="input-group">
									<input
										className="form-control"
										type="file"
										required="required"
										name="cancel_cheque"
										onChange={handleChangeEdit}
									/>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="btn btn-light" onClick={() => handleCloseModal()}>
							Cancel
						</Button>
						<Button
							variant="primary"
							onClick={() => {
								handleAddEdit("edit");
							}}
						>
							update
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
			{memberData.payment === true && (
				<AddModal
					cancelBtn={"Cancel"}
					submitBtn={"SUBMIT"}
					createAddTopic={"Add Payment"}
					confirmText={"Add Payment"}
					isOpen={memberData.openmodal}
					onSubmit={memberData.handlesubmit}
					onClose={() => setMemberData({ openmodal: false })}
				/>
			)}
		</>
	);
}
