import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import buglogo from "../../assets/BugBounty.png";
import bigLogo from "../../assets/Bugbusterlabs 1.png";
import "./researcherProgramTab.css";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ResearcherProgramSummary from "./ResearcherProgramSummary";
import HTTPService from "../../../utils/axios";
import { toast } from "react-toastify";
import { formatDate } from "../../../utils/helperFunctions";
import moment from "moment";
import AgreementModal from "./AgreementModal/AgreementModal";
import useFetchCustomer from "../../HandleApis/Customer/FetchCustomer";
import AppContext from "../../Context/AppContext";
import Skeleton from "react-loading-skeleton";
import CircularLoader from "../../CircularLoader/CircularLoader";
import { customToast } from "../../../App";

const ResearcherMainProgramTab = () => {
  const { customerOpt } = useFetchCustomer();
  const [agreementModal, setAgreementModal] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const { invokeApi } = HTTPService();
  const [program, setProgram] = useState();
  const [customer, setCustomer] = useState({});
  const [safeHarbor, setSafeHarbor] = useState(false);
  const { loading } = useContext(AppContext);
  const [loadingPage, setLoadingPage] = useState(true);

  const checkDocument = async () => {
    invokeApi({
      method: "GET",
      url: `/safe-harbor/${id}`,
    })
      .then((data) => {
        if (data.status == "accepted") {
          setSafeHarbor(true);
        }
        console.log("researcher details", data);
      })
      .catch((error) => {
        return null;
      });
  };
  useEffect(() => {
    checkDocument();
  }, []);

  const fetchProgram = () => {
    setLoadingPage(true);
    invokeApi({
      method: "GET",
      url: `programs/?id=${id}`,
    })
      .then((data) => {
        if (data.results && data.results.length > 0)
          setProgram(data.results[0]);
        setLoadingPage(false);
      })
      .catch((error) => {
        setLoadingPage(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const fetchCustomer = () => {
    let customerDetails = customerOpt?.results?.find(
      (item) => program?.customer === item.id
    );
    setCustomer(() => {
      return { ...customerDetails };
    });
    console.log("customer details", customer);
  };
  useEffect(() => {
    if (id) {
      fetchProgram();
    }
  }, [id]);
  useEffect(() => {
    fetchCustomer();
  }, [program, customerOpt]);

  const dynamicBreadCrumb = () => {
    if (location.pathname === `/researcher/programs/${id}/summary`) {
      return "Summary";
    }
    if (location.pathname === `/researcher/programs/${id}/resource`) {
      return "Resource";
    }
    if (location.pathname === `/researcher/programs/${id}/submission`) {
      return "Submission";
    }
    if (location.pathname === `/researcher/programs/${id}/rewards`) {
      return "Reward";
    }
    if (location.pathname === `/researcher/programs/${id}/tracking`) {
      return "Tracking";
    }
    if (location.pathname === `/researcher/programs/${id}/announcement`) {
      return "Announcement";
    }
  };

  const Breadcrumbname = {
    home: "/researcher/home",
    Managment: "/researcher/program/dashboard",
    Programs: "/researcher/program/dashboard",
    [dynamicBreadCrumb()]: "",
  };
  const navigate = useNavigate();

  return (
    <>
      {loading && <CircularLoader size={100} />}
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6 ps-3 d-flex justify-content-end">
            <button
              onClick={() => navigate('/researcher/program/dashboard')}
              className="btn"
              style={{ backgroundColor: "#663bbb", color: "#fff" }}
            >
              BACK
            </button>
          </div>
        </div>

        <div className="row p-0">
          <div className="mainDivTopPart">
            <div className="col-12 ">
              {loadingPage ? (
                <Skeleton height={400} width={"100%"} />
              ) : (
                <>
                  <div className="d-flex">
                    <div
                      className="col-4 demoTitleDiv"
                      style={{ padding: "20px 17px" }}
                    >
                      <div
                        className=" demoTitleDiv2 d-flex justify-content-center align-items-center"
                        style={{ backgroundColor: "#fff" }}
                      >
                        <div className="row">
                          <div className="d-flex justify-content-center">
                            {program?.logo ? (
                              <img
                                style={{
                                  height: "90px",
                                  objectFit: "contain",
                                }}
                                src={program?.logo}
                                alt=""
                              />
                            ) : (
                              <img
                                style={{
                                  height: "200px",
                                  objectFit: "contain",
                                }}
                                src={bigLogo}
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <h5 className="demoTitleDivTitle mb-0">
                          Customer Details:
                        </h5>
                      </div>
                      <div className="row">
                        <div className="col-12 d-flex pt-3">
                          <div className="col-4">
                            <p
                              className="mb-0 "
                              style={{ color: "#525252", fontWeight: "600" }}
                            >
                              ID
                            </p>
                          </div>
                          <div className="col-8">
                            <p
                              className="mb-0 "
                              style={{ color: "#525252", fontWeight: "600" }}
                            >
                              {customer.user_id}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 d-flex pt-1">
                          <div className="col-4">
                            <p
                              className="mb-0 "
                              style={{ color: "#525252", fontWeight: "600" }}
                            >
                              Entity Name
                            </p>
                          </div>
                          <div className="col-8">
                            <p
                              className="mb-0 "
                              style={{ color: "#525252", fontWeight: "600" }}
                            >
                              {customer.entity_name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-8 demoTitleDiv">
                      <div className="row d-flex justify-content-between">
                        <div className="col-10 demoTitleDivTitle">
                          <h5 className="demoTitleDivTitle mb-0">
                            {program?.program_title}
                            <span className="">({program?.program_id})</span>
                          </h5>
                        </div>
                        <div className="col-2 d-flex justify-content-end">
                          <div
                            style={{
                              backgroundColor: "#FBD38D",
                              border: "1px solid #ddd",
                              borderRadius: "20px",
                              overflow: "hidden",
                            }}
                            className=" d-flex justify-content-center"
                          >
                            <span
                              className={
                                program?.program_status === "pending"
                                  ? "customerStatus customerStsPending"
                                  : program?.program_status === "hold"
                                    ? "customerStatus customerStsHold"
                                    : program?.program_status === "closed"
                                      ? "customerStatus customerStsClosed"
                                      : program?.program_status === "approved"
                                        ? "customerStatus customerStsApproved"
                                        : program?.program_status === "in_progess"
                                          ? "customerStatus customerInProgress"
                                          : ""
                              }
                              style={{ textTransform: "capitalize" }}
                            >
                              {program?.program_status === "in_progess"
                                ? "In-Progress"
                                : program?.program_status}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="iBar">
                          <div className="iBarIconDiv">
                            <i className="fa-solid fa-circle-info"></i>
                          </div>
                          <div className="d-flex align-items-center justify-content-center ms-3">
                            <p
                              className="mb-0 "
                              style={{ color: "#040404", fontWeight: "600" }}
                            >
                              Reminder:
                            </p>
                            <span
                              className="ms-2"
                              style={{ color: "#040404", fontWeight: "500" }}
                            >
                              Verify Safe Harbor Compliance before Signing
                              Submission Agreement.
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4 d-flex">
                        <div className="col-7 flex-column flagRow ">
                          <div className="d-flex flagRowChild">
                            <div className="flagRowChildI">
                              <div>
                                <i className="fa-solid fa-flag"></i>
                              </div>
                            </div>
                            <div
                              className="d-flex flagRowChildText"
                              style={{
                                color: "#666666",
                                fontSize: "14px",
                                marginTop: "0px",
                              }}
                            >
                              <p>
                                ${program?.p5_reward_amt}-$
                                {program?.p1_reward_amt}
                              </p>
                              <span>Per Vulnerability</span>
                            </div>
                          </div>
                          <div className="d-flex flagRowChild">
                            <div className=" flagRowChildIStart">
                              <div>
                                <i className="fa-solid fa-star"></i>
                              </div>
                            </div>
                            <div
                              className="d-flex flagRowChildText"
                              style={{
                                color: "#666666",
                                fontSize: "14px",
                                marginTop: "0px",
                              }}
                            >
                              <p>Up to ${program?.maximun_budget}</p>
                              <span>Maximum Reward</span>
                            </div>
                          </div>
                          <div className="d-flex flagRowChild">
                            <div className=" flagRowChildIStart">
                              <div>
                                <i className="fa-solid fa-bolt"></i>
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center flagRowChildText"
                              style={{
                                color: "#666666",
                                fontSize: "14px",
                                marginTop: "0px",
                              }}
                            >
                              <p>Program Type :</p>
                              <div>
                                <p>{program?.program_type}</p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flagRowChild">
                            <div className="flagRowChildIHand">
                              <div>
                                <i className="fa-solid fa-handshake"></i>
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center flagRowChildText"
                              style={{
                                color: "#666666",
                                fontSize: "14px",
                                marginTop: "0px",
                              }}
                            >
                              <p>Program Package :</p>
                              <div>
                                <p>{program?.program_package}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-5 flagRow">
                          <div className="d-flex flex-column ">
                            <div className="d-flex flagRowChild">
                              <div className=" flagRowChildIStart">
                                <div>
                                  <i className="fa-solid fa-bolt"></i>
                                </div>
                              </div>
                              <div className="d-flex align-items-center flagRowChildText">
                                <p>Severity :</p>
                                <span>{program?.severity}</span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mt-3">
                              <div className="d-flex">
                                <div className="flagRowChildIHand">
                                  <div>
                                    <i className="fa-solid fa-handshake"></i>
                                  </div>
                                </div>
                                <div className="d-flex flagRowChildText">
                                  <p style={{ marginTop: "0px" }}>
                                    Safe harbor
                                  </p>
                                </div>
                              </div>
                              <div className="">
                                <button
                                  className="viewAgreementBtn"
                                  onClick={() => setAgreementModal(true)}
                                  disabled={safeHarbor}
                                >
                                  {safeHarbor ? "Submitted" : "View Agreement"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <hr className="titlehr" />

                    <div className="row d-flex mb-4 justify-content-between align-items-center">
                      <div className=" col-3 ms-5 manageBy">
                        <div className="manageByImg">
                          <img src={buglogo} alt="" />
                        </div>
                        <div className="manageByText">
                          <p>Managed by Bugbusterlabs</p>
                        </div>
                      </div>
                      <div className="col-3">
                        <button
                          className="submitReportBtn"
                          onClick={() => {
                            navigate(
                              `/researcher/submissions/new/${program?.id}`
                            );
                          }}
                          disabled={!safeHarbor}
                          style={{ marginBottom: "0.5rem" }}
                        >
                          New Submission
                        </button>
                      </div>
                      <div className="col-4 text-end">
                        <p
                          style={{
                            color: "#000",
                            fontWeight: "500",
                            marginRight: "40px",
                            marginBottom: "0.5rem",
                          }}
                        >
                          Ends In:{" "}
                          {moment(program?.end_date).format(
                            "DD MMM YYYY hh:ss"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className="row align-items-center"
          style={{ borderBottom: "2px solid #EAEAEA" }}
        >
          <div className="col-8">
            <div className="nav-wrapper position-relative end-0 mt-4">
              <ul className="nav tabBarResearcher nav-fill " role="tablist">
                <li className="nav-item  d-flex" role="presentation">
                  <Link
                    className={
                      location.pathname === `/researcher/programs/${id}/summary`
                        ? "prgmTabLink mb-0 px-0  currTab"
                        : "prgmTabLink mb-0 px-0 "
                    }
                    to={{
                      pathname: `/researcher/programs/${id}/summary`,
                      state: { safeHarbor },
                    }}
                    aria-selected="false"
                  >
                    Program Summary
                  </Link>
                </li>
                <li className="nav-item d-flex" role="presentation">
                  <Link
                    className={
                      location.pathname ===
                        `/researcher/programs/${id}/resource`
                        ? "prgmTabLink mb-0 px-0  currTab"
                        : "prgmTabLink mb-0 px-0"
                    }
                    to={`/researcher/programs/${id}/resource`}
                    aria-selected="false"
                  >
                    Resources
                  </Link>
                </li>
                <li className="nav-item d-flex">
                  <Link
                    className={
                      location.pathname ===
                        `/researcher/programs/${id}/submission`
                        ? "prgmTabLink mb-0 px-0  currTab"
                        : "prgmTabLink mb-0 px-0"
                    }
                    to={`/researcher/programs/${id}/submission`}
                    aria-selected="false"
                  >
                    Submissions
                  </Link>
                </li>
                <li className="nav-item d-flex">
                  <Link
                    className={
                      location.pathname === `/researcher/programs/${id}/rewards`
                        ? "prgmTabLink mb-0 px-0  currTab"
                        : "prgmTabLink mb-0 px-0"
                    }
                    to={`/researcher/programs/${id}/rewards`}
                    aria-selected="false"
                  >
                    Rewards
                  </Link>
                </li>
                <li className="nav-item d-flex">
                  <Link
                    className={
                      location.pathname ===
                        `/researcher/programs/${id}/tracking`
                        ? "prgmTabLink mb-0 px-0  currTab"
                        : "prgmTabLink mb-0 px-0"
                    }
                    to={`/researcher/programs/${id}/tracking`}
                    aria-selected="false"
                  >
                    Tracking
                  </Link>
                </li>
                {/* <li className="nav-item d-flex">
									<Link
										className={
											location.pathname ===
											`/researcher/programs/${id}/announcement`
												? "prgmTabLink mb-0 px-0  currTab"
												: "prgmTabLink mb-0 px-0"
										}
										to={`/researcher/programs/${id}/announcement`}
										aria-selected="false"
									>
										Announcements
									</Link>
								</li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-12 mt-lg-0 mt-4 d-flex justify-content-center">
            {location.pathname === "/researcher/programs" ? (
              <ResearcherProgramSummary />
            ) : (
              <Outlet />
            )}
          </div>
        </div>
      </div>
      <AgreementModal
        setAgreementModal={setAgreementModal}
        setSafeHarbor={setSafeHarbor}
        isOpen={agreementModal}
        programId={program?.program_id}
        programTitle={program?.program_title}
        organizationName={
          customer && customer.entity_name && customer.entity_name
        }
        customerName={`${customer.first_name} ${customer.last_name}`}
        customerId={customer.user_id}
        onClose={() => setAgreementModal(false)}
      />
    </>
  );
};

export default ResearcherMainProgramTab;
