import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userInfo: {},
    userAccData: {},
    permissions: [],
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setUserAccData: (state, action) => {
      state.userAccData = action.payload;
    },
    setPermission: (state, action) => {
      state.permissions = action.payload;
    },
  },
});

export const { setUserInfo, setUserAccData, setPermission } = authSlice.actions;

export const getUserInfo = (state) => state.auth;

export const getPermissions = (state) => state.auth.permissions;

export default authSlice.reducer;
