import React, { useContext, useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap';
import AppContext from '../Context/AppContext';
import { toast } from 'react-toastify';
import useHttp from '../Hooks/useHttp';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../reducers/authSlice';
import { useParams } from 'react-router-dom';
import AddModal from './modal/AddModal';
import { customToast } from '../../App';

function KycProfile() {
	const isSupportEnabled = localStorage.getItem('supporttoken')
	const token = localStorage.getItem("token");
	const API = process.env.REACT_APP_API_URL;
	const [form, setForm] = useState({});
	const [errors, setErrors] = useState({});
	const [valids, setValids] = useState({});
	const { memberData, setMemberData, setCircularLoading } = useContext(AppContext);
	const { PatchRequest, GetRequest } = useHttp();
	const { userInfo } = useSelector(getUserInfo)
	const { userId } = useParams()
	useEffect(() => {
		if (userInfo?.user_data?.id) {
			fetAccountDetails();
		}
	}, [userInfo?.user_data?.id]);

	const fetAccountDetails = async () => {
		let userType = "customer";
		if (location.pathname.startsWith("/researcher")) {
			userType = "researcher";
		}
		if (
			location.pathname.includes("researcher") &&
			location.pathname.includes("/admin")
		) {
			userType = "researcher";
		}
		if (
			location.pathname.startsWith("user") &&
			location.pathname.includes("admin") &&
			location.pathname.includes("header") &&
			localStorage.getItem('role') !== 'sub_admin'

		) {
			userType = "user";
		}
		if (
			location.pathname.startsWith("/admin/user/edit")
		) {
			userType = "sub_admin";
		}
		if (
			location.pathname.startsWith("/admin/user/edit") &&
			localStorage.getItem('role') == 'sub_admin'
		) {
			userType = "sub_admin";
		}
		if (
			location.pathname.startsWith("/admin/user/edit") &&
			localStorage.getItem('role') == 'admin' && location.pathname.includes('header')
		) {
			userType = "user";
		}
		const { data, error } = await GetRequest(
			`${API}${userType}/${userId}`,
			{},
			{
				Authorization: "Bearer  " + token,
				"Content-Type": "multipart/form-data",
			}
		);
		if (error) {
			customToast.error("Something went worng!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {
			setForm(data);

		}
	};
	const checkKycValidity = () => {
		const { id_proof, id_proof_doc, address_proof, address_proof_doc } = form;

		const kycErrors = {};

		// id_proof errors
		if (!id_proof || id_proof === "")
			kycErrors.id_proof = "Please enter id  proof number";

		// id_proof_doc errors
		if (!id_proof_doc || id_proof_doc === "" || id_proof_doc.size === 0)
			kycErrors.id_proof_doc = "Please upload id  proof";

		// address_proof errors
		if (!address_proof || address_proof === "")
			kycErrors.address_proof = "Please enter your address proof number";

		// address_proof_doc errors
		if (
			!address_proof_doc ||
			address_proof_doc === "" ||
			address_proof_doc.size === 0
		)
			kycErrors.address_proof_doc = "Please upload address proof";

		return kycErrors;
	};

	//id proof
	const setIdProofNumber = async (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please Enter Id Proof Number",
			});
			setValids({
				...valids,
				[field]: false,
			});
		} else {
			setValids({
				...valids,
				[field]: true,
			});
			setErrors({
				...errors,
				[field]: null,
			});
		}
	};

	//id proof doc
	const setIdDocFile = async (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please Upload Id Proof Document",
			});
			setValids({
				...valids,
				[field]: false,
			});
		} else {
			setValids({
				...valids,
				[field]: true,
			});
			setErrors({
				...errors,
				[field]: null,
			});
		}
	};

	//address proof number
	const setAddressProofNumber = async (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please Enter Address Proof Number",
			});
			setValids({
				...valids,
				[field]: false,
			});
		} else {
			setValids({
				...valids,
				[field]: true,
			});
			setErrors({
				...errors,
				[field]: null,
			});
		}
	};

	//address proof doc
	const setAddressDocFile = async (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		if (!value || value === "") {
			setErrors({
				...errors,
				[field]: "Please Upload Address Proof Document",
			});
			setValids({
				...valids,
				[field]: false,
			});
		} else {
			setValids({
				...valids,
				[field]: true,
			});
			setErrors({
				...errors,
				[field]: null,
			});
		}
	};
	const saveKycInformation = () => {
		const kycErrors = checkKycValidity();
		if (Object.keys(kycErrors).length > 0) {
			setErrors(kycErrors);
			setMemberData({
				handlesubmit: () => handleSubmit(),
				openmodal: false,
				basicinfo: false,
			});
		} else {
			handleSubmit()
		}
	};

	const handleSubmit = async (type) => {
		let userType = "customer";
		if (location.pathname.startsWith("/researcher")) {
			userType = "researcher";
		}
		if (
			location.pathname.includes("researcher") &&
			location.pathname.includes("/admin")
		) {
			userType = "researcher";
		}
		if (
			location.pathname.startsWith("user") &&
			location.pathname.includes("admin") &&
			location.pathname.includes("header") &&
			localStorage.getItem('role') !== 'sub_admin'

		) {
			userType = "user";
		}
		if (
			location.pathname.startsWith("/admin/user/edit")
		) {
			userType = "sub_admin";
		}
		if (
			location.pathname.startsWith("/admin/user/edit") &&
			localStorage.getItem('role') == 'sub_admin'
		) {
			userType = "sub_admin";
		}
		if (
			location.pathname.startsWith("/admin/user/edit") &&
			localStorage.getItem('role') == 'admin' && location.pathname.includes('header')
		) {
			userType = "user";
		}
		//  variable that will be send in payload
		let apiData = {
			...form,
		};

		if (typeof apiData.display_pic === "string") {
			delete apiData.display_pic;
		}
		if (typeof apiData.address_proof_doc === "string") {
			delete apiData.address_proof_doc;
		}
		if (typeof apiData.id_proof_doc === "string") {
			delete apiData.id_proof_doc;
		}

		setMemberData({ openmodal: false });
		setCircularLoading(true);
		const authToken = isSupportEnabled != null ? isSupportEnabled : token;
		const { data, error } = await PatchRequest(
			API + `${userType}/` + userId + "/",
			{
				...apiData,
			},
			{},
			{
				Authorization: "Bearer  " + authToken,
				"Content-Type": "multipart/form-data",
			}
		);
		if (error) {
			setCircularLoading(false);
			customToast.error(error?.data?.detail ?? "Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
			});
		}
		if (data) {
			setCircularLoading(false);
			customToast.success("Data updated", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		}
	};
	return (
		<div>

			<div className="card mt-0" id="basic-info">
				<div className="card-header">
					<h5>KYC Info</h5>
				</div>
				<div className="card-body pt-0">
					<div className="row">
						<div className="row mt-3 ">
							<Form.Group as={Col} md="6">
								<Form.Label label="Password">ID Proof</Form.Label>
								<Form.Control
									className="multisteps-form__input"
									type="text"
									name="idproof"
									placeholder="Enter ID Proof Number"
									value={form && form.id_proof && form.id_proof}
									onChange={(e) =>
										setIdProofNumber("id_proof", e.target.value)
									}
									isInvalid={!!errors.id_proof}
									isValid={
										valids.id_proof && valids.id_proof === true
									}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.id_proof}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group
								as={Col}
								md="6"
								controlId="validationCustom03"
							>
								<Form.Label label="Password">
									Upload Image
								</Form.Label>
								<Form.Control
									className="multisteps-form__input"
									type="file"
									name="idproofdoc"
									onChange={(e) =>
										setIdDocFile(
											"id_proof_doc",
											e.target.files[0]
										)
									}
									isInvalid={!!errors.id_proof_doc}
									isValid={
										valids.id_proof_doc &&
										valids.id_proof_doc === true
									}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.id_proof_doc}
								</Form.Control.Feedback>
							</Form.Group>
						</div>
						<div className="row mt-3 mt-5">
							<Form.Group
								as={Col}
								md="6"
								controlId="validationCustom03"
							>
								<Form.Label label="Password">
									Address Proof
								</Form.Label>
								<Form.Control
									className="multisteps-form__input"
									type="text"
									name="addressproof"
									placeholder="Enter Address Proof Number"
									value={
										form &&
										form.address_proof &&
										form.address_proof
									}
									onChange={(e) =>
										setAddressProofNumber(
											"address_proof",
											e.target.value
										)
									}
									isInvalid={!!errors.address_proof}
									isValid={
										valids.address_proof &&
										valids.address_proof === true
									}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.address_proof}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group as={Col} md="6">
								<Form.Label label="Password">
									Upload Image
								</Form.Label>
								<Form.Control
									className="multisteps-form__input"
									type="file"
									name="addressproofdoc"
									onChange={(e) =>
										setAddressDocFile(
											"address_proof_doc",
											e.target.files[0]
										)
									}
									isInvalid={!!errors.address_proof_doc}
									isValid={
										valids.address_proof_doc &&
										valids.address_proof_doc === true
									}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.address_proof_doc}
								</Form.Control.Feedback>
							</Form.Group>
						</div>
					</div>
					<button
						className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0"
						type="button"
						onClick={() => {
							setMemberData({
								handlesubmit: () => saveKycInformation(),
								openmodal: true,
								basicinfo: true,
							});
						}}
					>
						Update
					</button>
				</div>
			</div>
			{memberData.basicinfo === true && (
				<AddModal
					cancelBtn={"Cancel"}
					submitBtn={"SUBMIT"}
					createAddTopic={"Update KYC"}
					confirmText={"Update KYC"}
					isOpen={memberData.openmodal}
					onSubmit={memberData.handlesubmit}
					onClose={() => setMemberData({ openmodal: false })}
				/>
			)}
		</div>
	)
}

export default KycProfile