import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Backbutton from "../../components/backbutton/Backbutton";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import { FaUserCircle } from "react-icons/fa";
import HTTPService from "../../../utils/axios";
import { formatDate, getObjectKeys } from "../../../utils/helperFunctions";
import ProfileImage from "../../../components/ProfileImage";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../reducers/authSlice";

export default function ViewSubCustomer() {
    const { invokeApi } = HTTPService()
    const { userId } = useParams();
    const [userData, setUserData] = useState();
    const [socialData, setSocialData] = useState();
    const [paymentData, setPaymentData] = useState();
    const [walletData, setWalletData] = useState();
    const { userInfo, permissions } = useSelector(getUserInfo);

    const fetchUserData = async () => {
        invokeApi({
            method: "GET",
            url: `customer/${userId}/`,
        }).then((data) => {
            setUserData(data)
            console.log("userdata", data)
        }).catch((error) => {
            console.log(error);
        })
    };
    const Breadcrumbname = {
        home: "/admin/home",
        Managment: "",
        'Support Customer': "/admin/supportcustomers",
        "View Support Customer": "",
    };
    useEffect(() => {
        if (userId) {
            fetchUserData();
            // fetchSocialData();
            // fetchPaymentData()
            // fetchWalletData()
        }
    }, [userId])

    const fetchSocialData = async () => {
        let userType = 'user_profile';
        invokeApi({
            method: "GET",
            url: `${userType}/?user=${userId}`,
        }).then((data) => {
            if (data.results && data.results[0]) {
                setSocialData(data.results[0])
            }
        }).catch((error) => {
            console.log(error);
        })
    };

    const fetchPaymentData = async () => {
        invokeApi({
            method: "GET",
            url: `payments/?user_id=${userId}`,
        }).then((data) => {
            if (data.results && data.results[0]) {
                setPaymentData(data.results[0])
            }
        }).catch((error) => {
            console.log(error);
        })
    };
    const fetchWalletData = async () => {
        invokeApi({
            method: "GET",
            url: `wallets/?user_id=${userId}`,
        }).then((data) => {
            if (data.results && data.results[0]) {
                setWalletData(data.results[0])
            }
        }).catch((error) => {
            console.log(error);
        })
    };

    return (
        <>
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-lg-6 m-auto ps-3">
                        <Breadcrumb names={Breadcrumbname} />
                    </div>
                    <div className="col-lg-6">
                        <Backbutton />
                    </div>
                </div>

                <div className="card card-body blur shadow-blur mx-0 overflow-hidden">
                    <div className="row gx-4">
                        <div className="col-auto">
                            <div className="avatar avatar-xl position-relative">
                                <ProfileImage src={userData?.display_pic} errorIcon={<FaUserCircle style={{ fontSize: '3rem', color: '#7f56d9 ' }} />} className="w-100 border-radius-lg shadow-sm" />
                            </div>
                        </div>
                        <div className="col-auto my-auto">
                            <div className="h-100">
                                <h5 className="mb-1">{userData?.first_name} {userData?.last_name}</h5>
                                <p className="mb-0 font-weight-bold text-sm">{userData?.user_id} ({userData?.username})</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                            <div className="nav-wrapper position-relative end-0">
                                <ul
                                    className="nav nav-pills nav-fill p-1 bg-transparent"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <Link
                                            to={`/${userInfo.role}/supportcustomers/edit/${userId}/basicinfo/#profile`}
                                            className="nav-link mb-0 px-0 py-1 text-end"
                                            role="tab"
                                            aria-selected="true"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="me-2"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                            >
                                                <mask
                                                    id="mask0_1683_5670"
                                                    maskUnits="userSpaceOnUse"
                                                    x="0"
                                                    y="0"
                                                    width="18"
                                                    height="19"
                                                >
                                                    <path
                                                        d="M14.0744 5.49005C14.5845 6.21955 14.9297 7.05105 15.086 7.92694H17V10.105H15.086C14.9297 10.9809 14.5845 11.8124 14.0744 12.5419L15.4284 13.8938L13.8856 15.4341L12.5316 14.0823C11.8009 14.5916 10.9681 14.9362 10.0908 15.0922V17.0032H7.9092V15.0922C7.0319 14.9362 6.19907 14.5916 5.4684 14.0823L4.1144 15.4341L2.5716 13.8938L3.9256 12.5419C3.41549 11.8124 3.07033 10.9809 2.914 10.105H1V7.92694H2.914C3.07033 7.05105 3.41549 6.21955 3.9256 5.49005L2.5716 4.13822L4.1144 2.59789L5.4684 3.94972C6.19907 3.44043 7.0319 3.09582 7.9092 2.93974V1.02881H10.0908V2.93974C10.9681 3.09582 11.8009 3.44043 12.5316 3.94972L13.8856 2.59789L15.4284 4.13822L14.0744 5.49005Z"
                                                        fill="white"
                                                        stroke="white"
                                                        strokeWidth="2"
                                                        strokeLinejoin="round"
                                                    ></path>
                                                    <path
                                                        d="M9 11.0126C9.53043 11.0126 10.0391 10.8023 10.4142 10.4278C10.7893 10.0533 11 9.54542 11 9.01584C11 8.48626 10.7893 7.97836 10.4142 7.60389C10.0391 7.22942 9.53043 7.01904 9 7.01904C8.46957 7.01904 7.96086 7.22942 7.58579 7.60389C7.21071 7.97836 7 8.48626 7 9.01584C7 9.54542 7.21071 10.0533 7.58579 10.4278C7.96086 10.8023 8.46957 11.0126 9 11.0126Z"
                                                        fill="black"
                                                        stroke="black"
                                                        strokeWidth="2"
                                                        strokeLinejoin="round"
                                                    ></path>
                                                </mask>
                                                <g mask="url(#mask0_1683_5670)">
                                                    <path
                                                        d="M-0.601562 -0.568604H18.5984V18.6006H-0.601562V-0.568604Z"
                                                        fill="#7F56D9"
                                                    ></path>
                                                </g>
                                            </svg>
                                            <span className="ms-1">Settings</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-4">
                <div className="row mt-3">
                    <div className="col-sm-12 mt-md-0 mt-4">
                        <div className="card h-100">
                            <div className="card-header pb-0 p-3">
                                <div className="row">
                                    <div className="col-md-8 d-flex align-items-center">
                                        <h6 className="mb-0">Profile Information</h6>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <Link to={`/${userInfo.role}/supportcustomers/edit/${userId}/basicinfo/#profile`}>
                                            <i
                                                className="fas fa-pen text-secondary text-sm"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                aria-hidden="true"
                                                aria-label="Edit Profile"
                                                data-bs-original-title="Edit Profile"
                                            ></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-3">
                                <p className="text-sm">
                                    {userData?.Short_Description}
                                </p>
                                <hr className="horizontal gray-light my-4" />
                                <ul className="list-group">
                                    <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                                        <strong className="text-dark">Full Name:</strong> &nbsp;
                                        {userData?.first_name} {userData?.last_name}
                                    </li>
                                    <li className="list-group-item border-0 ps-0 text-sm">
                                        <strong className="text-dark">Mobile:</strong> &nbsp;
                                        {userData?.phone_number}
                                    </li>
                                    <li className="list-group-item border-0 ps-0 text-sm">
                                        <strong className="text-dark">Email:</strong> &nbsp; {
                                            userData?.email
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
