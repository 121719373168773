import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import Footer from "../../components/Footer/Footer";
import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import { customToast } from "../../../App";

export default function ForgotPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const { invokeApi } = HTTPService();
  const sendEmail = async () => {
    if (!email || email.length < 0) {
      customToast.error("Enter your email!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return null;
    }
    const payload = { email: email };
    invokeApi({
      method: "POST",
      url: "forgot_password/",
      data: payload,
    }).then((res) => {
      navigate(
        location.pathname.startsWith("/admin")
          ? "/admin/forgotpasswordsuccess"
          : location.pathname.startsWith("/researcher")
            ? "/researcher/forgotpasswordsuccess"
            : location.pathname.startsWith("/customer") &&
            "/customer/forgotpasswordsuccess"
      );
    }).catch((error) => {
      console.log(error);
      customToast.error(`Error: ${error.data.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return null;
    });

  };
  return (
    <>
      <div
        className="bg-gray-100 "
        style={{ height: "100vh", position: "relative" }}
      >
        <div className="container h-100">
          <div
            className="d-flex flex-column justify-content-center "
            style={{ height: "88%" }}
          >
            <Link to="/">
              <div className=" top-content">
                <img className="logo" src={logo} alt="logo" width="20%" />
              </div>
            </Link>
            <div
              className="row   justify-content-center"
              style={{ marginTop: " 171px!important" }}
            >
              <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
                <div className="card z-index-0">
                  <div className="main-welcome" id="verifyemail">
                    {/* <img className="mb-3" src={envicon} alt="email-icon" /> */}
                    <h4 className="verifyemail text-dark pb-0">
                      Reset Password
                    </h4>
                    <span className="main-welcome-para">
                      Reset Password With <strong>BugbustersLab</strong>
                    </span>
                    <div
                      style={{
                        backgroundColor: "#d4f1df",
                        padding: "10px 19px",
                        borderRadius: "22px",
                        margin: "8px 28px",
                      }}
                    >
                      <span style={{ padding: "0px", color: "#59826c" }}>
                        Enter your Email and Instructions will be sent to you!
                      </span>
                    </div>
                  </div>
                  <div className="card-body py-0">
                    <form className="text-start">
                      <label htmlFor="">Email</label>
                      <div style={{ position: "relative" }}>
                        <div
                          style={{
                            position: "absolute",
                            top: "20%",
                            left: "8px",
                          }}
                        >
                          <i className="fa-solid fa-envelope-open-text"></i>
                        </div>
                        <input
                          type="email"
                          placeholder="Enter Email"
                          className="form-control"
                          style={{ paddingLeft: "30px" }}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="loginbtn btn  w-100  mb-2"
                          style={{ backgroundColor: "#6941C6" }}
                          onClick={() => sendEmail()}
                        >
                          Send Password Reset Email
                        </button>
                      </div>
                      <div className="mb-2 position-relative text-center">
                        <p className="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3">
                          or
                        </p>
                      </div>
                      <div className="text-center mb-3">
                        <span className="text-decoration-none text-cl">
                          Remember it ?{" "}
                          <Link
                            to={
                              location.pathname.startsWith("/admin")
                                ? "/admin/login"
                                : location.pathname.startsWith("/customer")
                                  ? "/customer/login"
                                  : location.pathname.startsWith("/researcher") &&
                                  "/researcher/login"
                            }
                          >
                            Signin
                          </Link>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ position: "absolute", bottom: "0px", width: "100%" }}>
          <Footer />
        </div>
      </div>
    </>
  );
}
