import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useHttp from "../../../Hooks/useHttp";
import AddModal from "../../../common/modal/AddModal";

import { useSelector } from "react-redux";
import withPermission from "../../../../hoc/withPermission";
import { getUserInfo } from "../../../../reducers/authSlice";
import AppContext from "../../../Context/AppContext";
import CommonTable from "../../../common/Table/CommonTable";
import { customToast } from "../../../../App";

const Faqa = () => {
  const { userInfo, permissions } = useSelector(getUserInfo);
  const permission = permissions.find((perm) => perm.module === "FAQ Q&A");
  const [openAddModal, setOpenAddModal] = useState({ isOpen: false });
  const [faqaData, setFaqaData] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [Title, setTitle] = useState();
  const [mTitle, setMTitle] = useState();
  const { DeleteRequest, GetRequest, PutRequest } = useHttp();
  const { setLoading } = useContext(AppContext);
  const token = localStorage.getItem("token");
  const API = process.env.REACT_APP_API_URL;
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();
  const filterColumn = [
    {
      name: "Status",
      value: "qa_status",
      filters: [
        { value: "active", label: "Active" },
        { value: "in_active", label: "Inactive" },]
    }
  ];
  const fetchQA = async (hide) => {
    if (!hide)
      setLoading(true);
    let url = `${API}faq_questionanswer/?page=${currentPage}&page_size=25`;
    if (searchQuery && searchQuery.length > 0) {
      url = url + `&${searchValue}=${searchQuery}`;
    }
    if (filterType && filterValue && filterValue.length > 0) {
      url = url + `&${filterType}=${filterValue}`;
    }
    const { data, error } = await GetRequest(
      url,
      {},
      {
        Authorization: "Bearer  " + token,
      }
    );
    if (error) {
      setLoading(false);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setLoading(false);
      setFaqaData(data);
      setTotalPages(data.total_pages);
    }
  };

  const fetchSubTitle = async () => {
    const { data, error } = await GetRequest(
      API + "faq_category/?category_type=sub_category&cat_status=active",
      {},
      {
        Authorization: "Bearer  " + token,
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      //
      setTitle(data);
    }
  };

  const fetchMainTitle = async () => {
    const { data, error } = await GetRequest(
      API + "faq_category/?category_type=main_category&cat_status=active",
      {},
      {
        Authorization: "Bearer  " + token,
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      console.log("maintitle", data);
      setMTitle(data);
    }
  };
  const deleteFaqQa = async (id) => {
    setOpenAddModal({ isOpen: false });
    const { error } = await DeleteRequest(
      API + "faq_questionanswer/" + id + "/",

      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      //alert("Something went wrong please check input");
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      fetchQA();
      customToast.success("Deleted Successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleStatus = async (
    id,
    ques,
    ans,
    sequence,
    uId,
    utype,
    subCatId,
    status
  ) => {
    const param = {
      question: ques,
      answer: ans,
      sequence: sequence,
      user_id: uId,
      user_type: utype,
      sub_category_id: subCatId,
      qa_status: status,
    };
    const { data, error } = await PutRequest(
      API + "faq_questionanswer/" + id + "/",
      param,
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      //alert("Something went wrong please check input");
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
    if (data) {
      fetchQA();
      customToast.success("status updated", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  useEffect(() => {
    fetchQA();
    fetchSubTitle();
    fetchMainTitle();
  }, []);

  useEffect(() => {
    fetchQA(true);
  }, [searchQuery, currentPage, filterValue]);

  const tableColumn = [
    "FAQ ID",
    "FAQ TYPE",
    "CATEGORY TITLE",
    "SUB-CATEGORY TITLE",
    "QUESTION TITLE",
    "SEQUENCE",
    "Status",
    "Action",
  ];
  const searchColumn = [
    { name: "Faq Id", value: "faq_qa_id" },
    { name: "Faq Type", value: "user_type" },
    { name: "Question", value: "question" },
    // { name: "Sequence", value: "sequence" },
  ];

  const Breadcrumbname = {
    home: "/admin/home",
    "Master Module": "",
    "FAQ Q&A": "/admin/faq/dashboard",
    Dashboard: "/admin/faq/dashboard",
  };

  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              {(permission?.create || userInfo.role === "admin") && (
                <Link
                  to="/admin/faq/add"
                  className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                  id="adduser"
                >
                  Add Question & Answer
                </Link>
              )}
            </div>
          </div>
        </div>

        <div className="table-overflow row my-4">
          <div className="col-12">
            <CommonTable
              filterList={filterColumn}
              filterType={filterType}
              setFilterType={setFilterType}
              setFilterValue={setFilterValue}
              column={tableColumn}
              data={faqaData && faqaData.results}
              totalPages={totalPages ?? 1}
              totalData={faqaData && faqaData.total_records}
              searchBar={true}
              searchBy={searchColumn}
              itemPerPg={25}
              setTable={2}
              card={7}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            >
              {faqaData &&
                faqaData.results &&
                faqaData.results.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        to={`/admin/faq/view/${item.id}`}
                        className="text-sm  mb-0 px-2 gotoView"
                      >
                        {item.faq_qa_id}
                      </Link>
                    </td>
                    <td>
                      <p className="text-sm  mb-0 px-2">{item.user_type}</p>
                    </td>

                    <td className="cell-breakWord text-start">
                      <p className="text-sm  mb-0">
                        {Title &&
                          Title.results.map((subT, indx) => {
                            let titleVal = "";
                            if (item.sub_category_id === subT.id) {
                              mTitle &&
                                mTitle.results.map((mt, inx) => {
                                  if (subT.parent_category_id === mt.id) {
                                    console.log(
                                      subT.parent_category_id === mt.id
                                    );
                                    titleVal = mt.category_title;
                                  }
                                });
                            }
                            return titleVal;
                          })}
                      </p>
                    </td>
                    <td className="text-start">
                      <p className="text-sm  mb-0">
                        {Title &&
                          Title.results.map((subT, indx) => {
                            if (item.sub_category_id === subT.id) {
                              return subT.category_title;
                            }
                          })}
                      </p>
                    </td>
                    <td className="text-start">
                      <p className="text-sm  mb-0">{item.question}</p>
                    </td>
                    <td>
                      <p className="text-sm  mb-0">{item.sequence}</p>
                    </td>

                    <td className="align-middle text-center">
                      {item.qa_status === "active" ? (
                        <span
                          style={{ cursor: "pointer" }}
                          className="badge bg-success text-white"
                          onClick={() => {
                            handleStatus(
                              item.id,
                              item.question,
                              item.answer,
                              item.sequence,
                              item.user_id,
                              item.user_type,
                              item.sub_category_id,
                              "in_active"
                            );
                          }}
                        >
                          Active
                        </span>
                      ) : (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleStatus(
                              item.id,
                              item.question,
                              item.answer,
                              item.sequence,
                              item.user_id,
                              item.user_type,
                              item.sub_category_id,
                              "active"
                            );
                          }}
                          className="badge bg-danger text-white"
                        >
                          Inactive
                        </span>
                      )}
                    </td>
                    <td>
                      <div className="d-flex">
                        <Link
                          to={`/admin/faq/view/${item.id}`}
                          className="btn btn-sm px-3 mt-2"
                        >
                          &#128065;
                        </Link>
                        <button
                          type="button"
                          className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v text-xs"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            {(permission?.edit ||
                              userInfo.role === "admin") && (
                                <Link
                                  className="dropdown-item"
                                  to={`/admin/faq/edit/${item.id}`}
                                >
                                  Edit
                                </Link>
                              )}
                          </li>
                          <li>
                            {(permission?.delete ||
                              userInfo.role === "admin") && (
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    setOpenAddModal({
                                      isOpen: true,
                                      id: item.id,
                                    })
                                  }
                                >
                                  Delete
                                </button>
                              )}
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
            </CommonTable>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"DELETE"}
        createAddTopic={"Delete Question"}
        confirmText={"Delete The Question"}
        isOpen={openAddModal.isOpen}
        onSubmit={() => deleteFaqQa(openAddModal.id)}
        onClose={() => setOpenAddModal({ isOpen: false })}
      />
    </>
  );
};

export default withPermission(Faqa);
Faqa.moduleName = "FAQ Q&A";
