export const getObjectKeys = (obj) => {
	if (typeof obj !== "object" || obj === null) {
		throw new Error("Input is not an object");
	}
	return Object.keys(obj);
};

export const formatDate = (dateString, whichOne) => {

	const date = new Date(dateString);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");
	const timeZone = "IST";
	if (whichOne === 1) {
		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${timeZone}`;
	}
	if (whichOne === 2) {
		return (
			<>
				<span>
					{`${year}-${month}-${day}`}
					<div>{` ${hours}:${minutes}:${seconds} ${timeZone}`}</div>
				</span>
			</>
		);
	}
};

export const lineOverFlowDot = (arr, lengthGet) => {
	const newArr = arr.replace(/(<([^>]+)>)/gi, "");
	if (newArr.length > lengthGet) {
		return newArr.substring(0, lengthGet) + "...";
	} else {
		return newArr;
	}
};

export const debounce = (func, wait) => {
	let timeout;
	return function (...args) {
		const context = this;
		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(context, args), wait);
	};
}

export const flattenedObject = (array) => array.reduce((acc, current) => {
	return { ...acc, ...current };
}, {});


export const acceptedMediaFileTypes = ['image/jpeg', 'image/png', 'image/webp'];

export const acceptedFileTypes = [
	'image/jpeg', 'image/png', 'image/webp',
	'application/pdf', 'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'text/csv', 'application/vnd.ms-powerpoint'
];

export const acceptedXlsDocFileTypes = [
	'application/pdf', 'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'text/csv', 'application/vnd.ms-powerpoint'
];
export const acceptedProofFileTypes = [
	'image/jpeg', 'image/png', 'image/webp',
	'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];


export const taxanomyTypes = [
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'text/csv', 'application/vnd.ms-powerpoint'
];
export const getOrdinalSuffix = (num) => {
	const j = num % 10,
		k = num % 100;
	if (j === 1 && k !== 11) {
		return num + "st";
	}
	if (j === 2 && k !== 12) {
		return num + "nd";
	}
	if (j === 3 && k !== 13) {
		return num + "rd";
	}
	return num + "th";
};

export const calculatePercentage = (str) => {
	const match = str.match(/(\d+)\s+of\s+(\d+)/i);
	if (!match) {
		return null; // Handle cases where the string format is invalid
	}
	const numerator = parseInt(match[1], 10);
	const denominator = parseInt(match[2], 10);
	if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
		return null; // Handle cases where the numbers are invalid or denominator is zero
	}
	return (numerator / denominator) * 100;
};


export const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const replaceMessageKeys = (template, message) => {
	return template.replace(/{(\w+)}/g, (match, key) => {
		if (key === 'time_stamp') {
			return '';
		}
		return `<b>${message[key]}</b>` || match; // Replace if the key is found, otherwise keep the placeholder
	}).replaceAll('Admin', '<b>Admin</b>');
}

export const moduleTypeIcon = {
	submission: 'fa-solid fa-ticket',
	transactions: 'fa fa-usd',
	account: 'fa-solid fa-user',
	signup: 'fa-solid fa-user',
	profile: 'fa-solid fa-user',
	program: 'fa-solid fa-ticket',
	wallet: 'fa fa-credit-card',
	badges: 'fa-solid fa-id-badge',
}