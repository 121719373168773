import React, { useState } from 'react'
import avatarimg from "../BackEnd/assets/img/user-default.png";

const ProfileImage = ({ src, errorIcon, alt, className, width, ...restProps }) => {
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };
    return (
        <>
            {imageError || src === "" || src == null ? (
                errorIcon ? errorIcon : <img
                    src={avatarimg}
                    alt=''
                    className={className}
                    {...restProps}
                />
            ) : (
                <img style={{
                    borderRadius: '50%',
                    objectFit: 'contain',
                    height: '100%',
                    width: width ? width : "100%"
                }} {...restProps} src={src} className={className} onError={handleImageError} alt={alt ?? 'image'} />
            )}
        </>
    );
}

export default ProfileImage