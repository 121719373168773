import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default function Partner() {
	return (
		<>
			<Header />
			<div className="container">
				<div>
					<h4 className="uui-heading-medium-2 privacyPolicyText text-center mt-5">Company</h4>
					<p>
						Welcome to Bugbusterslabs, your trusted cybersecurity testing and
						vulnerability management partner. Bugbusterslabs emphasizes the
						importance of cybersecurity in protecting digital assets from
						evolving cyber threats and the need for robust security measures.
					</p>
					<p>
						Bugbusterslabs offers a bug bounty platform connecting you with
						global security researchers to detect and fix vulnerabilities.
						Access a community of professionals and utilize Artificial
						Intelligence for an enhanced customer experience.
					</p>
					<p>
						By partnering with Bugbusterslabs, you gain access to
						industry-leading cybersecurity testing services tailored to your
						organization's specific needs and requirements. Our team
						collaborates with clients to create tailored security solutions for
						businesses of all sizes. Bugbusterslabs aims to improve security and
						safeguard digital assets from cyber threats.
					</p>
					<p>
						Join our satisfied customers who trust Bugbusterslabs Solutions to
						deliver reliable, effcient, cost-eective cybersecurity testing
						services. With Bugbusterslabs, you can take proactive steps to
						strengthen your security defenses and stay ahead of evolving cyber
						threats.
					</p>
				</div>
				<div>
					<h4 className="uui-heading-medium-2 privacyPolicyText text-center mt-5">Partners</h4>
					<p>
						Are you passionate about cybersecurity and committed to helping
						organizations protect their digital assets? Partner with
						Bugbusterslabs, a top provider of cybersecurity testing and
						vulnerability management services.
					</p>
					<p>
						At Bugbusterslabs, we believe in the power of collaboration and
						partnership to address the growing cybersecurity challenges faced by
						organizations worldwide. We seek skilled security researchers,
						cybersecurity professionals, and technology partners to join our
						global network and deliver innovative solutions to customers.
					</p>
					<p>
						By partnering with Bugbusterslabs, Bugbusterslabs provides
						partnership opportunities for independent security researchers and
						technology providers to showcase expertise, expand networks, and
						contribute to cybersecurity advancements.
					</p>
					<p>
						Join forces with Bugbusterslabs and become part of a dynamic
						community of cybersecurity experts dedicated to making the digital
						world safer for everyone. Together, we can strengthen security
						defenses, mitigate risks, and build a more secure future for
						organizations and individuals alike.
					</p>
				</div>
			</div>

			<Footer />
		</>
	);
}
