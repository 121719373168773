import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import "./researcherProgramDashboard.css";

import logo from "../../../assets/BugBounty.png";

import useHttp from "../../../Hooks/useHttp";
import { ToastContainer, toast } from "react-toastify";
import {
  debounce,
  getObjectKeys,
  lineOverFlowDot,
} from "../../../../utils/helperFunctions";
import PrgmSkeletonLoader from "../../../components/SkeletonLoader/PrgmSkeletonLoader";
import { Card, Col, Row, Stack } from "react-bootstrap";
import { MenuItem, Select } from "@mui/material";
import HTTPService from "../../../../utils/axios";
import Skeleton from "react-loading-skeleton";
import profile1 from "../../../../FrontEnd/Images/alex-suprun-ZHvM3XIOHoE-unsplash.jpg";
import profile2 from "../../../../FrontEnd/Images/alexander-hipp-iEEBWgY_6lA-unsplash.jpg";
import profile3 from "../../../../FrontEnd/Images/christopher-campbell-rDEOVtE7vOs-unsplash.jpg";
import profile4 from "../../../../FrontEnd/Images/jurica-koletic-7YVZYZeITc8-unsplash.jpg";
import ProfileImage from "../../../../components/ProfileImage";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../../reducers/authSlice";
import { customToast } from "../../../../App";
const sortOptions = [
  {
    label: "Default",
    value: "default",
  },
  {
    label: "Latest",
    value: "latest",
  },
  // {
  //   label: "status",
  //   value: "status",
  // },
  {
    label: "Name A-Z",
    value: "a-z",
  },
  {
    label: "Name Z-A",
    value: "z-a",
  },
  {
    label: "Serverity",
    value: "serverity",
  },
  // {
  // 	label: "End Date {(Critical,High,Medium,Low,Informational)}",
  // 	value: "Priority",
  // },
];
const statusOrders = [
  {
    label: "Select Status",
    value: "Select Status",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "In-Progress",
    value: "in_progess",
  },
  {
    label: "Hold",
    value: "hold",
  },
  {
    label: "Closed",
    value: "closed",
  },
];
// Approved, Pending, In-Progress, Hold, Closed.
const customOrder = {
  approved: 1,
  pending: 2,
  in_progress: 3,
  hold: 4,
  closed: 5,
};

const searchBy = [
  { name: "Id", value: "program_id" },
  { name: "Program Name", value: "program_title" },
  { name: "Program Type", value: "program_type" },
];

export default function ResearcherProgramDashbord() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [programData, setProgramData] = useState();
  const [customerDetails, setCustomerDetails] = useState();
  const [programType, setProgramType] = useState("All Programs");
  const [programTypes, setProgramTypes] = useState();
  const [sortBy, setSortBy] = useState("default");
  const [filteredStatus, setFilteredStatus] = useState("Select Status");
  const { GetRequest } = useHttp();
  const token = localStorage.getItem("token");
  const API = process.env.REACT_APP_API_URL;
  const { invokeApi } = HTTPService();

  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue, setInputValue] = useState("");

  const { userInfo } = useSelector(getUserInfo);
  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      // setCurrentPage(1);
    }, 800),
    []
  );
  const handleSearch = (e) => {
    setInputValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    const selectedOption = searchBy.find((opt) => searchValue === opt.value);
    setSearchPlaceHolder(
      selectedOption && selectedOption.name ? selectedOption.name : ""
    );
  }, [searchValue]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const timeZone = "IST";

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${timeZone}`;
  };

  const fetchProgramData = async () => {
    setLoading(true);
    let params = {};
    if (programType !== "All Programs" && programType !== "") {
      params.program_type = programType;
    }
    if (sortBy !== "default" && programType !== "") {
      params.sort_by = sortBy;
    }
    if (searchQuery && searchQuery.length > 0) {
      params[searchValue] = searchQuery;
    }

    const { data, error } = await GetRequest(
      API + "programs/researcher?program_status=approved",
      params,
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      console.log(error);
    }
    if (data) {
      setLoading(false);
      setProgramData(data);
    }
  };
  const fetchCustomerDetails = async () => {
    const { data, error } = await GetRequest(
      API + "customer/",
      {},
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      console.log(error);
    }
    if (data) {
      setCustomerDetails(data);
    }
  };
  const fetchProgramTypes = () => {
    invokeApi({
      method: "GET",
      url: `programs/count`,
    })
      .then((response) => {
        let newRes = response;
        let programTypes = [];
        let totalCount = getObjectKeys(newRes.program_type_counts).reduce(
          (total = 0, current) => {
            programTypes.push({
              count: newRes.program_type_counts[current],
              program_type: current,
            });
            return (total += parseInt(newRes.program_type_counts[current]));
          },
          0
        );
        programTypes.push({
          count: totalCount,
          program_type: "All Programs",
        });
        newRes.program_type_counts = programTypes;
        setProgramTypes(newRes);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (userInfo?.user_data?.id) {
      fetchProgramData();
      fetchCustomerDetails();
      fetchProgramTypes();
    }
  }, [userInfo?.user_data?.id]);

  useEffect(() => {
    if (programType && sortBy) {
      fetchProgramData();
    }
  }, [programType, sortBy, filteredStatus, searchQuery]);
  const Breadcrumbname = {
    home: "/researcher/home",
    Managment: "/researcher/program/dashboard",
    Programs: "/researcher/program/dashboard",
  };

  return (
    <div className="programContainer">
      <div className="container-fluid pt-4">
        <h3 style={{ color: "#000" }}>Programs</h3>
      </div>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
        </div>
        <div className="d-flex justify-content-between flex-wrap">
          <div className="col-lg-12 col-12">
            <div className="row d-flex">
              <div className="mt-4 mt-md-0" style={{ flex: 1 }}>
                <div className="card">
                  <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                  <div className="card-body p-3 position-relative">
                    <div className="row">
                      <div className="col-7 text-start">
                        <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                          <i
                            className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <h5 className="text-white font-weight-bolder mb-0 mt-3">
                          {programTypes?.program_status_counts?.approved ?? 0}
                        </h5>
                        <span className="cardHeader text-white text-sm">
                          Approved
                        </span>
                      </div>
                      <div className="col-5">
                        <div className="dropdown text-end mb-5"></div>
                        <h5 className="text-end text-white pt-3 mb-0 mt-2">
                          {programTypes?.today_program_status_counts
                            ?.approved ?? 0}
                        </h5>
                        <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                          Today's
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 mt-md-0" style={{ flex: 1 }}>
                <div className="card">
                  <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                  <div className="card-body p-3 position-relative">
                    <div className="row">
                      <div className="col-7 text-start">
                        <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                          <i
                            className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <h5 className="text-white font-weight-bolder mb-0 mt-3">
                          {programTypes?.program_status_counts?.pending ?? 0}
                        </h5>
                        <span className="cardHeader text-white text-sm">
                          Pending
                        </span>
                      </div>
                      <div className="col-5">
                        <div className="dropdown text-end mb-5"></div>
                        <h5 className="text-end text-white pt-3 mb-0 mt-2">
                          {programTypes?.today_program_status_counts?.pending ??
                            0}
                        </h5>
                        <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                          Today's
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 mt-md-0" style={{ flex: 1 }}>
                <div className="card">
                  <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                  <div className="card-body p-3 position-relative">
                    <div className="row">
                      <div className="col-7 text-start">
                        <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                          <i
                            className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <h5 className="text-white font-weight-bolder mb-0 mt-3">
                          {programTypes?.program_status_counts?.in_progress ??
                            0}
                        </h5>
                        <span className="cardHeader text-white text-sm">
                          In-Progress
                        </span>
                      </div>
                      <div className="col-5">
                        <div className="dropdown text-end mb-5"></div>
                        <h5 className="text-end text-white pt-3 mb-0 mt-2">
                          {programTypes?.today_program_status_counts
                            ?.in_progress ?? 0}
                        </h5>
                        <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                          Today's
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 mt-md-0" style={{ flex: 1 }}>
                <div className="card">
                  <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                  <div className="card-body p-3 position-relative">
                    <div className="row">
                      <div className="col-7 text-start">
                        <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                          <i
                            className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <h5 className="text-white font-weight-bolder mb-0 mt-3">
                          {programTypes?.program_status_counts?.hold ?? 0}
                        </h5>
                        <span className="cardHeader text-white text-sm">
                          Hold
                        </span>
                      </div>
                      <div className="col-5">
                        <div className="dropdown text-end mb-5"></div>
                        <h5 className="text-end text-white pt-3 mb-0 mt-2">
                          {programTypes?.today_program_status_counts?.hold ?? 0}
                        </h5>
                        <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                          Today's
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 mt-md-0" style={{ flex: 1 }}>
                <div className="card">
                  <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                  <div className="card-body p-3 position-relative">
                    <div className="row">
                      <div className="col-7 text-start">
                        <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                          <i
                            className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <h5 className="text-white font-weight-bolder mb-0 mt-3">
                          {programTypes?.program_status_counts?.closed ?? 0}
                        </h5>
                        <span className="cardHeader text-white text-sm">
                          Closed
                        </span>
                      </div>
                      <div className="col-5">
                        <div className="dropdown text-end mb-5"></div>
                        <h5 className="text-end text-white pt-3 mb-0 mt-2">
                          {programTypes?.today_program_status_counts?.closed ??
                            0}
                        </h5>
                        <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                          Today's
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="programContainer mt-4 row d-flex justify-content-between flex-wrap">
          <div className="col-6 d-flex justify-content-left align-items-start flex-wrap">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 me-4">
              <Select
                fullWidth
                value={programType}
                onChange={(e) => setProgramType(e.target.value)}
                size="small"
              >
                {programTypes &&
                  programTypes.program_type_counts &&
                  programTypes.program_type_counts
                    .sort((a, b) => b.count - a.count)
                    .map((data) => (
                      <MenuItem
                        key={data.program_type}
                        value={data.program_type}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>{data.program_type}</div>
                          <span className="counterProgram">{data.count}</span>
                        </div>
                      </MenuItem>
                    ))}
              </Select>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 me-4">
              <Select
                fullWidth
                defaultValue={sortOptions[0].value}
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                size="small"
              >
                {sortOptions &&
                  sortOptions.map((data) => (
                    <MenuItem key={data.value} value={data.value}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>{data.label}</div>
                      </div>
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-end align-items-start flex-wrap">
            <div className="search-container ">
              <div className="row">
                <div style={{ width: "300px" }}>
                  {loading ? (
                    <Skeleton height={40} />
                  ) : (
                    <select
                      name=""
                      className="form-select"
                      id=""
                      value={searchValue && searchValue}
                      onChange={(e) => handleSearchValue(e)}
                    >
                      <option value="">Select Search Option</option>
                      {searchBy.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div
                  className="input-group mb-3 d-flex justify-content-center align-items-center"
                  style={{ width: 300 }}
                >
                  {loading ? (
                    <>
                      <Skeleton height={40} width={250} />
                    </>
                  ) : (
                    <>
                      <input
                        type="text"
                        placeholder={`Search by ${searchPlaceHolder}`}
                        value={inputValue}
                        onChange={handleSearch}
                        disabled={searchPlaceHolder ? false : true}
                        className="form-control"
                      />

                      <span
                        style={{
                          position: "absolute",
                          right: 0,
                          zIndex: 100,
                          padding: "15px",
                        }}
                      >
                        <i className="fas fa-search" aria-hidden="true"></i>
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="total-programs">
              <Stack direction="row" alignItems="center">
                <h6 style={{ fontSize: "13px", fontWeight: 900 }}>
                  Total Programs:
                </h6>
                <span>
                  {programData?.results &&
                    programData.results?.length
                  }
                </span>
              </Stack>
            </div>
          </div>
        </div>

        <div className=" programContainer row d-flex ">
          {loading && <PrgmSkeletonLoader card={8} />}
          {programData && programData?.results &&
            programData.results?.map((item, index) => (
              <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                <div className="customer-name-status">
                  <div className="topdiv row mt-3 mb-3 w-100 d-flex justify-content-between align-items-center">
                    <div className="col-6">
                      <span className="customerName">
                        {customerDetails &&
                          customerDetails.results.map((ite, index) => {
                            if (item.customer === ite.id) {
                              return lineOverFlowDot(ite.entity_name, 20);
                            }
                          })}
                      </span>
                    </div>
                    <div className="col-6 d-flex flex-row justify-content-end align-items-center">
                      <div
                        style={{
                          backgroundColor: "#FBD38D",
                          border: "1px solid #ddd",
                          borderRadius: "20px",
                          overflow: "hidden",
                        }}
                        className="d-flex justify-content-center"
                      >
                        <span
                          className={
                            item.program_status === "pending"
                              ? "customerStatus customerStsPending"
                              : item.program_status === "hold"
                                ? "customerStatus customerStsHold"
                                : item.program_status === "closed"
                                  ? "customerStatus customerStsClosed"
                                  : item.program_status === "approved"
                                    ? "customerStatus customerStsApproved"
                                    : item.program_status === "in_progess"
                                      ? "customerStatus customerInProgress"
                                      : ""
                          }
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.program_status === "in_progess"
                            ? "In-Progress"
                            : item.program_status}
                        </span>
                      </div>
                      <div className="seePrgmMenu">
                        <button
                          type="button"
                          className="btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v text-md"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <ul className="dropdown-menu dropMenu">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/researcher/programs/${item.id}/submission`}
                            >
                              View Submissions
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mb-3 w-100 d-flex justify-content-center">
                    <div className="col-11 d-flex justify-content-center align-items-center aim-section">
                      <img src={item.logo} alt="" />
                    </div>
                  </div> */}
                  <div className="backProgram row mb-3 w-100 d-flex justify-content-center">
                    <div className="row prgmCardItem">
                      <div className="col-3">
                        <div className="frontPrgmLogo cursorPointer">
                          <Link
                            to={`/researcher/programs/${item.id}/summary`}
                          >
                            {" "}
                            <ProfileImage src={item.logo} alt="" />
                          </Link>
                        </div>
                      </div>
                      <div
                        className="col-9"
                        style={{ alignContent: "center", paddingLeft: 0 }}
                      >
                        <div className="col-12">
                          <div className="PTitle">
                            <Link
                              to={`/researcher/programs/${item.id}/summary`}
                            >
                              <h5
                                style={{
                                  cursor: "pointer",
                                  color: "#7F56D9",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                }}
                              >
                                {item.program_title}
                              </h5>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div>
                        <span className="pDescriptionText">Description</span>
                      </div>
                      <div>
                        <p className="pDescriptionDetails">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item.project_description,
                            }}
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row bug-bounty-demo">
                    <hr />
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        style={{ height: "30px", width: "30px" }}
                        src={logo}
                        alt=""
                      />
                      <span className="ms-3">{item.program_type}</span>
                    </div>

                    <hr />
                  </div>

                  <div className="backPrgmBottomMain">
                    <div className="row">
                      <div className="backPrgmBottomDiv mb-3 row d-flex justify-content-center align-items-center">
                        <div className="col-1">
                          <i className="fa-solid fa-id-badge"></i>
                        </div>
                        <div className="col-3">
                          <span
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            <b>ID</b>
                          </span>
                        </div>
                        <div className="col-7">
                          <Link
                            style={{
                              fontWeight: "600",
                              fontSize: "14px",
                              color: "#5A78F0",
                            }}
                            to={`/researcher/programs/${item.id}/summary`}
                          >
                            {item.program_id}
                          </Link>
                        </div>
                      </div>
                      <div className="backPrgmBottomDiv mb-3 row d-flex justify-content-center align-items-center">
                        <div className="col-1">
                          <i className="fa-regular fa-clock"></i>
                        </div>
                        <div className="col-3">
                          <span
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            <b>End In</b>
                          </span>
                        </div>
                        <div className="col-7">
                          <span
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            {formatDate(item.end_date)}
                          </span>
                        </div>
                      </div>
                      <div className="backPrgmBottomDiv mb-3 row d-flex justify-content-center align-items-center">
                        <div className="col-1">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-exclamation-square-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm6 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                            </svg>
                          </span>
                        </div>
                        <div className="col-3">
                          <span
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Severity
                          </span>
                        </div>
                        <div className="col-7">
                          <span
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            {item.severity}
                          </span>
                        </div>
                      </div>
                      <div className=" backPrgmBottomDiv mb-3 row d-flex justify-content-center align-items-center">
                        <div className="col-1">
                          <span>
                            <i className="fa-solid fa-clipboard-list"></i>
                          </span>
                        </div>
                        <div className="col-3">
                          <span
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Accept
                          </span>
                        </div>
                        <div className="col-7">
                          <span
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            1
                          </span>
                        </div>
                      </div>
                      <div className="backPrgmBottomDiv mb-3 row d-flex justify-content-center align-items-center">
                        <div className="col-1">
                          <span>
                            <i className="fa-solid fa-dollar-sign"></i>
                          </span>
                        </div>
                        <div className="col-3">
                          <span
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Reward
                          </span>
                        </div>
                        <div className="col-7">
                          <span
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            {item.maximun_budget}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="row w-100 px-2 d-flex justify-content-between">
                    <div className="col-6 d-flex justify-content-between align-items-center">
                      {/* <div className="col-4 researcherProfiles backPrgmResearcherProfile">
                        <div className="profileResearcher1">
                          <img src={profile1} alt="" />
                        </div>
                        <div className="profileResearcher2">
                          <img src={profile2} alt="" />
                        </div>
                        <div className="profileResearcher3">
                          <img src={profile3} alt="" />
                        </div>
                        <div className="profileResearcher4">
                          <img src={profile4} alt="" />
                        </div>
                        <div className="profileResearcher5">
                          <span>+2</span>
                        </div>
                      </div> */}
                      <div
                        className="col-12 responseCount"
                        style={{ alignContent: "center", marginTop: "0px" }}
                      >
                        <span>{item.submission_count} Responses</span>
                      </div>
                    </div>

                    <div className="col-6 d-flex justify-content-end pe-2 mb-3">
                      <button
                        className="view-summary-btn"
                        onClick={() =>
                          navigate(`/researcher/programs/${item.id}/summary`)
                        }
                      >
                        View Summary
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
