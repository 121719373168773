import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import HTTPService from "../../utils/axios";
import { moduleTypeIcon, replaceMessageKeys } from "../../utils/helperFunctions";
import CircularLoader from "../CircularLoader/CircularLoader";
import { customToast } from "../../App";

export default function ProgramTracking() {
	const { invokeApi } = HTTPService();
	const [loading, setLoading] = useState(true);
	const { id } = useParams();
	const [trackings, setTrackings] = useState([]);
	const getTracking = async () => {
		setLoading(true);
		const params = {
			program_id: id,
		};
		invokeApi({
			method: "GET",
			url: `program-tracking/`,
			params,
		}).then((res) => {
			setTrackings(res);
			setLoading(false);
		}).catch((error) => {
			setLoading(false);
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		});
	};
	useEffect(() => {
		getTracking();
	}, []);
	return (
		<>
			{loading &&
				<CircularLoader size={100} />}
			<div style={{ backgroundColor: "#fff", width: "100%", borderRadius: "30px" }}>
				<div className="row d-flex justify-content-start mt-5 px-7">
					{trackings.map((notification, index) => (
						<div key={index} className="d-flex py-1">
							<div className="my-auto mr-2">
								<i className={`${moduleTypeIcon['program']} fontSize-2rem mr-2 text-primary`} ></i>
							</div>
							<div className="d-flex flex-column justify-content-center">
								<h6 className="text-sm font-weight-normal mb-1">
									<span className="" style={{
										whiteSpace: "pre-wrap",
										display: "block"
									}}
										dangerouslySetInnerHTML={{ __html: replaceMessageKeys(notification?.message?.message, notification.message) }}
									>

									</span>
								</h6>
								<p className="text-xs text-secondary mb-0 mt-1 d-flex align-items-center">
									<div><i className="fa fa-clock me-1 text-primary"></i></div>
									<div>{notification?.message?.time_stamp}</div>
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
}

{/* <div key={index} className="statustimeline">
	<div className="statustimeline1">
		<div className="profilePic">
			<img src={profileImg} alt="" />
		</div>
		<div className="whatisthestatus">
			<div className="status1">
				<span className="displayName">{item.name}</span>
				<span className="displayParaStatus">{item.para}</span>
				{item.rewardedTo && item.rewardedTo !== null ? (
					<span className="displayName">{item.rewardedTo}</span>
				) : (
					""
				)}
				{item.state === null || item.state === "cancel" ? (
					""
				) : (
					<span className="trackingStatus">{item.state}</span>
				)}
				{item.point !== null ? (
					<span className="points" style={{ color: "#f00" }}>
						{item.point}
					</span>
				) : (
					""
				)}

				{item.rewarded !== null ? (
					<span className="dollar" style={{ color: "#f00" }}>
						{item.rewarded}
					</span>
				) : (
					""
				)}
			</div>
			<div className="timeago" style={{ fontSize: "11px" }}>
				<span>{item.time}</span>
			</div>
		</div>
	</div>
	{item.state === "cancel" ? (
		<div className="d-flex flex-column ms-7">
			<span
				style={{
					color: "#000",
					fontSize: "15px",
					fontWeight: "600",
				}}
			>
				Reason: Incorrect Amount
			</span>
			<span style={{ fontSize: "12px", color: "#000" }}>Should have been $1000</span>
		</div>
	) : (
		""
	)}
</div> */}
