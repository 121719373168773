import React, { useContext } from "react";
import "../signin/customerSignin.css";
import Footer from "../../components/Footer/Footer";

import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
// import adminData from "./adminUser.json";
import Login from "./Login";
import AppContext from "../../Context/AppContext";
import CircularLoader from "../../CircularLoader/CircularLoader";

const CustomerBasic = () => {
	const {loading}=useContext(AppContext)
	return (
		<>
			{loading && <CircularLoader size={100}/>}
			<div className="bg-gray-100">
				<main
					className="main-content position-relative mt-0"
					style={{ height: "100vh" }}
				>
					<div className="container">
						<Link to="/">
							<div className=" top-content w-100">
								<img className="logo" src={logo} alt="logo" width="20%" />
							</div>
						</Link>
						<div
							className="row   justify-content-center"
							style={{ marginTop: " 171px!important" }}
						>
							<div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
								<div className="card z-index-0">
									<div className="main-welcome">
										<h4 className="main-welcome-head CUS">Welcome Back!</h4>
										<span className="main-welcome-para">
											Sign-in to continue to Bugbusterslabs
										</span>
									</div>
									<div className="card-body pt-0">
										<Login />
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</main>
			</div>
		</>
	);
};

export default CustomerBasic;
