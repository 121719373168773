import { Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import "./BadgeLevelModal.css";
import { toast } from "react-toastify";
import useHttp from "../Hooks/useHttp";
import { customToast } from "../../App";

const BadgeLevelModal = ({
  show,
  setShow,
  modalClose,
  priorityOptions,
  baseValueOptions,
  modalId,
  setModalId,
}) => {
  const [form, setForm] = useState();
  const [errors, setErrors] = useState({});
  const [valids, setValids] = useState({});
  const [levelsList, setLevelsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  const { PostRequest, GetRequest, PutRequest, DeleteRequest } = useHttp();
  const token = localStorage.getItem("token");
  const API = process.env.REACT_APP_API_URL;

  // const handleChange = (selectedOption) => {
  //   setSelectedOption(selectedOption);
  //   setError("");
  // };

  const setPoint = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Enter Points",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const handlePriorityChange = (selectedOption) => {
    setForm({
      ...form,
      priority: selectedOption,
    });
    setError("");
  };

  const handleBaseValueChange = (selectedOption) => {
    setForm({
      ...form,
      baseValue: selectedOption,
    });
    setError("");
  };

  const deleteLevel = async (e, index, id) => {
    e.preventDefault();
    const { data, error } = await DeleteRequest(API + `badge-levels/${id}/`, {
      Authorization: "Bearer  " + token,
    });
    if (error) {
      console.log(error);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      const arr = [...levelsList];
      arr.splice(index, 1);
      setLevelsList([...arr]);
    }
  };

  const fetchLevels = async () => {
    const { data, error } = await GetRequest(
      API + `badge-levels?badge_id=${modalId}`,
      {},
      {
        Authorization: "Bearer  " + token,
      }
    );
    if (error) {
      console.log(error);
      // customToast.error("Something went wrong!", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 1000,
      // });
    } else {
      const tempArr = [];
      data.results.map((item) => {
        tempArr.push({
          point: item.points,
          priority: { value: item.priority, label: item.priority },
          baseValue: { value: item.type, label: item.type },
          id: item.id,
        });
      });
      setLevelsList([...tempArr]);
    }
  };
  useEffect(() => {
    if (modalId) {
      fetchLevels();
    }
  }, [modalId]);

  const handleAddInput = async (e) => {
    if (form && form.point && form.priority && form.baseValue) {
      const param = {
        level: levelsList.length + 1,
        points: form.point,
        type: form.baseValue.map((item) => item.value).join(", "),
        priority: form.priority.map((item) => item.value).join(", "),
        badge_id: modalId,
      };
      setLoading(true);
      const { data, error } = await PostRequest(API + "badge-levels/", param, {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      });
      if (error) {
        console.log(error);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        setLevelsList([
          {
            point: form.point,
            priority: {
              value: form.priority.map((item) => item.value).join(", "),
              label: form.baseValue.map((item) => item.value).join(", "),
            },
            baseValue: {
              value: form.baseValue.map((item) => item.value).join(", "),
              label: form.baseValue.map((item) => item.value).join(", "),
            },
            id: data.id,
          },
          ...levelsList,
        ]);
        setForm({});
      }
      setLoading(false);
    } else {
      setError("Please select an option.");
      return;
    }
  };

  console.log("levels", levelsList);

  return (
    <Modal
      show={show}
      onHide={modalClose}
      // dialogClassName="custom-modal"
      // style
      size="xl"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Level</Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{
          overflowY: "auto",
          maxHeight: "450px",
          minHeight: "450px",
          // width: "500px",
        }}
      >
        <div
          className="card-randdom px-3 mt-4 box-shadow-0 border rounded p-4"
        // style={{ width: "500px !important" }}
        >
          <div className="row">
            <div className="col-lg-2 d-flex align-items-center justify-content-center">
              <Form.Label className="h6 mt-4">Level :</Form.Label>
            </div>

            <div className="col-lg-3">
              <Form.Label>Priority</Form.Label>
              {/* <Select
                options={priorityOptions}
                onChange={handlePriorityChange}
              /> */}
              <form>
                <Select
                  value={form && form.priority ? form.priority : ""}
                  onChange={handlePriorityChange}
                  options={priorityOptions}
                  placeholder="Select.."
                  isMulti
                />
                {error && (
                  <div style={{ color: "red", textWrap: "nowrap" }}>
                    {error}
                  </div>
                )}
              </form>
            </div>
            <div className="col-lg-2">
              <Form.Label>Count</Form.Label>
              <Form.Control
                className="m-0"
                type="text"
                name="Points"
                placeholder="Enter Count"
                value={form && form.point ? form.point : ""}
                onChange={(e) => setPoint("point", e.target.value)}
                isInvalid={!!errors.point}
                isValid={valids.point && valids.point === true}
              ></Form.Control>
            </div>
            <div className="col-lg-3">
              <Form.Label>Type</Form.Label>
              {/* <Select
                options={baseValueOptions}
                onChange={handleBaseValueChange}
              /> */}
              <form>
                <Select
                  value={form && form.baseValue ? form.baseValue : ""}
                  onChange={handleBaseValueChange}
                  options={baseValueOptions}
                  placeholder="Select.."
                  isMulti
                />
                {error && (
                  <div style={{ color: "red", textWrap: "nowrap" }}>
                    {error}
                  </div>
                )}
              </form>
            </div>
            <div className="col-lg-2 d-flex align-items-center">
              <button
                onClick={handleAddInput}
                style={{ borderRadius: "8px", whiteSpace: "nowrap" }}
                className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
              >
                Add +
              </button>
            </div>
          </div>
          {levelsList.map((item, index) => (
            <div className="row d-flex  mt-3">
              <div className="col-lg-2 d-flex align-items-center justify-content-center">
                <Form.Label className="h6 mt-2">
                  {/* Level-{item.priority.value.split("P")} */}
                  Level-{levelsList.length - index}
                </Form.Label>
              </div>

              <div className="col-lg-3">
                <Form.Control
                  className="m-0"
                  type="text"
                  name="Points"
                  value={item.priority.value}
                  disabled={true}
                ></Form.Control>
              </div>
              <div className="col-lg-2">
                <Form.Control
                  className="m-0"
                  type="text"
                  name="Points"
                  value={item.point}
                  disabled={true}
                ></Form.Control>
              </div>
              <div className="col-lg-3">
                <Form.Control
                  className="m-0"
                  type="text"
                  name="Points"
                  value={item.baseValue.value}
                  disabled={true}
                ></Form.Control>
              </div>
              <div className="col-lg-2 d-flex align-items-end">
                <button
                  onClick={(e) => deleteLevel(e, index, item.id)}
                  style={{ borderRadius: "8px" }}
                  className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                  disabled={loading}
                >
                  X
                </button>
              </div>
              <div></div>
            </div>
          ))}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setLevelsList([]);
            modalClose();
          }}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BadgeLevelModal;
