import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HTTPService from "../../../utils/axios";
import { toast } from "react-toastify";
import { customToast } from "../../../App";

const ResearcherProgramResources = () => {
  const { id } = useParams();
  const { invokeApi } = HTTPService();
  const [program, setProgram] = useState();

  const fetchProgram = () => {
    invokeApi({
      method: "GET",
      url: `programs/${id}`,
    })
      .then((data) => {
        setProgram(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  useEffect(() => {
    if (id) {
      fetchProgram();
    }
  }, [id]);
  return (
    <>
      <div
        className="row w-100 pt-3"
        style={{ backgroundColor: "#fff", paddingBottom: "20px" }}
      >
        <div className="col-12">
          <span style={{ fontSize: "14px", fontWeight: "500" }}>
            file attached in this brief. Sorted by the upload date.
          </span>
        </div>
        <div style={{ marginTop: "30px", marginLeft: "30px" }}>
          {program && program[`program_attachments`] && (
            <div className="fileDetails">
              <div className="fileIcon">
                <i className="fa-solid fa-file-image"></i>
              </div>
              <div>
                <div className="fileName">
                  <a
                    target="_blank"
                    href={program[`program_attachments`]}
                    rel="noreferrer"
                    download={true}
                  >
                    {program[`program_attachments`].split("/").at(-1)}
                    {"   "}
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
                {/* <div>
										<span style={{ fontSize: "14px", fontWeight: "500" }}>
											736 KB Image . Uploaded on 8 Aug 2020
										</span>
									</div> */}
              </div>
            </div>
          )}
          {[1, 2, 3, 4, 5].map((data) => {
            if (program && program[`taxonomy_doc${data}`])
              return (
                <div key={data} className="fileDetails">
                  <div className="fileIcon">
                    <i className="fa-solid fa-file-image"></i>
                  </div>
                  <div>
                    <div className="fileName">
                      <a
                        target="_blank"
                        href={program[`taxonomy_doc${data}`]}
                        rel="noreferrer"
                        download={true}
                      >
                        {program[`taxonomy_doc${data}`].split("/").at(-1)}
                        {"   "}
                        <i className="fa-solid fa-download"></i>
                      </a>
                    </div>
                    {/* <div>
										<span style={{ fontSize: "14px", fontWeight: "500" }}>
											736 KB Image . Uploaded on 8 Aug 2020
										</span>
									</div> */}
                  </div>
                </div>
              );
          })}

          {[1, 2, 3, 4, 5].map((data) => {
            if (program && program[`upload_doc${data}`])
              return (
                <div key={data} className="fileDetails">
                  <div className="fileIcon">
                    <i className="fa-solid fa-file-image"></i>
                  </div>
                  <div>
                    <div className="fileName">
                      <a
                        target="_blank"
                        href={program[`upload_doc${data}`]}
                        rel="noreferrer"
                        download={true}
                      >
                        {program[`upload_doc${data}`].split("/").at(-1)}
                        {"   "}
                        <i className="fa-solid fa-download"></i>
                      </a>
                    </div>
                    {/* <div>
										<span style={{ fontSize: "14px", fontWeight: "500" }}>
											736 KB Image . Uploaded on 8 Aug 2020
										</span>
									</div> */}
                  </div>
                </div>
              );
          })}
        </div>
      </div>
    </>
  );
};

export default ResearcherProgramResources;
