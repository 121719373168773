import { useContext, useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserInfo, setUserAccData } from "../../../reducers/authSlice";
import HTTPService from "../../../utils/axios";
import { getObjectKeys, moduleTypeIcon, replaceMessageKeys } from "../../../utils/helperFunctions";
import "../../assets/css/dashboard.css";
import "../../assets/css/nucleo-icons.css";
import "../../assets/css/nucleo-svg.css";
import logo from "../../assets/logo.png";
import AppContext from "../../Context/AppContext";
import AvatarMenu from "./AvatarMenu"; // import the AvatarMenu component
import "./Header.css";
import { customToast } from "../../../App";

export default function Header({ returnhome, children }) {
  const { invokeApi } = HTTPService();
  const { setShowLockScreen } = useContext(AppContext);
  const location = useLocation();
  const { userInfo, permissions } = useSelector(getUserInfo);
  const dispatch = useDispatch();
  const [userdata, setUserData] = useState();
  const [userFound, setUserFound] = useState(true);
  const [imageError, setImageError] = useState(false);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const uid = localStorage.getItem("userid");
  const [userType, setUserType] = useState();
  const [type, setType] = useState();
  const { setCircularLoading } = useContext(AppContext);
  const [notifications, setNotifications] = useState();

  const Logout = async () => {
    try {
      setCircularLoading(true);
      if (
        userInfo?.role === "admin" ||
        location.pathname.startsWith("/admin")
      ) {
        let url =
          userInfo?.role === "sub_admin" ? `sub_admin/logout/` : `user/logout/`;
        invokeApi({
          method: "POST",
          url,
        })
          .then((data) => {
            setCircularLoading(false);
            localStorage.clear();
            if (userInfo?.role === "sub_admin") {
              navigate("/subadmin/logout");
            } else {
              navigate("/admin/logout");
            }
          })
          .catch((error) => {
            setCircularLoading(false);
            customToast.error("Something went wrong!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          });
      }
      if (
        userInfo?.role === "researcher" ||
        location.pathname.startsWith("/researcher")
      ) {
        invokeApi({
          method: "POST",
          url: `researcher/logout/`,
        })
          .then((data) => {
            setCircularLoading(false);
            localStorage.clear();
            navigate("/researcher/logout");
          })
          .catch((error) => {
            setCircularLoading(false);
            customToast.error("Something went wrong!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          });
      }
      if (
        userInfo?.role === "customer" ||
        location.pathname.startsWith("/customer")
      ) {
        invokeApi({
          method: "POST",
          url: `customer/logout/`,
        })
          .then((data) => {
            setCircularLoading(false);
            localStorage.clear();
            navigate("/customer/logout");
          })
          .catch((error) => {
            setCircularLoading(false);
            customToast.error("Something went wrong!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          });
      }
    } catch (error) {
      setCircularLoading(false);
      console.error("Error during logout:", error);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const fetchUser = async () => {
    if (userInfo?.role === "admin" || location.pathname.startsWith("/admin")) {
      let url =
        userInfo?.role === "sub_admin" ? `sub_admin/${uid}/` : `user/${uid}/`;
      invokeApi({
        method: "GET",
        url,
      })
        .then((data) => {
          setUserData(data);
          dispatch(setUserAccData(data));
        })
        .catch((error) => {
          customToast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return null;
        });
    } else if (
      userInfo?.role === "customer" ||
      location.pathname.startsWith("/customer")
    ) {
      invokeApi({
        method: "GET",
        url: `customer/${uid}/`,
      })
        .then((data) => {
          setUserData(data);
          localStorage.setItem("role", "customer");
          dispatch(setUserAccData(data));
        })
        .catch((error) => {
          customToast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return null;
        });
    } else if (
      userInfo?.role === "researcher" ||
      location.pathname.startsWith("/researcher")
    ) {
      invokeApi({
        method: "GET",
        url: `researcher/${uid}/`,
      })
        .then((data) => {
          setUserData(data);
          localStorage.setItem("role", "researcher");
          dispatch(setUserAccData(data));
        })
        .catch((error) => {
          customToast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return null;
        });
    }

    return null;
  };

  const fetchNotifications = async () => {
    invokeApi({
      method: "GET",
      url: `/notifications/`,
      params: {
        "is_read": "false"
      }
    })
      .then((data) => {
        setNotifications(data);
      })
      .catch((error) => {
        return null;
      });
  };
  const setScreenLock = () => {
    setShowLockScreen(true);
    localStorage.setItem("showLockScreen", 'true');
  };

  useEffect(() => {
    if (getObjectKeys(userInfo).length > 0 && userInfo.role) {
      fetchUser();
      fetchNotifications()
    }
  }, [userInfo?.user_data?.id]);

  useEffect(() => {
    if (location.pathname.startsWith("customer")) {
      setUserType("customer");
    } else if (location.pathname.startsWith("researcher")) {
      setUserType("researcher");
    } else {
      setUserType("user");
    }
    if (location.pathname) setType(location.pathname.split("/")[1]);
  }, [location]);

  return (
    <>
      {/* Desktop and Tablet Header */}
      <div
        className="main-menu position-sticky top-0 px-0 mx-0 border-radius-xl z-index-sticky d-none d-md-block"
        id="desktop-header"
      >
        <nav className="navbar navbar-expand-lg shadow-none p-0">
          <div className="container-fluid">
            <Link className="navbar-brand" to={returnhome}>
              <div className="d-flex">
                <img src={logo} alt="logo" width="30%" />
              </div>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <div className="mx-md-auto pe-md-3 d-flex">
                {/* <div
                  className="input-group"
                  style={{ width: "350px", marginLeft: "315px" }}
                >
                  <span className="input-group-text text-body">
                    <i className="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type here..."
                  />
                </div> */}
              </div>
              <div className="d-flex justify-content-end">
                <ul
                  className="navbar-nav justify-content-end"
                  style={{ width: "min-content" }}
                >
                  <div>
                    <li
                      className="nav-item d-flex align-items-center"
                      style={{ width: "39%" }}
                    >
                      <AvatarMenu
                        userdata={userdata}
                        userFound={userFound}
                        imageError={imageError}
                        setImageError={setImageError}
                        type={type}
                        userType={userType}
                        Logout={Logout}
                        setScreenLock={setScreenLock}
                      />
                    </li>
                  </div>
                  <li className="nav-item dropdown pe-2 d-flex align-items-center">
                    <Link
                      to="#"
                      className="nav-link text-body p-0"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa fa-bell cursor-pointer"></i>
                    </Link>
                    <ul
                      className="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4 notificationConatiner"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {
                        notifications && notifications.length > 0 ? notifications.map((notification => <li key={notification?.id} className="mb-2">
                          <Link className="dropdown-item border-radius-md" to="#">
                            <div className="d-flex py-1">
                              <div className="my-auto mr-2">
                                <div className="bg-primary"
                                  style={{
                                    borderRadius: '50%', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    width: '40px',
                                    height: '40px',
                                  }}>
                                  <i className={`${moduleTypeIcon[notification.module_type]} fontSize-2rem text-white`} ></i>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-center">
                                <h6 className="text-sm font-weight-normal mb-1">
                                  <span className="" style={{
                                    whiteSpace: "pre-wrap",
                                    width: "300px",
                                    display: "block"
                                  }}
                                    dangerouslySetInnerHTML={{ __html: replaceMessageKeys(notification?.message?.message, notification.message) }}
                                  >
                                  </span>
                                </h6>
                                <p className="text-xs text-secondary mb-0 mt-1 d-flex align-items-center">
                                  <div><i className="fa fa-clock me-1"></i></div>
                                  <div>{notification?.message?.time_stamp}</div>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </li>)) : <h6 className="p-3"> <i className="fa-solid fa-comments"></i> No new notifications</h6>
                      }
                      <li className="text-center">
                        <Link to={`notifications`}>
                          <button type="button" className="btn bg-primary text-white">View All</button>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <nav
          className="px-5 navbar-expand-lg bg-white primary-menu"
          style={{ paddingBottom: ".15rem", paddingTop: ".15rem" }}
        >
          <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
            {children}
          </ul>
        </nav>
      </div>

      {/* Mobile Header */}
      <div
        className="main-menu position-sticky top-0 px-0 mx-0 z-index-sticky d-block d-md-none"
        id="mobile-header"
      >
        <nav
          className="navbar navbar-main navbar-expand-lg position-sticky top-0 px-0 mx-0 z-index-sticky blur shadow-blur left-auto"
          id="navbarBlur"
          data-scroll="true"
        >
          <div className="container-fluid py-1 px-3 d-flex justify-content-between">
            <Link className="navbar-brand p-0" to={returnhome}>
              <img src={logo} alt="logo" width="30%" />
            </Link>
            <div className="d-flex align-items-center">
              <div className="input-group me-3">
                <span className="input-group-text text-body">
                  <i className="fas fa-search" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here..."
                />
              </div>
              <div className="d-flex align-items-center">
                <AvatarMenu
                  userdata={userdata}
                  userFound={userFound}
                  imageError={imageError}
                  setImageError={setImageError}
                  type={type}
                  userType={userType}
                  Logout={Logout}
                  setScreenLock={setScreenLock}
                />
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={handleShow}
                  style={{ border: "0px solid #fff" }}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {/* Offcanvas Left Modal */}
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title className="p-0">
            <i
              className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0"
              aria-hidden="true"
              id="iconSidenav"
              onClick={handleClose}
            ></i>
            <Link className="navbar-brand m-0" to="/">
              <img
                src={logo}
                className="navbar-brand-img h-100"
                alt="main_logo"
                width="70%"
                height="3rem"
              />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <aside
            className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-white"
            id="sidenav-main"
          >
            <hr className="horizontal w-100 dark mt-0 p-0" />
            <div
              className="collapse navbar-collapse w-auto h-auto"
              id="sidenav-collapse-main"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    to="/home"
                    className="nav-link"
                    aria-controls="pagesExamples"
                    role="button"
                    aria-expanded="false"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                      >
                        <path
                          d="M1 10.5H7C7.55 10.5 8 10.05 8 9.5V1.5C8 0.95 7.55 0.5 7 0.5H1C0.45 0.5 0 0.95 0 1.5V9.5C0 10.05 0.45 10.5 1 10.5ZM1 18.5H7C7.55 18.5 8 18.05 8 17.5V13.5C8 12.95 7.55 12.5 7 12.5H1C0.45 12.5 0 12.95 0 13.5V17.5C0 18.05 0.45 18.5 1 18.5ZM11 18.5H17C17.55 18.5 18 18.05 18 17.5V9.5C18 8.95 17.55 8.5 17 8.5H11C10.45 8.5 10 8.95 10 9.5V17.5C10 18.05 10.45 18.5 11 18.5ZM10 1.5V5.5C10 6.05 10.45 6.5 11 6.5H17C17.55 6.5 18 6.05 18 5.5V1.5C18 0.95 17.55 0.5 17 0.5H11C10.45 0.5 10 0.95 10 1.5Z"
                          fill="#7F56D9"
                        ></path>
                      </svg>
                    </div>
                    <span className="nav-link-text ms-1">Dashboard</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    data-bs-toggle="collapse"
                    to="#Management"
                    className="nav-link"
                    aria-controls="Management"
                    role="button"
                    aria-expanded="false"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                      >
                        <path
                          d="M9 13.116C10.02 13.116 11.1525 12.9138 12.3975 12.5094C13.6425 12.1051 15.51 11.3937 18 10.3753V10.0384C18 9.27462 17.6925 8.6606 17.0775 8.19634C16.4625 7.73209 15.7875 7.60479 15.0525 7.81446C14.7975 7.88934 14.5389 7.97919 14.2767 8.08402C14.0145 8.18886 13.6656 8.32364 13.23 8.48838C12.315 8.87775 11.5425 9.14732 10.9125 9.29708C10.2825 9.44684 9.645 9.52172 9 9.52172C8.325 9.52172 7.6725 9.44324 7.0425 9.2863C6.4125 9.12935 5.6175 8.85589 4.6575 8.46591C4.2975 8.31615 3.9864 8.19275 3.7242 8.09571C3.462 7.99866 3.2106 7.9124 2.97 7.83692C2.235 7.59731 1.5564 7.70963 0.9342 8.17388C0.312 8.63814 0.0006 9.25964 0 10.0384V10.3304C1.965 11.229 3.69 11.9179 5.175 12.3971C6.66 12.8763 7.935 13.116 9 13.116ZM9 18.5073C11.07 18.5073 12.8889 17.9418 14.4567 16.8108C16.0245 15.6798 17.0856 14.2311 17.64 12.4645C15.675 13.3032 14.025 13.9211 12.69 14.3182C11.355 14.7154 10.125 14.9137 9 14.9131C7.86 14.9131 6.5589 14.6998 5.0967 14.2733C3.6345 13.8468 2.0406 13.2364 0.315 12.442C0.825 14.2841 1.8525 15.7556 3.3975 16.8567C4.9425 17.9577 6.81 18.5079 9 18.5073ZM9 7.7246C8.01 7.7246 7.1625 7.37267 6.4575 6.6688C5.7525 5.96492 5.4 5.11878 5.4 4.13037C5.4 3.14195 5.7525 2.29581 6.4575 1.59194C7.1625 0.888068 8.01 0.536133 9 0.536133C9.99 0.536133 10.8375 0.888068 11.5425 1.59194C12.2475 2.29581 12.6 3.14195 12.6 4.13037C12.6 5.11878 12.2475 5.96492 11.5425 6.6688C10.8375 7.37267 9.99 7.7246 9 7.7246Z"
                          fill="#7F56D9"
                        ></path>
                      </svg>
                    </div>
                    <span className="nav-link-text ms-1">Management</span>
                  </Link>
                  <div className="collapse" id="Management">
                    <ul className="nav ms-4 ps-3">
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal"> Programs </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal"> Submissions </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/customers">
                          <span className="sidenav-normal"> Customers </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/researchers">
                          <span className="sidenav-normal"> Researchers </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/users">
                          <span className="sidenav-normal"> Sub Admin </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal">
                            Roles & Permission
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal">
                            Department & Designation
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <Link
                    data-bs-toggle="collapse"
                    to="#Payments"
                    className="nav-link"
                    aria-controls="Payments"
                    role="button"
                    aria-expanded="false"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                      >
                        <path
                          d="M2.8125 0.0319824C2.06658 0.0319824 1.35121 0.327824 0.823762 0.854425C0.296316 1.38103 0 2.09525 0 2.83998V4.30014H18V2.83998C18 2.09525 17.7037 1.38103 17.1762 0.854425C16.6488 0.327824 15.9334 0.0319824 15.1875 0.0319824H2.8125ZM0 10.7024V5.42333H18V10.7024C18 11.4471 17.7037 12.1613 17.1762 12.6879C16.6488 13.2145 15.9334 13.5104 15.1875 13.5104H2.8125C2.06658 13.5104 1.35121 13.2145 0.823762 12.6879C0.296316 12.1613 0 11.4471 0 10.7024ZM12.2625 9.24221C12.1133 9.24221 11.9702 9.30138 11.8648 9.4067C11.7593 9.51202 11.7 9.65486 11.7 9.80381C11.7 9.95275 11.7593 10.0956 11.8648 10.2009C11.9702 10.3062 12.1133 10.3654 12.2625 10.3654H14.7375C14.8867 10.3654 15.0298 10.3062 15.1352 10.2009C15.2407 10.0956 15.3 9.95275 15.3 9.80381C15.3 9.65486 15.2407 9.51202 15.1352 9.4067C15.0298 9.30138 14.8867 9.24221 14.7375 9.24221H12.2625Z"
                          fill="#7F56D9"
                        ></path>
                      </svg>
                    </div>
                    <span className="nav-link-text ms-1">Payments</span>
                  </Link>
                  <div className="collapse" id="Payments">
                    <ul className="nav ms-4 ps-3">
                      <li className="nav-item">
                        <Link className="nav-link" to="/WalletDashboard">
                          <span className="sidenav-normal"> Wallet </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/Admindashbord">
                          <span className="sidenav-normal"> Earnings </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal"> Transactions </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal"> KYC </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <Link
                    data-bs-toggle="collapse"
                    to="#Integrations"
                    className="nav-link"
                    aria-controls="Integrations"
                    role="button"
                    aria-expanded="false"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#7F56D9"
                        className="bi bi-puzzle-fill me-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.112 3.645A1.5 1.5 0 0 1 4.605 2H7a.5.5 0 0 1 .5.5v.382c0 .696-.497 1.182-.872 1.469a.5.5 0 0 0-.115.118l-.012.025L6.5 4.5v.003l.003.01q.005.015.036.053a.9.9 0 0 0 .27.194C7.09 4.9 7.51 5 8 5c.492 0 .912-.1 1.19-.24a.9.9 0 0 0 .271-.194.2.2 0 0 0 .036-.054l.003-.01v-.008l-.012-.025a.5.5 0 0 0-.115-.118c-.375-.287-.872-.773-.872-1.469V2.5A.5.5 0 0 1 9 2h2.395a1.5 1.5 0 0 1 1.493 1.645L12.645 6.5h.237c.195 0 .42-.147.675-.48.21-.274.528-.52.943-.52.568 0 .947.447 1.154.862C15.877 6.807 16 7.387 16 8s-.123 1.193-.346 1.638c-.207.415-.586.862-1.154.862-.415 0-.733-.246-.943-.52-.255-.333-.48-.48-.675-.48h-.237l.243 2.855A1.5 1.5 0 0 1 11.395 14H9a.5.5 0 0 1-.5-.5v-.382c0-.696.497-1.182.872-1.469a.5.5 0 0 0 .115-.118l.012-.025.001-.006v-.003l-.003-.01a.2.2 0 0 0-.036-.053.9.9 0 0 0-.27-.194C8.91 11.1 8.49 11 8 11s-.912.1-1.19.24a.9.9 0 0 0-.271.194.2.2 0 0 0-.036.054l-.003.01v.002l.001.006.012.025c.016.027.05.068.115.118.375.287.872.773.872 1.469v.382a.5.5 0 0 1-.5.5H4.605a1.5 1.5 0 0 1-1.493-1.645L3.356 9.5h-.238c-.195 0-.42.147-.675.48-.21.274-.528.52-.943.52-.568 0-.947-.447-1.154-.862C.123 9.193 0 8.613 0 8s.123-1.193.346-1.638C.553 5.947.932 5.5 1.5 5.5c.415 0 .733.246.943.52.255.333.48.48.675.48h.238z" />
                      </svg>
                    </div>
                    <span className="nav-link-text ms-1">Integrations</span>
                  </Link>
                  <div className="collapse" id="Integrations">
                    <ul className="nav ms-4 ps-3">
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal">
                            Slack Integration
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal">
                            Jira Integration
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal"> Twilio (SMS) </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal"> Email </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal">
                            Payment Gateway
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <Link
                    data-bs-toggle="collapse"
                    to="#Reports"
                    className="nav-link"
                    aria-controls="Reports"
                    role="button"
                    aria-expanded="false"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M14.2214 0.0319824H13.3325C12.3506 0.0319824 11.5547 0.826646 11.5547 1.80691V14.2314C11.5547 15.2117 12.3506 16.0064 13.3325 16.0064H14.2214C15.2032 16.0064 15.9991 15.2117 15.9991 14.2314V1.80691C15.9991 0.826646 15.2032 0.0319824 14.2214 0.0319824Z"
                          fill="#7F56D9"
                        ></path>
                        <path
                          d="M8.44401 5.35693H7.55512C6.57328 5.35693 5.77734 6.1516 5.77734 7.13186V14.2316C5.77734 15.2119 6.57328 16.0065 7.55512 16.0065H8.44401C9.42585 16.0065 10.2218 15.2119 10.2218 14.2316V7.13186C10.2218 6.1516 9.42585 5.35693 8.44401 5.35693Z"
                          fill="#7F56D9"
                        ></path>
                        <path
                          d="M2.66667 11.5693H1.77778C0.795938 11.5693 0 12.364 0 13.3443V14.2317C0 15.212 0.795938 16.0067 1.77778 16.0067H2.66667C3.64851 16.0067 4.44444 15.212 4.44444 14.2317V13.3443C4.44444 12.364 3.64851 11.5693 2.66667 11.5693Z"
                          fill="#7F56D9"
                        ></path>
                      </svg>
                    </div>
                    <span className="nav-link-text ms-1">Reports</span>
                  </Link>
                  <div className="collapse" id="Reports">
                    <ul className="nav ms-4 ps-3">
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal">
                            Reports Dashboard
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal">
                            Generate Reports
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <Link
                    data-bs-toggle="collapse"
                    to="#manageModule"
                    className="nav-link"
                    aria-controls="manageModule"
                    role="button"
                    aria-expanded="false"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <mask
                          id="mask0_1683_5670"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="18"
                          height="19"
                        >
                          <path
                            d="M14.0744 5.49005C14.5845 6.21955 14.9297 7.05105 15.086 7.92694H17V10.105H15.086C14.9297 10.9809 14.5845 11.8124 14.0744 12.5419L15.4284 13.8938L13.8856 15.4341L12.5316 14.0823C11.8009 14.5916 10.9681 14.9362 10.0908 15.0922V17.0032H7.9092V15.0922C7.0319 14.9362 6.19907 14.5916 5.4684 14.0823L4.1144 15.4341L2.5716 13.8938L3.9256 12.5419C3.41549 11.8124 3.07033 10.9809 2.914 10.105H1V7.92694H2.914C3.07033 7.05105 3.41549 6.21955 3.9256 5.49005L2.5716 4.13822L4.1144 2.59789L5.4684 3.94972C6.19907 3.44043 7.0319 3.09582 7.9092 2.93974V1.02881H10.0908V2.93974C10.9681 3.09582 11.8009 3.44043 12.5316 3.94972L13.8856 2.59789L15.4284 4.13822L14.0744 5.49005Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M9 11.0126C9.53043 11.0126 10.0391 10.8023 10.4142 10.4278C10.7893 10.0533 11 9.54542 11 9.01584C11 8.48626 10.7893 7.97836 10.4142 7.60389C10.0391 7.22942 9.53043 7.01904 9 7.01904C8.46957 7.01904 7.96086 7.22942 7.58579 7.60389C7.21071 7.97836 7 8.48626 7 9.01584C7 9.54542 7.21071 10.0533 7.58579 10.4278C7.96086 10.8023 8.46957 11.0126 9 11.0126Z"
                            fill="black"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinejoin="round"
                          ></path>
                        </mask>
                        <g mask="url(#mask0_1683_5670)">
                          <path
                            d="M-0.601562 -0.568604H18.5984V18.6006H-0.601562V-0.568604Z"
                            fill="#7F56D9"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <span className="nav-link-text ms-1">Manage Module</span>
                  </Link>
                  <div className="collapse" id="manageModule">
                    <ul className="nav ms-4 ps-3">
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal"> Homepage</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal"> Custom Pages </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal"> CMS Pages </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal"> Navigation </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <Link
                    data-bs-toggle="collapse"
                    to="#mastermodule"
                    className="nav-link"
                    aria-controls="mastermodule"
                    role="button"
                    aria-expanded="false"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#7F56D9"
                        className="bi bi-shield-shaded me-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 14.933a1 1 0 0 0 .1-.025q.114-.034.294-.118c.24-.113.547-.29.893-.533a10.7 10.7 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.8 11.8 0 0 1-2.517 2.453 7 7 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7 7 0 0 1-1.048-.625 11.8 11.8 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 63 63 0 0 1 5.072.56"
                        />
                      </svg>
                    </div>
                    <span className="nav-link-text ms-1">Master Module</span>
                  </Link>
                  <div className="collapse" id="mastermodule">
                    <ul className="nav ms-4 ps-3">
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal"> FAQ Category</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal"> FAQ Q & A </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/companydashboard">
                          <span className="sidenav-normal"> Company </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          <span className="sidenav-normal"> Navigation </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </aside>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
