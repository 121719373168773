import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

import useHttp from "../../Hooks/useHttp";

import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import CircularLoader from "../../CircularLoader/CircularLoader";
import AddModal from "../../common/modal/AddModal";
import CommonTable from "../../common/Table/CommonTable";
import AppContext from "../../Context/AppContext";
import "./RoleandPermissionStyle.css";
import { customToast } from "../../../App";

export default function RoleAndPermissionDashboard() {
  const [openAddModal, setOpenAddModal] = useState(false);

  const [DelId, setDelId] = useState({ mapId: "", index: "" });
  const Breadcrumbname = {
    home: "/admin/home",
    Management: "",
    "Role & Permission": "/admin/rolesandpermission",
    Dashboard: "",
  };
  const { setLoading } = useContext(AppContext);
  const [circularLoading, setCircularLoading] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const API = process.env.REACT_APP_API_URL;
  const { PostRequest, GetRequest, DeleteRequest, PatchRequest } = useHttp();
  const token = localStorage.getItem("token");

  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);

  const tableOption = [
    "role id",
    "role name",
    "permissions",
    "status",
    "action",
  ];
  const searchColumn = [
    { name: "Role Id", value: "role_id" },
    { name: "Role Name", value: "role_title" },
  ];

  // const getPermissions = async (rolesIds) => {
  // 	try {
  // 		const permissionsPromises = rolesIds.map(async (id) => {
  // 			const { data, error } = await GetRequest(
  // 				API + `permissions/?roles_id=${id}`,
  // 				{},
  // 				{
  // 					Authorization: "Bearer  " + token,
  // 				}
  // 			);
  // 			if (error) {
  // 				throw new Error(
  // 					"Failed to fetch permissions for role with ID: " + id
  // 				);
  // 			}
  // 			return data.results;
  // 		});

  // 		const permissions = await Promise.all(permissionsPromises);

  // 		return permissions;
  // 	} catch (error) {
  // 		console.error("Error fetching permissions:", error);
  // 		customToast.error("Something went wrong!", {
  // 			position: toast.POSITION.TOP_RIGHT,
  // 			autoClose: 1000,
  // 		});
  // 		return [];
  // 	}
  // };

  // const fetchRolesData = async () => {
  // 	try {
  // 		const { data, error } = await GetRequest(
  // 			API + `/roles`,
  // 			{},
  // 			{
  // 				Authorization: "Bearer  " + token,
  // 			}
  // 		);
  // 		if (error) {
  // 			throw new Error("Failed to fetch roles");
  // 		}

  // 		setRolesList(data.results);

  // 		const rolesIds = data.results.map((item) => item.id);
  // 		const permissions = await getPermissions(rolesIds);

  // 		setRolesList((prevRolesList) =>
  // 			prevRolesList.map((role, index) => ({
  // 				...role,
  // 				permissions: permissions[index],
  // 			}))
  // 		);
  // 	} catch (error) {
  // 		console.error("Error fetching roles data:", error);
  // 		customToast.error("Something went wrong!", {
  // 			position: toast.POSITION.TOP_RIGHT,
  // 			autoClose: 1000,
  // 		});
  // 	}
  // };

  const fetchRolesPermissions = async () => {
    setLoading(true);
    let url = `${API}rolepermission?page=${currentPage}&page_size=25`;
    if (searchQuery && searchQuery.length > 0) {
      url = url + `&${searchValue}=${searchQuery}`;
    }
    const { data, error } = await GetRequest(
      url,
      {},
      {
        Authorization: "Bearer  " + token,
      }
    );
    if (error) {
      setLoading(false);
    } else {
      setLoading(false);
      setRolesList(data.results);
      setTotalData(data.total_records);
      setTotalPages(data.total_pages);
      console.log("userdata", data);
    }
  };
  useEffect(() => {
    fetchRolesPermissions();
  }, [currentPage, searchValue, searchQuery]);

  const handleStatusChange = async (id, status, index) => {
    let newStatus;
    if (status == "active") {
      newStatus = "inactive";
    } else {
      newStatus = "active";
    }
    const { data, error } = await PatchRequest(
      API + `roles/${id}/`,
      { status: newStatus },
      {},
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      console.log(error);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      const arr = [...rolesList];
      arr[index].status = newStatus;
      setRolesList([...arr]);
    }
  };

  const handleDeleteRole = async (id, index) => {
    setCircularLoading(true);
    setOpenAddModal(false);
    const { data, error } = await DeleteRequest(API + `roles/${id}/`, {
      Authorization: "Bearer  " + token,
    });
    if (error) {
      setCircularLoading(false);
      console.log(error);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setCircularLoading(false);
      const arr = [...rolesList];
      arr.splice(index, 1);
      setRolesList([...arr]);
    }
  };

  useEffect(() => {
    // fetchRolesData();
    fetchRolesPermissions();
  }, []);

  return (
    <>
      {circularLoading && <CircularLoader size={100} />}
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              <Link
                to="/admin/rolesandpermission/add"
                className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                id="adduser"
              >
                Add New Role
              </Link>
            </div>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12">
            <CommonTable
              column={tableOption}
              data={rolesList}
              totalPages={totalPages ?? 1}
              totalData={totalData}
              searchBar={true}
              searchBy={searchColumn}
              itemPerPg={25}
              setTable={2}
              card={4}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            >
              {rolesList &&
                rolesList?.map((item, index) => (
                  <tr key={index}>
                    <td className="rolename">
                      <p className="text-sm  mb-0 px-2">{item?.role_id}</p>
                    </td>
                    <td className="text-start rolename">
                      <p className="text-sm  mb-0 px-2">{item?.role_title}</p>
                    </td>
                    <td style={{ width: "58%", textAlign: "left" }}>
                      <p className="text-wrap text-sm  mb-0 px-2">
                        {/* {item.Permissions} */}
                        {item && item?.permissions ? (
                          item.permissions
                            ?.filter(
                              (p) => p.view || p.create || p.edit || p.delete
                            )
                            .map((p) => p.module)
                            .join(", ")
                        ) : (
                          <Skeleton width={400} count={2} />
                        )}
                      </p>
                    </td>

                    <td
                      className="align-middle text-center cursor-pointer"
                      onClick={() => {
                        handleStatusChange(item.id, item.status, index);
                      }}
                    >
                      {item.status === "active" ? (
                        <span className="badge bg-success text-white">
                          Active
                        </span>
                      ) : (
                        <span className="badge bg-danger text-white">
                          Inactive
                        </span>
                      )}
                    </td>
                    <td className>
                      <div className="d-flex justify-content-center">
                        {/* <Link to="" className="btn btn-sm px-3 mt-2">
																	&#128065;
																</Link> */}
                        <button
                          type="button"
                          className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v text-xs"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/admin/rolesandpermission/edit/${item.id}`}
                            >
                              Edit
                            </Link>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                setOpenAddModal(true);
                                setDelId({
                                  mapId: item.id,
                                  index: index,
                                });
                              }}
                            >
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
            </CommonTable>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"DELETE"}
        createAddTopic={"Delete Role & Permission"}
        confirmText={"Delete The Role & Permission"}
        isOpen={openAddModal}
        onSubmit={() => handleDeleteRole(DelId.mapId, DelId.index)}
        onClose={() => setOpenAddModal(false)}
      />
    </>
  );
}
