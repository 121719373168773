import React, { useEffect, useState } from "react";
import useHttp from "../../../Hooks/useHttp";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import ViewInfo from "../../../common/ViewInfo";
import HTTPService from "../../../../utils/axios";
import { customToast } from "../../../../App";

export default function ViewCMS() {
  const { GetRequest } = useHttp();
  const [CMSData, setCMSData] = useState({});

  const { invokeApi } = HTTPService();
  const API = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const { id } = useParams();

  const fetchCMS = async () => {
    invokeApi({
      method: "GET",
      url: `cms_pages/${id}/`,
    })
      .then((res) => {
        setCMSData(res);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });

    // const { data, error } = await GetRequest(
    //     API + "cms_pages/" + id + "/",
    //     {},
    //     {
    //         Authorization: `Bearer ${token}`,
    //     }
    // );
    // // console.log(data, error);
    // if (error) {
    //     //alert("Something went wrong");
    //     customToast.error("Something went wrong!", {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 1000,
    //     });
    //     console.log(error);
    // }
    // if (data) {
    //     setCMSData(data);
    //     console.log("CMSData -- ", data);
    // }
  };

  useEffect(() => {
    fetchCMS();
  }, [id]);

  const Title = "View CMS Details";
  const option = [
    "PAGE ID",
    "PAGE TITLE",
    "PAGE CONTENT",
    "META DESCRIPTION",
    "STATUS",
  ];

  const CMSValue = [
    CMSData.cms_page_id,
    CMSData.meta_title,
    CMSData.page_content,
    CMSData.meta_description,
    CMSData.cms_status,
  ];

  const Breadcrumbname = {
    home: "/admin/home",
    MasterModule: "",
    fetchCMS: "/admin/master-module",
    "faq-sub-category": "",
  };

  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row w-100 d-flex justify-content-center">
          <div className="addCategoryArea  my-4">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="col-lg-7 ps-3">
                <Breadcrumb names={Breadcrumbname} />
              </div>

              <div className="button-row d-flex justify-content-end mt-0 mb-3">
                <Link
                  to="/admin/cms-pages"
                  className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                  id="adduser"
                >
                  VIEW ALL
                </Link>
              </div>
            </div>

            <div className="col-12">
              <div className="card" style={{ backgroundColor: "white" }}>
                <ViewInfo itemValue={CMSValue} option={option} title={Title} />

                <div className=" d-flex justify-content-end p-4">
                  <button
                    className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
                    onClick={() => navigate(`/admin/cms-page/edit/${id}`)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
