import React, { useContext, useEffect, useState } from "react";

import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import useHttp from "../../Hooks/useHttp";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import "./RoleandPermissionStyle.css";
import { toast } from "react-toastify";
import AddModal from "../../common/modal/AddModal";
import AppContext from "../../Context/AppContext";
import Skeleton from "react-loading-skeleton";
import CircularLoader from "../../CircularLoader/CircularLoader";
import { getUserInfo } from "../../../reducers/authSlice";
import { useSelector } from "react-redux";
import { customToast } from "../../../App";

const permissionLabels1 = [
	"Programs",
	"Submissions",
	"Customers",
	"Researchers",
	"Sub-Admin",
	"Wallet",
	"Earnings & Transactions",
	"Badges",
];
const permissionLabels2 = [
	"Integrations",
	"Reports",
	"CMS Pages",
	"Navigation",
	"Base Values",
	"FAQ Category",
	"FAQ Q&A",
	"Company",
];

export default function AddNewRoleAndPermission() {
	const userInfo = useSelector(getUserInfo);
	const [errors, setErrors] = useState({});
	const { loading, setLoading } = useContext(AppContext);
	const [circularLoading, setCircularLoading] = useState(false);
	const [openAddModal, setOpenAddModal] = useState(false);
	const location = useLocation();

	const [inpValue, setInpValue] = useState();
	const [viewPermissions, setViewPermissions] = useState({});
	const [createPermissions, setCreatePermissions] = useState({});
	const [editPermissions, setEditPermissions] = useState({});
	const [deletePermissions, setDeletePermissions] = useState({});
	const [allPermission, setAllPermission] = useState({});
	const [permissionIds, setPermissionIds] = useState({});
	const [roleStatus, setRoleStatus] = useState("active");

	const navigate = useNavigate();
	const { PostRequest, GetRequest, PutRequest, PatchRequest } = useHttp();
	const token = localStorage.getItem("token");
	const API = process.env.REACT_APP_API_URL;
	let { id } = useParams();

	const getRoleDataAndPermissions = async (id) => {
		setLoading(true);
		const { data, error } = await GetRequest(
			API + `/rolepermission/${id}`,
			{},
			{
				Authorization: "Bearer " + token,
			}
		);
		if (error) {
			setLoading(false);
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {
			setInpValue(data.role_title);
			setRoleStatus(data.status);

			data.permissions.forEach((permission) => {
				if (permission.view) {
					setViewPermissions((prevPermissions) => ({
						...prevPermissions,
						[permission.module]: permission.view,
					}));
				}
				if (permission.create) {
					setCreatePermissions((prevPermissions) => ({
						...prevPermissions,
						[permission.module]: permission.create,
					}));
				}
				if (permission.delete) {
					setDeletePermissions((prevPermissions) => ({
						...prevPermissions,
						[permission.module]: permission.delete,
					}));
				}
				if (permission.edit) {
					setEditPermissions((prevPermissions) => ({
						...prevPermissions,
						[permission.module]: permission.edit,
					}));
				}
				setPermissionIds((prevPermissions) => ({
					...prevPermissions,
					[permission.module]: permission.id,
				}));
			});

			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			getRoleDataAndPermissions(id);
		}
	}, [id]);

	// const getRoleData = async () => {
	//   const { data, error } = await GetRequest(
	//     API + `/roles/${id}/`,
	//     {},
	//     {
	//       Authorization: "Bearer  " + token,
	//     }
	//   );
	//   if (error) {
	//     customToast.error("Something went wrong!", {
	//       position: toast.POSITION.TOP_RIGHT,
	//       autoClose: 1000,
	//     });
	//   } else {
	//     setInpValue(data.role_title);
	//     setRoleStatus(data.status);
	//     getPermissions(data.id);
	//   }
	// };

	// const getPermissions = async (id) => {
	//   setLoading(true);
	//   const { data, error } = await GetRequest(
	//     API + `permissions/?roles_id=${id}`,
	//     {},
	//     {
	//       Authorization: "Bearer  " + token,
	//     }
	//   );
	//   if (error) {
	//     setLoading(false);
	//     customToast.error("Something went wrong!", {
	//       position: toast.POSITION.TOP_RIGHT,
	//       autoClose: 1000,
	//     });
	//   } else {
	//     setLoading(false);
	//     data.results.forEach((permission) => {
	//       if (permission.view) {
	//         setViewPermissions((prevPermissions) => ({
	//           ...prevPermissions,
	//           [permission.module]: permission.view,
	//         }));
	//       }
	//       if (permission.create) {
	//         setCreatePermissions((prevPermissions) => ({
	//           ...prevPermissions,
	//           [permission.module]: permission.create,
	//         }));
	//       }
	//       if (permission.delete) {
	//         setDeletePermissions((prevPermissions) => ({
	//           ...prevPermissions,
	//           [permission.module]: permission.delete,
	//         }));
	//       }
	//       if (permission.edit) {
	//         setEditPermissions((prevPermissions) => ({
	//           ...prevPermissions,
	//           [permission.module]: permission.edit,
	//         }));
	//       }
	//       setPermissionIds((prevPermissions) => ({
	//         ...prevPermissions,
	//         [permission.module]: permission.id,
	//       }));
	//     });
	//   }
	// };

	const Breadcrumbname = {
		home: "/admin/home",
		Managment: "",
		"Role & Permission": "/admin/rolesandpermission",
		"Add New Role": "",
	};

	const createRoleWithPermissions = async () => {
		const permissionsList = [...permissionLabels1, ...permissionLabels2];
		let permissionsData = [];
		if (id) {
			permissionsData = permissionsList.map((permission) => ({
				module: permission,
				view: Boolean(viewPermissions[permission]),
				create: Boolean(createPermissions[permission]),
				edit: Boolean(editPermissions[permission]),
				delete: Boolean(deletePermissions[permission]),
				roles_id: id,
			}));
		} else {
			permissionsData = permissionsList.map((permission) => ({
				module: permission,
				view: Boolean(viewPermissions[permission]),
				create: Boolean(createPermissions[permission]),
				edit: Boolean(editPermissions[permission]),
				delete: Boolean(deletePermissions[permission]),
			}));
		}

		const payload = {
			role_title: inpValue,
			status: roleStatus,
			permissions: permissionsData,
			user: userInfo.userAccData.id,
		};

		try {
			setCircularLoading(true);
			setOpenAddModal(false);

			const { data, error } = id
				? await PutRequest(API + `rolepermission/${id}`, payload, {
					Authorization: "Bearer  " + token,
					// "Content-Type": "multipart/form-data",
				})
				: await PostRequest(API + `rolepermission`, payload, {
					Authorization: "Bearer  " + token,
					// "Content-Type": "multipart/form-data",
				});

			if (error) {
				setCircularLoading(false);
				setErrors({ ...error.data });
				throw new Error("Something went wrong!");
			}

			customToast.success("Role added successfully", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
			navigate("/admin/rolesandpermission");
		} catch (error) {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		}
	};
	// const createRole = async () => {
	// 	const payload = {
	// 		role_title: inpValue,
	// 		status: roleStatus,
	// 	};

	// 	if (id) {
	// 		try {
	// 			setCircularLoading(true);
	// 			setOpenAddModal(false);
	// 			const { data, error } = await PutRequest(
	// 				API + `roles/${id}/`,
	// 				payload,
	// 				{
	// 					Authorization: "Bearer  " + token,
	// 					"Content-Type": "multipart/form-data",
	// 				}
	// 			);

	// 			if (error) {
	// 				setCircularLoading(false);
	// 				throw new Error("Something went wrong!");
	// 			}
	// 			await postPermissions(data.id);
	// 		} catch (error) {
	// 			customToast.error("Something went wrong!", {
	// 				position: toast.POSITION.TOP_RIGHT,
	// 				autoClose: 1000,
	// 			});
	// 		}
	// 	} else {
	// 		try {
	// 			setCircularLoading(true);
	// 			setOpenAddModal(false);
	// 			const { data, error } = await PostRequest(API + `roles/`, payload, {
	// 				Authorization: "Bearer  " + token,
	// 				"Content-Type": "multipart/form-data",
	// 			});

	// 			if (error) {
	// 				setErrors({ ...error.data });
	// 				setCircularLoading(false);
	// 				throw new Error("Something went wrong!");
	// 			}
	// 			await postPermissions(data.id);
	// 		} catch (error) {
	// 			customToast.error("Something went wrong!", {
	// 				position: toast.POSITION.TOP_RIGHT,
	// 				autoClose: 1000,
	// 			});
	// 		}
	// 	}
	// };

	// const postPermissions = async (id) => {
	// 	const permissionsList = [...permissionLabels1, ...permissionLabels2];
	// 	const promises = permissionsList.map((permission) => {
	// 		const data = {
	// 			module: permission,
	// 			view: Boolean(viewPermissions[permission]),
	// 			create: Boolean(createPermissions[permission]),
	// 			edit: Boolean(editPermissions[permission]),
	// 			delete: Boolean(deletePermissions[permission]),
	// 			roles_id: id,
	// 		};
	// 		if (permissionIds.hasOwnProperty(permission)) {
	// 			return PutRequest(
	// 				API + `permissions/${permissionIds[permission]}/`,
	// 				data,
	// 				{
	// 					Authorization: "Bearer  " + token,
	// 					"Content-Type": "multipart/form-data",
	// 				}
	// 			);
	// 		} else {
	// 			return PostRequest(API + "permissions/", data, {
	// 				Authorization: "Bearer  " + token,
	// 				"Content-Type": "multipart/form-data",
	// 			});
	// 		}
	// 	});

	// 	try {
	// 		await Promise.all(promises);
	// 		customToast.success("Role added successfully", {
	// 			position: toast.POSITION.TOP_RIGHT,
	// 			autoClose: 1000,
	// 		});
	// 		navigate("/admin/rolesandpermission");
	// 	} catch (error) {
	// 		customToast.error("Something went wrong! One or more requests failed.", {
	// 			position: toast.POSITION.TOP_RIGHT,
	// 			autoClose: 1000,
	// 		});
	// 	}
	// };

	return (
		<>
			{circularLoading && <CircularLoader size={100} />}
			<div className="container-fluid py-4 pb-0">
				<div className="row">
					<div className="col-lg-6 ps-3">
						<Breadcrumb names={Breadcrumbname} />
					</div>
				</div>

				<div className="row w-100 d-flex justify-content-center">
					<div className="  my-4">
						<div className="">
							<div className="button-row d-flex justify-content-end mt-0 mb-3">
								<Link
									to="/admin/rolesandpermission"
									className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
									id="adduser"
								>
									VIEW ALL
								</Link>
							</div>
						</div>
						<div className="col-12">
							<div className="card">
								<div className="row">
									<div className="card mt-0" id="basic-info">
										<div className="card-header">
											<h5>ADD New Role</h5>
										</div>
										<div className="roleDiv card-body pt-0">
											<div className="row d-flex justify-content-center">
												<div className="col-10 d-flex align-items-center">
													<div className="col-2">
														<label className="roleFormLable ">
															New Role Title*:
														</label>
													</div>
													{loading ? (
														<Skeleton width={"100%"} height={40} />
													) : (
														<div className="col-10">
															<input
																className="form-control w-100"
																placeholder="Add New Role Title"
																type="text"
																value={inpValue}
																onChange={(e) => setInpValue(e.target.value)}
															/>
														</div>
													)}
												</div>
												<div className="col-10" style={{ height: "16px" }}>
													<div className="col-9 ms-9">
														<div>
															{(inpValue === null ||
																inpValue === undefined ||
																inpValue === "") && (
																	<p
																		style={{
																			color: "red",
																			marginBottom: "0px",
																			fontSize: "14px",
																		}}
																	>
																		{errors.role_title}
																	</p>
																)}
														</div>
													</div>
												</div>
											</div>

											<div className="row permissionHead">
												<hr />
												<label className="text-center roleFormLable ">
													Permissions
												</label>
												<hr />
											</div>

											<div className="row d-flex justify-content-between">
												<div className="col-5">
													<table className="permissionTable">
														<thead>
															<tr>
																<th style={{ width: "38%" }}></th>
																<th>View</th>
																<th>Create</th>
																<th>Edit</th>
																<th>Delete</th>
																<th>All</th>
															</tr>
														</thead>
														<tbody>
															{permissionLabels1.map((label, index) => (
																<tr key={index} >
																	<td className="text-start">{label}</td>
																	<td>
																		<div className="d-flex justify-content-center">
																			<div className="form-check form-switch mb-0">
																				<input
																					className="form-check-input"
																					type="checkbox"
																					id="flexSwitchCheckDefault0"
																					checked={viewPermissions[label]}
																					onChange={(event) => {
																						setViewPermissions({
																							...viewPermissions,
																							[label]: event.target.checked,
																						});
																					}}
																				/>
																			</div>
																		</div>
																	</td>
																	<td>
																		<div className="d-flex justify-content-center">
																			<div className="form-check form-switch mb-0">
																				<input
																					className="form-check-input"
																					type="checkbox"
																					id="flexSwitchCheckDefault0"
																					checked={createPermissions[label]}
																					onChange={(event) => {
																						setCreatePermissions({
																							...createPermissions,
																							[label]: event.target.checked,
																						});
																					}}
																				/>
																			</div>
																		</div>
																	</td>
																	<td>
																		<div className="d-flex justify-content-center">
																			<div className="form-check form-switch mb-0">
																				<input
																					className="form-check-input"
																					type="checkbox"
																					id="flexSwitchCheckDefault0"
																					checked={editPermissions[label]}
																					onChange={(event) => {
																						setEditPermissions({
																							...editPermissions,
																							[label]: event.target.checked,
																						});
																					}}
																				/>
																			</div>
																		</div>
																	</td>
																	<td>
																		<div className="d-flex justify-content-center">
																			<div className="form-check form-switch mb-0">
																				<input
																					className="form-check-input"
																					type="checkbox"
																					id="flexSwitchCheckDefault0"
																					checked={deletePermissions[label]}
																					onChange={(event) => {
																						setDeletePermissions({
																							...deletePermissions,
																							[label]: event.target.checked,
																						});
																					}}
																				/>
																			</div>
																		</div>
																	</td>
																	<td>
																		<div className="d-flex justify-content-center">
																			<div className="form-check form-switch mb-0">
																				<input
																					className="form-check-input"
																					type="checkbox"
																					id="flexSwitchCheckDefault0"
																					checked={allPermission[label]}
																					onChange={(event) => {
																						setAllPermission({
																							...allPermission,
																							[label]: event.target.checked,
																						});
																						if (allPermission[label] === true) {
																							setViewPermissions({
																								...viewPermissions,
																								[label]: false,
																							});
																							setCreatePermissions({
																								...createPermissions,
																								[label]: false,
																							});
																							setEditPermissions({
																								...editPermissions,
																								[label]: false,
																							});
																							setDeletePermissions({
																								...deletePermissions,
																								[label]: false,
																							});
																						} else {
																							setViewPermissions({
																								...viewPermissions,
																								[label]: true,
																							});
																							setCreatePermissions({
																								...createPermissions,
																								[label]: true,
																							});
																							setEditPermissions({
																								...editPermissions,
																								[label]: true,
																							});
																							setDeletePermissions({
																								...deletePermissions,
																								[label]: true,
																							});
																						}
																					}}
																				/>
																			</div>
																		</div>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
												<div className="col-5">
													<table className="permissionTable">
														<thead>
															<tr>
																<th style={{ width: "38%" }}></th>
																<th>View</th>
																<th>Create</th>
																<th>Edit</th>
																<th>Delete</th>
																<th>All</th>
															</tr>
														</thead>
														<tbody>
															{permissionLabels2.map((label, index) => (
																<tr key={index} >
																	<td className="text-start">{label}</td>
																	<td>
																		<div className="d-flex justify-content-center">
																			<div className="form-check form-switch mb-0">
																				<input
																					className="form-check-input"
																					type="checkbox"
																					id="flexSwitchCheckDefault0"
																					checked={viewPermissions[label]}
																					onChange={(event) => {
																						setViewPermissions({
																							...viewPermissions,
																							[label]: event.target.checked,
																						});
																					}}
																				/>
																			</div>
																		</div>
																	</td>
																	<td>
																		<div className="d-flex justify-content-center">
																			<div className="form-check form-switch mb-0">
																				<input
																					className="form-check-input"
																					type="checkbox"
																					id="flexSwitchCheckDefault0"
																					checked={createPermissions[label]}
																					onChange={(event) => {
																						setCreatePermissions({
																							...createPermissions,
																							[label]: event.target.checked,
																						});
																					}}
																				/>
																			</div>
																		</div>
																	</td>
																	<td>
																		<div className="d-flex justify-content-center">
																			<div className="form-check form-switch mb-0">
																				<input
																					className="form-check-input"
																					type="checkbox"
																					id="flexSwitchCheckDefault0"
																					checked={editPermissions[label]}
																					onChange={(event) => {
																						setEditPermissions({
																							...editPermissions,
																							[label]: event.target.checked,
																						});
																					}}
																				/>
																			</div>
																		</div>
																	</td>
																	<td>
																		<div className="d-flex justify-content-center">
																			<div className="form-check form-switch mb-0">
																				<input
																					className="form-check-input"
																					type="checkbox"
																					id="flexSwitchCheckDefault0"
																					checked={deletePermissions[label]}
																					onChange={(event) => {
																						setDeletePermissions({
																							...deletePermissions,
																							[label]: event.target.checked,
																						});
																					}}
																				/>
																			</div>
																		</div>
																	</td>
																	<td>
																		<div className="d-flex justify-content-center">
																			<div className="form-check form-switch mb-0">
																				<input
																					className="form-check-input"
																					type="checkbox"
																					id="flexSwitchCheckDefault0"
																					checked={allPermission[label]}
																					onChange={(event) => {
																						setAllPermission({
																							...allPermission,
																							[label]: event.target.checked,
																						});
																						if (allPermission[label] === true) {
																							setViewPermissions({
																								...viewPermissions,
																								[label]: false,
																							});
																							setCreatePermissions({
																								...createPermissions,
																								[label]: false,
																							});
																							setEditPermissions({
																								...editPermissions,
																								[label]: false,
																							});
																							setDeletePermissions({
																								...deletePermissions,
																								[label]: false,
																							});
																						} else {
																							setViewPermissions({
																								...viewPermissions,
																								[label]: true,
																							});
																							setCreatePermissions({
																								...createPermissions,
																								[label]: true,
																							});
																							setEditPermissions({
																								...editPermissions,
																								[label]: true,
																							});
																							setDeletePermissions({
																								...deletePermissions,
																								[label]: true,
																							});
																						}
																					}}
																				/>
																			</div>
																		</div>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											</div>

											<div className=" d-flex justify-content-end">
												<button
													className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
													onClick={() => {
														navigate("/admin/rolesandpermission");
													}}
												>
													Cancel
												</button>
												<button
													className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
													onClick={() => setOpenAddModal(true)}
												>
													Save
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AddModal
				cancelBtn={"Cancel"}
				submitBtn={"SUBMIT"}
				createAddTopic={
					location.pathname === "/admin/rolesandpermission/add"
						? "Add Role"
						: "Edit Role"
				}
				confirmText={
					location.pathname === "/admin/rolesandpermission/add"
						? "Add The Role"
						: "Edit The Role"
				}
				isOpen={openAddModal}
				onSubmit={createRoleWithPermissions}
				onClose={() => setOpenAddModal(false)}
			/>
		</>
	);
}
