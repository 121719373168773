import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDate, lineOverFlowDot } from "../../../utils/helperFunctions";
import HTTPService from "../../../utils/axios";
import AddModal from "../modal/AddModal";
import CommonTable from "../Table/CommonTable";
import CountSkeletonLoader from "../../components/SkeletonLoader/CountSkeletonLoader";
import AppContext from "../../Context/AppContext";
import useHttp from "../../Hooks/useHttp";
import ProfileImage from "../../../components/ProfileImage";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../reducers/authSlice";
import withPermission from "../../../hoc/withPermission";
import { customToast } from "../../../App";

const tableColumn = [
    "Customer ID",
    "User Name",
    "Full Name",
    "Entity Name",
    "Email",
    "Phone",
    "Registred At",
    "Status",
    "Action",
];

const searchColumn = [
    { name: "User Id", value: "user_id" },
    { name: "User Name", value: "username" },
    { name: "Name", value: "first_name" },
    { name: "Email", value: "email" },
    { name: "Phone", value: "phone_number" },
];

const filterColumn = [
    {
        name: "Status",
        value: "user_status",
        filters: [{ value: "pending", label: "Pending" },
        { value: "active", label: "Active" },
        { value: "in_active", label: "Inactive" },
        { value: "hold", label: "Hold" },
        { value: "blocked", label: "Blocked" },],
    },
];

function SubCustomerDashboard() {
    const { setLoading } = useContext(AppContext);
    const { userInfo, permissions } = useSelector(getUserInfo);
    const permission = permissions.find((perm) => perm.module === "Support Customer");
    const [refresh, setRefresh] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [supportcustomers, setSupportCustomers] = useState();
    const [totalPages, setTotalPages] = useState(1);
    const [filterType, setFilterType] = useState();
    const [filterValue, setFilterValue] = useState();
    const [openAddModal, setOpenAddModal] = useState(false);
    const [DelId, setDelId] = useState();
    const API = process.env.REACT_APP_API_URL;
    const { loading, setCircularLoading } = useContext(AppContext);
    const { GetRequest, DeleteRequest, PutRequest } = useHttp();
    const token = localStorage.getItem("token");
    const [userData, setUserData] = useState({});
    const [changeStatusModal, setChangeStatusModal] = useState(false);
    const [changeStatusVal, setChangeStatusVal] = useState();
    const [getId, setGetId] = useState("");
    const [count, setCount] = useState();
    const { invokeApi } = HTTPService();
    const navigate = useNavigate();

    const fetchSupportCustomers = () => {
        let url = `/customer/support/?page=${currentPage}&&page_size=25`;
        if (searchQuery && searchQuery.length > 0) {
            url = url + `&&${searchValue}=${searchQuery}`;
        }
        if (filterType && filterValue && filterValue.length > 0) {
            url = url + `&${filterType}=${filterValue}`;
        }
        if (userInfo?.role && userInfo?.role !== 'admin') {
            url = url + `&parent_user=${userInfo?.user_data?.id}`;
        }
        setLoading(true);
        invokeApi({
            method: "GET",
            url,
        }).then((data) => {
            setLoading(false);

            setSupportCustomers(data);
            setTotalPages(data.total_pages);
        }).catch((error) => {
            setLoading(false);

            customToast.error("Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        });
    };

    useEffect(() => {
        if (userInfo?.user_data?.id)
            fetchSupportCustomers();
    }, [userInfo?.user_data?.id, searchQuery, currentPage, filterValue]);


    const fetchStatus = async (stsId) => {
        console.log(stsId)
        const { data, error } = await GetRequest(
            API + "/customer/" + stsId.id + "/",
            {},
            {
                Authorization: "Bearer  " + token,
            }
        );
        if (error) {
            customToast.error("Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
            console.log(error);
        }
        if (data) {
            setChangeStatusVal(data && data.user_status);
        }
    };

    const handleStatus = async (data) => {
        fetchStatus(data);
        setChangeStatusModal(true);
        setGetId(data.id);
    };
    const setStatus = async () => {
        setChangeStatusModal(false);
        setCircularLoading(true);
        const id = getId;
        const params = {
            id: id,
            user_status: changeStatusVal,
        };
        invokeApi({
            method: "PATCH",
            url: `/customer/${id}/`,
            data: params
        }).then((data) => {
            setCircularLoading(false);
            setRefresh(!refresh);
            setSupportCustomers(prev => ({
                ...prev,
                results: prev.results.map(res => {
                    if (res.id === id) {
                        return {
                            ...res,
                            ...params
                        }
                    }
                    return res
                })
            }))
            customToast.success("Status updated", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        }).catch((error) => {
            setCircularLoading(false);
            console.log(error);
            customToast.error("Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        });
    };

    const deleteUser = async (id) => {
        setOpenAddModal(false);
        setCircularLoading(true);
        const { error } = await DeleteRequest(
            API + "/customer/" + id + "/",

            {
                Authorization: "Bearer  " + token,
                "Content-Type": "multipart/form-data",
            }
        );
        if (error) {
            setCircularLoading(false);
            customToast.error("Something went worng!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        } else {
            setCircularLoading(false);
            setRefresh(!refresh);
            customToast.success("Deleted Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        }
    };

    const fetchCount = async () => {
        const { data, error } = await GetRequest(
            API + "/customer/support/status_count/",
            {},
            {
                Authorization: "Bearer  " + token,
                "Content-Type": "multipart/form-data",
            }
        );
        if (error) {
            customToast.error("Something went worng!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        } else {
            setCount(data);
            //
        }
    };
    useEffect(() => {
        // fetchCount();
        // GetUsers();
    }, []);
    const BreadcrumbData = {
        Home: "/admin/home",
        Management: "/admin/users",
        'Support Customer': "/admin/supportcustomers",
    };
    return (
        <div style={{ position: "relative" }}>
            <div className="container-fluid py-4 pb-0">
                <div className="row">
                    <div className="col-lg-6 ps-3">
                        <Breadcrumb names={BreadcrumbData} />
                    </div>
                    <div className="col-lg-6">
                        <div className="button-row d-flex justify-content-end mt-0 mb-3">
                            <Link
                                to={`/${userInfo.role}/supportcustomers/add`}
                                className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                                id="adduser"
                            >
                                Add Sub Customer
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    {loading ? (
                        <CountSkeletonLoader showState={1} />
                    ) : (
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="card">
                                        <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                                        <div className="card-body p-3 position-relative">
                                            <div className="row">
                                                <div className="col-8 text-start">
                                                    <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                                                        <i
                                                            className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <h5 className="text-white font-weight-bolder mb-0 mt-3">
                                                        {count &&
                                                            count.total_user_counts &&
                                                            count.total_user_counts.active}
                                                    </h5>
                                                    <span className="text-white text-sm">
                                                        Active Users
                                                    </span>
                                                </div>
                                                <div className="col-4">
                                                    <div className="dropdown text-end mb-5"></div>
                                                    <h5 className="text-end text-white pt-3 mb-0 mt-2">
                                                        {count &&
                                                            count.today_user_counts &&
                                                            count.today_user_counts.active}
                                                    </h5>
                                                    <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                                                        Today's
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mt-4 mt-md-0">
                                    <div className="card">
                                        <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                                        <div className="card-body p-3 position-relative">
                                            <div className="row">
                                                <div className="col-8 text-start">
                                                    <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                                                        <i
                                                            className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <h5 className="text-white font-weight-bolder mb-0 mt-3">
                                                        {count &&
                                                            count.total_user_counts &&
                                                            count.total_user_counts.in_active}
                                                    </h5>
                                                    <span className="text-white text-sm">
                                                        In-Actives Users
                                                    </span>
                                                </div>
                                                <div className="col-4">
                                                    <div className="dropdown text-end mb-5"></div>
                                                    <h5 className="text-end text-white pt-3 mb-0 mt-2">
                                                        {count &&
                                                            count.today_user_counts &&
                                                            count.today_user_counts.in_active}
                                                    </h5>
                                                    <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                                                        Today's
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mt-4 mt-md-0">
                                    <div className="card">
                                        <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                                        <div className="card-body p-3 position-relative">
                                            <div className="row">
                                                <div className="col-8 text-start">
                                                    <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                                                        <i
                                                            className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <h5 className="text-white font-weight-bolder mb-0 mt-3">
                                                        {count &&
                                                            count.total_user_counts &&
                                                            count.total_user_counts.blocked}
                                                    </h5>
                                                    <span className="text-white text-sm">
                                                        Blocked Users
                                                    </span>
                                                </div>
                                                <div className="col-4">
                                                    <div className="dropdown text-end mb-5"></div>
                                                    <h5 className="text-end text-white pt-3 mb-0 mt-2">
                                                        {count &&
                                                            count.today_user_counts &&
                                                            count.today_user_counts.blocked}
                                                    </h5>
                                                    <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                                                        Today's
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mt-4 mt-md-0">
                                    <div className="card">
                                        <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                                        <div className="card-body p-3 position-relative">
                                            <div className="row">
                                                <div className="col-8 text-start">
                                                    <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                                                        <i
                                                            className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <h5 className="text-white font-weight-bolder mb-0 mt-3">
                                                        {count &&
                                                            count.total_user_counts &&
                                                            count.total_user_counts.pending}
                                                    </h5>
                                                    <span className="text-white text-sm">
                                                        Pending Approveals
                                                    </span>
                                                </div>
                                                <div className="col-4">
                                                    <div className="dropdown text-end mb-5"></div>
                                                    <h5 className="text-end text-white pt-3 mb-0 mt-2">
                                                        {count &&
                                                            count.today_user_counts &&
                                                            count.today_user_counts.pending}
                                                    </h5>
                                                    <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                                                        Today's
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div> */}

                <div className="row my-4">
                    <div className="col-12">
                        <CommonTable
                            column={tableColumn}
                            data={supportcustomers?.results}
                            totalPages={totalPages ?? 1}
                            totalData={supportcustomers?.total_records}
                            filterList={filterColumn}
                            filterType={filterType}
                            setFilterType={setFilterType}
                            filterValue={filterValue}
                            setFilterValue={setFilterValue}
                            searchBar={true}
                            searchBy={searchColumn}
                            itemPerPg={25}
                            setTable={1}
                            card={7}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            searchPlaceHolder={searchPlaceHolder}
                            setSearchPlaceHolder={setSearchPlaceHolder}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        >
                            {supportcustomers?.results &&
                                supportcustomers?.results.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <p
                                                className="text-sm  cursor-pointer mb-0 px-2"
                                                onClick={() => {
                                                    navigate(`/${userInfo.role}/customer/view/${item.id}`);
                                                }}
                                                style={{ color: "#5A78F0" }}
                                            >
                                                {item.user_id}
                                            </p>
                                        </td>
                                        <td className="text-start">
                                            <p
                                                className="text-sm  cursor-pointer mb-0 px-2"
                                                onClick={() => {
                                                    navigate(`/${userInfo.role}/customer/view/${item.id}`);
                                                }}
                                                style={{ color: "#5A78F0" }}
                                            >
                                                {item.username}
                                            </p>
                                        </td>
                                        <td
                                            className="cursor-pointer text-start"
                                            onClick={() => {
                                                navigate(`/${userInfo.role}/customer/view/${item.id}`);
                                            }}
                                        >
                                            <div className="d-flex px-2 py-1">
                                                <div
                                                    style={{
                                                        height: "36px",
                                                        width: "36px",
                                                        marginRight: "10px",
                                                    }}
                                                >
                                                    <ProfileImage
                                                        alt="avatar"
                                                        src={item.display_pic}
                                                        className="avatar avatar-sm "
                                                    />
                                                </div>
                                                <div className="d-flex flex-column justify-content-center">
                                                    <h6
                                                        className="mb-0 text-sm"
                                                        style={{ color: "#5A78F0" }}
                                                    >
                                                        {item.first_name} {item.last_name}
                                                    </h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-start">
                                            <p className="text-sm  mb-0">{item.entity_name}</p>
                                        </td>
                                        <td className="text-start">
                                            <p className="text-sm  mb-0">{item.email}</p>
                                        </td>
                                        <td className="align-middle text-start text-sm">
                                            <p className="text-sm  mb-0">{item.phone_number}</p>
                                        </td>
                                        <td className="align-middle text-start">
                                            <p className="text-sm  mb-0">
                                                {formatDate(item.created_at, 1)}
                                            </p>
                                        </td>
                                        <td className="align-middle text-center">
                                            {item.user_status === "active" ? (
                                                <button
                                                    onClick={() => handleStatus(item)}
                                                    className="badge bg-success text-white"
                                                >
                                                    Active
                                                </button>
                                            ) : item.user_status === "in_active" ? (
                                                <button
                                                    onClick={() => handleStatus(item)}
                                                    className="badge bg-danger text-white"
                                                >
                                                    Inactive
                                                </button>
                                            ) : item.user_status === "hold" ? (
                                                <button
                                                    onClick={() => handleStatus(item)}
                                                    className="badge bg-danger text-white"
                                                >
                                                    Hold
                                                </button>
                                            ) : item.user_status === "pending" ? (
                                                <button
                                                    onClick={() => handleStatus(item)}
                                                    className="badge bg-danger text-white"
                                                >
                                                    Pending
                                                </button>
                                            ) : item.user_status === "blocked" ? (
                                                <button
                                                    onClick={() => handleStatus(item)}
                                                    className="badge bg-danger text-white"
                                                >
                                                    Blocked
                                                </button>
                                            ) : (item.user_status === null) | undefined ? (
                                                <button
                                                    onClick={() => handleStatus(item)}
                                                    className="badge bg-danger text-white"
                                                >
                                                    null
                                                </button>
                                            ) : (
                                                ""
                                            )}
                                        </td>
                                        <td>
                                            <div className="d-flex">
                                                <Link
                                                    to={`/${userInfo.role}/supportcustomers/view/${item.id}`}
                                                    className="btn btn-sm px-3 mt-2"
                                                >
                                                    &#128065;
                                                </Link>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <i
                                                        className="fa fa-ellipsis-v text-xs"
                                                        aria-hidden="true"
                                                    ></i>
                                                </button>
                                                <ul
                                                    className="dropdown-menu shadow"
                                                    style={{ zIndex: 100 }}
                                                >
                                                    {(permission?.edit || ["admin", "customer"].includes(userInfo.role)) && (
                                                        <li>
                                                            <Link
                                                                className="dropdown-item"
                                                                to={`/${userInfo.role}/supportcustomers/edit/${item.id}`}
                                                            >
                                                                Edit
                                                            </Link>
                                                        </li>
                                                    )}
                                                    {permission?.delete ||
                                                        (["admin", "customer"].includes(userInfo.role) && (
                                                            <li>
                                                                <button
                                                                    onClick={() => {
                                                                        setOpenAddModal(true);
                                                                        setDelId(item.id);
                                                                    }}
                                                                    className="dropdown-item"
                                                                >
                                                                    Delete
                                                                </button>
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </CommonTable>
                    </div>
                </div>
            </div>
            <AddModal
                cancelBtn={"Cancel"}
                submitBtn={"SUBMIT"}
                createAddTopic={"Delete Sub-Admin"}
                confirmText={"Delete The Sub-Admin"}
                isOpen={openAddModal}
                onSubmit={() => deleteUser(DelId)}
                onClose={() => setOpenAddModal(false)}
            />
            {changeStatusModal ? (
                <div className="changeStatus">
                    <div className="changeStatusBody">
                        <div className="changeStatusBodyTop">
                            <i className="fa-solid fa-circle-exclamation fa-rotate-180"></i>
                            <p>Change The User Status</p>
                        </div>
                        <div className="changeStatusBodySecPart">
                            <select
                                name=""
                                id=""
                                value={changeStatusVal}
                                onChange={(e) => setChangeStatusVal(e.target.value)}
                            >
                                <option value="active">Active</option>
                                <option value="in_active">Inactive</option>
                                <option value="pending">Pending</option>
                                <option value="hold">Hold</option>
                                <option value="blocked">Blocked</option>
                            </select>
                        </div>
                        <div className="changeStatusBodyThirdPart">
                            <p>Are You Sure You Want to Change The Status?</p>
                        </div>
                        <div className="changeStatusBodyLastPart">
                            <button
                                onClick={() => setChangeStatusModal(false)}
                                className="changeStatusBtnClose"
                            >
                                CLOSE
                            </button>
                            <button onClick={setStatus} className="changeStatusBtnSubmit">
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
export default withPermission(SubCustomerDashboard);

SubCustomerDashboard.moduleName = "Support Customer";