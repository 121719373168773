import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./components/css/policy.css";

export default function CancleRefund() {
	return (
		<>
			<Header />
			<section className="uui-section_faq01 privacy">
				<div className="uui-page-padding-3">
					<div className="uui-container-large-3">
						<div
							className="uui-padding-vertical-xhuge-3"
							style={{ paddingTop: "3rem" }}
						>
							<div className="uui-text-align-center-3">
								<div className=" align-center">
									<h2 className="uui-heading-medium-2 privacyPolicyText">
										Cancellation and Refund Policy
									</h2>
								</div>

								<div
									className="uui-text-size-large-7 text-start mt-5 mb-4 "
									style={{ color: "#000", fontSize: "16px" }}
								>
									Effective Date: 13-Feb-2024
								</div>
								<h3
									className="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Cancellation Policy:
								</h3>

								<h3
									className="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Cancellation by Customer:
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											Customers must give written notice to Bugbusterslabs
											Private Limited at least 45 days before the scheduled
											delivery date to cancel the Bugbounty Platform license or
											security value-added service.
										</li>
										<li>
											Any cancellations made after this timeframe may lead to
											the complete forfeiture of the payment.
										</li>
									</ul>
								</div>
								<h3
									className="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Cancellation by Bugbusterslabs:
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											Bugbusterslabs Private Limited retains the authority to
											terminate the Bugbounty platform or security value-added
											service due to unforeseen circumstances, such as:
											<ul>
												<li>Unforeseen Events</li>
												<li>Technical Failures</li>
												<li>Product or Service Unavailability</li>
												<li>Non-Compliance with Terms</li>
												<li>Force Majeure</li>
												<li>Regulatory Changes</li>
												<li>Supplier Issues</li>
												<li>Payment Issues</li>
												<li>Customer Request</li>
												<li>Third-Party Actions</li>
												<li>Security Breach</li>
												<li>Acts of God</li>
												<li>Labor Disputes</li>
												<li>Intellectual Property Issues</li>
												<li>Change in Business Operations.</li>
											</ul>
										</li>
										<li>
											In these situations, Bugbusterslabs Private Limited will
											provide customers with a complete refund or propose an
											alternative date.
										</li>
									</ul>
								</div>
								<h3
									className="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Refund Policy:
								</h3>
								<h3
									className="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Refund Eligibility:
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											Refunds are available in the following situations:
											<ul>
												<li>
													Cancellation by Bugbusterslabs Private Limited due to
													unforeseen circumstances.
												</li>
												<li>
													Failure to provide the Bugbounty Platform or security
													value-added services on the agreed-upon date.
												</li>
												<li>
													Any other situations deemed appropriate by
													Bugbusterslabs Private Limited.
												</li>
											</ul>
										</li>
									</ul>
								</div>
								<h3
									className="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Refund Process:
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											Customers requesting a refund must contact Bugbusterslabs
											Private Limited within 30 days of the cancellation or
											service failure.
										</li>
										<li>
											To request a refund, customers need to submit a written
											request explaining the reason.
										</li>
										<li>
											Once approved, refunds will be issued within 15 days.
										</li>
									</ul>
								</div>
								<h3
									className="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Refund Method:
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											Refunds will be processed using the same payment method
											for the initial transaction.
										</li>
										<li>
											Bugbusterslabs Private Limited will cover any fees linked
											to the refund procedure.
										</li>
									</ul>
								</div>
								<h3
									className="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									No-Show Policy:
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											With prior notice, customers who attend a scheduled Bug
											Bounty Platform or security value-added service may earn
											their payment.
										</li>
									</ul>
								</div>
								<h3
									className="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Exceptions:
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											Refunds are not available for:
											<ul>
												<li>Change of Mind,</li>
												<li>Service Completion,</li>
												<li>Downloadable or Consumable Products,</li>
												<li>Discounted or Clearance Items,</li>
												<li>Customized or Personalized Products,</li>
												<li>Late Cancellations,</li>
												<li>No-Show Policy,</li>
												<li>Promotional or Bundle Offers,</li>
												<li>Subscription Renewals before the renewal date,</li>
												<li>Trial Period Expiry,</li>
												<li>Failure to Provide Necessary Information,</li>
												<li>Product Obsolescence.</li>
											</ul>
										</li>
									</ul>
								</div>
								<h3
									className="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Contact Information:
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									If you have any inquiries or issues regarding cancellations
									and refunds, please get in touch with Bugbusterslabs Private
									Limited at **legal@bugbusterslabs.com**.
									<br />
									<br />
									Your interaction with Bugbusterslabs Private Limited means
									accepting this Cancellation and Refund Policy. Bugbusterslabs
									Private Limited reserves the right to revise or amend this
									policy at any time, with such adjustments becoming valid upon
									posting on the Bugbusterslabs Platform.
									<br />
									<br />
									We appreciate your selection of Bugbusterslabs Private
									Limited.
								</div>



							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}
