import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import "../resetpassword/Resetpassword.css";
import Infoicon from "./info.svg";
import Staricon from "./Star.svg";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'

export default function CustomerResetpassword() {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState("");
  const [cnfpassword, setCnfPassword] = useState("");
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type==='password'){
       setIcon(eye);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
 };

  const handalSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      navigate("/customer-login");
    }
    setValidated(true);
  };
  return (
    <>
      <div className="bg-gray-100">
        <main
          className="main-content position-relative  mt-0"
          style={{ height: "110vh" }}
        >
          <div className="container">
            <Link to="/">
              <div className=" top-content">
                <img className="logo" src={logo} alt="logo" width="20%" />
              </div>
            </Link>
            <div
              className="row   justify-content-center"
              style={{ marginTop: " 171px!important" }}
            >
              <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
                <div className="card z-index-0">
                  <div className="main-welcome mb-3">
                    <h4 className="main-welcome-head mb-0">
                      Reset your Password
                    </h4>
                  </div>
                  <div className="card-body pt-0">
                    <Form
                      className="text-start"
                      noValidate
                      validated={validated}
                      onSubmit={handalSubmit}
                    >
                      <Row className="mb-3">
                        <Form.Group
                          className="position-relative"
                          as={Col}
                          md="12"
                          controlId="validationCustom03"
                        >
                          <Form.Label label="Password">Password</Form.Label>
                          <Form.Control
                            type={type}
                            placeholder="New Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <span
                            className="flex justify-around items-center position-absolute"
                            id="eyeicon"
                            onClick={handleToggle}
                          >
                            <Icon
                              className="absolute mr-10"
                              icon={icon}
                              size={25}
                            />
                          </span>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Password.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom03"
                        >
                          <Form.Label label="Password">Password</Form.Label>
                          <Form.Control
                            type={type}
                            placeholder="New Password"
                            onChange={(e) => setCnfPassword(e.target.value)}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Match Confirm Password .
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <div className="suggestion-pass">
                          <ul className="list-unstyled">
                            <li>
                              <div className="d-flex">
                                <img
                                  className="info-icon"
                                  src={Infoicon}
                                  alt="info-icon"
                                />
                                <strong>
                                  Suggestions for a Strong Password:
                                </strong>
                              </div>
                            </li>
                            <li>
                              <div className="star mt-2">
                                <img
                                  className="info-icon"
                                  src={Staricon}
                                  alt="info-icon"
                                />
                                <p className="m-0">
                                  A Combination of Upper and Lowercase Letters,
                                  Numbers & Special Characters (Allowed One Only
                                  )
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="star mt-2">
                                <img
                                  className="info-icon"
                                  src={Staricon}
                                  alt="info-icon"
                                />
                                <p className="m-0">
                                  At least 8 Characters or More
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </Row>
                      <div className="text-center">
                        <Button
                          type="submit"
                          className="loginbtn w-100 my-4 mb-2"
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
}
