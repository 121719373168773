import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import { acceptedFileTypes } from "../../../utils/helperFunctions";
import AddModal from "../../common/modal/AddModal";
import EditPrgmSkeletonLoader from "../../components/SkeletonLoader/EditPrgmSkeletonLoader";
import AppContext from "../../Context/AppContext";
import useFetchSeverity from "../../HandleApis/Basevalues/FetchSeverity";
import useHttp from "../../Hooks/useHttp";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../reducers/authSlice";
import { customToast } from "../../../App";

export default function EditSubmission() {
  const { severityOpt } = useFetchSeverity();
  const { invokeApi } = HTTPService();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [userRole, setUserRole] = useState();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [valids, setValids] = useState({});
  const [files, setFiles] = useState([]);
  const [attchFile, setAttchFile] = useState([]);
  const { id } = useParams();
  const [targetUrlInput, setTargetUrlInput] = useState([]);
  const [userType, setUserType] = useState();
  const [userMainType, setUserMainType] = useState({ user_type: "" });
  const [programOpt, setProgramOpt] = useState();
  const [userOpt, setUserOpt] = useState();
  const [priorityOpt, setPriorityOpt] = useState();
  const [lfValue, setLfValue] = useState("");
  const [lfArray, setLfArray] = useState([]);
  const [aeValue, setAeValue] = useState("");
  const [aeArray, setAeArray] = useState([]);
  const [totaValue, setTotaValue] = useState("");
  const [totaArray, setTotaArray] = useState([]);
  const { PutRequest, GetRequest } = useHttp();
  const { loading, setLoading } = useContext(AppContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const API = process.env.REACT_APP_API_URL;
  const [taxonomyData, setTaxonomyData] = useState();
  const { userInfo } = useSelector(getUserInfo)
  const getVtxSubmissions = () => {
    invokeApi({
      method: "GET",
      url: `submissions-vtx`,
    }).then((data) => {
      setTaxonomyData(data.results.map(dat => dat.vtx_code));
    }).catch((error) => {
    });
  };
  const currDate = () => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;
  };

  const setType = (field, value) => {
    setUserMainType({ user_type: value });
    if (!value) {
      setErrors((prev) => ({ ...prev, [field]: "Please select type" }));
      setValids((prev) => ({ ...prev, [field]: false }));
    } else {
      setErrors((prev) => ({ ...prev, [field]: null }));
      setValids((prev) => ({ ...prev, [field]: true }));
    }
  };

  const receiveData = (allData) => {
    invokeApi({ method: "GET", url: `user/list_by_role/` })
      .then((data) => {
        setLoading(false);
        const userRoleData2 = data.results?.find(
          (item) => item.id === allData.user
        );
        const userRoleData = { ...userRoleData2 };
        setUserMainType({ user_type: userRoleData.role });
        setForm({
          ...allData,
          user_type: userRoleData.role,
          user_id: allData.user,
        });

        const TargetUrl = [
          allData.target_url1,
          allData.target_url2,
          allData.target_url3,
          allData.target_url4,
          allData.target_url5,
        ].filter(Boolean);
        const evidenceDoc = [
          allData.evidence_attachments1,
          allData.evidence_attachments2,
          allData.evidence_attachments3,
          allData.evidence_attachments4,
          allData.evidence_attachments5,
        ].filter(Boolean);
        const attachDoc = [
          allData.attachments_upload_doc1,
          allData.attachments_upload_doc2,
          allData.attachments_upload_doc3,
          allData.attachments_upload_doc4,
          allData.attachments_upload_doc5,
        ].filter(Boolean);

        setFiles(evidenceDoc);
        setAttchFile(attachDoc);
        setTotaArray(allData.type_of_testing_allowed?.split(",") || []);
        setLfArray(allData.languages_frameworks?.split(",") || []);
        setAeArray(allData.asset_environments?.split(",") || []);
        setTargetUrlInput(TargetUrl);
      })
      .catch(() => {
        setLoading(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const fetchSubmissionData = async () => {
    setLoading(true);
    invokeApi({ method: "GET", url: `submissions/${id}/` })
      .then((data) => receiveData(data))
      .catch(() => {
        setLoading(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const setField = (field, value) => {
    setForm((prev) => ({ ...prev, [field]: value }));
    if (field == 'vtx' && !taxonomyData.includes(value)) {
      setErrors({
        ...errors,
        [field]: "Please enter valid VTX code",
      });
      setValids({
        ...valids,
        [field]: false,
      });
      return
    }
    if (!value) {
      setErrors((prev) => ({ ...prev, [field]: `Please Enter ${field}` }));
      setValids((prev) => ({ ...prev, [field]: false }));
    } else {
      setErrors((prev) => ({ ...prev, [field]: null }));
      setValids((prev) => ({ ...prev, [field]: true }));
    }
  };

  const handleAddInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("i am called", e.target)
    if (targetUrlInput.length < 4) setTargetUrlInput([...targetUrlInput, ""]);
  };

  const deleteTargetBugUrl = (e, i) => {
    e.preventDefault();
    setTargetUrlInput((prev) => prev.filter((_, index) => index !== i));
  };

  const fetchProgramlist = async () => {
    invokeApi({
      method: "GET",
      url: `programs/`,
      params: {
        program_status: "approved",
        researcher_id: form?.user_id,
      },
    })
      .then((data) => setProgramOpt(data))
      .catch(() => { });
  };

  const handleFileChange = (event, setFileState) => {
    const selectedFiles = Array.from(event.target.files);
    const newFiles = Array.from(selectedFiles);
    for (const file of newFiles) {
      if (file.size > 5 * 1024 * 1024) {
        return customToast.error(`File "${file?.name}" exceeds the maximum upload size of 5MB.`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }
      if (!acceptedFileTypes.includes(file?.type)) {
        return customToast.error(`File ${file?.name} not supported`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }
    }
    if (setFileState.length + selectedFiles.length > 5) {
      return customToast.error(`You can only upload a maximum of 5 files.`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
    }
    setFileState((prev) => [...prev, ...selectedFiles]);
  };

  const handleRemoveFile = (index, setFileState) => {
    setFileState((prev) => prev.filter((_, i) => i !== index));
  };

  const handleKeyPress = (e, value, setValue, setArray) => {
    if (e.keyCode === 13 && value && value != "") {
      e.stopPropagation();
      e.preventDefault();
      setArray((prev) => [...prev, value]);
      setValue("");
    } else {

    }
  };

  const fetchValueOpt = async () => {
    invokeApi({ method: "GET", url: `user/roles/` })
      .then((res) => setUserType(res))
      .catch(() => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const fetchPriority = async () => {
    GetRequest(
      API + "basevalues/?title_code=Priority",
      {},
      { Authorization: "Bearer  " + token }
    )
      .then(({ data }) => setPriorityOpt(data.results))
      .catch(() => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const checkAllValidity = () => {
    const { user_id, program_id, submission_title, step_to_reproduce, vtx, severity, priority, target_url1, remediation_recommendation } = form;
    const allError = {};
    if (!user_id) allError.user_id = "Please select user";
    if (!program_id || program_id === "")
      allError.program_id = "Please select program";
    if (!submission_title || submission_title === "")
      allError.submission_title = "Please enter submisison title";
    if (!step_to_reproduce || step_to_reproduce === "")
      allError.step_to_reproduce = "Please enter step to reproduce";
    if (!severity) allError.severity = "Please enter value";
    if (!priority) allError.priority = "Please enter value";
    if (!vtx) allError.vtx = "Please enter value";
    if (totaArray.length < 1) allError.totaArray = "Please enter atleast one value";
    if (lfArray.length < 1) allError.lfArray = "Please enter atleast one value";
    if (aeArray.length < 1) allError.aeArray = "At least one target URL must be provided.";
    if (!target_url1) allError.target_url1 = "At least one target URL must be provided";


    if (!remediation_recommendation)
      allError.remediation_recommendation = "Please enter remediation recommendation"
    if (files.length < 1) allError.files = "Please upload atleast one document";

    return allError;
  };

  const finalSubmit = async () => {
    const checkError = checkAllValidity();
    if (Object.keys(checkError).length > 0) {
      setErrors(checkError);
      console.log("errors", checkError)
    } else {
      const updatedForm = { ...form };
      ["evidence_attachments", "attachments_upload_doc"].forEach(
        (field, index) => {
          for (let i = 1; i <= 5; i++) {
            const key = `${field}${i}`;
            if (typeof form[key] === "string") {
              delete updatedForm[key];
            } else {
              updatedForm[key] =
                field === "evidence_attachments"
                  ? files[i - 1]
                  : attchFile[i - 1];
            }
          }
        }
      );

      const param = {
        ...updatedForm,
        user: form.user_id,
        type_of_testing_allowed: totaArray.toString(),
        languages_frameworks: lfArray.toString(),
        asset_environments: aeArray.toString(),
        date: currDate(),
        target_url1:
          targetUrlInput && targetUrlInput[0] ? targetUrlInput[0] : '',
        target_url2:
          targetUrlInput && targetUrlInput[1] ? targetUrlInput[1] : '',
        target_url3:
          targetUrlInput && targetUrlInput[2] ? targetUrlInput[2] : '',
        target_url4:
          targetUrlInput && targetUrlInput[3] ? targetUrlInput[3] : '',
        target_url5:
          targetUrlInput && targetUrlInput[4] ? targetUrlInput[4] : '',
      };
      ["user_details", "assign_details", "transaction_details"].forEach(
        (key) => delete param[key]
      );

      invokeApi({ method: "PATCH", url: `submissions/${id}/`, data: param })
        .then(() => {
          setLoading(false);
          navigate("/admin/submissions");
        })
        .catch((error) => {
          setLoading(false);
          customToast.error(error?.data?.detail?.toString() ?? "Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        });
    }
  };

  useEffect(() => {
    if (userInfo?.user_data?.id) {
      fetchSubmissionData();
      getVtxSubmissions();
      fetchPriority();
      fetchValueOpt();
    }
  }, [userInfo?.user_data?.id]);

  useEffect(() => {
    if (userMainType.user_type) {
      GetRequest(
        API + `user/list_by_role/?role=${userMainType.user_type}`,
        {},
        { Authorization: "Bearer  " + token }
      )
        .then(({ data }) => setUserOpt(data))
        .catch(() => { });
    }
  }, [userMainType.user_type]);

  useEffect(() => {
    if (form?.user_id) fetchProgramlist();
  }, [form?.user_id]);

  const handleSubmitModal = () => {
    const checkError = checkAllValidity();
    if (Object.keys(checkError).length > 0) {
      setErrors(checkError);
      console.log("errors", checkError)
      customToast.error("Enter Values for mandatory fields!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    } else {
      setOpenAddModal(true);
    }
  };
  return (
    <>
      {loading ? (
        <EditPrgmSkeletonLoader layout={2} />
      ) : (
        <>
          <div className="container ">
            <div className="row">
              <div className="col-12 mt-4 ">
                <div className="card">
                  <div className="row ">
                    <div className="card mt-0 " id="basic-info">
                      <div className="card-header">
                        <p
                          style={{
                            color: "#000",
                            fontWeight: "600",
                            marginBottom: "0",
                            fontSize: "17px",
                          }}
                        >
                          Edit Submission
                        </p>
                      </div>
                      <div className="card-body pt-0">
                        <Form>
                          <div className="row mb-3">
                            <Form.Group as={Col} md="6">
                              <Form.Label
                                className="form-label"
                                style={{ fontSize: "14px" }}
                              >
                                Select Type
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <Form.Control
                                as="select"
                                className="form-select"
                                name="choices-gender"
                                id="choices-gender"
                                value={userMainType.user_type}
                                onChange={(e) =>
                                  setType("user_type", e.target.value)
                                }
                                isInvalid={!!errors.user_type}
                                isValid={valids.user_type}
                              >
                                <option value="" selected>
                                  Select User Type
                                </option>
                                {userType?.results.map((item, index) => {
                                  if (item.role.toLowerCase() === "researcher")
                                    return (
                                      <option
                                        key={index}
                                        value={item.role}
                                        className="text-capitalize"
                                      >
                                        {item.role}
                                      </option>
                                    );
                                })}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors.user_type}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                              <Form.Label
                                className="form-label"
                                style={{ fontSize: "14px" }}
                              >
                                Select User
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <Form.Control
                                as="select"
                                className="form-select"
                                name="choices-gender"
                                id="choices-gender"
                                value={form.user_id}
                                onChange={(e) =>
                                  setField("user_id", e.target.value)
                                }
                                isInvalid={!!errors.user_id}
                                isValid={valids.user_id}
                              >
                                <option value="" selected>
                                  Select User
                                </option>
                                {userOpt?.results
                                  .filter(
                                    (item) => item.user_status === "active"
                                  )
                                  .map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.username} {item.user_id}
                                    </option>
                                  ))}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors.user_id}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="row mb-3">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label"
                                style={{ fontSize: "14px" }}
                              >
                                Select Program
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <Form.Control
                                as="select"
                                className="form-select"
                                name="choices-gender"
                                id="choices-gender"
                                value={form.program_id}
                                onChange={(e) =>
                                  setField("program_id", e.target.value)
                                }
                                isInvalid={!!errors.program_id}
                                isValid={valids.program_id}
                              >
                                <option value="" selected>
                                  Select Program
                                </option>
                                {programOpt?.results.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.program_title} -- {item.program_id}
                                  </option>
                                ))}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors.program_id}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="row">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                Title
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>
                              <p
                                style={{
                                  color: "#B2C3DD",
                                  marginBottom: "10px",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                }}
                              >
                                (The Title or Short Description of the
                                Vulnerability Report)
                              </p>
                              <div className=" ">
                                <Form.Control
                                  className="form-control"
                                  placeholder="Enter a Submission Title"
                                  type="text"
                                  value={form.submission_title}
                                  onChange={(e) =>
                                    setField("submission_title", e.target.value)
                                  }
                                  isInvalid={!!errors.submission_title}
                                  isValid={valids.submission_title}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.submission_title}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </div>

                          <div className="row">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                Detail Description
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={form.detail_description || ""}
                                onChange={(e, editor) =>
                                  setField(
                                    "detail_description",
                                    editor.getData()
                                  )
                                }
                              />
                              {!form.detail_description && (
                                <p style={{ color: "#f00", fontSize: "14px" }}>
                                  Please Enter Detail Description
                                </p>
                              )}
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                Step to Reproduce
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={form.step_to_reproduce || ""}
                                onChange={(e, editor) =>
                                  setField(
                                    "step_to_reproduce",
                                    editor.getData()
                                  )
                                }
                              />
                              {!form.step_to_reproduce && (
                                <p style={{ color: "#f00", fontSize: "14px" }}>
                                  Please Enter Value
                                </p>
                              )}
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                Remediation Recommendation
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={form.remediation_recommendation || ""}
                                onChange={(e, editor) =>
                                  setField(
                                    "remediation_recommendation",
                                    editor.getData()
                                  )
                                }
                              />
                            </Form.Group>
                            {!form.remediation_recommendation && errors?.remediation_recommendation && (
                              <p className="invalid-warning" style={{ color: "#f00", fontSize: "14px", display: 'block' }}>
                                {errors?.remediation_recommendation}
                              </p>
                            )}
                          </div>

                          <div className="row mt-3">
                            <Form.Group as={Col} md="6">
                              <Form.Label
                                className="form-label"
                                style={{ fontSize: "14px" }}
                              >
                                Severity
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <Form.Control
                                as="select"
                                className="form-select"
                                name="choices-gender"
                                id="choices-gender"
                                value={form.severity}
                                onChange={(e) =>
                                  setField("severity", e.target.value)
                                }
                                isValid={valids.severity}
                              >
                                <option value="" selected>
                                  Select Severity
                                </option>
                                {severityOpt?.split(",").map((item, index) => (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                              <Form.Label
                                className="form-label"
                                style={{ fontSize: "14px" }}
                              >
                                Priority
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <Form.Control
                                as="select"
                                className="form-select"
                                name="choices-gender"
                                id="choices-gender"
                                value={form.priority}
                                onChange={(e) =>
                                  setField("priority", e.target.value)
                                }
                                isValid={valids.priority}
                              >
                                <option value="" selected>
                                  Select priority
                                </option>
                                {priorityOpt?.[0]?.value_options
                                  .split(",")
                                  .map((item, index) => (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  ))}
                              </Form.Control>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group as={Col} md="6">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                VTx
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <div className=" ">
                                <Form.Control
                                  className="form-control"
                                  placeholder="Enter VTx"
                                  type="text"
                                  value={form.vtx}
                                  onChange={(e) =>
                                    setField("vtx", e.target.value)
                                  }
                                  isValid={valids.vtx}
                                />
                              </div>
                              <a
                                href="/admin/vtxtaxonomy/dashboard"
                                className="link"
                                target="_blank"
                              >
                                View Vtx Dashboard
                              </a>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                CVSS Score
                              </Form.Label>

                              <div className=" ">
                                <div
                                  className="form-control"
                                  style={{ backgroundColor: "#99D9EA" }}
                                >
                                  <p
                                    style={{
                                      marginBottom: "0",
                                      color: "#000",
                                      fontWeight: "600",
                                    }}
                                  >
                                    System Generate Calculation
                                  </p>
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                Target Title
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <div className=" ">
                                <Form.Control
                                  className="form-control"
                                  placeholder="Enter a Target Name"
                                  type="text"
                                  value={form.target_title}
                                  onChange={(e) =>
                                    setField("target_title", e.target.value)
                                  }
                                  isValid={valids.target_title}
                                />
                              </div>
                              {form.target_title === " " ||
                                form.target_title === undefined ||
                                form.target_title === null && (
                                  <p style={{ color: "#f00", fontSize: "14px" }}>
                                    Please Enter Target Title
                                  </p>
                                )}
                            </Form.Group>
                          </div>
                          <div className="row">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                Target Bug URL
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <div className="d-flex flex-column">
                                <div className="d-flex">
                                  <div style={{ width: "70%" }}>
                                    <Form.Control
                                      className="form-control"
                                      placeholder="Enter URL"
                                      type="text"
                                      value={form.target_url1}
                                      onChange={(e) =>
                                        setField("target_url1", e.target.value)
                                      }
                                      isValid={valids.target_url1}
                                    />
                                  </div>
                                  <div>
                                    <button
                                      type="button" onClick={handleAddInput}
                                      style={{ borderRadius: "8px" }}
                                      className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                                    >
                                      Add +
                                    </button>
                                  </div>
                                </div>
                                {targetUrlInput.map((input, index) => (
                                  <div key={index} className="d-flex mt-3">
                                    <div style={{ width: "70%" }}>
                                      <Form.Control
                                        className="form-control"
                                        placeholder="Enter URL"
                                        type="text"
                                        value={input}
                                        onChange={(e) => {
                                          const newInputs = [...targetUrlInput];
                                          newInputs[index] = e.target.value;
                                          setTargetUrlInput(newInputs);
                                        }}
                                        isValid={!!input}
                                      />
                                    </div>
                                    <div>
                                      <button
                                        type="button" onClick={(e) =>
                                          deleteTargetBugUrl(e, index)
                                        }
                                        style={{ borderRadius: "8px" }}
                                        className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                                      >
                                        X
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Form.Group>
                            {!form.target_url1 && errors?.target_url1 && (
                              <p className="invalid-warning" style={{ display: 'block' }}>
                                {errors?.target_url1}
                              </p>
                            )}
                          </div>
                          <div className="row mb-3 mt-4">
                            <Form.Group as={Col} md="12">
                              <Form.Label
                                className="form-label"
                                style={{ fontSize: "14px" }}
                              >
                                Types of Testing Allowed
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <div className="form-control languageFrameworkDiv">
                                {totaArray.map((item, index) => (
                                  <div key={index} className="LfElement">
                                    <span>{item}</span>
                                    <span
                                      onClick={() =>
                                        setTotaArray((prev) =>
                                          prev.filter((t) => t !== item)
                                        )
                                      }
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <i className="fa-solid fa-xmark"></i>
                                    </span>
                                  </div>
                                ))}

                                <input
                                  type="text"
                                  placeholder="Add Type of Testing Allowed"
                                  value={totaValue}
                                  onChange={(e) => setTotaValue(e.target.value)}
                                  onKeyDown={(e) =>
                                    handleKeyPress(
                                      e,
                                      totaValue,
                                      setTotaValue,
                                      setTotaArray
                                    )
                                  }
                                />
                              </div>
                              {errors.totaValue && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.totaValue}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                          <div className="row mb-3 mt-4">
                            <div className="col-6">
                              <Form.Label
                                className="form-label"
                                style={{ fontSize: "14px" }}
                              >
                                Languages & Frameworks
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <div className="form-control languageFrameworkDiv">
                                {lfArray.map((item, index) => (
                                  <div key={index} className="LfElement">
                                    <span>{item}</span>
                                    <span
                                      onClick={() =>
                                        setLfArray((prev) =>
                                          prev.filter((t) => t !== item)
                                        )
                                      }
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <i className="fa-solid fa-xmark"></i>
                                    </span>
                                  </div>
                                ))}
                                <input
                                  type="text"
                                  placeholder="Add Language or Framework"
                                  value={lfValue}
                                  onChange={(e) => setLfValue(e.target.value)}
                                  onKeyDown={(e) =>
                                    handleKeyPress(
                                      e,
                                      lfValue,
                                      setLfValue,
                                      setLfArray
                                    )
                                  }
                                />
                              </div>
                              {errors.lfArray && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.lfArray}
                                </div>
                              )}
                            </div>
                            <div className="col-6">
                              <Form.Label
                                className="form-label"
                                style={{ fontSize: "14px" }}
                              >
                                Asset Environments
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </Form.Label>

                              <div className="form-control languageFrameworkDiv">
                                {aeArray.map((item, index) => (
                                  <div key={index} className="LfElement">
                                    <span>{item}</span>
                                    <span
                                      onClick={() =>
                                        setAeArray((prev) =>
                                          prev.filter((t) => t !== item)
                                        )
                                      }
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <i className="fa-solid fa-xmark"></i>
                                    </span>
                                  </div>
                                ))}
                                <input
                                  type="text"
                                  placeholder="Add Asset Environments"
                                  value={aeValue}
                                  onChange={(e) => setAeValue(e.target.value)}
                                  onKeyDown={(e) =>
                                    handleKeyPress(
                                      e,
                                      aeValue,
                                      setAeValue,
                                      setAeArray
                                    )
                                  }
                                />
                              </div>
                              {errors.aeArray && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.aeArray}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row mb-3 mt-4">
                            <div className="col-6">
                              <div className="d-flex justify-content-between">
                                <Form.Label
                                  className="form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Evidence Attachments
                                  <span
                                    style={{ color: "red", marginLeft: "3px" }}
                                  >
                                    *
                                  </span>
                                </Form.Label>
                                <p
                                  style={{
                                    marginBottom: "0",
                                    fontSize: "10px",
                                  }}
                                >
                                  Max Upload Size: 5 MB
                                </p>
                              </div>

                              <div className="form-control dotted">
                                <div className="file-upload w-100">
                                  {files.length === 0 ? (
                                    <p>Drop File Here To Upload</p>
                                  ) : (
                                    files.map((file, index) => (
                                      <div
                                        key={index}
                                        className="d-flex justify-content-between align-items-center"
                                      >
                                        <span className="text-overflow txtWidth">
                                          {file.name || file}
                                        </span>
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-sm"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleRemoveFile(index, setFiles);
                                          }}
                                          style={{
                                            borderRadius: "8px",
                                            zIndex: 100,
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    ))
                                  )}
                                  <Form.Control
                                    type="file"
                                    multiple
                                    onChange={(e) =>
                                      handleFileChange(e, setFiles)
                                    }
                                  />
                                </div>

                              </div>  {files && files.length < 1 && errors.files && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.files}
                                </div>
                              )}
                              <div className="d-flex justify-content-between">
                                <span className="text-xxs">
                                  * File Formats Allowed (.jpg, .png, .webp,
                                  .pdf, .xls, .doc, .docx, .csv, .ppt)
                                </span>
                                <span className="text-xxs">
                                  Max File Count: 5
                                </span>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex justify-content-between">
                                <Form.Label
                                  className="form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Attachments(Upload Documents)
                                </Form.Label>
                                <p
                                  style={{
                                    marginBottom: "0",
                                    fontSize: "10px",
                                  }}
                                >
                                  Max Upload Size: 5 MB
                                </p>
                              </div>

                              <div className="form-control dotted">
                                <div className="file-upload w-100">
                                  {attchFile.length === 0 ? (
                                    <p>Drop File Here To Upload</p>
                                  ) : (
                                    attchFile.map((file, index) => (
                                      <div
                                        key={index}
                                        className="d-flex justify-content-between align-items-center"
                                      >
                                        <span className="text-overflow txtWidth">
                                          {file.name || file}
                                        </span>
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-sm"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleRemoveFile(
                                              index,
                                              setAttchFile
                                            );
                                          }}
                                          style={{
                                            borderRadius: "8px",
                                            zIndex: 100,
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    ))
                                  )}
                                  <Form.Control
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(e, setAttchFile)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <span className="text-xxs">
                                  * File Formats Allowed (.jpg, .png, .webp,
                                  .pdf, .xls, .doc, .docx, .csv, .ppt)
                                </span>
                                <span className="text-xxs">
                                  Max File Count: 5
                                </span>
                              </div>
                            </div>
                          </div>
                        </Form>

                        <div className="d-flex justify-content-end">
                          <Link
                            to="/admin/submissions"
                            className="btn  btn-sm float-end mt-4 mb-0 px-3"
                            style={{ backgroundColor: "#E9ECEF" }}
                          >
                            Cancel
                          </Link>
                          <button
                            type="button" onClick={() => handleSubmitModal()}
                            className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
                          >
                            UPDATE SUBMISSION
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddModal
            cancelBtn={"Cancel"}
            submitBtn={"SUBMIT"}
            createAddTopic={"Edit Submission"}
            confirmText={"Edit The Submission"}
            isOpen={openAddModal}
            onSubmit={finalSubmit}
            onClose={() => setOpenAddModal(false)}
          />
        </>
      )}
    </>
  );
}
