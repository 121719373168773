import React, { useEffect, useState } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./components/css/BugBounty.css";
import { MenuItem, Select } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import HTTPService from "../utils/axios";
import AzureLogo from "./components/images/Azure-logo.png";
import MicrosoftLogo from "./components/images/microsoft-logo.png";
import SolutionImg from "./components/images/solutionsImg.jpeg";
import TwilloLogo from "./components/images/Twilio_logo.png";
import sanitizeHtml from "sanitize-html";
import { customToast } from "../App";

export default function TryBugBounty() {
  const { invokeApi } = HTTPService();
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    work_email: "",
    company_name: "",
    job_title: "",
    phone_number: "",
    country: "",
    bug_bounty_program: "",
  });

  const [errors, setErrors] = useState({}); // State to track errors

  const sanitizeFormData = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sanitizedData[key] = sanitizeHtml(data[key], {
          allowedTags: [],
          allowedAttributes: {},
        });
      }
    }
    return sanitizedData;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear error when user starts typing
    });
  };

  const handleOnChange = (value, country) => {
    setFormData((prev) => ({
      ...prev,
      phone_number: sanitizeHtml(value), // Sanitize phone number
      country: sanitizeHtml(country.dialCode),
    }));
    setErrors({
      ...errors,
      phone_number: "", // Clear error when user starts typing
      country: "", // Clear error for country as well
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const newErrors = {};

    // Helper function to check for empty or whitespace strings
    const isEmptyOrWhitespace = (str) => !str || str.trim().length === 0;

    const requiredFields = [
      "bug_bounty_program",
      "first_name",
      "last_name",
      "work_email",
      "company_name",
      "job_title",
      "phone_number",
      "country",
    ];
    for (let field of requiredFields) {
      if (isEmptyOrWhitespace(formData[field])) {
        newErrors[field] = `${field.replaceAll("_", " ")} is required!`;
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);
      return; // Prevent API call
    }

    const data = sanitizeFormData({ ...formData });
    const phoneNumberWithoutCode = data.phone_number
      .replace(`${data.country}`, "")
      .trim();
    data.phone_number = phoneNumberWithoutCode;

    invokeApi({
      method: "POST",
      url: `/try-bugbounty/`,
      data,
    })
      .then((data) => {
        setFormData({
          first_name: "",
          last_name: "",
          work_email: "",
          company_name: "",
          job_title: "",
          phone_number: "",
          country: "",
          bug_bounty_program: "",
        });
        customToast.success("Request sent successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        setLoading(false);
      })
      .catch((error) => {
        const flattenedErrors = error.data
          ? Object.keys(error.data)
            .map((key) => error.data[key])
            .toString()
          : "";
        customToast.error(flattenedErrors ?? "Something went wrong!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        setLoading(false);
      });
  };

  const fetchPrograms = () => {
    invokeApi({
      method: "GET",
      url: "basevalues/?title_code=program_type",
    })
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const res = data.results[0].value_options;
          const options = [];
          res.split(",").map((value) => {
            options.push({ label: value, value: value });
          });
          setPrograms(options);
        }
      })
      .catch((error) => {
        // Handle error
      });
  };

  useEffect(() => {
    fetchPrograms();
  }, []);

  return (
    <>
      <Header />
      <header>
        <div className="top-heading text-center">
          <h3>Get Started with the Bugbusterslabs Platform</h3>
        </div>
        <div className="app-container">
          <div className="poster-container">
            <img src={SolutionImg} alt="Poster" />
          </div>
          <div className="form-container">
            <h2>Chat with a Security Expert</h2>
            <p>
              We'll work to understand your business and provide a customize
              demo focused your unique security needs
            </p>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="first_name"
                placeholder="First Name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
              {errors.first_name && (
                <div className="invalid-warning">{errors.first_name}</div>
              )}

              <input
                type="text"
                name="last_name"
                placeholder="Last Name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
              {errors.last_name && (
                <div className="invalid-warning">{errors.last_name}</div>
              )}

              <input
                type="work_email"
                name="work_email"
                placeholder="Work email"
                value={formData.work_email}
                onChange={handleChange}
                required
              />
              {errors.work_email && (
                <div className="invalid-warning">{errors.work_email}</div>
              )}

              <input
                type="text"
                name="company_name"
                placeholder="Company Name"
                value={formData.company_name}
                onChange={handleChange}
                required
              />
              {errors.company_name && (
                <div className="invalid-warning">{errors.company_name}</div>
              )}

              <input
                type="text"
                name="job_title"
                placeholder="Job Title"
                value={formData.job_title}
                onChange={handleChange}
                required
              />
              {errors.job_title && (
                <div className="invalid-warning">{errors.job_title}</div>
              )}

              <PhoneInput
                country={"91"}
                placeholder="Enter phone number"
                className="w-100 mb-3"
                value={formData?.phone_number ?? ""}
                onChange={(value, country) => handleOnChange(value, country)}
                inputStyle={{ width: "100%", padding: "20px 40px" }}
              />
              {errors.phone_number && (
                <div className="invalid-warning">{errors.phone_number}</div>
              )}

              <Select
                name="bug_bounty_program"
                id="bug_bounty_program"
                sx={{
                  mb: 2,
                  ".paper": {
                    height: "200px !important",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      width: 200,
                      maxHeight: 200,
                    },
                  },
                }}
                placeholder="Select"
                value={formData?.bug_bounty_program || " "}
                fullWidth
                onChange={handleChange}
              >
                <MenuItem value=" ">Select</MenuItem>
                {programs?.map((program) => (
                  <MenuItem key={program.value} value={program.value}>
                    {program.label}
                  </MenuItem>
                ))}
              </Select>
              {errors.bug_bounty_program && (
                <div className="invalid-warning">
                  {errors.bug_bounty_program}
                </div>
              )}

              <button
                className="bugbountySubmitBtn"
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
        <div>
          <footer className="BB-footer">
            <div>
              <h5>TECHNOLOGY PARTNER</h5>
            </div>
            <div className="BB-footer-logo">
              <ul>
                <li>
                  {" "}
                  <img src={TwilloLogo} alt="#" />{" "}
                </li>
                <li>
                  <img src={MicrosoftLogo} alt="#" />
                </li>
                <li>
                  <img src={AzureLogo} alt="#" />
                </li>
                {/* <ul>
                  <p>etc..</p>
                </ul> */}
              </ul>
            </div>
          </footer>
        </div>
      </header>
      <Footer />
    </>
  );
}
