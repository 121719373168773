import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ProfileImage from "../../../components/ProfileImage";
import withPermission from "../../../hoc/withPermission";
import { getUserInfo } from "../../../reducers/authSlice";
import HTTPService from "../../../utils/axios";
import { formatDate } from "../../../utils/helperFunctions";
import AddModal from "../../common/modal/AddModal";
import CommonTable from "../../common/Table/CommonTable";
import CountSkeletonLoader from "../../components/SkeletonLoader/CountSkeletonLoader";
import AppContext from "../../Context/AppContext";
import useHttp from "../../Hooks/useHttp";
import "./CustomerDashboardStyle.css";
import { customToast } from "../../../App";

const Customerdashboard = () => {
  const { invokeApi } = HTTPService();
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { userInfo, permissions } = useSelector(getUserInfo);
  const permission = permissions.find((perm) => perm.module === "Customers");
  const [customerOpt, setCustomerOpt] = useState();
  const { setLoading, loading, setCircularLoading } = useContext(AppContext);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [DelId, setDelId] = useState();
  const navigate = useNavigate();
  const { DeleteRequest, PostRequest, PatchRequest } = useHttp();
  const storedToken = localStorage.getItem("token");
  const [analytics, setAnalytics] = useState();
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusVal, setChangeStatusVal] = useState();
  const [selectedUser, setSelectedUser] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();
  const fetchCustomer = () => {
    setLoading(true);
    let url = `customer/?page=${currentPage}`;
    let params = {
      page_size: 25
    }
    if (searchQuery && searchQuery.length > 0) {
      params[searchValue] = searchQuery.replaceAll('+', '')
    }
    if (filterType && filterValue && filterValue.length > 0) {
      params[filterType] = filterValue
    }
    invokeApi({
      method: "GET",
      url,
      params
    })
      .then((data) => {
        setLoading(false);

        setCustomerOpt(data);
        setTotalPages(data.total_pages);
      })
      .catch((error) => {
        setLoading(false);

        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  useEffect(() => {
    fetchCustomer();
  }, []);

  useEffect(() => {
    fetchCustomer();
  }, [searchQuery, currentPage, filterValue]);

  const tableColumn = [
    "Customer ID",
    "User Name",
    "Full Name",
    "Entity Name",
    "Email",
    "Phone",
    "Registred At",
    "Status",
    "Action",
  ];
  const searchColumn = [
    { name: "Customer Id", value: "user_id" },
    { name: "User Name", value: "username" },
    { name: "Name", value: "first_name" },
    { name: "Entity Name", value: "entity_name" },
    { name: "Email", value: "email" },
    { name: "Phone", value: "phone_number" },
    // { name: "Registred At", value: "created_at" },
    // { name: "Status", value: "user_status" },
  ];

  const filterColumn = [
    {
      name: "Status",
      value: "user_status",
      filters: [
        { value: "pending", label: "Pending" },
        { value: "active", label: "Active" },
        { value: "in_active", label: "Inactive" },
        { value: "hold", label: "Hold" },
        { value: "blocked", label: "Blocked" },
      ],
    },
  ];

  const API = process.env.REACT_APP_API_URL;

  const Breadcrumbname = {
    home: "/admin/home",
    Management: "",
    Customer: "/admin/customers",
    Dashboard: "",
  };

  // const handalFetch = async () => {
  // 	setUserdata(customerOpt && customerOpt);
  // };

  const fetchAnalytics = async (event) => {
    try {
      const response = await axios(`${API}customer/status_count`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      });
      setAnalytics(response.data);
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };
  const AddEditWallet = async () => {
    if (selectedUser.wallet_id && changeStatusVal) {
      const { data, error } = await PatchRequest(
        `${API}wallets/${selectedUser.wallet_id}/`,
        {
          KYC_Status: ["pending", "hold"].includes(changeStatusVal)
            ? "hold"
            : changeStatusVal.replaceAll("_", "-"),
        },
        {},
        {
          Authorization: "Bearer  " + storedToken,
          "Content-Type": "multipart/form-data",
        }
      );
      if (error) {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setChangeStatusModal(false);
        console.log(error);
      }
      if (data) {
        setStatus();
        // setChangeStatusVal(data && data.user_status);
      }
    } else if (!selectedUser.wallet_id && changeStatusVal === "active") {
      const { data, error } = await PostRequest(
        API + "wallets/",
        {
          first_name: selectedUser.first_name,
          middle_name: selectedUser.middle_name,
          last_name: selectedUser.last_name,
          KYC_Status: "active",
          Wallet_Balance: 0,
          user_id: selectedUser.id,
        },
        {
          Authorization: "Bearer  " + storedToken,
          "Content-Type": "multipart/form-data",
        }
      );
      if (error) {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.log(error);
        setChangeStatusModal(false);
      }
      if (data) {
        setStatus();
        // setChangeStatusVal(data && data.user_status);
      }
    } else {
      setStatus();
    }
  };

  const handleStatus = (data) => {
    setChangeStatusModal(true);
    setChangeStatusVal(data.user_status);
    setSelectedUser(data);
  };
  const setStatus = async () => {
    setChangeStatusModal(false);
    setCircularLoading(true);
    const id = selectedUser?.id;
    const param = {
      id: id,
      user_status: changeStatusVal,
    };
    const { data, error } = await PatchRequest(
      API + "customer/" + id + "/",
      param,
      {},
      {
        Authorization: "Bearer  " + storedToken,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      setCircularLoading(false);

      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
    if (data) {
      setCircularLoading(false);
      setCustomerOpt(prev => ({
        ...prev,
        results: prev.results.map(res => {
          if (res.id === id) {
            return {
              ...res,
              ...param
            }
          }
          return res
        })
      }))
      customToast.success("Status updated", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const deleteCustomer = (id) => {
    setOpenAddModal(false);
    setCircularLoading(true);
    const { error } = DeleteRequest(API + "customer/" + id + "/", {
      Authorization: "Bearer  " + storedToken,
      "Content-Type": "multipart/form-data",
    });
    if (error) {
      setCircularLoading(false);
      customToast.error("Something went worng!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setCircularLoading(false);
      setCustomerOpt(prev => ({
        ...prev,
        results: prev.results.filter(res => res.id !== id)
      }))
      customToast.success("Deleted Successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    // handalFetch();
    fetchAnalytics();
  }, []);

  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            {(permission?.create || userInfo.role === "admin") && (
              <div className="button-row d-flex justify-content-end mt-0 mb-3">
                <Link
                  to={"/admin/customer/add"}
                  className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                  id="adduser"
                >
                  Add Customer
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          {loading ? (
            <CountSkeletonLoader showState={1} />
          ) : (
            <div className="col-lg-12 col-12">
              <div className="row d-flex">
                <div className="" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-8 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics &&
                              analytics.total_customer_counts &&
                              analytics.total_customer_counts.active}
                          </h5>
                          <span className="text-white text-sm">
                            Active Customers
                          </span>
                        </div>
                        <div className="col-4">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics &&
                              analytics.today_customer_counts &&
                              analytics.today_customer_counts.active}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4 mt-md-0" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-8 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics &&
                              analytics.total_customer_counts &&
                              analytics.total_customer_counts.in_active}
                          </h5>
                          <span className="text-white text-sm">
                            In-Actives Customers
                          </span>
                        </div>
                        <div className="col-4">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics &&
                              analytics.today_customer_counts &&
                              analytics.today_customer_counts.in_active}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4 mt-md-0" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-8 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics &&
                              analytics.total_customer_counts &&
                              analytics.total_customer_counts.blocked}
                          </h5>
                          <span className="text-white text-sm">
                            Blocked Customers
                          </span>
                        </div>
                        <div className="col-4">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics &&
                              analytics.today_customer_counts &&
                              analytics.today_customer_counts.blocked}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4 mt-md-0" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-8 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics &&
                              analytics.total_customer_counts &&
                              analytics.total_customer_counts.pending}
                          </h5>
                          <span className="text-white text-sm">
                            Pending Approveals
                          </span>
                        </div>
                        <div className="col-4">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics &&
                              analytics.today_customer_counts &&
                              analytics.today_customer_counts.pending}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4 mt-md-0" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-8 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics &&
                              analytics.total_customer_counts &&
                              analytics.total_customer_counts.hold}
                          </h5>
                          <span className="text-white text-sm">Hold</span>
                        </div>
                        <div className="col-4">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics &&
                              analytics.today_customer_counts &&
                              analytics.today_customer_counts.hold}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <div className="search-container">
					<input
						type="text"
						placeholder="Search by name..."
						value={searchQuery}
						onChange={handleSearch}
					/>
				</div> */}

        <div className="row my-4">
          <div className="col-12">
            <CommonTable
              column={tableColumn}
              data={customerOpt?.results}
              totalPages={totalPages}
              totalData={customerOpt?.total_records}
              filterList={filterColumn}
              filterType={filterType}
              setFilterType={setFilterType}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              searchBar={true}
              searchBy={searchColumn}
              itemPerPg={25}
              setTable={1}
              card={7}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              align="center"
            >
              <>
                {customerOpt?.results &&
                  customerOpt?.results.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <p
                          className="text-sm  cursor-pointer mb-0 px-2"
                          onClick={() => {
                            navigate(`/admin/customer/view/${item.id}`);
                          }}
                          style={{ color: "#5A78F0" }}
                        >
                          {item.user_id}
                        </p>
                      </td>
                      <td className="text-start">
                        <p
                          className="text-sm  cursor-pointer mb-0 px-2"
                          onClick={() => {
                            navigate(`/admin/customer/view/${item.id}`);
                          }}
                          style={{ color: "#5A78F0" }}
                        >
                          {item.username}
                        </p>
                      </td>
                      <td
                        className="cursor-pointer text-start"
                        onClick={() => {
                          navigate(`/admin/customer/view/${item.id}`);
                        }}
                      >
                        <div className="d-flex px-2 py-1">
                          <div
                            style={{
                              height: "36px",
                              width: "36px",
                              marginRight: "10px",
                            }}
                          >
                            <ProfileImage
                              alt="avatar"
                              src={item.display_pic}
                              className="avatar avatar-sm "
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6
                              className="mb-0 text-sm"
                              style={{ color: "#5A78F0" }}
                            >
                              {item.first_name} {item.last_name}
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td className="text-start">
                        <p className="text-sm  mb-0">{item.entity_name}</p>
                      </td>
                      <td className="text-start">
                        <p className="text-sm  mb-0">{item.email}</p>
                      </td>
                      <td className="align-middle text-start text-sm">
                        <p className="text-sm  mb-0">{item.phone_number}</p>
                      </td>
                      <td className="align-middle text-start">
                        <p className="text-sm  mb-0">
                          {formatDate(item.created_at, 1)}
                        </p>
                      </td>
                      <td className="align-middle text-center">
                        {item.user_status === "active" ? (
                          <button
                            onClick={() => handleStatus(item)}
                            className="badge bg-success text-white"
                          >
                            Active
                          </button>
                        ) : item.user_status === "in_active" ? (
                          <button
                            onClick={() => handleStatus(item)}
                            className="badge bg-danger text-white"
                          >
                            Inactive
                          </button>
                        ) : item.user_status === "hold" ? (
                          <button
                            onClick={() => handleStatus(item)}
                            className="badge bg-danger text-white"
                          >
                            Hold
                          </button>
                        ) : item.user_status === "pending" ? (
                          <button
                            onClick={() => handleStatus(item)}
                            className="badge bg-danger text-white"
                          >
                            Pending
                          </button>
                        ) : item.user_status === "blocked" ? (
                          <button
                            onClick={() => handleStatus(item)}
                            className="badge bg-danger text-white"
                          >
                            Blocked
                          </button>
                        ) : (item.user_status === null) | undefined ? (
                          <button
                            onClick={() => handleStatus(item)}
                            className="badge bg-danger text-white"
                          >
                            null
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            to={`/admin/customer/view/${item.id}`}
                            className="btn btn-sm px-3 mt-2"
                          >
                            &#128065;
                          </Link>
                          <button
                            type="button"
                            className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-ellipsis-v text-xs"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <ul
                            className="dropdown-menu shadow"
                            style={{ zIndex: 100 }}
                          >
                            {(permission?.edit || userInfo.role === "admin") && (
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={`/admin/customer/edit/${item.id}`}
                                >
                                  Edit
                                </Link>
                              </li>
                            )}
                            {(permission?.delete || userInfo.role === "admin") && (
                              <li>
                                <button
                                  onClick={() => {
                                    setOpenAddModal(true);
                                    setDelId(item.id);
                                  }}
                                  className="dropdown-item"
                                >
                                  Delete
                                </button>
                              </li>
                            )}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
              </>
            </CommonTable>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"DELETE"}
        createAddTopic={"Delete Customer"}
        confirmText={"Delete The Customer"}
        isOpen={openAddModal}
        onSubmit={() => deleteCustomer(DelId)}
        onClose={() => setOpenAddModal(false)}
      />
      {changeStatusModal ? (
        <div className="changeStatus">
          <div className="changeStatusBody">
            <div className="changeStatusBodyTop">
              <i className="fa-solid fa-circle-exclamation fa-rotate-180"></i>
              <p>Change The User Status</p>
            </div>
            <div className="changeStatusBodySecPart">
              <select
                name=""
                id=""
                value={changeStatusVal}
                onChange={(e) => setChangeStatusVal(e.target.value)}
              >
                <option value="active">Active</option>
                <option value="in_active">Inactive</option>
                <option value="pending">Pending</option>
                <option value="hold">Hold</option>
                <option value="blocked">Blocked</option>
              </select>
            </div>
            <div className="changeStatusBodyThirdPart">
              <p>Are You Sure You Want to Change The Status?</p>
            </div>
            <div className="changeStatusBodyLastPart">
              <button
                onClick={() => setChangeStatusModal(false)}
                className="changeStatusBtnClose"
              >
                CLOSE
              </button>

              <button onClick={AddEditWallet} className="changeStatusBtnSubmit">
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default withPermission(Customerdashboard);

Customerdashboard.moduleName = "Customers";
