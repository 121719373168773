import React, { useEffect, useState } from 'react'
import ViewInfo from '../../../common/ViewInfo'
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb'
import { Link, useNavigate, useParams } from 'react-router-dom'

import "./VTxTaxonomy.css"
import HTTPService from '../../../../utils/axios'
import ViewInfoSkeleton from '../../../components/SkeletonLoader/ViewInfoSkeleton'
import { customToast } from '../../../../App'


// const { id } = useParams();

function ViewVTxTaxonomy() {
  const { id } = useParams();
  const [TaxonomyData, setTaxonomyData] = useState()
  const navigate = useNavigate();
  const TaxonomyValue = [TaxonomyData?.vtx_code, TaxonomyData?.main_category, TaxonomyData?.sub_category, TaxonomyData?.variant_title]
  const Title = "View Taxonomy Details"
  const subTitle = "Taxonomy Details"
  const option = ["VTx Code", "Category", "Sub-Category", "Variant Title"];
  const OtherValue = [TaxonomyData?.generic_description, TaxonomyData?.generic_description]
  const subTitle2 = "Other Details"
  const option2 = ["Generic Description", "Best Practic Note | Appendix"];
  const { invokeApi } = HTTPService();
  const [loading, setLoading] = useState(false)
  const Breadcrumbname = {
    home: "/admin/home",
    "Master Module": "",
    "VTx Taxonomy": "/admin/master-module",
    "View Taxonomy": "",
  };
  const fetchVtx = () => {
    setLoading(true)
    invokeApi({
      method: "GET",
      url: `submissions-vtx/${id}`,

    }).then((data) => {
      setLoading(false)
      setTaxonomyData(data);
    }).catch((error) => {
      setLoading(false)
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  };

  useEffect(() => {
    fetchVtx()
  }, []);
  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row w-100 d-flex justify-content-center">
          <div className="addCategoryArea  my-4">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="col-lg-6 ps-3">
                <Breadcrumb names={Breadcrumbname} />
              </div>

              <div className="button-row d-flex justify-content-end mt-0 mb-3">
                <Link
                  to="/admin/vtxtaxonomy/dashboard"
                  className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                  id="adduser"
                >
                  VIEW ALL
                </Link>
              </div>
            </div>


            <div className="col-12">
              <div className="card" style={{ backgroundColor: "white" }}>
                {loading ?
                  <ViewInfoSkeleton /> :
                  <ViewInfo itemValue={TaxonomyValue} option={option} title={Title} subTitle={subTitle} />
                }
                {loading ?
                  <ViewInfoSkeleton /> :
                  <ViewInfo itemValue={OtherValue} option={option2} subTitle={subTitle2} />
                }

                <div className=" d-flex justify-content-end p-4">
                  <button className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3" onClick={() => navigate(-1)}>
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
                    onClick={() => navigate(`/admin/vtxtaxonomy/edit/${id}`)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>





          </div>
        </div>
      </div>
    </>
  )
}

export default ViewVTxTaxonomy
