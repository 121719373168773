import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import { getUserInfo } from "../../../../reducers/authSlice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CommonTable from "../../../common/Table/CommonTable";
import AddModal from "../../../common/modal/AddModal";
import { ToastContainer, toast } from "react-toastify";

import AddVTxTaxonomy from "./AddVTxTaxonomy";
import HTTPService from "../../../../utils/axios";
import CommonTableSkeletonLoader from "../../../components/SkeletonLoader/CommonTableSkeletonLoader";
import AppContext from "../../../Context/AppContext";
import { customToast } from "../../../../App";

export default function VTxTaxonomy() {
  const { userInfo, permissions } = useSelector(getUserInfo);
  const permission = permissions.find((perm) => perm.module === "VTx Taxonomy");
  const [taxonomyData, setTaxonomyData] = useState();
  // const [loading, setLoading] = useState(false);
  const { setLoading } = useContext(AppContext);
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();
  const filterColumn = [
    {
      name: "Status",
      value: "vtx_status",
      filters: [
        { value: "active", label: "Active" },
        { value: "in_active", label: "Inactive" },]
    }
  ];
  const tableColumn = [
    "VTx Code",
    "Main Category",
    "Sub-Category",
    "Variant title",
    "Status",
    "Action",
  ];

  const searchColumn = [
    { name: "VTx Code", value: "vtx_code" },
    { name: "Main category", value: "main_category" },
    { name: "Sub Category", value: "sub_category" },
    { name: "Variant Title", value: "variant_title" },
  ];

  const handleStatus = async (
    id,
    VTxCode,
    MCategory,
    SCategory,
    VariantDetails,
    status
  ) => {
    const param = {
      VTx_Code: VTxCode,
      Main_Category: MCategory,
      category_type: SCategory,
      Variant_Details: VariantDetails,
      is_deleted: false,
      Status: status,
    };
  };

  const Breadcrumbname = {
    home: "/admin/home",
    "Master Module": "",
    "VTx Taxonomy": "/admin/vtxtaxonomy",
    Dashboard: "/admin/vtxtaxonomy",
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const { invokeApi } = HTTPService();

  const getVtxSubmissions = (isLoading = true) => {
    if (isLoading)
      setLoading(true);
    let params = {
      page: currentPage,
      page_size: 25
    }
    if (searchValue && searchValue.length > 0) {
      params[searchValue] = searchQuery
    }
    if (filterType && filterValue && filterValue.length > 0) {
      params[filterType] = filterValue
    }
    invokeApi({
      method: "GET",
      url: `submissions-vtx`,
      params
    })
      .then((data) => {
        setLoading(false);
        setTaxonomyData(data);
      })
      .catch((error) => {
        setLoading(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const handleSubmit = (files) => {
    handleCloseModal();
  };

  useEffect(() => {
    if (userInfo?.user_data?.id)
      getVtxSubmissions();
  }, [userInfo?.user_data?.id]);

  useEffect(() => {
    if (userInfo?.user_data?.id)
      getVtxSubmissions(false);
  }, [searchQuery, currentPage, searchValue, filterValue]);
  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              {(permission?.create || userInfo.role === "admin") && (
                <button
                  onClick={handleOpenModal}
                  className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                  id="adduser"
                >
                  Taxonomy Bulk Upload
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="table-overflow row my-4">
          <div className="col-12">
            <div className="table-responsive">
              <CommonTable
                filterList={filterColumn}
                filterType={filterType}
                setFilterType={setFilterType}
                setFilterValue={setFilterValue}
                column={tableColumn}
                data={taxonomyData && taxonomyData?.results}
                searchBar={true}
                itemPerPg={25}
                searchBy={searchColumn && searchColumn}
                setTable={1}
                card={7}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                searchPlaceHolder={searchPlaceHolder}
                setSearchPlaceHolder={setSearchPlaceHolder}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={taxonomyData?.total_pages ?? 1}
                totalData={taxonomyData?.total_records}
              >
                {taxonomyData &&
                  taxonomyData.results.map((item, index) => (
                    <tr key={index}>
                      {/* <td>
                          <Link
                            to={`/admin/faq/view/${item.id}`}
                            className="text-sm  mb-0 px-2 gotoView"
                          >
                            {item.id}
                          </Link>
                        </td> */}

                      <td className="cell-breakWord ">
                        <p className="text-sm  mb-0">{item.vtx_code}</p>
                      </td>
                      <td className="text-start">
                        <p className="text-sm  mb-0">{item.main_category}</p>
                      </td>
                      <td className="text-start">
                        <p className="text-sm  mb-0">{item.sub_category}</p>
                      </td>
                      <td className="text-start">
                        <p className="text-sm  mb-0">{item.variant_title}</p>
                      </td>

                      <td className="align-middle text-center">
                        {item.vtx_status === "active" ? (
                          <span
                            style={{ cursor: "pointer" }}
                            className="badge bg-success text-white"
                            onClick={() => {
                              handleStatus(
                                item.id,
                                item.VTx_Code,
                                item.Main_Category,
                                item.Sub_Category,
                                item.Variant_Details,
                                "in_active"
                              );
                            }}
                          >
                            Active
                          </span>
                        ) : (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleStatus(
                                item.id,
                                item.VTx_Code,
                                item.Main_Category,
                                item.Sub_Category,
                                item.Variant_Details,
                                "active"
                              );
                            }}
                            className="badge bg-danger text-white"
                          >
                            Inactive
                          </span>
                        )}
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            to={`/admin/vtxtaxonomy/view/${item.id}`}
                            className="btn btn-sm px-3 mt-2"
                          >
                            &#128065;
                          </Link>
                          <button
                            type="button"
                            className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-ellipsis-v text-xs"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              {(permission?.edit ||
                                userInfo.role === "admin") && (
                                  <Link
                                    className="dropdown-item"
                                    to={`/admin/vtxtaxonomy/edit/${item.id}`}
                                  >
                                    Edit
                                  </Link>
                                )}
                            </li>
                            <li>
                              {(permission?.delete ||
                                userInfo.role === "admin") && (
                                  <button
                                    className="dropdown-item"
                                  // onClick={() =>
                                  //     setOpenAddModal({
                                  //         isOpen: true,
                                  //         id: item.id,
                                  //     })
                                  // }
                                  >
                                    Delete
                                  </button>
                                )}
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
              </CommonTable>
            </div>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"DELETE"}
        createAddTopic={"Delete Question"}
        confirmText={"Delete The Question"}
      // isOpen={openAddModal.isOpen}
      // onSubmit={() => deleteFaqQa(openAddModal.id)}
      // onClose={() => setOpenAddModal({ isOpen: false })}
      />

      {isModalOpen && (
        <AddVTxTaxonomy
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          modalTitle="Add Bulk Upload Taxonomy"
          confirmText="upload these files"
          cancelBtn="CANCLE"
        />
      )}
    </>
  );
}
