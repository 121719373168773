import React from "react";
import reportData from "./programReportData.json";
import { Link } from "react-router-dom";
export default function AdminProgramReports() {
  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row w-100 d-flex justify-content-start">
          <h5 style={{ color: "#000" }}>Reports</h5>
        </div>
        <div className="row mt-3">
          <div className="col-6 d-flex align-items-center">
            <div className="col-5">
              <select
                className="form-select"
                aria-label="Default select example"
              >
                <option selected>All Reports Type</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div>
              <span
                style={{
                  marginLeft: "20px",
                  fontSize: "16px",
                  fontWeight: 700,
                  color: "#673AB7",
                }}
              >
                8 reports
              </span>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <div className="col-4">
              <div className="button-row d-flex justify-content-end mt-0 mb-3">
                <button
                  style={{ textWrap: "nowrap" }}
                  className="btn bg-gradient-dark me-3 mb-0 text-capitalize d-flex align-items-center"
                  id="adduser"
                >
                  Generate Reports{" "}
                  <i className="ms-1 fa-solid fa-chevron-down"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table align-items-center mb-0 adminReportDashboardTable">
                  <thead>
                    <tr>
                      <th
                        style={{ textAlign: "left" }}
                        className="text-uppercase  font-weight-bolder opacity-8"
                      >
                        <div style={{ marginLeft: "50px" }}>Title</div>
                      </th>
                      <th
                        style={{ textAlign: "left" }}
                        className="text-uppercase  font-weight-bolder opacity-8"
                      >
                        <div style={{ marginLeft: "50px" }}>Type</div>
                      </th>
                      <th
                        style={{ textAlign: "left" }}
                        className="text-uppercase  font-weight-bolder opacity-8 ps-2"
                      >
                        <div style={{ marginLeft: "50px" }}>Created</div>
                      </th>
                      <th className=" text-uppercase  font-weight-bolder opacity-8">
                        <div>Actions</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData &&
                      reportData.map((item, index) => (
                        <tr key={index} className="text-start">
                          <td>
                            <p
                              style={{
                                color: "#257BC2",
                                textAlign: "left",
                                marginLeft: "50px",
                              }}
                              className="text-sm  mb-0 px-2"
                            >
                              {item.title}
                            </p>
                          </td>
                          <td>
                            <p
                              style={{
                                textAlign: "left",
                                marginLeft: "50px",
                              }}
                              className="text-sm text-secondary mb-0 px-2"
                            >
                              {item.type}
                            </p>
                          </td>
                          <td>
                            <p
                              style={{
                                textAlign: "left",
                                marginLeft: "50px",
                              }}
                              className="text-sm text-secondary mb-0 px-2"
                            >
                              {item.created}
                            </p>
                          </td>
                          <td className="text-center">
                            {/* <div className="d-flex"> */}
                            <Link
                              // to={`/admin/customer/view/${item.id}`}
                              className="btn btn-sm px-3 mt-2"
                            >
                              &#128065;
                            </Link>
                            <i
                              style={{ color: "#7F56D9", marginLeft: "1rem" }}
                              className="fa fa-trash"
                              aria-hidden="true"
                            ></i>
                            {/* </div> */}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
