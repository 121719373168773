import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import { useEffect, useState } from "react";


export default function useFetchProgramPackage() {
   
	const [programPkgOpt, setProgramPkgOpt] = useState();

	const { invokeApi } = HTTPService();
	useEffect(() => {
		const fetchPrgmPkg = async () => {
			invokeApi({
				method: "GET",
				url: "basevalues/?title_code=program_package",
			})
				.then((data) => {
					setProgramPkgOpt(data.results[0].value_options);
				})
				.catch((error) => {
					toast.error("Something went wrong!", {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 1000,
					});
				});
		};
		fetchPrgmPkg();
	}, []);
	return { programPkgOpt };
}
