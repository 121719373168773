import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUserInfo } from '../../reducers/authSlice';
import HTTPService from '../../utils/axios';
import slackLogo from '../assets/img/jira.jpg';
import CircularLoader from '../CircularLoader/CircularLoader';
import { customToast } from '../../App';
const JiraIntegrationPage = () => {
    const { invokeApi } = HTTPService();
    const { userInfo } = useSelector(getUserInfo);
    const [searchParams, setSearchParams] = useSearchParams();
    const role = localStorage.getItem('role')
    const [loading, setLoading] = useState(true);
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const navigate = useNavigate()
    const handleCode = () => {
        setLoading(true)
        invokeApi({
            method: "POST",
            url: `jira/callback`,
            params: {
                code,
                // program_id: program
            },
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            setLoading(false)
            if (role === 'admin')
                navigate('/admin/integrations/jira');
            else if (role === 'customer')
                navigate('/customer/integrations/jira');
            else if (role === 'customer')
                navigate('/researcher/integrations/jira');

            customToast.success("Account added successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
        }).catch(error => {
            const flattenedErrors = typeof error.data == Object ? Object.keys(error.data)
                .map(key => key.replaceAll('_', ' ').toUpperCase() + ": " + error.data[key].join('\n'))
                .join('\n') : undefined;
            customToast.error(flattenedErrors ?? "Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
            // if (role === 'admin')
            //     navigate('/admin/integrations/jira');
            // else if (role === 'customer')
            //     navigate('/customer/integrations/jira');
            // else if (role === 'customer')
            //     navigate('/researcher/integrations/jira');
            setLoading(false)
        })
    }

    useEffect(() => {
        if (userInfo?.user_data && code && state)
            handleCode();
    }, [userInfo?.user_data, code, state])
    // useEffect(() => {
    //     if (code)
    //         handleCode()
    // }, [code]);
    return (
        <div style={{ height: '100vh', overflowY: 'auto', width: '100%' }} className='d-flex justify-content-center align-items-center row'>
            {loading &&
                <CircularLoader size={100} />}
            <div className='col-sm-12 col-md-4' style={{ boxShadow: '0 0 3px 0 #ccc', height: 'calc(70% - 0px)', padding: '2rem', overflowY: 'auto' }}>
                <h2>
                    <img height={'80px'} className='mr-2' src={slackLogo} alt="" />
                    Jira Integration</h2>
                <h3 className='mt-4'>Please While we connect your account...</h3>

            </div>

        </div>
    )
}

export default JiraIntegrationPage