import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { useNavigate } from "react-router-dom";
import useHttp from "../../Hooks/useHttp";
import AppContext from "../../Context/AppContext";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../../reducers/authSlice";
import { toast } from "react-toastify";
import { customToast } from "../../../App";
export default function Login() {
	const API = process.env.REACT_APP_API_URL;
	const { setLoading } = useContext(AppContext);
	const dispatch = useDispatch();
	const [err, setErr] = useState({})
	const navigate = useNavigate();
	const [validated, setValidated] = useState(false);
	const [type, setType] = useState("password");
	const [icon, setIcon] = useState(eyeOff);
	const { PostRequest } = useHttp();
	const handleToggle = () => {
		if (type === "password") {
			setIcon(eye);
			setType("text");
		} else {
			setIcon(eyeOff);
			setType("password");
		}
	};
	const [formData, setFormData] = useState({
		username: "",
		password: "",
		role: "researcher",
	});

	const handleChange = async (event) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};
	const handalSubmit = async (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		}
		if (form.checkValidity() === true) {
			event.preventDefault();
			event.stopPropagation();
			setLoading(true)
			if (formData.username !== "" && formData.password !== "") {
				const { data, error } = await PostRequest(
					`${API}researcher/login/`,
					{ ...formData, username: formData.username.toLocaleLowerCase() },
					{}
				);
				// console.log(data, error);
				if (error) {
					setLoading(false);
					const flattenedErrors = error.data ? Object.keys(error.data)
						.map(key => error.data[key]).toString() : '';
					customToast.error(flattenedErrors ?? "Something went wrong!", {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 5000,
					});
					setErr({ ...error.data });
				} else {
					setLoading(false)
					localStorage.clear();
					localStorage.setItem("token", data.access_token);
					localStorage.setItem("userid", data.user_data.id);
					localStorage.setItem("role", "researcher");
					localStorage.setItem("refreshToken", data?.refresh_token);
					localStorage.setItem("userInfo", JSON.stringify({ ...data, role: 'researcher' }));
					localStorage.setItem("showLockScreen", 'false');
					dispatch(setUserInfo({ ...data, role: 'researcher' }));
					navigate("/researcher/home");
				}
			}
		}
		setValidated(true);
	};

	// useEffect(() => {
	// 	const token = localStorage.getItem("token");
	// 	if (token) {
	// 		navigate("/admin/home");
	// 	}
	// });
	return (
		<>
			<Form
				className="text-start"
				noValidate
				validated={validated}
				onSubmit={handalSubmit}
			>
				<Row className="mb-3">
					<Form.Group as={Col} md="12" controlId="validationCustom03">
						<Form.Label label="username">Username</Form.Label>
						<Form.Control
							type="text"
							name="username"
							placeholder="Enter Username / Email"
							required
							onChange={handleChange}
							defaultValue={formData.username}
						/>
						<Form.Control.Feedback type="invalid">
							Please provide a valid Username.
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group
						className="mt-3 position-relative"
						as={Col}
						md="12"
						controlId="validationCustom04"
					>
						<Form.Label label="password">Password</Form.Label>
						<Form.Control
							type={type}
							placeholder="Enter Password"
							name="password"
							onChange={handleChange}
							defaultValue={formData.password}
							autoComplete="current-password"
							required
						/>
						<span
							className="flex justify-around items-center position-absolute"
							id="eyeicon"
							onClick={handleToggle}
						>
							<Icon className="absolute mr-10" icon={icon} size={25} />
						</span>
						<Form.Control.Feedback type="invalid">
							Please provide a valid Password.
						</Form.Control.Feedback>
						<Form.Control.Feedback style={{ color: "red" }}>
							{err.non_field_errors || err.detail}
						</Form.Control.Feedback>
					</Form.Group>
					<div className="checkbox mt-3">
						<Form.Check // prettier-ignore
							type="switch"
							id="custom-switch"
							label="Remember me"
						/>

						<div className="forget-password">
							<Link to="/researcher/forgotpassword">Forget Password ?</Link>
						</div>
					</div>
				</Row>
				<div className="text-center">
					<Button type="submit" className="loginbtn w-100 my-4 mb-2">
						LOGIN
					</Button>
				</div>
				<div className="mb-0 position-relative text-center">
					<p className="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3">
						or
					</p>
				</div>
				<div className="text-center">
					<Button
						onClick={() => navigate("/researcher/signup")}
						className="loginbtn w-100 mt-2 mb-4"
					>
						Signup
					</Button>
				</div>
			</Form>
		</>
	);
}
