import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "./researcherProgramTab.css";
import HTTPService from "../../../utils/axios";
import ProfileImage from "../../../components/ProfileImage";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import AppContext from "../../Context/AppContext";
import { formatDate } from "../../../utils/helperFunctions";
import Chart from "react-apexcharts";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../reducers/authSlice";
import moment from "moment";

const timeFrames = [
  { label: "7 Days", value: "7" },
  { label: "15 Days", value: "15" },
  { label: "30 Days", value: "30" },
  { label: "60 Days", value: "60" },
  { label: "All Time", value: "all" },
];
const ResearcherProgramReward = () => {
  const [stateChange, setStateChange] = useState(1);
  const [filterDay, setFilterDay] = useState("all");
  const [leaderBoard, setLeaderBoard] = useState();
  const [rewardStats, setRewardStats] = useState();
  const { invokeApi } = HTTPService();
  const { id } = useParams();
  const [loadingPage, setLoadingPage] = useState(true);
  const { setLoading } = useContext(AppContext);
  const [program, setProgram] = useState();
  const [leaderBoardMonth, setLeaderBoardMonth] = useState('all');
  const ASSET_URL = process.env.REACT_APP_ASSET_URL;
  const { userInfo } = useSelector(getUserInfo);
  const months = [
    { value: "all", label: 'All' },
    { value: moment().month(), label: 'last Month' },
  ];
  const handleFilter = (day) => {
    setFilterDay(day);
  };

  const fetchReward = () => {
    setLoading(true);
    let params = {
      user_id: userInfo?.user_data?.id
    }
    if (filterDay != 'all') {
      params.days = filterDay
    }
    invokeApi({
      method: "GET",
      url: "/rewards/stats",
      params: {
        program_id: id,
        ...params
      }
    }).then((response) => {
      setRewardStats(response);
      setLoading(false);
      setLoadingPage(false);
    }).catch((error) => {
      setLoading(false);
      setLoadingPage(false);
      console.error("Error fetching transactions data:", error);
    });
  };

  const fetchLeaderBoard = () => {
    let params = {
      program_id: id,
      user_id: userInfo?.user_data?.id
    }
    if (leaderBoardMonth!='all') {
      params.month = leaderBoardMonth
    }
    invokeApi({
      method: "GET",
      url: "widget/researcher-leader-board/",
      params
    }).then((response) => {
      setLoading(false);
      setLeaderBoard(response);
    }).catch((error) => {
      setLoading(false);
      console.error("Error fetching transactions data:", error);
    });
  };

  const fetchProgram = () => {
    invokeApi({
      method: "GET",
      url: `programs/${id}`,
    }).then((response) => {
      setProgram(response);
    }).catch((error) => {
    });
  };

  const overviewBalanceOpt = {
    series: [
      {
        data: rewardStats?.rewards_charts?.rewards ? [...rewardStats.rewards_charts?.rewards?.map(data => data.total_amount)] : [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: ["#363062"],
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 4,
          borderRadiusApplication: "around",
          // borderRadiusWhenStacked: "last",
          columnWidth: "30%",
          barHeight: "100%",
          distributed: false,
          rangeBarOverlap: true,
          rangeBarGroupRows: false,
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          dumbbellColors: undefined,
          isFunnel: false,
          isFunnel3d: true,
          colors: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: undefined,
              },
            ],
            backgroundBarColors: ["#EEEEEE"],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 4,
          },
          dataLabels: {
            position: "top",
            maxItems: 100,
            hideOverflowingLabels: true,

            total: {
              enabled: false,
              formatter: undefined,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontFamily: undefined,
                fontWeight: 600,
              },
            },
          },
        },
      },

      xaxis: {
        categories: rewardStats?.rewards_charts?.rewards ? [...rewardStats.rewards_charts?.rewards?.map(data => data.period)] : [],
        tooltip: {
          x: {
            show: false,
          },
        },
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: false,
          showDuplicates: false,
          trim: false,
          minHeight: "30px",
          maxHeight: 120,

          style: {
            colors: "#4F6488",
            fontSize: "14px",
            fontWeight: 500,

            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      yaxis: {
        min: 0,
        tickAmount: 2,
        labels: {
          formatter: function (value) {
            var val = Math.abs(value);
            return `$${val}`;
          },
          style: {
            fontSize: "14px",
            fontWeight: "500",
          },
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value) {
            return `$${value}`;
          },
          title: {
            formatter: function () {
              return '';
            }
          }
        }
      },
    },
  };
  useEffect(() => {
    if (userInfo?.user_data?.id) {
      fetchLeaderBoard();
      fetchProgram();
    }
  }, [userInfo?.user_data?.id]);

  useEffect(() => {
    if (filterDay)
      fetchReward()
  }, [filterDay]);

  useEffect(() => {
    if (leaderBoardMonth) {
      fetchLeaderBoard();
      setLoading(true)
    }

  }, [leaderBoardMonth]);

  return (
    <>
      <div className="row mt-4 programResearcher">
        <div className="col-3 researcherLeftSide">
          {loadingPage ? (
            <div
              style={{ height: "150px", width: "100%", borderRadius: "12px" }}
            >
              <Skeleton height={"50px"} width={"100%"} sx={{ mt: '10px', borderRadius: '10px' }} />
              <Skeleton height={"50px"} width={"100%"} sx={{ mt: '10px', borderRadius: '10px' }} />
            </div>
          ) : (
            <div className="partInviteBox">
              <div
                className="border-bottom"
                onClick={() => setStateChange(1)}
                style={stateChange === 1 ? { backgroundColor: 'rgb(245, 245, 251)' } : { cursor: "pointer" }}
              >
                <h5 style={stateChange === 1 ? { color: "#000" } : {}}>
                  Overview
                </h5>
              </div>
              <div
                onClick={() => setStateChange(2)}
                style={stateChange === 2 ? { backgroundColor: 'rgb(245, 245, 251)' } : { cursor: "pointer" }}
              >
                <h5 style={stateChange === 2 ? { color: "#000" } : {}}>
                  Rewards Points
                </h5>
              </div>
            </div>
          )}
        </div>
        <div className="col-9 researcherRightSide">
          {stateChange === 1 ? (
            <>
              {loadingPage ? (
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <Skeleton variant="rectangular" width={100} height={300} />
                  <Skeleton variant="rectangular" width={100} height={300} style={{ marginLeft: 10 }} />
                  <Skeleton variant="rectangular" width={100} height={300} style={{ marginLeft: 10 }} />
                  <Skeleton variant="rectangular" width={100} height={300} style={{ marginLeft: 10 }} />
                  <Skeleton variant="rectangular" width={100} height={300} style={{ marginLeft: 10 }} />
                  <Skeleton variant="rectangular" width={100} height={300} style={{ marginLeft: 10 }} />
                </Box>
              ) : (
                <>
                  <div className="row">
                    <div className="col-6">
                      <div>
                        <span>
                          <b>$50000</b> Rewards Settle All The Time
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ height: '300px' }}>
                    <div className="col-sm-12 col-md-8">
                      <Chart
                        options={overviewBalanceOpt.options}
                        series={overviewBalanceOpt.series}
                        type="bar"
                        height="100%"
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="weekly24main" style={{ width: "87%" }}>
                        <select className="weekly24" value={filterDay} onChange={e => handleFilter(e.target.value)}>
                          {timeFrames.map((data) => {
                            return <option key={data.value} value={data.value}>{data.label}</option>
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <div>
                        <p style={{ color: "#000", fontWeight: "500" }}>
                          Rewards
                        </p>
                        <h6>{rewardStats?.rewards_stats?.total_rewards}</h6>
                      </div>
                    </div>
                    <div
                      className="col-3"
                      style={{ borderLeft: "1px solid #dee2e6" }}
                    >
                      <div>
                        <p style={{ color: "#000", fontWeight: "500" }}>
                          Highest Rewards
                        </p>
                        <h6>${rewardStats?.rewards_stats?.highest_reward}</h6>
                      </div>
                    </div>
                    <div
                      className="col-3"
                      style={{ borderLeft: "1px solid #dee2e6" }}
                    >
                      <div>
                        <p style={{ color: "#000", fontWeight: "500" }}>
                          Average Reward
                        </p>
                        <h6>${rewardStats?.rewards_stats?.average_reward ? rewardStats?.rewards_stats?.average_reward.toFixed(2) : 0}</h6>
                      </div>
                    </div>
                    <div
                      className="col-3"
                      style={{ borderLeft: "1px solid #dee2e6" }}
                    >
                      <div>
                        <p style={{ color: "#000", fontWeight: "500" }}>
                          Remaining Reward Pool
                        </p>
                        <h6>${parseFloat(rewardStats?.rewards_stats?.total_rewards_pool) - parseFloat(rewardStats?.rewards_stats?.total_rewards_given)}</h6>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div>
                      <h4>Rewards History</h4>
                    </div>
                    <div>
                      <div className="table-responsive">
                        <table className="table align-items-center mb-0 roleandpermissiontable">
                          <thead>
                            <tr>
                              <th className="text-uppercase  font-weight-bolder opacity-7">
                                Researcher
                              </th>
                              <th className="text-uppercase  font-weight-bolder opacity-7">
                                Rewarded For
                              </th>

                              <th className="text-center text-uppercase  font-weight-bolder opacity-7">
                                Amount
                              </th>
                              <th className="text-center text-uppercase  font-weight-bolder opacity-7">
                                Date Rewarded
                              </th>
                              <th className="text-center text-uppercase  font-weight-bolder opacity-7">
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {rewardStats?.rewards_history && rewardStats?.rewards_history.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="rolename">
                                    <div className="d-flex">
                                      <div className="researcherProfilePhoto">
                                        <ProfileImage src={item?.researcher_details?.user_details?.display_pic} style={{ objectFit: 'cover' }} />
                                      </div>
                                      <div className="text-start d-flex align-items-center pl-3">
                                        <p className="text-sm  mb-0 px-2">
                                          {item.researcher_details?.user_details?.first_name} {" "}
                                          {item.researcher_details?.user_details?.last_name}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center">
                                      <div
                                        className={
                                          item.confirmed_priority == "P1"
                                            ? "p1Level pdiv"
                                            : item.confirmed_priority ==
                                              "P2"
                                              ? "p2Level pdiv"
                                              : item.confirmed_priority ==
                                                "P3"
                                                ? "p3Level pdiv"
                                                : item.confirmed_priority ==
                                                  "P4"
                                                  ? "p4Level pdiv"
                                                  : item.confirmed_priority ==
                                                    "P5"
                                                    ? "p5Level pdiv"
                                                    : "pdiv"
                                        }
                                      >
                                        {item.confirmed_priority}
                                      </div>
                                    </div>
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    style={{ color: "red", fontWeight: 600 }}
                                  >
                                    {item.settlement_amount}
                                  </td>
                                  <td className="align-middle text-center">
                                    {formatDate(item.created_at, 1)}
                                  </td>
                                  <td className="align-middle text-center">
                                    <div>
                                      {item.note_status === "cancelled" ? (
                                        <>
                                          <div>
                                            <div className="cancelledStatus">
                                              <b>Cancelled</b>
                                            </div>
                                          </div>
                                          <p className="text-secondary">
                                            Incorrect Amount
                                          </p>
                                        </>
                                      ) : item.note_status === "submitted" ? (
                                        <div className="completeStatus">
                                          <b>Submitted</b>
                                        </div>
                                      ) : item.note_status === "pending" ? (
                                        <>
                                          <div className="processingStatus">
                                            <b>Pending </b>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-9">
                  <div>
                    <h3>
                      Bugbusterslabs Quarterly Leaderboard
                      <span style={{ color: "#828282" }}>(Points)</span>
                    </h3>
                  </div>
                  <div className="thisQuarter">
                    <span>This Quarter is Currently Underway. </span>
                    <span style={{ color: "#3369C0" }}>
                      Join The Bug Bounty Battle for Glory.
                    </span>
                  </div>
                  <div className="mt-4">
                    <Table>
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ backgroundColor: "#F6F6F6" }}>
                            Reputation
                          </th>
                          <th>Signal</th>
                          <th>Impact</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaderBoard && leaderBoard.length > 0 ? leaderBoard.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center justify-content-center me-3">
                                  {index + 1}
                                </div>
                                <div
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    overflow: 'hidden',
                                    borderRadius: '50%'
                                  }}
                                >
                                  <ProfileImage src={ASSET_URL + item.display_pic} style={{ objectFit: 'cover' }} />
                                </div>
                                <div className="text-start">
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      color: "#446EAB",
                                      fontWeight: "500",
                                    }}
                                  >
                                    <span>
                                      {item.first_name} {" "}{item.last_name}
                                    </span>
                                    <span>({item.username})</span>
                                  </div>
                                  <div style={{ fontSize: "11px" }}>
                                    <span>{item.summary}</span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                backgroundColor: "#F6F6F6",
                                fontWeight: "600",
                                color: "#446EAB",
                                alignItems: "center",
                              }}
                            >
                              {item.reward_points}
                            </td>
                            <td
                              style={{
                                fontWeight: "600",
                                color: "#446EAB",
                                alignItems: "center",
                              }}
                            >
                              {!item.total_reports ? 0 : parseInt(item.reward_points / item.total_reports) ?? 0}
                            </td>
                            <td
                              style={{
                                fontWeight: "600",
                                color: "#446EAB",
                                alignItems: "center",
                              }}
                            >
                              {!item.valid_reports ? 0 : parseInt(item.reward_points / item.valid_reports) ?? 0}
                            </td>
                          </tr>
                        )) :
                          <tr>
                            <td colSpan="5">NO DATA</td>
                          </tr>}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="col-3">
                  <div>
                    <select className="form-select quSelect" value={leaderBoardMonth} onChange={e => setLeaderBoardMonth(e.target.value)}>
                      {months.map(option => <option key={option} value={option.value}>
                        Period: <p>{option.label}</p>
                      </option>)}
                    </select>
                  </div>
                  <div className=" researcherLastSide">
                    <div className="LastSideHeading border-bottom">
                      <h5>How it Works!</h5>
                    </div>

                    {/* <div className="LastSideMiddle border-bottom ">
                      <p>
                        {" "}
                        <b>Based on Reputation earned: </b> <br />
                        2023/10/01 - 2023/12/31{" "}
                      </p>
                    </div> */}
                    <div className="LastSideLast">
                      <ul>
                        <li>
                          <b>Reputation</b> : Gained or lost based on report
                          validity. <br /> Gain more based on the size of your
                          bounty
                        </li>
                        <li>
                          <b>Signal</b> : Average Reputation per report.
                        </li>
                        <li>
                          <b>Impact</b> : Average Reputation per bounty
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ResearcherProgramReward;
