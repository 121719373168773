import React from 'react';
import { useSelector } from 'react-redux';
import { getPermissions, getUserInfo } from '../reducers/authSlice';

const withPermission = (WrappedComponent) => {
    return (props) => {
        const permissions = useSelector(getPermissions);
        const { userInfo } = useSelector(getUserInfo);
        const moduleName = WrappedComponent.moduleName || WrappedComponent.name || 'Component';
        console.log('WrappedComponent moduleName:', moduleName);

        const hasPermission = (moduleName) => {
            const permission = permissions.find(item => item.module === moduleName);
            return permission && permission.view;
        }

        if (hasPermission(moduleName) || ['admin', 'customer', 'researcher'].includes(userInfo.role)) {
            return <WrappedComponent {...props} />;
        } else {
            return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh', fontSize: '2rem' }}>
                You don't have Permission to visit this page...
            </div>;
        }
    };
};
export default withPermission;