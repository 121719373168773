import React from "react";
import { Link } from "react-router-dom";
import downarrowdark from "../../assets/svg/downarrow.svg";

import menudata from "../menudata/menudata.json";

const Integrations = (props) => {
	const userType = props.usertype;
	const userRole = Object.entries(menudata).filter(
		([key, value]) => key === userType
	);
	return (
		<>
			<li className="nav-item dropdown dropdown-hover">
				<Link
					className="nav-link d-flex justify-content-between cursor-pointer align-items-center "
					to="#"
					role="button"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="#7F56D9"
						className="bi bi-puzzle-fill me-2"
						viewBox="0 0 16 16"
					>
						<path d="M3.112 3.645A1.5 1.5 0 0 1 4.605 2H7a.5.5 0 0 1 .5.5v.382c0 .696-.497 1.182-.872 1.469a.5.5 0 0 0-.115.118l-.012.025L6.5 4.5v.003l.003.01q.005.015.036.053a.9.9 0 0 0 .27.194C7.09 4.9 7.51 5 8 5c.492 0 .912-.1 1.19-.24a.9.9 0 0 0 .271-.194.2.2 0 0 0 .036-.054l.003-.01v-.008l-.012-.025a.5.5 0 0 0-.115-.118c-.375-.287-.872-.773-.872-1.469V2.5A.5.5 0 0 1 9 2h2.395a1.5 1.5 0 0 1 1.493 1.645L12.645 6.5h.237c.195 0 .42-.147.675-.48.21-.274.528-.52.943-.52.568 0 .947.447 1.154.862C15.877 6.807 16 7.387 16 8s-.123 1.193-.346 1.638c-.207.415-.586.862-1.154.862-.415 0-.733-.246-.943-.52-.255-.333-.48-.48-.675-.48h-.237l.243 2.855A1.5 1.5 0 0 1 11.395 14H9a.5.5 0 0 1-.5-.5v-.382c0-.696.497-1.182.872-1.469a.5.5 0 0 0 .115-.118l.012-.025.001-.006v-.003l-.003-.01a.2.2 0 0 0-.036-.053.9.9 0 0 0-.27-.194C8.91 11.1 8.49 11 8 11s-.912.1-1.19.24a.9.9 0 0 0-.271.194.2.2 0 0 0-.036.054l-.003.01v.002l.001.006.012.025c.016.027.05.068.115.118.375.287.872.773.872 1.469v.382a.5.5 0 0 1-.5.5H4.605a1.5 1.5 0 0 1-1.493-1.645L3.356 9.5h-.238c-.195 0-.42.147-.675.48-.21.274-.528.52-.943.52-.568 0-.947-.447-1.154-.862C.123 9.193 0 8.613 0 8s.123-1.193.346-1.638C.553 5.947.932 5.5 1.5 5.5c.415 0 .733.246.943.52.255.333.48.48.675.48h.238z" />
					</svg>
					Integrations
					<img
						src={downarrowdark}
						alt="down-arrow"
						className="arrow ms-1 d-lg-block d-none"
					/>
					<img
						src={downarrowdark}
						alt="down-arrow"
						className="arrow ms-1 d-lg-none d-block"
					/>
				</Link>

				<div
					className="dropdown-menu dropdown-menu-animation dropdown-md dropdown-md-responsive p-3 border-radius-lg mt-0 mt-lg-2"
					aria-labelledby="dropdownMenuBlocks"
				>
					<div className="d-none d-lg-block">
						<ul className="list-group">
							{userRole[0][1]?.integrations && userRole[0][1].integrations.submenu.map((item, index) => {
								if ((props?.overAllPermissions && props?.overAllPermissions[item.name]?.view) || ['admin','customer','researcher'].includes(userType))
									return (
										<li key={index} className="nav-item dropdown dropdown-hover dropdown-subitem list-group-item border-0 p-0">
											<Link
												className="dropdown-item py-2 ps-3 border-radius-md"
												to={item.link}
											>
												<div className="d-flex">
													<div className="icon h-10 me-3 d-flex mt-1">
														<i className={item.icon}></i>
													</div>
													<div className="w-100 d-flex align-items-center justify-content-between">
														<div>
															<p className="dropdown-header text-dark p-0">
																{item.name}
															</p>
														</div>
													</div>
												</div>
											</Link>
										</li>
									);
							})}
						</ul>
					</div>
				</div>
			</li>
		</>
	);
};

export default Integrations;
