import React from "react";
import Skeleton from "react-loading-skeleton";

export default function PtabDetailSkeleton({ customerLoad }) {
  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row p-0">
          <div className="">
            <div className="col-12 ">
              <Skeleton height={400} width={"100%"} />
            </div>
          </div>
        </div>

        <div className="row w-100" style={{ backgroundColor: "#fff" }}>
          <div className="col-8">
            <Skeleton height={1000} width={"100%"} />
          </div>
          <div className="col-4">
            <Skeleton height={1000} width={"100%"} />
          </div>
        </div>
      </div>
    </>
  );
}
