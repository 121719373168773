import React, { useEffect, useState } from "react";
import ViewInfo from "../../../common/ViewInfo";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";

import "./BaseValueStyles.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import HTTPService from "../../../../utils/axios";
import ViewInfoSkeleton from "../../../components/SkeletonLoader/ViewInfoSkeleton";
import { customToast } from "../../../../App";

export default function ViewBaseVAlue() {
	const [baseValueLoader, setBaseValueLoader] = useState(false);
	const { invokeApi } = HTTPService();
	const [baseValuedata, setBaseVAluedata] = useState({});
	const [optionVal, setOption] = useState({});

	const navigate = useNavigate();

	const { id } = useParams();

	const fetchBaseValue = () => {
		setBaseValueLoader(true)
		invokeApi({
			method: "GET",
			url: `basevalues/${id}/`,
		})
			.then((res) => {
				setBaseValueLoader(false)
				setBaseVAluedata(res);
				console.log(baseValuedata);
			})
			.catch((error) => {
				setBaseValueLoader(false)
				customToast.error("Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			});
	};
	function fetchOptions() {
		const optArr = baseValuedata?.value_options?.split(",");
		console.log("opt", optArr);
		setOption({ innerItem: [...optArr], structure: "objStr" });
	}
	const option = ["Title", "Title Code", "Options"];
	const Title = "VIEW BASE VALUES";
	const NewBaseValueDate = [
		baseValuedata?.title,
		baseValuedata?.title_code,
		optionVal && optionVal,
	];

	useEffect(() => {
		if (id) {
			fetchBaseValue();
		}
	}, [id]);

	useEffect(() => {
		if (baseValuedata?.value_options) {
			fetchOptions();
		}
	}, [baseValuedata]);

	console.log("Basevalue data", baseValuedata);

	const Breadcrumbname = {
		home: "/admin/home",
		"Master Module": "",
		"Base Values": "/admin/base-values",
		"View Base Value": "",
	};

	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row w-100 d-flex justify-content-center">
					<div className="addCategoryArea  my-4">
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div className="col-lg-6 ps-3">
								<Breadcrumb names={Breadcrumbname} />
							</div>

							<div className="button-row d-flex justify-content-end mt-0 mb-3">
								<Link
									to="/admin/base-values"
									className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
									id="adduser"
								>
									VIEW ALL
								</Link>
							</div>
						</div>

						<div className="col-12">
							<div className="card" style={{ backgroundColor: "white" }}>
								{baseValueLoader ? (
									<ViewInfoSkeleton />
								) : (<ViewInfo
									itemValue={NewBaseValueDate && NewBaseValueDate}
									option={option}
									title={Title}
								/>)
								}
								<div className=" d-flex justify-content-end p-4">
									<button
										className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
										onClick={() => navigate(-1)}
									>
										Back
									</button>
									<button
										type="button"
										className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
										onClick={() => navigate(`/admin/base-value/edit/${id}`)}
									>
										Edit
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
