import React, { useContext, useEffect, useState } from "react";
import {
    Link,
    Outlet,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import basic from "../../assets/svg/basic.svg";
import box from "../../assets/svg/box.svg";
import rocket from "../../assets/svg/rocket.svg";
import Basicinfo from "../Basicinfo";

import { FaUserCircle } from "react-icons/fa";
import ProfileImage from "../../../components/ProfileImage";
import { getUserInfo } from "../../../reducers/authSlice";
import CircularLoader from "../../CircularLoader/CircularLoader";
import AppContext from "../../Context/AppContext";
import useHttp from "../../Hooks/useHttp";
import CommonMemberEditSkeleton from "../../components/SkeletonLoader/CommonMemberEditSkeleton";
import AddModal from "../modal/AddModal";
import { useSelector } from "react-redux";

export default function EditSubCustomer() {
    const { userId } = useParams();
    const token = localStorage.getItem("token");
    const API = process.env.REACT_APP_API_URL;
    const [userData, setUserData] = useState();
    const { GetRequest } = useHttp();
    const navigate = useNavigate();
    const location = useLocation();
    const { userInfo, permissions } = useSelector(getUserInfo);
    const { loading, setLoading, memberData, setMemberData, circularLoading } =
        useContext(AppContext);
    const fetchUserData = async () => {
        setLoading(true);
        const { data, error } = await GetRequest(
            API + "customer/" + userId + "/",
            {},
            {
                Authorization: "Bearer  " + token,
            }
        );
        if (error) {
            setLoading(false);
            console.log(error);
        } else {
            setLoading(false);
            setUserData(data);
            console.log("userdata", data);
        }
    };
    useEffect(() => {
        fetchUserData();
    }, [userId]);
    const Breadcrumbname = {
        home: "/admin/home",
        Managment: "",
        'Support Customer': "/admin/supportcustomers",
        "View Support Customer": "",
    };

    return (
        <>
            {loading ? (
                <CommonMemberEditSkeleton />
            ) : (
                <>
                    {circularLoading && <CircularLoader size={100} />}
                    <div className="bg-gray-100">
                        <main className="position-relative mt-0">
                            <div
                                className="container-fluid pt-4  "
                                style={{ background: "#f5f5fb" }}
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-3 col-sm-8">
                                        <div className="nav-wrapper position-relative end-0">
                                            <ul className="nav nav-pills nav-fill p-1" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <Link
                                                        className={
                                                            location.pathname ===
                                                                `/${userInfo.role}/supportcustomers/edit/` + userId
                                                                ? "nav-link mb-0 px-0 py-1 active"
                                                                : "nav-link mb-0 px-0 py-1"
                                                        }
                                                        to={`/${userInfo.role}/supportcustomers/edit/${userId}`}
                                                        aria-selected="false"
                                                    >
                                                        Profile
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-9 col-sm-4 d-flex justify-content-end mt-0 mb-3">
                                        <Link
                                            className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                                            onClick={() => navigate(-1)}
                                        >
                                            Back
                                        </Link>
                                    </div>
                                </div>
                                <div className="container-fluid my-3 py-3 mb-0 p-0">
                                    <div className="row mb-5">
                                        <div className="col-12 mb-4">
                                            {/* <!-- Card Profile --> */}
                                            <div className="card card-body" id="profile">
                                                <div className="row justify-content-center align-items-center">
                                                    <div className="col-sm-auto col-4">
                                                        <div className="avatar avatar-xl position-relative">
                                                            <ProfileImage
                                                                src={userData?.display_pic}
                                                                errorIcon={
                                                                    <FaUserCircle
                                                                        style={{
                                                                            fontSize: "3rem",
                                                                            color: "#7f56d9 ",
                                                                        }}
                                                                    />
                                                                }
                                                                className="w-100 border-radius-lg shadow-sm"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-auto col-8 my-auto">
                                                        <div className="h-100">
                                                            <h5 className="mb-1 font-weight-bolder">
                                                                {userData?.first_name} {userData?.last_name}
                                                            </h5>
                                                            <p className="mb-0 font-weight-bold text-sm">
                                                                {userData?.user_id} ({userData?.username})
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="card position-sticky top-1">
                                                <ul className="nav flex-column bg-white border-radius-lg p-3">
                                                    <li className="nav-item">
                                                        <HashLink
                                                            smooth
                                                            className="nav-link text-body"
                                                            data-scroll=""
                                                            to={`/${userInfo.role}/supportcustomers/edit/${userId}/basicinfo/#profile`}
                                                        >
                                                            <div className="icon me-2">
                                                                <img src={rocket} alt="" />
                                                            </div>
                                                            <span className="text-sm">Profile</span>
                                                        </HashLink>
                                                    </li>
                                                    <li className="nav-item pt-2">
                                                        <HashLink
                                                            className="nav-link text-body"
                                                            data-scroll=""
                                                            to={`/${userInfo.role}/supportcustomers/edit/${userId}/basicinfo/#basic-info`}
                                                        >
                                                            <div className="icon me-2">
                                                                <img src={basic} />
                                                            </div>
                                                            <span className="text-sm">Basic Info</span>
                                                        </HashLink>
                                                    </li>
                                                    <li className="nav-item pt-2">
                                                        <HashLink
                                                            className="nav-link text-body"
                                                            data-scroll=""
                                                            to={`/${userInfo.role}/supportcustomers/edit/${userId}/basicinfo/#password`}
                                                        >
                                                            <div className="icon me-2">
                                                                <img src={box} />
                                                            </div>
                                                            <span className="text-sm">Change Password</span>
                                                        </HashLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-9 mt-lg-0 mt-4">
                                            {location.pathname ===
                                                `/${userInfo.role}/supportcustomers/edit/` + userId ? (
                                                <Basicinfo userData={userData} />
                                            ) : (
                                                <Outlet />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </>
            )}
            {memberData.basicinfo === true && (
                <AddModal
                    cancelBtn={"Cancel"}
                    submitBtn={"SUBMIT"}
                    createAddTopic={"Update Basic Info"}
                    confirmText={"Update Basic Info"}
                    isOpen={memberData.openmodal}
                    onSubmit={memberData.handlesubmit}
                    onClose={() => setMemberData({ openmodal: false })}
                />
            )}

            {memberData.changePassword === true && (
                <AddModal
                    cancelBtn={"Cancel"}
                    submitBtn={"SUBMIT"}
                    createAddTopic={"Update Password"}
                    confirmText={"Update Password"}
                    isOpen={memberData.openmodal}
                    onSubmit={memberData.handlesubmit}
                    onClose={() => setMemberData({ openmodal: false })}
                />
            )}
            {memberData.payment === true && (
                <AddModal
                    cancelBtn={"Cancel"}
                    submitBtn={"SUBMIT"}
                    createAddTopic={"Add Payment"}
                    confirmText={"Add Payment"}
                    isOpen={memberData.openmodal}
                    onSubmit={memberData.handlesubmit}
                    onClose={() => setMemberData({ openmodal: false })}
                />
            )}
            {memberData.social === true && (
                <AddModal
                    cancelBtn={"Cancel"}
                    submitBtn={"SUBMIT"}
                    createAddTopic={"Add Social Link"}
                    confirmText={"Add Social Link"}
                    isOpen={memberData.openmodal}
                    onSubmit={memberData.handlesubmit}
                    onClose={() => setMemberData({ openmodal: false })}
                />
            )}
        </>
    );
}
