import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import { Link, useParams, useNavigate } from "react-router-dom";

import "./FaqCategoryStyles.css";

import useHttp from "../../../Hooks/useHttp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddModal from "../../../common/modal/AddModal";
import AppContext from "../../../Context/AppContext";
import HTTPService from "../../../../utils/axios";
import { customToast } from "../../../../App";

export default function EditFaqCategory() {
	const { setCircularLoading } = useContext(AppContext);
	const [openAddModal, setOpenAddModal] = useState(false);

	const { invokeApi } = HTTPService();
	const { id } = useParams();
	const navigate = useNavigate();
	const [mainCatName, setMainCatName] = useState("");
	const [categoryCode, setCategoryCode] = useState("");
	const [sequence, setSequence] = useState();
	const [type, setType] = useState("");
	const [selectOptType, setSelectOptType] = useState();



	const handleSubmit = async () => {
		setOpenAddModal(false);
		setCircularLoading(true);
		const param = {
			category_title: mainCatName,
			category_code: categoryCode,
			sequence: sequence,
			user_type: type,
			category_type: "main_category",
			cat_status: "active",
			user_id: "test_user_id_1",
			is_deleted: false,
			parent_category_id: "",
		};

		invokeApi({
			method: "PUT",
			url: `faq_category/${id}/`,
			data: param,
		})
			.then((res) => {
				setCircularLoading(false);

				customToast.success("FAQ-Category update successfully", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
				navigate("/admin/faq-category");
			})
			.catch((error) => {
				setCircularLoading(false);
				customToast.error("Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			});


	};

	const fetchFaqCatData = async () => {
		invokeApi({
			method: "GET",
			url: `faq_category/${id}/`,
		})
			.then((res) => {
				setMainCatName(res.category_title);
				setSequence(res.sequence);
				setCategoryCode(res.category_code);
				setType(res.user_type);
				console.log(res);
			})
			.catch((error) => {
				customToast.error("Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			});
	};



	const fetchValueOpt = async () => {
		invokeApi({
			method: "GET",
			url: `basevalues/?title_code=user_type`,
		})
			.then((res) => {
				setSelectOptType(res);
			})
			.catch((error) => {
				customToast.error("Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			});
	};



	useEffect(() => {
		fetchValueOpt();
		fetchFaqCatData();
	}, []);

	const Breadcrumbname = {
		home: "/admin/home",
		"Master Module": "",
		"FAQ Category": "/admin/faq-category",
		"Edit Main Category": "",
	};
	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row w-100 d-flex justify-content-center">
					<div className="addCategoryArea  my-4">
						<div className="d-flex justify-content-between">
							<div className="">
								<Breadcrumb names={Breadcrumbname} />
							</div>
							<div className=" d-flex justify-content-end mt-0 mb-3">
								<Link
									to="/admin/faq-category"
									className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
									id="adduser"
								>
									VIEW ALL
								</Link>
							</div>
						</div>
						<div className="col-12">
							<div className="card">
								<div className="row">
									<div className="card mt-0" id="basic-info">
										<div className="card-header">
											<h5>EDIT FAQ MAIN CATEGORY</h5>
										</div>
										<div className="card-body pt-0">
											<div className="row">
												<div className="col-6">
													<label className="form-label ">Type</label>

													<select
														className="form-select"
														name="choices-gender"
														id="choices-gender"
														value={type}
														onChange={(e) => setType(e.target.value)}
													>
														{selectOptType &&
															selectOptType?.results[0].value_options.length >
															0 &&
															selectOptType?.results[0].value_options
																?.split(",")
																.map((item, index) => (
																	<option
																		key={index}
																		value={item.replace(/ /g, "")}
																	>
																		{item}
																	</option>
																))}
													</select>
												</div>
											</div>

											<div className="row">
												<div className="col-sm-6">
													<label className="form-label mt-4">
														Main Category
													</label>
													<div className="input-group ">
														<input
															className="form-control"
															placeholder="Enter Main Category"
															type="text"
															value={mainCatName && mainCatName}
															onChange={(e) => setMainCatName(e.target.value)}
														/>
													</div>
												</div>
												<div className="col-6">
													<label className="form-label mt-4">
														Category Code
													</label>

													<div className="input-group">
														<input
															id="firstName"
															name="firstName"
															className="form-control"
															type="text"
															placeholder="Enter Category Code"
															required="required"
															value={categoryCode && categoryCode}
															onChange={(e) => setCategoryCode(e.target.value)}
														/>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-6">
													<label className="form-label mt-4">Sequence</label>
													<div className="input-group ">
														<input
															className="form-control"
															placeholder="Enter Sequence"
															type="number"
															value={sequence && sequence}
															onChange={(e) => setSequence(e.target.value)}
														/>
													</div>
												</div>
											</div>
											<div className=" d-flex justify-content-end">
												<Link
													to="/admin/faq-category"
													className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
												>
													Cancel
												</Link>
												<button
													onClick={() => setOpenAddModal(true)}
													className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
												>
													Update Category
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AddModal
				cancelBtn={"Cancel"}
				submitBtn={"SUBMIT"}
				createAddTopic={"Edit Faq Category"}
				confirmText={"Edit The Faq Category"}
				isOpen={openAddModal}
				onSubmit={handleSubmit}
				onClose={() => setOpenAddModal(false)}
			/>
		</>
	);
}
