import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import "./admindashbord.css";
import HTTPService from "../../../utils/axios";
import { getUserInfo } from "../../../reducers/authSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { debounce, formatDate } from "../../../utils/helperFunctions";
import { MenuItem, Select, Tooltip } from "@mui/material";
import AppContext from "../../Context/AppContext";
import CommonTable from "../../common/Table/CommonTable";
import { customToast } from "../../../App";

const searchBy = [
  { name: "Transaction ID", value: "transactions_id" },
  { name: "Wallet ID", value: "Wallet_kyc_id" },
  { name: "User ID", value: "user_id" },
];

const statusOrders = [
  {
    label: "Select Status",
    value: "Select Status",
  },
  {
    label: "Success",
    value: "success",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

const transactionTypes = [
  {
    label: "All Transactions",
    value: "All Transactions",
  },
  { label: "Debit", value: "debit" },
  { label: "Credit", value: "credit" },
];

const tableColumn = [
  "Transaction ID",
  "Date",
  "Type",
  "Description",
  { label: "Wallet ID", align: "center" },
  { label: "User Details", align: "center" },
  { label: "Amount", align: "center" },
  { label: "Fees", align: "center" },
  "Total",
  "Status",
];

export default function ResearcherTransaction() {
  const Breadcrumbname = {
    home: "/researcher/home",
    Payments: "",
    Earnings: "",
    Dashboard: "",
  };
  const { invokeApi } = HTTPService();
  const { userInfo, userAccData } = useSelector(getUserInfo);
  const [transactionsList, setTransactionsList] = useState([]);
  const [earningData, setEarningData] = useState();
  const [programType, setProgramType] = useState("All Transactions");
  const [sortBy, setSortBy] = useState("default");
  const [filteredStatus, setFilteredStatus] = useState("Select Status");
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { setLoading, loading } = useContext(AppContext);
  const navigate = useNavigate();
  const fetchTransactions = () => {
    let params = { user_id: userInfo?.user_data?.id };
    let url = `transactions?page=${currentPage}&page_size=25`;
    if (programType !== "All Transactions" && programType !== "") {
      params.txn_type = programType;
    }
    // if (sortBy !== "default" && programType !== "") {
    //   params.sort_by = sortBy;
    // }
    if (filteredStatus !== "" && filteredStatus !== "Select Status") {
      params.status = filteredStatus;
    }
    if (searchQuery && searchQuery.length > 0) {
      params[searchValue] = searchQuery;
    }
    invokeApi({
      method: "GET",
      // url: "transactions",
      url,
      params,
    })
      .then((response) => {
        setTransactionsList(response);
      })
      .catch((error) => {
        console.error("Error fetching transactions data:", error);
      });
  };
  const fetchEarnings = () => {
    invokeApi({
      method: "GET",
      url: "payments/earnings",
      params: {
        user_id: userInfo?.user_data?.id,
      },
    })
      .then((res) => {
        setEarningData(res);
      })
      .catch((error) => {
        customToast.error("Unable to fetch earning data", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      // setCurrentPage(1);
    }, 800),
    []
  );
  const handleSearch = (e) => {
    setInputValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    if (programType && sortBy) {
      fetchTransactions();
    }
  }, [programType, sortBy, filteredStatus, searchQuery]);

  useEffect(() => {
    if (userInfo?.user_data?.id) {
      fetchTransactions();
      fetchEarnings();
    }
  }, [userInfo]);

  useEffect(() => {
    const selectedOption = searchBy.find((opt) => searchValue === opt.value);
    setSearchPlaceHolder(
      selectedOption && selectedOption.name ? selectedOption.name : ""
    );
  }, [searchValue]);

  const handleSubmission = (item) => {
    if (item.submission_id)
      navigate(`/researcher/submissions/view/${item.submission_id}`);
  };
  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-12 mt-4">
            <div>
              <p
                style={{
                  marginBottom: "4px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Available Funds:
              </p>
              <div className="card p-3">
                <div className="d-flex align-items-baseline mb-3">
                  <div>
                    <span
                      style={{
                        color: "rgb(184, 185, 189)",
                        fontSize: "13px",
                      }}
                    >
                      Balance Available for Use
                    </span>
                  </div>
                  <Tooltip title="Funds Available in Researcher(s) Wallet">
                    <i
                      className="fa fa-question-circle ms-3"
                      aria-hidden="true"
                    ></i>
                  </Tooltip>
                </div>

                <div className="mb-1 d-flex justify-content-between">
                  <h3 className="mb-0 " style={{ fontSize: "23px" }}>
                    $ {earningData?.total_wallet_balance ?? 0}
                  </h3>
                  <div>
                    <button
                      style={{
                        padding: "10px",
                        fontSize: "14px",
                        backgroundColor: "#7F56D9",
                        color: "#fff",
                      }}
                    >
                      Withdraw Balance
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-12 mt-4">
            <div>
              <p
                style={{
                  marginBottom: "4px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Future Payments:
              </p>
              <div className="card p-3">
                <div className="d-flex align-items-baseline mb-3">
                  <div>
                    <span
                      style={{
                        color: "rgb(184, 185, 189)",
                        fontSize: "13px",
                      }}
                    >
                      Payments being Cleared
                    </span>
                  </div>
                  <Tooltip title="Payments being Cleared">
                    <i
                      className="fa fa-question-circle ms-3"
                      aria-hidden="true"
                    ></i>
                  </Tooltip>
                </div>

                <div className="mb-3">
                  <h3 className="mb-0 " style={{ fontSize: "23px" }}>
                    $ {earningData?.payment_towards_submission ?? 0}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-12 mt-4">
            <div>
              <p
                style={{
                  marginBottom: "4px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Last Payment:
              </p>
              <div className="card p-3">
                <div className="d-flex align-items-baseline mb-3">
                  <div>
                    <span
                      style={{
                        color: "rgb(184, 185, 189)",
                        fontSize: "13px",
                      }}
                    >
                      Payment Deposited
                    </span>
                  </div>
                  <Tooltip title="Payment Deposited">
                    <i
                      className="fa fa-question-circle ms-3"
                      aria-hidden="true"
                    ></i>
                  </Tooltip>
                </div>

                <div className="mb-3">
                  <h3 className="mb-0 " style={{ fontSize: "23px" }}>
                    $ {earningData?.payment_deposited ?? 0}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-12 mt-4">
            <div>
              <p
                style={{
                  marginBottom: "4px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Withdrawls:
              </p>
              <div className="card p-3">
                <div className="d-flex align-items-baseline mb-3">
                  <div>
                    <span
                      style={{
                        color: "rgb(184, 185, 189)",
                        fontSize: "13px",
                      }}
                    >
                      Earnings Withdrawn to Date
                    </span>
                  </div>
                  <Tooltip title="Earnings Withdrawn to Date">
                    <i
                      className="fa fa-question-circle ms-3"
                      aria-hidden="true"
                    ></i>
                  </Tooltip>
                </div>

                <div className="mb-3">
                  <h3 className="mb-0 " style={{ fontSize: "23px" }}>
                    $ {earningData?.earnings_withdrawn ?? 0}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 row d-flex justify-content-between">
          <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-left align-items-center">
            <div className="col-12 col-md-5 me-md-4 mb-3 mb-md-0">
              <Select
                fullWidth
                value={programType}
                onChange={(e) => setProgramType(e.target.value)}
                size="small"
              >
                {transactionTypes &&
                  transactionTypes.map((data) => (
                    <MenuItem key={data.label} value={data.value}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>{data.label}</div>
                      </div>
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <div className="col-12 col-md-5">
              <Select
                fullWidth
                value={filteredStatus}
                onChange={(e) => setFilteredStatus(e.target.value)}
                size="small"
              >
                {statusOrders &&
                  statusOrders.map((data) => (
                    <MenuItem key={data.value} value={data.value}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>{data.label}</div>
                      </div>
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center mt-3 mt-md-0">
            <div className="search-container w-100">
              <div className="row">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                  {loading ? (
                    <Skeleton height={40} />
                  ) : (
                    <select
                      name=""
                      className="form-select"
                      id=""
                      value={searchValue && searchValue}
                      onChange={(e) => handleSearchValue(e)}
                    >
                      <option value="">Select Search Option</option>
                      {searchBy.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center position-relative">
                  {loading ? (
                    <Skeleton height={40} width={250} />
                  ) : (
                    <>
                      <input
                        type="text"
                        placeholder={`Search by ${searchPlaceHolder}`}
                        value={inputValue}
                        onChange={handleSearch}
                        disabled={searchPlaceHolder ? false : true}
                        className="form-control"
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: 0,
                          zIndex: 100,
                          padding: "15px",
                        }}
                      >
                        <i className="fas fa-search" aria-hidden="true"></i>
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row my-4">
          {/* {!loading && <CommonTableSkeletonLoader card={8}/>} */}
          <div className="col-12">
            <CommonTable
              column={tableColumn}
              data={transactionsList?.results}
              totalPages={transactionsList?.total_pages ?? 1}
              totalData={transactionsList?.total_records}
              // filterList={filterColumn}
              // filterType={filterType}
              // setFilterType={setFilterType}
              // filterValue={filterValue}
              // setFilterValue={setFilterValue}
              // searchBar={true}
              itemPerPg={25}
              searchBy={searchBy}
              setTable={1}
              card={7}
              // searchValue={searchValue}
              // setSearchValue={setSearchValue}
              // searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              // searchQuery={searchQuery}
              // setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            >
              {transactionsList?.results &&
                transactionsList?.results?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {" "}
                      <p className="text-sm text-secondary mb-0 px-2">
                        {item.transactions_id}
                      </p>
                    </td>
                    <td>
                      {" "}
                      <p className="text-sm text-secondary mb-0 px-2">
                        {formatDate(item.created_at, 1)}
                      </p>
                    </td>
                    <td>
                      <div className="d-flex flex-column justify-content-center">
                        <h6 className="mb-0 text-sm text-capitalize">
                          {item.txn_type}
                        </h6>
                      </div>
                    </td>
                    <td
                      className="text-start"
                      style={{
                        width: "300px",
                        overflow: "hidden",
                        whiteSpace: "normal",
                      }}
                    >
                      <div
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "3em",
                        }}
                        onClick={() => handleSubmission(item)}
                      >
                        {item.description ? (
                          <p className="text-sm text-secondary mb-2">
                            {item.description}
                          </p>
                        ) : (
                          "-"
                        )}
                        {item?.user_details?.role === "researcher" &&
                          item.txn_type === "debit" && (
                            <div>Transfer to Bank</div>
                          )}
                        {item?.user_details?.role === "researcher" &&
                          item.txn_type === "credit" && (
                            <div>
                              {item?.submission_details?.submission_title}
                            </div>
                          )}
                        {item?.user_details?.role === "customer" &&
                          item.txn_type === "debit" && (
                            <div className="textBalance">
                              {item?.submission_details?.submission_title}
                            </div>
                          )}
                        {item?.user_details?.role === "customer" &&
                          item.txn_type === "credit" && (
                            <div>Wallet Refilled</div>
                          )}
                      </div>
                      <div className="d-flex justify-content-start mt-2">
                        {item?.submission_details?.priority && (
                          <div
                            className={
                              item?.submission_details?.priority == "P1"
                                ? "taxonomyDiv p1Level me-2"
                                : item?.submission_details?.priority == "P2"
                                  ? "taxonomyDiv p2Level me-2"
                                  : item?.submission_details?.priority == "P3"
                                    ? "taxonomyDiv p3Level me-2"
                                    : item?.submission_details?.priority == "P4"
                                      ? "taxonomyDiv p4Level me-2"
                                      : "taxonomyDiv p5Level me-2"
                            }
                            style={{ padding: "2px !important" }}
                          >
                            {" "}
                            <p className="text-sm text-secondary   text-dark p-2 ">
                              {item?.submission_details?.priority}
                            </p>
                          </div>
                        )}
                        {item?.submission_details?.severity && (
                          <p
                            className="taxonomyDiv text-sm text-secondary mb-0 text-dark p-2 "
                            style={{
                              padding: "2px 10px 2px !important",
                            }}
                          >
                            {item?.submission_details?.severity}
                          </p>
                        )}
                      </div>
                    </td>

                    <td>
                      <p className="text-sm text-secondary mb-0">
                        {item?.wallet_details?.Wallet_kyc_id}
                      </p>
                    </td>
                    <td>
                      <div className="px-2 py-1" style={{ textAlign: "start" }}>
                        <p className="text-sm text-secondary mb-0 text-capitalize">
                          {item?.user_details?.role}
                        </p>
                        <div style={{ fontSize: "14px" }}>
                          {item?.user_details?.user_id}
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="mb-0 text-sm">
                            {item?.user_details?.first_name}{" "}
                            {item?.user_details?.last_name}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td className="align-middle text-center">
                      <div className="d-flex flex-column ">
                        {item.status === "Failed" ? (
                          <h6 className="mb-0 text-sm text-danger">
                            {item.amount}
                          </h6>
                        ) : (
                          <h6
                            className="mb-0 text-sm "
                            style={
                              item.txn_type == "debit"
                                ? { color: "#880015" }
                                : { color: "#82d616" }
                            }
                          >
                            {item.amount}
                          </h6>
                        )}
                      </div>
                    </td>
                    <td className="align-middle text-center ">
                      <div className="d-flex flex-column ">
                        <h6
                          className="mb-0 text-sm"
                          style={
                            item.txn_type == "debit"
                              ? { color: "#880015" }
                              : { color: "#82d616" }
                          }
                        >
                          {item.processing_fees ? item.processing_fees : "-"}
                        </h6>
                      </div>
                    </td>
                    <td className="align-middle text-center">
                      <div className="d-flex flex-column ">
                        <h6 className="mb-0 text-sm">{item.total}</h6>
                      </div>
                    </td>

                    <td className="align-middle text-center">
                      {item.status === "success" ? (
                        <span className="badge bg-success text-white text-capitalize">
                          Success
                        </span>
                      ) : item.status === "pending" ? (
                        <span className="badge bg-info text-white text-capitalize">
                          Pending
                        </span>
                      ) : (
                        <span className="badge bg-danger text-white text-capitalize">
                          Failed
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
            </CommonTable>
          </div>
        </div>

        {/* <div className="row my-4">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table align-items-center mb-0 adminDashboardTable">
                  <thead>
                    <tr>
                      <th className="text-uppercase  font-weight-bolder opacity-7">
                        Transation ID
                      </th>
                      <th className="text-uppercase  font-weight-bolder opacity-7">
                        Date
                      </th>
                      <th className="text-uppercase  font-weight-bolder opacity-7">
                        Type
                      </th>
                      <th className=" text-uppercase  font-weight-bolder opacity-7">
                        Description
                      </th>
                      <th className="text-uppercase  font-weight-bolder opacity-7 ps-2">
                        Wallet ID
                      </th>
                      <th className="text-uppercase  font-weight-bolder opacity-7 ps-2">
                        User Details
                      </th>
                      <th className=" text-uppercase  font-weight-bolder opacity-7">
                        Amount
                      </th>
                      <th className=" text-uppercase  font-weight-bolder opacity-7">
                        Fees
                      </th>
                      <th className=" text-uppercase  font-weight-bolder opacity-7">
                        Total
                      </th>
                      <th className=" text-uppercase  font-weight-bolder opacity-7">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionsList &&
                      transactionsList?.results?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {" "}
                            <p className="text-sm text-secondary mb-0 px-2">
                              {item.transactions_id}
                            </p>
                          </td>
                          <td>
                            {" "}
                            <p className="text-sm text-secondary mb-0 px-2">
                              {formatDate(item.created_at, 1)}
                            </p>
                          </td>
                          <td>
                            <div className="d-flex flex-column justify-content-center">
                              <h6 className="mb-0 text-sm text-capitalize">
                                {item.txn_type}
                              </h6>
                            </div>
                          </td>
                          <td
                            className="text-start"
                            style={{
                              width: "300px",
                              overflow: "hidden",
                              whiteSpace: "normal",
                            }}
                          >
                            <div
                              style={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxHeight: "3em",
                              }}
                              onClick={() => handleSubmission(item)}
                            >
                              {item.description ? (
                                <p className="text-sm text-secondary mb-2">
                                  {item.description}
                                </p>
                              ) : (
                                "-"
                              )}
                              {item?.user_details?.role === "researcher" &&
                                item.txn_type === "debit" && (
                                  <div>Transfer to Bank</div>
                                )}
                              {item?.user_details?.role === "researcher" &&
                                item.txn_type === "credit" && (
                                  <div>
                                    {item?.submission_details?.submission_title}
                                  </div>
                                )}
                              {item?.user_details?.role === "customer" &&
                                item.txn_type === "debit" && (
                                  <div className="textBalance">
                                    {item?.submission_details?.submission_title}
                                  </div>
                                )}
                              {item?.user_details?.role === "customer" &&
                                item.txn_type === "credit" && (
                                  <div>Wallet Refilled</div>
                                )}
                            </div>
                            <div className="d-flex justify-content-start mt-2">
                              {item?.submission_details?.priority && (
                                <div
                                  className={
                                    item?.submission_details?.priority == "P1"
                                      ? "taxonomyDiv p1Level me-2"
                                      : item?.submission_details?.priority ==
                                        "P2"
                                      ? "taxonomyDiv p2Level me-2"
                                      : item?.submission_details?.priority ==
                                        "P3"
                                      ? "taxonomyDiv p3Level me-2"
                                      : item?.submission_details?.priority ==
                                        "P4"
                                      ? "taxonomyDiv p4Level me-2"
                                      : "taxonomyDiv p5Level me-2"
                                  }
                                  style={{ padding: "2px !important" }}
                                >
                                  {" "}
                                  <p className="text-sm text-secondary   text-dark p-2 ">
                                    {item?.submission_details?.priority}
                                  </p>
                                </div>
                              )}
                              {item?.submission_details?.severity && (
                                <p
                                  className="taxonomyDiv text-sm text-secondary mb-0 text-dark p-2 "
                                  style={{ padding: "2px 10px 2px !important" }}
                                >
                                  {item?.submission_details?.severity}
                                </p>
                              )}
                            </div>
                          </td>
                          <td>
                            <p className="text-sm text-secondary mb-0">
                              {item?.wallet_details?.Wallet_kyc_id}
                            </p>
                          </td>
                          <td>
                            <div
                              className="px-2 py-1"
                              style={{ textAlign: "start" }}
                            >
                              <p className="text-sm text-secondary mb-0 text-capitalize">
                                {item?.user_details?.role}
                              </p>
                              <div style={{ fontSize: "14px" }}>
                                {item.user_details?.user_id}
                                
                              </div>
                              <div className="d-flex flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">
                                  {item?.user_details?.first_name}{" "}
                                  {item?.user_details?.last_name}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle text-center">
                            <div className="d-flex flex-column ">
                              {item.status === "Failed" ? (
                                <h6 className="mb-0 text-sm text-danger">
                                  {item.amount}
                                </h6>
                              ) : (
                                <h6
                                  className="mb-0 text-sm"
                                  style={
                                    item.txn_type == "debit"
                                      ? { color: "#880015" }
                                      : { color: "#82d616" }
                                  }
                                >
                                  {item.amount}
                                </h6>
                              )}
                            </div>
                          </td>
                          <td className="align-middle text-center">
                            <div className="d-flex flex-column ">
                              <h6
                                className="mb-0 text-sm"
                                style={
                                  item.txn_type == "debit"
                                    ? { color: "#880015" }
                                    : { color: "#82d616" }
                                }
                              >
                                {item.processing_fees
                                  ? item.processing_fees
                                  : "-"}
                              </h6>
                            </div>
                          </td>
                          <td className="align-middle text-center">
                            <div className="d-flex flex-column ">
                              <h6 className="mb-0 text-sm">{item.total}</h6>
                            </div>
                          </td>

                          <td className="align-middle text-center">
                            {item.status === "success" ? (
                              <span className="badge bg-success text-white text-capitalize">
                                Success
                              </span>
                            ) : item.status === "pending" ? (
                              <span className="badge bg-info text-white text-capitalize">
                                Pending
                              </span>
                            ) : (
                              <span className="badge bg-danger text-white text-capitalize">
                                Failed
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
