import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo, setPermission } from "../../reducers/authSlice";
import CircularLoader from "../CircularLoader/CircularLoader";
import AppContext from "../Context/AppContext";
import HTTPService from "../../utils/axios";
import { toast } from "react-toastify";
import { customToast } from "../../App";

export default function Login({ subAdmin }) {
  const { setLoading, loading } = useContext(AppContext);
  const [err, setError] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const { invokeApi } = HTTPService();
  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    role: subAdmin ? "sub_admin" : "admin",
  });

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handalSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      if (formData.username !== "" && formData.password !== "") {
        setLoading(true);
        let url = subAdmin ? "sub_admin/login/" : "user/login/";
        invokeApi({
          method: "POST",
          url,
          data: { ...formData, username: formData.username.toLocaleLowerCase() },
        }).then((data) => {
          setLoading(false);
          console.log("dataTest", data);
          localStorage.clear();
          dispatch(
            setUserInfo({ ...data, role: subAdmin ? "sub_admin" : "admin" })
          );
          if (subAdmin)
            dispatch(setPermission([...data.role.permissions]));
          localStorage.setItem("token", data.access_token);
          localStorage.setItem("refreshToken", data?.refresh_token);
          localStorage.setItem("userid", data.user_data.id);
          localStorage.setItem("showLockScreen", 'false');
          // localStorage.setItem("role", data.user_data.role);
          localStorage.setItem("role", subAdmin ? "sub_admin" : "admin");
          if (data?.role?.permissions)
            localStorage.setItem("rolePermissions", JSON.stringify(data.role.permissions));
          localStorage.setItem(
            "userInfo",
            JSON.stringify({
              ...data,
              role: subAdmin ? "sub_admin" : "admin",
              permissions: data?.role?.permissions ?? [],
            })
          );
          navigate("/admin/home");
        }).catch((error) => {
          setLoading(false);

          setError({ ...error.data });
          const flattenedErrors = error.data ? Object.keys(error.data)
            .map(key => error.data[key]).toString() : '';
          customToast.error(flattenedErrors ?? "Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        });
      }
    }
    setValidated(true);
  };

  return (
    <>
      <Form
        className="text-start"
        noValidate
        validated={validated}
        onSubmit={handalSubmit}
      >
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom03">
            <Form.Label label="username">Username</Form.Label>
            <Form.Control
              type="text"
              name="username"
              placeholder="Enter Username / Email"
              required
              onChange={handleChange}
              defaultValue={formData.username}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid Username.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            className="mt-3 position-relative"
            as={Col}
            md="12"
            controlId="validationCustom04"
          >
            <Form.Label label="password">Password</Form.Label>
            <Form.Control
              type={type}
              placeholder="Enter Password"
              name="password"
              onChange={handleChange}
              defaultValue={formData.password}
              autoComplete="current-password"
              required
            />
            <span
              className="flex justify-around items-center position-absolute"
              id="eyeicon"
              onClick={handleToggle}
            >
              <Icon className="absolute mr-10" icon={icon} size={25} />
            </span>
            <Form.Control.Feedback type="invalid">
              Please provide a valid Password.
            </Form.Control.Feedback>

            <Form.Control.Feedback style={{ color: "red" }}>
              {err.non_field_errors || err.detail}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="checkbox mt-3">
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              label="Remember me"
            />

            <div className="forget-password">
              <Link to="/admin/forgotpassword">Forget Password ?</Link>
            </div>
          </div>
        </Row>
        <div className="text-center">
          <Button type="submit" className="loginbtn w-100 my-4 mb-2">
            LOGIN
          </Button>
        </div>
        {/* <div className="mb-0 position-relative text-center">
          <p className="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3">
            or
          </p>
        </div> */}
        {/* <div className="text-center">
          <Button type="submit" className="loginbtn w-100 mt-2 mb-4">
            Signup
          </Button>
        </div> */}
      </Form>
    </>
  );
}
