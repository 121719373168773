import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import "./payments.css";
import walletDashboard from "../../assets/img/wallet-solid.svg";
import { getUserInfo } from "../../../reducers/authSlice";
import { useSelector } from "react-redux";
import moment from "moment";
import useHttp from "../../Hooks/useHttp";
import { Tooltip } from "@mui/material";

export default function CustomerKyc() {
	const Breadcrumbname = {
		Home: "/customer/home",
		Payment: "",
		KYC: "",
		Dashboard: "",
	};
	const userInfo = useSelector(getUserInfo);
	const API = process.env.REACT_APP_API_URL;
	const { GetRequest } = useHttp();
	const storedToken = localStorage.getItem("token");
	const [walletData, setWalletData] = useState({});

	const fetchUser = async () => {
		const { data, error } = await GetRequest(
			API + `wallets/${userInfo.userAccData.wallet_id}/`,
			{},
			{
				Authorization: "Bearer " + storedToken,
				"Content-Type": "multipart/form-data",
			}
		);

		if (error) {
			// handle error
			return null;
		}

		if (data) {
			setWalletData(data);
		}
		return null;
	};
	console.log("--->>>", userInfo);
	useEffect(() => {
		if (userInfo?.userAccData?.wallet_id) {
			fetchUser();
		}
	}, [userInfo]);

	return (
		<>
			<main className="main-content position-relative height-vh-100 border-radius-lg ">
				<div className="container-fluid py-4 pb-0">
					<div className="row">
						<div className="col-lg-6 ps-3">
							<Breadcrumb names={Breadcrumbname} />
						</div>
					</div>

					<div className="body-part">
						{userInfo.userAccData.wallet_id ? (
							<div className="right-part">
								<div className="wallet-details">
									<h6>Wallet details</h6>
									<img
										src={walletDashboard}
										className="wallet-img"
										alt="walllet pic"
									/>
									<div className="kyc-btn">KYC Verified</div>
								</div>
								<div className="company-details">
									<div>
										<p>
											Customer Name 	<Tooltip title="Customer full name">
												<i className="fa fa-question-circle ms-3" aria-hidden="true"></i>
											</Tooltip>
											<br />
											<span className="font-weight-bold text-dark">
												{`${walletData.first_name} ${walletData?.middle_name ? walletData.middle_name : ""
													} ${walletData.last_name}`}
											</span>
										</p>
									</div>

									<div>
										<p>
											Customer ID 	<Tooltip title="Unique Customer ID">
												<i className="fa fa-question-circle ms-3" aria-hidden="true"></i>
											</Tooltip>
											<br />
											<span className="font-weight-bold text-dark">
												{walletData?.user?.user_id}
											</span>
										</p>
									</div>

									<div>
										<p>
											Wallet ID
											<Tooltip title="Unique Wallet ID">
												<i className="fa fa-question-circle ms-3" aria-hidden="true"></i>
											</Tooltip>
											<br />
											<span className="font-weight-bold text-dark">
												{walletData?.Wallet_kyc_id}
											</span>
										</p>
									</div>
								</div>

								<div className="wallet-Bal-details">
									<div>
										<p>
											Wallet Balance{" "}
											<Tooltip title="Available Balance">
												<i className="fa fa-question-circle ms-3" aria-hidden="true"></i>
											</Tooltip>
											<br />
											<span className="fs-4 font-weight-bold text-dark">
												{walletData?.Wallet_Balance ?? 0}
											</span>
										</p>
									</div>
									<div>
										<p>
											Last updates on{" "}
											<b className="font-weight-bold text-dark">
												{moment(walletData?.updated_at).format("YYYY MMM DD")}
											</b>{" "}
											<Tooltip title="Wallet last updated date">
												<i className="fa fa-question-circle ms-3" aria-hidden="true"></i>
											</Tooltip>
										</p>
									</div>
									<div>
										<p>
											KYC Status 	<Tooltip title="KYC status of wallet">
												<i className="fa fa-question-circle ms-3" aria-hidden="true"></i>
											</Tooltip>
											<br />
											<span className="font-weight-bold text-success text-capitalize">
												{walletData?.KYC_Status}
											</span>{" "}
										</p>
									</div>
								</div>
							</div>
						) : (
							<div className="right-part">
								<div className="wallet-details">
									<h6>Wallet Not Created</h6>
								</div>
							</div>
						)}

						<div className="left-part">
							<div className="leftFirst">
								<div>
									<p>
										Full Name <Tooltip title="Customer full name">
												<i className="fa fa-question-circle ms-3" aria-hidden="true"></i>
											</Tooltip>
										<br />
										<span className="font-weight-bold text-dark">
											{`${userInfo?.userAccData?.first_name}  ${userInfo?.userAccData?.last_name}`}
										</span>
									</p>
								</div>

								<div>
									<p>
										ID Proof <Tooltip title="Identity Proof of User">
												<i className="fa fa-question-circle ms-3" aria-hidden="true"></i>
											</Tooltip>
										<br />
										<span className="font-weight-bold text-dark">
											{userInfo?.userAccData?.id_proof}
										</span>
									</p>
								</div>

								<div>
									<p>
										Address Proof <Tooltip title="Address Proof of User">
												<i className="fa fa-question-circle ms-3" aria-hidden="true"></i>
											</Tooltip>
										<br />
										<span className="font-weight-bold text-dark text-overflow" style={{ width: '20ch' }}>
											{userInfo?.userAccData?.address_proof}
										</span>
									</p>
								</div>
							</div>

							<div className="leftSecond">
								<div>
									<p>
										Date of Registration <br />
										<span className="font-weight-bold text-dark">
											{moment(userInfo?.userAccData?.created_at).format(
												"YYYY MMM DD"
											)}
										</span>
									</p>
								</div>

								<div>
									<p className="d-flex  flex-column justify-content-center">
										ID Proof Link <br />
										<span className="font-weight-bold text-dark text-overflow" style={{ width: '20ch' }}>
											{userInfo?.userAccData?.id_proof_doc} &nbsp; &nbsp;{" "}
										</span>{" "}
										<a target="_blank" href={userInfo?.userAccData?.id_proof_doc}><i className="fa-solid fa-file-arrow-down docIcon"></i></a>
									</p>
								</div>

								<div>
									<p className="d-flex  flex-column justify-content-center">
										Address Proof Link<br />
										<span className="font-weight-bold text-dark text-overflow" style={{ width: '20ch' }}>
											{userInfo?.userAccData?.address_proof_doc} &nbsp; &nbsp;
											&nbsp;{" "}
										</span>{" "}
										<a target="_blank" href={userInfo?.userAccData?.address_proof_doc}><i className="fa-solid fa-file-arrow-down docIcon"></i></a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}
