import React, { useCallback, useContext, useEffect, useState } from "react";
import "./CommonTableStyle.css";
import CommonTableSkeletonLoader from "../../components/SkeletonLoader/CommonTableSkeletonLoader";
import AppContext from "../../Context/AppContext";
import Skeleton from "react-loading-skeleton";
import { debounce } from "../../../utils/helperFunctions";

export default function CommonTable({
  children,
  column,
  data,
  totalPages,
  totalData,
  filterList,
  filterType,
  setFilterType,
  filterValue,
  setFilterValue,
  searchBar,
  searchBy,
  searchValue,
  setSearchValue,
  searchPlaceHolder,
  setSearchPlaceHolder,
  setSearchQuery,
  currentPage,
  setCurrentPage,
  itemPerPg,
  setTable,
  card,
  align = "start",
}) {
  const [inputValue, setInputValue] = useState("");
  const { loading } = useContext(AppContext);
  const recordsPerPage = itemPerPg;
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setCurrentPage(1);
    }, 800),
    []
  );
  const handleSearch = (e) => {
    setInputValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const newDatalength = data ? data.length : 0;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const newData = data?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(newDatalength / recordsPerPage);
  const number = [...Array(totalPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prePage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
  };

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const selectedOption = searchBy.find((opt) => searchValue === opt.value);
    setSearchPlaceHolder(
      selectedOption && selectedOption.name ? selectedOption.name : ""
    );
  }, [searchValue]);

  const getFilterOptions = (type) => {
    const filterArr = filterList.find((item) => item.value === type);
    return filterArr ? filterArr.filters : [];
  };

  return (
    <>
      <div className="toolbarContainer">
        <div>
          {filterList && filterList.length > 0 && (
            <div className="row">
              <div style={{ width: "350px" }}>
                {loading ? (
                  <Skeleton height={40} />
                ) : (
                  <select
                    name=""
                    className="form-select"
                    id=""
                    value={filterType && filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                  >
                    <option value="">Select Filter Option</option>
                    {filterList.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div
                className="input-group mb-3 d-flex justify-content-center align-items-center"
                style={{ width: 400 }}
              >
                {loading ? (
                  <Skeleton height={40} />
                ) : (
                  <select
                    name="filterValue"
                    className="form-select"
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                    disabled={!filterType}
                  >
                    <option value="">Select Filter</option>
                    {getFilterOptions(filterType).map((option, index) => (
                      <option key={index} value={option.value ?? option}>
                        {option.label ?? option}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          {searchBar && (
            <div className="row">
              <div style={{ width: "350px" }}>
                {loading ? (
                  <Skeleton height={40} />
                ) : (
                  <select
                    name=""
                    className="form-select"
                    id=""
                    value={searchValue && searchValue}
                    onChange={(e) => handleSearchValue(e)}
                  >
                    <option value="">Select Search Option</option>
                    {searchBy.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div
                className="input-group mb-3 d-flex justify-content-center align-items-center"
                style={{ width: 400 }}
              >
                {loading ? (
                  <>
                    <Skeleton height={40} width={400} />
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      placeholder={`Search by ${searchPlaceHolder}`}
                      value={inputValue}
                      onChange={handleSearch}
                      disabled={searchPlaceHolder ? false : true}
                      className="form-control"
                    />

                    <span
                      style={{
                        position: "absolute",
                        right: 0,
                        zIndex: 100,
                        padding: "15px",
                      }}
                    >
                      <i className="fas fa-search" aria-hidden="true"></i>
                    </span>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="card">
        <div className="table-responsive">
          {loading ? (
            <CommonTableSkeletonLoader card={card} setTable={setTable} />
          ) : (
            <>
              <table className="table align-items-center mb-0 customerDashboardTable">
                <thead>
                  <tr>
                    {column &&
                      column.map((item, index) => (
                        <th
                          key={index}
                          className={
                            item.align
                              ? `text-uppercase text-${item.align} font-weight-bolder opacity-7`
                              : index == 0 || index >= column.length - 2
                              ? "text-uppercase text-center font-weight-bolder opacity-7"
                              : `text-uppercase text-${align} font-weight-bolder opacity-7`
                          }
                        >
                          {item.label ?? item}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>{children}</tbody>
              </table>
              <div className="pageCountInfo mb-4">
                <div className="pageCount">
                  <h6>
                    Showing{" "}
                    {totalData && totalData > 0
                      ? (currentPage - 1) * itemPerPg + 1
                      : 0}
                    -
                    {totalData && totalData > 0
                      ? Math.min(currentPage * itemPerPg, totalData)
                      : 0}{" "}
                    of {totalData ? totalData : 0} Entries
                  </h6>
                </div>

                <div>
                  <div className="CommonTablePagination">
                    <div className="d-flex">
                      <button className="prePage" onClick={prePage}>
                        <i className="fa-solid fa-arrow-left-long"></i>
                        Prev
                      </button>

                      {number.map((n, i) => {
                        if (
                          n === 1 ||
                          n === currentPage ||
                          n === totalPages ||
                          (n >= currentPage - 1 && n <= currentPage + 1)
                        ) {
                          return (
                            <button
                              key={i}
                              className={`prePage ${
                                currentPage === n ? "pageActive" : ""
                              }`}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </button>
                          );
                        } else if (
                          n === currentPage - 2 ||
                          n === currentPage + 2
                        ) {
                          return (
                            <div key={i} className="">
                              <span className="DotPage">...</span>
                            </div>
                          );
                        }
                        return null;
                      })}
                      <button className="prePage" onClick={nextPage}>
                        Next
                        <i className="fa-solid fa-arrow-right-long ms-2 me-0"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
