import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import "./Maindashboard.css";
import Chart from "react-apexcharts";
import { toast } from "react-toastify";
import ProfileImage from "../../components/ProfileImage";
import HTTPService from "../../utils/axios";
import Skeleton from "react-loading-skeleton";
import { customToast } from "../../App";
const Breadcrumbname = {
  home: "/admin/home",
  Dashboard: "",
};

const severityValues = {
  Critical: 5,
  Severe: 4,
  Moderate: 3,
  Low: 2,
  Informational: 1,
  null: 0,
};
export default function Maindashboard() {
  const ASSET_URL = process.env.REACT_APP_ASSET_URL;
  const [customerPortfolioLoad, setCustomerPortfolioLoad] = useState(false);
  const [researcherPortfolioLoad, setResearcherPortfolioLoad] = useState(false);
  const [customerPortfolio, setCustomerPortfolio] = useState([]);
  const [researcherPortfolio, setResearcherPortfolio] = useState([]);
  const [widgetStats, setWidgetStats] = useState({});
  const [issueTracking, setIssueTracking] = useState([]);
  const [programStats, setProgramStats] = useState([]);
  const [rewardMetric, setRewardMetric] = useState();
  const { invokeApi } = HTTPService();
  const getCustomerPortfolio = () => {
    setCustomerPortfolioLoad(true);
    invokeApi({
      method: "GET",
      url: `widget/customer-portfolio/`,
    })
      .then((data) => {
        setCustomerPortfolioLoad(false);
        setCustomerPortfolio(
          data.sort(
            (a, b) => b.total_submissions_count - a.total_submissions_count
          )
        );
      })
      .catch((error) => {
        setCustomerPortfolioLoad(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const getResearcherPortfolio = () => {
    setResearcherPortfolioLoad(true);
    invokeApi({
      method: "GET",
      url: `widget/researcher-leader-board`,
    })
      .then((data) => {
        setResearcherPortfolioLoad(false);
        setResearcherPortfolio(data);
      })
      .catch((error) => {
        setResearcherPortfolioLoad(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const getWidgetStats = () => {
    // setResearcherPortfolioLoad(true);
    invokeApi({
      method: "GET",
      url: `widget/programs-stats/`,
    })
      .then((data) => {
        setWidgetStats(data.widget_stats);
      })
      .catch((error) => {
        // setResearcherPortfolioLoad(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const getWidgetIssueTracking = () => {
    // setResearcherPortfolioLoad(true);
    invokeApi({
      method: "GET",
      url: `widget/submission-issues-tracking/`,
    })
      .then((data) => {
        setIssueTracking(data);
      })
      .catch((error) => {
        // setResearcherPortfolioLoad(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const getProgramStats = () => {
    invokeApi({
      method: "GET",
      url: `widget/programs-submission-stats/`,
    })
      .then((data) => {
        setProgramStats(data.program_submission_stats);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const getRewardMetrics = () => {
    invokeApi({
      method: "GET",
      url: `widget/reward-metrics`,
    })
      .then((data) => {
        setRewardMetric(data);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  useEffect(() => {
    getCustomerPortfolio();
    getResearcherPortfolio();
    getWidgetStats();
    getWidgetIssueTracking();
    getProgramStats();
    getRewardMetrics();
  }, []);

  const issueOptions = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 7,
        borderRadiusApplication: "around",
        // borderRadiusWhenStacked: "last",
        columnWidth: "50%",
        barHeight: "70%",
        distributed: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
        hideZeroBarsWhenGrouped: false,
        isDumbbell: false,
        dumbbellColors: undefined,
        isFunnel: false,
        isFunnel3d: true,
        colors: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: undefined,
            },
          ],
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
        },
        dataLabels: {
          position: "top",
          maxItems: 100,
          hideOverflowingLabels: true,

          total: {
            enabled: false,
            formatter: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#373d3f",
              fontSize: "12px",
              fontFamily: undefined,
              fontWeight: 600,
            },
          },
        },
      },
    },
    xaxis: {
      categories: [
        ...issueTracking.map((el) => {
          return el.vtx_main_category ?? '';
        }),
      ],
      labels: {
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: false,
        showDuplicates: false,
        trim: true,
        minHeight: undefined,
        maxHeight: 120,

        style: {
          colors: "#65778E",
          fontSize: "12px",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 5,
      tickAmount: 5,
      labels: {
        formatter: function (value) {
          var val = value;
          if (val == 5) {
            val = "Critical";
          }
          if (val == 4) {
            val = "Severe";
          }
          if (val == 3) {
            val = "Moderate";
          }
          if (val == 2) {
            val = "Low";
          }
          if (val == 1) {
            val = "Informational";
          }
          return val;
        },
      },
    },
    background: {
      enabled: true,
      foreColor: "#fff",
      padding: 4,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: "#fff",
      opacity: 0.9,
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    series: [
      {
        name: "",
        data: [
          ...issueTracking.map((el) => {
            return severityValues[el.severity];
          }),
        ],
      },
    ],
    colors: [
      function ({ value, seriesIndex, w }) {
        if (value === 1) {
          return "#E6E6FA";
        }
        if (value === 2) {
          return "#FFDB96";
        }
        if (value === 3) {
          return "#ABCF9A";
        }
        if (value === 4) {
          return "#A4C2E6";
        }
        if (value === 5) {
          return "#FFD681";
        }
      },
    ],
  };

  const donutOptions = {
    chart: {
      type: "donut",
      width: '100%',
      height: '100%',
    },
    legend: {
      show: true,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val + "%"; // Custom tooltip format
        },
      },
    },
    series: [
      ...programStats.map((el) => {
        return el.percentage_of_total_submissions;
      }),
    ],
    labels: [
      ...programStats.map((el) => {
        return el.program_title;
      }),
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 500,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      show: false,
    },
  };

  return (
    <>
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="row p-0 m-0">
            <div className="col-sm-12 col-md-6 mt-4">
              <div className="card h-100">
                <h5 className="p-3 text-center">Customer Portfolio</h5>
                <div className="table-responsive researcherProfile">
                  <table className="table align-items-center mb-0 researcherProfileTable">
                    <thead>
                      <tr>
                        <th
                          style={{ padding: "0" }}
                          className="text-uppercase  font-weight-bolder"
                        ></th>
                        <th
                          style={{ padding: "0" }}
                          className="text-uppercase  font-weight-bolder"
                        >
                          Total <br /> Programs
                        </th>
                        <th
                          style={{ padding: "0" }}
                          className="text-uppercase  font-weight-bolder"
                        >
                          Active <br /> Programs
                        </th>
                        <th
                          style={{ padding: "0" }}
                          className="text-uppercase  font-weight-bolder"
                        >
                          Total <br /> Submissions
                        </th>
                        <th
                          style={{ padding: "0" }}
                          className="text-uppercase  font-weight-bolder"
                        >
                          Resolved <br /> submissions
                        </th>
                        <th
                          style={{ padding: "0" }}
                          className="text-uppercase  font-weight-bolder"
                        >
                          Satisfaction <br /> Rating
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerPortfolioLoad
                        ? Array(5)
                          .fill()
                          .map((item) => (
                            <tr>
                              <td>
                                <div className="d-flex">
                                  <div className="me-2">
                                    <div className="outsideBorder">
                                      <div className="customerProfilePic">
                                        <Skeleton
                                          height={"100%"}
                                          width={"100%"}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-start">
                                    <span className="text-xxs">
                                      {/* <b>{item.user_id}</b> */}
                                      <Skeleton height={13} width={"100%"} />
                                    </span>
                                    <p
                                      style={{ fontSize: "14px" }}
                                      className="mb-0"
                                    >
                                      <Skeleton height={14} width={"100%"} />
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p
                                  className="  mb-0 px-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  <Skeleton height={14} width={"100%"} />
                                </p>
                              </td>

                              <td className="cell-breakWord">
                                <p
                                  className="  mb-0"
                                  style={{ fontSize: "14px" }}
                                >
                                  <Skeleton height={14} width={"100%"} />
                                </p>
                              </td>
                              <td>
                                <p
                                  className="  mb-0"
                                  style={{ fontSize: "14px" }}
                                >
                                  <Skeleton height={14} width={"100%"} />
                                </p>
                              </td>
                              <td>
                                <p
                                  className="  mb-0"
                                  style={{ fontSize: "14px" }}
                                >
                                  <i
                                    className="me-1 fa-solid fa-star"
                                    style={{ color: " #FFD43B" }}
                                  ></i>
                                  <Skeleton height={14} width={30} />
                                </p>
                              </td>
                            </tr>
                          ))
                        : customerPortfolio &&
                        customerPortfolio.slice(0, 5).map((item, index) => (
                          <tr key={index}>
                            <td style={{ padding: "0" }}>
                              <div className="d-flex align-items-center">
                                <div className="me-2 p-2">
                                  <div className="outsideBorder">
                                    <div className="customerProfilePic">
                                      <ProfileImage
                                        src={ASSET_URL + item.display_pic}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="text-start">
                                  <span className="text-xxs">
                                    <b
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {item.user_id}
                                    </b>
                                  </span>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                    className="mb-0"
                                  >
                                    {item.first_name} {item.last_name}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      color: "#67748e",
                                    }}
                                  >
                                    {item.entity_name}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                padding: "0",
                                whiteSpace: "normal",
                                fontSize: "14px",
                                fontWeight: 600,
                              }}
                            >
                              <p
                                className="mb-0"
                                style={{ fontSize: "14px", fontWeight: 600 }}
                              >
                                {item.total_programs_count}
                              </p>
                            </td>
                            <td style={{ padding: "0" }}>
                              <p
                                className="mb-0"
                                style={{ fontSize: "14px", fontWeight: 600 }}
                              >
                                {item.active_program_count}
                              </p>
                            </td>

                            <td className="cell-breakWord">
                              <p
                                className="  mb-0"
                                style={{ fontSize: "14px", fontWeight: 600 }}
                              >
                                {item.total_submissions_count}
                              </p>
                            </td>
                            <td style={{ padding: "0" }}>
                              <p
                                className="  mb-0"
                                style={{ fontSize: "14px", fontWeight: 600 }}
                              >
                                {item.resolved_submissions_count}
                              </p>
                            </td>
                            <td style={{ padding: "0" }}>
                              <p
                                className="  mb-0"
                                style={{ fontSize: "14px", fontWeight: 600 }}
                              >
                                <i
                                  className="me-1 fa-solid fa-star"
                                  style={{ color: " #FFD43B" }}
                                ></i>
                                {item.rating.toFixed(2)} <span>| 5</span>
                              </p>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 mt-4">
              <div className="row ">
                <div
                  className="col-12 p-0 mb-3"
                // style={{ paddingLeft: "12px", paddingRight: "29px" }}
                >
                  <div className="card programStsTxt">
                    <h5>Program Stats</h5>
                  </div>
                </div>
                <div className="col-6 p-0 h-100">
                  <div className="card mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        {widgetStats?.total_active_customers ?? 0}
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                        }}
                      >
                        Total Customers
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Sum of All Active Bug Bounty Programs
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-6  p-0 h-100">
                  <div className="card mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        {widgetStats?.total_active_researchers ?? 0}
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                        }}
                      >
                        Total Researcher
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Average Satisfaction Rating Across All Customers
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-6  p-0 h-100">
                  <div className="card mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        {widgetStats?.total_programs ?? 0}
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Total Number Of Programs
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Sum Of All Reports Submitted In Customer Programs
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-6 p-0 h-100">
                  <div className="card  mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        {widgetStats?.total_active_programs ?? 0}
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Total Number Of Active Programs
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Number Of Customer Support Queries Resolved
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-6 p-0 h-100">
                  <div className="card  mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        {widgetStats?.total_submissions ?? 0}
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Total Submissions
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Number Of Customer Support Queries Resolved
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-6 p-0 h-100">
                  <div className="card  mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        {widgetStats?.total_valid_submissions ?? 0}
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Total Valid Submissions
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Number Of Customer Support Queries Resolved
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-6  p-0 h-100">
                  <div className="card mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        4.2 <span style={{ fontSize: "18px" }}>/ 5.0</span>
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                        }}
                      >
                        Average Customer Satisfaction
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Average Satisfaction Rating Across All Customers
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-6  p-0 h-100">
                  <div className="card mb-3 me-3 p-2 d-flex justify-content-center align-items-center">
                    <div>
                      <h3
                        style={{ fontSize: "50px", color: "#1883FE" }}
                        className="mb-0"
                      >
                        {widgetStats?.total_submissions === 0 ||
                          widgetStats?.total_valid_submissions === 0
                          ? "0.0"
                          : (
                            (parseInt(widgetStats?.total_valid_submissions) /
                              parseInt(widgetStats?.total_submissions)) *
                            5
                          ).toFixed(1)}
                        <span style={{ fontSize: "18px" }}>/ 5.0</span>
                      </h3>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          marginBottom: "0",
                          color: "#455775",
                          fontWeight: "600",
                        }}
                      >
                        Average Submission Rate
                      </p>
                      {/* <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Average Satisfaction Rating Across All Customers
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 mt-4">
              <div className="card h-100">
                <h5 className="p-3 text-center">Researcher Profile</h5>
                <div className="table-responsive researcherProfile">
                  <table className="table align-items-center mb-0 researcherProfileTable">
                    <thead>
                      <tr>
                        <th className="text-uppercase  font-weight-bolder text-start ps-7">
                          ID & Name
                        </th>
                        <th className="text-uppercase  font-weight-bolder">
                          Total <br /> Submissions
                        </th>
                        <th className="text-uppercase  font-weight-bolder">
                          Valid <br /> Submissions
                        </th>
                        <th className="text-uppercase  font-weight-bolder ">
                          Total
                          <br /> Rewards
                        </th>
                        <th className="text-uppercase  font-weight-bolder ">
                          Critical <br /> Findings
                        </th>
                        <th className="text-uppercase  font-weight-bolder ">
                          Highest
                          <br /> Rewards
                        </th>
                        <th className="text-uppercase  font-weight-bolder ">
                          Reward
                          <br /> Points
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {researcherPortfolioLoad
                        ? Array(5)
                          .fill()
                          .map((item, index) => (
                            <tr key={index}>
                              <td
                                className="text-start td-first"
                                style={{ width: "100px", overflow: "hidden" }}
                              >
                                <div className="d-flex">
                                  <div className="me-2">
                                    <div className="outsideBorder">
                                      <div className="customerProfilePic">
                                        <Skeleton
                                          height={"100%"}
                                          width={"100%"}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-start">
                                    <span className="text-xxs">
                                      <Skeleton height={14} width={"100%"} />
                                    </span>
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "10px" }}
                                    >
                                      <Skeleton height={14} width={"100%"} />
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p
                                  className="  mb-0 px-2"
                                  style={{ fontSize: "10px" }}
                                >
                                  <Skeleton height={14} width={"100%"} />
                                </p>
                              </td>

                              <td className="cell-breakWord">
                                <Skeleton height={14} width={"100%"} />
                              </td>
                              <td>
                                <Skeleton height={14} width={"100%"} />
                              </td>
                              <td>
                                <Skeleton height={14} width={"100%"} />
                              </td>
                              <td>
                                {/* <p className="  mb-1">{item.progress} %</p> */}
                                <div className="">
                                  <Skeleton height={9} width={120} />
                                </div>
                              </td>
                            </tr>
                          ))
                        : researcherPortfolio &&
                        researcherPortfolio.slice(0, 5).map((item, index) => (
                          <tr key={index}>
                            <td
                              className="text-start td-first "
                              style={{ overflow: "hidden" }}
                            >
                              <div className="d-flex align-items-center">
                                <div className="me-2 p-2">
                                  <div className="outsideBorder">
                                    <div className="customerProfilePic">
                                      <ProfileImage src={ASSET_URL + item.display_pic} />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <span
                                    className="text-xxs"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>{item.user_id}</b>
                                  </span>
                                  <p
                                    className="mb-0"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      textWrap: "nowrap",
                                    }}
                                  >
                                    {item.first_name} {item.last_name}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td style={{ padding: "0" }}>
                              <p
                                className="  mb-0"
                                style={{ fontSize: "14px", fontWeight: 600 }}
                              >
                                {item.total_reports}
                              </p>
                            </td>
                            <td style={{ padding: "0" }} className="mb-0">
                              <p
                                className="  mb-0"
                                style={{ fontSize: "14px", fontWeight: 600 }}
                              >
                                {item.valid_reports}
                              </p>
                            </td>
                            <td style={{ padding: "0" }}>
                              <p
                                className="  mb-0 "
                                style={{
                                  color: "#22B14C",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                ${item.total_rewards}
                              </p>
                            </td>
                            <td style={{ padding: "0" }}>
                              <p
                                className="  mb-0"
                                style={{ fontSize: "14px", fontWeight: 600 }}
                              >
                                {item.critical_findings}
                              </p>
                            </td>
                            <td style={{ padding: "0" }}>
                              <p
                                className="  mb-0 "
                                style={{
                                  color: "#22B14C",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                ${item.highest_rewards}
                              </p>
                            </td>
                            <td style={{ padding: "0" }}>
                              <p
                                className="  mb-0 "
                                style={{
                                  color: "#000080",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                {item.reward_points}
                              </p>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 mt-4">
              <div className="card h-100">
                <h5 className="p-3 text-center pb-0 mb-1">
                  Vulnerabilities Reports Summary
                </h5>
                {/* <div className="text-center">Issues Tracking</div>
                <div className="text-center">Vulnerabilities Management</div> */}
                <div className="mixed-chart w-100">
                  <div style={{ height: "384px" }}>
                    <Chart
                      options={issueOptions}
                      series={issueOptions.series}
                      type="bar"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 mt-4">
              <div className="card h-100">
                <div className="row d-flex justify-content-center pb-2 ">
                  <div className="col-12">
                    <h5 className="p-3 text-center">Programs</h5>
                  </div>
                  <div className="col-sm-12 col-md-6 text-center p-0">
                    <Chart
                      options={donutOptions}
                      series={donutOptions.series}
                      type="donut"
                      width={"100%"}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div
                      style={{
                        marginRight: "14px",
                        marginBottom: "14px",
                      }}
                    >
                      {programStats &&
                        programStats.map((data) => (
                          <div key={data.id} className="d-flex donutLegendPart">
                            <div className="ms-2 w-100">
                              <div className="col-12">
                                <div className=" donutLegendName justify-content-between">
                                  <p>{data.program_title}</p>
                                  <p>
                                    {data.percentage_of_total_submissions.toFixed(
                                      2
                                    )}
                                    %
                                  </p>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="donutLegendName">
                                  Submission count:{" "}
                                  <span>
                                    {data.total_program_submissions.toFixed(0)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 mt-4">
              <div className="card p-4 h-100">
                <h5 className=" text-center">Aggregate Reward Metrics</h5>

                <div className="row mt-4">
                  <div className="col-sm-12 col-md-6 mb-4">
                    <div className="AggregateInnerDiv">
                      <div className="d-flex align-items-center">
                        <div className="col-3">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "end",
                              justifyContent: "center",
                            }}
                          >
                            <i
                              style={{
                                color: "#BF0CA6",
                                textShadow: "0px 3px 6px rgba(0,0,0,.2)",
                              }}
                              className="fa-2x fa-solid fa-circle-dollar-to-slot"
                            ></i>
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="ms-2">
                            <p style={{ fontWeight: "500" }}>
                              Total Rewards Across All Program
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="col-3">
                          <div></div>
                        </div>
                        <div className="col-9">
                          <div className="ms-2">
                            <div>
                              <h3>{" " + rewardMetric?.total_rewards}</h3>
                            </div>
                            <div className="progress">
                              <div
                                style={{
                                  width: 70,
                                  backgroundColor: "#314567",
                                }}
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 mb-4">
                    <div className="AggregateInnerDiv">
                      <div className="d-flex align-items-center">
                        <div className="col-3">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "end",
                              justifyContent: "center",
                            }}
                          >
                            <i
                              style={{
                                color: "#1B9FFE",
                                textShadow: "0px 3px 6px rgba(0,0,0,.2)",
                              }}
                              className="fa-2x fa-solid fa-hand-holding-dollar"
                            ></i>
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="ms-2">
                            <p style={{ fontWeight: "500" }}>
                              Average Payout Per Report
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="col-3">
                          <div></div>
                        </div>
                        <div className="col-9">
                          <div className="ms-2">
                            <div>
                              <h3>
                                {" " + rewardMetric?.average_payout.toFixed(0)}
                              </h3>
                            </div>
                            <div className="progress">
                              <div
                                style={{
                                  width: 90,
                                  backgroundColor: "#314567",
                                }}
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 mt-3">
                    <div className="AggregateInnerDiv">
                      <div className="d-flex align-items-center">
                        <div className="col-3">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "end",
                              justifyContent: "center",
                            }}
                          >
                            <i
                              style={{
                                color: "#F76634",
                                textShadow: "0px 3px 6px rgba(0,0,0,.2)",
                              }}
                              className="fa-2x fa-solid fa-circle-check"
                            ></i>
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="ms-2">
                            <p style={{ fontWeight: "500" }}>
                              Total Number Of Valid Reports
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="col-3">
                          <div></div>
                        </div>
                        <div className="col-9">
                          <div className="ms-2">
                            <div>
                              <h3>{" " + rewardMetric?.total_submissions}</h3>
                            </div>
                            <div className="progress">
                              <div
                                style={{
                                  width: 30,
                                  backgroundColor: "#314567",
                                }}
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 mt-3">
                    <div className="AggregateInnerDiv">
                      <div className="d-flex align-items-center">
                        <div className="col-3">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "end",
                              justifyContent: "center",
                            }}
                          >
                            <i
                              style={{
                                color: "#F32A34",
                                textShadow: "0px 3px 6px rgba(0,0,0,.2)",
                              }}
                              className="fa-2x fa-solid fa-coins"
                            ></i>
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="ms-2">
                            <p style={{ fontWeight: "500" }}>
                              Overall Budget Utilization (%)
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="col-3">
                          <div></div>
                        </div>
                        <div className="col-9">
                          <div className="ms-2">
                            <div>
                              <h3>
                                {" " +
                                  rewardMetric?.overall_budget_utilization.toFixed(
                                    0
                                  )}
                                %
                              </h3>
                            </div>
                            <div className="progress">
                              <div
                                style={{
                                  width: 64,
                                  backgroundColor: "#314567",
                                }}
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
