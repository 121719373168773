import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import Breadcrumb from "../components/breadcrumb/Breadcrumb";

import useHttp from "../Hooks/useHttp";
import { ToastContainer, toast } from "react-toastify";

import "./DepartmentDesignation.css";
import AddModal from "../common/modal/AddModal";
import AppContext from "../Context/AppContext";
import CommonTable from "../common/Table/CommonTable";
import { customToast } from "../../App";

export default function DepartmentDesignation() {
  const { setLoading } = useContext(AppContext);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [DelId, setDelId] = useState({ mapId: "", index: "" });
  const Breadcrumbname = {
    home: "/admin/home",
    Management: "",
    "Department Designation": "/admin/department-designation",
    Dashboard: "",
  };

  const API = process.env.REACT_APP_API_URL;
  const { PostRequest, GetRequest, DeleteRequest, PatchRequest } = useHttp();
  const token = localStorage.getItem("token");

  const [designationsList, setDesignationsList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [usersList, setUsersList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);

  const fetchSubadminsList = async () => {
    setLoading(true);
    const { data, error } = await GetRequest(
      API + "/sub_admin/",
      {},
      {
        Authorization: "Bearer  " + token,
      }
    );
    if (error) {
      setLoading(false);
      // alert("Something went wrong");
      // customToast.error("Something went wrong!", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 1000,
      // });
    } else {
      setLoading(false);
      const tempArr = [];
      setUsersList(data.results);
    }
  };

  const fetchRolesList = async () => {
    setLoading(true);
    const { data, error } = await GetRequest(
      API + "/roles/",
      {},
      {
        Authorization: "Bearer " + token,
      }
    );
    if (error) {
      setLoading(false);
      // alert("Something went wrong");
      // customToast.error("Something went wrong!", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 1000,
      // });
    } else {
      setLoading(false);
      // const tempArr = [];
      // data.results.map((item) => {
      //   tempArr.push({
      //     value: item.id,
      //     label: item.role_title,
      //   });
      // });
      setRoles(data.results);
    }
  };
  console.log("user", usersList);
  useEffect(() => {
    fetchSubadminsList();
    fetchRolesList();
  }, []);

  const fetchDesignationsList = async () => {
    setLoading(true);
    let url = `${API}designations/?page=${currentPage}&page_size=25`;
    if (searchQuery && searchQuery.length > 0) {
      url = url + `&${searchValue}=${searchQuery}`;
    }
    const { data, error } = await GetRequest(
      url,
      {},
      {
        Authorization: "Bearer " + token,
      }
    );
    if (error) {
      setLoading(false);
      alert("Something went wrong");
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setLoading(false);
      setDesignationsList(data.results);
      setTotalData(data.total_records);
      setTotalPages(data.total_pages);
    }
  };

  useEffect(() => {
    fetchDesignationsList();
  }, []);

  useEffect(() => {
    fetchDesignationsList();
  }, [searchQuery, , currentPage]);

  const handleStatusChange = async (id, status, index) => {
    let newStatus;
    if (status == "active") {
      newStatus = "inactive";
    } else {
      newStatus = "active";
    }
    const { data, error } = await PatchRequest(
      API + `designations/${id}/`,
      { status: newStatus },
      {},
      {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      console.log(error);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      const arr = [...designationsList];
      arr[index].status = newStatus;
      setDesignationsList([...arr]);
    }
  };

  const handleDelete = async (id, index) => {
    const { data, error } = await DeleteRequest(API + `designations/${id}/`, {
      Authorization: "Bearer  " + token,
    });
    if (error) {
      console.log(error);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setOpenAddModal(false);
      const arr = [...designationsList];
      arr.splice(index, 1);
      setDesignationsList([...arr]);
    }
  };
  const tableColumn = [
    "Designation id",
    "designation",
    "department",
    "user details",
    "contact",
    "status",
    "action",
  ];
  const searchColumn = [
    { name: "Designation id", value: "designation_id" },
    { name: "Designation", value: "designation_title" },
  ];

  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              <Link
                to="/admin/department-designation/add-designation"
                className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                id="adduser"
              >
                Add Designation
              </Link>
            </div>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12">
            <CommonTable
              column={tableColumn}
              data={designationsList}
              totalPages={totalPages}
              totalData={totalData}
              searchBar={true}
              searchBy={searchColumn}
              itemPerPg={25}
              setTable={3}
              card={5}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            >
              {designationsList &&
                designationsList.map((item, index) => {
                  const user = usersList.find(
                    (user) => user.id === item.user_id
                  );
                  const userrole = roles.find(
                    (role) => role.id === item.role_id
                  );
                  return (
                    <tr key={item.id}>
                      <td className="rolename">
                        <p className="text-sm  mb-0 px-2">
                          {item.designation_id}
                        </p>
                      </td>
                      <td className=" text-start rolename">
                        <p className="text-wrap text-sm  mb-0 px-2 ">
                          {item.designation_title}
                        </p>
                      </td>
                      <td className="text-start rolename">
                        <p className="text-wrap text-sm  mb-0 px-2">
                          {userrole ? userrole.role_title : ""}
                        </p>
                      </td>
                      <td className="text-start  rolename">
                        <p className="tableRowItem text-wrap text-sm  mb-2 px-2 text-start">
                          Name :{" "}
                          {user ? `${user.first_name} ${user.last_name}` : ""}
                        </p>
                        <p className="text-wrap text-sm  mb-2 px-2 text-start">
                          UserId : {user ? user.user_id : ""}
                        </p>
                      </td>
                      <td className="text-start rolename">
                        <p className="text-wrap text-sm  mb-2 px-2 text-start">
                          Phone : {user ? user.phone_number : ""}
                        </p>
                        <p className="text-wrap text-sm  mb-2 px-2 text-start">
                          Email : {user ? user.email : ""}
                        </p>
                      </td>

                      <td
                        className="align-middle text-center cursor-pointer"
                        onClick={() => {
                          handleStatusChange(item.id, item.status, index);
                        }}
                      >
                        {item.status === "active" ? (
                          <span className="badge bg-success text-white">
                            Active
                          </span>
                        ) : (
                          <span className="badge bg-danger text-white">
                            Inactive
                          </span>
                        )}
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          {/* <Link to="" className="btn btn-sm px-3 mt-2">
              &#128065;
            </Link> */}
                          <button
                            type="button"
                            className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-ellipsis-v text-xs"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/admin/department-designation/edit-designation/${item.id}`}
                              >
                                Edit
                              </Link>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={() => {
                                  setOpenAddModal(true);
                                  setDelId({
                                    mapId: item.id,
                                    index: index,
                                  });
                                }}
                              >
                                Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </CommonTable>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"DELETE"}
        createAddTopic={"Delete Designation"}
        confirmText={"Delete The Designation"}
        isOpen={openAddModal}
        onSubmit={() => handleDelete(DelId.mapId, DelId.index)}
        onClose={() => setOpenAddModal(false)}
      />
    </>
  );
}
