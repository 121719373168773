import React, { useState } from "react";
import { Link } from "react-router-dom";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import userdata from "./researcherdata.json";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Dashboard from "../../components/Header-Components/Dashboard";

import Management from "../../components/Header-Components/Management";

import Payments from "../../components/Header-Components/Payments";
import Integrations from "../../components/Header-Components/Integrations";
import Reports from "../../components/Header-Components/Reports";

export default function ResearchersManagementDashboard() {
	const [formState, setFormState] = useState(1);
	const Breadcrumbname = {
		Home: "/researcher/home",
		Managment: "",
		Researcher: "",
	};
	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row">
					<div className="col-lg-6 ps-3">
						<Breadcrumb names={Breadcrumbname} />
					</div>
					<div className="col-lg-6">
						<div className="button-row d-flex justify-content-end mt-0 mb-3">
							<Link
								to="/researcher/management/researcher/add"
								className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
								id="adduser"
							>
								Add Researcher
							</Link>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12 col-12">
						<div className="row">
							<div className="col-lg-3 col-md-6 col-12">
								<div className="card">
									<span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
									<div className="card-body p-3 position-relative">
										<div className="row">
											<div className="col-8 text-start">
												<div className="icon icon-shape bg-white shadow text-center border-radius-md">
													<i
														className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
														aria-hidden="true"
													></i>
												</div>
												<h5 className="text-white font-weight-bolder mb-0 mt-3">
													1600
												</h5>
												<span className="text-white text-sm">
													Active Researcher{" "}
												</span>
											</div>
											<div className="col-4">
												<div className="dropdown text-end mb-5"></div>
												<h5 className="text-end text-white pt-3 mb-0 mt-2">
													5
												</h5>
												<p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
													Today's
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-12 mt-4 mt-md-0">
								<div className="card">
									<span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
									<div className="card-body p-3 position-relative">
										<div className="row">
											<div className="col-8 text-start">
												<div className="icon icon-shape bg-white shadow text-center border-radius-md">
													<i
														className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
														aria-hidden="true"
													></i>
												</div>
												<h5 className="text-white font-weight-bolder mb-0 mt-3">
													357
												</h5>
												<span className="text-white text-sm">
													In-Actives Researcher{" "}
												</span>
											</div>
											<div className="col-4">
												<div className="dropdown text-end mb-5"></div>
												<h5 className="text-end text-white pt-3 mb-0 mt-2">
													5
												</h5>
												<p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
													Today's
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-12 mt-4 mt-md-0">
								<div className="card">
									<span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
									<div className="card-body p-3 position-relative">
										<div className="row">
											<div className="col-8 text-start">
												<div className="icon icon-shape bg-white shadow text-center border-radius-md">
													<i
														className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
														aria-hidden="true"
													></i>
												</div>
												<h5 className="text-white font-weight-bolder mb-0 mt-3">
													357
												</h5>
												<span className="text-white text-sm">
													Blocked Researcher{" "}
												</span>
											</div>
											<div className="col-4">
												<div className="dropdown text-end mb-5"></div>
												<h5 className="text-end text-white pt-3 mb-0 mt-2">
													5
												</h5>
												<p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
													Today's
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-12 mt-4 mt-md-0">
								<div className="card">
									<span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
									<div className="card-body p-3 position-relative">
										<div className="row">
											<div className="col-8 text-start">
												<div className="icon icon-shape bg-white shadow text-center border-radius-md">
													<i
														className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
														aria-hidden="true"
													></i>
												</div>
												<h5 className="text-white font-weight-bolder mb-0 mt-3">
													357
												</h5>
												<span className="text-white text-sm">
													Pending Approveals{" "}
												</span>
											</div>
											<div className="col-4">
												<div className="dropdown text-end mb-5"></div>
												<h5 className="text-end text-white pt-3 mb-0 mt-2">
													5
												</h5>
												<p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
													Today's
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row my-4">
					<div className="col-12">
						<div className="card">
							<div className="table-responsive">
								<table className="table align-items-center mb-0 tableHeaderStyle">
									<thead>
										<tr>
											<th className="text-uppercase  font-weight-bolder opacity-7">
												Researcher ID
											</th>
											<th className="text-uppercase  font-weight-bolder opacity-7">
												UserName
											</th>
											<th className="text-uppercase  font-weight-bolder opacity-7">
												Full Name
											</th>
											<th className="text-uppercase  font-weight-bolder opacity-7 ps-2">
												Gender
											</th>
											<th className="text-center text-uppercase  font-weight-bolder opacity-7">
												Email
											</th>
											<th className="text-center text-uppercase  font-weight-bolder opacity-7">
												Phone
											</th>
											<th className="text-center text-uppercase  font-weight-bolder opacity-7">
												Registred
											</th>
											<th className="text-center text-uppercase  font-weight-bolder opacity-7">
												Status
											</th>
											<th className="text-center text-uppercase  font-weight-bolder opacity-7">
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{userdata &&
											userdata.map((item,index) => (
												<tr key={index} >
													<td>
														{" "}
														<p className="text-sm text-secondary mb-0 px-2">
															#{item.id}
														</p>
													</td>
													<td>
														{" "}
														<p className="text-sm text-secondary mb-0 px-2">
															#{item.username}
														</p>
													</td>
													<td>
														<div className="d-flex px-2 py-1">
															<div>
																<img
																	src={"../../../assets/img/" + item.img}
																	className="avatar avatar-sm me-3"
																	alt="avatar image"
																/>
															</div>
															<div className="d-flex flex-column justify-content-center">
																<h6 className="mb-0 text-sm">{item.name}</h6>
															</div>
														</div>
													</td>
													<td>
														<p className="text-sm text-secondary mb-0">
															{item.gender}
														</p>
													</td>
													<td>
														<p className="text-sm text-secondary mb-0">
															{item.email}
														</p>
													</td>
													<td className="align-middle text-center text-sm">
														<p className="text-sm text-secondary mb-0">
															{item.phone}
														</p>
													</td>
													<td className="align-middle text-center">
														<p className="text-sm text-secondary mb-0">
															{item.register_time}
														</p>
													</td>
													<td className="align-middle text-center">
														{item.status === "active" ? (
															<span className="badge bg-success text-white">
																Active
															</span>
														) : (
															<span className="badge bg-danger text-white">
																Inactive
															</span>
														)}
													</td>
													<td>
														<div className="d-flex">
															<Link
																to={`/researcher/management/researcher/view/${item.id}`}
																className="btn btn-sm px-3 mt-2"
															>
																&#128065;
															</Link>
															<button
																type="button"
																className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
																data-bs-toggle="dropdown"
																aria-expanded="false"
															>
																<i
																	className="fa fa-ellipsis-v text-xs"
																	aria-hidden="true"
																></i>
															</button>
															<ul className="dropdown-menu">
																<li>
																	<Link
																		className="dropdown-item"
																		to={`/researcher/management/researcher/edit/${item.id}`}
																	>
																		Edit
																	</Link>
																</li>
																<li>
																	<a className="dropdown-item" href="#">
																		Delete
																	</a>
																</li>
															</ul>
														</div>
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
