import React, { useEffect } from "react";

import { useState } from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaLinkedin, FaTwitter } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserInfo } from "../../../reducers/authSlice";
import HTTPService from "../../../utils/axios";
import { calculatePercentage, getOrdinalSuffix } from "../../../utils/helperFunctions";
import quality from "./Img/quality.png";
import Ranking from "./Img/ranking.png";
import "./ResearcherStatsReport.css";
import moment from "moment/moment";
import { customToast } from "../../../App";

export default function ResearcherStatsReports() {
  const { userInfo, userAccData } = useSelector(getUserInfo);
  const [activeNavItem, setActiveNavItem] = useState("All Time");
  const [toggle, setToggle] = useState(4);
  const [profile, setProfile] = useState();
  const { invokeApi } = HTTPService();
  const fetchResearcherProfile = async () => {
    let params = {}
    if (activeNavItem == 'LastMonth') {
      const lastMonthDate = moment().subtract(1, 'months');
      const monthNumber = lastMonthDate.month() + 1;
      params.month = monthNumber
    }
    invokeApi({
      method: "GET",
      url: `/researcher/profile`,
      params
    }).then((data) => {
      setProfile(data);
    }).catch((error) => {
      customToast.error("Unable to fetch data", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  };
  useEffect(() => {
    if (userInfo?.user_data) {
      fetchResearcherProfile();
    }
  }, [userInfo, activeNavItem]);

  const Alltime = (
    <div className="reportCard-container">
      {/* User Details */}
      <div className="userCard-details">
        <div className="profileDetails">
          <div className="imgDetails">
            <img
              src={userAccData.display_pic}
              alt="img"
              style={{ borderRadius: "50%", height: "100px", width: "100px" }}
            />
          </div>
          <div className="namedetails">
            <h4>
              {userAccData.first_name} {userAccData.last_name}
            </h4>
            <div>
              <p style={{ marginRight: "30px" }}>
                <div>
                  <i className="me-2 fa-solid fa-location-dot"></i>
                </div>
                India
              </p>
              <p className="">
                <div>
                  <i
                    style={{ color: "#5DAC0F", marginRight: "5px" }}
                    className="fa-solid fa-circle-check"
                  ></i>
                </div>
                <span style={{ color: "#737373" }}>ID verified</span>
              </p>
            </div>
          </div>
          <div>
            <h6>
              User:{" "}
              <span style={{ fontWeight: "800" }}> {userAccData.username}</span>{" "}
            </h6>
            <h6>
              ID:{" "}
              <span style={{ fontWeight: "800" }}> {userAccData.user_id}</span>{" "}
            </h6>
          </div>
          <div>
            <div style={{ textAlign: "center" }}>
              <button type="button" className="btn btn-success px-1 py-1">
                Active
              </button>
            </div>
            <div className="socialDetails">
              {profile?.researcher_basic_details[0]?.twitter && <div>
                <a target="_blank" href={profile?.researcher_basic_details[0]?.twitter}>
                  <FaTwitter />
                </a>
              </div>}
              {profile?.researcher_basic_details[0]?.linked_in && <div>
                <a target="_blank" href={profile?.researcher_basic_details[0]?.linked_in}>
                  <FaLinkedin />
                </a>
              </div>}
              {
                profile?.researcher_basic_details[0]?.instagram && <div>
                  <a target="_blank" href={profile?.researcher_basic_details[0]?.instagram}>
                    <FaInstagram />
                  </a>
                </div>
              }

              {profile?.researcher_basic_details[0]?.youtube && <div>
                <a target="_blank" href={profile?.researcher_basic_details[0]?.youtube}>
                  <FaYoutube />
                </a>
              </div>}
              {
                profile?.researcher_basic_details[0]?.facebook && <div>
                  <a target="_blank" href={profile?.researcher_basic_details[0]?.facebook}>
                    <FaFacebook />
                  </a>
                </div>
              }


            </div>
          </div>
        </div>

        <div className="markDetails">
          <div>
            <h6>Current-Rank</h6>
            <h4>{profile?.rewards?.rank}</h4>
          </div>
          <div>
            <h6>All-time points</h6>
            <h4>{profile?.rewards?.total_reward_points}</h4>
          </div>
        </div>
      </div>

      <div className="CardMarkDetails">
        <div className="ReportFirstCardMark">
          <div style={{ borderRight: "1px solid #e4e4e4" }}>
            <h6 style={{ color: "#6B6B6B" }}>Total Submission</h6>
            <h5>{profile?.researcher_submissions?.total_submissions}</h5>
          </div>
          <div className="ps-2 " style={{ borderRight: "1px solid #e4e4e4" }}>
            <h6 style={{ color: "#6B6B6B" }}>Valid Submission</h6>
            <h5>{profile?.researcher_submissions?.total_approved_submissions}</h5>
          </div>
          <div className="ps-2" style={{ borderRight: "1px solid #e4e4e4" }}>
            <h6 style={{ color: "#6B6B6B" }}>Programs</h6>
            <h5>{profile?.researcher_programs?.total_programs}</h5>
          </div>
          <div className="ps-2" style={{ borderRight: "1px solid #e4e4e4" }}>
            <h6 style={{ color: "#6B6B6B" }}>Accuracy</h6>
            <h5>{profile?.researcher_submissions?.total_submissions && profile?.researcher_submissions?.total_approved_submissions &&
              parseInt(parseInt(profile?.researcher_submissions?.total_approved_submissions) / parseInt(profile?.researcher_submissions?.total_submissions) * 100)
            } %</h5>
          </div>
          <div className="ps-2">
            <h6 style={{ color: "#6B6B6B" }}>Average Severity</h6>
            <h5>{profile?.researcher_submissions?.total_submissions && profile?.researcher_submissions?.total_approved_submissions &&
              parseInt(parseInt(profile?.researcher_submissions?.total_approved_submissions) / parseInt(profile?.researcher_submissions?.total_submissions) * 100 / 3.14)
            }
            </h5>
          </div>
        </div>
      </div>
      <h6 className="subHeading">Leaderboard Rank</h6>
      <div className="LeaderboardCard">
        {/* fourth Card */}
        <div className="LeaderboardRank-card">
          <div className="rankImg">
            <img src={quality} alt="quality" />
          </div>
          <div className="leaderboard-desc">
            <p style={{ color: "#9F9899" }}>{userAccData?.username}</p>
            <h4>
              {getOrdinalSuffix(profile?.rewards?.rank)}
            </h4>
            <p style={{ color: "#616161", fontSize: "90%" }}>
              <b>{profile?.researcher_submissions?.total_approved_submissions}</b> submission
            </p>
          </div>
        </div>
      </div>
      {/* Rank Details */}
      <div className="StatReportRank-details">
        <div className="achiveHeading">
          <h4>Achievement</h4>
        </div>

        <div className="fullCard-Container">
          <h6 className="subHeading">Unlocked</h6>
          <div className="rankCard-container">
            {
              profile?.researcher_badge_level?.achieved_badges?.map(badge => {
                return <div className="StsReportRank-card" key={badge.badge_title}>
                  <div className="rankImg">
                    <img src={Ranking} alt="ranking" />
                  </div>
                  <h6>{badge?.badge_title}</h6>
                  <p style={{ fontSize: '0.80rem' }}>
                    Level - {badge.level}
                  </p>
                </div>
              })
            }
          </div>

          <h6 className="subHeading">In Progress</h6>
          <div className="secondrankCard">
            {/* fourth Card */}

            {
              profile?.researcher_badge_level?.in_progress_badges?.map((badge, index) => {
                return <div className="StsReportRank-card" key={index}>
                  <div className="rankImg">
                    <img src={quality} alt="quality" />
                  </div>
                  <h6>{badge.badge_title}</h6>
                  <div
                    className="progress"
                    style={{ width: "80%", margin: "auto" }}
                  >
                    <div
                      className="progress-bar"
                      style={{
                        width: `${calculatePercentage(badge.comment)}%`,
                        height: "7px",
                        backgroundColor: "#FF6701",
                      }}
                    ></div>
                  </div>
                  <div>
                    <p className="pt-2" style={{ textAlign: "center", fontSize: ".80rem" }}>
                      {badge.comment}
                    </p>
                  </div>
                </div>
              })
            }
          </div>
          <h6 className="subHeading">Locked Badges</h6>
          <div className="secondrankCard">
            {
              profile?.researcher_badge_level?.locked_badges?.map((badge, index) => {
                return <div className="StsReportRank-card" key={index}>
                  <div className="rankImg">
                    <img src={quality} alt="quality" />
                  </div>
                  <h6>{badge.badge_title}</h6>
                  <p>
                    {badge.comment}
                  </p>
                </div>
              })
            }
          </div>

        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="stats-container row w-100">
        <div className="pathStas">
          <Link to="">Home &gt; </Link>
          <Link to="">&nbsp; Insights &gt;</Link>
          <Link to="">&nbsp; Stats </Link>
        </div>

        <h3 className="stats-heading ">Performance Stats</h3>

        <div className="StatsReport-page row">
          <div className="OptionNav col-3">
            <ul>
              <li
                className={activeNavItem === "All Time" ? "active" : ""}
                onClick={() => {
                  setToggle(4);
                  setActiveNavItem("All Time");
                }}
              >
                All Time
              </li>
              <li
                className={activeNavItem === "LastMonth" ? "active" : ""}
                onClick={() => {
                  setToggle(3);
                  setActiveNavItem("LastMonth");
                }}
              >
                Last Month
              </li>

            </ul>
          </div>

          <div className="details-section col-9 ">
            {Alltime}
          </div>
        </div>
      </div>
    </>
  );
}
