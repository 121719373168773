import React, { useState } from "react";
import "./components/css/RegSucessfully.msg.css";

export default function RegSucessfully({ isOpen, onClose, regEmail }) {
	return (
		<>
			{isOpen && (<div className="RegModalOverly" style={{ zIndex: '99999999', }}>
				<div className="RegSuccessContainer">
					<i className="fa-solid fa-circle-check  rightMark"></i>
					<h5 className="RegSuccessMessage">
						Thank You !
					</h5>
					<div className="RegSuccessDescription">
						<p>
							Your details have been successfully submitted. You will receive an email once the admin reviews your account.
						</p>
					</div>
					<div >
						<button class="btn btn-primary me-2 bg-primary" onClick={onClose} >Close</button>
					</div>
				</div>
			</div>)}

		</>
	);
}



