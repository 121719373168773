import { MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUserInfo } from '../../../../reducers/authSlice';
import HTTPService from '../../../../utils/axios';
import { formatDate } from '../../../../utils/helperFunctions';
import CircularLoader from '../../../CircularLoader/CircularLoader';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import CommonTable from '../../Table/CommonTable';
import AppContext from '../../../Context/AppContext';
import AddModal from '../../modal/AddModal';
import { customToast } from '../../../../App';



const tableColumn = [
    { label: "Project Name", align: 'start' },
    "Program ID",
    "Program Name",
    "Created at",
    "Action",
];
const searchColumn = [
    { name: "Program ID", value: "program_id" },
    { name: "Program Name", value: "program_title" },
];
const JiraProject = () => {
    const { invokeApi } = HTTPService();
    const { memberData, setMemberData, loading, setLoading } = useContext(AppContext);
    const { userInfo, permissions } = useSelector(getUserInfo);
    const [totalPages, setTotalPages] = useState(1)
    const [searchValue, setSearchValue] = useState("");
    const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [mappedProjects, setMappedProjects] = useState();
    const [showModal, setShowModal] = useState(false);
    const [prorgams, setPrograms] = useState([]);
    const [projectList, setProjectList] = useState();
    const [program, setProgram] = useState(' ');
    const [selectedProject, setSelectedProject] = useState(' ');
    const [filterValue, setFilterValue] = useState();
    const { cloudName } = useParams();
    const Breadcrumbname = {
        home: `/${userInfo?.role == 'sub_admin' ? 'admin' : userInfo?.role}/home`,
        Integrations: `/${userInfo?.role == 'sub_admin' ? 'admin' : userInfo?.role}/integrations/jira`,
        Jira: `/${userInfo?.role == 'sub_admin' ? 'admin' : userInfo?.role}/integrations/jira`,
        JiraProjects: `/${userInfo?.role == 'sub_admin' ? 'admin' : userInfo?.role}/integrations/jira/projects`,
    };
    // based on cloudName get project list
    const fetchProjectList = () => {
        setLoading(true);
        invokeApi({
            method: "POST",
            url: `/jira/list-project`,
            data: {
                cloud_name: cloudName
            }, headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            console.log(res)
            setProjectList(res.map(item => ({ ...item, value: item.id, label: item.name })))
            setLoading(false);
        }).catch(error => {

            setLoading(false);
        })
    }
    // get all programs
    const getPrograms = () => {
        let arr = [];
        let url = `programs/`
        if (userInfo?.role === 'customer' && userInfo?.user_data?.id)
            url = `programs/?customer=${userInfo.user_data.id}`
        invokeApi({
            method: "GET",
            url,
        }).then((data) => {
            data.results.map(option => {
                arr.push({
                    label: `${option.program_id} (${option.program_title.length > 30
                        ? option.program_title.slice(0, 30) + "..."
                        : option.program_title
                        })`,
                    value: option.id,
                });
            });
            setPrograms(arr);
        }).catch((error) => {
            customToast.error("Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        });
    }

    useEffect(() => {
        if (userInfo?.user_data?.id) {
            getPrograms();
            fetchProjectList()
            fetchMappedProjects();
        }
    }, [userInfo?.user_data?.id])
    useEffect(() => {
        fetchMappedProjects();
    }, [searchQuery, currentPage, searchValue]);

    const handleSubmitMapProject = () => {
        const selectPrj = projectList.find(prj => prj.id == selectedProject)
        invokeApi({
            method: "POST",
            url: `jira/map-project`,
            data: {
                project_key: selectPrj.key,
                project_name: selectPrj.name,
                program_id: program,
                cloud_name: cloudName
            }
        }).then(res => {
            customToast.success("Program configuration added!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            })
            console.log(res)
            setShowModal(false)
            fetchMappedProjects()
        }).catch(error => {
            customToast.error(error?.data?.error ?? "Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
        })
    }

    const fetchMappedProjects = () => {
        let params = {
            page: currentPage,
        }
        if (searchValue && searchValue.length > 0) {
            params[searchValue] = searchQuery
        }
        invokeApi({
            method: "GET",
            url: `/jira/project-operation`,
            params: {
                cloud_name: cloudName,
                ...params
            }, headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            setMappedProjects(res)
            setTotalPages(res?.total_pages ?? 1)
        }).catch(error => {
            customToast.error("Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
        })
    }

    const handleDelete = (id) => {
        invokeApi({
            method: "DELETE",
            url: `/jira/delete_mapping/${id}`,
        }).then(res => {
            setMemberData({ openmodal: false });
            customToast.success("Deleted successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
            fetchMappedProjects();
            // setMappedProjects()
        }).catch(error => {

            customToast.error(error?.data?.error ?? "Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
            setMemberData({ openmodal: false })
        })
    }

    return (
        <div>
            {loading &&
                <CircularLoader size={100} />}
            <div className="container-fluid py-4 pb-0">
                <div className="row">
                    <div className="col-lg-6 ps-3">
                        <Breadcrumb names={Breadcrumbname} />
                    </div>
                    <div className="col-lg-6">
                        <div className="button-row d-flex justify-content-end mt-0 mb-3">
                            <button className="btn bg-gradient-dark me-3 mb-0 text-capitalize" onClick={() => {
                                setShowModal(true)
                            }}>Add Program</button>
                        </div>
                    </div>
                </div>

                <div className="row my-4">
                    <div className="col-12">
                        <CommonTable
                            column={tableColumn}
                            data={mappedProjects?.results}
                            totalPages={totalPages ?? 1}
                            itemPerPg={25}
                            setTable={1}
                            card={7}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            searchPlaceHolder={searchPlaceHolder}
                            setSearchPlaceHolder={setSearchPlaceHolder}
                            searchBar={true}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            filterValue={filterValue}
                            searchBy={searchColumn}
                            totalData={mappedProjects?.total_records}
                        >
                            <>
                                {mappedProjects?.results &&
                                    mappedProjects?.results?.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <p
                                                    className="text-sm  cursor-pointer mb-0 text-start px-2"
                                                    style={{ color: "#5A78F0" }}
                                                >
                                                    {item.project_name}
                                                </p>
                                            </td>
                                            <td className="text-start">
                                                <p
                                                    className="text-sm  cursor-pointer mb-0 px-2"
                                                    style={{ color: "#5A78F0" }}
                                                >
                                                    {item?.program_details?.program_id}
                                                </p>
                                            </td>
                                            <td className="text-start">
                                                <p
                                                    className="text-sm  cursor-pointer mb-0 px-2"
                                                    style={{ color: "#5A78F0" }}
                                                >
                                                    {item?.program_details?.program_title}
                                                </p>
                                            </td>

                                            <td className="text-center">
                                                <p className="text-sm  mb-0 text-center">
                                                    {formatDate(item.created_at, 1)}
                                                </p>
                                            </td>
                                            <td className="text-center">
                                                <button onClick={() => {
                                                    setMemberData({
                                                        handlesubmit: () => handleDelete(item.id),
                                                        openmodal: true,
                                                    });
                                                }} className='btn btn-danger'>Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                            </>
                        </CommonTable>
                    </div>
                </div>
            </div>

            {showModal ? (
                <div className="changeStatus" style={{ zIndex: 2 }}>
                    <div className="changeStatusBody">
                        <div className="changeStatusBodyTop">
                            <i className="fa-solid fa-circle-exclamation fa-rotate-180"></i>
                            <p>Map Project with Program</p>
                        </div>
                        <div className="p-4">
                            <Select
                                fullWidth
                                value={selectedProject}
                                onChange={(e) => setSelectedProject(e.target.value)}
                                sx={{
                                    mt: 1,
                                    ".paper": {
                                        height: "200px !important",
                                    },
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                }}  >
                                <MenuItem value=' ' sx={{ fontSize: '1.2rem' }}>
                                    Select Project
                                </MenuItem>
                                {projectList?.map((option) => (
                                    <MenuItem key={option.value} value={option.value} sx={{ fontSize: '1.2rem' }}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                fullWidth
                                value={program}
                                onChange={(e) => setProgram(e.target.value)}
                                sx={{
                                    mt: 1,
                                    ".paper": {
                                        height: "200px !important",
                                    },
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                }}  >
                                <MenuItem value=' ' sx={{ fontSize: '1.2rem' }}>
                                    Select Program
                                </MenuItem>
                                {prorgams.map((option) => (
                                    <MenuItem key={option.value} value={option.value} sx={{ fontSize: '1.2rem' }}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="changeStatusBodyLastPart">
                            <button
                                onClick={() => setShowModal(false)}
                                className="changeStatusBtnClose"
                            >
                                CLOSE
                            </button>

                            <button onClick={handleSubmitMapProject} className="changeStatusBtnSubmit">
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}

            {memberData.openmodal === true && (
                <AddModal
                    cancelBtn={"Cancel"}
                    submitBtn={"SUBMIT"}
                    createAddTopic={"Delete"}
                    confirmText={"Delete"}
                    isOpen={memberData.openmodal}
                    onSubmit={memberData.handlesubmit}
                    onClose={() => setMemberData({ openmodal: false })}
                />
            )}

        </div>
    )
}

export default JiraProject;