import React, { useContext, useEffect, useState } from "react";
import useHttp from "../Hooks/useHttp";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getObjectKeys } from "../../utils/helperFunctions";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import AppContext from "../Context/AppContext";
import HTTPService from "../../utils/axios";
import AddModal from "./modal/AddModal";
import { customToast } from "../../App";

export default function Social() {
	const { invokeApi } = HTTPService();
	const navigate = useNavigate();
	const [linkLoading, setLinkLoading] = useState(false);
	const { memberData, setMemberData, setCircularLoading } = useContext(AppContext);
	const { userId } = useParams();
	const [formData, setFormData] = useState({});
	const [cancleToPrev, setCancleToPrev] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const token = localStorage.getItem("token");
	const API = process.env.REACT_APP_API_URL;
	const location = useLocation();

	const { PatchRequest, GetRequest, PostRequest } = useHttp();
	useEffect(() => {
		fetchUserData();
	}, []);

	const fetchUserData = async () => {
		let userType = "user_profile";
		// if (location.pathname.includes('researcher')) {
		//   userType = 'user_profile';
		// } else if (location.pathname.includes('user')) {
		//   userType = 'user_profile';
		// }
		setLinkLoading(true);

		invokeApi({
			method: "GET",
			url: `${userType}/?user=${userId}`,
		})
			.then((data) => {
				setLinkLoading(false);

				if (data.results && data.results[0]) {
					setFormData(data.results[0]);
					setCancleToPrev(data.results[0]);
					setIsEdit(true);
				} else {
					setIsEdit(false);
				}
			})
			.catch((error) => {
				setLinkLoading(false);
				const flattenedErrors = Object.keys(error.data)
					.map(key => key.replaceAll('_', ' ').toUpperCase() + ": " + error.data[key].join('\n'))
					.join('\n');
				customToast.error(flattenedErrors ?? "Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
				});
			});
	};

	const handleChange = (e) => {
		let data = { ...formData };
		data[e.target.name] = e.target.value;
		setFormData(data);
	};

	const handleAddEdit = async (type) => {
		//  variable that will be send in payload
		let apiData = {
			...formData,
		};
		if (type === "edit") {
			setCircularLoading(true);
			setMemberData({ openmodal: false });
			const { data, error } = await PatchRequest(
				API + `user_profile/${apiData.id}/`,
				{
					...apiData,
				},
				{},
				{
					Authorization: "Bearer  " + token,
					"Content-Type": "multipart/form-data",
				}
			);
			if (error) {
				setCircularLoading(false);
				console.log(error);
				const flattenedErrors = Object.keys(error.data)
					.map(key => key.replaceAll('_', ' ').toUpperCase() + ": " + error.data[key].join('\n'))
					.join('\n');
				customToast.error(flattenedErrors ?? "Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
				});
			}
			if (data) {
				setCircularLoading(false);
				customToast.success("status updated", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}
		} else {
			setCircularLoading(true);
			const { data, error } = await PostRequest(
				API + `user_profile/`,
				{
					...apiData,
					user: userId,
				},
				{
					Authorization: "Bearer  " + token,
					"Content-Type": "multipart/form-data",
				}
			);
			if (error) {
				setCircularLoading(false);
				console.log(error);
				const flattenedErrors = Object.keys(error.data)
					.map(key => key.replaceAll('_', ' ').toUpperCase() + ": " + error.data[key].join('\n'))
					.join('\n');
				customToast.error(flattenedErrors ?? "Something went wrong!", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
				});
			}
			if (data) {
				setCircularLoading(false);
				customToast.success("status updated", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}
		}
	};

	return (
		<>
			<div className="card mt-0" id="basic-info">
				<div className="card-header mb-1">
					<h5 className="mb-0">Include Social Media Links</h5>
				</div>
				<div className="card-body px-5">
					<div className="row">
						<div className="col-lg-2 d-flex align-items-center">
							<div className="d-flex align-items-center">
								<i className="fab fa-xl fa-linkedin"></i>
								<h5 className="m-0 p-0 ms-3">Linked In</h5>
							</div>
						</div>
						<div className="col-lg-6">
							{linkLoading ? (
								<Skeleton width={"100%"} height={40} />
							) : (
								<div className="input-group">
									<input
										className="form-control m-0"
										type="text"
										placeholder="Enter The URL "
										required="required"
										name="linked_in"
										value={formData?.linked_in}
										onChange={handleChange}
									/>
								</div>
							)}
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-lg-2 d-flex align-items-center">
							<div className="d-flex align-items-center">
								<i className="fab fa-xl fa-facebook-square"></i>
								<h5 className="m-0 p-0 ms-3">Facebook</h5>
							</div>
						</div>
						<div className="col-lg-6">
							{linkLoading ? (
								<Skeleton width={"100%"} height={40} />
							) : (
								<div className="input-group">
									<input
										className="form-control m-0"
										type="text"
										placeholder="Enter The URL "
										required="required"
										name="facebook"
										value={formData?.facebook}
										onChange={handleChange}
									/>
								</div>
							)}
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-lg-2 d-flex align-items-center">
							<div className="d-flex align-items-center">
								<i className="fab fa-xl fa-twitter-square"></i>
								<h5 className="m-0 p-0 ms-3">Twitter | X</h5>
							</div>
						</div>
						<div className="col-lg-6">
							{linkLoading ? (
								<Skeleton width={"100%"} height={40} />
							) : (
								<div className="input-group">
									<input
										className="form-control m-0"
										type="text"
										placeholder="Enter The URL "
										required="required"
										name="twitter_x"
										value={formData?.twitter_x}
										onChange={handleChange}
									/>
								</div>
							)}
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-lg-2 d-flex align-items-center">
							<div className="d-flex align-items-center">
								<i className="fab fa-xl fa-youtube-square"></i>
								<h5 className="m-0 p-0 ms-3">Youtube</h5>
							</div>
						</div>
						<div className="col-lg-6">
							{linkLoading ? (
								<Skeleton width={"100%"} height={40} />
							) : (
								<div className="input-group">
									<input
										className="form-control m-0"
										type="text"
										placeholder="Enter The URL "
										required="required"
										name="youtube"
										value={formData?.youtube}
										onChange={handleChange}
									/>
								</div>
							)}
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-lg-2 d-flex align-items-center">
							<div className="d-flex align-items-center">
								<i className="fab fa-xl fa-instagram-square"></i>
								<h5 className="m-0 p-0 ms-3">Instagram</h5>
							</div>
						</div>
						<div className="col-lg-6">
							{linkLoading ? (
								<Skeleton width={"100%"} height={40} />
							) : (
								<div className="input-group">
									<input
										className="form-control m-0"
										type="text"
										placeholder="Enter The URL "
										required="required"
										name="instagram"
										value={formData?.instagram}
										onChange={handleChange}
									/>
								</div>
							)}
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-lg-12">
							<button
								className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0"
								// type="button"
								onClick={() => {
									setMemberData({
										handlesubmit: () => handleAddEdit(isEdit ? "edit" : "add"),
										openmodal: true,
										social: true,
									});
								}}
							>
								Submit Details
							</button>
							<button
								className="btn  bg-danger text-white btn-sm me-3 float-end mt-4 mb-0"
								onClick={() => navigate(-1)}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
			{memberData.social === true && (
				<AddModal
					cancelBtn={"Cancel"}
					submitBtn={"SUBMIT"}
					createAddTopic={"Update Social"}
					confirmText={"Update Social"}
					isOpen={memberData.openmodal}
					onSubmit={memberData.handlesubmit}
					onClose={() => setMemberData({ openmodal: false })}
				/>
			)}
		</>
	);
}
