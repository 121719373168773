import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./components/css/bugbusterslabs.webflow.css";
import "./components/css/policy.css"

export default function Privacypolicy() {
	return (
		<>
			<Header />
			<section className="uui-section_faq01 privacy">
				<div className="uui-page-padding-3">
					<div className="uui-container-large-3">
						<div className="uui-padding-vertical-xhuge-3" style={{paddingTop:"3rem"}}>
							<div className="uui-text-align-center-3">
								<div className="uui-max-width-large-3 align-center">
									<h2 className="uui-heading-medium-2 privacyPolicyText">
										Privacy Policy
									</h2>
								</div>
								{/* <div class="uui-space-xsmall-7"></div> */}
								<div class="uui-text-size-large-7 text-start mt-5 mb-5">
									<div
										style={{ width: "60%", color: "#000", fontSize: "16px" }}
									>
										Bugbusterslabs Private Limited <br />
										As of February 13, 2024, Bugbusterslabs Private Limited and
										its aliates (together referred to as "Bugbusterslabs," "we,"
										"us," or "our") have revised our Privacy Policy.
										<br />
										Last updated: 13-02-2024
									</div>
								</div>
								<h3
									class="uui-heading text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Introduction
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									Welcome to Bugbusterslabs Private Limited. We prioritize your
									privacy. Our policy at Bugbusterslabs is to uphold your
									privacy and adhere to all relevant laws and regulations
									concerning any personal data we gather about you. This applies
									to our website, 
									<a style={{marginRight:"4px",marginLeft:"4px"}} href="https://bugbusterslabs.com">
										 https://bugbusterslabs.com
									</a>
									, and other sites under our ownership and operation.
									<br />
									<br />
									Personal information encompasses details that can identify
									you. This involves personal data like your name, address, date
									of birth, information about your devices, payment particulars,
									and even data on how you interact with a website or online
									service.
								</div>
								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Information We Collect
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									We might gather the following categories of personal data:
									<br />
									<br />
									<ul>
										<li>
											Contact Information: Includes name, email address, and
											phone number.
										</li>
										<li>
											Account Information: Comprises usernames, passwords, and
											security questions.
										</li>
										<li>
											Usage Information: Encompasses IP addresses, device
											details, browser type, visited pages, and additional
											analytical data.
										</li>
										<li>
											Payment Information: Involves credit card particulars and
											billing address (if needed).
										</li>
										<li>
											Communication Information: Could you forward any
											correspondence, feedback, or questions you send us?
										</li>
									</ul>
								</div>
								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									How We Use Your Information
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ fontSize: "16px", color: "#000" }}
								>
									We might utilize your personal information for the following
									reasons:
									<br />
									<br />
									<ul>
										<li>Offering, maintaining, and enhancing our services.</li>
										<li>
											Handling transactions and sending transaction-related
											messages.
										</li>
										<li>
											Addressing your inquiries and delivering customer support.
										</li>
										<li>
											Sending newsletters, promotional materials, or other
											communications.
										</li>
										<li>
											Observing and analyzing usage patterns to improve our
											website and services.
										</li>
										<li>
											Adhering to legal obligations and safeguarding against
											fraudulent activities.
										</li>
									</ul>
								</div>
								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Cookies and Tracking Technologies
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ fontSize: "16px", color: "#000" }}
								>
									We utilize cookies and related tracking technologies to gather
									data on how you engage with our website. Adjusting your
									browser settings to disable cookies can aect your browsing
									experience on our site.
								</div>

								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Third-Party Disclosure
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ fontSize: "16px", color: "#000" }}
								>
									We do not disclose, trade, or pass on your personal
									information to third parties without your consent except when
									we have the law or it is necessary to deliver our services.
								</div>

								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Security
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ fontSize: "16px", color: "#000" }}
								>
									We implement appropriate security measures to safeguard your
									personal information from unauthorized access, disclosure,
									alteration, and destruction.
								</div>

								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Your Choices
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ fontSize: "16px", color: "#000" }}
								>
									You can just go ahead and opt out of our promotional
									communications by following the instructions in our emails. If
									you need to update or delete your personal information, please
									get in touch with us without any hesitation.
								</div>

								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Children's Privacy
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ fontSize: "16px", color: "#000" }}
								>
									Our services are intended for individuals aged 13 and above.
									We do not knowingly gather personal information from children.
								</div>

								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Changes to This Privacy Policy
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ fontSize: "16px", color: "#000" }}
								>
									We reserve the right to amend this Privacy Policy at any time.
									Any changes will take eect once they are published on our
									website.
								</div>

								<h3
									class="uui-heading text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Contact Us
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ fontSize: "16px", color: "#000" }}
								>
									If you have any questions about this Privacy Policy, please
									contact us at 
									<a style={{marginLeft:"4px", marginRight:"4px"}} href="mailto:legal@bugbusterslabs.com">
										 legal@bugbusterslabs.com
									</a>
									.
								</div>

								<div
									class="uui-text-size-large-7 text-start mt-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Thank you for trusting Bugbusterslabs Private Limited. Your
									privacy is important to us.
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}
