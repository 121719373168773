import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

import useHttp from "../../Hooks/useHttp";
import { ToastContainer, toast } from "react-toastify";
import AddModal from "../../common/modal/AddModal";
import PhoneInput from "react-phone-input-2";
import { options } from "../../assets/js/plugins/dropzone.min";
import AppContext from "../../Context/AppContext";
import HTTPService from "../../../utils/axios";
import { customToast } from "../../../App";

export default function Addcompany() {
  const { invokeApi } = HTTPService();
  const [openAddModal, setOpenAddModal] = useState(false);
  const { setCircularLoading } = useContext(AppContext);
  const { PostRequest, GetRequest } = useHttp();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [allotmentOpt, setAllotment] = useState();

  const [formState, setFormState] = useState(1);
  const [errors, setErrors] = useState({});
  const [valids, setValids] = useState({});
  const [formData, setFormData] = useState({});

  const [phValue, setPhValue] = useState();
  console.log("PH--->>>", phValue, formData);
  const handleOnChange = (value, country) => {
    const phoneNumberWithoutCode = value
      .replace(`${country.dialCode}`, "")
      .trim();

    setFormData({
      ...formData,
      phone_number: phoneNumberWithoutCode,
      iso: `+${country.dialCode}`,
    });
    setPhValue({ value });

    if (!phoneNumberWithoutCode) {
      setErrors({
        ...errors,
        phone_number: "Please provide Number.",
      });
      setValids({
        ...valids,
        phone_number: false,
      });
    } else {
      setErrors({
        ...errors,
        phone_number: null,
      });
      setValids({
        ...valids,
        phone_number: true,
      });
    }
  };

  console.log("formdata", formData);
  const token = localStorage.getItem("token");
  const API = process.env.REACT_APP_API_URL;
  const Breadcrumbname = {
    home: "/admin/home",
    "Master Module": "/admin/company/dashboard",
    Company: "/admin/company/dashboard",
    "Add Company": "/admin/company/add",
  };

  const checkCompanyValidity = () => {
    const {
      allotment_type,
      domain_name,
      legal_name,
      email,
      support_email,
      phone_number,
      website_title,
    } = formData;
    const CompanyErrors = {};

    if (!domain_name || domain_name === "") {
      CompanyErrors.domain_name = "Please provide Domain Name.";
    }
    if (!allotment_type || allotment_type === "") {
      CompanyErrors.allotment_type = "Please Select Allotment Type.";
    }
    if (!legal_name || legal_name === "") {
      CompanyErrors.legal_name = "Please provide Legal Name.";
    }
    if (!email || email === "") {
      CompanyErrors.email = "Please provide Valid Email.";
    }
    if (!support_email || support_email === "") {
      CompanyErrors.support_email = "Please provide Support Email.";
    }
    if (!phone_number || phone_number === "") {
      CompanyErrors.phone_number = "Please provide Number.";
    }
    if (!website_title || website_title === "") {
      CompanyErrors.website_title = "Please provide Website Title.";
    }
    return CompanyErrors;
  };

  const checkAddressValidity = () => {
    const { address1, landmark, city, state, country, CIN, TAN, PAN, GSTIN } =
      formData;
    const AddressError = {};

    if (!address1 || address1 === "") {
      AddressError.address1 = "Please provide primary Address.";
    }
    if (!landmark || landmark === "") {
      AddressError.landmark = "Please provide Landmark.";
    }
    if (!city || city === "") {
      AddressError.city = "Please provide City.";
    }
    if (!state || state === "") {
      AddressError.state = "Please provide State.";
    }
    if (!country || country === "") {
      AddressError.country = "Please provide Country.";
    }
    if (!CIN || CIN === "") {
      AddressError.CIN = "Please provide CIN Number.";
    }
    if (!TAN || TAN === "") {
      AddressError.TAN = "Please provide TAN Number.";
    }
    if (!PAN || PAN === "") {
      AddressError.PAN = "Please provide PAN Number.";
    }
    if (!GSTIN || GSTIN === "") {
      AddressError.GSTIN = "Please provide GSTIN Number.";
    }
    return AddressError;
  };
  // step handeler
  const handelstatestep = (e, step) => {
    // const form = document.getElementById("mulstep");
    // if (form.checkValidity() == false) {
    // 	e.preventDefault();
    // 	e.stopPropagation();
    // } else {
    // 	setFormState(step);
    // }
    // setValidated(true);
    if (formState === 1) {
      const CompanyError = checkCompanyValidity();
      if (Object.keys(CompanyError).length > 0) {
        setErrors(CompanyError);
      } else {
        setFormState(step);
      }
    } else if (formState === 2) {
      const AddressErr = checkAddressValidity();
      if (Object.keys(AddressErr).length > 0) {
        setErrors(AddressErr);
      } else {
        setFormState(step);
      }
    } else {
      setFormState(step);
    }
  };

  const handleChange = async (event) => {
    const { name, type, files } = event.target;
    const field = name;

    if (type === "file") {
      const file = files[0];

      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64 = reader.result.split(",")[1];
          const extensionMatch = file.name.match(/\.([^.]+)$/);
          const extension = extensionMatch[1].toLowerCase();

          // Update formData with the new file object
          // setFormData((prevData) => ({
          // 	...prevData,
          // 	[name]: [
          // 		...(prevData[name] || []), // Ensure prevData[name] is an array or initialize as an empty array
          // 		{
          // 			type: extension,
          // 			content: base64,
          // 			size: getFileSizeInMB(file),
          // 		},
          // 	],
          // }));
          setFormData({ ...formData, [name]: file });
        };

        reader.readAsDataURL(file);
      }
      // setValidated(true);
    } else {
      const value = event.target.value;

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      if (!value || value === "") {
        setErrors({
          ...errors,
          [name]:
            field === "domain_name"
              ? "Please provide Domain Name."
              : field === "legal_name"
                ? "Please provide Legal Name."
                : field === "email"
                  ? "Please provide Valid Email."
                  : field === "support_email"
                    ? "Please provide Support Email."
                    : field === "phone_number"
                      ? "Please provide Number."
                      : field === "website_title"
                        ? "Please provide Website Title."
                        : field === "address1"
                          ? "Please provide primary Address."
                          : field === "landmark"
                            ? "Please provide Landmark."
                            : field === "city"
                              ? "Please provide City."
                              : field === "state"
                                ? "Please provide State."
                                : field === "country"
                                  ? "Please provide Country."
                                  : field === "CIN"
                                    ? "Please provide CIN Number."
                                    : field === "TAN"
                                      ? "Please provide TAN Number."
                                      : field === "PAN"
                                        ? "Please provide PAN Number."
                                        : field === "GSTIN"
                                          ? "Please provide GSTIN Number."
                                          : field === "allotment_type"
                                            ? "Please Select Allotment Type."
                                            : null,
        });
        setValids({
          ...valids,
          [name]: false,
        });
      } else {
        setValids({
          ...valids,
          [name]: true,
        });
        setErrors({
          ...errors,
          [name]: null,
        });
      }

      // setValidated(true);
    }
  };

  const fetchAllotmentType = async () => {
    invokeApi({
      method: "GET",
      url: "basevalues/?title_code=allotment_type",
    })
      .then((data) => {
        setAllotment(data.results[0].value_options);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  useEffect(() => {
    fetchAllotmentType();
  }, []);

  function getFileSizeInMB(file) {
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

    if (fileSizeInMB > 5) {
      // customToast.error("Please select max 5 MB File only");
      return false;
    }
    return fileSizeInMB.toFixed(2);
  }

  const handelstatestepPrew = (e, step) => {
    e.preventDefault();
    setFormState(step);
  };

  const handalSubmit = async (event) => {
    setOpenAddModal(false);
    setCircularLoading(true);
    const {
      domain_name,
      legal_name,
      email,
      phone_number,
      support_email,
      support_phone,
      website_title,
      landline_number,
      allotment_type,
      header_logo,
      leader_logo,
      footer_logo,
      favicon_logo,
    } = formData;
    const param = {
      domain_name,
      legal_name,
      email,
      support_email,
      support_phone,
      website_title,
      landline_number,
      allotment_type,
      header_logo,
      leader_logo,
      footer_logo,
      favicon_logo,
      phone_number: phValue,
      is_deleted: false,
    };

    const { data, error } = await PostRequest(API + "company/", param, {
      Authorization: "Bearer  " + token,
      "Content-Type": "multipart/form-data",
    });
    if (error) {
      setCircularLoading(false);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
    if (data) {
      setCircularLoading(false);
      customToast.success("Company added successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      console.log("company Data", data);
      const companyId = data.id;
      const RegAddress = async () => {
        const {
          address1,
          address2,
          address3,
          landmark,
          phone,
          city,
          state,
          country,
          CIN,
          TAN,
          PAN,
          GSTIN,
        } = formData;
        const param = {
          address1,
          address2,
          address3,
          landmark,
          phone,
          city,
          state,
          country,
          CIN,
          TAN,
          PAN,
          GSTIN,
          company: companyId,
          is_deleted: false,
        };

        const { data, error } = await PostRequest(
          API + "company_address/",
          param,
          {
            Authorization: "Bearer  " + token,
            "Content-Type": "multipart/form-data",
          }
        );
        if (error) {
          customToast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
        if (data) {
          customToast.success("Company added successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          console.log("company Data", data);
        }
      };
      RegAddress();
      const CompanyLink = async () => {
        const {
          facebook,
          instagram,
          twitter,
          pinterest,
          google_plus,
          youtube,
          extra,
          b2buser_android,
          b2buser_ios,
        } = formData;
        const param = {
          facebook,
          instagram,
          twitter,
          pinterest,
          google_plus,
          youtube,
          extra,
          b2buser_android,
          b2buser_ios,
          company: companyId,
          is_deleted: false,
        };

        const { data, error } = await PostRequest(
          API + "comapny_link/",
          param,
          {
            Authorization: "Bearer  " + token,
            "Content-Type": "multipart/form-data",
          }
        );
        if (error) {
          customToast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
        if (data) {
          customToast.success("Company added successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          console.log("company Data", data);
        }
      };
      CompanyLink();

      setTimeout(() => {
        navigate("/admin/company/dashboard");
      }, 2000);
    }
  };

  return (
    <>
      <div className="container-fluid py-4" style={{ height: "120vh" }}>
        <div className="row">
          <div className="col-lg-12 m-auto ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="multisteps-form mb-5">
              <div className="row">
                <div className="col-12 col-lg-10 mx-auto my-5 ">
                  <div className="multisteps-form__progress">
                    {formState >= 1 ? (
                      <button
                        className="multisteps-form__progress-btn js-active"
                        type="button"
                        title="User Info"
                      >
                        Basic Profile
                      </button>
                    ) : (
                      <button
                        className="multisteps-form__progress-btn"
                        type="button"
                        title="User Info"
                      >
                        Basic Profile
                      </button>
                    )}

                    {formState >= 2 ? (
                      <button
                        className="multisteps-form__progress-btn js-active"
                        type="button"
                        title="Socials"
                      >
                        Registration & Address
                      </button>
                    ) : (
                      <button
                        className="multisteps-form__progress-btn"
                        type="button"
                        title="Verification"
                      >
                        Registration & Address
                      </button>
                    )}

                    {formState >= 3 ? (
                      <button
                        className="multisteps-form__progress-btn js-active"
                        type="button"
                        title="Socials"
                      >
                        Social Media Link
                      </button>
                    ) : (
                      <button
                        className="multisteps-form__progress-btn"
                        type="button"
                        title="Verification"
                      >
                        Social Media Link
                      </button>
                    )}
                    {formState >= 4 ? (
                      <button
                        className="multisteps-form__progress-btn js-active"
                        type="button"
                        title="Profile"
                      >
                        Mobile App Links
                      </button>
                    ) : (
                      <button
                        className="multisteps-form__progress-btn"
                        type="button"
                        title="Profile"
                      >
                        Mobile App Links
                      </button>
                    )}
                    {formState === 5 ? (
                      <button
                        className="multisteps-form__progress-btn js-active"
                        type="button"
                        title="Profile"
                      >
                        Logos & Others
                      </button>
                    ) : (
                      <button
                        className="multisteps-form__progress-btn"
                        type="button"
                        title="Profile"
                      >
                        Logos & Others
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-10 m-auto">
                  <Form
                    className="multisteps-form__form mb-8"
                    noValidate
                    // validated={validated}
                    // onSubmit={handalSubmit}
                    id="mulstep"
                  >
                    {formState === 1 ? (
                      <div
                        className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
                        data-animation="FadeIn"
                      >
                        <div className="multisteps-form__content">
                          <h5 className="font-weight-bolder">Basic Profile</h5>
                          <div className="row mt-3">
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Domain Name">
                                Allotment Type
                              </Form.Label>
                              <Form.Select
                                className="multisteps-form__input"
                                type="text"
                                name="allotment_type"
                                placeholder="Enter Domain Name"
                                required
                                // onChange={(e) =>
                                // 	setDomainName("domain_name", e.target.value)
                                value={
                                  formData &&
                                  formData.allotment_type &&
                                  formData.allotment_type
                                }
                                isValid={
                                  valids.allotment_type &&
                                  valids.allotment_type === true
                                }
                                isInvalid={!!errors.allotment_type}
                                onChange={handleChange}
                              >
                                <option value="">Select Allotment Type</option>
                                {allotmentOpt?.split(",").map((item, index) => (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.allotment_type}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Domain Name">
                                Domain Name
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="domain_name"
                                placeholder="Enter Domain Name"
                                required
                                // onChange={(e) =>
                                // 	setDomainName("domain_name", e.target.value)
                                // }
                                onChange={handleChange}
                                value={
                                  formData &&
                                  formData.domain_name &&
                                  formData.domain_name
                                }
                                // isValid={formData && formData.}
                                isInvalid={!!errors.domain_name}
                                isValid={
                                  valids.domain_name &&
                                  valids.domain_name === true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.domain_name}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Domain Name">
                                Legal Name
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="legal_name"
                                placeholder="Enter Legal Name "
                                value={
                                  formData &&
                                  formData.legal_name &&
                                  formData.legal_name
                                }
                                isInvalid={!!errors.legal_name}
                                isValid={
                                  valids.legal_name &&
                                  valids.legal_name === true
                                }
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.legal_name}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom03"
                          >
                            <Form.Label label="Domain Name">Email</Form.Label>
                            <Form.Control
                              className="multisteps-form__input"
                              type="email"
                              name="email"
                              placeholder="Enter Email "
                              value={
                                formData && formData.email && formData.email
                              }
                              isInvalid={!!errors.email}
                              isValid={valids.email && valids.email === true}
                              onChange={handleChange}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom03"
                          >
                            <Form.Label label="Support Mail">
                              Support Mail
                            </Form.Label>
                            <Form.Control
                              className="multisteps-form__input"
                              type="email"
                              name="support_email"
                              placeholder="Enter Support Mail "
                              value={
                                formData &&
                                formData.support_email &&
                                formData.support_email
                              }
                              isInvalid={!!errors.support_email}
                              isValid={
                                valids.support_email &&
                                valids.support_email === true
                              }
                              onChange={handleChange}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.support_email}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="row mt-3">
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom03"
                          >
                            <Form.Label htmlFor="">
                              Phone Number
                              <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <div style={{ width: "100%" }}>
                              <PhoneInput
                                country={"91"}
                                placeholder="Enter phone number"
                                value={phValue?.value ?? ""}
                                onChange={(value, country) =>
                                  handleOnChange(value, country)
                                }
                                inputStyle={{
                                  width: "100%",
                                  borderColor: errors.phone_number
                                    ? "red"
                                    : undefined,
                                }}
                              />
                            </div>

                            {errors.phone_number && (
                              <div className="invalid-feedback d-block">
                                {errors.phone_number}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom03"
                          >
                            <Form.Label label="Websit Title">
                              Website Title
                            </Form.Label>
                            <Form.Control
                              className="multisteps-form__input"
                              type="text"
                              name="website_title"
                              placeholder="Enter Website Title"
                              value={
                                formData &&
                                formData.website_title &&
                                formData.website_title
                              }
                              isInvalid={!!errors.website_title}
                              isValid={
                                valids.website_title &&
                                valids.website_title === true
                              }
                              onChange={handleChange}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.website_title}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="row mt-3">
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom03"
                          >
                            <Form.Label label="Websit Title">
                              Support Phone
                            </Form.Label>
                            <Form.Control
                              className="multisteps-form__input"
                              type="number"
                              name="support_phone"
                              placeholder="Enter Support Phone Number"
                              value={
                                formData &&
                                formData.support_phone &&
                                formData.support_phone
                              }
                              isValid={
                                valids.support_phone &&
                                valids.support_phone === true
                              }
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom03"
                          >
                            <Form.Label label="Websit Title">
                              Land Line Number
                            </Form.Label>
                            <Form.Control
                              className="multisteps-form__input"
                              type="number"
                              name="landline_number"
                              placeholder="Enter Land Line Number"
                              value={
                                formData &&
                                formData.landline_number &&
                                formData.landline_number
                              }
                              isValid={
                                valids.landline_number &&
                                valids.landline_number === true
                              }
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </div>
                        <div className="button-row d-flex mt-4">
                          <button
                            className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                            type="button"
                            title="Next"
                            onClick={(e) => handelstatestep(e, 2)}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {formState === 2 ? (
                      <div
                        className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
                        data-animation="FadeIn"
                      >
                        <h5 className="font-weight-bolder">
                          Registration & Address
                        </h5>
                        <div className="multisteps-form__content">
                          <div className="row mt-3">
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Websit Title">
                                Address 1
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="address1"
                                placeholder="Enter Address 1"
                                value={
                                  formData &&
                                  formData.address1 &&
                                  formData.address1
                                }
                                isInvalid={!!errors.address1}
                                isValid={
                                  valids.address1 && valids.address1 === true
                                }
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.address1}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Websit Title">
                                Address 2
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="address2"
                                placeholder="Enter Address 2"
                                value={
                                  formData &&
                                  formData.address2 &&
                                  formData.address2
                                }
                                isValid={
                                  valids.address2 && valids.address2 === true
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Address 3 ">
                                Address 3
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="address3"
                                placeholder="Enter Address 3"
                                value={
                                  formData &&
                                  formData.address3 &&
                                  formData.address3
                                }
                                isValid={
                                  valids.address3 && valids.address3 === true
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </div>
                          <div className="row mt-3">
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Landmark">Landmark</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="landmark"
                                placeholder="Enter Nearest Land Mark"
                                value={
                                  formData &&
                                  formData.landmark &&
                                  formData.landmark
                                }
                                isInvalid={!!errors.landmark}
                                isValid={
                                  valids.landmark && valids.landmark === true
                                }
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.landmark}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Phone">Phone</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="number"
                                name="phone"
                                placeholder="Enter Phone Number"
                                value={
                                  formData && formData.phone && formData.phone
                                }
                                isValid={valids.phone && valids.phone === true}
                                onChange={handleChange}
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="City">City</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="city"
                                placeholder="Enter City"
                                value={
                                  formData && formData.city && formData.city
                                }
                                isInvalid={!!errors.city}
                                isValid={valids.city && valids.city === true}
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.city}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="row mt-3">
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="State">State</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="state"
                                placeholder="Enter State"
                                value={
                                  formData && formData.state && formData.state
                                }
                                isInvalid={!!errors.state}
                                isValid={valids.state && valids.state === true}
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.state}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Country">Country</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="country"
                                placeholder="Enter Country"
                                value={
                                  FormData &&
                                  formData.country &&
                                  formData.country
                                }
                                isInvalid={!!errors.country}
                                isValid={
                                  valids.country && valids.country === true
                                }
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.country}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="CIN">CIN</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="CIN"
                                placeholder="Enter CIN Number"
                                value={formData && formData.CIN && formData.CIN}
                                isInvalid={!!errors.CIN}
                                isValid={valids.CIN && valids.CIN === true}
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.CIN}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="row mt-3">
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="TAN">TAN</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="TAN"
                                placeholder="Enter TAN Number"
                                value={formData && formData.TAN && formData.TAN}
                                isInvalid={!!errors.TAN}
                                isValid={valids.TAN && valids.TAN === true}
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.TAN}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="PAN">PAN</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="PAN"
                                placeholder="Enter PAN Number"
                                value={formData && formData.PAN && formData.PAN}
                                isInvalid={!!errors.PAN}
                                isValid={valids.PAN && valids.PAN === true}
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.PAN}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="GSTIN">GSTIN</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="GSTIN"
                                placeholder="Enter GSTIN Number"
                                value={
                                  formData && formData.GSTIN && formData.GSTIN
                                }
                                isInvalid={!!errors.GSTIN}
                                isValid={valids.GSTIN && valids.GSTIN === true}
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.GSTIN}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="row">
                            <div className="button-row d-flex mt-4 col-12">
                              <button
                                className="btn bg-gradient-dark mb-0 js-btn-prev"
                                type="button"
                                title="Prev"
                                onClick={(e) => handelstatestepPrew(e, 1)}
                              >
                                Prev
                              </button>
                              <button
                                className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                                type="button"
                                title="Next"
                                onClick={(e) => handelstatestep(e, 3)}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {formState === 3 ? (
                      <div
                        className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
                        data-animation="FadeIn"
                      >
                        <h5 className="font-weight-bolder">
                          Social Media Link
                        </h5>
                        <div className="multisteps-form__content">
                          <div className="row mt-3">
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Facebook">Facebook</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="facebook"
                                placeholder="Enter Facebook URL"
                                value={
                                  formData &&
                                  formData.facebook &&
                                  formData.facebook
                                }
                                isValid={
                                  valids.facebook && valids.facebook === true
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Instagram">
                                Instagram
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="instagram"
                                placeholder="Enter Instagram URL"
                                value={
                                  formData &&
                                  formData.instagram &&
                                  formData.instagram
                                }
                                isValid={
                                  valids.instagram && valids.instagram === true
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Twitter">Twitter</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="twitter"
                                placeholder="Enter Twitter URL"
                                value={
                                  formData &&
                                  formData.twitter &&
                                  formData.twitter
                                }
                                isValid={
                                  valids.twitter && valids.twitter === true
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </div>
                          <div className="row mt-3">
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Pinterest">
                                Pinterest
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="pinterest"
                                placeholder="Enter Pinterest URL"
                                value={
                                  formData &&
                                  formData.pinterest &&
                                  formData.pinterest
                                }
                                isValid={
                                  valids.pinterest && valids.pinterest === true
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Googleplus">
                                Google+
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="google_plus"
                                placeholder="Enter Google+ URL"
                                value={
                                  formData &&
                                  formData.google_plus &&
                                  formData.google_plus
                                }
                                isValid={
                                  valids.google_plus &&
                                  valids.google_plus === true
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Googleplus">
                                Youtube
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="youtube"
                                placeholder="Enter Youtube URL"
                                value={
                                  formData &&
                                  formData.youtube &&
                                  formData.youtube
                                }
                                isValid={
                                  valids.youtube && valids.youtube === true
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </div>
                          <div className="row mt-3">
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Googleplus">Extra</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="extra"
                                placeholder="Enter Extra URL"
                                value={
                                  formData && formData.extra && formData.extra
                                }
                                isValid={valids.extra && valids.extra === true}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </div>
                          <div className="row">
                            <div className="button-row d-flex mt-4 col-12">
                              <button
                                className="btn bg-gradient-dark mb-0 js-btn-prev"
                                type="button"
                                title="Prev"
                                onClick={(e) => handelstatestepPrew(e, 2)}
                              >
                                Prev
                              </button>
                              <button
                                className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                                type="button"
                                title="Next"
                                onClick={(e) => handelstatestep(e, 4)}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {formState === 4 ? (
                      <div
                        className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
                        data-animation="FadeIn"
                      >
                        <h5 className="font-weight-bolder">Mobile Apps Link</h5>
                        <div className="multisteps-form__content mt-3">
                          <div className="row">
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Googleplus">
                                B2B User Android
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="b2buser_android"
                                placeholder="Enter Android Link"
                                value={
                                  formData &&
                                  formData.b2buser_android &&
                                  formData.b2buser_android
                                }
                                isValid={
                                  valids.b2buser_android &&
                                  valids.b2buser_android === true
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Googleplus">
                                B2B User IOS
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="b2buser_ios"
                                placeholder="Enter IOS Link"
                                value={
                                  formData &&
                                  formData.b2buser_ios &&
                                  formData.b2buser_ios
                                }
                                isValid={
                                  valids.b2buser_ios &&
                                  valids.b2buser_ios === true
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </div>
                          <div className="button-row d-flex mt-4">
                            <button
                              className="btn bg-gradient-dark mb-0 js-btn-prev"
                              type="button"
                              title="Prev"
                              onClick={(e) => handelstatestepPrew(e, 3)}
                            >
                              Prev
                            </button>
                            <button
                              className="btn bg-gradient-dark ms-auto mb-0"
                              type="button"
                              title="Send"
                              onClick={(e) => handelstatestep(e, 5)}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {formState === 5 ? (
                      <div
                        className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
                        data-animation="FadeIn"
                      >
                        <h5 className="font-weight-bolder">Logos & Others</h5>
                        <div className="multisteps-form__content mt-3">
                          <div className="row">
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="headerlogo">
                                Header Logo
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="file"
                                name="header_logo"
                                isValid={
                                  valids.header_logo &&
                                  valids.header_logo === true
                                }
                                // value={formData.header_logo}
                                onChange={handleChange}
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="headerlogo">Leader</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="file"
                                name="leader_logo"
                                isValid={
                                  valids.leader_logo &&
                                  valids.leader_logo === true
                                }
                                // value={formData.leader_logo}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </div>
                          <div className="row mt-3">
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="headerlogo">
                                Footer Logo
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="file"
                                name="footer_logo"
                                isValid={
                                  valids.footer_logo &&
                                  valids.footer_logo === true
                                }
                                // value={formData.footer_logo}
                                onChange={handleChange}
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="headerlogo">
                                Favicon Logo
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="file"
                                name="favicon_logo"
                                isValid={
                                  valids.favicon_logo &&
                                  valids.favicon_logo === true
                                }
                                // value={formData.favicon_logo}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </div>
                          <div className="button-row d-flex mt-4">
                            <button
                              className="btn bg-gradient-dark mb-0 js-btn-prev"
                              type="button"
                              title="Prev"
                              onClick={(e) => handelstatestepPrew(e, 4)}
                            >
                              Prev
                            </button>
                            <button
                              className="btn bg-gradient-dark ms-auto mb-0"
                              type="button"
                              title="submit"
                              onClick={() => setOpenAddModal(true)}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"SUBMIT"}
        createAddTopic={"Add Company"}
        confirmText={"Add The Company"}
        isOpen={openAddModal}
        onSubmit={handalSubmit}
        onClose={() => setOpenAddModal(false)}
      />
    </>
  );
}
