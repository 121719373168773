import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import walletDashboard from "../../assets/img/wallet-solid.svg";
import "./payments.css";
import { Link, useNavigate } from "react-router-dom";
import HTTPService from "../../../utils/axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../reducers/authSlice";
import { customToast } from "../../../App";
const Breadcrumbname = {
	home: "/customer/home",
	Payments: "",
	Wallet: "/customer/payment/wallet",
	"Add Money": "",
};

const CustomerAddMoney = () => {
	const navigate = useNavigate();
	const { userAccData } = useSelector(getUserInfo)
	let [formData, setFormData] = useState({});
	let [fees, setFees] = useState(0);
	let [paymentLoading, setPaymentLoading] = useState(false);
	const [currBalance, setCurrBalance] = useState(500);
	const { invokeApi } = HTTPService();

	const handleChange = (field, value) => {
		setFormData(prev => {
			return {
				...prev,
				[field]: value
			}
		})
	}
	const getWalletFee = () => {
		invokeApi({
			method: "GET",
			url: "basevalues/",
			params: {
				title_code: 'wallet_fees'
			}
		}).then((res) => {
			if (res.results && res.results.length > 0) {
				setFees(parseFloat(res.results[0].value_options.replace('%', '')))
			}
			// setEarningData(res);
		}).catch((error) => {
			customToast.error("Unable to fetch earning data", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		});

	}
	const handleAddMoney = () => {
		let data = {
			...formData,
			user_id: userAccData?.id,
			wallet_id: userAccData?.wallet_id,
		};
		data.amount = parseFloat(formData?.amount) + parseFloat(formData?.amount * fees / 100)
		setPaymentLoading(true)
		invokeApi({
			method: "POST",
			url: "transactions/checkout-session",
			data
		}).then((res) => {
			console.log("transaction", res)
			window.location.href = res.checkout_session_url
			// openInNewTab(res.checkout_session_url);
			// setEarningData(res);
		}).catch((error) => {
			customToast.error("Unable to fetch earning data", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		});

	}
	// Function to open a URL in a new tab
	function openInNewTab(url) {
		var win = window.open(url, '_blank', 'width=800,height=600');
		win.focus();
	}
	useEffect(() => {
		if (userAccData?.id) {
			getWalletFee()
			setFormData(prev => {

				return {
					...prev,
					name: userAccData?.first_name,
					email: userAccData?.email,
				}
			})
		}
	}, [userAccData?.id]);

	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row w-100 d-flex justify-content-center">
					<div className="addCategoryArea  my-4">
						<div className="">
							<div className="row">
								<div className="col-lg-6 ps-3">
									<Breadcrumb names={Breadcrumbname} />
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="card">
								<div className="row px-5 pb-5">
									<div className=" mt-0 " id="basic-info">
										<div className="card-header">
											<h5>ADD MONEY TO WALLET</h5>
										</div>
										<div
											className="px-4 py-5"
											style={{
												border: "2px solid #CACACA",
												borderRadius: "20px",
											}}
										>
											<div
												className="d-flex justify-content-between align-items-center mb-4 pb-3"
												style={{ borderBottom: "2px solid #CACACA" }}
											>
												<div>
													<p
														style={{
															color: "#000",
															fontWeight: "600",
															fontSize: "20px",
															marginBottom: "0px",
														}}
													>
														Bugbusterslabs Money Wallet
													</p>
													<span style={{ fontSize: "18px", color: "#353535" }}>
														Current Balance: <b>${currBalance}</b>
													</span>
												</div>
												<div style={{ height: "89px" }}>
													<img
														style={{ height: "100%" }}
														src={walletDashboard}
														alt=""
													/>
												</div>
											</div>

											<div className="mb-5">
												<span
													style={{
														fontSize: "18px",
														color: "#000",
														fontWeight: "bold",
														paddingLeft: "15px",
													}}
												>
													Enter an Amount
												</span>
											</div>
											<div className="CAM-fieldBox  mb-3">
												{/* <button onClick={() => handlePrice(1)}>$500</button>
												<button onClick={() => handlePrice(2)}>$1000</button>
												<button onClick={() => handlePrice(3)}>$2000</button>
												<button onClick={() => handlePrice(4)}>$5000</button> */}

												<div className="CAM-field">
													<label htmlFor="">Wallet Amount </label>
													<div className="inpEnterPriceDiv">
														<span style={{ color: "#000" }}>$</span>
														<input
															type="number"
															value={formData?.amount ?? 0}
															onChange={(e) => handleChange('amount', e.target.value)}
															placeholder="0.00"
														/>
													</div>
												</div>

												<div className="CAM-field">
													<label htmlFor="">Fees ({fees} %)</label>
													<div className="inpEnterPriceDiv">
														<span style={{ color: "#000" }}>$</span>
														<input
															type="number"
															value={formData?.amount * fees / 100}
															// onChange={(e) => handleChange('fee', e.target.value)}
															placeholder="0.00"
															disabled
														/>
													</div>
												</div>

												<div
													className="CAM-field"
													style={{ paddingTop: "20px" }}
												>
													<label htmlFor="">Total </label>
													<div className="inpEnterPriceDiv">
														<span style={{ color: "#000" }}>$</span>
														<input
															type="number"
															value={parseFloat(formData?.amount) + parseFloat(formData?.amount * fees / 100)}
															onChange={(e) => handleChange('amount', e.target.value)}
															placeholder="0.00"
															readOnly
															disabled
														/>
													</div>
												</div>
												<div
													className="CAM-field"
													style={{ paddingTop: "20px" }}
												>
													<label htmlFor="">Description </label>
													<div className="inpEnterPriceDiv">
														<textarea
															cols={41}
															value={formData?.description}
															onChange={(e) => handleChange('description', e.target.value)}
														/>
													</div>
												</div>
												<div
													className="CAM-field"
													style={{ paddingTop: "20px" }}
												>
													<label htmlFor="">Name </label>
													<div className="inpEnterPriceDiv">
														<input
															type="text"
															value={formData?.name}
															onChange={(e) => handleChange('name', e.target.value)}
														/>
													</div>
												</div>
												<div
													className="CAM-field"
													style={{ paddingTop: "20px" }}
												>
													<label htmlFor="">Email </label>
													<div className="inpEnterPriceDiv">
														<input
															type="text"
															value={formData?.email}
															onChange={(e) => handleChange('email', e.target.value)}
														/>
													</div>
												</div>
												<div
													className="CAM-field"
													style={{ paddingTop: "20px" }}
												>
													<label htmlFor="">Address </label>
													<div className="inpEnterPriceDiv">
														<textarea
															cols={41}
															value={formData?.address}
															onChange={(e) => handleChange('address', e.target.value)}
														/>
													</div>
												</div>
												<div
													className="CAM-field"
													style={{ paddingTop: "20px" }}
												>
													<label htmlFor="">Postal Code </label>
													<div className="inpEnterPriceDiv">
														<input
															type="text"
															value={formData?.postal_code}
															onChange={(e) => handleChange('postal_code', e.target.value)}
														/>
													</div>
												</div>
												<div
													className="CAM-field"
													style={{ paddingTop: "20px" }}
												>
													<label htmlFor="">City </label>
													<div className="inpEnterPriceDiv">
														<input
															type="text"
															value={formData?.city}
															onChange={(e) => handleChange('city', e.target.value)}
														/>
													</div>
												</div>
												<div
													className="CAM-field"
													style={{ paddingTop: "20px" }}
												>
													<label htmlFor="">State </label>
													<div className="inpEnterPriceDiv">
														<input
															type="text"
															value={formData?.state}
															onChange={(e) => handleChange('state', e.target.value)}
														/>
													</div>
												</div>
												<div
													className="CAM-field"
													style={{ paddingTop: "20px" }}
												>
													<label htmlFor="">Country </label>
													<div className="inpEnterPriceDiv">
														<input
															type="text"
															value={formData?.country}
															onChange={(e) => handleChange('country', e.target.value)}
														/>
													</div>
												</div>
											</div>
											<div className=" d-flex justify-content-end">
												<button
													onClick={() => navigate(-1)}
													to="/customer/payment/wallet"
													className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
												>
													Cancel
												</button>
												<button
													onClick={handleAddMoney}
													className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
												>
													Add Money
												</button>
											</div>
										</div>
										<p
											style={{
												color: "#9e1212",
												paddingLeft: "20px",
												paddingTop: "8px",
												fontSize: "15px",
											}}
										>
											* A Nominal Platform Fees Will be Charge on every
											transactions
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CustomerAddMoney;
