import React from "react";
import Skeleton from "react-loading-skeleton";

export default function PrgmSummarySkeleton() {
  return (
    <div style={{width:"100vw"}}>
      <div className="container-fluid  mt-4">
        <div className="row" style={{height:"200px"}}>
          <div className="col-4">
            {/* demo title part */}
              <Skeleton height={"100%"} width={"100%"} />
          </div>
          <div className="col-4">
              <Skeleton height={"100%"} width={"100%"} />
          </div>
          <div className="col-4">
              <Skeleton height={"100%"} width={"100%"} />
          </div>

          
        </div>
        <div className="row" style={{height:"500px", width:"100%"}}>
          {/* your assigned submission */}
          <div className="mt-4 col-6">
            <Skeleton height={"100%"} width={"100%"} />
          </div>
          <div className="col-6 mt-4">
          <Skeleton height={"100%"} width={"100%"} />
          </div>
        </div>
      </div>
    </div>
  );
}
