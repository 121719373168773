import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrumb(prop) {
  return (
    <>
      <div className="container-fluid px-0">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb px-0">
            {prop.names &&
              Object.entries(prop.names).map(([name, link], index) => (
                <li
                  key={index}
                  className={
                    prop.names.length === index + 1
                      ? "breadcrumb-item active"
                      : "breadcrumb-item"
                  }
                  aria-current={prop.names.length === index + 1 ? "page" : ""}
                >
                  <Link to={link}>{name}</Link>
                </li>
              ))}
          </ol>
        </nav>
      </div>
    </>
  );
}
