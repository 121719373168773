import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import PhoneInput from "react-phone-input-2";
import useHttp from "../BackEnd/Hooks/useHttp";
import HTTPService from "../utils/axios";
import { acceptedMediaFileTypes, acceptedProofFileTypes, passwordPattern } from "../utils/helperFunctions";
import "./components/css/Signup.css";
import RegSucessfully from "./RegSucessfully";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { Col } from "react-bootstrap";
import AddModal from "../BackEnd/common/modal/AddModal";
import CircularLoader from "../BackEnd/CircularLoader/CircularLoader";
import { customToast } from "../App";

function convertToObject(obj) {
  const validations = {};
  const errors = {};
  for (let key in obj) {
    validations[key] = false;
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      errors[key] = key + " " + obj[key].join(", ");
    } else {
      errors[key] = obj[key];
    }
  }
  return { errors, validations };
}
const Signup = (props) => {
  const { invokeApi } = HTTPService();
  const [isChecked, setIsChecked] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [valids, setValids] = useState({});
  const location = useLocation();
  const [isGender, setIsGender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [genderValue, setGenderValue] = useState("");
  const API = process.env.REACT_APP_API_URL;
  const { PostRequest } = useHttp();

  const [regModal, setRegModal] = useState(false);
  const navigate = useNavigate();
  const [phValue, setPhValue] = useState();

  const [passwordIcon, setPasswordIcon] = useState(eyeOff);
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(eyeOff);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [otp, setOtp] = useState();
  const [emailOtp, setEmailOtp] = useState("");
  const [formState, setFormState] = useState(1);
  const [openAddModal, setOpenAddModal] = useState(false);


  const handlePasswordToggle = () => {
    if (passwordType === "password") {
      setPasswordIcon(eye);
      setPasswordType("text");
    } else {
      setPasswordIcon(eyeOff);
      setPasswordType("password");
    }
  };

  const handleConfirmPasswordToggle = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordIcon(eye);
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordIcon(eyeOff);
      setConfirmPasswordType("password");
    }
  };

  const handleOnChange = (value, country) => {
    const phoneNumberWithoutCode = value
      .replace(`${country.dialCode}`, "")
      .trim();
    setPhoneNumber("phone_number", phoneNumberWithoutCode);
    setPhValue({ value });

    setCountryCode("Country_Code", `+${country.dialCode}`);
  };

  const handleKeyPress = (event) => {
    const keyPressed = event.key;
    if (!isNaN(keyPressed)) {
      event.preventDefault();
    }
  };

  const setFirstName = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter First Name",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  const setLastName = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Last Name",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  const setUserName = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter UserName",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      invokeApi({
        method: "GET",
        url: "check_user_exists/?username=" + value,
        noToken: true
      }).then((data) => {
        if (data && data.results && data.results[0].status === true) {
          setErrors({
            ...errors,
            username: "Username already registered",
          });
          setValids({
            ...valids,
            [field]: false,
          });
        } else {
          setValids({
            ...valids,
            [field]: true,
          });
          setErrors({
            ...errors,
            [field]: null,
          });
        }
      });
    }
  };
  const setEntityName = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Company Name",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const setEmail = async (field, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setForm({
      ...form,
      [field]: value.toLowerCase(),
    });
    if (!value || value === "" || !emailRegex.test(value)) {
      setErrors({
        ...errors,
        [field]: "Please Enter valid Email",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      invokeApi({
        method: "GET",
        url: "check_user_exists/?email=" + value.toLowerCase(),
        noToken: true
      }).then((data) => {
        if (data && data.results && data.results[0].status === true) {
          setErrors({
            ...errors,
            email: "Email already registered",
          });
          setValids({
            ...valids,
            [field]: false,
          });
        } else {
          setValids({
            ...valids,
            [field]: true,
          });
          setErrors({
            ...errors,
            [field]: null,
          });
        }
      });
    }
  };
  const setCountryCode = async (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Country Code",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  const setPhoneNumber = async (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Phone Number",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      invokeApi({
        method: "GET",
        url: "check_user_exists/?phone_number=" + form.Country_Code + value,
        noToken: true
      }).then((data) => {
        if (data && data.results && data.results[0].status === true) {
          setErrors({
            ...errors,
            phone_number: "Phone number already registered",
          });
          // phoneNumberCheck = true;
          setValids({
            ...valids,
            [field]: false,
          });
        } else {
          setValids({
            ...valids,
            [field]: true,
          });
          // phoneNumberCheck = false;
          setErrors({
            ...errors,
            [field]: null,
          });
        }
      });
    }
  };
  //Password
  const setPassword = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    console.log(!passwordPattern.test(value), value)
    if (!passwordPattern.test(value)) {
      setErrors({
        ...errors,
        [field]: "Password does not meet the criteria.",
      });
      setValids({
        ...valids,
        [field]: false,
      });
      return
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please enter password",
      });
    }
  };

  //Confirm Password
  const setConfirmPassword = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (form.password === value) {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
      return null
    } else if (form.password != value) {
      setErrors({
        ...errors,
        [field]: "Password and Confirm Password are not same.",
      });
      return null
    } else {
      setErrors({
        ...errors,
        [field]: "Password Confirmation Failed",
      });
    }
    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please re-enter  password",
      });
    }
  };

  const sendPhoneOtp = async () => {
    const { phone_number, Country_Code } = form;
    const phnumber = Country_Code + phone_number;
    invokeApi({
      method: "POST",
      url: "verify/phone/",
      data: { phone_number: phnumber },
      noToken: true
    }).then((data) => {

    }).catch((error) => {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  };
  const sendVerificationEmail = async () => {
    const { email } = form;
    invokeApi({
      method: "POST",
      url: "send-email-otp/",
      data: { email: email },
      noToken: true
    }).then((data) => {

    }).catch((error) => {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  };
  //Verify Phone OTP
  const verifyPhoneOtp = async (e) => {
    e.preventDefault();
    const { phone_number, Country_Code } = form;
    const phnumber = Country_Code + phone_number;

    invokeApi({
      method: "POST",
      url: "verify/code/",
      noToken: true,
      data: {
        phone_number: phnumber.toString(),
        verification_code: otp.toString(),
      },
    }).then((data) => {
      setForm({
        ...form,
        Phone_Verfication: true,
      });
      if (!!errors["Phone_Verfication"])
        setErrors({
          ...errors,
          Phone_Verfication: null,
        });
    }).catch((error) => {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setErrors({
        ...errors,
        Phone_Verfication: "Invalid OTP",
      });
    });
  };

  //Verify Email OTP
  const verifyEmailOtp = async (e) => {
    e.preventDefault();
    const { email } = form;
    invokeApi({
      method: "POST",
      url: "verify-email-otp/",
      noToken: true,
      data: {
        email: email,
        verification_code: emailOtp,
      },
    }).then((data) => {
      setForm({
        ...form,
        email_Verfication: true,
      });
      if (!!errors["email_Verfication"])
        setErrors({
          ...errors,
          email_Verfication: null,
        });
    }).catch((error) => {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setErrors({
        ...errors,
        email_Verfication: "Invalid OTP",
      });
    });
  };
  //id proof
  const setIdProofNumber = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Id Proof Number",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //id proof doc
  const setIdDocFile = async (field, e) => {
    const value = e.target.files[0];
    if (value && value.size > 5 * 1024 * 1024) {
      customToast.error(`File "${value.name}" exceeds the maximum upload size of 5MB.`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
      e.target.value = null;
      return null;
    }
    if (value && !acceptedProofFileTypes.includes(value?.type)) {
      customToast.error(`${value.name} not supported`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,

      })
      e.target.value = null;
      return null;
    }
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Upload Id Proof Document",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //address proof number
  const setAddressProofNumber = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Address Proof Number",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //address proof doc
  const setAddressDocFile = async (field, e) => {
    const value = e.target.files[0];
    if (value && value.size > 5 * 1024 * 1024) {
      customToast.error(`File "${value.name}" exceeds the maximum upload size of 5MB.`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
      e.target.value = null;
      return null;
    }
    if (value && !acceptedProofFileTypes.includes(value?.type)) {
      customToast.error(`${value.name} not supported`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
      e.target.value = null;
      return null;
    }
    setForm({
      ...form,
      [field]: value,
    });
    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Upload Address Proof Document",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  // display pic
  const setProfilePic = async (field, e) => {
    const value = e.target.files[0];
    if (value && value.size > 5 * 1024 * 1024) {
      customToast.error(`File "${value.name}" exceeds the maximum upload size of 5MB.`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
      e.target.value = null;
      return null;
    }
    if (value && !acceptedMediaFileTypes.includes(value?.type)) {
      customToast.error(`${value.name} not supported`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
      e.target.value = null;
      return null;
    }
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Upload Profile Picture",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };


  //short description
  const setShortDesc = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter a short description",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const checkProfileValidity = () => {
    const { display_pic, Short_Description } = form;

    const profileErrors = {};

    // display_pic errors
    if (!display_pic || display_pic === "" || display_pic.size === 0)
      profileErrors.display_pic = "Please upload profile picture";

    // Short_Description errors
    if (!Short_Description || Short_Description === "")
      profileErrors.Short_Description = "Please enter a short description";

    return profileErrors;
  };
  const finalSubmit = async () => {
    const param = {
      ...form,
      phone_number: form.Country_Code + form.phone_number,
      user_status: "pending",
      is_deleted: false,
    };
    if (location.pathname === "/researcher/signup") {
      param.gender = genderValue
    }
    setLoading(true);
    setOpenAddModal(false);
    invokeApi({
      method: "POST",
      url: location.pathname === "/researcher/signup" ? "researcher/" : "customer/",
      data: param,
      noToken: true
    })
      .then((data) => {
        setLoading(false);
        // navigate("/");
        setRegModal(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        const flattenedErrors = typeof error.data == 'object' ? Object.keys(error.data)
          .map(key => key.replaceAll('_', ' ').toUpperCase() + ": " + error.data[key].join('\n'))
          .join('\n') : undefined;
        customToast.error(flattenedErrors ?? "Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      });

  };

  const saveProfileInformation = () => {
    const profileErrors = checkProfileValidity();
    if (Object.keys(profileErrors).length > 0) {
      setErrors(profileErrors);
      setOpenAddModal(false)
    } else {
      finalSubmit();
    }
  };

  const checkBasicInformationValidity = () => {
    const {
      first_name,
      last_name,
      entity_name,
      username,
      email,
      Country_Code,
      phone_number,
      password,
      confirm_password,
    } = form;

    const basicInformationErrors = {};

    // first_name errors
    if (!first_name || first_name === "")
      basicInformationErrors.first_name = "Please enter first name";

    // last_name errors
    if (!last_name || last_name === "")
      basicInformationErrors.last_name = "Please enter last name";

    // username errors
    if (!username || username === "")
      basicInformationErrors.username = "Please enter username";

    // email errors
    if (!email || email === "")
      basicInformationErrors.email = "Please enter email";

    // Country_Code errors
    if (!Country_Code || Country_Code === "")
      basicInformationErrors.Country_Code = "Please select country code";

    // phone_number errors
    if (!phone_number || phone_number === "" || errors.phone_number)
      basicInformationErrors.phone_number = errors.phone_number ?? "Please enter phone number";

    if (location.pathname === "/researcher/signup") {
      if (!genderValue || genderValue === "") {
        basicInformationErrors.genderValue = "Please select gender";
      }
    } else {
      if (!entity_name || entity_name === "") {
        basicInformationErrors.entity_name = "Please enter entity name";
      }
    }

    // password errors
    if (!password || password === "")
      basicInformationErrors.password = "Please enter password";

    // password errors
    if (!confirm_password || confirm_password === "")
      basicInformationErrors.confirm_password = "Please enter confirm password";

    //password confirmation errors
    if (
      password &&
      password !== "" &&
      confirm_password &&
      confirm_password !== "" &&
      password !== confirm_password
    )
      basicInformationErrors.confirm_password = "Password confirmation failed";

    return basicInformationErrors;
  };
  //Save Basic Information
  const saveBasicInformation = () => {
    const basicInformationErrors = checkBasicInformationValidity();
    console.log(basicInformationErrors)
    if (Object.keys(basicInformationErrors).length > 0) {
      setErrors(basicInformationErrors);
    } else {
      if (!!form["Phone_Verfication"])
        setForm({
          ...form,
          Phone_Verfication: false,
        });
      if (!!form["email_Verfication"])
        setForm({
          ...form,
          email_Verfication: false,
        });
      setOtp("");
      setEmailOtp("");
      sendVerificationEmail();
      sendPhoneOtp();
      setFormState(2);
    }
  };

  const checkVerificationValidity = () => {
    const { email_Verfication, Phone_Verfication } = form;

    const verificationErrors = {};

    // Phone OTP errors
    if (
      !Phone_Verfication ||
      Phone_Verfication === false ||
      Phone_Verfication === null
    )
      verificationErrors.Phone_Verfication = "Please verify phone number";

    // Email OTP errors
    if (
      !email_Verfication ||
      email_Verfication === false ||
      email_Verfication === null
    )
      verificationErrors.email_Verfication = "Please verify email";

    return verificationErrors;
  };

  const saveVerificationInformation = () => {
    const verificationErrors = checkVerificationValidity();
    if (Object.keys(verificationErrors).length > 0) {
      setErrors(verificationErrors);
    } else {
      setFormState(3);
    }
  };

  //kyc validity check
  const checkKycValidity = () => {
    const { id_proof, id_proof_doc, address_proof, address_proof_doc } = form;

    const kycErrors = {};

    // id_proof errors
    if (!id_proof || id_proof === "")
      kycErrors.id_proof = "Please enter id  proof number";

    // id_proof_doc errors
    if (!id_proof_doc || id_proof_doc === "" || id_proof_doc.size === 0)
      kycErrors.id_proof_doc = "Please upload id  proof";

    // address_proof errors
    if (!address_proof || address_proof === "")
      kycErrors.address_proof = "Please enter your address proof number";

    // address_proof_doc errors
    if (
      !address_proof_doc ||
      address_proof_doc === "" ||
      address_proof_doc.size === 0
    )
      kycErrors.address_proof_doc = "Please upload address proof";

    return kycErrors;
  };

  //kyc info save
  const saveKycInformation = () => {
    const kycErrors = checkKycValidity();
    if (Object.keys(kycErrors).length > 0) {
      setErrors(kycErrors);
    } else {
      setFormState(4);
    }
  };
  return (
    <>
      {loading && <CircularLoader size={100} />}
      <section className="container-fluid mainsignupDiv">
        <div className="row mt-4 mb-4">
          <div className="multisteps-form">
            <div className="row">
              <div className="col-12 col-lg-10 mx-auto my-5 ">
                <div className="multisteps-form__progress">
                  {formState >= 1 ? (
                    <button
                      className="multisteps-form__progress-btn js-active"
                      type="button"
                      title="User Info"
                    >
                      User Info
                    </button>
                  ) : (
                    <button
                      className="multisteps-form__progress-btn"
                      type="button"
                      title="User Info"
                    >
                      User Info
                    </button>
                  )}

                  {formState >= 2 ? (
                    <button
                      className="multisteps-form__progress-btn js-active"
                      type="button"
                      title="Socials"
                    >
                      Verification
                    </button>
                  ) : (
                    <button
                      className="multisteps-form__progress-btn"
                      type="button"
                      title="Verification"
                    >
                      Verification
                    </button>
                  )}
                  {formState >= 3 ? (
                    <button
                      className="multisteps-form__progress-btn js-active"
                      type="button"
                      title="Socials"
                    >
                      KYC/Uploads
                    </button>
                  ) : (
                    <button
                      className="multisteps-form__progress-btn"
                      type="button"
                      title="Verification"
                    >
                      KYC/Uploads
                    </button>
                  )}
                  {formState === 4 ? (
                    <button
                      className="multisteps-form__progress-btn js-active"
                      type="button"
                      title="Profile"
                    >
                      Profile
                    </button>
                  ) : (
                    <button
                      className="multisteps-form__progress-btn"
                      type="button"
                      title="Profile"
                    >
                      Profile
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="multisteps-form__form">

              <div style={formState === 1 ? { opacity: 1, position: 'relative' } : { position: 'absolute', opacity: 0, visibility: 'hidden' }}>
                <div className="row mb-4">
                  <div className="col-6">
                    <Form.Group className="d-flex flex-column">
                      <Form.Label htmlFor="">
                        First Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        className="signupInp"
                        type="text"
                        required
                        placeholder="Enter First Name"
                        isInvalid={!!errors.first_name}
                        isValid={valids.first_name && valids.first_name === true}
                        value={form && form.first_name && form.first_name}
                        onChange={(e) => setFirstName("first_name", e.target.value)}
                        onKeyPress={handleKeyPress}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.first_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="d-flex flex-column">
                      <Form.Label htmlFor="">
                        Last Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        className="signupInp"
                        type="text"
                        required
                        placeholder="Enter Last Name"
                        isInvalid={!!errors.last_name}
                        isValid={valids.last_name && valids.last_name === true}
                        value={form && form.last_name && form.last_name}
                        onChange={(e) => setLastName("last_name", e.target.value)}
                        onKeyPress={handleKeyPress}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.last_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <Form.Group className="d-flex flex-column">
                      <Form.Label htmlFor="">
                        User Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        className="signupInp"
                        type="text"
                        required
                        placeholder="Enter User Name"
                        isInvalid={!!errors.username}
                        isValid={valids.username && valids.username === true}
                        value={form && form.username && form.username}
                        onChange={(e) => setUserName("username", e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div
                  className={
                    location.pathname === "/customer/signup"
                      ? "row mb-4 d-block"
                      : "d-none"
                  }
                >
                  <div className="col-12">
                    <Form.Group className="d-flex flex-column">
                      <Form.Label htmlFor="">
                        Company Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        className="signupInp"
                        type="text"
                        placeholder="Enter Company Name"
                        isInvalid={!!errors.entity_name}
                        isValid={valids.entity_name && valids.entity_name === true}
                        value={form && form.entity_name && form.entity_name}
                        onChange={(e) =>
                          setEntityName("entity_name", e.target.value)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.entity_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div
                  className={
                    location.pathname === "/researcher/signup"
                      ? "row mb-4 d-block"
                      : "d-none"
                  }
                >
                  <div className="col-12">
                    <Form.Group
                      className="d-flex flex-column"
                      style={{ position: "relative" }}
                    >
                      <Form.Label htmlFor="">
                        Gender <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        onClick={() => setIsGender(!isGender)}
                        readOnly
                        required
                        className="signupInp"
                        type="text"
                        placeholder="Select Gender"
                        value={genderValue}
                        isInvalid={
                          genderValue === null || genderValue === "" ? true : false
                        }
                        isValid={
                          genderValue !== null || genderValue !== "" ? true : false
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Gender
                      </Form.Control.Feedback>

                      <div
                        className={
                          isGender === true ? "genderDropdown d-block" : "d-none"
                        }
                      >
                        <div
                          className="gender"
                          onClick={() => {
                            setGenderValue("Male");
                            setIsGender(false);
                          }}
                        >
                          <span>Male</span>
                        </div>
                        <div
                          className="gender"
                          onClick={() => {
                            setGenderValue("Female");
                            setIsGender(false);
                          }}
                        >
                          <span>Female</span>
                        </div>
                        <div
                          className="gender"
                          onClick={() => {
                            setGenderValue("Trans");
                            setIsGender(false);
                          }}
                        >
                          <span>Trans</span>
                        </div>
                      </div>

                      <div className="text-danger">
                        <small>
                          <em>
                            {errors.genderValue &&
                              errors.genderValue}
                          </em>
                        </small>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <Form.Group className="d-flex flex-column">
                      <Form.Label htmlFor="">
                        Email <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        className="signupInp"
                        type="text"
                        placeholder="Enter Email"
                        isInvalid={!!errors.email}
                        isValid={valids.email && valids.email === true}
                        value={form && form.email && form.email}
                        onChange={(e) => setEmail("email", e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <Form.Group className="d-flex flex-column">
                      <Form.Label htmlFor="">
                        Phone Number<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <div style={{ width: "100%" }}>
                        <PhoneInput
                          country={"91"}
                          placeholder="Enter phone number"
                          value={phValue?.value ?? ""}
                          onChange={(value, country) =>
                            handleOnChange(value, country)
                          }
                          inputStyle={{ width: "100%" }}
                        />
                      </div>
                      {errors.phone_number && <div className="invalid-warning" style={{ display: 'block' }}>{errors.phone_number}</div>}
                    </Form.Group>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <Form.Group className="d-flex flex-column">
                      <Form.Label htmlFor="">
                        Password <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type={passwordType}
                          required
                          className="signupInp"
                          placeholder="Enter Password"
                          autoComplete="new-password"
                          value={form && form.password && form.password}
                          onChange={(e) => setPassword("password", e.target.value)}
                          isInvalid={!!errors.password}
                          isValid={valids.password && valids.password === true}
                        />
                        <span
                          className="flex justify-around items-center position-absolute"
                          style={{ top: '10px', right: '40px' }}
                          onClick={handlePasswordToggle}
                        >
                          <Icon
                            className="absolute mr-10"
                            icon={passwordIcon}
                            size={25}
                          />
                        </span>
                      </div>
                      {errors.password && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.password}
                        </div>
                      )}
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback> */}
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="d-flex flex-column">
                      <Form.Label htmlFor="">
                        Password confirmation{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          className="signupInp position-relative"
                          required
                          type={confirmPasswordType}
                          placeholder="Confirm Password"
                          value={
                            form && form.confirm_password && form.confirm_password
                          }
                          onChange={(e) =>
                            setConfirmPassword("confirm_password", e.target.value)
                          }
                          isInvalid={!!errors.confirm_password}
                          isValid={
                            valids.confirm_password &&
                            valids.confirm_password === true
                          }
                        />
                        <span
                          className="flex justify-around items-center position-absolute"
                          style={{ top: '10px', right: '40px' }}
                          onClick={handleConfirmPasswordToggle}
                        >
                          <Icon
                            className="absolute mr-10"
                            icon={confirmPasswordIcon}
                            size={25}
                          />
                        </span>
                      </div>
                      {errors.confirm_password && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.confirm_password}
                        </div>
                      )}
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.confirm_password}
                  </Form.Control.Feedback> */}
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="d-flex col-6">
                    <div className="me-3" style={{ color: "#0278B8" }}>
                      <i className="fa-solid fa-circle-exclamation fa-rotate-180"></i>
                    </div>
                    <div>
                      <span style={{ fontSize: "13px" }}>
                        Suggestions for a strong password:
                      </span>
                      <ul
                        style={{
                          fontSize: "13px",
                          paddingLeft: "16px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <li style={{ marginBottom: "10px" }}>
                          A combination of upper and lowercase letters, numbers and
                          special characters
                        </li>
                        <li style={{ marginBottom: "10px" }}>
                          A combination of random words
                        </li>
                        <li style={{ marginBottom: "10px" }}>
                          At least 8 characters or more
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <div className="alreadyAccount">
                      <p className="mb-0">Already have an account?</p>
                      <Link to={props.loginLink}>Log in</Link>
                    </div>
                  </div>
                </div>
                <div className="row mt-4 mb-4">
                  <div className="d-flex justify-content-center">
                    <input
                      className="agreeCheckbox"
                      type="checkbox"
                      checked={isChecked}
                      required
                      onChange={() => setIsChecked(!isChecked)}
                    />
                    <p className="Iaccept">
                      <span style={{ color: "#f00" }}>*</span>I accept the
                      <Link
                        to="/terms&condition"
                        style={{
                          color: "#376CC1",
                          marginLeft: "4px",
                          marginRight: "4px",
                        }}
                      >
                        Terms & Conditions
                      </Link>
                      and the
                      <Link
                        style={{
                          color: "#376CC1",
                          marginLeft: "4px",
                          marginRight: "4px",
                        }}
                        to="/privacypolicy"
                      >
                        Privacy Policy
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="button-row d-flex mt-4">
                  <button
                    className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                    type="button"
                    title="Next"
                    onClick={saveBasicInformation}
                    disabled={!isChecked}     >
                    Next
                  </button>
                </div>
              </div>

              <div style={formState === 2 ? { opacity: 1, position: 'relative' } : { position: 'absolute', opacity: 0, visibility: 'hidden' }}>
                <h5 className="font-weight-bolder">
                  Authenticate User Account
                </h5>
                <div>
                  <div className="row mt-5 m-auto">
                    <div className="col-lg-3 col-12 text-center d-flex align-items-center justify-content-end">
                      <div className=" px-0 ">
                        <i
                          style={{ color: "#7F56D9" }}
                          className="fa-solid fa-phone bg-white border border-dark p-4 rounded-circle"
                        ></i>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12 text-center verify-text">
                      <h5 style={{ color: "#7F56D9" }}>
                        Verify Your Phone
                      </h5>
                      <p className="my-2">
                        Please enter the 6 digit code send to
                      </p>
                      <h6>
                        {form.Country_Code +
                          form.phone_number?.replace(/.(?=.{2})/g, "x")}
                      </h6>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="d-flex align-items-center">
                        <OTPInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          inputStyle="form-control w-100 me-4 px-0"
                          renderInput={(props) => (
                            <input
                              disabled={
                                form.Phone_Verfication &&
                                  form.Phone_Verfication
                                  ? true
                                  : false
                              }
                              {...props}
                            />
                          )}
                        />

                        <button
                          type="button"
                          name="submit"
                          className="btn bg-gradient-dark mb-0"
                          id="verifybtn"
                          onClick={(e) => verifyPhoneOtp(e)}
                          disabled={
                            form.Phone_Verfication &&
                              form.Phone_Verfication
                              ? true
                              : false
                          }
                        >
                          {form.Phone_Verfication &&
                            form.Phone_Verfication
                            ? "Verified"
                            : "Verify"}
                        </button>
                      </div>
                      <div className="text-danger">
                        <small>
                          <em>
                            {errors.Phone_Verfication &&
                              errors.Phone_Verfication}
                          </em>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5 mb-3 m-auto">
                    <div className="col-lg-3 col-12 text-start d-flex align-items-center justify-content-end">
                      <div className=" px-0 ">
                        <i
                          style={{ color: "#7F56D9" }}
                          className="fa-solid fa-envelope bg-white border border-dark p-4 rounded-circle"
                        ></i>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12 text-center verify-text">
                      <h5 style={{ color: "#7F56D9" }}>
                        Verify Your email
                      </h5>
                      <p className="my-2">
                        Please enter the 6 digit code send to
                      </p>
                      <h6>{form.email?.replaceAll(/.(?=.{4})/g, "x")}</h6>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="d-flex align-items-center">
                        <OTPInput
                          value={emailOtp}
                          onChange={setEmailOtp}
                          numInputs={6}
                          inputStyle="form-control w-100 me-4 px-0"
                          renderInput={(props) => (
                            <input
                              disabled={
                                form.email_Verfication &&
                                  form.email_Verfication
                                  ? true
                                  : false
                              }
                              {...props}
                            />
                          )}
                        />
                        <button
                          type="button"
                          name=""
                          className="btn bg-gradient-dark mb-0"
                          id="verifybtn"
                          onClick={(e) => verifyEmailOtp(e)}
                          disabled={
                            form.email_Verfication &&
                              form.email_Verfication
                              ? true
                              : false
                          }
                        >
                          {form.email_Verfication &&
                            form.email_Verfication
                            ? "Verified"
                            : "Verify"}
                        </button>
                      </div>
                      <div className="text-danger">
                        <small>
                          <em>
                            {errors.email_Verfication &&
                              errors.email_Verfication}
                          </em>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="button-row d-flex mt-4 col-12">
                      <button
                        className="btn bg-gradient-dark mb-0 js-btn-prev"
                        type="button"
                        title="Prev"
                        onClick={(e) => {
                          setFormState(1);
                        }}
                      >
                        Prev
                      </button>
                      <button
                        className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                        type="button"
                        title="Next"
                        onClick={saveVerificationInformation}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>


              <div style={formState === 3 ? { opacity: 1, position: 'relative' } : { position: 'absolute', opacity: 0, visibility: 'hidden' }}>

                <h5 className="font-weight-bolder">
                  KYC (Upload Documents)
                </h5>
                <div className="">
                  <div className="row mt-3 ">
                    <Form.Group as={Col} md="6">
                      <Form.Label label="Password">ID Proof</Form.Label>
                      <Form.Control
                        className="multisteps-form__input"
                        type="text"
                        name="idproof"
                        placeholder="Enter ID Proof Number"
                        value={form && form.id_proof && form.id_proof}
                        onChange={(e) =>
                          setIdProofNumber("id_proof", e.target.value)
                        }
                        isInvalid={!!errors.id_proof}
                        isValid={
                          valids.id_proof && valids.id_proof === true
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.id_proof}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="validationCustom03"
                    >
                      <Form.Label label="Password">
                        Upload Attachment
                      </Form.Label>
                      <Form.Control
                        className="multisteps-form__input"
                        type="file"
                        name="idproofdoc"
                        onChange={(e) =>
                          setIdDocFile(
                            "id_proof_doc",
                            e
                          )
                        }
                        isInvalid={!!errors.id_proof_doc}
                        isValid={
                          valids.id_proof_doc &&
                          valids.id_proof_doc === true
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.id_proof_doc}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="row mt-3 mt-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="validationCustom03"
                    >
                      <Form.Label label="Password">
                        Address Proof
                      </Form.Label>
                      <Form.Control
                        className="multisteps-form__input"
                        type="text"
                        accept=".jpg, .png, .webp, .pdf, doc, docx"
                        name="addressproof"
                        placeholder="Enter Address Proof Number"
                        value={
                          form &&
                          form.address_proof &&
                          form.address_proof
                        }
                        onChange={(e) =>
                          setAddressProofNumber(
                            "address_proof",
                            e.target.value
                          )
                        }
                        isInvalid={!!errors.address_proof}
                        isValid={
                          valids.address_proof &&
                          valids.address_proof === true
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address_proof}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6">
                      <Form.Label label="Password">
                        Upload Attachment
                      </Form.Label>
                      <Form.Control
                        className="multisteps-form__input"
                        type="file"
                        accept=".jpg, .png, .webp, .pdf, doc, docx"
                        name="addressproofdoc"
                        onChange={(e) =>
                          setAddressDocFile(
                            "address_proof_doc",
                            e
                          )
                        }
                        isInvalid={!!errors.address_proof_doc}
                        isValid={
                          valids.address_proof_doc &&
                          valids.address_proof_doc === true
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address_proof_doc}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="row">
                    <div className="button-row d-flex mt-4 col-12">
                      <button
                        className="btn bg-gradient-dark mb-0 js-btn-prev"
                        type="button"
                        title="Prev"
                        onClick={(e) => {
                          setFormState(2);
                        }}
                      >
                        Prev
                      </button>
                      <button
                        className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                        type="button"
                        title="Next"
                        onClick={saveKycInformation}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div style={formState === 4 ? { opacity: 1, position: 'relative' } : { position: 'absolute', opacity: 0, visibility: 'hidden' }}>
                <h5 className="font-weight-bolder">Profile</h5>
                <div className=" mt-3">
                  <div className="row">
                    <Form.Group as={Col} md="12">
                      <Form.Label label="Password">
                        Upload Profile Image
                      </Form.Label>
                      <Form.Control
                        className="multisteps-form__input"
                        type="file"
                        name="profileimg"
                        accept=".jpg,.png,.webp"
                        onChange={(e) =>
                          setProfilePic(
                            "display_pic",
                            e
                          )
                        }
                        isInvalid={!!errors.display_pic}
                        isValid={
                          valids.display_pic &&
                          valids.display_pic === true
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.display_pic}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mt-2" as={Col} md="12">
                      <Form.Label label="Password">
                        Short Description
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        className="multisteps-form__input"
                        type="text"
                        name="shortdesk"
                        value={
                          form &&
                          form.Short_Description &&
                          form.Short_Description
                        }
                        onChange={(e) =>
                          setShortDesc(
                            "Short_Description",
                            e.target.value
                          )
                        }
                        isInvalid={!!errors.Short_Description}
                        isValid={
                          valids.Short_Description &&
                          valids.Short_Description === true
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.Short_Description}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="button-row d-flex mt-4">
                    <button
                      className="btn bg-gradient-dark mb-0 js-btn-prev"
                      type="button"
                      title="Prev"
                      onClick={(e) => {
                        setFormState(3);
                      }}
                    >
                      Prev
                    </button>
                    <button
                      className="btn bg-gradient-dark ms-auto mb-0"
                      type="button"
                      title="Send"
                      onClick={() => setOpenAddModal(true)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section >
      <RegSucessfully
        isOpen={regModal}
        onClose={() => {
          setRegModal(false)
          navigate("/")
        }}
        regEmail={form?.email}
      />

      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"SUBMIT"}
        createAddTopic={"Are you sure?"}
        confirmText={"Save Details"}
        isOpen={openAddModal}
        onSubmit={saveProfileInformation}
        onClose={() => setOpenAddModal(false)}
      />
    </>
  );
};

export default Signup;
