import React, { useContext, useEffect, useState } from "react";

import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Dashboard from "../../../components/Header-Components/Dashboard";
import ManageModule from "../../../components/Header-Components/ManageModule";
import Management from "../../../components/Header-Components/Management";
import MasterModule from "../../../components/Header-Components/MasterModule";
import Payments from "../../../components/Header-Components/Payments";
import Integrations from "../../../components/Header-Components/Integrations";
import Reports from "../../../components/Header-Components/Reports";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import useHttp from "../../../Hooks/useHttp";
import AddModal from "../../../common/modal/AddModal";
import AppContext from "../../../Context/AppContext";
import { customToast } from "../../../../App";

export default function AddNavigation() {
	const { setCircularLoading } = useContext(AppContext);
	const [openAddModal, setOpenAddModal] = useState(false);

	const [form, setForm] = useState({});
	const [cmsPageOpt, setCmsPageOpt] = useState();
	const [showOnOpt, setShowOnOpt] = useState();
	const [cmsPageId, setCmsPageId] = useState();
	const [sequence, setSequence] = useState();
	const navigate = useNavigate();
	const token = localStorage.getItem("token");
	const uid = localStorage.getItem("userid");
	const API = process.env.REACT_APP_API_URL;

	const { GetRequest, PostRequest } = useHttp();

	const fetchCmsPage = async () => {
		const { data, error } = await GetRequest(
			API + "cms_pages/",
			{},
			{ Authorization: "Bearer  " + token }
		);
		if (error) {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {
			setCmsPageOpt(data);
			console.log("CmsPage data--", data.results);
		}
	};
	const fetchShowOn = async () => {
		const { data, error } = await GetRequest(
			API + "basevalues/?title_code=show_on",
			{},
			{
				Authorization: "Bearer  " + token,
			}
		);
		if (error) {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {
			setShowOnOpt(data.results);
		}
	};
	const handleSubmit = async () => {
		setOpenAddModal(false);
		setCircularLoading(true);
		const param = {
			...form,
			cms_navigation_status: "active",
			user_id: uid,
			is_deleted: false,
		};
		const { data, error } = await PostRequest(API + "cms_navigation/", param, {
			Authorization: "Bearer  " + token,
			"Content-Type": "multipart/form-data",
		});
		if (data) {
			setCircularLoading(false);
			navigate("/admin/cms-navigation");
		} else {
			setCircularLoading(false);
			console.log(error);
		}
	};

	useEffect(() => {
		fetchCmsPage();
		fetchShowOn();
	}, []);
	const Breadcrumbname = {
		home: "/admin/home",
		"Manage Module": "",
		Navigation: "/admin/cms-navigation",
		"Add New CMS Navigation": "/admin/cms-navigation/add",
	};
	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row w-100 d-flex justify-content-center">
					<div className="addCategoryArea  my-4">
						<div className="row">
							<div className="col-lg-8 ps-3">
								<Breadcrumb names={Breadcrumbname} />
							</div>

							<div className="col-lg-4">
								<div className="button-row d-flex justify-content-end mt-0 mb-3">
									<Link
										to="/admin/cms-navigation"
										className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
										id="adduser"
									>
										VIEW ALL
									</Link>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="card">
								<div className="row">
									<div className="card mt-0" id="basic-info">
										<div className="card-header">
											<h5>ADD New CMS Navigation</h5>
										</div>
										<div className="card-body pt-0">
											<div className="row mb-4">
												<div className="col-7">
													<label className="form-label">CMS Page</label>

													<select
														className="form-select"
														name="choices-gender"
														id="choices-gender"
														value={form && form.page_id}
														onChange={(e) =>
															setForm({ ...form, page_id: e.target.value })
														}
													>
														<option value="" selected>
															Select Page
														</option>
														{cmsPageOpt &&
															cmsPageOpt.results.map((item, index) => (
																<option value={item.id} key={index}>
																	{item.page_title} -- {item.cms_page_id} -- {item.meta_title}
																</option>
															))}
													</select>
												</div>
											</div>
											<div className="row mb-4">
												<div className="col-7">
													<label className="form-label">Show On</label>

													<select
														className="form-select"
														name="choices-gender"
														id="choices-gender"
														value={form && form.show_on}
														onChange={(e) =>
															setForm({ ...form, show_on: e.target.value })
														}
													>
														<option value="" selected>
															Select Navigation Page
														</option>
														{showOnOpt &&
															showOnOpt[0].value_options
																?.split(",")
																.map((item, index) => (
																	<option key={index} value={item}>{item}</option>
																))}
													</select>
												</div>
											</div>

											<div className="row mb-4">
												<div className="col-7">
													<label className="form-label mt-4">Sequence</label>
													<div className="input-group ">
														<input
															className="form-control"
															placeholder="Enter Sequence"
															type="number"
															value={form && form.sequence}
															onChange={(e) =>
																setForm({ ...form, sequence: e.target.value })
															}
														/>
													</div>
												</div>
											</div>
											<div className=" d-flex justify-content-end">
												<Link
													to="/admin/cms-navigation"
													className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
												>
													Cancel
												</Link>
												<button
													onClick={() => setOpenAddModal(true)}
													className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
												>
													Add Navigation
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AddModal
				cancelBtn={"Cancel"}
				submitBtn={"SUBMIT"}
				createAddTopic={"Add Navigation"}
				confirmText={"Add The Navigation"}
				isOpen={openAddModal}
				onSubmit={handleSubmit}
				onClose={() => setOpenAddModal(false)}
			/>
		</>
	);
}
