import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Infoicon from "./info.svg";
import Staricon from "./Star.svg";
import "./customerUsers.css";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

import Dashboard from "../../components/Header-Components/Dashboard";
import Management from "../../components/Header-Components/Management";
import Payments from "../../components/Header-Components/Payments";
import Integrations from "../../components/Header-Components/Integrations";
import Reports from "../../components/Header-Components/Reports";

export default function CustomerUserAdd() {
	const [formState, setFormState] = useState(1);
	const Breadcrumbname = {
		Home: "/customer/home",
		User: "/customer/users",
		"Add User": "/customer/user/add",
	};
	return (
		<>
			<div className="container-fluid py-4">
				<div className="row">
					<div className="col-lg-12 col-12 m-auto ps-3">
						<Breadcrumb names={Breadcrumbname} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="multisteps-form mb-5">
							<div className="row">
								<div className="col-12 col-lg-10 mx-auto my-5 ">
									<div className="multisteps-form__progress">
										{formState >= 1 ? (
											<button
												className="multisteps-form__progress-btn js-active"
												type="button"
												title="User Info"
											>
												User Info
											</button>
										) : (
											<button
												className="multisteps-form__progress-btn"
												type="button"
												title="User Info"
											>
												User Info
											</button>
										)}

										{formState >= 2 ? (
											<button
												className="multisteps-form__progress-btn js-active"
												type="button"
												title="Socials"
											>
												Verification
											</button>
										) : (
											<button
												className="multisteps-form__progress-btn"
												type="button"
												title="Verification"
											>
												Verification
											</button>
										)}
										{formState === 3 ? (
											<button
												className="multisteps-form__progress-btn js-active"
												type="button"
												title="Profile"
											>
												Profile
											</button>
										) : (
											<button
												className="multisteps-form__progress-btn"
												type="button"
												title="Profile"
											>
												Profile
											</button>
										)}
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12 col-lg-10 m-auto">
									<form className="multisteps-form__form mb-8">
										{formState === 1 ? (
											<div
												className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
												data-animation="FadeIn"
											>
												<h5 className="font-weight-bolder mb-0">New User</h5>
												<div className="multisteps-form__content">
													<div className="row mt-3">
														<div className="col-12 col-sm-6">
															<label>User Name</label>
															<input
																className="multisteps-form__input form-control"
																type="text"
																placeholder="Enter Username"
															/>
														</div>
														<div className="col-12 col-sm-6 mt-3 mt-sm-0">
															<label>First Name</label>
															<input
																className="multisteps-form__input form-control"
																type="text"
																placeholder="Enter First Name"
															/>
														</div>
													</div>
													<div className="row mt-3">
														<div className="col-12 col-sm-6">
															<label>Last Name</label>
															<input
																className="multisteps-form__input form-control"
																type="text"
																placeholder="Enter Last Name"
															/>
														</div>
														<div className="col-12 col-sm-6 mt-3 mt-sm-0">
															<label>Gender</label>
															<select className="multisteps-form__input form-control">
																<option selected>-- Select Gender --</option>
																<option value="Male">Male</option>
																<option value="Female">Female</option>
															</select>
														</div>
													</div>
													<div className="row mt-3">
														<div className="col-12 col-sm-1">
															<label>ISO</label>
															<select className="multisteps-form__input form-control">
																<option selected>IN</option>
																<option value="UK">UK</option>
																<option value="USA">USA</option>
															</select>
														</div>
														<div className="col-12 col-sm-5 mt-3 mt-sm-0">
															<label>Number</label>
															<div className="input-group mb-4">
																<span className="input-group-text">+91</span>
																<input
																	className="form-control"
																	placeholder="Enter Number"
																	type="number"
																/>
															</div>
														</div>

														<div className="col-12 col-sm-6 mt-3 mt-sm-0">
															<label>Email</label>
															<div className="input-group mb-4">
																<input
																	className="form-control"
																	placeholder="example@gmail.com"
																	type="email"
																/>
															</div>
														</div>
													</div>
													<div className="row mt-0">
														<div className="col-12 col-sm-6">
															<label>Password</label>
															<input
																className="multisteps-form__input form-control"
																type="password"
																placeholder="******"
															/>
														</div>
														<div className="col-12 col-sm-6 mt-3 mt-sm-0">
															<label>Re-Enter Password</label>
															<input
																className="multisteps-form__input form-control"
																type="password"
																placeholder="******"
															/>
														</div>
													</div>
													<div className="suggestion-pass mt-4">
														<ul className="list-unstyled">
															<li>
																<div className="d-flex">
																	<img
																		className="info-icon"
																		src={Infoicon}
																		alt="info-icon"
																	/>
																	<strong>
																		Suggestions for a Strong Password:
																	</strong>
																</div>
															</li>
															<li>
																<div className="star mt-2 ms-4">
																	<img
																		className="info-icon"
																		src={Staricon}
																		alt="info-icon"
																	/>
																	<p className="m-0">
																		A Combination of Upper and Lowercase
																		Letters, Numbers & Special Characters
																		(Allowed One Only )
																	</p>
																</div>
															</li>
															<li>
																<div className="star mt-2 ms-4">
																	<img
																		className="info-icon"
																		src={Staricon}
																		alt="info-icon"
																	/>
																	<p className="m-0">
																		At least 8 Characters or More
																	</p>
																</div>
															</li>
														</ul>
													</div>
													<div className="button-row d-flex mt-4">
														<button
															className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
															type="button"
															title="Next"
															onClick={() => setFormState(2)}
														>
															Next
														</button>
													</div>
												</div>
											</div>
										) : (
											""
										)}

										{formState === 2 ? (
											<div
												className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
												data-animation="FadeIn"
											>
												<h5 className="font-weight-bolder">
													Authenticate User Account
												</h5>
												<div className="multisteps-form__content">
													<div className="row mt-5 m-auto">
														<div className="col-lg-3 col-12 text-center d-flex align-items-center justify-content-end">
															<div className=" px-0 ">
																<i
																	style={{ color: "#7F56D9" }}
																	className="fa-solid fa-phone bg-white border border-dark p-4 rounded-circle"
																></i>
															</div>
														</div>
														<div className="col-lg-3 col-12 text-center verify-text">
															<h5 style={{ color: "#7F56D9" }}>
																Verify Your Phone
															</h5>
															<p className="my-2">
																Please enter the 4 digit code send to
															</p>
															<h6>+91********136</h6>
														</div>
														<div className="col-lg-6 col-12 d-flex align-items-center">
															<form
																className="d-flex align-items-center"
																id="inputfilds"
																action=""
															>
																<input
																	type="text"
																	name=""
																	className="form-control me-1 px-1"
																/>
																<input
																	type="text"
																	name=""
																	className="form-control me-1 px-0"
																/>
																<input
																	type="text"
																	name=""
																	className="form-control me-1 px-0"
																/>
																<input
																	type="text"
																	name=""
																	className="form-control me-4 px-0"
																/>
																<button
																	type="submit"
																	name="submit"
																	className="btn bg-gradient-dark mb-0"
																	id="verifybtn"
																>
																	Verify
																</button>
															</form>
														</div>
													</div>

													<div className="row mt-5 mb-3 m-auto">
														<div className="col-lg-3 col-12 text-start d-flex align-items-center justify-content-end">
															<div className=" px-0 ">
																<i
																	style={{ color: "#7F56D9" }}
																	className="fa-solid fa-envelope bg-white border border-dark p-4 rounded-circle"
																></i>
															</div>
														</div>
														<div className="col-lg-3 col-12 text-center verify-text">
															<h5 style={{ color: "#7F56D9" }}>
																Verify Your email
															</h5>
															<p className="my-2">
																Please enter the 4 digit code send to
															</p>
															<h6>e********@gmail.com</h6>
														</div>
														<div className="col-lg-6 col-12 d-flex align-items-center">
															<form
																className="d-flex "
																id="inputfilds"
																action=""
															>
																<input
																	type="text"
																	name=""
																	className="form-control me-1 px-1"
																/>
																<input
																	type="text"
																	name=""
																	className="form-control me-1 px-0"
																/>
																<input
																	type="text"
																	name=""
																	className="form-control me-1 px-0"
																/>
																<input
																	type="text"
																	name=""
																	className="form-control me-4 px-0"
																/>
																<button
																	type="submit"
																	name=""
																	className="btn bg-gradient-dark mb-0"
																	id="verifybtn"
																>
																	Verify
																</button>
															</form>
														</div>
													</div>

													<div className="row">
														<div className="button-row d-flex mt-4 col-12">
															<button
																className="btn bg-gradient-dark mb-0 js-btn-prev"
																type="button"
																title="Prev"
																onClick={() => setFormState(1)}
															>
																Prev
															</button>
															<button
																className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
																type="button"
																title="Next"
																onClick={() => setFormState(3)}
															>
																Next
															</button>
														</div>
													</div>
												</div>
											</div>
										) : (
											""
										)}
										{formState === 3 ? (
											<div
												className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
												data-animation="FadeIn"
											>
												<h5 className="font-weight-bolder">Profile</h5>
												<div className="multisteps-form__content mt-3">
													<div className="row">
														<div className="col-12">
															<label>Upload Image</label>
															<input
																className="multisteps-form__input form-control"
																type="file"
																placeholder="Use an address you don't use frequently."
															/>
														</div>
														<div className="col-12 mt-3">
															<label>Short Description</label>
															<textarea
																className="multisteps-form__textarea form-control"
																rows="5"
																placeholder="Say a few words about who you are or what you're working on."
															></textarea>
														</div>
													</div>
													<div className="button-row d-flex mt-4">
														<button
															className="btn bg-gradient-dark mb-0 js-btn-prev"
															type="button"
															title="Prev"
															onClick={() => setFormState(2)}
														>
															Prev
														</button>
														<button
															className="btn bg-gradient-dark ms-auto mb-0"
															type="button"
															title="Send"
														>
															Submit
														</button>
													</div>
												</div>
											</div>
										) : (
											""
										)}
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
