import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ResearcherData from "./researchTabData.json";
import profilePhoto from "./img/ivana-square.jpg";
import HTTPService from "../../utils/axios";
import "./ProgramtabStyle.css";
import AppContext from "../Context/AppContext";
import Skeleton from "react-loading-skeleton";
import { formatDate } from "../../utils/helperFunctions";
import { toast } from "react-toastify";
import CircularLoader from "../CircularLoader/CircularLoader";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../reducers/authSlice";
import { customToast } from "../../App";

export default function AdminProgramResearcher() {
  const { id } = useParams();
  const [stateChange, setStateChange] = useState(1);
  const { invokeApi } = HTTPService();
  const [stats, setStats] = useState({});
  const [invitedResearcher, setInvitedResearcher] = useState();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [researcher, setResearcher] = useState();
  const [researchers, setResearchers] = useState();
  const [program, setProgram] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const { loading, setLoading } = useContext(AppContext);
  const { userInfo } = useSelector(getUserInfo);

  const fetchProgram = () => {
    invokeApi({
      method: "GET",
      url: `programs/${id}`,
    })
      .then((response) => {
        setProgram(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchStats = () => {
    setLoadingPage(true);
    invokeApi({
      method: "GET",
      url: `/programs/researcher-stats/${id}`,
    })
      .then((response) => {
        setLoadingPage(false);
        setStats(response);
      })
      .catch((error) => {
        setLoadingPage(false);
        console.error("Error fetching data:", error);
      });
  };
  const fetchInvitedResearchers = () => {
    setLoadingPage(true);
    invokeApi({
      method: "GET",
      url: `/researcher/program-invitation`,
      params: {
        program: id
      }
    }).then((response) => {
      setLoadingPage(false);
      setInvitedResearcher(response);
    }).catch((error) => {
      setLoadingPage(false);
      console.error("Error fetching data:", error);
    });
  };
  const fetchResearchers = () => {
    setLoadingPage(true);
    invokeApi({
      method: "GET",
      url: `/researcher/`,
    }).then((response) => {
      setLoadingPage(false);
      setResearchers(response);
    }).catch((error) => {
      setLoadingPage(false);
      console.error("Error fetching data:", error);
    });
  };
  const inviteResearcher = (values) => {
    setLoading(true)
    const data = {
      ...values,
      program: id,
    }
    invokeApi({
      method: "POST",
      url: `/researcher/program-invitation`,
      data
    }).then((response) => {
      setName('')
      setEmail('')
      setResearcher('')
      setInvitedResearcher(prev => {
        let newD = { ...prev };
        newD.results.push(response)
        return newD;
      })
      setLoading(false)
      customToast.success("Invited Successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }).catch((error) => {
      setLoading(false)
      customToast.error(error?.data?.message ?? "Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    });
  };
  const handleTeamInvite = () => {
    if (!researcher) {
      customToast.error("Select Researcher First", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      return
    }
    const res = researchers.results.find(e => e.id === researcher);
    const data = {
      user: res.id,
      full_name: res.first_name + " " + res.last_name,
      email: res.email
    }
    inviteResearcher(data)
  }
  const handleInviteSubmit = () => {
    if (!name) {
      customToast.error("Enter Full Name", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      return
    }
    if (!email) {
      customToast.error("Enter Email", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      return
    } const data = {
      full_name: name,
      email: email
    }
    inviteResearcher(data)
  }
  useEffect(() => {
    if (userInfo?.user_data?.id) {
      fetchStats();
      fetchInvitedResearchers();
      fetchResearchers();
      fetchProgram();
    }
  }, [userInfo?.user_data?.id]);

  return (
    <>
      <div className="row mt-4 programResearcher">
        <div className="col-3 researcherLeftSide">
          {loadingPage ? (
            <div>
              <Skeleton height={"50px"} width={"100%"} sx={{ mt: '10px', borderRadius: '10px' }} />
              <Skeleton height={"50px"} width={"100%"} sx={{ mt: '10px', borderRadius: '10px' }} />
            </div>
          ) : (
            <div className="partInviteBox">
              <div
                className={`border-bottom`}
                onClick={() => setStateChange(1)}
                style={stateChange === 1 ? { backgroundColor: 'rgb(245, 245, 251)' } : { cursor: "pointer" }}
              >
                <h5 style={stateChange === 1 ? { color: "#000" } : {}}>
                  Participants
                </h5>
              </div>
              <div
                onClick={() => setStateChange(2)}
                style={stateChange === 2 ? { backgroundColor: 'rgb(245, 245, 251)' } : { cursor: "pointer" }}
              >
                <h5 style={stateChange === 2 ? { color: "#000" } : {}}>
                  Invitations
                </h5>
              </div>
            </div>
          )}
        </div>
        <div className="col-9 researcherRightSide">
          {loadingPage ? (
            <div>
              <Skeleton height={100} width={"96%"} />{" "}
            </div>
          ) : (
            <div className="row">
              <div className="col-3">
                <div>
                  <h5>Total Invited</h5>
                  <h6>{stats?.researcher_submissions_count?.total_invites ?? 0}</h6>
                </div>
              </div>
              <div
                className="col-3"
                style={{ borderLeft: "1px solid #dee2e6" }}
              >
                <div>
                  <h5>Total Submitters</h5>
                  <h6>{stats?.researcher_submissions_count?.total_unique_researchers ?? 0}</h6>
                </div>
              </div>
              <div
                className="col-3"
                style={{ borderLeft: "1px solid #dee2e6" }}
              >
                <div>
                  <h5>Total Submissions</h5>
                  <h6>{stats?.researcher_submissions_count?.total_submissions ?? 0}</h6>
                </div>
              </div>
              <div
                className="col-3"
                style={{ borderLeft: "1px solid #dee2e6" }}
              >
                <div>
                  <h5>Valid Submissions</h5>
                  <h6>{stats?.researcher_submissions_count?.total_valid_submissions ?? 0}</h6>
                </div>
              </div>
            </div>
          )}

          {stateChange === 1 ? (
            <>
              <div className="row mt-4">
                {loadingPage ? (
                  <Skeleton height={600} width={"96%"} />
                ) : (
                  <>
                    <div>
                      <h4>Participating Researchers</h4>
                    </div>
                    <div>
                      <div className="table-responsive">
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr className="text-center">
                              <th className="text-uppercase text-secondary researcherTableTh font-weight-bolder opacity-8">
                                Researcher
                              </th>
                              <th className="text-uppercase text-secondary researcherTableTh font-weight-bolder opacity-8">
                                Valid Submissions
                              </th>
                              <th className="text-uppercase text-secondary researcherTableTh font-weight-bolder opacity-8 ps-2">
                                Total Submissions
                              </th>
                              <th className=" text-uppercase text-secondary researcherTableTh font-weight-bolder opacity-8">
                                <button
                                  type="button"
                                  className=" btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "#6f42c1",
                                    fontSize: "17px",
                                    fontWeight: "600",
                                  }}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Last Submitted{" "}
                                  {/* <i className="fa-solid fa-caret-down"></i> */}
                                </button>
                                {/* <ul
                                  className="dropdown-menu"
                                  style={{ backgroundColor: "#000" }}
                                >
                                  <li>
                                    <Link className="dropdown-item" to="">
                                      Over 1 year ago
                                    </Link>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      6 months ago
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      Over 2 year ago
                                    </a>
                                  </li>
                                </ul> */}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {stats?.researcher_submissions_stats && stats?.researcher_submissions_stats?.length > 0 ?
                              stats.researcher_submissions_stats.map((item, index) => (
                                <tr key={index} className="text-center">
                                  <td>
                                    <div className="d-flex align-items-center">
                                      {/* <div className="researcherProfilePhoto">
                                        <img src={profilePhoto} alt="" />
                                      </div> */}
                                      <div className="text-start">
                                        <p className="text-sm  mb-0 px-2">
                                          {item.researcher_name}
                                        </p>
                                        {/* <p className="text-sm text-secondary mb-0 px-2">
                                          Remove from Program
                                        </p> */}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p className="text-sm  mb-0 px-2">
                                      {item.resolved_submissions}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="text-sm  mb-0 px-2">
                                      {item.total_submissions}
                                    </p>
                                  </td>
                                  <td className="text-center">
                                    <p className="text-sm  mb-0 px-2">
                                      {formatDate(item.last_submission_date, 1)}
                                    </p>
                                  </td>
                                </tr>
                              ))
                              :
                              <tr className="text-center">
                                <td colSpan={4}> No data</td>
                              </tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div>
                <div className="Up-part">
                  <div className="searchpart col-10">
                    <div className="d-flex ">
                      <div style={{ width: "72%" }}>

                        <select
                          disabled={program?.program_status !== 'approved'}
                          placeholder="Select Researcher"
                          className="form-select quSelect" value={researcher} onChange={e => setResearcher(e.target.value)}>
                          <option value="">Select Researcher</option>
                          {researchers?.results?.map(option => <option key={option.id} value={option.id}>
                            {option.first_name} {" "} {option.last_name}({option.username})
                          </option>)}
                        </select>
                      </div>
                      <div className="btn-part">
                        <button className="btn " disabled={program?.program_status !== 'approved'} onClick={program?.program_status !== 'approved' ? () => { } : handleTeamInvite}>Invite a team member</button>
                      </div>
                    </div>

                    <p className="ps-12">----or----</p>

                    <div className="d-flex">
                      <div style={{ width: "36%" }}>
                        <input
                          disabled={program?.program_status !== 'approved'}
                          className="form-control  w-100"
                          type="text"
                          placeholder="Full Name"
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                      </div>

                      <div style={{ width: "36%" }}>
                        <input
                          disabled={program?.program_status !== 'approved'}
                          className="form-control w-100"
                          style={{ marginLeft: "10px" }}
                          type="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={e => setEmail(e.target.value)} />
                      </div>

                      <div className="btn-part">
                        <button className="btn " disabled={program?.program_status !== 'approved'} onClick={program?.program_status !== 'approved' ? () => { } : handleInviteSubmit}>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table align-items-center mb-0">
                    <thead>
                      <tr className="text-center">
                        <th className="text-uppercase text-secondary researcherTableTh font-weight-bolder opacity-8 text-start">
                          Researcher
                        </th>
                        <th className="text-uppercase text-secondary researcherTableTh font-weight-bolder opacity-8">
                          Email
                        </th>
                        {/* <th className="text-uppercase text-secondary researcherTableTh font-weight-bolder opacity-8 ps-2">
                          Action
                        </th> */}
                        <th className="text-uppercase text-secondary researcherTableTh font-weight-bolder opacity-8 ps-2">
                          Status
                        </th>
                        <th className="text-uppercase text-secondary researcherTableTh font-weight-bolder opacity-8 ps-2">
                          Date
                        </th>
                      </tr>
                    </thead>
                    <hr />

                    <tbody>
                      {invitedResearcher?.results && invitedResearcher?.results?.length > 0 ?
                        invitedResearcher.results.map(data =>
                          <tr className="text-center">
                            <td>
                              <div className="d-flex">
                                {/* <div className="researcherProfilePhoto">
                                <img src={profilePhoto} alt="" />
                              </div> */}
                                <div className="text-start">
                                  <p className="text-sm  mb-0 px-2">{data?.full_name}</p>
                                  <p className="text-sm text-secondary mb-0 px-2">
                                    {!data.user ? "Un-Register" : "Registered"}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className="text-sm text-secondary mb-0 px-2">
                                {data?.email}
                              </p>
                            </td>
                            {/* <td>
                              <p className="border border-primary font-weight-bold text-sm  mb-0 px-2 At-sts">
                                Resend Invitation
                              </p>
                            </td> */}
                            <td>
                              <p className={`text-sm  mb-0 px-2  font-weight-bold text-capitalize ${data.invitation_status === 'accepted' ? 'text-success' : 'text-danger'}`}>
                                {data?.invitation_status}
                              </p>
                            </td>
                            <td className="text-center">
                              <p className="text-sm  mb-0 px-2">
                                {formatDate(data?.created_at, 1)}
                              </p>
                            </td>
                          </tr>

                        ) :
                        <tr className="text-center">
                          <td colSpan={4}> No data</td>
                        </tr>
                      }

                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
