import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";

import "./BadgesDashboard.css";
import BadgeLevelModal from "./BadgeLevelModal";
import useHttp from "../Hooks/useHttp";
import { ToastContainer, toast } from "react-toastify";
import ProfileImage from "../../components/ProfileImage";
import AddModal from "../common/modal/AddModal";
import CommonTable from "../common/Table/CommonTable";
import AppContext from "../Context/AppContext";
import { getUserInfo } from "../../reducers/authSlice";
import { useSelector } from "react-redux";
import withPermission from "../../hoc/withPermission";
import HTTPService from "../../utils/axios";
import { customToast } from "../../App";

const tableColumn = [
  "icon",
  { label: "id", align: "center" },
  "title",
  "description",
  { label: "levels", align: "center" },
  { label: "sequence", align: "center" },
  "status",
  "action",
];
const searchColumn = [
  { name: "ID", value: "badge_id" },
  { name: "Title", value: "badge_title" },
  // { name: "Description", value: "badge_description" },
  // { name: "Sequence", value: "sequence" },
  // { name: "Status", value: "badge_status" },
];
const Breadcrumbname = {
  home: "/admin/home",
  Payments: "",
  Badges: "/admin/badges/dashboard",
  Dashboard: "",
};
function BadgesDashboard() {
  const { userInfo, permissions } = useSelector(getUserInfo);
  const permission = permissions.find((perm) => perm.module === "Badges");
  const { setLoading } = useContext(AppContext);
  const [DelId, setDelId] = useState({});
  const [openAddModal, setOpenAddModal] = useState(false);
  const [show, setShow] = useState(false);
  const [modalId, setModalId] = useState();
  const [page, setPage] = useState(0);
  const [badgesList, setBadgesList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [baseValueOptions, setBaseValueOptions] = useState();
  const API = process.env.REACT_APP_API_URL;
  const { PostRequest, GetRequest, DeleteRequest } = useHttp();
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { invokeApi } = HTTPService();
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusVal, setChangeStatusVal] = useState();
  const [selectedBadge, setSelectedBadge] = useState();
  const [totalData, setTotalData] = useState(0);
  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();
  const filterColumn = [
    {
      name: "Status",
      value: "badge_status",
      filters: [
        { value: "active", label: "Active" },
        { value: "in_active", label: "Inactive" },]
    }
  ];
  const getBadgeLevelCount = async (badgeIds) => {
    setLoading(true);
    try {
      const levelsPromises = badgeIds.map(async (id) => {
        const { data, error } = await GetRequest(
          API + `badge-levels/?badge_id=${id}`,
          {},
          {
            Authorization: "Bearer " + token,
          }
        );
        if (error) {
          setLoading(false);
          throw new Error("Failed to fetch Levels for Badge with ID: " + id);
        }
        setLoading(false);
        return data.count;
      });
      const levels = await Promise.all(levelsPromises);
      return levels;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching Levels:", error);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return [];
    }
  };

  const fetchBadgesData = async () => {
    let url = `${API}badges?page=${currentPage}&page_size=25`;
    if (searchQuery && searchQuery.length > 0) {
      url = url + `&${searchValue}=${searchQuery}`;
    }
    if (filterType && filterValue && filterValue.length > 0) {
      url = url + `&${filterType}=${filterValue}`;
    }
    try {
      setLoading(true);
      const { data, error } = await GetRequest(
        url,
        // API + `/badges?page=${page}`,
        // { params: { ...queryParams } },
        {},
        {
          Authorization: "Bearer " + token,
        }
      );
      if (error) {
        throw new Error("Failed to fetch Badges");
      }

      setBadgesList(data.results);
      setTotalPages(data.total_pages);
      setTotalData(data.count);

      const badgeIds = data.results.map((item) => item.id);
      const levels = await getBadgeLevelCount(badgeIds);

      setBadgesList((prevBadgesList) =>
        prevBadgesList.map((badge, index) => ({
          ...badge,
          levelCount: levels[index],
        }))
      );
    } catch (error) {
      console.error("Error fetching roles data:", error);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBadgesData();
    fetchBaseValues();
  }, []);
  useEffect(() => {
    fetchBadgesData();
  }, [searchQuery, currentPage, filterValue]);

  const fetchBaseValues = async () => {
    const { data, error } = await GetRequest(
      API + "/basevalues/",
      {},
      {
        Authorization: "Bearer  " + token,
      }
    );
    if (error) {
      // alert("Something went wrong");
      // customToast.error("Something went wrong!", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 1000,
      // });
    } else {
      data?.results?.map((item) => {
        if (item.title_code == "Priority") {
          setPriorityOptions(
            item.value_options
              .split(",")
              .map((value) => ({ value, label: value }))
          );
        }
        if (item.title_code == "Type_Badge_Based_Values") {
          setBaseValueOptions(
            item.value_options
              .split(",")
              .map((value) => ({ value, label: value }))
          );
        }
      });
    }
  };

  const modalClose = () => {
    setShow(false);
    setModalId();
  };
  // const modalShow = () => setShow(true);
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  const handleDelete = async (id, index) => {
    const { data, error } = await DeleteRequest(API + `badges/${id}/`, {
      Authorization: "Bearer  " + token,
    });
    if (error) {
      console.log(error);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setOpenAddModal(false);
      const arr = [...badgesList];
      arr.splice(index, 1);
      setBadgesList([...arr]);
    }
  };
  const setStatus = async () => {
    setChangeStatusModal(false);
    const param = {
      badge_status: changeStatusVal.toLowerCase(),
    };
    invokeApi({
      method: "PATCH",
      url: `badges/${selectedBadge.id}/`,
      data: param,
    })
      .then((res) => {
        fetchBadgesData(true);
        customToast.success("status updated", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const handleStatus = (data) => {
    setChangeStatusModal(true);
    setChangeStatusVal(data.badge_status);
    setSelectedBadge(data);
  };
  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              {(permission?.create || userInfo.role === "admin") && (
                <Link
                  to="/admin/badges/add"
                  className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                  id="adduser"
                >
                  + New Badges
                </Link>)}
            </div>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12">
            <CommonTable
              filterList={filterColumn}
              filterType={filterType}
              setFilterType={setFilterType}
              setFilterValue={setFilterValue}
              column={tableColumn}
              data={badgesList}
              totalPages={totalPages ?? 1}
              totalData={totalData}
              searchBar={true}
              searchBy={searchColumn}
              itemPerPg={25}
              setTable={1}
              card={7}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            >
              {badgesList.map((item, index) => (
                <tr>
                  <td className="rolename text-center d-flex justify-content-center">
                    <div style={{ width: "50px", height: "50px" }}>
                      <ProfileImage
                        src={item.image}
                        width="50px"
                        className="img-fluid"
                      />
                    </div>
                  </td>
                  <td className="rolename">
                    <Link
                      to={`/admin/badges/view/${item.id}`}
                      className="text-wrap text-sm  mb-0 px-2 gotoView"
                    >
                      {item.badge_id}
                    </Link>
                  </td>
                  <td className="text-start rolename">
                    <Link
                      to={`/admin/badges/view/${item.id}`}
                      className="text-wrap text-sm  mb-0 px-2 gotoView"
                    >
                      {item.badge_title}
                    </Link>
                  </td>
                  <td className="text-start rolename">
                    <p className="text-wrap text-sm  mb-2 px-2">
                      {/* {item.description} */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: truncateText(item.badge_description, 100),
                        }}
                      />
                    </p>
                  </td>
                  <td className="rolename">
                    <p className="text-wrap text-sm  mb-2 px-2">
                      {item && item.levelCount && item.levelCount > 0
                        ? item.levelCount > 1
                          ? `Levels: 1-${item.levelCount}`
                          : "Levels: 1"
                        : "Levels: 0"}
                    </p>
                  </td>
                  <td className=" rolename">
                    <p className="sequenceRow text-wrap text-sm  mb-2 px-2">
                      {item.sequence}
                    </p>
                  </td>

                  <td className="align-middle text-center">
                    {item.badge_status === "active" ? (
                      <span
                        className="badge bg-success text-white"
                        onClick={() => handleStatus(item)}
                      >
                        Active
                      </span>
                    ) : (
                      <span
                        className="badge bg-danger text-white"
                        onClick={() => handleStatus(item)}
                      >
                        Inactive
                      </span>
                    )}
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <Link
                        to={`/admin/badges/view/${item.id}`}
                        className="btn btn-sm px-3 mt-2"
                      >
                        &#128065;
                      </Link>
                      <button
                        type="button"
                        className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          className="fa fa-ellipsis-v text-xs"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <ul className="dropdown-menu">
                        {(permission?.edit || userInfo.role === "admin") && (
                          <>
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={() => {
                                  setShow(true);
                                  setModalId(item.id);
                                }}
                              >
                                Add Level
                              </button>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/admin/badges/edit/${item.id}`}
                              >
                                Edit
                              </Link>
                            </li>
                          </>
                        )}
                        <li>
                          {(permission?.delete ||
                            userInfo.role === "admin") && (
                              <button
                                className="dropdown-item"
                                onClick={() => {
                                  setDelId({ del: item.id, ind: index });
                                  setOpenAddModal(true);
                                }}
                              >
                                Delete
                              </button>
                            )}
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </CommonTable>
          </div>
        </div>
      </div>
      <BadgeLevelModal
        show={show}
        setShow={setShow}
        modalClose={modalClose}
        priorityOptions={priorityOptions}
        baseValueOptions={baseValueOptions}
        modalId={modalId}
        setModalId={setModalId}
      />
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"DELETE"}
        createAddTopic={"Delete Badge"}
        confirmText={"Delete The Badge"}
        isOpen={openAddModal}
        onSubmit={() => handleDelete(DelId.del, DelId.ind)}
        onClose={() => setOpenAddModal(false)}
      />
      {changeStatusModal ? (
        <div className="changeStatus">
          <div className="changeStatusBody">
            <div className="changeStatusBodyTop">
              <i className="fa-solid fa-circle-exclamation fa-rotate-180"></i>
              <p>Change The Badge Status</p>
            </div>
            <div className="changeStatusBodySecPart">
              <select
                value={changeStatusVal}
                onChange={(e) => setChangeStatusVal(e.target.value)}
              >
                <option value="active">Active</option>
                <option value="in_active">In Active</option>
              </select>
            </div>
            <div className="changeStatusBodyThirdPart">
              <p>Are You Sure You Want to Change The Status?</p>
            </div>
            <div className="changeStatusBodyLastPart">
              <button
                onClick={() => setChangeStatusModal(false)}
                className="changeStatusBtnClose"
              >
                CLOSE
              </button>
              <button onClick={setStatus} className="changeStatusBtnSubmit">
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default withPermission(BadgesDashboard);
BadgesDashboard.moduleName = "Badges";
