import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";

import Backbutton from "../../components/backbutton/Backbutton";

import useHttp from "../../Hooks/useHttp";

import { toast } from "react-toastify";
import { customToast } from "../../../App";

export default function Viewprofile() {
	const [formState, setFormState] = useState(1);
	const { userId } = useParams();
	// const user = userdata.filter(item => item.id == userId);
	const Breadcrumbname = {
		home: "/admin/home",
		"Master Module": "/admin/company/dashboard",
		Company: "/admin/company/dashboard",
		"View Company": "",
	};

	console.log(userId);

	const { GetRequest, PutRequest, DeleteRequest } = useHttp();
	const [companydata, setCompanydata] = useState({});
	const [companyAddressdata, setCompanyAddressdata] = useState();
	const [companyLink, setCompanyLink] = useState();
	const API = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem("token");

	const fetchCompany = async (stsId) => {
		const { data, error } = await GetRequest(
			API + "company",
			{},
			{
				Authorization: "Bearer  " + token,
				// "Content-Type": "multipart/form-data",
				// Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			}
		);
		if (error) {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {

			setCompanydata(data);
			//let cData=data.results
			//setCompanydata({...companydata,cData});
		}
	};

	const fetchCompanyAddress = async (stsId) => {
		const { data, error } = await GetRequest(
			API + "company_address",
			{},
			{
				Authorization: "Bearer  " + token,
				// "Content-Type": "multipart/form-data",
				// Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			}
		);
		if (error) {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {

			//let cData=data.results
			//setCompanydata({...companydata,cData});
			setCompanyAddressdata(data);
		}
	};

	const fetchCompanyLink = async (stsId) => {
		const { data, error } = await GetRequest(
			API + "company_link",
			{},
			{
				Authorization: "Bearer  " + token,
				// "Content-Type": "multipart/form-data",
				// Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			}
		);
		if (error) {
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		} else {

			setCompanyLink(data);
		}
	};

	useEffect(() => {
		fetchCompany();
		fetchCompanyAddress();
		fetchCompanyLink();
	}, []);

	const filteredCompanies =
		companydata && companydata.results
			? companydata.results.filter((item) => item.id === userId)
			: [];

	const filteredCompaniesAddress =
		companyAddressdata && companyAddressdata.results
			? companyAddressdata.results.filter((item) => item.company === userId)
			: [];

	const filteredCompaniesLink =
		companyLink && companyLink.results
			? companyLink.results.filter((item) => item.company === userId)
			: [];

	return (
		<>
			<div className="container-fluid py-4">
				<div className="row">
					<div className="col-lg-6 m-auto ps-3">
						<Breadcrumb names={Breadcrumbname} />
					</div>
					<div className="col-lg-6">
						<Backbutton />
					</div>
				</div>

				<div className="card card-body blur shadow-blur mx-0 overflow-hidden">
					<div className="row gx-4">
						<div className="col-6">
							{filteredCompanies.map((company) => (
								<div
									key={company.id}
									className="col-12 d-flex align-items-center"
								>
									<div className="col-auto">
										<div className="avatar avatar-xl position-relative">
											<img
												src={company.leader_logo}
												alt="profile_image"
												style={{ objectFit: "contain" }}
												className="w-100 h-100 border-radius-lg shadow-sm"
											/>
										</div>
									</div>
									<div className="col-auto ms-3">
										<div className="h-100">
											<h5 className="mb-1">{company.legal_name}</h5>
											<p className="mb-0 font-weight-bold text-sm">
												{company.domain_name}
											</p>
										</div>
									</div>
								</div>
							))}
						</div>

						<div className="col-6 d-flex justify-content-end align-items-center">
							<div className="nav-wrapper position-relative end-0">
								<ul
									className="nav nav-pills nav-fill p-1 bg-transparent"
									role="tablist"
								>
									<li className="nav-item">
										<Link
											to={`/admin/company/edit/${userId}`}
											className="nav-link mb-0 px-0 py-1 text-end"
											role="tab"
											aria-selected="true"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="me-2"
												width="18"
												height="18"
												viewBox="0 0 18 18"
												fill="none"
											>
												<mask
													id="mask0_1683_5670"
													maskUnits="userSpaceOnUse"
													x="0"
													y="0"
													width="18"
													height="19"
												>
													<path
														d="M14.0744 5.49005C14.5845 6.21955 14.9297 7.05105 15.086 7.92694H17V10.105H15.086C14.9297 10.9809 14.5845 11.8124 14.0744 12.5419L15.4284 13.8938L13.8856 15.4341L12.5316 14.0823C11.8009 14.5916 10.9681 14.9362 10.0908 15.0922V17.0032H7.9092V15.0922C7.0319 14.9362 6.19907 14.5916 5.4684 14.0823L4.1144 15.4341L2.5716 13.8938L3.9256 12.5419C3.41549 11.8124 3.07033 10.9809 2.914 10.105H1V7.92694H2.914C3.07033 7.05105 3.41549 6.21955 3.9256 5.49005L2.5716 4.13822L4.1144 2.59789L5.4684 3.94972C6.19907 3.44043 7.0319 3.09582 7.9092 2.93974V1.02881H10.0908V2.93974C10.9681 3.09582 11.8009 3.44043 12.5316 3.94972L13.8856 2.59789L15.4284 4.13822L14.0744 5.49005Z"
														fill="white"
														stroke="white"
														strokeWidth="2"
														strokeLinejoin="round"
													></path>
													<path
														d="M9 11.0126C9.53043 11.0126 10.0391 10.8023 10.4142 10.4278C10.7893 10.0533 11 9.54542 11 9.01584C11 8.48626 10.7893 7.97836 10.4142 7.60389C10.0391 7.22942 9.53043 7.01904 9 7.01904C8.46957 7.01904 7.96086 7.22942 7.58579 7.60389C7.21071 7.97836 7 8.48626 7 9.01584C7 9.54542 7.21071 10.0533 7.58579 10.4278C7.96086 10.8023 8.46957 11.0126 9 11.0126Z"
														fill="black"
														stroke="black"
														strokeWidth="2"
														strokeLinejoin="round"
													></path>
												</mask>
												<g mask="url(#mask0_1683_5670)">
													<path
														d="M-0.601562 -0.568604H18.5984V18.6006H-0.601562V-0.568604Z"
														fill="#7F56D9"
													></path>
												</g>
											</svg>
											<span className="ms-1">Settings</span>
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid py-4">
				<div className="row mt-3">
					<div className="col-12 col-md-6 col-xl-4 mt-md-0 mt-4">
						<div className="card h-100">
							<div className="card-header pb-0 p-3">
								<div className="row">
									<div className="col-md-8 d-flex align-items-center">
										<h6 className="mb-0">Basic Profile</h6>
									</div>
									<div className="col-md-4 text-end">
										<Link
											to={`/admin/company/edit/${userId}`}
										>
											<i
												className="fas fa-pen text-secondary text-sm"
												data-bs-toggle="tooltip"
												data-bs-placement="top"
												aria-hidden="true"
												aria-label="Edit Profile"
												data-bs-original-title="Edit Profile"
											></i>
										</Link>
									</div>
								</div>
							</div>

							{filteredCompanies.map((company, index) => (
								<div key={index} className="card-body p-3">
									<ul className="m-0 list-unstyled">
										<li>
											Domin Name : <strong>{company.domain_name}</strong>{" "}
										</li>
										<li>
											Legal Name : <strong>{company.legal_name}</strong>
										</li>
										<li>Email : {company.email}</li>
										<li>Support Mail : {company.support_email}</li>
										<li>Phone Number : {company.phone_number}</li>
										<li>Website Title : {company.website_title}</li>
										<li>Support Phone : {company.support_phone}</li>
										<li>Land Line Number : {company.landline_number}</li>
										<li>Allotment Type : {company.allotment_type}</li>
									</ul>
								</div>
							))}
						</div>
					</div>

					<div className="col-12 col-md-6 col-xl-4 mt-md-0 mt-4">
						<div className="card h-100">
							<div className="card-header pb-0 p-3">
								<div className="row">
									<div className="col-md-8 d-flex align-items-center">
										<h6 className="mb-0">Registration & Address</h6>
									</div>
									<div className="col-md-4 text-end">
										<Link

											to={`/admin/company/edit/${userId}`}
										>
											<i
												className="fas fa-pen text-secondary text-sm"
												data-bs-toggle="tooltip"
												data-bs-placement="top"
												aria-hidden="true"
												aria-label="Edit Profile"
												data-bs-original-title="Edit Profile"
											></i>
										</Link>
									</div>
								</div>
							</div>

							{filteredCompaniesAddress.map((company, index) => (
								<div key={index} className="card-body p-3">
									<ul className="m-0 list-unstyled">
										<li>Address 1 : {company.address1} </li>
										<li>Address 2 : {company.address2}</li>
										<li>Address 3 : {company.address3}</li>
										<li>Landmark : {company.landmark}</li>
										<li>Phone : {company.phone}</li>
										<li>City : {company.city}</li>
										<li>State : {company.state}</li>
										<li>Country : {company.country}</li>
										<li>CIN : {company.CIN}</li>
										<li>TAN : {company.TAN}</li>
										<li>PAN : {company.PAN}</li>
										<li>GSTIN : {company.GSTIN}</li>
									</ul>
								</div>
							))}
						</div>
					</div>
					<div className="col-12 col-md-4 col-xl-4 mt-md-0 mt-4">
						<div className="card h-100">
							<div className="card-header pb-0 p-3">
								<div className="row">
									<div className="col-md-8 d-flex align-items-center">
										<h6 className="mb-0">Social</h6>
									</div>
									<div className="col-md-4 text-end">
										<Link to={`/admin/company/edit/${userId}`}
										>
											<i
												className="fas fa-pen text-secondary text-sm"
												data-bs-toggle="tooltip"
												data-bs-placement="top"
												aria-hidden="true"
												aria-label="Edit Profile"
												data-bs-original-title="Edit Profile"
											></i>
										</Link>
									</div>
								</div>
							</div>

							{filteredCompaniesLink.map((company, index) => (
								<div key={index} className="card-body p-3">
									<ul className="m-0 list-unstyled">
										<li className="mt-0">
											<i className="fab fa-facebook-square"></i> Facebook :{" "}
											{company.facebook}
										</li>
										<li className="mt-1">
											<i className="fab fa-instagram-square"></i> Instagram :{" "}
											{company.instagram}
										</li>
										<li className="mt-1">
											{" "}
											<i className="fab fa-twitter-square"></i> Twitter :{" "}
											{company.twitter}
										</li>
										<li className="mt-1">
											{" "}
											<i className="fa-brands fa-linkedin"></i> Linkedin :{" "}
											{company.printerest}
										</li>
										<li className="mt-1">
											<i className="fab fa-google-plus-square"></i> Google My
											Account : {company.google}
										</li>
										<li className="mt-1">
											<i className="fab fa-youtube"></i> Youtube :{" "}
											{company.youtube}
										</li>
										<li className="mt-1">
											<i className="fas fa-plus-square"></i> Extra :{" "}
											{company.extra}
										</li>
									</ul>
								</div>
							))}
						</div>
					</div>
					{/* <div className="col-12 col-md-6 col-xl-4">
						<div className="card h-100">
							<div className="card-header pb-0 p-3">
								<h6 className="mb-0">Notification Settings</h6>
							</div>
							<div className="card-body p-3">
								<h6 className="text-uppercase text-body text-xs font-weight-bolder">
									Account
								</h6>
								<ul className="list-group">
									<li className="list-group-item border-0 px-0">
										<div className="form-check form-switch ps-0">
											<input
												className="form-check-input ms-auto"
												type="checkbox"
												id="flexSwitchCheckDefault"
												checked=""
											/>
											<label
												className="form-check-label text-body ms-3 text-truncate w-80 mb-0"
												for="flexSwitchCheckDefault"
											>
												Email me when someone follows me
											</label>
										</div>
									</li>
									<li className="list-group-item border-0 px-0">
										<div className="form-check form-switch ps-0">
											<input
												className="form-check-input ms-auto"
												type="checkbox"
												id="flexSwitchCheckDefault1"
											/>
											<label
												className="form-check-label text-body ms-3 text-truncate w-80 mb-0"
												for="flexSwitchCheckDefault1"
											>
												Email me when someone answers on my post
											</label>
										</div>
									</li>
									<li className="list-group-item border-0 px-0">
										<div className="form-check form-switch ps-0">
											<input
												className="form-check-input ms-auto"
												type="checkbox"
												id="flexSwitchCheckDefault2"
												checked=""
											/>
											<label
												className="form-check-label text-body ms-3 text-truncate w-80 mb-0"
												for="flexSwitchCheckDefault2"
											>
												Email me when someone mentions me
											</label>
										</div>
									</li>
								</ul>
								<h6 className="text-uppercase text-body text-xs font-weight-bolder mt-4">
									Application
								</h6>
								<ul className="list-group">
									<li className="list-group-item border-0 px-0">
										<div className="form-check form-switch ps-0">
											<input
												className="form-check-input ms-auto"
												type="checkbox"
												id="flexSwitchCheckDefault3"
											/>
											<label
												className="form-check-label text-body ms-3 text-truncate w-80 mb-0"
												for="flexSwitchCheckDefault3"
											>
												New launches and projects
											</label>
										</div>
									</li>
									<li className="list-group-item border-0 px-0">
										<div className="form-check form-switch ps-0">
											<input
												className="form-check-input ms-auto"
												type="checkbox"
												id="flexSwitchCheckDefault4"
												checked=""
											/>
											<label
												className="form-check-label text-body ms-3 text-truncate w-80 mb-0"
												for="flexSwitchCheckDefault4"
											>
												Monthly product updates
											</label>
										</div>
									</li>
									<li className="list-group-item border-0 px-0 pb-0">
										<div className="form-check form-switch ps-0">
											<input
												className="form-check-input ms-auto"
												type="checkbox"
												id="flexSwitchCheckDefault5"
											/>
											<label
												className="form-check-label text-body ms-3 text-truncate w-80 mb-0"
												for="flexSwitchCheckDefault5"
											>
												Subscribe to newsletter
											</label>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div> */}
				</div>

				<div className="row mt-3">

					<div className="col-12 col-md-4 col-xl-4 mt-md-0 mt-4">
						<div className="card h-100">
							<div className="card-header pb-0 p-3">
								<div className="row">
									<div className="col-md-8 d-flex align-items-center">
										<h6 className="mb-0">Mobile Apps Link</h6>
									</div>
									<div className="col-md-4 text-end">
										<Link to={`/admin/company/edit/${userId}`}
										>
											<i
												className="fas fa-pen text-secondary text-sm"
												data-bs-toggle="tooltip"
												data-bs-placement="top"
												aria-hidden="true"
												aria-label="Edit Profile"
												data-bs-original-title="Edit Profile"
											></i>
										</Link>
									</div>
								</div>
							</div>

							<div className="card-body p-3">
								{filteredCompaniesLink.map((company) => (
									<div key={company.id}>
										<ul className="m-0 list-unstyled">
											<li>
												B2B User Android : :
												<strong>{company.b2buser_android}</strong>{" "}
											</li>
											<li>
												B2B User IOS : <strong>{company.b2buser_ios} </strong>
											</li>
										</ul>
									</div>
								))}

								{filteredCompanies.map((company) => (
									<div key={company.id}>
										<h6 className="mt-4">Logos & Images</h6>
										<ul className="m-0 list-unstyled">
											<li>Header Logo : :{company.header_logo}</li>
											<li>Loader : {company.leader_logo}</li>
											<li>Footer Logo : {company.footer_logo}</li>
											<li>Favicon Logo : {company.favicon_logo}</li>
										</ul>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
