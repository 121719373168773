import React from "react";
import { Link } from "react-router-dom";

function ViewInfo({ itemValue, option, title, subTitle, containerStyles, fullWidth }) {
  return (
    <div className={!containerStyles ? 'px-5 pt-4 pb-0' : ''} style={containerStyles}>
      <h6 className=" pt-1 ps-2" style={{ fontWeight: "bold" }}>
        {title}
      </h6>
      <p className="ps-7 mb-1" style={{ fontWeight: "bold", color: "black" }}>
        {subTitle}
      </p>

      <div className="d-flex align-items-center justify-content-center">
        <div style={{ border: "1px solid #575460", width: fullWidth ? '100%' : "80%" }}>
          {itemValue?.map((item, index) => (
            <div
              key={index}
              style={{
                border: "1px solid #575460",
                display: "grid",
                gridTemplateColumns: "40% 60%",
              }}
            >
              <div
                style={{
                  borderRight: "2px solid #575460",
                  backgroundColor: "#7F50DA",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <p style={{ paddingRight: "15px", marginBottom: "0" }}>
                  {option[index]}
                </p>
              </div>
              <div
                style={{
                  backgroundColor: "#E8E0F8",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {item !== null &&
                  typeof item === "string" &&
                  item.startsWith("http") ? (
                  <a
                    href={item}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      marginLeft: "10px",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                  >
                    {item}
                  </a>
                ) : item !== null && typeof item === "string" ? (
                  <div
                    style={{ marginLeft: "10px" }}
                    dangerouslySetInnerHTML={{ __html: item }}
                  ></div>
                ) : typeof item === "object" &&
                  (item?.structure === "obj1" || item?.structure === "img") ? (
                  <ul
                    style={{
                      overflow: "auto",
                      maxHeight: "auto",
                      marginBottom: "2px",
                      marginTop: "2px",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {item?.innerItem?.length > 0 &&
                      item?.innerItem?.map((i, idx) => (
                        <li key={idx}>
                          <a
                            href={i}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textWrap: "wrap",
                              color: "blue",
                              textDecoration: "underline",
                            }}
                          >
                            <span>{i}</span>
                          </a>
                        </li>
                      ))}
                  </ul>
                ) : typeof item === "object" && item?.structure === "objStr" ? (
                  <ul
                    style={{
                      overflow: "auto",
                      maxHeight: "auto",
                      marginBottom: "2px",
                      marginTop: "2px",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {item?.innerItem?.length > 0 &&
                      item?.innerItem?.map((i, idx) => {
                        if (i !== "") {
                          return <li key={i}>{i}</li>;
                        } else {
                          return null;
                        }
                      })}
                  </ul>
                ) : (
                  <ul
                    style={{
                      overflow: "auto",
                      maxHeight: "auto",
                      marginBottom: "4px",
                      marginTop: "4px",
                    }}
                    dangerouslySetInnerHTML={{ __html: item }}
                  ></ul>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ViewInfo;
