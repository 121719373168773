import React, { useContext, useEffect, useState } from "react";

import "../userlockscreen/Userlockscreen.css";
import Footer from "../components/Footer/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import logo from "../assets/logo.png";
import user from "../assets/user.png";
import useHttp from "../Hooks/useHttp";
import AppContext from "../Context/AppContext";
import CircularLoader from "../CircularLoader/CircularLoader";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, setUserInfo } from "../../reducers/authSlice";

export default function Userlockscreen() {
	const { userAccData, userInfo } = useSelector(getUserInfo);
	const [loading, setLoading] = useState(false);
	const { setShowLockScreen } = useContext(AppContext);
	const [err, setErr] = useState({});
	const [password, setPassword] = useState();
	const [typePass, setTypePass] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { PostRequest } = useHttp();
	const API = process.env.REACT_APP_API_URL;
	const gotologin = () => {
		localStorage.clear();
		if (location.pathname.startsWith("/admin")) {
			navigate("/admin/login");
		} else if (location.pathname.startsWith("/researcher")) {
			navigate("/researcher/login");
		} else if (location.pathname.startsWith("/customer")) {
			navigate("/customer/login");
		} else {
			return;
		}
	};
	let role = localStorage.getItem('role');

	const handleUnlock = async () => {
		const param = {
			password: password,
			username: userInfo?.user_data?.email,
			role,
		};
		if (role === 'sub_admin') {
			setLoading(true);
			if (
				(param.email !== null || param.email !== "") &&
				(param.password !== null || param.password !== "")
			) {
				const { data, error } = await PostRequest(
					API + "sub_admin/login/",
					param,
					{}
				);
				if (error) {
					setLoading(false);
					setErr({ ...error.data });
				}
				if (data) {
					setLoading(false);
					localStorage.clear();
					dispatch(setUserInfo({ ...data, role: role }));
					localStorage.setItem("token", data.access_token);
					localStorage.setItem("refreshToken", data?.refresh_token);
					localStorage.setItem("userid", data.user_data.id);
					localStorage.setItem("showLockScreen", 'false');
					localStorage.setItem("role", role);
					if (data?.role?.permissions)
						localStorage.setItem("rolePermissions", JSON.stringify(data.role.permissions));
					localStorage.setItem("userInfo", JSON.stringify({
						...data, role: role,
						permissions: data?.role?.permissions ?? [],
					}));
					navigate(location.pathname);
					setShowLockScreen(false);
				}
			}
		}
		if (location.pathname.startsWith("/admin") && role === 'admin') {
			setLoading(true);
			if (
				(param.email !== null || param.email !== "") &&
				(param.password !== null || param.password !== "")
			) {
				const { data, error } = await PostRequest(
					API + "user/login/",
					param,
					{}
				);
				if (error) {
					setLoading(false);
					setErr({ ...error.data });
				}
				if (data) {
					setLoading(false);
					localStorage.clear();
					dispatch(setUserInfo({ ...data, role: role }));
					localStorage.setItem("token", data.access_token);
					localStorage.setItem("refreshToken", data?.refresh_token);
					localStorage.setItem("userid", data.user_data.id);
					// localStorage.setItem("role", data.user_data.role);
					localStorage.setItem("role", role);
					localStorage.setItem("userInfo", JSON.stringify({ ...data, role: role }));
					navigate(location.pathname);
					setShowLockScreen(false);
				}
			}
		}
		if (location.pathname.startsWith("/researcher")) {
			setLoading(true);
			if (
				(param.email !== null || param.email !== "") &&
				(param.password !== null || param.password !== "")
			) {
				const { data, error } = await PostRequest(
					API + "researcher/login/",
					param,
					{}
				);
				if (error) {
					setLoading(false);
					setErr({ ...error.data });
				}
				if (data) {
					setLoading(false);
					localStorage.clear();
					dispatch(setUserInfo({ ...data, role }));
					localStorage.setItem("token", data.access_token);
					localStorage.setItem("refreshToken", data?.refresh_token);
					localStorage.setItem("userid", data.user_data.id);
					// localStorage.setItem("role", data.user_data.role);
					localStorage.setItem("role", 'researcher');
					localStorage.setItem("userInfo", JSON.stringify({ ...data, role }));
					navigate(location.pathname);
					setShowLockScreen(false);
				}
			}
		}
		if (location.pathname.startsWith("/customer")) {
			setLoading(true);
			if (
				(param.email !== null || param.email !== "") &&
				(param.password !== null || param.password !== "")
			) {
				const { data, error } = await PostRequest(
					API + "customer/login/",
					param,
					{}
				);
				if (error) {
					setLoading(false);
					setErr({ ...error.data });
				}
				if (data) {
					setLoading(false);
					localStorage.clear();
					dispatch(setUserInfo({ ...data, role }));
					localStorage.setItem("token", data.access_token);
					localStorage.setItem("refreshToken", data?.refresh_token);
					localStorage.setItem("userid", data.user_data.id);
					if (data?.support_access_token && data?.support_access_token !== 'None') {
						localStorage.setItem("supporttoken", data?.support_access_token);
					}
					// localStorage.setItem("role", data.user_data.role);
					localStorage.setItem("role", 'customer');
					localStorage.setItem("userInfo", JSON.stringify({ ...data, role }));
					navigate(location.pathname);
					setShowLockScreen(false);
				}
			}
		}
	};

	const handleEnterKey = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault(); // Prevent form submission if inside a form
			handleUnlock();
		}
	}
	// useEffect(() => {
	// 	fetchUser();
	// }, [navigate]);
	return (
		<>
			{loading && <CircularLoader size={100} />}
			<div className="bg-gray-100">
				<main className="main-content  mt-0" style={{ height: "90vh" }}>
					<div className="container" id="logincard">
						<Link to="/">
							<div className=" top-content w-100">
								<img className="logo" src={logo} alt="logo" width="20%" />
							</div>
						</Link>
						<div
							className="row   justify-content-center"
							style={{ marginTop: " 171px!important" }}
						>
							<div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
								<div className="card z-index-0">
									<div className="main-welcome">
										<h4 className="main-welcome-head">Screen Lock</h4>
										<span className="main-welcome-para">
											Enter your Password to unlock the Screen
										</span>
									</div>
									<div className="text-center avater">
										<div
											style={{
												width: "50px",
												height: "50px",
												borderRadius: "50%",
											}}
										>
											{userAccData && userAccData.display_pic ? (
												<img
													src={userAccData.display_pic}
													alt="avater"
													style={{ width: "100%", height: "100%" }}
												/>
											) : (
												<img
													src={user}
													alt="avater"
													style={{ width: "100%", height: "100%" }}
												/>
											)}
										</div>
										<div>
											<span>{userAccData.first_name}</span>
											<span style={{ marginLeft: "8px" }}>
												{userAccData.last_name}
											</span>
										</div>
									</div>
									<div className="card-body">
										<Form className="text-start">
											<Form.Group
												className="mb-2 "
												style={{ position: "relative" }}
											>
												<Form.Label htmlFor="Password">Password</Form.Label>
												<div style={{ position: "relative" }}>
													<Form.Control
														type={typePass ? "text" : "password"}
														className="form-control"
														placeholder="Enter Password"
														aria-label="Password"
														value={password}
														onChange={(e) => setPassword(e.target.value)}
													/>
													<span
														className="lockScreenEyePass"
														onClick={() => setTypePass(!typePass)}
													>
														{/* <img src="../assets/img/icons/Eye.png" alt="" /> */}
														{typePass ? (
															<i className="fa-regular fa-eye-slash"></i>
														) : (
															<i className="fa-regular fa-eye"></i>
														)}
													</span>
												</div>
												<p
													style={{
														color: "red",
														fontSize: "13px",
														fontWeight: "500",
													}}
												>
													{err.non_field_errors || err.detail || err.password}
												</p>
											</Form.Group>

											<div className="text-center">
												<button
													onClick={handleUnlock}
													onKeyDown={(e) => handleEnterKey(e)}
													type="button"
													className="loginbtn btn  w-100 mt-3 mb-2"
												>
													Unlock
												</button>
											</div>
											<div className="text-center my-2">
												<span className="text-decoration-underline text-cl">
													<button
														style={{
															backgroundColor: "transparent",
															color: "#7F56D9",
														}}
														onClick={gotologin}
													>
														Sign In
													</button>
												</span>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
				<Footer />
			</div>
		</>
	);
}
