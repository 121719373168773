import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";

export default function useFetchCustomer(
  dependency = [],
  // currentPage,
  // searchValue,
  // searchQuery
) {
  const [customerOpt, setCustomerOpt] = useState();
  const { setLoading } = useContext(AppContext);

  const { invokeApi } = HTTPService();
  useEffect(() => {
    setLoading(true);
    let url = `customer/`;
    // ?page=${currentPage}&page_size=25
    // if (searchQuery && searchQuery.length > 0) {
    //   url = url + `&${searchValue}=${searchQuery}`;
    // }
    const fetchCustomer = () => {
      invokeApi({
        method: "GET",
        url,
      })
        .then((data) => {
          setLoading(false);

          setCustomerOpt(data);
        })
        .catch((error) => {
          setLoading(false);

          toast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        });
    };
    fetchCustomer();
  }, [...dependency]);
  return { customerOpt };
}
