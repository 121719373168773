import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import useHttp from "../../Hooks/useHttp";
import { toast } from "react-toastify";
import ViewInfo from "../../common/ViewInfo";
import ViewTable from "../../common/ViewTable";
import { getUserInfo } from "../../../reducers/authSlice";
import { useSelector } from "react-redux";
import withPermission from "../../../hoc/withPermission";
import ProfileImage from "../../../components/ProfileImage";
import { customToast } from "../../../App";

function ViewSubmission() {
  const { userInfo, permissions } = useSelector(getUserInfo);

  const permission = permissions.find((perm) => perm.module === "Submissions");

  const { GetRequest } = useHttp();
  const [submissionData, setSubmissionData] = useState({});
  const [submissionUserData, setSubmissionUserData] = useState({});
  const [programData, setProgramData] = useState({});
  const API = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { id } = useParams();
  const [TUrl, setTUrl] = useState({ innerItem: [], structure: "" });
  const [Tota, setTota] = useState({ innerItem: [], structure: "" });
  const [lf, setLf] = useState({ innerItem: [], structure: "" });
  const [ae, setAe] = useState({ innerItem: [], structure: "" });
  const [EvAttach, setEvAttach] = useState({ innerItem: [], structure: "" });
  const [AttachDoc, setAttachDoc] = useState({ innerItem: [], structure: "" });
  const viewAllRoute = ["admin", "sub_admin"].includes(userInfo.role)
    ? "/admin/submissions"
    : userInfo.role == "customer"
      ? "/customer/submissions"
      : "/researcher/submissions";

  const fetchSubmission = async () => {
    const { data, error } = await GetRequest(
      API + "submissions/" + id + "/",
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
    if (data) {
      setSubmissionUserData(data.user_details);
      setSubmissionData(data);
    }
  };

  const fetchProgram = async (programId) => {
    const { data, error } = await GetRequest(
      API + "programs/" + programId + "/",
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (error) {
      console.log(error);
    }
    if (data) {
      setProgramData(data);
    }
  };

  const fetchTUrl = () => {
    const targetURLs = [];
    if (submissionData.target_url1) targetURLs.push(submissionData.target_url1);
    if (submissionData.target_url2) targetURLs.push(submissionData.target_url2);
    if (submissionData.target_url3) targetURLs.push(submissionData.target_url3);
    if (submissionData.target_url4) targetURLs.push(submissionData.target_url4);
    if (submissionData.target_url5) targetURLs.push(submissionData.target_url5);
    setTUrl({ innerItem: targetURLs, structure: "obj1" });
  };

  const fetchTota = () => {
    const totaArr = submissionData?.type_of_testing_allowed?.split(",") || [];
    setTota({ innerItem: totaArr, structure: "objStr" });
  };

  const fetchLf = () => {
    const LfArr = submissionData?.languages_frameworks?.split(",") || [];
    setLf({ innerItem: LfArr, structure: "objStr" });
  };

  const fetchAe = () => {
    const AeArr = submissionData?.asset_environments?.split(",") || [];
    setAe({ innerItem: AeArr, structure: "objStr" });
  };

  const fetchEvAttach = () => {
    const evattch = [];
    if (submissionData.evidence_attachments1)
      evattch.push(submissionData.evidence_attachments1);
    if (submissionData.evidence_attachments2)
      evattch.push(submissionData.evidence_attachments2);
    if (submissionData.evidence_attachments3)
      evattch.push(submissionData.evidence_attachments3);
    if (submissionData.evidence_attachments4)
      evattch.push(submissionData.evidence_attachments4);
    if (submissionData.evidence_attachments5)
      evattch.push(submissionData.evidence_attachments5);
    setEvAttach({ innerItem: evattch, structure: "obj1" });
  };

  const fetchAttachDoc = () => {
    const attachdoc = [];
    if (submissionData.attachments_upload_doc1)
      attachdoc.push(submissionData.attachments_upload_doc1);
    if (submissionData.attachments_upload_doc2)
      attachdoc.push(submissionData.attachments_upload_doc2);
    if (submissionData.attachments_upload_doc3)
      attachdoc.push(submissionData.attachments_upload_doc3);
    if (submissionData.attachments_upload_doc4)
      attachdoc.push(submissionData.attachments_upload_doc4);
    if (submissionData.attachments_upload_doc5)
      attachdoc.push(submissionData.attachments_upload_doc5);
    setAttachDoc({ innerItem: attachdoc, structure: "obj1" });
  };

  useEffect(() => {
    fetchSubmission();
  }, []);

  useEffect(() => {
    if (submissionData.id) {
      fetchTUrl();
      fetchTota();
      fetchLf();
      fetchAe();
      fetchEvAttach();
      fetchAttachDoc();
    }
  }, [submissionData]);

  useEffect(() => {
    if (submissionData.program_id) fetchProgram(submissionData.program_id);
  }, [submissionData]);

  const fullname = `${submissionUserData?.first_name || ""} ${submissionUserData?.last_name || ""}`;

  const NewSubmissionUser = {
    user_id: submissionUserData?.user_id,
    fullname,
    username: submissionUserData?.username,
  };
  const NewProgramData = {
    program_id: programData.program_id,
    program_title: programData.program_title,
    program_type: programData.program_type,
  };

  const NewSubmission = {
    submissions_id: submissionData?.submissions_id,
    submission_title: submissionData?.submission_title,
    detail_description: submissionData?.detail_description,
    step_to_reproduce: submissionData?.step_to_reproduce,
    remediation_recommendation: submissionData?.remediation_recommendation,
    target_title: submissionData?.target_title,
    TUrl,
    Tota,
    lf,
    ae,
  };

  const AttachmentOption = [
    "Evidence Attachments",
    "Attachments(Upload Documents)",
  ];
  const AttachmentsValue = [EvAttach, AttachDoc];

  const Breadcrumbname = {
    home: "/admin/home",
    Management: "",
    Submission: "/admin/submissions",
    "View Submission": "",
  };

  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row w-100 d-flex justify-content-center">
          <div className="  my-4">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="col-lg-6 ps-3">
                <Breadcrumb names={Breadcrumbname} />
              </div>

              <div className="button-row d-flex justify-content-end mt-0 mb-3">
                <Link
                  to={viewAllRoute}
                  className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                  id="adduser"
                >
                  VIEW ALL
                </Link>
              </div>
            </div>

            <div className="col-12">
              <div className="card p-5" style={{ backgroundColor: "white" }}>

                <div className="row justify-content-end mb-4 pb-4">
                  <div className="col-sm-12 col-md-8 text-dark" style={{ fontSize: '16px' }}>
                    <div>Submission ID: <span style={{ fontWeight: '600' }}>{NewSubmission.submissions_id}</span></div>
                    <div><span style={{ fontWeight: '600' }}>{NewSubmission.submission_title}</span></div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="row justify-content-end">
                      <div className="col-sm-10 d-flex flex-column align-items-end text-dark">
                        <div style={{ fontSize: '20px', fontWeight: '800' }} className="text-primary">{NewSubmissionUser.fullname}</div>
                        <div style={{ fontSize: '14px' }}>User Name: <span style={{ fontWeight: '600' }}>{NewSubmissionUser.username}</span></div>
                        <div style={{ fontSize: '14px' }}>ID: <span style={{ fontWeight: '600' }}>{NewSubmissionUser.user_id}</span></div>
                      </div>
                      <div className="col-sm-2"> <ProfileImage /></div>
                    </div>
                  </div>
                </div>
                <div className="row p-3">
                  <div className="mr-2 col-sm-12 col-md-12 bg-light mt-4 rounded overflow-hidden border">
                    <div className="bg-dark text-white p-2 pl-2 text-center"><i className="mr-2 fa-solid fa-table"></i>Submission Description</div>
                    <div className="text-dark p-2 pb-4">
                      <div>
                        <span dangerouslySetInnerHTML={{ __html: NewSubmission.detail_description }}></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2 justify-content-between ">

                  <div className="col-sm-12 col-md-6">
                    <div className="col-sm-12 bg-light p-0 rounded overflow-hidden border">
                      <div className="bg-dark text-white p-2 pl-2"><i className="mr-2 fa-solid fa-square-check"></i>Program Details</div>
                      <div className="text-dark p-2 pb-2 pt-4">
                        <div><span style={{ display: 'inline-block', width: '50px' }}>ID</span>: {NewProgramData.program_id}</div>
                        <div><span style={{ display: 'inline-block', width: '50px' }}>Title</span>: {NewProgramData.program_title}</div>
                        <div><span style={{ display: 'inline-block', width: '50px' }}>Type</span>: {NewProgramData.program_type}</div>
                      </div>
                    </div>
                    <div className="col-sm-12 mt-2 bg-light p-0  rounded overflow-hidden border">
                      <div className="bg-dark text-white p-2 pl-2"><i className="mr-2 fa-solid fa-square-check"></i>Target Title & URLS</div>
                      <div className="text-dark p-2 pb-4 pt-4">
                        <div><span style={{ display: 'inline-block', width: '100px', fontWeight: 800 }}>Target Title</span><br />{NewSubmission.submission_title}</div>
                        <div><span style={{ display: 'inline-block', width: '100px', fontWeight: 800 }}>Target URLS</span><br /> {NewSubmission?.TUrl?.innerItem?.map(url => <div>{url}</div>)}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="col-sm-12 p-0">
                          <div className="bg-light h-100  rounded overflow-hidden border" style={{ marginRight: '4px', marginLeft: '4px' }}>
                            <div className="bg-dark text-white p-2 pl-2"><i className="fa-solid fa-paperclip mr-2"></i> Evidence Attachments</div>
                            <div className="text-dark p-2 pb-2 pt-4">
                              <div>{EvAttach?.innerItem?.map(data => <a
                                href={data}
                                key={data}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-primary"
                                style={{
                                  textWrap: "wrap",
                                }}
                              ><span>{data.split('/')[data.split('/').length - 1]}</span>
                              </a>)}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 mt-2 bg-light p-0  rounded overflow-hidden border">
                          <div className="bg-dark text-white p-2 pl-2"><i className="fa-solid fa-paperclip mr-2"></i> Attachments(Upload Documents)</div>
                          <div className="text-dark p-2 pb-2 pt-4">
                            <div>{AttachDoc?.innerItem?.map(data =>
                              <a
                                href={data}
                                key={data}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-primary"
                                style={{
                                  textWrap: "wrap",
                                }}
                              ><span>{data.split('/')[data.split('/').length - 1]}</span>
                              </a>)}</div>
                          </div>
                        </div>
                        <div className="col-sm-12 mt-2 bg-light p-2 pt-2 pb-2 rounded overflow-hidden border" >
                          <div><span style={{ display: 'inline-block', width: '120px', fontWeight: 800 }}>Severity</span>: {submissionData?.severity}</div>
                          <div><span style={{ display: 'inline-block', width: '120px', fontWeight: 800 }}>Priority</span>: {submissionData?.priority}</div>
                          <div><span style={{ display: 'inline-block', width: '120px', fontWeight: 800 }}>VTx</span>: {submissionData?.vtx}</div>
                          <div><span style={{ display: 'inline-block', width: '120px', fontWeight: 800 }}>CVSS Score</span>: {submissionData?.cvss_score ?? '-'}</div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="col-sm-12 p-0"                  >
                          <div className="bg-light h-100  rounded overflow-hidden border" style={{ marginRight: '4px', marginLeft: '4px' }}>
                            <div className="bg-dark text-white p-2 pl-2"><i className="mr-2 fa-solid fa-square-check"></i>Type of Testing Allowed</div>
                            <div className="text-dark p-2 pb-2 pt-4">
                              <div>{NewSubmission?.Tota?.innerItem?.map(data => <div><i className="mr-2 text-primary fa-solid fa-circle-check"></i>{data}</div>)}</div>
                            </div>
                          </div> </div>
                        <div className="col-sm-12 mt-2 p-0">
                          <div className="bg-light h-100  rounded overflow-hidden border" style={{ marginRight: '4px', marginLeft: '4px' }}>
                            <div className="bg-dark text-white p-2 pl-2"><i className="mr-2 fa-solid fa-square-check"></i>Languages & Framework</div>
                            <div className="text-dark p-2 pb-2 pt-4">
                              <div>{NewSubmission?.lf?.innerItem?.map(data => <div><i className="mr-2 text-primary fa-solid fa-circle-check"></i>{data}</div>)}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 mt-2 p-0">
                          <div className="bg-light h-100  rounded overflow-hidden border" style={{ marginRight: '4px', marginLeft: '4px' }}>
                            <div className="bg-dark text-white p-2 pl-2"><i className="mr-2 fa-solid fa-square-check"></i>Asset Environments</div>
                            <div className="text-dark p-2 pb-2 pt-4">
                              <div>{NewSubmission?.ae?.innerItem?.map(data => <div><i className="mr-2 text-primary fa-solid fa-circle-check"></i>{data}</div>)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="mr-2 col-sm-12 col-md-12 bg-light p-0 mt-4 rounded overflow-hidden border">
                    <div className="bg-dark text-white p-2 pl-2 text-center"><i className="mr-2 fa-solid fa-stairs"></i>Steps to Reproduce</div>
                    <div className="text-dark p-2 pb-4">
                      <div>
                        <span dangerouslySetInnerHTML={{ __html: NewSubmission.step_to_reproduce }}></span>
                      </div>
                    </div>
                  </div>
                  <div className="mr-2 col-sm-12 col-md-12 bg-light p-0 mt-4 rounded overflow-hidden border">
                    <div className="bg-dark text-white p-2 pl-2 text-center"><i className="mr-2 fa-solid fa-table"></i>Remediation Recommendation</div>
                    <div className="text-dark p-2 pb-4">
                      <div>
                        <span dangerouslySetInnerHTML={{ __html: NewSubmission.remediation_recommendation }}></span>

                      </div>
                    </div>
                  </div>

                </div>



                <div className=" d-flex justify-content-end p-4">
                  <button
                    className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                  {permission?.edit ||
                    (["admin"].includes(userInfo.role) && (
                      <button
                        type="button"
                        className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
                        onClick={() => {
                          if (["admin", "sub_admin"].includes(userInfo.role))
                            navigate(`/admin/submissions/edit/${id}`);
                          else if (userInfo.role == "customer")
                            navigate(`/customer/submissions/edit/${id}`);
                          else if (userInfo.role == "researcher")
                            navigate(`/researcher/submissions/edit/${id}`);
                        }}
                      >
                        Edit
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withPermission(ViewSubmission);
ViewSubmission.moduleName = "Submissions";
