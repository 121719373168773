import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import userdata from "./Reportdata.json";
import ReportModal from "../../common/ReportModal/ReportModal";
import HTTPService from "../../../utils/axios";
import moment from "moment";
import withPermission from "../../../hoc/withPermission";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../reducers/authSlice";
import { customToast } from "../../../App";

function ResearcherReportsDashboard() {
  const { userInfo, userAccData, permissions } = useSelector(getUserInfo);

  console.log("test---", userAccData.user_id, userAccData.id, userInfo.role);
  const [isOpen, setIsOpen] = useState(false);
  const [reportList, setReportList] = useState([]);
  const permission = permissions.find((perm) => perm.module === "Reports");
  const { invokeApi } = HTTPService();
  const Breadcrumbname = {
    Home: "/researcher/home",
    Reports: "/researcher/reports-dashboard",
    "All Reports": "/researcher/reports-dashboard",
    Dashboard: "/researcher/reports-dashboard",
  };

  const handleDelete = async (id) => {
    invokeApi({
      method: "DELETE",
      url: `/report/${id}/`,
    })
      .then((data) => {
        setReportList(reportList.filter((obj) => obj.id !== id));
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const fetchData = async () => {
    invokeApi({
      method: "GET",
      url: `/report/`,
    })
      .then((data) => {
        setReportList(data.results);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                id="adduser"
              >
                Generate New Report
              </button>
            </div>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table align-items-center mb-0 tableHeaderStyle">
                  <thead>
                    <tr className="text-center">
                      <th
                        className="text-uppercase  font-weight-bolder opacity-8 text-start"
                        style={{ paddingLeft: "1.5rem" }}
                      >
                        Title
                      </th>
                      <th
                        className="text-uppercase  font-weight-bolder opacity-8 text-start"
                        style={{ paddingLeft: "1.5rem" }}
                      >
                        Type
                      </th>
                      <th
                        className="text-uppercase  font-weight-bolder opacity-8text-start "
                        style={{ paddingLeft: "1.5rem" }}
                      >
                        User ID
                      </th>
                      <th
                        className="text-uppercase  font-weight-bolder opacity-8text-start "
                        style={{ paddingLeft: "1.5rem" }}
                      >
                        Username
                      </th>
                      <th
                        className="text-uppercase  font-weight-bolder opacity-8text-start "
                        style={{ paddingLeft: "1.5rem" }}
                      >
                        User Type
                      </th>
                      <th className="text-uppercase  font-weight-bolder opacity-8 ps-2">
                        Created
                      </th>
                      <th className=" text-uppercase  font-weight-bolder opacity-8">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportList &&
                      reportList.map((item, index) => (
                        <tr key={index} className="text-center">
                          <td>
                            <p className="text-sm text-secondary mb-0 px-2 text-start">
                              {item.title}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm text-secondary mb-0 px-2 text-start">
                              {item.report_type}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm text-secondary mb-0 px-2 text-start">
                              {item.user_details.user_id}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm text-secondary mb-0 px-2 text-start">
                              {item.user_details.username}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm text-secondary mb-0 px-2 text-start">
                              {item.user_details.role}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm text-secondary mb-0 px-2">
                              {moment(item.created_at).format(
                                "YYYY-MM-DD HH:mm:ss [IST]"
                              )}
                            </p>
                          </td>
                          <td className="text-center">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "2rem",
                                justifyContent: "center",
                              }}
                            >
                              <i
                                onClick={() => {
                                  window.open(item.report);
                                }}
                                style={{ color: "#7F56D9", cursor: "pointer" }}
                                className="fa fa-eye"
                                aria-hidden="true"
                              ></i>
                              <i
                                onClick={() => {
                                  handleDelete(item.id);
                                }}
                                style={{
                                  color: "#7F56D9",
                                  cursor: "pointer",
                                }}
                                className="fa fa-trash"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {isOpen && (
          <ReportModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            user_id={userAccData.user_id}
            uid={userAccData.id}
            role={userInfo.role}
          />
        )}
      </div>
    </>
  );
}

export default withPermission(ResearcherReportsDashboard);
ResearcherReportsDashboard.moduleName = "Reports";
