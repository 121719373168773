import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HTTPService from "../../../../utils/axios";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import AppContext from "../../../Context/AppContext";
import { customToast } from "../../../../App";

export default function EditVTxTaxonomy() {
	const { setCircularLoading } = useContext(AppContext);
	const { invokeApi } = HTTPService();
	const { id } = useParams();
	const [vtx, setVtx] = useState();
	const navigate = useNavigate();
	const handleChange = (field, value) => {
		setVtx(prev => ({
			...prev,
			[field]: value
		}))
	}
	const handleSubmit = async () => {
		setCircularLoading(true);
		invokeApi({
			method: "PATCH",
			url: `submissions-vtx/${id}`,
			data: vtx,
		}).then((res) => {
			setCircularLoading(false);
			customToast.success("Data updated successfully", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
			navigate("/admin/vtxtaxonomy/dashboard");
		}).catch((error) => {
			setCircularLoading(false);
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		});

	};
	const fetchVtx = () => {
		setCircularLoading(true);
		invokeApi({
			method: "GET",
			url: `submissions-vtx/${id}`,
		}).then((res) => {
			setCircularLoading(false);
			setVtx(res)
		}).catch((error) => {
			setCircularLoading(false);
			customToast.error("Something went wrong!", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		});
	}

	useEffect(() => {
		fetchVtx();
	}, []);

	const Breadcrumbname = {
		home: "/admin/home",
		"Master Module": "",
		"VTx Taxonomy": "/admin/vtxtaxonomy",
		Dashboard: "/admin/vtxtaxonomy",
	};
	return (
		<>
			<div className="container-fluid py-4 pb-0">
				<div className="row w-100 d-flex justify-content-center">
					<div className="addCategoryArea  my-4">
						<div className="d-flex justify-content-between">
							<div className="">
								<Breadcrumb names={Breadcrumbname} />
							</div>
							<div className=" d-flex justify-content-end mt-0 mb-3">
								<Link
									to="/admin/vtxtaxonomy/dashboard"
									className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
									id="adduser"
								>
									VIEW ALL
								</Link>
							</div>
						</div>
						<div className="col-12">
							<div className="card">
								<div className="row">
									<div className="card mt-0" id="basic-info">
										<div className="card-header">
											<h5>EDIT FAQ MAIN CATEGORY</h5>
										</div>
										<div className="card-body pt-0">
											<div className="row">
												<div className="col-sm-6">
													<label className="form-label mt-4">
														Main Category
													</label>
													<div className="input-group ">
														<input
															className="form-control"
															placeholder="Enter Main Category"
															type="text"
															value={vtx?.main_category}
															onChange={(e) => handleChange('main_category', e.target.value)}
														/>
													</div>
												</div>
												<div className="col-6">
													<label className="form-label mt-4">
														Sub Category
													</label>

													<div className="input-group">
														<input
															className="form-control"
															type="text"
															placeholder="Enter sub Category"
															value={vtx?.sub_category}
															onChange={(e) => handleChange('sub_category', e.target.value)}
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-6">
													<label className="form-label mt-4">
														Variant Title
													</label>

													<div className="input-group">
														<input
															className="form-control"
															type="text"
															placeholder="Enter sub Category"
															value={vtx?.variant_title}
															onChange={(e) => handleChange('variant_title', e.target.value)}
														/>
													</div>
												</div>
												<div className="col-6">
													<label className="form-label mt-4">
														Generic Description
													</label>

													<div className="input-group">
														<textarea
															className="form-control"
															value={vtx?.generic_description}
															onChange={(e) => handleChange('generic_description', e.target.value)}
														/>
													</div>
												</div>
											</div>


											<div className=" d-flex justify-content-end">
												<Link
													to="/admin/vtxtaxonomy/dashboard"
													className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0 px-3"
												>
													Cancel
												</Link>
												<button
													onClick={handleSubmit}
													className="btn bg-gradient-dark btn-sm float-end mt-4 ms-3 mb-0 px-3"
												>
													Update
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
