import React, { useContext, useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
// import CmsPagesData from "../CmsPages/CmsPagesData.json";

import "../CmsPages/cmspagestyle.css";

import useHttp from "../../../Hooks/useHttp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddModal from "../../../common/modal/AddModal";
import AppContext from "../../../Context/AppContext";
import CommonTable from "../../../common/Table/CommonTable";
import { getUserInfo } from "../../../../reducers/authSlice";
import { useSelector } from "react-redux";
import withPermission from "../../../../hoc/withPermission";
import { customToast } from "../../../../App";

const CmsPagesDashboard = () => {
  const { userInfo, permissions } = useSelector(getUserInfo);
  const permission = permissions.find((perm) => perm.module === "CMS Pages");
  const { setLoading } = useContext(AppContext);
  const [cmsPageData, setCmsPageData] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [openAddModal, setOpenAddModal] = useState({ isOpen: false });
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { DeleteRequest, GetRequest, PutRequest } = useHttp();
  const token = localStorage.getItem("token");
  const API = process.env.REACT_APP_API_URL;
  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();

  const handleStatus = async (
    id,
    pageT,
    pageC,
    sequence,
    uId,
    utype,
    subCatId,
    metaT,
    metaD,
    status
  ) => {
    const param = {
      page_title: pageT,
      page_content: pageC,
      sequence: sequence,
      user_id: uId,
      user_type: utype,
      sub_category_id: subCatId,
      meta_title: metaT,
      meta_description: metaD,
      cms_status: status,
    };
    const { data, error } = await PutRequest(
      API + "cms_pages/" + id + "/",
      param,
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      //alert("Something went wrong please check input");
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
    if (data) {
      fetchCms();
      customToast.success("status updated", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const fetchCms = async (hide) => {
    if (!hide)
      setLoading(true);
    let url = `${API}cms_pages/?page=${currentPage}&page_size=25`;
    if (searchQuery && searchQuery.length > 0) {
      url = url + `&${searchValue}=${searchQuery}`;
    }
    if (filterType && filterValue && filterValue.length > 0) {
      url = url + `&${filterType}=${filterValue}`;
    }
    const { data, error } = await GetRequest(
      url,
      {},
      {
        Authorization: "Bearer  " + token,
      }
    );
    if (error) {
      setLoading(false);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setLoading(false);
      setCmsPageData(data);
      setTotalPages(data.total_pages);
    }
  };

  const deleteCms = async (id) => {
    setOpenAddModal({ ...openAddModal, isOpen: false });
    const { error } = await DeleteRequest(API + "cms_pages/" + id + "/", {
      Authorization: "Bearer  " + token,
      "Content-Type": "multipart/form-data",
    });

    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      fetchCms();
      customToast.success("Deleted Successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  const tableColumn = [
    "page id",
    "page title",
    // "page content",
    "meta title",
    "meta description",
    "status",
    "action",
  ];
  const searchColumn = [
    { name: "Page Id", value: "cms_page_id" },
    { name: "Page Title", value: "page_title" },
  ];
  const filterColumn = [
    {
      name: "Status",
      value: "cms_status",
      filters: [
        { value: "active", label: "Active" },
        { value: "in_active", label: "Inactive" },]
    }
  ];
  useEffect(() => {
    fetchCms();
  }, []);

  useEffect(() => {
    fetchCms(true);
  }, [searchQuery, , currentPage, filterValue]);

  const Breadcrumbname = {
    home: "/admin/home",
    "Manage Module": "",
    "CMS Pages": "/admin/cms-pages",
    Dashboard: "/admin/cms-pages",
  };
  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              {(permission?.create || userInfo.role === "admin") && (
                <Link
                  to="/admin/cms-page/add"
                  className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                  id="adduser"
                >
                  ADD CMS Pages
                </Link>
              )}
            </div>
          </div>
        </div>

        <div className="table-overflow row my-4">
          <div className="col-12">
            <CommonTable
              filterList={filterColumn}
              filterType={filterType}
              setFilterType={setFilterType}
              setFilterValue={setFilterValue}
              column={tableColumn}
              data={cmsPageData && cmsPageData.results}
              totalPages={totalPages ?? 1}
              totalData={cmsPageData?.total_records}
              searchBar={true}
              searchBy={searchColumn}
              itemPerPg={25}
              setTable={3}
              card={7}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            >
              {cmsPageData &&
                cmsPageData.results &&
                cmsPageData.results.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        to={`/admin/cms-page/view/${item.id}`}
                        className="text-sm gotoView mb-0 px-2"
                      >
                        {item.cms_page_id}
                      </Link>
                    </td>
                    <td className="text-start">
                      <Link
                        to={`/admin/cms-page/view/${item.id}`}
                        className="text-sm gotoView mb-0 px-2"
                      >
                        {item.page_title}
                      </Link>
                    </td>

                    {/* <td
                      style={{ width: "30%" }}
                      className="text-wrap text-start"
                    >
                      <p className="text-sm  mb-0 ">
                        {item.page_content.replace(/(<([^>]+)>)/gi, "")}
                      </p>
                    </td> */}
                    <td className="text-start">
                      <p className="text-sm  mb-0">{item.meta_title}</p>
                    </td>
                    <td className="text-start">
                      <p className="text-sm  mb-0">{item.meta_description}</p>
                    </td>

                    <td className="align-middle text-center">
                      {item.cms_status === "active" ? (
                        <span
                          style={{ cursor: "pointer" }}
                          className="badge bg-success text-white"
                          onClick={() => {
                            handleStatus(
                              item.id,
                              item.page_title,
                              item.page_content,
                              item.sequence,
                              item.user_id,
                              item.user_type,
                              item.sub_category_id,
                              item.meta_title,
                              item.meta_description,
                              "in_active"
                            );
                          }}
                        >
                          Active
                        </span>
                      ) : (
                        <span
                          style={{ cursor: "pointer" }}
                          className="badge bg-danger text-white"
                          onClick={() => {
                            handleStatus(
                              item.id,
                              item.page_title,
                              item.page_content,
                              item.sequence,
                              item.user_id,
                              item.user_type,
                              item.sub_category_id,
                              item.meta_title,
                              item.meta_description,
                              "active"
                            );
                          }}
                        >
                          Inactive
                        </span>
                      )}
                    </td>
                    <td>
                      <div className="d-flex">
                        <Link
                          to={`/admin/cms-page/view/${item.id}`}
                          className="btn btn-sm px-3 mt-2"
                        >
                          &#128065;
                        </Link>
                        <button
                          type="button"
                          className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v text-xs"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            {(permission?.edit ||
                              userInfo.role === "admin") && (
                                <Link
                                  className="dropdown-item"
                                  to={`/admin/cms-page/edit/${item.id}`}
                                >
                                  Edit
                                </Link>
                              )}
                          </li>
                          <li>
                            {(permission?.create ||
                              userInfo.role === "admin") && (
                                <button
                                  onClick={() =>
                                    setOpenAddModal({
                                      isOpen: true,
                                      id: item.id,
                                    })
                                  }
                                  className="dropdown-item"
                                >
                                  Delete
                                </button>
                              )}
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
            </CommonTable>
          </div>
        </div>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"DELETE"}
        createAddTopic={"Delete CMS Page"}
        confirmText={"Delete The CMS Page"}
        isOpen={openAddModal.isOpen}
        onSubmit={() => deleteCms(openAddModal.id)}
        onClose={() => setOpenAddModal({ isOpen: false })}
      />
    </>
  );
};

export default withPermission(CmsPagesDashboard);
CmsPagesDashboard.moduleName = "CMS Pages";
