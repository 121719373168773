export const programStatusConstant = [
    // 'pending',
    // 'in_progess',
    // 'approved',
    // 'hold',
    // 'closed',
    'new',
    'triaged',
    'review',
    'resolved',
    'unresolved',
    'rejected',
]