import React from "react";
import { Link } from "react-router-dom";
import downarrowdark from "../../assets/svg/downarrow.svg";

import menudata from "../menudata/menudata.json";

// {
// 	"name": "Homepage",
// 	"link": "#",
// 	"icon": "ni ni-box-2 text-gradient text-primary"
// },
// {
// 	"name": "Custom Pages",
// 	"link": "#",
// 	"icon": "ni ni-app text-gradient text-primary"
// },
const ManageModule = (props) => {
	const userType = props.usertype;

	const userRole = Object.entries(menudata).filter(
		([key, value]) => key === userType
	);
	return (
		<>
			<li className="nav-item dropdown dropdown-hover">
				<Link
					className="nav-link d-flex justify-content-between cursor-pointer align-items-center "
					to="#"
					role="button"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="me-2"
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
					>
						<mask
							id="mask0_1683_5670"
							maskUnits="userSpaceOnUse"
							x="0"
							y="0"
							width="18"
							height="19"
						>
							<path
								d="M14.0744 5.49005C14.5845 6.21955 14.9297 7.05105 15.086 7.92694H17V10.105H15.086C14.9297 10.9809 14.5845 11.8124 14.0744 12.5419L15.4284 13.8938L13.8856 15.4341L12.5316 14.0823C11.8009 14.5916 10.9681 14.9362 10.0908 15.0922V17.0032H7.9092V15.0922C7.0319 14.9362 6.19907 14.5916 5.4684 14.0823L4.1144 15.4341L2.5716 13.8938L3.9256 12.5419C3.41549 11.8124 3.07033 10.9809 2.914 10.105H1V7.92694H2.914C3.07033 7.05105 3.41549 6.21955 3.9256 5.49005L2.5716 4.13822L4.1144 2.59789L5.4684 3.94972C6.19907 3.44043 7.0319 3.09582 7.9092 2.93974V1.02881H10.0908V2.93974C10.9681 3.09582 11.8009 3.44043 12.5316 3.94972L13.8856 2.59789L15.4284 4.13822L14.0744 5.49005Z"
								fill="white"
								stroke="white"
								strokeWidth="2"
								strokeLinejoin="round"
							></path>
							<path
								d="M9 11.0126C9.53043 11.0126 10.0391 10.8023 10.4142 10.4278C10.7893 10.0533 11 9.54542 11 9.01584C11 8.48626 10.7893 7.97836 10.4142 7.60389C10.0391 7.22942 9.53043 7.01904 9 7.01904C8.46957 7.01904 7.96086 7.22942 7.58579 7.60389C7.21071 7.97836 7 8.48626 7 9.01584C7 9.54542 7.21071 10.0533 7.58579 10.4278C7.96086 10.8023 8.46957 11.0126 9 11.0126Z"
								fill="black"
								stroke="black"
								strokeWidth="2"
								strokeLinejoin="round"
							></path>
						</mask>
						<g mask="url(#mask0_1683_5670)">
							<path
								d="M-0.601562 -0.568604H18.5984V18.6006H-0.601562V-0.568604Z"
								fill="#7F56D9"
							></path>
						</g>
					</svg>
					Manage Module
					<img
						src={downarrowdark}
						alt="down-arrow"
						className="arrow ms-1 d-lg-block d-none"
					/>
					<img
						src={downarrowdark}
						alt="down-arrow"
						className="arrow ms-1 d-lg-none d-block"
					/>
				</Link>

				<div
					className="dropdown-menu dropdown-menu-animation dropdown-md dropdown-md-responsive p-3 border-radius-lg mt-0 mt-lg-2"
					aria-labelledby="dropdownMenuBlocks"
				>
					<div className="d-none d-lg-block">
						<ul className="list-group">
							{userRole[0][1]?.manageModule && userRole[0][1].manageModule.submenu.map((item, index) => {
								if ((props?.overAllPermissions && props?.overAllPermissions[item.name]?.view) || ['admin', 'customer', 'researcher'].includes(userType))
									return (
										<li key={index} className="nav-item dropdown dropdown-hover dropdown-subitem list-group-item border-0 p-0">
											<Link
												className="dropdown-item py-2 ps-3 border-radius-md"
												to={item.link}
											>
												<div className="d-flex">
													<div className="icon h-10 me-3 d-flex mt-1">
														<i className={item.icon}></i>
													</div>
													<div className="w-100 d-flex align-items-center justify-content-between">
														<div>
															<p className="dropdown-header text-dark p-0">
																{item.name}
															</p>
														</div>
													</div>
												</div>
											</Link>
										</li>
									);
							})}
						</ul>
					</div>
				</div>
			</li>
		</>
	);
};

export default ManageModule;
