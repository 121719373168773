import React, { useContext, useState } from "react";
import useHttp from "../../Hooks/useHttp";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Infoicon from "../../resetpassword/info.svg";
import Staricon from "../../resetpassword/Star.svg";
import "../customer/Style.css";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OtpInput from "react-otp-input";
import { Navigate, useNavigate } from "react-router-dom";
import AddModal from "../../common/modal/AddModal";

import PhoneInput from "react-phone-input-2";
import AppContext from "../../Context/AppContext";
import HTTPService from "../../../utils/axios";
import { customToast } from "../../../App";

export default function Addcustomer() {
  const { invokeApi } = HTTPService();
  const { setLoading } = useContext(AppContext);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [formState, setFormState] = useState(1);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [otp, setOtp] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [valids, setValids] = useState({});
  const navigate = useNavigate();
  const { PostRequest, GetRequest } = useHttp();

  const token = localStorage.getItem("token");
  const API = process.env.REACT_APP_API_URL;
  const [phoneNumberCheck, setPhoneNumberCheck] = useState();

  const [phValue, setPhValue] = useState();
  console.log("PH--->>>", phValue, form);
  const handleOnChange = (value, country) => {
    const phoneNumberWithoutCode = value
      .replace(`${country.dialCode}`, "")
      .trim();
    setPhoneNumber("phone_number", phoneNumberWithoutCode);
    setPhValue({ value });

    setCountryCode("Country_Code", `+${country.dialCode}`);
  };

  //First Name
  const setFirstName = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please enter first name",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //Last name
  const setLastName = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please enter last name",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //Check Username
  const setUsername = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please enter username",
      });
    } else {
      invokeApi({
        method: "GET",
        url: "check_user_exists/?username=" + value,
        noToken: true
      }).then((data) => {
        if (data && data.results && data.results[0].status === true) {
          setErrors({
            ...errors,
            username: "Username already registered",
          });
          setValids({
            ...valids,
            [field]: false,
          });
        } else {
          setValids({
            ...valids,
            [field]: true,
          });
          setErrors({
            ...errors,
            [field]: null,
          });
        }
      });
    }
  };

  //Email
  const setEmail = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please enter Email",
      });
    } else {
      invokeApi({
        method: "GET",
        url: "check_user_exists/?email=" + value.toLowerCase(),
        noToken: true
      }).then((data) => {
        if (data && data.results && data.results[0].status === true) {
          setErrors({
            ...errors,
            email: "Email already registered",
          });
          setValids({
            ...valids,
            [field]: false,
          });
        } else {
          setValids({
            ...valids,
            [field]: true,
          });
          setErrors({
            ...errors,
            [field]: null,
          });
        }
      });
    }
  };

  //Country Code
  const setCountryCode = async (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please enter Country Code",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //Phone Number
  const setPhoneNumber = async (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please enter phone number",
      });
    } else {
      invokeApi({
        method: "GET",
        url: "check_user_exists/?phone_number=" + form.Country_Code + value,
        noToken: true
      }).then((data) => {
        if (data && data.results && data.results[0].status === true) {
          setErrors({
            ...errors,
            phone_number: "Phone number already registered",
          });
          // phoneNumberCheck = true;
          setValids({
            ...valids,
            [field]: false,
          });
        } else {
          setValids({
            ...valids,
            [field]: true,
          });
          // phoneNumberCheck = false;
          setErrors({
            ...errors,
            [field]: null,
          });
        }
      });
    }
  };

  //Entity Name
  const setEntityName = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please enter entity name",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //Password
  const setPassword = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    const validatePassword = () => {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      // console.log(passwordRegex.test(value),value);
      return passwordRegex.test(value);
    };

    if (!validatePassword()) {
      setErrors({
        ...errors,
        [field]: "Password does not meet the criteria.",
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please enter password",
      });
    }
  };

  //Confirm Password
  const setConfirmPassword = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (form.password === value) {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    } else {
      setErrors({
        ...errors,
        [field]: "Password Confirmation Failed",
      });
    }
    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please re-enter  password",
      });
    }
  };

  //Check Basic Information Validity
  const checkBasicInformationValidity = () => {
    const {
      first_name,
      last_name,
      username,
      email,
      Country_Code,
      phone_number,
      entity_name,
      password,
      confirm_password,
    } = form;

    const basicInformationErrors = {};

    // first_name errors
    if (!first_name || first_name === "")
      basicInformationErrors.first_name = "Please enter first name";

    // last_name errors
    if (!last_name || last_name === "")
      basicInformationErrors.last_name = "Please enter last name";

    // username errors
    if (!username || username === "")
      basicInformationErrors.username = "Please enter username";

    // email errors
    if (!email || email === "")
      basicInformationErrors.email = "Please enter email";

    // Country_Code errors
    if (!Country_Code || Country_Code === "")
      basicInformationErrors.Country_Code = "Please select country code";

    // phone_number errors
    if (!phone_number || phone_number === "")
      basicInformationErrors.phone_number = "Please enter phone number";

    // entity_name errors
    if (!entity_name || entity_name === "")
      basicInformationErrors.entity_name = "Please enter entity name";

    // password errors
    if (!password || password === "")
      basicInformationErrors.password = "Please enter password";

    // password errors
    if (!confirm_password || confirm_password === "")
      basicInformationErrors.confirm_password = "Please re-enter password";

    // password confirmation errors
    if (
      password &&
      password !== "" &&
      confirm_password &&
      confirm_password !== "" &&
      password !== confirm_password
    )
      basicInformationErrors.confirm_password = "Password confirmation failed";

    return basicInformationErrors;
  };

  //Send Verification Email
  const sendVerificationEmail = async () => {
    const { email } = form;
    const { data, error } = await PostRequest(
      API + "send-email-otp/",
      {
        email: email,
      },
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      // customToast.error("Something went wrong!", {
      // 	position: toast.POSITION.TOP_RIGHT,
      // 	autoClose: 1000,
      // });
    } else {

    }
  };

  //Send Phone OTP
  const sendPhoneOtp = async () => {
    const { phone_number, Country_Code } = form;
    const phnumber = Country_Code + phone_number;
    const { data, error } = await PostRequest(
      API + "verify/phone/",
      {
        phone_number: phnumber,
      },
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {

    }
  };

  //Save Basic Information
  const saveBasicInformation = () => {
    const basicInformationErrors = checkBasicInformationValidity();
    if (Object.keys(basicInformationErrors).length > 0) {
      setErrors(basicInformationErrors);
    } else {
      if (!!form["Phone_Verfication"])
        setForm({
          ...form,
          Phone_Verfication: false,
        });
      if (!!form["email_Verfication"])
        setForm({
          ...form,
          email_Verfication: false,
        });
      setOtp("");
      setEmailOtp("");
      sendVerificationEmail();
      sendPhoneOtp();
      setFormState(2);
    }
  };

  //Verify Phone OTP
  const verifyPhoneOtp = async (e) => {
    e.preventDefault();
    const { phone_number, Country_Code } = form;
    const phnumber = Country_Code + phone_number;
    const { data, error } = await PostRequest(
      API + "verify/code/",
      {
        phone_number: phnumber.toString(),
        verification_code: otp.toString(),
      },
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setErrors({
        ...errors,
        Phone_Verfication: "Invalid OTP",
      });
    } else {
      setForm({
        ...form,
        Phone_Verfication: true,
      });
      if (!!errors["Phone_Verfication"])
        setErrors({
          ...errors,
          Phone_Verfication: null,
        });
    }
  };

  //Verify Email OTP
  const verifyEmailOtp = async (e) => {
    e.preventDefault();
    const { email } = form;
    const { data, error } = await PostRequest(
      API + "verify-email-otp/",
      {
        email: email,
        verification_code: emailOtp,
      },
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setErrors({
        ...errors,
        email_Verfication: "Invalid OTP",
      });
    } else {
      setForm({
        ...form,
        email_Verfication: true,
      });
      if (!!errors["email_Verfication"])
        setErrors({
          ...errors,
          email_Verfication: null,
        });
    }
    // setForm({
    //   ...form,
    //   email_Verfication: true,
    // });
    // if (!!errors["email_Verfication"])
    //   setErrors({
    //     ...errors,
    //     email_Verfication: null,
    //   });
  };

  const checkVerificationValidity = () => {
    const { email_Verfication, Phone_Verfication } = form;

    const verificationErrors = {};

    // Phone OTP errors
    if (
      !Phone_Verfication ||
      Phone_Verfication === false ||
      Phone_Verfication === null
    )
      verificationErrors.Phone_Verfication = "Please verify phone number";

    // Email OTP errors
    if (
      !email_Verfication ||
      email_Verfication === false ||
      email_Verfication === null
    )
      verificationErrors.email_Verfication = "Please verify email";

    return verificationErrors;
  };

  const saveVerificationInformation = () => {
    const verificationErrors = checkVerificationValidity();
    if (Object.keys(verificationErrors).length > 0) {
      setErrors(verificationErrors);
    } else {
      setFormState(3);
    }
  };

  //id proof
  const setIdProofNumber = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Id Proof Number",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //id proof doc
  const setIdDocFile = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Upload Id Proof Document",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //address proof number
  const setAddressProofNumber = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter Address Proof Number",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //address proof doc
  const setAddressDocFile = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Upload Address Proof Document",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //kyc validity check
  const checkKycValidity = () => {
    const { id_proof, id_proof_doc, address_proof, address_proof_doc } = form;

    const kycErrors = {};

    // id_proof errors
    if (!id_proof || id_proof === "")
      kycErrors.id_proof = "Please enter id  proof number";

    // id_proof_doc errors
    if (!id_proof_doc || id_proof_doc === "" || id_proof_doc.size === 0)
      kycErrors.id_proof_doc = "Please upload id  proof";

    // address_proof errors
    if (!address_proof || address_proof === "")
      kycErrors.address_proof = "Please enter your address proof number";

    // address_proof_doc errors
    if (
      !address_proof_doc ||
      address_proof_doc === "" ||
      address_proof_doc.size === 0
    )
      kycErrors.address_proof_doc = "Please upload address proof";

    return kycErrors;
  };

  //kyc info save
  const saveKycInformation = () => {
    const kycErrors = checkKycValidity();
    if (Object.keys(kycErrors).length > 0) {
      setErrors(kycErrors);
    } else {
      setFormState(4);
    }
  };

  // display pic
  const setProfilePic = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Upload Profile Picture",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //short description
  const setShortDesc = async (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!value || value === "") {
      setErrors({
        ...errors,
        [field]: "Please Enter a short description",
      });
      setValids({
        ...valids,
        [field]: false,
      });
    } else {
      setValids({
        ...valids,
        [field]: true,
      });
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  //profile validity check
  const checkProfileValidity = () => {
    const { display_pic, Short_Description } = form;

    const profileErrors = {};

    // display_pic errors
    if (!display_pic || display_pic === "" || display_pic.size === 0)
      profileErrors.display_pic = "Please upload profile picture";

    // Short_Description errors
    if (!Short_Description || Short_Description === "")
      profileErrors.Short_Description = "Please enter a short description";

    return profileErrors;
  };

  const finalSubmit = async () => {
    const param = {
      ...form,
      phone_number: form.Country_Code + form.phone_number,
      user_status: "pending",
      is_deleted: false,
    };
    setLoading(true);
    setOpenAddModal(false);
    invokeApi({
      method: "POST",
      url: "customer/",
      data: param,
    })
      .then((data) => {
        setLoading(false);

        navigate("/admin/customers");
      })
      .catch((error) => {
        console.log(error);
        const flattenedErrors = Object.keys(error.data)
          .map(key => key.replaceAll('_', ' ').toUpperCase() + ": " + error.data[key].join('\n'))
          .join('\n');
        customToast.error(flattenedErrors ?? "Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      });

  };

  const saveProfileInformation = () => {
    const profileErrors = checkProfileValidity();
    if (Object.keys(profileErrors).length > 0) {
      setErrors(profileErrors);
    } else {
      finalSubmit();
    }
  };

  const Breadcrumbname = {
    Home: "/admin/home",
    Managment: "/admin/customers",
    Customer: "/admin/customers",
    "Add Customer": "",
  };

  return (
    <>
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-12 m-auto ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
        </div>
        <Row>
          <div className="col-12">
            <div className="multisteps-form mb-5">
              <div className="row">
                <div className="col-12 col-lg-10 mx-auto my-5 ">
                  <div className="multisteps-form__progress">
                    {formState >= 1 ? (
                      <button
                        className="multisteps-form__progress-btn js-active"
                        type="button"
                        title="User Info"
                      >
                        User Info
                      </button>
                    ) : (
                      <button
                        className="multisteps-form__progress-btn"
                        type="button"
                        title="User Info"
                      >
                        User Info
                      </button>
                    )}

                    {formState >= 2 ? (
                      <button
                        className="multisteps-form__progress-btn js-active"
                        type="button"
                        title="Socials"
                      >
                        Verification
                      </button>
                    ) : (
                      <button
                        className="multisteps-form__progress-btn"
                        type="button"
                        title="Verification"
                      >
                        Verification
                      </button>
                    )}

                    {formState >= 3 ? (
                      <button
                        className="multisteps-form__progress-btn js-active"
                        type="button"
                        title="Socials"
                      >
                        KYC/Uploads
                      </button>
                    ) : (
                      <button
                        className="multisteps-form__progress-btn"
                        type="button"
                        title="Verification"
                      >
                        KYC/Uploads
                      </button>
                    )}
                    {formState === 4 ? (
                      <button
                        className="multisteps-form__progress-btn js-active"
                        type="button"
                        title="Profile"
                      >
                        Profile
                      </button>
                    ) : (
                      <button
                        className="multisteps-form__progress-btn"
                        type="button"
                        title="Profile"
                      >
                        Profile
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-10 m-auto">
                  <Form
                    className="multisteps-form__form mb-8"
                    autoComplete="off"
                  >
                    {formState === 1 ? (
                      <div
                        className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
                        data-animation="FadeIn"
                      >
                        <div className="multisteps-form__content">
                          <div className="row mt-3">
                            <Form.Group as={Col} md="6">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                className="multisteps-form__input shadow-none"
                                type="text"
                                placeholder="Enter First Name"
                                value={
                                  form && form.first_name && form.first_name
                                }
                                onChange={(e) =>
                                  setFirstName("first_name", e.target.value)
                                }
                                isInvalid={!!errors.first_name}
                                isValid={
                                  valids.first_name &&
                                  valids.first_name === true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.first_name}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                className="multisteps-form__input shadow-none"
                                type="text"
                                placeholder="Enter Last Name"
                                value={form && form.last_name && form.last_name}
                                onChange={(e) =>
                                  setLastName("last_name", e.target.value)
                                }
                                isInvalid={!!errors.last_name}
                                isValid={
                                  valids.last_name && valids.last_name === true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.last_name}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="row mt-2">
                            <Form.Group as={Col} md="6">
                              <Form.Label>Username</Form.Label>
                              <Form.Control
                                className="multisteps-form__input shadow-none"
                                type="text"
                                placeholder="Enter Username"
                                value={form && form.username && form.username}
                                onChange={(e) =>
                                  setUsername("username", e.target.value)
                                }
                                isInvalid={!!errors.username}
                                isValid={
                                  valids.username && valids.username === true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.username}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                              <Form.Label>Email Address</Form.Label>
                              <Form.Control
                                className="multisteps-form__input shadow-none"
                                type="text"
                                placeholder="Enter Email Address"
                                value={form && form.email && form.email}
                                onChange={(e) =>
                                  setEmail("email", e.target.value)
                                }
                                isInvalid={!!errors.email}
                                isValid={valids.email && valids.email === true}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="row mt-2">
                            <Form.Group as={Col} md="6">
                              <Form.Label htmlFor="">
                                Phone Number
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <div style={{ width: "100%" }}>
                                <PhoneInput
                                  country={"91"}
                                  placeholder="Enter phone number"
                                  value={phValue?.value ?? ""}
                                  onChange={(value, country) =>
                                    handleOnChange(value, country)
                                  }
                                  inputStyle={{ width: "100%" }}
                                />
                              </div>

                              <Form.Control.Feedback type="invalid">
                                {errors.phone_number}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                              <Form.Label>Entity Name</Form.Label>
                              <Form.Control
                                className="multisteps-form__input shadow-none"
                                type="text"
                                placeholder="Enter Entity Name"
                                value={
                                  form && form.entity_name && form.entity_name
                                }
                                onChange={(e) =>
                                  setEntityName("entity_name", e.target.value)
                                }
                                isInvalid={!!errors.entity_name}
                                isValid={
                                  valids.entity_name &&
                                  valids.entity_name === true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.entity_name}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="row mt-2">
                            <Form.Group as={Col} md="6">
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                className="multisteps-form__input shadow-none"
                                type="password"
                                placeholder="******"
                                value={form && form.password && form.password}
                                onChange={(e) =>
                                  setPassword("password", e.target.value)
                                }
                                isInvalid={!!errors.password}
                                isValid={
                                  valids.password && valids.password === true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.password}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                              <Form.Label>Re-Enter Password</Form.Label>
                              <Form.Control
                                className="multisteps-form__input shadow-none"
                                type="password"
                                placeholder="******"
                                value={
                                  form &&
                                  form.confirm_password &&
                                  form.confirm_password
                                }
                                onChange={(e) =>
                                  setConfirmPassword(
                                    "confirm_password",
                                    e.target.value
                                  )
                                }
                                isInvalid={!!errors.confirm_password}
                                isValid={
                                  valids.confirm_password &&
                                  valids.confirm_password === true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.confirm_password}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="suggestion-pass mt-4">
                            <ul className="list-unstyled">
                              <li>
                                <div className="d-flex">
                                  <img
                                    className="info-icon"
                                    src={Infoicon}
                                    alt="info-icon"
                                  />
                                  <strong>
                                    Suggestions for a Strong Password:
                                  </strong>
                                </div>
                              </li>
                              <li>
                                <div className="star mt-2 ms-4">
                                  <img
                                    className="info-icon"
                                    src={Staricon}
                                    alt="info-icon"
                                  />
                                  <p className="m-0">
                                    A Combination of Upper and Lowercase
                                    Letters, Numbers & Special Characters
                                    (Allowed One Only )
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className="star mt-2 ms-4">
                                  <img
                                    className="info-icon"
                                    src={Staricon}
                                    alt="info-icon"
                                  />
                                  <p className="m-0">
                                    At least 8 Characters or More
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="button-row d-flex mt-4">
                            <button
                              className="btn bg-gradient-dark ms-auto mb-0 js-btn-next L551"
                              type="button"
                              title="Next"
                              onClick={saveBasicInformation}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {formState === 2 ? (
                      <div
                        className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
                        data-animation="FadeIn"
                      >
                        <h5 className="font-weight-bolder">
                          Authenticate Customer Account
                        </h5>
                        <div className="multisteps-form__content">
                          <div className="row mt-5 m-auto">
                            <div className="col-lg-3 col-12 text-center d-flex align-items-center justify-content-end">
                              <div className=" px-0 ">
                                <i
                                  style={{ color: "#7F56D9" }}
                                  className="fa-solid fa-phone bg-white border border-dark p-4 rounded-circle"
                                ></i>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12 text-center verify-text">
                              <h5 style={{ color: "#7F56D9" }}>
                                Verify Your Phone
                              </h5>
                              <p className="my-2">
                                Please enter the 6 digit code send to
                              </p>
                              <h6>
                                {form.Country_Code +
                                  form.phone_number.replace(/.(?=.{2})/g, "x")}
                              </h6>
                            </div>
                            <div className="col-lg-6 col-12">
                              <div className="d-flex align-items-center">
                                <OtpInput
                                  value={otp}
                                  onChange={setOtp}
                                  numInputs={6}
                                  inputStyle="form-control w-100 me-4 px-0"
                                  renderInput={(props) => (
                                    <input
                                      disabled={
                                        form.Phone_Verfication &&
                                          form.Phone_Verfication
                                          ? true
                                          : false
                                      }
                                      {...props}
                                    />
                                  )}
                                />

                                <button
                                  type="button"
                                  name="submit"
                                  className="btn bg-gradient-dark mb-0"
                                  id="verifybtn"
                                  onClick={(e) => verifyPhoneOtp(e)}
                                  disabled={
                                    form.Phone_Verfication &&
                                      form.Phone_Verfication
                                      ? true
                                      : false
                                  }
                                >
                                  {form.Phone_Verfication &&
                                    form.Phone_Verfication
                                    ? "Verified"
                                    : "Verify"}
                                </button>
                              </div>
                              <div className="text-danger">
                                <small>
                                  <em>
                                    {errors.Phone_Verfication &&
                                      errors.Phone_Verfication}
                                  </em>
                                </small>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-5 mb-3 m-auto">
                            <div className="col-lg-3 col-12 text-start d-flex align-items-center justify-content-end">
                              <div className=" px-0 ">
                                <i
                                  style={{ color: "#7F56D9" }}
                                  className="fa-solid fa-envelope bg-white border border-dark p-4 rounded-circle"
                                ></i>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12 text-center verify-text">
                              <h5 style={{ color: "#7F56D9" }}>
                                Verify Your email
                              </h5>
                              <p className="my-2">
                                Please enter the 4 digit code send to
                              </p>
                              <h6>e********@gmail.com</h6>
                            </div>
                            <div className="col-lg-6 col-12">
                              <div className="d-flex align-items-center">
                                <OtpInput
                                  value={emailOtp}
                                  onChange={setEmailOtp}
                                  numInputs={6}
                                  inputStyle="form-control w-100 me-4 px-0"
                                  renderInput={(props) => (
                                    <input
                                      disabled={
                                        form.email_Verfication &&
                                          form.email_Verfication
                                          ? true
                                          : false
                                      }
                                      {...props}
                                    />
                                  )}
                                />
                                <button
                                  type="button"
                                  name=""
                                  className="btn bg-gradient-dark mb-0"
                                  id="verifybtn"
                                  onClick={(e) => verifyEmailOtp(e)}
                                  disabled={
                                    form.email_Verfication &&
                                      form.email_Verfication
                                      ? true
                                      : false
                                  }
                                >
                                  {form.email_Verfication &&
                                    form.email_Verfication
                                    ? "Verified"
                                    : "Verify"}
                                </button>
                              </div>
                              <div className="text-danger">
                                <small>
                                  <em>
                                    {errors.email_Verfication &&
                                      errors.email_Verfication}
                                  </em>
                                </small>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="button-row d-flex mt-4 col-12">
                              <button
                                className="btn bg-gradient-dark mb-0 js-btn-prev"
                                type="button"
                                title="Prev"
                                onClick={(e) => {
                                  setFormState(1);
                                }}
                              >
                                Prev
                              </button>
                              <button
                                className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                                type="button"
                                title="Next"
                                onClick={saveVerificationInformation}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {formState === 3 ? (
                      <div
                        className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
                        data-animation="FadeIn"
                      >
                        <h5 className="font-weight-bolder">
                          KYC (Upload Documents)
                        </h5>
                        <div className="multisteps-form__content">
                          <div className="row mt-3 ">
                            <Form.Group as={Col} md="6">
                              <Form.Label label="Password">ID Proof</Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="idproof"
                                placeholder="Enter ID Proof Number"
                                value={form && form.id_proof && form.id_proof}
                                onChange={(e) =>
                                  setIdProofNumber("id_proof", e.target.value)
                                }
                                isInvalid={!!errors.id_proof}
                                isValid={
                                  valids.id_proof && valids.id_proof === true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.id_proof}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Password">
                                Upload Image
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="file"
                                name="idproofdoc"
                                onChange={(e) =>
                                  setIdDocFile(
                                    "id_proof_doc",
                                    e.target.files[0]
                                  )
                                }
                                isInvalid={!!errors.id_proof_doc}
                                isValid={
                                  valids.id_proof_doc &&
                                  valids.id_proof_doc === true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.id_proof_doc}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="row mt-3 mt-5">
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom03"
                            >
                              <Form.Label label="Password">
                                Address Proof
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="text"
                                name="addressproof"
                                placeholder="Enter Address Proof Number"
                                value={
                                  form &&
                                  form.address_proof &&
                                  form.address_proof
                                }
                                onChange={(e) =>
                                  setAddressProofNumber(
                                    "address_proof",
                                    e.target.value
                                  )
                                }
                                isInvalid={!!errors.address_proof}
                                isValid={
                                  valids.address_proof &&
                                  valids.address_proof === true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.address_proof}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                              <Form.Label label="Password">
                                Upload Image
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="file"
                                name="addressproofdoc"
                                onChange={(e) =>
                                  setAddressDocFile(
                                    "address_proof_doc",
                                    e.target.files[0]
                                  )
                                }
                                isInvalid={!!errors.address_proof_doc}
                                isValid={
                                  valids.address_proof_doc &&
                                  valids.address_proof_doc === true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.address_proof_doc}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="row">
                            <div className="button-row d-flex mt-4 col-12">
                              <button
                                className="btn bg-gradient-dark mb-0 js-btn-prev"
                                type="button"
                                title="Prev"
                                onClick={(e) => {
                                  setFormState(2);
                                }}
                              >
                                Prev
                              </button>
                              <button
                                className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                                type="button"
                                title="Next"
                                onClick={saveKycInformation}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {formState === 4 ? (
                      <div
                        className="card multisteps-form__panel p-4 border-radius-xl bg-white js-active"
                        data-animation="FadeIn"
                      >
                        <h5 className="font-weight-bolder">Profile</h5>
                        <div className="multisteps-form__content mt-3">
                          <div className="row">
                            <Form.Group as={Col} md="12">
                              <Form.Label label="Password">
                                Upload Profile Image
                              </Form.Label>
                              <Form.Control
                                className="multisteps-form__input"
                                type="file"
                                name="profileimg"
                                onChange={(e) =>
                                  setProfilePic(
                                    "display_pic",
                                    e.target.files[0]
                                  )
                                }
                                isInvalid={!!errors.display_pic}
                                isValid={
                                  valids.display_pic &&
                                  valids.display_pic === true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.display_pic}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mt-2" as={Col} md="12">
                              <Form.Label label="Password">
                                Short Description
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                className="multisteps-form__input"
                                type="text"
                                name="shortdesk"
                                value={
                                  form &&
                                  form.Short_Description &&
                                  form.Short_Description
                                }
                                onChange={(e) =>
                                  setShortDesc(
                                    "Short_Description",
                                    e.target.value
                                  )
                                }
                                isInvalid={!!errors.Short_Description}
                                isValid={
                                  valids.Short_Description &&
                                  valids.Short_Description === true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.Short_Description}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="button-row d-flex mt-4">
                            <button
                              className="btn bg-gradient-dark mb-0 js-btn-prev"
                              type="button"
                              title="Prev"
                              onClick={(e) => {
                                setFormState(3);
                              }}
                            >
                              Prev
                            </button>
                            <button
                              className="btn bg-gradient-dark ms-auto mb-0"
                              type="button"
                              title="Send"
                              onClick={() => setOpenAddModal(true)}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
      <AddModal
        cancelBtn={"Cancel"}
        submitBtn={"SUBMIT"}
        createAddTopic={"Add Customer"}
        confirmText={"Add The Customer"}
        isOpen={openAddModal}
        onSubmit={saveProfileInformation}
        onClose={() => setOpenAddModal(false)}
      />
    </>
  );
}
